import { isValidEmail } from 'src/utilities/generalUtils'

export function transformPostClinicBrandData(data, storeData) {
  //existing users
  const ownerUserIds = data?.ownerUserIds
    ?.filter(filterNonProspects)
    ?.map((user) => user?.id || user?.userId)

  const attachedDoctorUserIds = data?.attachedDoctorUserIds
    ?.filter(filterNonProspects)
    ?.map((user) => user?.userId)
  const attachedTherapistUserIds = data?.attachedTherapistUserIds
    ?.filter(filterNonProspects)
    ?.map((ther) => ther?.userId)
  const attachedClinicAssistantUserIds = data?.attachedClinicAssistantUserIds
    ?.filter(filterNonProspects)
    ?.map((user) => user?.userId)

  //prospects
  const prospectOwnerEmailId =
    data?.ownerUserIds?.filter(filterProspectUserEmail)?.map(getActualIdFromProspects)?.[0] || ''

  const prospectDoctorEmailIds = data?.attachedDoctorUserIds
    ?.filter(filterProspectUserEmail)
    ?.map((ther) => ther?.userId)
  const prospectTherapistEmailIds = data?.attachedTherapistUserIds
    ?.filter(filterProspectUserEmail)
    ?.map((ther) => ther?.userId)
  const prospectClinicAssistantEmailIds = data?.attachedClinicAssistantUserIds
    ?.filter(filterProspectUserEmail)
    ?.map(getActualIdFromProspects)

  return {
    ...data,
    title: data?.brandName,
    ownerUserIds,
    attachedDoctorUserIds,
    attachedTherapistUserIds,
    attachedClinicAssistantUserIds,
    prospectOwnerEmailId,
    prospectDoctorEmailIds,
    prospectTherapistEmailIds,
    prospectClinicAssistantEmailIds,
    prospects: {
      doctors: data?.prospectDoctorEmailIds?.map((email) => getDataForProspects(email)),
      therapists: data?.prospectTherapistEmailIds?.map((email) => getDataForProspects(email)),
      clinicAssistants: data?.prospectClinicAssistantEmailIds?.map((email) =>
        getDataForProspects(email),
      ),
    },
    workforce: {
      doctors: data?.attachedDoctorUserIds
        ?.filter(filterNonProspects)
        ?.map((userObject) => getDataForWorkForce(userObject)),
      therapists: data?.attachedTherapistUserIds
        ?.filter(filterNonProspects)
        ?.map((userObject) => getDataForWorkForce(userObject)),
      clinicAssistants: data?.attachedClinicAssistantUserIds
        ?.filter(filterNonProspects)
        ?.map((userObject) => getDataForWorkForce(userObject)),
    },
  }
}

function filterProspectUserEmail(user) {
  return user?.__isNew__
}
function filterNonProspects(user) {
  return !user?.__isNew__
}

const getActualIdFromProspects = (user) => user?.userId || ''

function getDataForWorkForce(userObject) {
  const mobileToEnter = userObject?.mobile
  const emailToEnter = userObject?.email

  return {
    id: userObject?.userId,
    userId: userObject?.userId,
    uid: userObject?.userId,
    mobile: mobileToEnter,
    title: userObject?.firstName,
    email: emailToEnter,
    status: 'approved',
  }
}

function getDataForProspects(emailOrPhone) {
  const emailIsValid = isValidEmail(emailOrPhone)
  const mobile = emailIsValid ? '' : emailOrPhone
  const email = emailIsValid ? emailOrPhone : ''

  return {
    email: emailOrPhone,
    // mobile,
  }
}
