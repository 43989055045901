import axios from 'axios'
import { useEffect, useState } from 'react'
import { AUMHUM_API_SERVER_MAP } from 'src/utilities/constants'

const token = localStorage.getItem('token')

function getAuthToken() {
  return localStorage.getItem('token')
}

// export const apiURL = 'http://172.16.0.206:8080/aumhum'
// export const apiURL = 'http://10.200.101.231:8080/aumhum'
export const defaultApiUrl = AUMHUM_API_SERVER_MAP.live

export const apiURL = localStorage.getItem('apiUrl') || defaultApiUrl

export const defaultBgImgId = 'MEDIA-1735d56eebb243dc8c2fa25b59e797ad'

export function getMediaImageUrl() {
  switch (apiURL) {
    case AUMHUM_API_SERVER_MAP.dev:
      return 'https://media.aumhum.xyz/images/prod/'
    case AUMHUM_API_SERVER_MAP.stg:
      return 'https://media.aumhum.xyz/images/prod/'
    case AUMHUM_API_SERVER_MAP.live:
      return 'https://media.aumhum.xyz/images/prod/'
    default:
      return 'https://media.aumhum.xyz/images/prod/'
  }
}
export function getMediaUrl() {
  return 'https://media.aumhum.xyz'
  // switch (apiURL) {
  //   case 'https://dev.aumhum.xyz':
  //     return 'https://media.aumhum.xyz/images/prod/'
  //   case 'https://stg.aumhum.xyz':
  //     return 'https://media.aumhum.xyz/images/prod/'
  //   case 'https://live.aumhum.xyz':
  //     return 'https://media.aumhum.xyz/images/prod/'
  //   default:
  //     return '/images/prod/'
  // }
}
export function getUppyUrl() {
  switch (apiURL) {
    case AUMHUM_API_SERVER_MAP.dev:
      return 'https://uppy-stg.aumhum.xyz'
    case AUMHUM_API_SERVER_MAP.stg:
      return 'https://uppy-live.aumhum.xyz'
    case AUMHUM_API_SERVER_MAP.live:
      return 'https://uppy-live.aumhum.xyz'
    case AUMHUM_API_SERVER_MAP.ganga:
      return 'https://uppy-live.aumhum.xyz'
    default:
      return 'https://uppy-live.aumhum.xyz'
  }
}

export function switchApiUrl() {
  switch (localStorage.getItem('apiUrl')) {
    case AUMHUM_API_SERVER_MAP.dev:
      setApiUrl(AUMHUM_API_SERVER_MAP.stg)
      break
    case AUMHUM_API_SERVER_MAP.stg:
      setApiUrl(AUMHUM_API_SERVER_MAP.live)
      break
    case AUMHUM_API_SERVER_MAP.live:
      setApiUrl(AUMHUM_API_SERVER_MAP.ganga)
      break
    case AUMHUM_API_SERVER_MAP.ganga:
      setApiUrl(AUMHUM_API_SERVER_MAP.office)
      break
    case AUMHUM_API_SERVER_MAP.office:
      setApiUrl(AUMHUM_API_SERVER_MAP.localHost)
      break
    case AUMHUM_API_SERVER_MAP.localHost:
      setApiUrl(AUMHUM_API_SERVER_MAP.dev)
      break
    default:
      setApiUrl(defaultApiUrl)
  }
}

export function setApiUrl(url) {
  localStorage.setItem('apiUrl', url)
}

export function getVideoMediaUrl() {
  switch (apiURL) {
    case 'https://dev.aumhum.xyz':
      return 'https://media.aumhum.xyz/'
    case 'https://stg.aumhum.xyz':
      return 'https://media.aumhum.xyz/'
    case 'https://live.aumhum.xyz':
      return 'https://media.aumhum.xyz/'
    default:
      return 'https://media.aumhum.xyz/'
  }
}

const API_URL = [apiURL, '/api/secure/cc/'].join('')

export const useFetch = (path, customParams = {}, ccSecureApi = true) => {
  const languageCode = sessionStorage.getItem('languageCode')
  const params = `languageCode=${languageCode}&platform=web`
  const [state, setState] = useState({
    loading: true,
    data: null,
    error: null,
  })
  const token = localStorage.getItem('token')
  useEffect(() => {
    setState({ loading: true, data: null, error: null })
    axios
      .get(`${ccSecureApi ? API_URL : `${apiURL}/`}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: customParams,
      })
      .then((res) => {
        setState({ data: res.data, loading: false, error: null })
      })
      .catch((error) => {
        setState({ data: null, loading: false, error: error })
      })
  }, [path, customParams, params, token])
  return state
}

export function DefaultHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken()}`,
  }
}

export async function verifyLogin(accessToken) {
  const countryCode = 'ru'
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `${apiURL}/api/user/firebase/login?languageCode=${languageCode}
        &countryCode=${countryCode}&accessToken=${accessToken}`,
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function fetchFunction(
  path,
  params,
  ccSecureApi = true,
  useMidSlash = true,
  returnNullOnError = false,
) {
  return axios
    .get(`${ccSecureApi ? API_URL : `${apiURL}${useMidSlash ? '/' : ''}`}${path}`, {
      headers: { ...DefaultHeaders() },
      params: params,
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
      if (returnNullOnError) {
        return null
      }

      return err
    })
}

export async function fetchPaginatedFunction(path, params, ccSecureApi = true, useMidSlash = true) {
  return axios
    .get(`${ccSecureApi ? API_URL : `${apiURL}${useMidSlash ? '/' : ''}`}${path}`, {
      headers: { ...DefaultHeaders() },
      params: params,
    })
    .then((res) => {
      return res.data?.content
    })
    .catch((err) => {
      console.log(err)
      return err
    })
}

export async function postFunction(path, data, ccSecureApi = true, useMidSlash = true) {
  return axios
    .post(`${ccSecureApi ? API_URL : `${apiURL}${useMidSlash ? '/' : ''}`}${path}`, data, {
      headers: { ...DefaultHeaders() },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
}
