import PropTypes from 'prop-types'
import React from 'react'
import { DISPLAY_MESSAGE_TEMPLATE_OPTIONS } from 'src/views/settings/DisplayMessageTemplates'
import { TIME_SLOT_VIEWS } from 'src/views/settings/utils/timeSlot-utils'
import DisplayMessageTemplateForm from './displayMessageTemplateForm/DisplayMessageTemplateForm'

export default function CardTemplateMsg({
  cardObject,
  edit,
  setCardObject,
  cardType,
  view = TIME_SLOT_VIEWS.displayMessageEdit,
  showHeading = 'true',
}) {
  return (
    <div className="p-2">
      {showHeading && <h2>{showTitle(cardObject?.type)}</h2>}
      <DisplayMessageTemplateForm
        edit={edit}
        currentCard={cardObject}
        setCurrentCard={setCardObject}
        view={view}
      />
    </div>
  )
}

function showTitle(cardType) {
  switch (cardType) {
    case DISPLAY_MESSAGE_TEMPLATE_OPTIONS.emptySlotMsg:
      return 'Empty slot message'
    case DISPLAY_MESSAGE_TEMPLATE_OPTIONS.completedSlotMsg:
      return 'Completed slot message'
    case DISPLAY_MESSAGE_TEMPLATE_OPTIONS.sentinelCard:
      return 'Sentinel card'
    default:
      return 'Display Card Message'
  }
}

CardTemplateMsg.propTypes = {
  cardObject: PropTypes.object,
  edit: PropTypes.bool,
  setCardObject: PropTypes.func,
  cardType: PropTypes.string,
  view: PropTypes.string,
  showType: PropTypes.bool,
  showHeading: PropTypes.bool,
}
