export function transformInvestigationPostData(data) {
  return {
    ...data,
    source: 'cms',
    testIds: data?.testIds?.map((test) => test?.id),
    referenceRange: data?.referenceRange?.filter(
      (range) => range?.min || range?.max || range?.unit,
    ),
  }
}
