import { FormControlLabel, Grid, Switch } from '@mui/material'
import React from 'react'
import 'react-phone-input-2/lib/bootstrap.css'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import AutomaticListAddingComponent from 'src/components/controlCenter/common/AutomaticListAddingComponent'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import ImageUpload from 'src/components/controlCenter/imageUpload/ImageUpload'
import {
  PRESCRIPTION_LAYOUTS,
  PRESCRIPTION_LAYOUTS_ARRAY,
  PRESCRIPTION_PRINT_TYPES,
  PRESCRIPTION_PRINT_TYPES_ARRAY,
} from 'src/utilities/constants'
import { giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import { CustomCreatableSelect } from 'src/views/plugins/calendar/components/CustomSelect'
import { PrescriptionFooter } from './components/layout/PrescriptionFooter'
import { PrescriptionHeader } from './components/layout/PrescriptionHeader'
import { PMPParent } from './components/styledComponents'

export default function PrescriptionTemplateForm({
  initialObject = {},
  imageObject: prescriptionTemplate,
  setImageObject: setPrescriptionTemplate,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  formMode,
}) {
  const listOfDegreesOptions = prescriptionTemplate?.listOfDegrees?.map((qual) => ({
    id: qual,
    name: qual,
  }))

  function handleChangeHeader(eve) {
    handleNestedChangeObject(eve, 'header')
  }
  function handleChangeFooter(eve) {
    handleNestedChangeObject(eve, 'footer')
  }

  function handleChangeListOfDegrees(e) {
    handleChangeObject({
      ...e,
      target: {
        ...e.target,
        value: e.target.value.map((val) => val?.id || val),
      },
    })
  }

  const handleCreateDegree = (inputValue) => {
    handleChangeObject({
      target: {
        value: [...(prescriptionTemplate?.listOfDegrees || []), inputValue],
        id: 'listOfDegrees',
      },
    })
  }

  function handleChangeRelatedClinicDetails(e, idx) {
    setPrescriptionTemplate((prev) => {
      var relatedClinicDetails = prev?.relatedClinicDetails
      var finalArray = Array.from(
        { length: relatedClinicDetails?.length > idx ? relatedClinicDetails?.length : idx + 1 },
        (_, index) => {
          return relatedClinicDetails?.[index] || {}
        },
      )

      return {
        ...prev,
        relatedClinicDetails: finalArray?.map((details, index) => {
          return index === idx ? giveGenericHandleChangedData(e, details) : details
        }),
      }
    })
  }

  return (
    <Grid container spacing={2}>
      {!disabled && (
        <>
          <Grid item xs={12}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  id="enabled"
                  checked={!!prescriptionTemplate?.enabled}
                  onChange={handleChangeObject}
                />
              }
              label="Enabled: "
              disabled={disabled}
            />
          </Grid>
          <GridSectionWrapper heading="Doctor Details" shadow={false}>
            <Grid item xs={12} md={6}>
              <CustomReactSelect
                id="doctorId"
                value={prescriptionTemplate.doctorId}
                options={customOptions.doctors}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.title}
                label="Doctor"
                onChangeEvent={handleChangeObject}
                disabled={disabled}
                selectType={selectTypes.string}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="doctorName"
                value={prescriptionTemplate?.doctorName}
                label="Doctor Name to show on prescription"
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="designation"
                value={prescriptionTemplate?.designation}
                label="Designation"
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="regId"
                value={prescriptionTemplate?.regId}
                label="Registration ID"
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="cmoRegId"
                value={prescriptionTemplate?.cmoRegId}
                label="CMO Registration ID"
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomReactSelect
                SelectComponent={CustomCreatableSelect}
                isMulti
                id="listOfDegrees"
                label={'Degrees'}
                value={prescriptionTemplate?.listOfDegrees}
                options={listOfDegreesOptions}
                onChangeEvent={handleChangeListOfDegrees}
                onCreateOption={handleCreateDegree}
                getOptionValue={(option) => option?.name}
                getOptionLabel={(option) => option.name}
                // inputvalue={qualificationInput}
                // onInputChange={handleInputChange}
                placeholder="Add degrees and press enter to add them..."
                getNewOptionData={(inputValue, optionLabel) => ({
                  id: inputValue,
                  name: optionLabel,
                })}
                formatCreateLabel={(value) => `Press enter to add: ${value}`}
                selectType={selectTypes.string}
                noOptionsMessage={() => 'Enter a degree.'}
                styles={{
                  option: (provided, state) => {
                    if (state.data.__isNew__) {
                      return { ...provided, color: '#6b50aa' }
                    } else {
                      return provided
                    }
                  },
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <AutomaticListAddingComponent
                id="affiliations"
                label="Affiliations"
                value={prescriptionTemplate?.affiliations}
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageUpload
                imageTypeObject={{
                  resolution: '100x50',
                }}
                title="Doctor's Signature"
                imageId={prescriptionTemplate?.signature}
                onChange={(imageId) =>
                  handleChangeObject({ target: { id: 'signature', value: imageId } })
                }
              />
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper heading="Clinic Details" shadow={false}>
            <Grid item xs={12} md={6}>
              <CustomReactSelect
                id="clinicId"
                value={prescriptionTemplate.clinicId}
                options={customOptions.clinics}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.title}
                label="Clinic"
                onChangeEvent={handleChangeObject}
                disabled={disabled}
                selectType={selectTypes.string}
                isClearable
              />
            </Grid>
            <Grid item xs={12}>
              <CCFormInput
                id="website"
                value={prescriptionTemplate?.website}
                label="Website"
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12}>
              <CCFormInput
                id="contactEmail"
                value={prescriptionTemplate?.contactEmail}
                label="Contact Email"
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12}>
              <CCFormInput
                id="contactNumber"
                value={prescriptionTemplate?.contactNumber}
                label="Contact Phone Number"
                onChange={handleChangeObject}
              />
            </Grid>
            <Grid item xs={12}>
              <CCTextArea
                id="contactAddress"
                value={prescriptionTemplate?.contactAddress}
                label="Contact Address"
                colNumber={12}
                rows={2}
                onChange={handleChangeObject}
              />
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper heading="Prescription Details" shadow={false}>
            <Grid item xs={12}>
              <CustomReactSelect
                id="layout"
                value={prescriptionTemplate?.layout}
                options={PRESCRIPTION_LAYOUTS_ARRAY}
                onChangeEvent={handleChangeObject}
                label="Prescription Layout Type"
                disabled={disabled}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.title}
                selectType={selectTypes.string}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomReactSelect
                id="doctorPrintType"
                value={prescriptionTemplate?.doctorPrintType}
                options={PRESCRIPTION_PRINT_TYPES_ARRAY}
                onChangeEvent={handleChangeObject}
                label="Prescription Print Type for doctor"
                disabled={disabled}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.title}
                selectType={selectTypes.string}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomReactSelect
                id="userPrintType"
                value={prescriptionTemplate?.userPrintType}
                options={PRESCRIPTION_PRINT_TYPES_ARRAY}
                onChangeEvent={handleChangeObject}
                label="Prescription Print Type for patients"
                disabled={disabled}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.title}
                selectType={selectTypes.string}
              />
            </Grid>
            <GridSectionWrapper heading="Header Details" shadow={false}>
              <Grid item xs={12} md={12}>
                <CCFormInput
                  id="title"
                  label="Header Title"
                  required
                  value={prescriptionTemplate?.header?.title}
                  onChange={handleChangeHeader}
                  disabled={disabled}
                />
              </Grid>
            </GridSectionWrapper>
            <GridSectionWrapper heading="Footer Details" shadow={false}>
              {prescriptionTemplate?.layout === PRESCRIPTION_LAYOUTS.threeColumnFooter && (
                <>
                  <RelatedClinicDetails
                    clinicDetails={prescriptionTemplate?.relatedClinicDetails?.[0]}
                    idx={0}
                    layoutType={prescriptionTemplate?.layout}
                    handleChange={(e) => {
                      handleChangeRelatedClinicDetails(e, 0)
                    }}
                  />
                  <RelatedClinicDetails
                    clinicDetails={prescriptionTemplate?.relatedClinicDetails?.[1]}
                    idx={1}
                    layoutType={prescriptionTemplate?.layout}
                    handleChange={(e) => {
                      handleChangeRelatedClinicDetails(e, 1)
                    }}
                  />
                  <RelatedClinicDetails
                    clinicDetails={prescriptionTemplate?.relatedClinicDetails?.[2]}
                    idx={2}
                    layoutType={prescriptionTemplate?.layout}
                    handleChange={(e) => {
                      handleChangeRelatedClinicDetails(e, 2)
                    }}
                  />
                </>
              )}
              {prescriptionTemplate?.layout === PRESCRIPTION_LAYOUTS.twoColumnFooter && (
                <>
                  <RelatedClinicDetails
                    clinicDetails={prescriptionTemplate?.relatedClinicDetails?.[0]}
                    idx={0}
                    layoutType={prescriptionTemplate?.layout}
                    handleChange={(e) => {
                      handleChangeRelatedClinicDetails(e, 0)
                    }}
                  />
                  <RelatedClinicDetails
                    clinicDetails={prescriptionTemplate?.relatedClinicDetails?.[1]}
                    idx={1}
                    layoutType={prescriptionTemplate?.layout}
                    handleChange={(e) => {
                      handleChangeRelatedClinicDetails(e, 1)
                    }}
                  />
                </>
              )}
              {prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS?.threeColumnFooter &&
                prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS?.twoColumnFooter && (
                  <Grid item xs={12} md={6}>
                    <CCFormInput
                      id="headlineAlt"
                      label="Footer Alt Headline"
                      value={prescriptionTemplate?.footer?.headlineAlt}
                      onChange={handleChangeFooter}
                      disabled={disabled}
                    />
                  </Grid>
                )}
              {prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS?.threeColumnFooter &&
                prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS?.twoColumnFooter && (
                  <Grid
                    item
                    xs={12}
                    md={
                      prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS.threeColumnFooter
                        ? 6
                        : 12
                    }
                  >
                    <CCFormInput
                      id="headline"
                      label="Footer Headline"
                      value={prescriptionTemplate?.footer?.headline}
                      onChange={handleChangeFooter}
                      disabled={disabled}
                    />
                  </Grid>
                )}

              {prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS?.threeColumnFooter &&
                prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS?.twoColumnFooter && (
                  <Grid item xs={12} md={6}>
                    <CCFormInput
                      id="subtextAlt"
                      label="Footer Alt Subtext"
                      value={prescriptionTemplate?.footer?.subtextAlt}
                      onChange={handleChangeFooter}
                      disabled={disabled}
                    />
                  </Grid>
                )}
              <Grid item xs={12} md={6}>
                <CCFormInput
                  id="subtext"
                  label="Footer Subtext"
                  value={prescriptionTemplate?.footer?.subtext}
                  onChange={handleChangeFooter}
                  disabled={disabled}
                />
              </Grid>

              {prescriptionTemplate?.layout !== PRESCRIPTION_LAYOUTS.threeColumnFooter && (
                <Grid item xs={12} md={6}>
                  <CCFormInput
                    id="supportTextAlt"
                    label="Footer Alt Support Text"
                    value={prescriptionTemplate?.footer?.supportTextAlt}
                    onChange={handleChangeFooter}
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <CCFormInput
                  id="supportText"
                  value={prescriptionTemplate?.footer?.supportText}
                  onChange={handleChangeFooter}
                  label="Support Text"
                  disabled={disabled}
                />
              </Grid>
              {prescriptionTemplate?.layout ===
                PRESCRIPTION_LAYOUTS?.twoColumnWithDisclaimerFooter && (
                <Grid item xs={12}>
                  <CCFormInput
                    id="disclaimerText"
                    label="Disclaimer Text"
                    value={prescriptionTemplate?.footer?.disclaimerText}
                    onChange={handleChangeFooter}
                    disabled={disabled}
                  />
                </Grid>
              )}
            </GridSectionWrapper>
          </GridSectionWrapper>
        </>
      )}
      <Grid item xs={12}>
        <PMPParent>
          <PrescriptionHeader
            prescriptionData={prescriptionTemplate}
            // printType={'withHeadingAndBottomInformation'}
            printType={PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterData}
          />
          <div style={{ textAlign: 'center', padding: '50px 10px' }}>
            ***** SAMPLE CONTENT *****
          </div>
          <PrescriptionFooter
            prescriptionData={prescriptionTemplate}
            printType={PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterData}
            // printType={'withHeadingAndBottomInformation'}
          />
        </PMPParent>
      </Grid>
      {!disabled && (
        <Grid item xs={12}>
          <EntityBasedImageUploadComponent
            entity={entity}
            entityObject={prescriptionTemplate}
            handleChange={handleChangeObject}
            noBorder
          />
        </Grid>
      )}
    </Grid>
  )
}

function RelatedClinicDetails({ clinicDetails, handleChange, idx, layoutType }) {
  const mdColNumber = {
    [PRESCRIPTION_LAYOUTS.threeColumnFooter]: 4,
    [PRESCRIPTION_LAYOUTS.twoColumnFooter]: 6,
  }

  return (
    <Grid item xs={12} md={mdColNumber?.[layoutType] || 12} sx={{ marginTop: '12px' }}>
      <Grid container>
        <Grid item xs={12}>
          <h6>Column: {idx + 1}</h6>
        </Grid>
        <Grid item xs={12}>
          <CCFormInput
            id="clinicName"
            value={clinicDetails?.clinicName}
            label="Clinic Name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="address"
            value={clinicDetails?.address}
            label="Clinic Address"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="timings"
            value={clinicDetails?.timings}
            label="Timings"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="timingsAltText"
            value={clinicDetails?.timingsAltText}
            label="Timings Alt-Text"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="bottomText"
            value={clinicDetails?.bottomText}
            label="Bottom Text"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="bottomText2"
            value={clinicDetails?.bottomText2}
            label="Bottom Text 2"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
