import {
  CCard,
  CCardBody,
  CCardHeader,
  CDatePicker,
  CForm,
  CFormInput,
  CTableHeaderCell,
} from '@coreui/react-pro'
import { Cancel } from '@mui/icons-material'
import { Avatar, ButtonBase, Paper } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import PhoneInput from 'react-phone-input-2'
import { getGradientColors, hexToRGBA } from 'src/components/controlCenter/styledComponents'
import styled from 'styled-components'

const COLOR_BOX = {
  overflow: 'hidden',
  borderRadius: '6px',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'center',
}

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
`
export const NewApiParamsContainer = styled.div``
export const Container = styled.div``

export const SettingsContainer = styled.div``

export const ApiParamsListCard = styled(CCard)``
export const ApiParamsListCardHeader = styled(CCardHeader)``
export const ApiParamsListCardBody = styled(CCardBody)``
export const ApiParamsListHeader = styled.div`
  border-radius: 20px;
`
export const ApiParamsList = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: stretch;
  gap: 0.25rem;
`
export const ApiParamsListRows = styled.div`
  border-radius: 10px;
  display: grid;
  padding: 10px;
  ${(props) => (props.customBg ? `background-color: ${props.customBg}` : '#7578ff')};
  ${(props) => (props.customBg ? 'color: white' : '')};
  gap: 10px;
  border: 1px solid rgb(199, 205, 214, 0.5);
  grid-template-columns: ${(props) =>
    props.rowElements || props.rowelements
      ? [
          Array.from(
            { length: (props.rowElements || props.rowelements) - 1 },
            (v, i) => `${90 / ((props.rowElements || props.rowelements) - 1)}%`,
          ).join(' '),
          '10%',
        ].join(' ')
      : '30% 30% 30% 10%'};
`
export const ApiParamsListHeaderData = styled.div`
  border-radius: 7px;
  padding: 10px;
  word-wrap: break-word;
  font-weight: 700;
`
export const ApiParamsListData = styled.div`
  display: block;
  border-radius: 7px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
`

export const ApiParamInputContainer = styled(CForm)`
  display: grid;
  border: 1px solid rgb(199, 205, 214, 0.5);
  grid-template-columns: ${(props) =>
    props.rowElements || props.rowelements
      ? [
          Array.from(
            { length: (props.rowElements || props.rowelements) - 1 },
            (v, i) => `${90 / ((props.rowElements || props.rowelements) - 1)}%`,
          ).join(' '),
          '10%',
        ].join(' ')
      : '30% 30% 30% 10%'};
  gap: 10px;
  border-radius: 10px;
`
export const ParamIdInput = styled(CFormInput)``
export const ParamNameInput = styled(CFormInput)``

export const CenterAlignCTableHeader = styled(CTableHeaderCell)`
  vertical-align: middle;
`

export const CardLayoutContainer = styled.div`
  box-sizing: border-box;
`
export const CardContentsContainer = styled.div`
  box-sizing: border-box;
`
export const BasicDetailsContainer = styled.div`
  box-sizing: border-box;
`
export const PageCuratedListsContainer = styled.div`
  box-sizing: border-box;
`
export const CardContentsAndLayoutsContainer = styled.div``

export const ColorButton = muiStyled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'degree' && prop !== 'colorIds',
})(({ theme, type, degree, colorIds }) => ({
  ...COLOR_BOX,
  overFlow: 'hidden',
  backgroundColor:
    type === 'solid'
      ? `${hexToRGBA(colorIds?.lastItem?.hexCode, colorIds?.lastItem?.opacity)}`
      : null,
  backgroundImage:
    type !== 'solid'
      ? `linear-gradient(
    ${degree}deg,
    ${getGradientColors(type, colorIds)})`
      : null,
  aspectRatio: 1,
}))

export const ColorItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  gap: 0.5rem;
  padding: 10px;
  color: #fff;
  background-image: linear-gradient(0, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 65%);
  width: 100%;
  height: 100%;
`
export const MetaCategoriesSliders = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
`
export const MetaCategoriesBackgroundMusicContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 2rem;
`
export const CategoryAnchor = styled.div`
  position: sticky;
  top: 0px;
`
export const CategoryFormContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 0.75rem;
`
export const MaxWidthTableData = styled.td`
  text-overflow: ellipsis;
`

export const MentorSelectContainer = styled.div`
  width: 100%;
`
export const MentorFiltersContainer = styled.div`
  padding: 30px 2%;
  border-radius: 10px;
  ${(props) =>
    props.theme === 'dark'
      ? 'background: linear-gradient(60deg, var(--cui-primary-start), var(--cui-primary-stop))'
      : 'background: var(--cui-primary)'}
`
export const MetaCatSlides = styled.div``

export const FAQPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MentorViewPageFormContainer = muiStyled('div')(
  ({ theme }) => `
gap: 60px;`,
)

export const CertificationFormTableItem = muiStyled('div')(
  ({ theme, odd }) => `
display:flex;
justify-content: flex-start;
align-items: center;
gap: 10px;
padding: 5px 10px;
border-radius: 7px;
`,
)

export const DownloadCertificateTag = muiStyled('a')(
  ({ theme }) => `
transition:all 100ms cubic-bezier(0, 0, 0.27, 0.93);
  color: ${theme.palette.mode === 'dark' ? '#fff' : '#374253'};
  &:hover {
    color: #2d7cc5;
  }
  
`,
)

export const StrongHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`

export const MentorLegalListItem = muiStyled(ButtonBase)(
  ({ theme, accepted }) => `
    display: grid;
    grid-template-columns: 3fr 1fr 2fr;
    padding: 10px 20px;
    gap: 20px;
    box-shadow: 0 0 5px 0 ${accepted ? '#00000082' : '#A864FF'};
    border-radius: 5px;
    ${!accepted ? '1px solid #a864ff73;' : ''}
    width: 100%;
    :hover {
        background: ${theme.palette.mode === 'dark' ? '#2f2f2f' : '#e9e9e9'};
    };
    transition: all 90ms ease-in-out;
    @media(min-width: 768px) {
        grid-template-columns: 3fr 1fr 2fr;
    }
`,
)
export const MentorLegalListItemTitle = muiStyled('div')(
  ({ theme }) => `
    justify-self: self-start;
    font-weight: 500;
    font-size: 14px;
    @media(min-width: 768px){
        font-size: 16px;

    }
`,
)
export const AcceptNowContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  @media(min-width: 768px){
      font-size: 16px;
  }
`,
)
export const AcceptedNotAcceptedContainer = muiStyled('div')(
  ({ theme, accepted }) => `
    color: ${accepted ? 'inherit' : '#ff3131'};
    font-size: 14px;
    @media(min-width: 768px){
        font-size: 16px;
    }
`,
)

export const AgreementsDialogPaper = muiStyled(Paper)`
  padding: 20px;
  width: 90vw;
  max-width: 90vw !important;
  height: 90vh;
`
export const AgreementContentContainer = muiStyled('div')(
  ({ theme }) => `
  padding: 40px 0px 20px;
  @media(min-width: 768px){
  padding: 50px 0px;
  }
`,
)

export const LegalPolicyHeadingContainer = muiStyled('div')(
  ({ theme }) => `
display: flex;
flex-direction: column;
align-items: center;
gap: 5px;
`,
)
export const LegalPolicyFields = muiStyled('div')(
  ({ theme }) => `
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 gap: 20px;
 padding-top: 15px;
 @media(min-width: 768px){
     min-width: 550px;
 flex-direction: row;
 gap: 275px;
 }
 @media(min-width: 992px){
     min-width: 650px;
 flex-direction: row;
 gap: 340px;
 }
`,
)
export const HeadingFields = muiStyled('div')(
  ({ theme, notAccepted }) => `
    color: ${notAccepted ? 'red' : 'inherit'};
    font-size: 16px;
`,
)

export const LegalPolicyHeading = muiStyled('div')(
  ({ theme }) => `
font-size: 30px;
font-weight: 500;
@media(min-width: 768px){
font-size: 35px;
}
`,
)
export const HeadingFieldDiv = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`,
)

export const GenericSectionContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media(min-width: 768px){
    flex-direction: row;
    justify-content: space-between;
  }
`,
)

export const PopoverTimePicker = muiStyled(CDatePicker)(
  ({ theme }) => `
  > .dropdown-menu {
    position:fixed !important;
    inset: unset !important;
    transform: translate(-0px, -550px)!important; 
    @media(min-width: 992px){
    transform: translate(-340px, -400px) !important;}
  }
`,
)

export const SupportRoleBox = muiStyled('div')(
  ({ theme }) => `
  display:flex;
  justify-content: stretch;
  gap: 10px;
  background-color: ${theme.palette.background.dividingContainer};
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
`,
)
export const ClinicScheduleHeading = muiStyled('div')(
  ({ theme }) => `
  font-size: 1rem;
  font-weight:500;
`,
)

export const ClinicTimeSlotSelectionContainer = muiStyled('div')(
  ({ theme }) => `
  width: 130px;
`,
)

export const CompleteValidaitonStaffMember = muiStyled('div')(
  ({ theme }) => `
  color: #5e5eff;
  display:flex;
  align-items:center;
  gap: 5px;
`,
)

export const StyledAddStaffButton = muiStyled(ButtonBase)(
  ({ theme, btnColor }) => `
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 7px 13px;
  gap:7px;
  border-radius: 10px;
  background: ${btnColor === 'none' ? 'transparent' : '#737be3'};
  color: ${btnColor === 'none' ? 'black' : 'white'};
  &:hover{
    background-color: ${btnColor === 'none' ? 'rgba(0,0,0,0.3)' : '#737be3db'};
  }
  transition: all 150ms ease-in-out;
`,
)

function staffCards({ theme }) {
  return `
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #00000059;
`
}

export const StyledClinicStaffForm = muiStyled('form')(staffCards)
export const StyledClinicStaffView = muiStyled('div')(staffCards)

export const StyledStaffPhoneNumberInput = muiStyled(PhoneInput)(
  ({ theme }) => `
  font-family: Outfit;
  > .flag-dropdown {
    font-family: Outfit;
  }
  > .form-control { 
    border: none !important;
    background: transparent !important;
    width: 100% !important;
    padding: 5px 14px 5px 60px !important;
    border-radius: 7px !important;
    border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
    &:focus {
        box-shadow: unset !important;
    }
  }
`,
)

export const StyledStaffInputComponent = muiStyled('div')(
  ({ theme, invalid }) => `
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  background: ${invalid ? '#edbbbb' : '#ebebeb'};
  border-radius: 7px;
  padding: 4px;
`,
)
export const StyledPhoneInputContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  background: ${({ invalid }) => (invalid ? '#edbbbb' : '#ebebeb')};
  border-radius: 7px;
`

export const ScheduleInputContainer = muiStyled('div')(
  ({ theme }) => `
  width: 100%;
`,
)

export const ScheduleTitleInputContainer = muiStyled('div')(
  ({ theme }) => `
  width: 100%;
  @media(min-width: 768px){
    width: 80%;
  }
  @media(min-width: 992px){
    width: 60%;
  }
`,
)

export const DosageWidth = styled.div`
  position: relative;
  width: 55px;
`

export const DeleteIcon = muiStyled(Cancel)(
  ({ theme }) => `
  position: absolute;
  color: ${theme.palette.error.main};
  cursor:pointer;
  background: ${theme.palette.background.dialogBackground};
  top: -8px;
  right: -8px;
  font-size: 18px;
  border-radius: 30px;
  padding: 1px;
`,
)

export const StyledTableAvatarComponent = muiStyled(Avatar)(
  ({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'};
`,
)
