import moment from 'moment'
import React, { useMemo } from 'react'
import SocialConnectionsComponent from './SocialConnectionsComponent'

export default function LoginDataComponent({ userData, dropDownData }) {
  const foundSegment = dropDownData?.segments?.find((seg) => seg?.id === userData?.segmentId)

  const loginStatus = useMemo(() => {
    console.log({ acq: userData?.acquisition })
    if (!!userData?.info?.USER_ON_BOARD) {
      if (userData?.appInstallation?.activationType === 'pending') {
        return 'Activation Pending'
      }
      return 'Onboarded and Activated'
    }
    return 'Not Onboarded'
    ///TODO: CHECK THE WHITEBOARD AND ADD THE REMAINING LOGIC
  }, [userData])

  return (
    <div className="d-flex flex-column h-100 w-100 gap-2">
      <SocialConnectionsComponent userData={userData} />
      <span className="fw-light">{foundSegment?.name || userData?.segmentId}</span>
      <span className="fw-light">Status: {loginStatus}</span>
      <span className="fw-light">
        Acquisition Source: {userData?.acquisition?.title || 'Other'}
      </span>
      <span className="fw-light">
        Join Date:{' '}
        {userData?.createdTime
          ? moment(userData?.createdTime).format('DD MMMM YYYY')
          : 'No Data Available'}
      </span>
    </div>
  )
}
