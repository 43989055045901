import { CCol } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { formatAndCleanColors, initializeColors } from 'src/views/settings/utils/colorUtils'
import {
  entityFormComponents,
  entityTitle,
  getFullSaveObject,
} from '../../../config/common/genericListingAndFormConfig'
import ColorGradientPicker, { colorIdObject } from '../ColorGradientPicker'
import ConfirmationModal from '../ConfirmationModal'
import GenericImageForm from '../backgroundSlides/ImageUploadComponents/GenericImageForm'
import { GenericViewComponentContainer } from '../styledComponents'
import { ImageUploadContext } from './ImageUpload'
import { ImageUploadChooseHeader } from './ImageUploadChoose'
import { COLORS_OBJECT } from './imageUploadUtils'

export default function ImageForm({ setPage, handleSaveAndUpload }) {
  const ImageContext = useContext(ImageUploadContext)
  const [store, dispatch] = useAppSettingsStore()
  const allLanguages = store.languages
  const formRef = useRef(null)
  const [initialImageObject, setInitialImageObject] = useState({
    collection: '',
    tags: [],
  })
  const [imageObject, setImageObject] = useState({
    collection: '',
    tags: [],
  })
  const [showColors, setShowColors] = useState(false)
  const [colors, setColors] = useState({
    ...COLORS_OBJECT,
    colorIds: [colorIdObject, colorIdObject],
  })
  const [showConfirmation, setShowConfirmation] = useState(false)
  const ViewEntityComponent = useMemo(() => {
    return entityFormComponents(ImageContext.imageEntityType).ViewEditComponent.component
  }, [ImageContext.imageEntityType])

  function handleChangeObject(event) {
    setImageObject((prev) => {
      return {
        ...prev,
        [event.target.id]:
          event.target.type !== 'checkbox' ? event.target.value : event.target.checked,
      }
    })
  }

  function handleReactSelectChange(selectedValue, id) {
    handleChangeObject({ target: { value: selectedValue, type: 'react_select', id: id } })
  }

  function formatAndSave() {
    const finalSendDetails = {
      ...imageObject,
      altColor: null,
      tags: imageObject?.tags?.map((tag) => tag.value),
      colors: formatAndCleanColors(showColors ? getColorsObject(colors) : null),
      languageCode: imageObject?.languageCode?.id || null,
      fileId: imageObject?.fileId ? imageObject.fileId : null,
    }
    handleSaveAndUpload(finalSendDetails)
  }

  function handleSave(event) {
    console.log(event)
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      setShowConfirmation(true)
      event.stopPropagation()
    }
  }

  useEffect(() => {
    const file = !!ImageContext?.uploadedFile
      ? Object.values(ImageContext.uploadedFile)?.lastItem
      : null
    const fullSaveObject = getFullSaveObject(ImageContext.imageEntityType)

    setInitialImageObject({
      ...fullSaveObject,
      ...ImageContext.imageObject,
      colors: initializeColors(ImageContext.imageObject?.colors),
      tags: ImageContext.imageObject?.tags?.map((tag) => ({ value: tag, label: tag })),
      languageCode: allLanguages?.find((allLanguage) => {
        return ImageContext.imageObject?.languageCode === allLanguage.id
      }),
      title: !!file ? file.name : ImageContext.imageObject?.title,
      type: !!file ? file.name?.split('.')?.lastItem || '' : ImageContext.imageObject?.type,
    })
    setImageObject({
      ...fullSaveObject,
      ...ImageContext.imageObject,
      colors: initializeColors(ImageContext.imageObject?.colors),
      tags: ImageContext.imageObject?.tags?.map((tag) => ({ value: tag, label: tag })),
      languageCode: allLanguages?.find((allLanguage) => {
        return ImageContext.imageObject?.languageCode === allLanguage.id
      }),
      title: !!file ? file.name : ImageContext.imageObject?.title,
      type: !!file ? file.name?.split('.')?.lastItem || '' : ImageContext.imageObject?.type,
    })

    if (
      !!ImageContext.imageObject?.colors &&
      ImageContext.imageObject?.colors?.colorIds?.length !== 0
    ) {
      setColors(ImageContext.imageObject?.colors)
    }
    setShowColors(!!ImageContext.imageObject?.colors)
  }, [ImageContext.imageObject])

  useEffect(() => {
    const file = !!ImageContext?.uploadedFile
      ? Object.values(ImageContext.uploadedFile)?.lastItem
      : null
    ImageContext?.uploadedFile &&
      setImageObject((prev) => ({
        ...prev,
        title: !!file ? file.name : ImageContext.imageObject?.title,
        type: !!file ? file.name?.split('.')?.lastItem || '' : ImageContext.imageObject?.type,
      }))
    setInitialImageObject((prev) => ({
      ...prev,
      title: !!file ? file.name : ImageContext.imageObject?.title,
      type: !!file ? file.name?.split('.')?.lastItem || '' : ImageContext.imageObject?.type,
    }))
  }, [ImageContext.uploadedFile])

  useEffect(() => {
    console.log(imageObject)
  }, [imageObject])
  return (
    <form
      ref={formRef}
      onSubmit={handleSave}
      className="h-100 d-flex flex-column align-items-stretch justify-content-stretch"
    >
      <DialogTitle>
        <ImageUploadChooseHeader
          title={entityTitle[ImageContext.imageEntityType]?.title || 'Add Details'}
        />
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <CCol md={12} className="row pb-3">
            {ViewEntityComponent && (
              <GenericViewComponentContainer
                {...entityFormComponents(ImageContext.imageEntityType).ViewEditComponent.props}
              >
                <ViewEntityComponent
                  colors={colors}
                  imageId={ImageContext?.imageId}
                  imageObject={ImageContext.imageObject}
                  imageType={ImageContext.imageType}
                  imageTypeObject={ImageContext.imageTypeObject}
                  showColors={showColors}
                  uploadedFile={ImageContext?.uploadedFile || {}}
                  setUploadedFile={ImageContext.setUploadedFile}
                />
              </GenericViewComponentContainer>
            )}
          </CCol>
          {(!!ImageContext.uploadedFile || !!imageObject.id) && (
            <>
              <CCol md={6}>
                <GenericImageForm
                  initialObject={initialImageObject}
                  entity={ImageContext.imageEntityType}
                  imageObject={imageObject}
                  handleChangeObject={handleChangeObject}
                  handleReactSelectChange={handleReactSelectChange}
                  allImages={ImageContext.allImages}
                />
              </CCol>
              <CCol md={6}>
                <ColorGradientPicker
                  sectionTitle="Colors"
                  colors={colors}
                  setColors={setColors}
                  showColors={showColors}
                  setShowColors={setShowColors}
                />
              </CCol>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            formRef.current.requestSubmit()
          }}
        >
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal
        action="submit"
        visibility={showConfirmation}
        visibilitySetter={setShowConfirmation}
        onSubmitFunctions={[formatAndSave]}
      />
    </form>
  )
}

ImageForm.propTypes = {
  setPage: PropTypes.func.isRequired,
  handleSaveAndUpload: PropTypes.func.isRequired,
}

function getColorsObject(colors) {
  const toReturn = {
    ...colors,
    colorIds: colors?.colorIds?.map((colorId) => ({
      ...colorId,
      position: `${colorId.position}`,
      opacity: parseFloat(String(colorId.opacity)),
    })),
    numberOfColors: colors?.colorIds?.length,
    degrees: parseFloat(String(colors?.degrees)),
  }
  return toReturn
}
