import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const OOH_MESSAGES_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'ooh-msg',
  modifiedBy: '',
  title: '',
  subText: '',
  imageId: '',
  imageUrl: '',
  imageType: '',
  userId: '',
  orderId: 0,
  mentorId: '',
  clinicId: '',
  clinicBrandId: '',
  orgIds: [],
  orgType: '',
  infoType: '',
}
