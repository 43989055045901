import axios from 'axios'
import { toast } from 'react-toastify'
import { apiURL } from 'src/services'
const token = localStorage.getItem('token')
const Headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
}

const API_URL = [apiURL, '/api/secure/cc/media/'].join('')

export async function UploadImage(Body) {
  console.log(Headers)
  try {
    const response = await axios.post(`${apiURL}/api/secure/cc/media/file/upload`, Body, {
      headers: {
        ...Headers,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    console.log(response)
    return response
  } catch (error) {
    toast.error('Something went wrong')
    console.log(error)
  }
}

export async function PostImageData(url, Body, Params = {}) {
  try {
    const response = axios.post(`${API_URL}${url}`, Body, {
      headers: Headers,
      params: Params,
    })
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}
