import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const DEFAULT_PLATFORM_TAB_SETTING = {
  name: '',
  preselectedTab: '',
  tabs: [],
  tilePolicyMap: {},
  exploreSettingId: '',
  sleepSettingId: '',
  storySettingId: '',
  yogaSettingId: '',
  journalSettingId: '',
  musicSettingId: '',
  libSettingId: '',
  meSettingId: '',
}

export const TAB_SETTINGS_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'meta-tab-setting',
  name: '',
  description: '',
  android: [DEFAULT_PLATFORM_TAB_SETTING],
  ios: [DEFAULT_PLATFORM_TAB_SETTING],
  web: [DEFAULT_PLATFORM_TAB_SETTING],
}
