import { CSpinner } from '@coreui/react-pro'
import Backdrop from '@mui/material/Backdrop'
import * as React from 'react'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

function LoadingSpinner() {
  const [store, dispatch] = useAppSettingsStore()
  const reduxTheme = store.theme
  return (
    <Backdrop sx={{ color: '#fff', backgroundColor: reduxTheme === 'dark' ? '#000' : '#fff' }} open>
      <CSpinner color="primary" variant="grow" />
    </Backdrop>
  )
}

export default LoadingSpinner
