import { GENDER_LIST_ONBOARDING } from 'src/utilities/constants'
import { getAgeFromDobWithTime, getImageLink } from 'src/utilities/generalUtils'
import { getDateOfPrescription } from 'src/views/healthcare/prescriptionTemplates/utils'
import { PMPHeaderDetails } from '../../../styledComponents'

export default function DefaultPatientDetails({ prescriptionData, leaveSpace }) {
  return (
    <>
      <PMPHeaderDetails
        className="d-flex align-items-center justify-content-between"
        noColor={true}
        style={{ borderTop: '1px solid black' }}
      >
        <div>
          <div>
            Patient Name:{' '}
            <span style={{ textTransform: 'capitalize' }}>
              {prescriptionData?.patientPrefill?.name}
            </span>
          </div>
          <div>
            Age/Gender:{' '}
            {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
            {
              GENDER_LIST_ONBOARDING?.find(
                (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
              )?.label
            }
          </div>
          <div>Date : {getDateOfPrescription(prescriptionData, true)}</div>
        </div>
        {prescriptionData?.prescriptionData?.qrCodeImageId && (
          <div className="d-flex flex-column align-items-center">
            <img
              src={getImageLink(prescriptionData?.prescriptionData?.qrCodeImageId)}
              alt="prescription-qr"
              height="60px"
              width="60px"
            ></img>
            <span style={{ textAlign: 'center' }}>View Online</span>
          </div>
        )}
      </PMPHeaderDetails>
    </>
  )
}
