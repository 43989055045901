import { GENDER_LIST_ONBOARDING } from 'src/utilities/constants'
import { getAgeFromDobWithTime } from 'src/utilities/generalUtils'
import { getDateOfPrescription } from 'src/views/healthcare/prescriptionTemplates/utils'
import { PMPHeaderDetails } from '../../../styledComponents'

export default function ThreeColumnPrescriptionFooterPatientDetails({
  prescriptionData,
  leaveSpace,
}) {
  return (
    <>
      <PMPHeaderDetails
        noColor={true}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          borderTop: '1px solid black',
        }}
      >
        <div>
          Patient Name:{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {prescriptionData?.patientPrefill?.name} (
            {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
            {
              GENDER_LIST_ONBOARDING?.find(
                (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
              )?.label
            }
            )
          </span>
        </div>
        <div style={{ textAlign: 'right' }}>
          Date : {getDateOfPrescription(prescriptionData, true)}
        </div>
      </PMPHeaderDetails>
    </>
  )
}
