import { Add, Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import PackageItem from './PackageItem'
import { PackageItemContainer, PackageMainContainer, PackagesContainer } from './styledComponents'

export default function Packages({ paywall, setPaywall, handleAdd, formMode }) {
  const [conformationModalProps, setConfirmationProps] = useState(defaultConfirmationModalProps)

  function handleChangePackageItem(itemToChange, packageIdx, itemIdx) {
    setPaywall((prevPaywall) => ({
      ...prevPaywall,
      items: (prevPaywall?.items || []).map((paywallPack, packIdx) => {
        return packIdx === packageIdx
          ? paywallPack?.map((paywallPackItem, packItemIdx) => {
              return packItemIdx === itemIdx ? itemToChange : paywallPackItem
            })
          : paywallPack
      }),
    }))
  }

  function handleDeletePackageItem(packageIdx, itemIdx) {
    setPaywall((prevPaywall) => ({
      ...prevPaywall,
      items: prevPaywall.items.map((packageItem, idx) => {
        if (idx === packageIdx) {
          return packageItem.filter((_, i) => i !== itemIdx)
        }
        return packageItem
      }),
    }))
  }

  function handleDeletePackage(packageIdx) {
    setPaywall((prevPaywall) => ({
      ...prevPaywall,
      items: prevPaywall.items.filter((_, idx) => idx !== packageIdx),
    }))
  }

  function handleDeleteButton(packageIdx) {
    setConfirmationProps({
      ...conformationModalProps,
      visibility: true,
      action: 'delete',
      body: 'Are you sure you want to delete this package?',
      onSubmitFunctions: [
        () => {
          handleDeletePackage(packageIdx)
        },
      ],
      visibilitySetter: setConfirmationProps,
      buttonColor: 'error',
    })
  }

  return (
    <>
      {paywall?.items?.map((paywallPackageItem, packageIdx) => (
        <PackageMainContainer key={packageIdx}>
          <div className="d-flex justify-content-between">
            <h3>Package {packageIdx + 1}</h3>
            {formMode !== 'View' && (
              <div>
                <IconButton
                  onClick={() => {
                    handleDeleteButton(packageIdx)
                  }}
                >
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleAdd(packageIdx)
                  }}
                >
                  <Add />
                </IconButton>
              </div>
            )}
          </div>
          <PackagesContainer>
            {Array.isArray(paywallPackageItem) &&
              paywallPackageItem.map((item, idx) => (
                <PackageItemContainer key={idx}>
                  <PackageItem
                    formMode={formMode}
                    paywallPackageItem={item}
                    paywallPackageItemIdx={idx}
                    handleChangeItem={(itm) => handleChangePackageItem(itm, packageIdx, idx)}
                    handleDeletePackageItem={() => {
                      handleDeletePackageItem(packageIdx, idx)
                    }}
                  />
                </PackageItemContainer>
              ))}
          </PackagesContainer>
        </PackageMainContainer>
      ))}
      <ConfirmationModal newImplementation {...conformationModalProps} />
    </>
  )
}
