import SentinelCardView from 'src/components/controlCenter/SentinelCardView'
import DisplayMessageTemplateView from 'src/components/controlCenter/displayMessageTemplateForm/DisplayMessageTemplateView'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { DISPLAY_MESSAGE_TEMPLATE_OPTIONS } from 'src/views/settings/DisplayMessageTemplates'

export function getGridCards(apiParam, urlApiParam, item, extraData) {
  const { onClick } = extraData

  switch (apiParam) {
    case ALL_ENTITY_TYPES.displayMessageTemplates:
      return item.type === DISPLAY_MESSAGE_TEMPLATE_OPTIONS.sentinelCard ? (
        <SentinelCardView inListing handleClick={onClick} item={item} />
      ) : (
        <DisplayMessageTemplateView inListing handleClick={onClick} item={item} />
      )
    default:
      return null
  }
}
