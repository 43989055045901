export function isDoctorOrTherapistApproved(person) {
  return person.status === 'approved'
}

export function getClinicBrandProspectMentorObjectForClinicSelect(email) {
  return {
    value: email,
    label: `${email} (Not onboarded yet)`,
    status: 'prospect',
  }
}

export function getClinicProspectDoctorObjectFromClinicBrandProspectMentorObject(mentor) {
  return {
    email: mentor.value,
    title: mentor.value,
    status: 'prospect',
  }
}

export function getFinalRemovedStaff({
  approvedStaff = [],
  prospectStaff = [],
  type = 'prospect',
  idx,
}) {
  switch (type) {
    case 'prospect':
      return [...(approvedStaff || []), ...prospectStaff?.filter((doc, i) => i !== idx)]
    case 'approved':
      return [...approvedStaff?.filter((doc, i) => i !== idx), ...(prospectStaff || [])]
    default:
      return [...approvedStaff, ...prospectStaff]
  }
}
