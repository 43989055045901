import { toast } from 'react-toastify'
import { approveCurrentClinic, fetchCurrentClinic, saveCurrentClinic } from './services/clinic'
import {
  fetchAllClinicBrands,
  fetchAvailableClinics,
  fetchCurrentClinicBrand,
} from './services/clinicBrand'
import { fetchCurrentClinicChambers } from './services/clinicChambers/clinicChambers'
import { fetchOOHScreensForCurrentClinic } from './services/oohScreens/oohScreens'
import {
  fetchAssistantsForCurrentClinic,
  fetchAssistantsFromCurrentClinicBrand,
  fetchCurrentAssistant,
} from './services/people/assistants'
import {
  fetchDoctorsForCurrentClinic,
  fetchDoctorsFromCurrentClinicBrand,
} from './services/people/doctors'
import {
  fetchTherapistsForCurrentClinic,
  fetchTherapistsFromCurrentClinicBrand,
} from './services/people/therapists'

// export const clinicDataStatusReducers = (builder) => {
//   builder
//     .addMatcher(isPending(fetchAvailableClinics, fetchCurrentClinicBrand), (state, action) => {
//       if (action.meta.arg === 'fetchAvailableClinics') {
//         state.availableClinicsForCurrentClinicBrand.status = 'loading'
//       } else if (action.meta.arg === 'fetchCurrentClinicBrand') {
//         state.currentClinicData.status = 'loading'
//       }
//     })
//     .addMatcher(isFulfilled(fetchAvailableClinics, fetchCurrentClinicBrand), (state, action) => {
//       if (action.meta.arg === 'fetchAvailableClinics') {
//         state.availableClinicsForCurrentClinicBrand.status = 'succeeded'
//         state.availableClinicsForCurrentClinicBrand.data = action.payload
//       } else if (action.meta.arg === 'fetchCurrentClinicBrand') {
//         state.currentClinicData.status = 'succeeded'
//         state.currentClinicData.data = action.payload
//       }
//     })
//     .addMatcher(isRejected(fetchAvailableClinics, fetchCurrentClinicBrand), (state, action) => {
//       if (action.meta.arg === 'fetchAvailableClinics') {
//         state.availableClinicsForCurrentClinicBrand.status = 'failed'
//         state.availableClinicsForCurrentClinicBrand.error = action.error.message
//       } else if (action.meta.arg === 'fetchCurrentClinicBrand') {
//         state.currentClinicData.status = 'failed'
//         state.currentClinicData.error = action.error.message
//       }
//     })
// }
export const clinicDataStatusReducers = (builder) => {
  builder
    // fetchAvailableClinics
    .addCase(fetchAvailableClinics.pending, (state) => {
      state.availableClinicsForCurrentClinicBrand.status = 'loading'
    })
    .addCase(fetchAvailableClinics.fulfilled, (state, action) => {
      state.availableClinicsForCurrentClinicBrand.status = 'succeeded'
      state.availableClinicsForCurrentClinicBrand.data = action.payload
    })
    .addCase(fetchAvailableClinics.rejected, (state, action) => {
      state.availableClinicsForCurrentClinicBrand.status = 'failed'
      state.availableClinicsForCurrentClinicBrand.error = action.error.message
    })
    // fetchCurrentClinicBrand
    .addCase(fetchCurrentClinicBrand.pending, (state) => {
      state.currentClinicBrandData.status = 'loading'
    })
    .addCase(fetchCurrentClinicBrand.fulfilled, (state, action) => {
      state.currentClinicBrandData.status = 'succeeded'
      state.currentClinicBrandData.data = action.payload
    })
    .addCase(fetchCurrentClinicBrand.rejected, (state, action) => {
      state.currentClinicBrandData.status = 'failed'
      state.currentClinicBrandData.error = action.error.message
    })
    // fetchCurrentClinic
    .addCase(fetchCurrentClinic.pending, (state) => {
      state.currentClinicData.status = 'loading'
    })
    .addCase(fetchCurrentClinic.fulfilled, (state, action) => {
      state.currentClinicData.status = 'succeeded'
      state.currentClinicData.data = action.payload
    })
    .addCase(fetchCurrentClinic.rejected, (state, action) => {
      state.currentClinicData.status = 'failed'
      state.currentClinicData.error = action.error.message
      toast.error(action.error.message)
    })
    // fetchAllClinicBrands
    .addCase(fetchAllClinicBrands.pending, (state) => {
      state.allClinicBrands.status = 'loading'
    })
    .addCase(fetchAllClinicBrands.fulfilled, (state, action) => {
      state.allClinicBrands.status = 'succeeded'
      state.allClinicBrands.data = action.payload
    })
    .addCase(fetchAllClinicBrands.rejected, (state, action) => {
      state.allClinicBrands.status = 'failed'
      state.allClinicBrands.error = action.error.message
    })
    // fetchDoctorsForCurrentClinic
    .addCase(fetchDoctorsForCurrentClinic.pending, (state) => {
      state.doctorsForCurrentClinic.status = 'loading'
    })
    .addCase(fetchDoctorsForCurrentClinic.fulfilled, (state, action) => {
      state.doctorsForCurrentClinic.status = 'succeeded'
      state.doctorsForCurrentClinic.data = action.payload
    })
    .addCase(fetchDoctorsForCurrentClinic.rejected, (state, action) => {
      state.doctorsForCurrentClinic.status = 'failed'
      state.doctorsForCurrentClinic.error = action.error.message
    })
    // saveCurrentClinic
    .addCase(saveCurrentClinic.pending, (state) => {})
    .addCase(saveCurrentClinic.fulfilled, (state, action) => {
      state.currentClinicData.status = 'succeeded'
      state.currentClinicData.data = action.payload
    })
    .addCase(saveCurrentClinic.rejected, (state, action) => {
      state.allClinicBrands.error = action.error.message
      toast.error(action.error.message)
    })
    // fetchAssistantsForCurrentClinic
    .addCase(fetchAssistantsForCurrentClinic.pending, (state) => {
      state.assistantsForCurrentClinic.status = 'loading'
    })
    .addCase(fetchAssistantsForCurrentClinic.fulfilled, (state, action) => {
      state.assistantsForCurrentClinic.status = 'succeeded'
      state.assistantsForCurrentClinic.data = action.payload
    })
    .addCase(fetchAssistantsForCurrentClinic.rejected, (state, action) => {
      state.assistantsForCurrentClinic.status = 'failed'
      state.assistantsForCurrentClinic.error = action.error.message
    })
    // fetchAssistantsFromCurrentClinicBrand
    .addCase(fetchAssistantsFromCurrentClinicBrand.pending, (state) => {
      state.assistantsForCurrentClinicBrand.status = 'loading'
    })
    .addCase(fetchAssistantsFromCurrentClinicBrand.fulfilled, (state, action) => {
      state.assistantsForCurrentClinicBrand.status = 'succeeded'
      state.assistantsForCurrentClinicBrand.data = action.payload
    })
    .addCase(fetchAssistantsFromCurrentClinicBrand.rejected, (state, action) => {
      state.assistantsForCurrentClinicBrand.status = 'failed'
      state.assistantsForCurrentClinicBrand.error = action.error.message
    })

    // fetchTherapistsForCurrentClinic
    .addCase(fetchTherapistsForCurrentClinic.pending, (state) => {
      state.therapistsForCurrentClinic.status = 'loading'
    })
    .addCase(fetchTherapistsForCurrentClinic.fulfilled, (state, action) => {
      state.therapistsForCurrentClinic.status = 'succeeded'
      state.therapistsForCurrentClinic.data = action.payload
    })
    .addCase(fetchTherapistsForCurrentClinic.rejected, (state, action) => {
      state.therapistsForCurrentClinic.status = 'failed'
      state.therapistsForCurrentClinic.error = action.error.message
      toast.error(action.error.message)
    })
    // fetchCurrentAssistant
    .addCase(fetchCurrentAssistant.pending, (state) => {
      state.currentAssistantData.status = 'loading'
    })
    .addCase(fetchCurrentAssistant.fulfilled, (state, action) => {
      state.currentAssistantData.status = 'succeeded'
      state.currentAssistantData.data = action.payload
    })
    .addCase(fetchCurrentAssistant.rejected, (state, action) => {
      state.currentAssistantData.status = 'failed'
      state.currentAssistantData.error = action.error.message
      toast.error(action.error.message)
    })

    // fetchOOHScreensForCurrentClinic
    .addCase(fetchOOHScreensForCurrentClinic.pending, (state) => {
      state.oohMsgsForCurrentClinic.status = 'loading'
    })
    .addCase(fetchOOHScreensForCurrentClinic.fulfilled, (state, action) => {
      state.oohMsgsForCurrentClinic.status = 'succeeded'
      state.oohMsgsForCurrentClinic.data = action.payload
    })
    .addCase(fetchOOHScreensForCurrentClinic.rejected, (state, action) => {
      state.oohMsgsForCurrentClinic.status = 'failed'
      state.oohMsgsForCurrentClinic.error = action?.error?.message
      toast.error(action?.error?.message)
    })

    // fetchCurrentClinicChambers
    .addCase(fetchCurrentClinicChambers.pending, (state) => {
      state.clinicChambersForCurrentClinic.status = 'loading'
    })
    .addCase(fetchCurrentClinicChambers.fulfilled, (state, action) => {
      state.clinicChambersForCurrentClinic.status = 'succeeded'
      state.clinicChambersForCurrentClinic.data = action.payload
    })
    .addCase(fetchCurrentClinicChambers.rejected, (state, action) => {
      state.clinicChambersForCurrentClinic.status = 'failed'
      state.clinicChambersForCurrentClinic.error = action.error.message
      toast.error(action.error.message)
    })

    // approveCurrentClinic
    .addCase(approveCurrentClinic.pending, (state) => {})
    .addCase(approveCurrentClinic.fulfilled, (state, action) => {})
    .addCase(approveCurrentClinic.rejected, (state, action) => {})

    // fetchDoctorsFromCurrentClinicBrand
    .addCase(fetchDoctorsFromCurrentClinicBrand.pending, (state) => {
      state.doctorsFromCurrentClinicBrand.status = 'loading'
    })
    .addCase(fetchDoctorsFromCurrentClinicBrand.fulfilled, (state, action) => {
      state.doctorsFromCurrentClinicBrand.status = 'succeeded'
      state.doctorsFromCurrentClinicBrand.data = action.payload
    })
    .addCase(fetchDoctorsFromCurrentClinicBrand.rejected, (state, action) => {
      state.doctorsFromCurrentClinicBrand.status = 'failed'
      state.doctorsFromCurrentClinicBrand.error = action.error.message
    })

    // fetchTherapistsFromCurrentClinicBrand
    .addCase(fetchTherapistsFromCurrentClinicBrand.pending, (state) => {
      state.therapistsFromCurrentClinicBrand.status = 'loading'
    })
    .addCase(fetchTherapistsFromCurrentClinicBrand.fulfilled, (state, action) => {
      state.therapistsFromCurrentClinicBrand.status = 'succeeded'
      state.therapistsFromCurrentClinicBrand.data = action.payload
    })
    .addCase(fetchTherapistsFromCurrentClinicBrand.rejected, (state, action) => {
      state.therapistsFromCurrentClinicBrand.status = 'failed'
      state.therapistsFromCurrentClinicBrand.error = action.error.message
    })
}
