import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { debounce } from 'lodash'
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ThemeContext } from 'src/context/ThemeContext'
import { getMediaImageUrl } from 'src/services'
import { handleDraftEditorDrop, sleep } from 'src/utilities/generalUtils'
import { FAQEditor } from 'src/views/miscellaneous/faqs/components/styledComponents'
import { fileUploadToApi } from './imageUpload/ImageUpload'
import { QuillWrapper } from './styledComponents'

const editorTypes = {
  draftjs: DraftJsEditor,
  quill: QuillEditor,
}

const editorTypesString = {
  draftJs: 'draftjs',
  quill: 'quill',
}

export default function CCRichTextEditor({ ...props }) {
  const [editorType, setEditorType] = useState(editorTypesString.draftJs)
  const EditorComponent = useMemo(() => editorTypes?.[editorType], [editorType])

  const handleChange = (event, editorString) => {
    setEditorType(editorString)
  }

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={editorType}
        exclusive
        onChange={handleChange}
        aria-label="Editor Type"
      >
        <ToggleButton value={editorTypesString.draftJs}>Draft.js</ToggleButton>
        <ToggleButton value={editorTypesString.quill}>Quill.js (Beta)</ToggleButton>
      </ToggleButtonGroup>
      <EditorComponent {...props} />
    </>
  )
}

function DraftJsEditor({
  id,
  rawId,
  htmlContent,
  initialContent,
  handleChangeHtml = () => {},
  handleChangeRaw = () => {},
  toolbarOptionsOverride,
  disabled,
}) {
  const { theme } = useContext(ThemeContext)

  const [editorState, setEditorState] = useState()
  const ccEditorRef = useRef(null)

  const debHandleChangeHtml = debounce(handleChangeHtml, 0)
  const debHandleChangeRaw = debounce(handleChangeRaw, 0)

  const focusEditor = useCallback(() => {
    console.log(ccEditorRef)
    if (ccEditorRef.current) {
      ccEditorRef.current.editor.focus()
    }
  }, [])

  function handleEditorState(state) {
    setEditorState(state)
    const currentContent = state.getCurrentContent()
    const rawData = convertToRaw(currentContent)

    debHandleChangeHtml({ target: { id: id, value: draftToHtml(rawData) } })
    debHandleChangeRaw({ target: { id: rawId, value: rawData } })
  }

  async function handleEditorDrop(e) {
    e.preventDefault()
    await handleDraftEditorDrop(e, editorState, handleEditorState)
  }

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(htmlContent || '')
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
      ),
    )
  }, [initialContent])

  return (
    <FAQEditor
      handlePastedText={() => false}
      onClick={focusEditor}
      onDrop={handleEditorDrop}
      theme={theme}
      style={{ height: 'calc(100% - 48px)' }}
    >
      <Editor
        handlePastedText={() => false}
        toolbar={toolbarOptions}
        wrapperStyle={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        toolbarStyle={{ flexShrink: 0 }}
        editorStyle={{
          height: 'auto',
          zIndex: '99',
          // '::-webkit-scrollbar': {
          //   display: 'none',
          // },
          // '-ms-overflow-style': 'none',
          // 'scrollbar-width': 'none',
        }}
        wrapperClassName="text-black"
        editorClassName="my_editor_class"
        toolbarClassName="wrapper-class text-black"
        readOnly={disabled}
        ref={ccEditorRef}
        editorState={editorState}
        onEditorStateChange={handleEditorState}
        placeholder="Write something!"
      />
    </FAQEditor>
  )
}

function QuillEditor({
  id,
  rawId,
  htmlContent,
  initialContent,
  handleChangeHtml = () => {},
  handleChangeRaw = () => {},
  toolbarOptionsOverride,
  disabled,
}) {
  const [quillSelectionData, setQuillSelectionData] = useState({
    range: null,
    source: null,
    editor: null,
  })
  const quillRef = useRef(null)

  const quillFormat = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  const quillModules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean'],
      ],
      // handlers: {
      //   image: selectLocalImage,
      // },
    },
  }

  function selectLocalImage() {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0]

      // file type is only image.
      if (/^image\//.test(file.type)) {
        saveToServer(file)
      } else {
        console.warn('You could only upload images.')
      }
    }
  }
  async function saveToServer(file) {
    const resp = await fileUploadToApi(file)
    await sleep(500)
    insertToEditor(resp)

    // xhr.onload = () => {
    //   if (xhr.status === 200) {
    //     // this is callback data: url
    //     const url = JSON.parse(xhr.responseText).data
    //     insertToEditor(url)
    //   }
    // }
    // xhr.send(fd)
  }
  function insertToEditor(url) {
    // push image url to rich editor.
    const range = quillRef?.current.editor.getSelection(true)
    quillRef?.current.editor.insertEmbed(range.index, 'image', `${getMediaImageUrl()}${url}`)
  }

  function handleChangeHtmlValue(value) {
    const quillEvent = { target: { value, id } }
    handleChangeHtml(quillEvent)
  }

  useEffect(() => {
    console.log({ quillRef })
    if (quillRef?.current) {
      const quillToolbar = quillRef.current.editor.getModule('toolbar')
      quillToolbar.addHandler('image', selectLocalImage)
    }
  }, [quillRef])

  return (
    <QuillWrapper>
      <ReactQuill
        ref={quillRef}
        readOnly={disabled}
        style={{ height: '100%' }}
        formats={quillFormat}
        modules={quillModules}
        theme={'snow'}
        value={htmlContent}
        onChange={handleChangeHtmlValue}
        onChangeSelection={(range, source, editor) => {
          setQuillSelectionData({ range, source, editor })
        }}
      ></ReactQuill>
    </QuillWrapper>
  )
}

const EditorComponent = memo(({ editorState, ...otherProps }) => {
  return <Editor editorState={editorState} {...otherProps} />
})

const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    // 'fontFamily',
    'list',
    // 'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'image',
    'remove',
    'history',
  ],
  // inline: {
  //   inDropdown: false,
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  //   options: [
  //     'bold',
  //     'italic',
  //     'underline',
  //     'strikethrough',
  //     'monospace',
  //     'superscript',
  //     'subscript',
  //   ],
  //   bold: { className: undefined },
  //   italic: { className: undefined },
  //   underline: { className: undefined },
  //   strikethrough: { className: undefined },
  //   monospace: { className: undefined },
  //   superscript: { className: undefined },
  //   subscript: { className: undefined },
  // },
  // blockType: {
  //   inDropdown: true,
  //   options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  // },
  // fontSize: {
  //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  // },
  // fontFamily: {
  //   options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  // },
  // list: {
  //   inDropdown: false,
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  //   options: ['unordered', 'ordered', 'indent', 'outdent'],
  //   unordered: { className: undefined },
  //   ordered: { className: undefined },
  //   indent: { className: undefined },
  //   outdent: { className: undefined },
  // },
  // textAlign: {
  //   inDropdown: false,
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  //   options: ['center', 'right', 'justify'],
  //   left: { className: undefined },
  //   center: { className: undefined },
  //   right: { className: undefined },
  //   justify: { className: undefined },
  // },
  // colorPicker: {
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   colors: [
  //     'rgb(97,189,109)',
  //     'rgb(26,188,156)',
  //     'rgb(84,172,210)',
  //     'rgb(44,130,201)',
  //     'rgb(147,101,184)',
  //     'rgb(71,85,119)',
  //     'rgb(204,204,204)',
  //     'rgb(65,168,95)',
  //     'rgb(0,168,133)',
  //     'rgb(61,142,185)',
  //     'rgb(41,105,176)',
  //     'rgb(85,57,130)',
  //     'rgb(40,50,78)',
  //     'rgb(0,0,0)',
  //     'rgb(247,218,100)',
  //     'rgb(251,160,38)',
  //     'rgb(235,107,86)',
  //     'rgb(226,80,65)',
  //     'rgb(163,143,132)',
  //     'rgb(239,239,239)',
  //     'rgb(255,255,255)',
  //     'rgb(250,197,28)',
  //     'rgb(243,121,52)',
  //     'rgb(209,72,65)',
  //     'rgb(184,49,47)',
  //     'rgb(124,112,107)',
  //     'rgb(209,213,216)',
  //   ],
  // },
  // link: {
  //   inDropdown: false,
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   dropdownClassName: undefined,
  //   showOpenOptionOnHover: true,
  //   defaultTargetOption: '_self',
  //   options: ['link', 'unlink'],
  //   link: { className: undefined },
  //   unlink: { className: undefined },
  //   linkCallback: undefined,
  // },
  // emoji: {
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   emojis: [
  //     '😀',
  //     '😁',
  //     '😂',
  //     '😃',
  //     '😉',
  //     '😋',
  //     '😎',
  //     '😍',
  //     '😗',
  //     '🤗',
  //     '🤔',
  //     '😣',
  //     '😫',
  //     '😴',
  //     '😌',
  //     '🤓',
  //     '😛',
  //     '😜',
  //     '😠',
  //     '😇',
  //     '😷',
  //     '😈',
  //     '👻',
  //     '😺',
  //     '😸',
  //     '😹',
  //     '😻',
  //     '😼',
  //     '😽',
  //     '🙀',
  //     '🙈',
  //     '🙉',
  //     '🙊',
  //     '👼',
  //     '👮',
  //     '🕵',
  //     '💂',
  //     '👳',
  //     '🎅',
  //     '👸',
  //     '👰',
  //     '👲',
  //     '🙍',
  //     '🙇',
  //     '🚶',
  //     '🏃',
  //     '💃',
  //     '⛷',
  //     '🏂',
  //     '🏌',
  //     '🏄',
  //     '🚣',
  //     '🏊',
  //     '⛹',
  //     '🏋',
  //     '🚴',
  //     '👫',
  //     '💪',
  //     '👈',
  //     '👉',
  //     '👉',
  //     '👆',
  //     '🖕',
  //     '👇',
  //     '🖖',
  //     '🤘',
  //     '🖐',
  //     '👌',
  //     '👍',
  //     '👎',
  //     '✊',
  //     '👊',
  //     '👏',
  //     '🙌',
  //     '🙏',
  //     '🐵',
  //     '🐶',
  //     '🐇',
  //     '🐥',
  //     '🐸',
  //     '🐌',
  //     '🐛',
  //     '🐜',
  //     '🐝',
  //     '🍉',
  //     '🍄',
  //     '🍔',
  //     '🍤',
  //     '🍨',
  //     '🍪',
  //     '🎂',
  //     '🍰',
  //     '🍾',
  //     '🍷',
  //     '🍸',
  //     '🍺',
  //     '🌍',
  //     '🚑',
  //     '⏰',
  //     '🌙',
  //     '🌝',
  //     '🌞',
  //     '⭐',
  //     '🌟',
  //     '🌠',
  //     '🌨',
  //     '🌩',
  //     '⛄',
  //     '🔥',
  //     '🎄',
  //     '🎈',
  //     '🎉',
  //     '🎊',
  //     '🎁',
  //     '🎗',
  //     '🏀',
  //     '🏈',
  //     '🎲',
  //     '🔇',
  //     '🔈',
  //     '📣',
  //     '🔔',
  //     '🎵',
  //     '🎷',
  //     '💰',
  //     '🖊',
  //     '📅',
  //     '✅',
  //     '❎',
  //     '💯',
  //   ],
  // },
  // embedded: {
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   embedCallback: undefined,
  //   defaultSize: {
  //     height: 'auto',
  //     width: 'auto',
  //   },
  // },
  // image: {
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   urlEnabled: true,
  //   uploadEnabled: true,
  //   alignmentEnabled: true,
  //   uploadCallback: undefined,
  //   previewImage: false,
  //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
  //   alt: { present: false, mandatory: false },
  //   defaultSize: {
  //     height: 'auto',
  //     width: 'auto',
  //   },
  // },
  // remove: { className: undefined, component: undefined },
  // history: {
  //   inDropdown: false,
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  //   options: ['undo', 'redo'],
  //   undo: { className: undefined },
  //   redo: { className: undefined },
  // },
}

const quillFormat = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
}

function getRtfStyleForDraftStyle(draftStyle) {
  // Map Draft.js styles to RTF styles
  // This is a simplified example; you may need to expand this mapping
  const styleMap = {
    BOLD: 'b',
    ITALIC: 'i',
    UNDERLINE: 'ul', // Add underline style
    STRIKETHROUGH: 'strike', // Add strike-through style
  }

  return styleMap[draftStyle] || ''
}

function draftToRTF(rawContentState) {
  let rtfContent = `{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033`
  rtfContent += `{\\fonttbl{\\f0 Times New Roman;}}`

  // Loop through the content blocks in Draft.js
  rawContentState.blocks.forEach((block) => {
    const text = block.text
    rtfContent += '{\\pard\\par}\n' // Start a new paragraph

    // Process inline styles
    block.inlineStyleRanges.forEach((range) => {
      const start = range.offset
      const end = start + range.length
      const style = getRtfStyleForDraftStyle(range.style)

      // Apply the style to the range
      rtfContent += `{\\${style} ${text.slice(start, end)}}`
    })

    // Append the text
    rtfContent += text + '\n'
  })

  rtfContent += '}' // Close the RTF document

  return rtfContent
}

// function draftToRTF(content) {
//   let rtf = `{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033`
//   rtf += `{\\fonttbl{\\f0 Times New Roman;}}`

//   content.blocks.forEach((block) => {
//     rtf += `{\\f0\\fs24 ${block.text}\\par}`
//   })

//   rtf += '}' // Closing RTF tags

//   return rtf
// }
