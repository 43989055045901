import { Stack, Typography } from '@mui/material'
import React from 'react'
import { TwoWaySwitchComponent } from './styledComponents'

export default function TwoWaySwitch({ leftText, rightText, ...props }) {
  return (
    <div>
      {' '}
      <Stack direction="row" spacing={1} alignItems="center">
        {leftText && <Typography>{leftText}</Typography>}
        <TwoWaySwitchComponent {...props} />
        {rightText && <Typography>{rightText}</Typography>}
      </Stack>
    </div>
  )
}
