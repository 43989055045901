import { Menu, Popper, alpha, styled as muiStyled } from '@mui/material'
import { CustomAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'

export const StyledTabListReorderContainer = muiStyled('div')(
  ({ theme, customSize = '300px' }) => `
    display: grid;
    grid-template-columns: 1fr ${customSize} ${customSize} ${customSize};
    overflow-x: auto;
`,
)

export const StyledTabListItem = muiStyled('div')(
  ({ theme, index, customBackground = '#0000001f' }) => `
    display: flex;
    width: 100%;
    min-width: 110px;
    gap: 7px;
    backdrop-filter: blur(20px);
    margin: 10px 0px 10px 0px;
    background: ${customBackground};
    border-radius: 7px;
    align-items: center;
    overflow:hidden;
    background: 1px solid #bfbfbf;
`,
)

export const StyledTabListItemContainer = muiStyled('div')(
  ({ theme, index }) => `
  padding: 0px 7px;
`,
)

function getAlternativeBackground(theme, index) {
  return index % 2 === 0 ? 'transparent' : theme.palette.background.paper
}

export function ZeroOpacityDiv({ children }) {
  return <div style={{ opacity: 0 }}>{children}</div>
}

export const TileSingleSelectContainer = muiStyled('div')(
  ({ theme }) => `
  max-width: 200px;
`,
)

export const TileAsyncPaginatedSelect = muiStyled(CustomAsyncPaginate)(
  ({ theme }) => `
> .activity-item-select__control > .activity-item-select__value-container > .activity-item-select__single-value {
  text-overflow: unset;
  white-space: unset;
}`,
)

export const TilePoliciesSelectionStyledMenuPopper = muiStyled((props) => (
  <Popper
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))
export const TilePoliciesSelectionStyledMenu = muiStyled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export const TabDragHandleContainer = muiStyled('div')(
  ({ theme }) => `
height: 80px;
background: #79797994;
`,
)

export const OpenTileFromTabColumnButton = muiStyled('a')(
  ({ theme }) => `
  text-decoration: underline;
  color: ${theme.palette.primary.main};
`,
)
