import { DragIndicator } from '@mui/icons-material'
import { ListItemText } from '@mui/material'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { ALL_AVAILABLE_PLATFORMS } from 'src/utilities/constants'
import { array_move } from 'src/views/curatedLists/utils/curatedListData-utils'
import TabTableItem from './TabTableItem'
import {
  StyledTabListItem,
  StyledTabListItemContainer,
  StyledTabListReorderContainer,
  TabDragHandleContainer,
  ZeroOpacityDiv,
} from './styledComponent'

export default function TabReOrderComponents({
  androidTilePolicies,
  webTabTilePolicies,
  iosTabTilePolicies,
  androidTabList,
  webTabList,
  iosTabList,
  dropDownOptions,
  handleChangeAndroid,
  handleChangeWeb,
  handleChangeIos,
  showTilePolicy,
  disabled,
  iconView,
  customSize,
}) {
  const maxNumberOfTabs = Math.max(
    androidTabList?.length || 0,
    webTabList?.length || 0,
    iosTabList?.length || 0,
    0,
  )
  const allTabs = dropDownOptions.allTabs

  const handleChangeSelectMap = {
    [ALL_AVAILABLE_PLATFORMS.android]: handleChangeAndroid,
    [ALL_AVAILABLE_PLATFORMS.web]: handleChangeWeb,
    [ALL_AVAILABLE_PLATFORMS.ios]: handleChangeIos,
  }

  function handleReorderTab({ source, destination }, platform) {
    if (source && destination && source?.index !== destination?.index) {
      handleChangeSelectMap?.[platform]((prev) => {
        const tabs = array_move(prev?.tabs, source.index, destination.index)
        return { ...prev, tabs }
      })
    }
  }

  console.log({ androidTilePolicies, webTabTilePolicies, iosTabTilePolicies })

  return (
    <StyledTabListReorderContainer customSize={customSize}>
      <StyledTabListItem
        customBackground="transparent"
        className="tab___list-item border-bottom mb-0"
        style={{ borderRadius: 0 }}
      >
        <ListItemText primary="Tab" />
      </StyledTabListItem>
      <StyledTabListItem
        customBackground="transparent"
        className="tab___list-item border-bottom mb-0"
        style={{ borderRadius: 0 }}
      >
        {!disabled && (
          <ZeroOpacityDiv>
            <DragIndicator />
          </ZeroOpacityDiv>
        )}
        <div style={{ minHeight: '80px', width: '0px' }}></div>
        <ListItemText sx={{ paddingLeft: '10px' }} primary="Android" />
      </StyledTabListItem>
      <StyledTabListItem
        customBackground="transparent"
        className="tab___list-item border-bottom mb-0"
        style={{ borderRadius: 0 }}
      >
        {!disabled && (
          <ZeroOpacityDiv>
            <DragIndicator />
          </ZeroOpacityDiv>
        )}
        <div style={{ minHeight: '80px', width: '0px' }}></div>
        <ListItemText sx={{ paddingLeft: '10px' }} primary="Web" />
      </StyledTabListItem>
      <StyledTabListItem
        customBackground="transparent"
        className="tab___list-item border-bottom mb-0"
        style={{ borderRadius: 0 }}
      >
        {!disabled && (
          <ZeroOpacityDiv>
            <DragIndicator />
          </ZeroOpacityDiv>
        )}
        <div style={{ minHeight: '80px', width: '0px' }}></div>
        <ListItemText sx={{ paddingLeft: '10px' }} primary="iOS" />
      </StyledTabListItem>
      <ReOrderTabList
        disabled
        tabList={Array.from({ length: maxNumberOfTabs }, (_, i) => ({
          id: `tabOrder - ${i + 1}`,
          title: i + 1,
        }))}
        platform="Tabs"
      />
      <ReOrderTabList
        key={ALL_AVAILABLE_PLATFORMS.android}
        disabled={disabled}
        tilePolicies={androidTilePolicies}
        tabList={androidTabList?.map(
          (tab) => allTabs?.find((fullTab) => fullTab.id === tab) || tab,
        )}
        showTilePolicy={showTilePolicy}
        platform={ALL_AVAILABLE_PLATFORMS.android}
        handleReorder={handleReorderTab}
        iconView={iconView}
      />
      <ReOrderTabList
        key={ALL_AVAILABLE_PLATFORMS.web}
        disabled={disabled}
        tilePolicies={webTabTilePolicies}
        tabList={webTabList?.map((tab) => allTabs?.find((fullTab) => fullTab.id === tab) || tab)}
        showTilePolicy={showTilePolicy}
        platform={ALL_AVAILABLE_PLATFORMS.web}
        handleReorder={handleReorderTab}
        iconView={iconView}
      />
      <ReOrderTabList
        key={ALL_AVAILABLE_PLATFORMS.ios}
        tilePolicies={iosTabTilePolicies}
        disabled={disabled}
        tabList={iosTabList?.map((tab) => allTabs?.find((fullTab) => fullTab.id === tab) || tab)}
        showTilePolicy={showTilePolicy}
        platform={ALL_AVAILABLE_PLATFORMS.ios}
        handleReorder={handleReorderTab}
        iconView={iconView}
      />
    </StyledTabListReorderContainer>
  )
}

function ReOrderTabList({
  tabList,
  tilePolicies,
  platform,
  handleReorder,
  disabled,
  iconView,
  showTilePolicy,
}) {
  return (
    <DragDropContext onDragEnd={(results) => handleReorder(results, platform)}>
      <Droppable id="platform" droppableId="platform" type="group">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="tab-list___non-shrinkable d-flex flex-column w-100"
          >
            {tabList?.map((tab, idx) => (
              <StyledTabListItemContainer index={idx} key={tab?.id} className="border-bottom">
                <Draggable
                  isDragDisabled={disabled}
                  key={`${tab?.id} - ${platform}`}
                  draggableId={`${tab?.id} - ${platform}`}
                  index={idx}
                >
                  {(provided) => (
                    <StyledTabListItem
                      id={tab?.id}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      className="tab___list-item"
                      customBackground={tab?.id?.includes('tabOrder') ? 'transparent' : undefined}
                    >
                      {!disabled && (
                        <TabDragHandleContainer
                          {...provided.dragHandleProps}
                          className="d-flex align-items-center"
                        >
                          <DragIndicator />
                        </TabDragHandleContainer>
                      )}
                      <TabTableItem
                        item={tab}
                        disableShortCutMenu={tab?.id?.includes('tabOrder')}
                        disableTabImage={tab?.id?.includes('tabOrder')}
                        concurrentTilePolicy={tilePolicies?.[tab?.id]}
                        hideButtonToOpenConcurrentTilePolicy={tab?.id?.includes('tabOrder')}
                        showTilePolicy={showTilePolicy}
                        iconView={iconView}
                      />
                    </StyledTabListItem>
                  )}
                </Draggable>
              </StyledTabListItemContainer>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
