export const FEATURES_API = {
  reflections: {
    get: {
      url: '/reflection/summary',
    },
    save: {
      url: '/reflection/save',
    },
    enable: {
      url: '',
    },
    disable: {
      url: '',
    },
  },
  quotes: {
    get: {
      url: '/quotes',
    },
    getSingle: {
      url: '/quote/',
    },
    save: {
      url: '/quote/save',
    },
    enable: {
      url: '/quote/{id}/enable',
    },
    disable: {
      url: '/quote/{id}/disable',
    },
  },
  journals: {
    get: {
      url: '/journal/summary',
    },
    getSingle: {
      url: '/journal/details',
    },
    save: {
      url: '/journal/save',
    },
    enable: {
      url: '/journal/{id}/enable',
    },
    disable: {
      url: '/journal/{id}/disable',
    },
  },
  hints: {
    get: {
      url: '/hint/summary',
    },
    save: {
      url: '/hint/save',
    },
  },
  breathe: {
    get: {
      url: '/breathe-programs',
    },
    getSingle: {
      url: '/breathe-program/details',
    },
    save: {
      url: '/hint/save',
    },
  },
  techniques: {
    getSingle: {
      url: '/meditation-technique/details',
    },
  },
}
