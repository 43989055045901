export const CATEGORY_TYPE_OBJECT = {
  createdBy: '',
  createdTime: {},
  deleted: true,
  desc: '',
  enabled: true,
  id: '',
  itemType: [],
  modifiedBy: '',
  modifiedTime: {},
  title: '',
}

export function getCategoryScopes() {
  return [
    { id: 'app', label: 'Avaliable To Apps', enabled: true },
    { id: 'server', label: 'Avaliable To System', enabled: true },
  ]
}
