import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'

export async function transformInitialUserData({ fetchedData, locationParams }) {
  var dataToReturn = fetchedData?.content?.find((user) => user?.id === locationParams?.id) || {}

  const appPolicy = await getUserAppPolicy({ userId: dataToReturn?.id })

  dataToReturn['appPolicy'] = appPolicy

  return dataToReturn
}

async function getUserAppPolicy({ userId }) {
  const resp = await GetSettingsData(USERS_API_SIGNATURES_MAP.appPolicy.get, { userId })

  if (resp.status === 200) {
    return resp.data
  } else {
    return {}
  }
}
