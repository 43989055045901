import { ButtonBase } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import { getMediaImageUrl } from 'src/services'
import styled from 'styled-components'
import { BACKGROUND_SLIDES_IMAGE_TYPES } from '../BackgroundSlidesUpload'

export const MediaPreviewObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: '#EBEBEB',
  height: '60vh',
  width: '100%',
  borderRadius: '9px',
}

// CONSTANTS

export const BGSPreviewBox = styled.img`
  height: 100%;
  aspect-ratio: ${(props) =>
    props.bgsType === BACKGROUND_SLIDES_IMAGE_TYPES.portraitImage ? '9/16' : '16/9'};
`

export const TimeLineContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #e8ecff;
  border-radius: 9px;
  width: 100%;
  height: 100%;
  gap: 2px;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`
export const TimeLineImage = styled.div`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-image: url('${(props) => props.img}');
  background-repeat: repeat-x;
  height: 100%;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  border: ${(props) => (props.selected ? '1.5px solid var(--cui-primary)' : '')};
  width: ${(props) => (props.imgWidth <= 39 ? 40 : props.imgWidth)}% !important;
  @media (min-width: 576px) {
    width: ${(props) => (props.imgWidth <= 39 ? 40 : props.imgWidth)}% !important;
  }
  @media (min-width: 768px) {
    width: ${(props) => (props.imgWidth <= 39 ? 40 : props.imgWidth)}% !important;
  }
  @media (min-width: 992px) {
    width: ${(props) => (props.imgWidth <= 10 ? 11 : props.imgWidth)}% !important;
  }
`
export const BgImageText = styled.h1`
  font-weight: 300;
  color: #858585;
`
export const BGSPreviewBoxContainer = styled.div`
  aspect-ratio: 1/1;
  height: 100%;
  display: flex;
  justify-content: center;
`
export const BGSPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
`

export const TimeFrameView = styled.div`
  display: flex;
  align-self: flex-end;
`

export const VideoUploadButton = muiStyled(ButtonBase)(({ theme }) => ({
  width: '100%',
  height: '100%',
  color: '#858585',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const TimeLineSlidesContainer = styled.div`
  position: relative;
  width: 100%;
`

export const SlideTimeStampContainer = styled.div`
  background-color: rgb(79, 93, 115);
  padding: 0.1rem 0.5rem;
  border-radius: 30px;
  color: #fff;
`
export const TransparentInput = styled.input`
  background-color: transparent;
  outline: none;
  padding: 0rem 0.25rem;
  font: inherit;
  transition: all 0.1s cubic-bezier(0.53, 0.18, 0.44, 1.14) 0s;

  &:hover {
    background-color: #ddd;
    color: #000000;
  }

  &:focus {
    background-color: #ddd;
    color: #000000;
  }
`

function getImages(images) {
  if (Array.isArray(images)) {
    return images?.map((img) => `url(${getMediaImageUrl()}${img})`)?.join(',')
  } else {
    return 'nan'
  }
}

export const BGSChooseButtonBackground = styled.div`
  background: ${(props) => getImages(props?.images)} no-repeat center;
  background-size: contain;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const BGSTableDataSize = styled.td`
  height: 70px;
`
export const BGSChooseButtonContentContainer = styled.div`
  z-index: 2;
`
