import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'
import { getReduxStoreData } from 'src/getDataFromRedux'
import { ObjectId } from 'src/utilities/generators'
import { DISPLAY_MESSAGE_TEMPLATES_OBJECT } from 'src/views/settings/utils/display-message-template'
import { optionTypeOptions } from '../components/MainJournalCard'

export const JOURNAL_OBJECT = {
  apiParam: 'journal',
  allGenre: [],
  altTexts: {
    explore: '',
    home: '',
    journal: '',
    sdk: '',
    sleep: '',
    sound: '',
    story: '',
    yoga: '',
  },
  appLanguages: ['en'],
  appListable: true,
  availableForQuote: false,
  backdropImage: '',
  backdropImageColor: '',
  blogBanner: '',
  blogBannerColor: '',
  colors: COLORS_OBJECT,
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  createdBy: '',
  customShortLink: '',
  deleted: false,
  description: '',
  enabled: true,
  endScreen: {
    animation: true,
    buttonText: '',
    message: '',
  },
  feelings: [],
  heroCardImage: '',
  heroCardImageColor: '',
  id: '',
  internalTitle: '',
  journalQusAns: [],
  journalType: 'regular',
  postMsg: '',
  postMsgs: [],
  preMsg: '',
  preMsgs: [],
  subText: '',
  subscription: null,
  tags: [],
  title: 'New Journal',
  translations: {},
}
export const ASSESSMENT_OBJECT = {
  allGenre: [],
  apiParam: 'assessment',
  appLanguages: [],
  appListable: true,
  attachableOnly: true,
  availableForQuote: true,
  backdropImage: '',
  backdropImageColor: '',
  blogBanner: '',
  blogBannerColor: '',
  categoryList: [],
  colors: null,
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  customShortLink: '',
  deleted: false,
  description: '',
  enabled: true,
  endScreen: {
    animation: true,
    buttonText: '',
    message: '',
  },
  feelings: [],
  heroCardImage: '',
  heroCardImageColor: '',
  id: '',
  idByUser: false,
  domainScoringMethod: 'summation',
  domainScoringRange: {},
  interpretationMethod: 'severity',
  internalTitle: '',
  journalQusAns: [],
  journalType: 'assessment',
  postMsg: '',
  scoringRange: [],
  postMsgs: [],
  preMsg: '',
  preMsgs: [],
  resultScreen: { type: 'landing-card', ...DISPLAY_MESSAGE_TEMPLATES_OBJECT },
  startScreen: { type: 'landing-card', ...DISPLAY_MESSAGE_TEMPLATES_OBJECT },
  subText: '',
  subscription: null,
  tags: [],
  therapistId: '',
  title: '',
  translations: {},
  verticalRectangle: '',
  verticalRectangleColor: '',
  whiteListedOptionIds: [],
}

export const SCORING_RANGE_OBJECT = {
  articleId: '', //suggested article for user based on score
  id: '',
  interpretation: '', //Short summary or insight of the score that the user got
  max: 10,
  min: 0,
  title: '',
}

export const JOURNAL_QUS_ANS = {
  answerType: '',
  answere: '',
  hints: [],
  id: '',
  nextItemId: '',
  nextItemType: '',
  options: [],
  question: '',
  qusId: '',
  subtext: '',
}
export const JOURNAL_MSG_OBJECT = {
  msg: '',
  msgId: '',
  msgType: '',
  nextItem: '',
  nextItemType: '',
}

export const tableIconProps = {
  fontSize: 'small',
}

export const journalPageOptions = [50, 100, 200, 400]

export function validateWhiteListedOptionIds(apiParam, whiteListedOptionIds) {
  switch (apiParam) {
    case 'assessment':
      return whiteListedOptionIds?.map((optionId) => ({
        ...optionId,
        id: optionId?.id ? optionId?.id : `OPT-${ObjectId()}`,
      }))
    default:
      return []
  }
}

export function validateQusOrMessage(validationList, type, apiParam, whiteListedOptionIds) {
  switch (type) {
    case 'question':
      return validationList.map((qusAns) => {
        return {
          ...qusAns,
          qusId: qusAns.qusId === '' ? ObjectId() : qusAns.qusId,
          options:
            apiParam === 'assessment'
              ? qusAns?.options?.map((option, idx) => {
                  const foundWhiteListedOption = whiteListedOptionIds?.find(
                    (wListedOptions) => wListedOptions.name === option?.name,
                  )
                  console.log(foundWhiteListedOption)
                  return {
                    ...option,
                    id: option?.id
                      ? option.id
                      : whiteListedOptionIds?.find(
                          (wListedOptions) => wListedOptions.name === option?.name,
                        )?.id,
                    // id: foundWhiteListedOption?.id,
                    orderId: idx + 1,
                  }
                }) || null
              : null,
        }
      })
    case 'message':
      return validationList.map((message) => {
        return message.msgId === '' ? { ...message, msgId: ObjectId() } : message
      })
    default:
      alert('wrong validation type:' + type)
      return validationList
  }
}

export function addMessage(id, setData) {
  setData((prev) => {
    let newTranslations = prev.translations
    Object.keys(newTranslations).forEach((lang, index) => {
      newTranslations[lang] = {
        ...newTranslations[lang],
        [id]: newTranslations[lang][id]
          ? [...newTranslations[lang][id], { ...JOURNAL_MSG_OBJECT, msgId: `JRLMSG-${ObjectId()}` }]
          : [{ ...JOURNAL_MSG_OBJECT, msgId: `JRLMSG-${ObjectId()}` }],
      }
    })
    return {
      ...prev,
      [id]: [...(prev[id] || []), { ...JOURNAL_MSG_OBJECT, msgId: `JRLMSG-${ObjectId()}` }],
      translations: newTranslations,
    }
  })
}

export function addJournalQuesAns(setData, apiParam) {
  setData((prev) => {
    let newTranslations = prev?.translations
    Object.keys(newTranslations).forEach((lang, index) => {
      newTranslations[lang] = {
        ...newTranslations[lang],
        journalQusAns: newTranslations[lang]?.journalQusAns
          ? [
              ...newTranslations[lang]?.journalQusAns,
              {
                ...JOURNAL_QUS_ANS,
                qusId: `QUS-${ObjectId()}`,
                answerType: apiParam === 'journal' ? 'textbox' : optionTypeOptions[0].value,
              },
            ]
          : [
              {
                ...JOURNAL_QUS_ANS,
                qusId: `QUS-${ObjectId()}`,
                answerType: apiParam === 'journal' ? 'textbox' : optionTypeOptions[0].value,
              },
            ],
      }
    })

    return {
      ...prev,
      journalQusAns: [
        ...(prev?.journalQusAns || []),
        {
          ...JOURNAL_QUS_ANS,
          qusId: `QUS-${ObjectId()}`,
          answerType: apiParam === 'journal' ? 'textbox' : optionTypeOptions[0].value,
          options:
            prev?.enforceWhiteListedOptions === true && apiParam === 'assessment'
              ? prev?.whiteListedOptionIds
              : [],
        },
      ],
    }
  })
}

export function removeMessage(id, index, setData) {
  console.log({ id: id, index: index })
  setData((prev) => {
    let newTranslations = prev.translations

    Object.keys(newTranslations).forEach((lang) => {
      newTranslations[lang] = {
        ...newTranslations[lang],
        [id]: newTranslations[lang][id]?.filter((message, messageIndex) => index !== messageIndex),
      }
    })

    return { ...prev, [id]: prev[id]?.filter((message, messageIndex) => index !== messageIndex) }
  })
}

export function isJournalValid(journal) {
  if (journal === undefined) return false

  if (journal.journalQusAns?.length === 0) {
    return false
  }
  // if (journal.preMsgs?.length === 0) {
  //   return false
  // }
  // if (journal.postMsgs?.length === 0) {
  //   return false
  // }
  for (var i = 0; i < journal?.journalQusAns?.length; ++i) {
    if (journal.journalQusAns[i]?.question === '') {
      return false
    }
  }
  for (var j = 0; j < journal?.preMsgs?.length; ++j) {
    if (journal.preMsgs[j]?.msg === '') {
      return false
    }
  }
  for (var k = 0; k < journal?.postMsgs?.length; ++k) {
    if (journal.postMsgs[k]?.msg === '') {
      return false
    }
  }
  return true
}

// export const tempDomains = [
//   { id: 'domain_depression', name: 'Domain: Depression' },
//   { id: 'domain_anxiety', name: 'Domain: Anxiety' },
//   { id: 'domain_stress', name: 'Domain: Stress' },
//   { id: 'domain_autonomy', name: 'Domain:Autonomy' },
//   { id: 'domain_environmental_mastery', name: 'Domian: Environmental Mastery' },
//   { id: 'domain_personal_growth', name: 'Domain: Personal Growth' },
//   { id: 'domain_positive_relationships', name: 'Domain: Positive Relationships' },
//   { id: 'domain_purpose_in_life', name: 'Domain: Purpose in Life' },
//   { id: 'domain_self_acceptance', name: 'Domain: Self-Acceptance' },
//   { id: 'domain_self_awareness', name: 'Domain: Self Awareness' },
//   { id: 'domain_managing_emotions', name: 'Domain: Managing Emotions' },
//   { id: 'domain_motivating_oneself', name: 'Domain: Motivating Oneself' },
//   { id: 'domain_empathy', name: 'Domain: Empathy' },
//   { id: 'domain_social_skills', name: 'Domain: Social Skills' },
// ]

export function getTempDomains() {
  return getReduxStoreData().tags?.filter((t) => t.type === 'domain')
}
