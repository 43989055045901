import axios from 'axios'
import { toast } from 'react-toastify'
import { defaultConfirmationModalProps } from 'src/components/controlCenter/ConfirmationModal'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { DefaultHeaders, apiURL } from 'src/services'

export async function cacheEvictRowClick({ setConfirmationModalProps, item }) {
  setConfirmationModalProps({
    ...defaultConfirmationModalProps,
    visibility: true,
    visibilitySetter: setConfirmationModalProps,
    action: 'evict',
    body: `Do you want to evict cache for ${item.title}?`,
    buttonColor: 'warning',
    onSubmitFunctions: [() => evictCache(item)],
  })
}

async function evictCache(item) {
  const tst = toast.loading('Evicting cache...', { autoClose: false })
  try {
    const res = await axios.get(`${apiURL}/api/secure/cc/cache/evict-by-id`, {
      headers: { ...DefaultHeaders() },
      params: {
        id: item.id,
      },
    })
    if (res.status === 200) {
      toast.update(tst, {
        ...TOAST_UTILS,
        isLoading: false,
        render: 'Cache evicted',
        type: 'success',
        autoClose: 3000,
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
  return item
}
