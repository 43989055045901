import moment from 'moment'

export function getDateOfPrescription(prescriptionData) {
  if (prescriptionData?.prescriptionData?.createdTime) {
    return moment(prescriptionData?.prescriptionData?.createdTime, 'YYYY-MM-DD HH:mm:ss').format(
      'DD/MM/YYYY',
    )
  }
  return moment().format('DD/MM/YYYY')
}
