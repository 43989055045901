import {
  CCol,
  CDatePicker,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
} from '@coreui/react-pro'
import { useTheme } from '@mui/material'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import 'draft-js/dist/Draft.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { debounce } from 'lodash'
import moment from 'moment/moment'
import PropTypes, { bool, func, object } from 'prop-types'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ThemeContext } from 'src/context/ThemeContext'
import { FIELD_TYPES_OBJECT_FIELD_TYPES } from 'src/utilities/constants'
import { handleDraftEditorDrop } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { FAQEditor } from 'src/views/miscellaneous/faqs/components/styledComponents'
import {
  entityFormComponents,
  toolbarOptions,
} from '../../../../config/common/genericListingAndFormConfig'
import { CustomReactSelect } from '../../FormComponents'
import { GenericSwitchesContainer } from '../../styledComponents'
import EntityBasedImageUploadComponent from './EntityBasedImageUploadComponents'

export default function GenericImageForm({
  initialObject = {},
  imageObject,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
}) {
  const editorRef = useRef(null)
  const [store, dispatch] = useAppSettingsStore()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const muiTheme = useTheme()
  const { theme } = useContext(ThemeContext)
  const dropDownOptions = {
    ...store,
    // categories: store.categories,
    // languages: store.languages,
    // tags: store.tags,
    // imageTypes: store.imageTypes,
    // mentalStateTypes: store.mentalStateTypes,
    gender: [
      { id: 'male', name: 'male' },
      { id: 'female', name: 'Female' },
      { id: 'neutral', name: 'Neutral' },
    ],
    subTitleType: [
      { id: 'vtt', name: 'VTT' },
      { id: 'srt', name: 'SRT' },
      { id: 'txt', name: 'Text' },
    ],
    trackType: [
      {
        id: 'audio',
        name: 'Audio',
      },
      {
        id: 'video',
        name: 'Video',
      },
    ],
    statValueType: [
      {
        id: 'number',
        name: 'Number',
      },
      {
        id: 'words',
        name: 'Words',
      },
      {
        id: 'seconds',
        name: 'Time in seconds',
      },
      {
        id: 'days',
        name: 'Time in Days',
      },
    ],
    tagType: [
      {
        id: 'tag',
        name: 'Tag',
        enabled: true,
      },
      {
        id: 'domain',
        name: 'Domain',
        enabled: true,
      },
      {
        id: 'genre',
        name: 'Genre',
        enabled: true,
      },
      {
        id: 'feeling',
        name: 'Feeling',
        enabled: true,
      },
    ],
    fieldTypes: FIELD_TYPES_OBJECT_FIELD_TYPES,
    ...customOptions,
  }

  const entityComponents = entityFormComponents(entity, dropDownOptions)

  const sectionClass = 'row g-3 pb-3'

  const debHandleRichTextChange = debounce(handleRichTextChange, 300)

  function handleEditorState(id, rawId, state) {
    setEditorState(state)
    const rawData = convertToRaw(editorState.getCurrentContent())
    debHandleRichTextChange({
      string: { id: id, value: draftToHtml(rawData) },
      raw: { id: rawId, value: rawData },
    })
  }

  function handleDateTimeChange(id, date) {
    const formattedDate = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    handleChangeObject({
      target: { id: id, value: formattedDate === 'Invalid Date' ? null : formattedDate },
    })
  }

  async function handleEditorDrop(id, rawId, e) {
    handleDraftEditorDrop(e, editorState, (newEditorState) => {
      handleEditorState(id, rawId, newEditorState)
    })
  }

  const generateAndDownloadRTF = () => {
    const rawData = convertToRaw(editorState.getCurrentContent())
    const rtfContent = draftToRTF(rawData) // Convert draft-js content to RTF
    const blob = new Blob([rtfContent], { type: 'application/rtf' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = 'document.rtf' // Set the filename
    document.body.appendChild(link)
    link.click()

    URL.revokeObjectURL(url) // Release the URL
  }

  const generateAndDownloadText = () => {
    const plainText = extractPlainText()
    const blob = new Blob([plainText], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = 'document.txt' // Set the filename
    document.body.appendChild(link)
    link.click()

    URL.revokeObjectURL(url) // Release the URL
  }

  const extractPlainText = () => {
    const contentState = editorState.getCurrentContent()
    const rawContentState = convertToRaw(contentState)

    // Extract and join the text from blocks
    const textArray = rawContentState.blocks.map((block) => block.text)
    const plainText = textArray.join('\n')

    return plainText
  }

  function focusEditor() {
    editorRef.current.editor.focus()
  }

  useEffect(() => {
    if (entityComponents?.richTextInput) {
      const blocksFromHTML = htmlToDraft(
        imageObject?.[entityComponents?.richTextInput?.[0]?.id] || '',
      )
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
        ),
      )
    }
  }, [initialObject])

  return (
    <>
      <GenericSwitchesContainer id="generic-switches" className={sectionClass}>
        {entityComponents?.switches &&
          entityComponents?.switches.map(({ id, label, ...switchProps }) => {
            return (
              <CCol key={id} md={6}>
                <CFormSwitch
                  id={id}
                  label={label}
                  checked={imageObject?.[id]}
                  onChange={handleChangeObject}
                  disabled={disabler(switchProps, disabled, imageObject, initialObject)}
                />
              </CCol>
            )
          })}
      </GenericSwitchesContainer>
      <GenericSwitchesContainer id="generic-inputs" className={sectionClass}>
        {entityComponents?.inputs &&
          entityComponents?.inputs.map((inputProps) => (
            <CCol
              md={inputProps?.col || 12}
              key={inputProps.id}
              // className="d-flex justify-content-start align-items-center gap-2"
            >
              <CFormInput
                {...inputProps}
                value={imageObject?.[inputProps.id] || ''}
                label={inputProps.label + ': '}
                onChange={handleChangeObject}
                disabled={disabler(inputProps, disabled, imageObject, initialObject)}
                invalid={
                  inputProps?.invalid &&
                  inputProps?.invalid(imageObject, allImages, inputProps.id, initialObject)
                }
              />
            </CCol>
          ))}
      </GenericSwitchesContainer>
      <GenericSwitchesContainer id="generic-text-areas" className={sectionClass}>
        {entityComponents?.textArea &&
          entityComponents?.textArea.map((inputProps) => (
            <CCol
              md={inputProps?.col || 12}
              key={inputProps.id}
              // className="d-flex justify-content-start align-items-center gap-2"
            >
              <CFormTextarea
                {...inputProps}
                value={imageObject?.[inputProps.id] || ''}
                label={inputProps.label + ': '}
                onChange={handleChangeObject}
                disabled={disabler(inputProps, disabled, imageObject, initialObject)}
                invalid={
                  inputProps?.invalid && inputProps?.invalid(imageObject, allImages, inputProps.id)
                }
              />
            </CCol>
          ))}
      </GenericSwitchesContainer>
      <GenericSwitchesContainer id="generic-html-selects" className={sectionClass}>
        {entityComponents?.htmlSelect &&
          entityComponents?.htmlSelect.map((formSelectProps) => (
            <CCol md={12} key={formSelectProps.id}>
              <CFormSelect
                {...formSelectProps}
                label={formSelectProps.label + ': '}
                value={imageObject?.[formSelectProps.id]}
                onChange={handleChangeObject}
                disabled={disabler(formSelectProps, disabled, imageObject)}
              >
                <option
                  style={{ display: 'none' }}
                  disabled
                  selected
                  value={'' || undefined || null}
                >
                  {formSelectProps.placeholder}
                </option>
                {dropDownOptions?.[formSelectProps.dropDownOptionId]?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          ))}
      </GenericSwitchesContainer>
      <GenericSwitchesContainer id="generic-react-selects" className={sectionClass}>
        {entityComponents?.reactSelects &&
          entityComponents?.reactSelects.map((selectProps) => {
            return (
              <GenericReactSelectComponent
                key={selectProps.id}
                dropDownOptions={dropDownOptions}
                handleChangeObject={handleChangeObject}
                selectProps={selectProps}
                object={imageObject}
                disabled={disabler(selectProps, disabled, imageObject)}
              />
            )
          })}
      </GenericSwitchesContainer>
      <GenericSwitchesContainer id="generic-time-selectors" className={sectionClass}>
        {entityComponents?.dateTimeInput &&
          entityComponents?.dateTimeInput.map((timeProps) => (
            <CCol md={timeProps.col || 12} key={timeProps.id}>
              <CFormLabel htmlFor={timeProps.id}>{timeProps?.label || 'Select Entity'}:</CFormLabel>
              <CDatePicker
                id={timeProps.id}
                date={
                  imageObject?.[timeProps.id]
                    ? moment
                        .utc(imageObject?.[timeProps.id], 'YYYY-MM-DD HH:mm:ss')
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null
                }
                confirmButton={false}
                cancelButton={false}
                onDateChange={(date) => handleDateTimeChange(timeProps.id, date)}
                timepicker
                required
                cleaner={false}
                disabled={disabler(timeProps, disabled, imageObject)}
              />
            </CCol>
          ))}
      </GenericSwitchesContainer>
      <GenericSwitchesContainer id="nested-inputs" className={sectionClass}>
        {entityComponents?.nestedInputs &&
          Object.entries(entityComponents?.nestedInputs).map(([outerLabel, innerInputs]) => (
            <CCol md={12} key={outerLabel} className="row">
              <h4>{outerLabel}</h4>
              {innerInputs.map((inputProps) => (
                <CCol
                  md={inputProps?.col || 12}
                  key={inputProps.id}
                  // className="d-flex justify-content-start align-items-center gap-2"
                >
                  <CFormInput
                    {...inputProps}
                    value={imageObject?.[inputProps.outerId]?.[inputProps.id] || ''}
                    label={inputProps.label + ': '}
                    onChange={(event) => handleNestedChangeObject(event, inputProps.outerId)}
                    disabled={disabler(inputProps, disabled, imageObject, initialObject)}
                    invalid={
                      inputProps?.invalid &&
                      inputProps?.invalid(imageObject, allImages, inputProps.outerId, inputProps.id)
                    }
                  />
                </CCol>
              ))}
            </CCol>
          ))}
      </GenericSwitchesContainer>
      <GenericSwitchesContainer id="nested-nested-inputs" className={sectionClass}>
        {entityComponents?.nestedNestedInputs &&
          entityComponents?.nestedNestedInputs.map((sectionProps) => (
            <CCol md={12} key={sectionProps.id}>
              <h3>{sectionProps.title}</h3>
              {Object.entries(sectionProps.fields).map(([outerLabel, innerInputs]) => (
                <CCol md={12} key={outerLabel} className="row">
                  {innerInputs?.label && <h4>{innerInputs.label}</h4>}
                  {innerInputs?.value.map((inputProps) => (
                    <CCol md={inputProps?.col || 12} key={inputProps.id}>
                      <CFormInput
                        {...inputProps}
                        value={
                          imageObject?.[sectionProps.id]?.[inputProps.outerId]?.[inputProps.id] ||
                          ''
                        }
                        label={inputProps.label + ': '}
                        onChange={(event) =>
                          handleNestedNestedChangeObject(event, inputProps.outerId, sectionProps.id)
                        }
                        disabled={disabler(inputProps, disabled, imageObject, initialObject)}
                        invalid={
                          inputProps?.invalid &&
                          inputProps?.invalid(
                            imageObject,
                            allImages,
                            inputProps.outerId,
                            inputProps.id,
                          )
                        }
                      />
                    </CCol>
                  ))}
                </CCol>
              ))}
            </CCol>
          ))}
      </GenericSwitchesContainer>
      {entityComponents?.richTextInput && (
        <GenericSwitchesContainer id="rich-text-input" className={`h-100 ${sectionClass}`}>
          {entityComponents?.richTextInput.map((editorProps) => (
            <div
              key={editorProps?.id}
              className="d-flex flex-column justify-content-center gap-2 h-100"
            >
              <FAQEditor
                handlePastedText={() => false}
                onClick={focusEditor}
                onDrop={(e) => handleEditorDrop(editorProps.id, editorProps.rawId, e)}
                theme={theme}
              >
                <Editor
                  handlePastedText={() => false}
                  toolbar={toolbarOptions}
                  wrapperStyle={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  toolbarStyle={{ flexShrink: 0 }}
                  editorStyle={{
                    height: 'auto',
                    zIndex: '99',
                    // '::-webkit-scrollbar': {
                    //   display: 'none',
                    // },
                    // '-ms-overflow-style': 'none',
                    // 'scrollbar-width': 'none',
                  }}
                  wrapperClassName="text-black"
                  editorClassName="my_editor_class"
                  toolbarClassName="wrapper-class text-black"
                  readOnly={disabler(editorProps, disabled, imageObject)}
                  ref={editorRef}
                  editorState={editorState}
                  onEditorStateChange={(state) =>
                    handleEditorState(editorProps.id, editorProps.rawId, state)
                  }
                  placeholder="Write something!"
                />
              </FAQEditor>
            </div>
          ))}
        </GenericSwitchesContainer>
      )}
      <GenericSwitchesContainer id="generic-images" className={sectionClass + ' pt-4'}>
        <EntityBasedImageUploadComponent
          disabled={disabled}
          entity={entity}
          entityObject={imageObject}
          handleChange={handleChangeObject}
        />
      </GenericSwitchesContainer>
    </>
  )
}

function getDateForMoment(date) {
  const today = new Date()
  return !!date ? date : moment.utc(today, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
}

// function draftToRTF(content) {
//   let rtf = `{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033`
//   rtf += `{\\fonttbl{\\f0 Times New Roman;}}`

//   content.blocks.forEach((block) => {
//     rtf += `{\\f0\\fs24 ${block.text}\\par}`
//   })

//   rtf += '}' // Closing RTF tags

//   return rtf
// }

function draftToRTF(rawContentState) {
  let rtfContent = `{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033`
  rtfContent += `{\\fonttbl{\\f0 Times New Roman;}}`

  // Loop through the content blocks in Draft.js
  rawContentState.blocks.forEach((block) => {
    const text = block.text
    rtfContent += '{\\pard\\par}\n' // Start a new paragraph

    // Process inline styles
    block.inlineStyleRanges.forEach((range) => {
      const start = range.offset
      const end = start + range.length
      const style = getRtfStyleForDraftStyle(range.style)

      // Apply the style to the range
      rtfContent += `{\\${style} ${text.slice(start, end)}}`
    })

    // Append the text
    rtfContent += text + '\n'
  })

  rtfContent += '}' // Close the RTF document

  return rtfContent
}

function getRtfStyleForDraftStyle(draftStyle) {
  // Map Draft.js styles to RTF styles
  // This is a simplified example; you may need to expand this mapping
  const styleMap = {
    BOLD: 'b',
    ITALIC: 'i',
    UNDERLINE: 'ul', // Add underline style
    STRIKETHROUGH: 'strike', // Add strike-through style
  }

  return styleMap[draftStyle] || ''
}

function showReactSelect(selectProps, inputObject, dropDownOptions) {
  if (!!selectProps?.viewBasedOn) {
    if (!!selectProps?.viewBasedOnCalculator) {
      return selectProps.viewBasedOnCalculator(
        inputObject?.[selectProps?.viewBasedOn],
        dropDownOptions,
      )
    }
  }
  return true
}

function GenericReactSelectComponent({
  selectProps,
  dropDownOptions,
  object,
  handleChangeObject,
  disabled,
}) {
  const allOptions = dropDownOptions?.[selectProps.dropDownOptionId]
  const selectOptions = useMemo(() => {
    return allOptions?.filter((optn) =>
      filterReactSelectOption(optn, selectProps, object, dropDownOptions),
    )
  }, [dropDownOptions, object])
  return showReactSelect(selectProps, object, dropDownOptions) ? (
    <CCol md={selectProps.col || 12}>
      <CustomReactSelect
        {...selectProps}
        selectType={selectProps?.changeType}
        label={selectProps?.label || 'Select Entity'}
        value={object?.[selectProps.id]}
        isMulti={selectProps?.isMulti !== undefined ? selectProps.isMulti : true}
        options={selectOptions || []}
        disabled={disabled}
        onChangeEvent={handleChangeObject}
      />
    </CCol>
  ) : (
    <></>
  )
}

function filterReactSelectOption(option, selectProps, imageObject, dropDownData) {
  if (option?.enabled === false) {
    return false
  }
  if (!!selectProps?.filterOptionBasedOn) {
    return selectProps.filterOptionBasedOn(
      option,
      imageObject?.[selectProps?.optionsBasedOn],
      dropDownData,
    )
  }
  return true
}

function disabler(inputProps, formDisabled, formData, initalData) {
  if (inputProps.disabledFunction) {
    return inputProps.disabledFunction(formData, initalData) || formDisabled
  }
  return formDisabled
}

GenericReactSelectComponent.propTypes = {
  selectProps: object,
  dropDownOptions: object,
  object: object,
  handleChangeObject: func,
  disabled: bool,
}

GenericImageForm.propTypes = {
  initialObject: PropTypes.object.isRequired,
  imageObject: PropTypes.object,
  handleChangeObject: PropTypes.func,
  handleNestedChangeObject: PropTypes.func,
  handleNestedNestedChangeObject: PropTypes.func,
  handleReactSelectChange: PropTypes.func,
  handleRichTextChange: PropTypes.func,
  handleImageChange: PropTypes.func,
  entity: PropTypes.string,
  allImages: PropTypes.array,
  customOptions: PropTypes.array,
  disabled: PropTypes.bool,
}
