import React, { useState } from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { loadCategoryTypeBasedItems } from '../../utils'

export default function DLSlugItemCourse({
  item,
  handleChangeItem,
  dropDownOptions,
  uiContextData,
  disabled,
}) {
  const categoryType = 'course'

  const [filterLang, setFilterLang] = useState()
  const [categoryFilters, setCategoryFilters] = useState()

  return (
    <>
      <div>
        <CustomReactSelect
          isMulti
          menuPortalTarget={document.body}
          value={filterLang}
          label={'Content Languages'}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.languageName}
          options={dropDownOptions.languages.sort((a, b) =>
            a.languageName.localeCompare(b.languageName),
          )}
          onChangeEvent={(e) => {
            setFilterLang(e.target.value)
            // setCourseOptions()
          }}
          selectType={selectTypes.string}
          placeholder="Select Language"
          disabled={disabled}
        />
      </div>
      <div>
        <CustomReactSelect
          menuPortalTarget={document.body}
          isMulti
          value={categoryFilters}
          label={'Category'}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title}
          options={dropDownOptions.categories
            ?.filter((cat) => cat.categoryType === categoryType)
            ?.sort((a, b) => a?.title.localeCompare(b?.title))}
          onChangeEvent={(e) => {
            setCategoryFilters(e.target.value)
            // setCourseOptions()
          }}
          selectType={selectTypes.string}
          placeholder="Select Language"
          disabled={disabled}
        />
      </div>
      <div>
        <label>{uiContextData?.linkIdSelectionLabel || 'Item'}</label>
        <ActivityItemAsyncSelect
          cacheUniqs={[categoryType, filterLang]}
          classNamePrefix="activity-item-select"
          menuPortalTarget={document.body}
          value={item?.value ? item : null}
          loadOptions={loadCategoryTypeBasedItems}
          debounceTimeout={1000}
          additional={{
            page: 0,
            size: 200,
            metaCategoryTypeId: categoryType,
          }}
          onChange={(selVal) => handleChangeItem(selVal)}
          isDisabled={disabled}
        />
      </div>
    </>
  )
}
