import { OpenInNew } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { openInNewTab } from 'src/utilities/generalUtils'

export default function OpenTilePolicyInNewTab({
  tileObject,
  tabId,
  openButtonRenderFunction = (props) => (
    <IconButton {...props}>
      <OpenInNew />
    </IconButton>
  ),
  loadingComponentRenderFunction = () => (
    <div style={{ padding: '8px', display: 'flex' }}>
      <CircularProgress
        sx={{ height: '24px !important', width: '24px !important', flexShrink: 0 }}
        color="secondary"
      />
    </div>
  ),
}) {
  const [loading, setLoading] = useState(false)
  const buttonProps = tileObject
    ? {
        href: `${window.location.origin}/setup/tiles/${tileObject?.id}`,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : { onClick: openDefaultTilePolicyInNewTab }

  async function openDefaultTilePolicyInNewTab() {
    setLoading(true)

    const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.tilePolicy,
      category: tabId,
      active: true,
    })
    if (response.status === 200) {
      if (response.data?.content?.length > 0) {
        openInNewTab(
          `${window.location.origin}/setup/tiles/${response.data?.content?.[0]?.id}?mode=View`,
        )
      } else {
        toast.error(`No default tile policy found for ${tabId} tab`)
      }
    }
    setLoading(false)
  }

  return <>{loading ? loadingComponentRenderFunction() : openButtonRenderFunction(buttonProps)}</>
}
