import { toast } from 'react-toastify'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { postFunctionSettings } from 'src/config/genericForm/post/postDataConfig'
import { transformUploadObject } from 'src/config/genericForm/post/transformUploadObject'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'

export async function getApiData(
  url,
  getFunction,
  setData,
  params = {},
  setterStructure = false,
  setKey = 'all',
  type = 'list',
) {
  const response = await getFunction(url, params)
  if (response.status === 200) {
    setData((prev) => {
      if (setterStructure) {
        return { ...prev, [setKey]: type === 'paginated' ? response.data.content : response.data }
      } else {
        return type === 'paginated' ? response.data.content : response.data
      }
    })
  } else {
    toast.error('Something went wrong')
  }
}

export async function getAndValidateApiData(
  url,
  getFunction,
  setData,
  sampleObject,
  uniqueID,
  uniqueIDField,
  setAllObjects,
  params = {},
) {
  const response = await getFunction(url, params)
  if (response.status === 200) {
    setAllObjects !== undefined && setAllObjects(response.data)
    const foundData = response.data.find((item) => {
      return item[uniqueIDField] === uniqueID
    })
    const dataToSet = Object.entries(sampleObject).reduce((accum, [key, val]) => {
      if (accum[key] === undefined) {
        accum[key] = val
      }
      return accum
    }, foundData)
    setData(dataToSet)
  }
}

export async function SaveEntity(
  entity,
  data,
  refreshList = () => {},
  closeFunction = () => {},
  storeData = {},
  showToast = true,
) {
  const config = postFunctionSettings(entity, data, {
    dropDownData: { ...storeData },
    refreshList,
    closeFunction,
    showToast,
    defaultApiSignature: GENERIC_APIS.saveEntity.generic_without_slash,
    defaultParams: { entityType: entity },
  })

  const tst = showToast ? toast.loading('Saving Entity...') : {}
  const transformedData = await transformUploadObject(entity, data, { ...storeData })
  const postApiConfig = config.functionAndParams
  const response = await PostSettingsData(
    postApiConfig.url,
    transformedData,
    postApiConfig.params,
    tst,
  )
  console.log(response.status)
  if (response.status === 200) {
    await refreshList()
    await config?.afterSaveScript(response.data, data)
    closeFunction(response.data)
    return response
  }
  return response
}

export async function GetDetailedEntity(
  entity,
  data,
  getURL = GENERIC_APIS.entity.details,
  customParams = {},
) {
  const response = await GetSettingsData(getURL, {
    entityType: entity,
    id: data.id,
    ...customParams,
  })
  if (response) {
    return response.data
  }
}
export async function EnableDisableEntity(entity, data, refreshList = () => {}, closeFunction) {
  var apiData = {
    enable: true,
    label: 'Enable',
    url: GENERIC_APIS.entity.enable,
  }
  if (data.enabled) {
    apiData = { enable: false, label: 'Disable', url: GENERIC_APIS.entity.disable }
  } else {
    apiData = { enable: true, label: 'Enable', url: GENERIC_APIS.entity.enable }
  }
  const tst = await toast.loading(`${apiData.label}ing Entity...`)
  const response = await GetSettingsData(apiData.url, { entityType: entity, id: data?.id })
  if (response.status === 200) {
    toast.update(tst, {
      render: `Entity ${apiData.label}ed Successfully`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      ...TOAST_UTILS,
    })
    await refreshList()
    closeFunction && closeFunction()
    return response
  }
  return response
}
