import React from 'react'
import { CCTextArea } from 'src/components/controlCenter/FormComponents'

export default function HyperLinkComponent({ externalLinksData, handleChangeExternalLinks }) {
  return (
    <div className="w-100 h-100 d-flex flex-column gap-1">
      <CCTextArea
        id="uri"
        label="URI (with protocol)"
        value={externalLinksData.uri}
        onChange={handleChangeExternalLinks}
        placeholder="Enter the uri..."
        colNumber={12}
      />
    </div>
  )
}
