import React from 'react'
import { currency_list } from 'src/utilities/constants'

export const ShowPricingDetails = ({
  priceToUse,
  getCurrencyId = (priceToUse) => priceToUse?.currencyId,
  getPrice = (priceToUse) => priceToUse?.price,
}) => {
  const foundCurrency = currency_list.find(
    (currency) => currency.code === getCurrencyId(priceToUse)?.toLowerCase(),
  )
  return (
    <>
      {!priceToUse ? (
        <div>No Data</div>
      ) : (
        <>
          {foundCurrency?.symbol || getCurrencyId(priceToUse)} {getPrice(priceToUse)}
        </>
      )}
    </>
  )
}
