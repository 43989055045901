import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const META_PLAN_GROUPS = {
  id: '',
  apiParam: ALL_ENTITY_TYPES_FROM_CONSTANTS.planGroups,
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  slug: '',
  name: '',
  active: true,
}
