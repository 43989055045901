import { Edit, OpenInNew } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { openInNewTab } from 'src/utilities/generalUtils'

export default function EditAndViewButtons({ uri, openDialog, hoverTitle, disabled }) {
  return (
    <div className="flex-shrink-0">
      {uri && (
        <Tooltip title={hoverTitle}>
          <IconButton
            onClick={() => {
              openInNewTab(uri)
            }}
          >
            <OpenInNew />
          </IconButton>
        </Tooltip>
      )}
      {!disabled && (
        <IconButton onClick={openDialog}>
          <Edit />
        </IconButton>
      )}
    </div>
  )
}
