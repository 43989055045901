import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import { defaultLinkMakerContextData } from 'src/components/controlCenter/linksMaker/LinksMakerComponents'
import { IdTypes } from 'src/utilities/constants'
import DLTypeCourse from './components/DLTypeCourse'
import DLTypeTag from './components/DLTypeTag'
import DLTypeTeacher from './components/DLTypeTeacher'
import DLTypeTiles from './components/DLTypeTiles'

export default function DLType({
  categoryType,
  item,
  handleChangeItem,
  dropDownOptions,
  setAdditionalData,
  additionalData,
  uiContextData,
}) {
  const ComponentToRender = useMemo(() => {
    switch (categoryType) {
      case 'course':
        return DLTypeCourse
      case 'tag':
        return DLTypeTag
      case 'tiles':
        return DLTypeTiles
      case 'service-provider':
        return DLTypeTeacher
      default:
        return React.Fragment
    }
  }, [categoryType])

  function changeAdditionalItem(selVal) {
    if (!selVal) {
      setAdditionalData((prev) => {
        return {
          ...prev,
          itemId: defaultLinkMakerContextData.additionalData.itemId,
          itemTitle: defaultLinkMakerContextData.additionalData.itemTitle,
        }
      })
      return
    }

    setAdditionalData((prev) => {
      let idToUse = null
      const idType = prev?.idType

      if (idType === IdTypes.id) {
        idToUse = selVal?.value
      }

      if (idType === IdTypes.slug) {
        idToUse = selVal?.slug
      }

      if (idType === IdTypes.slug) {
        if (!idToUse) {
          toast.warn('This item may not have a slug')
          return prev
        }
      }

      if (!idToUse) {
        return prev
      }

      return {
        ...prev,
        itemId: selVal.value,
        itemTitle: selVal.label,
      }
    })
  }

  return (
    <ComponentToRender
      {...{
        categoryType,
        item,
        handleChangeItem,
        dropDownOptions,
        setAdditionalData,
        additionalData: {
          ...additionalData,
          ...(!!additionalData?.itemId
            ? { value: additionalData.itemId, label: additionalData?.itemTitle }
            : { value: null, label: null }),
        },
        changeAdditionalItem,

        uiContextData,
      }}
    />
  )
}

function NotSupportedText() {
  return <div>Not Supported</div>
}
