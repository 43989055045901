import { convertDLMetaCategoryTypeToMetaCategoryType } from 'src/common/utils/categoryType/converters'
import { defaultLinkMakerContextData } from '../../../../LinksMakerComponents'
import { getExtraItemTitlesForAnyIdType } from './fetchForExtraItem/factory'
import { getItemTitlesForAnyIdType } from './fetchTitles/factory'

export function splitDeepLinkURI(uri) {
  const uriParts = uri?.split('/')
  const cleanedUriParts = uriParts.filter((part) => part !== 'extra')
  let initUriNumber = 2
  let hasExtra = false

  let additionalIdType = null
  let additionalItemId = null

  let extraItemIdType = null
  let extraItemItemId = null
  let extraItemCategoryType = null

  if (uriParts?.includes('extra')) {
    hasExtra = true
  }

  //usable uri parts start from index 2
  const metaCategoryTypeId = cleanedUriParts?.[initUriNumber] || ''
  const itemId = cleanedUriParts?.[initUriNumber + 2] || ''
  const idType = cleanedUriParts?.[initUriNumber + 1] || ''

  if (hasExtra) {
    extraItemCategoryType = cleanedUriParts?.[initUriNumber + 3] || null
    extraItemIdType = cleanedUriParts?.[initUriNumber + 4] || null
    extraItemItemId = cleanedUriParts?.[initUriNumber + 5] || null
  } else {
    additionalIdType = cleanedUriParts?.[initUriNumber + 3] || null
    additionalItemId = cleanedUriParts?.[initUriNumber + 4] || null
  }
  return {
    metaCategoryTypeId,
    itemId,
    idType,

    additionalIdType,
    additionalItemId,

    extraItemIdType,
    extraItemItemId,
    extraItemCategoryType,

    hasExtra,
  }
}

export function initURIContextDataForDeepLinks(externalLinksData, metaCategoryTypes) {
  const {
    metaCategoryTypeId,
    itemId,
    idType,

    additionalIdType,
    additionalItemId,

    extraItemIdType,
    extraItemItemId,
    extraItemCategoryType,

    hasExtra,
  } = splitDeepLinkURI(externalLinksData.uri)
  let defaultDlData = {
    ...defaultLinkMakerContextData,
    additionalData: { ...defaultLinkMakerContextData.additionalData },
    extraData: { ...defaultLinkMakerContextData.extraData },
  }

  defaultDlData.metaCategoryId = metaCategoryTypes?.find((catType) => {
    const convertedDlCatType = convertDLMetaCategoryTypeToMetaCategoryType(metaCategoryTypeId)
    return catType?.id === convertedDlCatType
  })?.id

  defaultDlData.itemId = itemId
  defaultDlData.idType = idType

  if (hasExtra) {
    defaultDlData.extraData = {
      metaCategoryId: extraItemCategoryType,
      itemId: extraItemItemId,
      idType: extraItemIdType,
    }
  } else {
    defaultDlData.additionalData.idType = additionalIdType
    defaultDlData.additionalData.itemId = additionalItemId
  }
  return defaultDlData
}
export async function initURIContextDataTitleForDeepLinks(linkMakerState, metaCategoryTypes) {
  const outerItemId = linkMakerState?.itemId || null
  const additionalItemId = linkMakerState?.additionalData?.itemId || null
  const extraItemId = linkMakerState?.extraData?.itemId || null

  let baseItem = null

  let outerItemTitle = null
  let additionalItemTitle = null
  let extraItemTitle = null

  if (outerItemId) {
    const responseData = await getItemTitlesForAnyIdType(
      outerItemId,
      linkMakerState.idType,
      linkMakerState.metaCategoryId,
    )
    // const responseData = await getCuratedCardRefsBulk(
    //   [outerItemId],
    //   linkMakerState.metaCategoryId, //this is actually the metaCategoryType
    //   linkMakerState.itemId,
    // )
    baseItem = responseData?.[0]
    outerItemTitle = responseData?.[0]?.label || ''
  }

  if (additionalItemId) {
    const responseData = await getItemTitlesForAnyIdType(
      additionalItemId,
      linkMakerState.additionalData.idType,
      linkMakerState.metaCategoryId,
    )
    // const responseData = await getCuratedCardRefsBulk(
    //   [additionalItemId],
    //   linkMakerState.additionalData.idType, //this is actually the metaCategoryType
    //   linkMakerState.additionalData.itemId,
    // )
    additionalItemTitle = responseData?.[0]?.label || ''
  }
  if (extraItemId) {
    const responseData = await getExtraItemTitlesForAnyIdType(
      outerItemId,
      linkMakerState.idType,
      linkMakerState.metaCategoryId,
      extraItemId,
      linkMakerState.extraData.metaCategoryId,
      linkMakerState.extraData.idType,
      baseItem,
    )
    extraItemTitle = responseData?.[0]?.label || ''
  }

  return {
    itemTitle: outerItemTitle,
    additionalData: { itemTitle: additionalItemTitle },
    extraData: { itemTitle: extraItemTitle },
  }
}
