import { Delete } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import React from 'react'
import { CCFormInput } from 'src/components/controlCenter/FormComponents'

export default function AgeGroupsComponent({ ageGroup, changeAgeGroup, deleteAgeGroup, disabled }) {
  return (
    <>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="minAge"
          type="number"
          value={ageGroup?.minAge}
          onChange={changeAgeGroup}
          colNumber={12}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="maxAge"
          type="number"
          value={ageGroup?.maxAge}
          onChange={changeAgeGroup}
          colNumber={12}
          disabled={disabled}
        />
      </Grid>
      {deleteAgeGroup && !disabled && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="error" startIcon={<Delete />} onClick={deleteAgeGroup}>
            Delete
          </Button>
        </Grid>
      )}
    </>
  )
}
