import { ONLINE_COURSE_EPISODE_OBJECT } from 'src/common/types/onlineCourse/onlineCourseEpisodeObject'
import {
  HLS_TRACK_TYPES_MAP,
  ONLINE_COURSE_MEDIA_TYPES_ARRAY,
  ONLINE_COURSE_TYPES,
} from 'src/utilities/constants'

import {
  getNumberOfSessionsMatchingToNumberOfEpisodes,
  SUPPORTED_FIELD_TYPES,
  SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES,
  SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES_VALUES,
} from './components/OnlineCourseFieldsHandler/components/FieldTypesComponents/utils'

export function autoHandleOnlineCourseObject(onlineCourseObject) {
  //overall duration handle
  var duration = onlineCourseObject?.duration
  const sessions = onlineCourseObject?.sessions || [
    {
      ...ONLINE_COURSE_EPISODE_OBJECT,
      title: onlineCourseObject?.title,
      shortDesc: onlineCourseObject?.shortDesc,
      longDesc: onlineCourseObject?.longDesc,
      coverIcon: onlineCourseObject?.coverIcon,
      coverImage: onlineCourseObject?.coverImage,
      verticalRectangle: onlineCourseObject?.verticalRectangle,
      blogBanner: onlineCourseObject?.blogBanner,
      backdropImage: onlineCourseObject?.backdropImage,
    },
  ]

  duration = sessions?.reduce((acc, session) => {
    return acc + (session?.steps?.meditate?.track?.duration || 0)
  }, 0)

  return {
    ...onlineCourseObject,
    noOfEpisode: onlineCourseObject?.noOfEpisode || 1,
    duration,
  }
}

function getTheCorrectField(fieldType, sections, fields) {
  return fields.find((field) => field.fieldType === fieldType && !!field?.sectionRefId)
}

function getTheCorrectFieldFromObjectFieldId(fieldType, sections, fields, objectFieldId) {
  function getParentSection(section, allSections) {
    if (!section?.parentSectionId) {
      return section
    }
    return getParentSection(
      allSections.find((sec) => sec.sectionId === section.parentSectionId),
      allSections,
    )
  }

  const relatedFields = fields.filter((field) => {
    return field?.fieldType === fieldType
  })

  const relatedSections = sections?.filter((sec) => {
    return relatedFields?.some((field) => {
      return field?.sectionRefId === sec?.sectionId
    })
  })

  const sectionToUse = relatedSections?.find((sec) => {
    const parentSection = getParentSection(sec, sections)
    return parentSection?.objectFieldId === objectFieldId
  })

  return relatedFields.find((field) => field.sectionRefId === sectionToUse?.sectionId)
}

export function getSessionTypeFromTrackType(sessionType) {
  return sessionType === ONLINE_COURSE_MEDIA_TYPES_ARRAY?.[0]?.id
    ? HLS_TRACK_TYPES_MAP?.hls_video
    : HLS_TRACK_TYPES_MAP?.hls_audio
}

export function onlineCourseValidation(onlineCourseObject, argSections, argFields, slugValidation) {
  let invalidEpisodes = []
  let courseErrors = []
  let sessionErrors = []

  const sections = argSections?.filter((section) => !!section?.enabled)
  const fields = argFields?.filter((field) => !!field?.enabled)

  const makeAllMandatory = false

  if (!onlineCourseObject.title) {
    const titleSection = getTheCorrectField('title', sections, fields)
    if (titleSection?.mandatory || makeAllMandatory) {
      courseErrors.push({ field: 'title', error: 'Required ' })
    }
  }
  if (!onlineCourseObject.internalTitle) {
    const internalTitleSection = getTheCorrectField('internalTitle', sections, fields)

    if (internalTitleSection?.mandatory || makeAllMandatory) {
      courseErrors.push({ field: 'internalTitle', error: 'Required ' })
    }
  }
  if (!onlineCourseObject.shortDesc) {
    const shortDescSection = getTheCorrectField('shortDesc', sections, fields)
    if (shortDescSection?.mandatory || makeAllMandatory) {
      courseErrors.push({ field: 'shortDesc', error: 'Required' })
    }
  }
  if (!onlineCourseObject.longDesc) {
    const longDescSection = getTheCorrectField('longDesc', sections, fields)

    if (longDescSection?.mandatory || makeAllMandatory) {
      courseErrors.push({ field: 'longDesc', error: 'Required' })
    }
  }
  if (!onlineCourseObject.publishAt) {
    const publishAtSection = getTheCorrectField('publishDate', sections, fields)

    if (publishAtSection?.mandatory || makeAllMandatory) {
      courseErrors.push({ field: 'publishDate', error: 'Required' })
    }
  }
  if (slugValidation?.unavailable || !slugValidation?.valid) {
    const slugSection = getTheCorrectField('slug-input', sections, fields)

    if (slugSection?.mandatory || makeAllMandatory) {
      courseErrors.push({ field: 'slug-input', error: 'Required' })
    }
  }

  for (var i = 0; i < onlineCourseObject.sessions?.length; i++) {
    var steps = onlineCourseObject.sessions[i].steps
    var errors = []
    const parentObjectId = 'sessions'

    if (!onlineCourseObject.sessions[i].title) {
      const sessionTitleFieldType = getTheCorrectFieldFromObjectFieldId(
        'title',
        sections,
        fields,
        parentObjectId,
      )
      if (sessionTitleFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'title', error: 'Required ' })
      }
    }
    if (!onlineCourseObject.sessions[i].shortDesc) {
      const sessionShortDescFieldType = getTheCorrectFieldFromObjectFieldId(
        'shortDesc',
        sections,
        fields,
        parentObjectId,
      )
      if (sessionShortDescFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'shortDesc', error: 'Required ' })
      }
    }
    if (!onlineCourseObject.sessions[i].longDesc) {
      const sessionLongDescFieldType = getTheCorrectFieldFromObjectFieldId(
        'longDesc',
        sections,
        fields,
        parentObjectId,
      )

      if (sessionLongDescFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'longDesc', error: 'Required ' })
      }
    }
    if (onlineCourseObject.sessions[i].primaryTags?.length === 0) {
      const sessionPrimaryTagsFieldType = getTheCorrectFieldFromObjectFieldId(
        'primaryTags',
        sections,
        fields,
        parentObjectId,
      )

      if (sessionPrimaryTagsFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'primaryTags', error: 'Required' })
      }
    }
    if (
      steps.meditate.enabled &&
      (steps.meditate.track.url === '' ||
        steps.meditate.track.url === undefined ||
        steps.meditate.track.url === null)
    ) {
      const sessionTrackFieldType = getTheCorrectFieldFromObjectFieldId(
        'track',
        sections,
        fields,
        parentObjectId,
      )
      if (sessionTrackFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'meditateUrl', error: 'Required' })
      }
    }

    if (!steps.meditate.track.url) {
      const sessionTrackFieldType = getTheCorrectFieldFromObjectFieldId(
        'track',
        sections,
        fields,
        parentObjectId,
      )
      if (sessionTrackFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'meditateUrl', error: 'Invalid Url' })
      }
    }

    if (!onlineCourseObject.sessions[i]?.sessionType) {
      errors.push({ field: 'sessionType', error: 'Required' })
    }

    const hlsTrackTypeForTrack = getSessionTypeFromTrackType(
      onlineCourseObject.sessions[i]?.sessionType,
    )

    if (steps?.meditate?.track?.type !== hlsTrackTypeForTrack) {
      errors.push({ field: 'track', error: 'Track type does not match session type' })
    }

    if (steps.preparatory.track.url !== '') {
      const prepFieldType = getTheCorrectField(
        SUPPORTED_FIELD_TYPES.preparatoryTrack,
        sections,
        fields,
      )
      if (prepFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'preparatoryUrl', error: 'Invalid Url' })
      }
    }
    if (steps.postInstructions.track.url !== '') {
      const postInstructionsFieldType = getTheCorrectField(
        SUPPORTED_FIELD_TYPES.postInstructionsTrack,
        sections,
        fields,
      )
      if (postInstructionsFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'postInstructionsUrl', error: 'Invalid Url' })
      }
    }
    if (steps.preInstructions.track.url !== '') {
      const preInstructionsFieldType = getTheCorrectField(
        SUPPORTED_FIELD_TYPES.preInstructionsTrack,
        sections,
        fields,
      )
      if (preInstructionsFieldType?.mandatory || makeAllMandatory) {
        errors.push({ field: 'preInstructionsUrl', error: 'Invalid Url' })
      }
    }

    if (errors?.length) {
      var episodeTitle = onlineCourseObject?.sessions[i]?.title || ''
      invalidEpisodes.push(episodeTitle)
    }
    sessionErrors[i] = errors
  }

  if (onlineCourseObject?.type === ONLINE_COURSE_TYPES?.[1]?.id) {
    if (
      onlineCourseObject?.sessions?.length < onlineCourseObject?.noOfEpisode ||
      onlineCourseObject?.noOfEpisode <= 0
    ) {
      sessionErrors.push({ field: 'track', error: 'Required' })
    }
  } else {
    if (onlineCourseObject?.sessions?.length === 0) {
      sessionErrors.push({ field: 'track', error: 'Required' })
    }
  }

  return { courseErrors, sessionErrors, invalidEpisodes }
}

export function getToSaveOnlineCourse(courseObject, currentCourseCategory, sliceSessions = true) {
  let sessionsToSet = getNumberOfSessionsMatchingToNumberOfEpisodes(
    courseObject?.noOfEpisode,
    courseObject?.sessions,
    currentCourseCategory,
    courseObject?.createdFor,
  )

  sessionsToSet =
    courseObject?.type === ONLINE_COURSE_TYPES?.[0]?.id
      ? [
          {
            ...(sessionsToSet?.[0] || ONLINE_COURSE_EPISODE_OBJECT),
            title: courseObject?.title,
            shortDesc: courseObject?.shortDesc,
            longDesc: courseObject?.longDesc,
            coverIcon: courseObject?.coverIcon,
            coverImage: courseObject?.coverImage,
            verticalRectangle: courseObject?.verticalRectangle,
            blogBanner: courseObject?.blogBanner,
            backdropImage: courseObject?.backdropImage,
          },
        ]
      : sessionsToSet?.slice(0, courseObject?.noOfEpisode) || []

  sessionsToSet = sessionsToSet.map((session) => {
    let trackList = []
    SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES_VALUES.forEach((trackType) => {
      if (session?.steps?.[trackType]?.track?.url) {
        trackList.push(
          trackType === SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES?.meditation?.id
            ? { ...session?.steps?.[trackType], mainTrack: true }
            : session?.steps?.[trackType],
        )
      }
    })

    return {
      ...session,
      steps: {
        ...(session?.steps || {}),
        trackList,
        meditate: { ...(session?.steps?.meditate || {}), mainTrack: true },
      },
    }
  })

  return {
    ...courseObject,
    category: [
      ...(courseObject?.category || [])?.filter((cat) => cat !== currentCourseCategory),
      currentCourseCategory,
    ],
    noOfEpisode:
      courseObject?.type === ONLINE_COURSE_TYPES?.[0]?.id ? 1 : courseObject?.noOfEpisode,
    sessions: sliceSessions ? sessionsToSet : courseObject?.sessions,
  }
}

export function getActionButtonViewStatusForOnlineCourse(formData, m) {
  const mode = m || 'View'

  let viewStatus = {
    moveToViewButton: false,
    saveAsDraft: false,
    edit: false,
    sendForApproval: false,
    cancelApproval: false,
    approveCourse: false,
    publishCourse: false,
    requestToUnpublish: false,
    acceptRequestToUnpublish: false,
    rejectRequestToUnpublish: false,
    disapproveOnlineCourse: false,
    cancel: false,
    moveToView: false,
    createDraftFromUnPublished: false,
    cancelDraft: false,
  }

  if (mode !== 'View') {
    if (formData?.status === 'draft') {
      viewStatus.cancelDraft = true
      viewStatus.sendForApproval = true
    }
    if (
      formData?.status === 'draft' ||
      formData?.status === 'unpublished' ||
      formData?.status === 'rejected'
    ) {
      viewStatus.saveAsDraft = true
    }

    viewStatus.cancel = true
    viewStatus.moveToView = true
  }
  if (mode === 'View') {
    if (formData?.reqToUnpublish) {
      viewStatus.acceptRequestToUnpublish = true
      viewStatus.rejectRequestToUnpublish = true
    }

    switch (formData?.status) {
      case 'draft':
        viewStatus.edit = true
        viewStatus.sendForApproval = true
        break
      case 'rejected':
        viewStatus.edit = true
        break
      case 'pending':
        //this step will viewed only the admin
        viewStatus.approveCourse = true
        viewStatus.disapproveOnlineCourse = true
        viewStatus.cancelApproval = true
        break
      case 'unpublished':
        // viewStatus.createDraftFromUnPublished = true
        viewStatus.publishCourse = true
        break
      case 'approved':
        // viewStatus.edit = true
        viewStatus.publishCourse = true
        break
      case 'published':
        if (!formData?.reqToUnpublish) viewStatus.requestToUnpublish = true
        break
      default:
        break
    }
  }

  return viewStatus
}

export function filterFormSectionsTester(section) {
  return !section?.parentSectionId && (section?.ref?.length === 0 || !section?.ref)
}

/*  if(onlineCourseObject.sessions[i].secondaryTags.length===0){
       errors.push({field:"secondaryTags",error:"Required"});
     } */
/*  if(onlineCourseObject.sessions[i].postures.length===0){
       errors.push({field:"postures",error:"Required"});
     } */

/*  if(steps.preInstructions.enabled&&
         (steps.preInstructions.track.url===''||steps.preInstructions.track.url===undefined||steps.preInstructions.track.url===null)){
       errors.push({field:"preInstructionsUrl",error:"Required"});
     }
     if(steps.postInstructions.enabled&&
         (steps.postInstructions.track.url===''||steps.postInstructions.track.url===undefined||steps.postInstructions.track.url===null)){
       errors.push({field:"postInstructionsUrl",error:"Required"});
     }
     if(steps.preparatory.enabled&&
         (steps.preparatory.track.url===''||steps.preparatory.track.url===undefined||steps.preparatory.track.url===null)){
       errors.push({field:"preparatoryUrl",error:"Required"});
     } */

/* if(onlineCourseObject.sessions[i].duration===''||onlineCourseObject.sessions[i].duration<=0){
       errors.push({field:"duration",error:"Required and greater then zero"});
     } */
/* if(onlineCourseObject.sessions[i].meditationIds.length===0){
       errors.push({field:"meditations",error:"Required"});
     } */
// if(onlineCourseObject.coverIcon===''||onlineCourseObject.coverIcon===null){
//   var item = self.images[Math.floor(Math.random() * self.images.length)];
//   onlineCourseObject.coverIcon = item.imageId;
// }
// if(onlineCourseObject.coverImage===''||onlineCourseObject.coverImage===null){
//   var item = self.images[Math.floor(Math.random() * self.images.length)];
//   onlineCourseObject.coverImage = item.imageId;
// }

// console.log('in validaton: onlineCourse.sessions.length is:', onlineCourseObject.sessions?.length)
