import { HLS_TRACK_TYPES_MAP, ONLINE_COURSE_MEDIA_TYPES_ARRAY } from 'src/utilities/constants'

const ONLINE_COURSE_TRACK_OBJECT = {
  duration: 0,
  fileId: '',
  slide: null,
  slides: [],
  subtitleFileId: '',
  type: HLS_TRACK_TYPES_MAP.hls_video,
  url: '',
}

export const ONLINE_COURSE_EPISODE_OBJECT = {
  allowPreview: false,
  bgMusicEnabled: false,
  bgMusicId: '',
  category: [],
  contributors: [],
  courseId: '',
  coverIcon: '',
  coverIconColor: '',
  coverIconHasText: false,
  coverImage: '',
  coverImageColor: '',
  createdFor: '',
  duration: 0,
  enableJournal: false,
  feelings: [],
  internalNotes: '',
  internalTags: [],
  journalId: '',
  longDesc: '',
  meditationIds: [],
  postures: [],
  preferredtimes: [],
  primaryTags: [],
  releaseAfter: 0,
  releaseImmediately: false,
  secondaryTags: [],
  sessionId: '',
  sessionType: ONLINE_COURSE_MEDIA_TYPES_ARRAY?.[0]?.id,
  shortDesc: '',
  steps: {
    actions: {
      enabled: true,
      mandatory: true,
    },
    feedback: {
      comment: '',
      compliments: {
        attitude: 0,
        instructions: 0,
        onTime: 0,
        peace: 0,
        voiceQuality: 0,
      },
      enabled: true,
      mandatory: true,
      posture: '',
      rating: 0,
      reasons: [],
    },
    meditate: {
      enabled: true,
      mandatory: true,
      recording: false,
      mainTrack: true,
      track: ONLINE_COURSE_TRACK_OBJECT,
      trackEditable: true,
    },
    notes: {
      enabled: true,
      mandatory: true,
    },
    postInstructions: {
      enabled: true,
      mandatory: true,
      track: ONLINE_COURSE_TRACK_OBJECT,
      trackEditable: true,
    },
    preInstructions: {
      enabled: true,
      mandatory: true,
      track: {
        duration: 0,
        fileId: '',
        slide: null,
        slides: [],
        subtitleFileId: '',
        type: '',
        url: '',
      },
      trackEditable: true,
    },
    preparatory: {
      enabled: true,
      mandatory: true,
      track: ONLINE_COURSE_TRACK_OBJECT,
      trackEditable: true,
    },
    result: {
      enabled: true,
      mandatory: true,
    },
  },
  theme: {
    colors: {},
    coverIcon: '',
    coverIconColor: '',
    coverImage: '',
    coverImageColor: '',
    enabled: true,
    images: {},
    videos: {},
  },
  title: '',
  verticalRectangle: '',
  verticalRectangleColor: '',
  videoOrientation: '',
}
