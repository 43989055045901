import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'

export async function transformOnlineCourseListingData({ listingData }) {
  let ocUpdatedList = await getApprovedByUserNames(listingData)

  var relatedOnlineCoursesToTheseCourseDrafts = []
  if (ocUpdatedList.length > 0) {
    relatedOnlineCoursesToTheseCourseDrafts = await getRelatedDraftsToTheseOnlineCourses(
      ocUpdatedList,
    )
  }

  console.log({ relatedOnlineCoursesToTheseCourseDrafts })

  return relatedOnlineCoursesToTheseCourseDrafts?.map((onlineCourse) => {
    return {
      ...onlineCourse,
      _props: {
        style: {
          border: onlineCourse?.status === 'draft' ? '2px solid #ecab14' : 'unset',
        },
      },
    }
  })
}

async function getApprovedByUserNames(ocList) {
  const userIdsToUse = ocList?.map((onlineCourse) => onlineCourse?.approvedBy)
  const foundUsers = await getUsersSummaryFromUserIds(userIdsToUse)
  return ocList?.map((onlineCourse) => {
    const foundUser = foundUsers?.find((user) => user?.userId === onlineCourse?.approvedBy)
    return { ...onlineCourse, approvedBy: foundUser?.name }
  })
}

async function getRelatedDraftsToTheseOnlineCourses(onlineCourses) {
  const onlineCourseIds = onlineCourses?.map((oc) => oc?.id)
  const foundCourseDraftResp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.courseDraft,
    size: 999,
    courseIds: onlineCourseIds?.join(','),
  })
  if (foundCourseDraftResp?.status !== 200) return onlineCourses
  return onlineCourses?.map((oc) => {
    const foundOnlineCourseDraft = foundCourseDraftResp?.data?.content?.find(
      (courseDraft) => courseDraft?.onlineCourse?.id === oc?.id,
    )
    return {
      ...oc,
      relatedDraft: foundOnlineCourseDraft,
    }
  })
}
