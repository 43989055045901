export const APIs = {
  dailyPlaySettings: {
    url: 'get-daily-play/settings',
  },
  dailyPlayContent: {
    url: 'daily-play',
  },
  allLanguages: {
    url: 'languages',
  },
  allCountries: {
    url: 'countries',
  },
  contentCategories: {
    url: 'content-categories',
  },
  journals: {
    url: 'journals',
  },
  quotes: {
    url: 'feature/quotes',
  },
  breathePrograms: {
    url: 'breathe-programs',
  },
  meditations: {
    url: 'meditation-techniques',
  },
  paginatedEntitySummary: { url: 'entity/paginated/summary' },
  episodes: {
    url: 'course-episodes',
  },
  timeSlots: {
    url: 'timeslots/summary',
  },
}
