import React from 'react'
import { LINK_TYPES } from 'src/utilities/constants'
import { genericHandleChange, giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { buildURI } from '../buildFInalURIs'
import DeepLinkBuilder from './DeepLinkBuilder'

export default function DeepLinkComponent({
  externalLinksData,
  externalLinksContextData,
  setExternalLinksContextData,
  handleChangeExternalLinks,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const dropDownOptions = { ...store }

  function handleChangeExternalLinksData(eve) {
    genericHandleChange(eve, setExternalLinksContextData)
  }

  function handleChangeAdditionalData(eve) {
    setExternalLinksContextData((prev) => {
      return {
        ...prev,
        additionalData: giveGenericHandleChangedData(eve, prev.additionalData),
      }
    })
  }

  function handleChangeExtraData(eve) {
    setExternalLinksContextData((prev) => {
      return {
        ...prev,
        extraData: giveGenericHandleChangedData(eve, prev.extraData),
      }
    })
  }

  return (
    <div className="w-100 h-100 d-flex flex-column gap-1 my-3">
      <div className="w-100 d-flex flex-column align-items-center gap-1">
        <strong>Formed URI</strong>
        <span style={{ textAlign: 'center' }}>
          {buildURI(externalLinksData, externalLinksContextData, LINK_TYPES.deep)?.uri}
        </span>
      </div>
      <DeepLinkBuilder
        deepLinkPart={externalLinksContextData}
        dropDownOptions={dropDownOptions}
        setDeepLinkPart={handleChangeExternalLinksData}
        setAdditionalData={handleChangeAdditionalData}
        handleChangeExtraData={handleChangeExtraData}
      />
      {/* {externalLinksContextData?.map((item, index) => (
      ))} */}
    </div>
  )
}

//FOR ARRAY MANUVERS
// function handleChangeDeeplinkPartByIndex(eve, index) {
//   setExternalLinksContextData((prev) => [
//     ...(prev || [])?.map((item, i) =>
//       i === index ? giveGenericHandleChangedData(eve, item) : item,
//     ),
//   ])
// }

// function addDeepLinkPart() {
//   setExternalLinksContextData((prev) => [...(prev || []), defaultLinkMakerContextData])
// }
