import { getCuratedCardRefsBulk } from 'src/views/setup/tiles/components/legacyTiles/tileFormUtils'

export async function transformIncomingBundle(bundles = []) {
  const transformedBundles = await Promise.all(
    bundles.map(async (bundle) => {
      const featureIds = bundle?.featureIds || []
      const foundFeatureIds = await getCuratedCardRefsBulk(featureIds, bundle?.metaCategoryType)
      return { ...bundle, featureIds: foundFeatureIds }
    }),
  )
  return transformedBundles
}
