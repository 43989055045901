import PropTypes from 'prop-types'
import React from 'react'
import { getMediaImageUrl } from 'src/services'
import { getImageWidth } from './TimeLineDnD'
import { TimeLineContainer, TimeLineImage } from './styledComponents'

export default function BGSPhotoFormView({ slides }) {
  const timeLine = {
    minTime: 0,
    maxTime: 10000,
  }
  console.log(slides)
  return (
    <TimeLineContainer className="h-100">
      {slides?.map((slide, idx) => {
        return (
          <TimeLineImage
            key={idx}
            img={`${getMediaImageUrl()}${slide?.image?.imageId}`}
            imgWidth={getImageWidth(slide.inAt, slide.outAt, timeLine.maxTime)}
          />
        )
      })}
    </TimeLineContainer>
  )
}

BGSPhotoFormView.propTypes = {
  slides: PropTypes.object.isRequired,
}
