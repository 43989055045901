import _ from 'lodash'

export const TILES_OBJECT = {
  contentCategories: [],
  curatable: false,
  customShareLink: '',
  defaultPrimaryThemeColor: '',
  defaultSecondaryThemeColor: '',
  defaultTabColor: true,
  description: '',
  featureCategories: [],
  icon: '',
  iconImage: '',
  id: '',
  image: '',
  images: [],
  platform: [],
  policyId: '',
  primaryThemeColor: '',
  secondaryThemeColor: '',
  setThisTabAsDefaultTabColor: true,
  shareLink: '',
  showInTab: true,
  showInTile: false,
  socialDescription: '',
  socialImage: '',
  socialTitle: '',
  specialTabColor: true,
  tileTitle: '',
  title: '',
  translations: {},
}
export const EXPLORE_TAB_SETTING_OBJECT = {
  altTextTranslations: {},
  autoPlayType: '',
  calendly: false,
  categories: [],
  categoryType: '',
  contentType: '',
  courseGrpIds: [],
  customCategories: [],
  description: '',
  enabled: false,
  intercom: false,
  involved: false,
  layout: '',
  listLayout: '',
  noOfSuchCategories: 1,
  refId: '',
  refIds: [],
  refType: '',
  showAll: false,
  showCount: false,
  size: 3,
  subTextTranslations: {},
  tagIds: [],
  techniqueIds: [],
  tileCard: [],
  tagTypes: [], // NEW FIELD
}

export const EXPORT_TAB_ORDER_OBJECT = {
  type: '',
  layout: '',
  listLayout: '',
  showAllEntryLayout: '',
  onTapBehaviour: 'list',
  showAll: false,
  showCount: false,
  item: 0,

  itemType: '',
  metaCategoryType: '',
  metaCategoryIds: [],
  contentIds: [],
  enabled: false,
}

export const TILE_BACKGROUND_IMAGE_OBJECT = {
  bgColor: '',
  enabled: false,
  imageId: '',
  preselected: false,
  secondaryColor: '',
}

export const EXPLORE_TAB_SETTINGS_OBJECT = {
  active: false,
  allDailyFeatures: EXPLORE_TAB_SETTING_OBJECT,
  articles: EXPLORE_TAB_SETTING_OBJECT,
  assessments: EXPLORE_TAB_SETTING_OBJECT,
  autoPlay: { ...EXPLORE_TAB_SETTING_OBJECT, autoPlayType: 'systemGenerated' },
  bgImageEnabled: true,
  breatheByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  breatheTile: EXPLORE_TAB_SETTING_OBJECT,
  brouwseByCategoryIndividualContentLayout: '',
  browseByCategory: EXPLORE_TAB_SETTING_OBJECT,
  browseByCategoryLayout: '',
  browseByCategoryShowAllLayout: '',
  categories: [],
  category: '',
  defaultCarouselSize: 20,
  challengeCard: EXPLORE_TAB_SETTING_OBJECT,
  chatTile: EXPLORE_TAB_SETTING_OBJECT,
  coaching: EXPLORE_TAB_SETTING_OBJECT,
  coachingByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  completedPlanItems: EXPLORE_TAB_SETTING_OBJECT,
  contentCardLayout: '',
  contentCardShowAllLayout: '',
  contestCard: EXPLORE_TAB_SETTING_OBJECT,
  coursesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  curatedArticles: EXPLORE_TAB_SETTING_OBJECT,
  curatedAssessments: EXPLORE_TAB_SETTING_OBJECT,
  curatedCard1: EXPLORE_TAB_SETTING_OBJECT,
  curatedCard2: EXPLORE_TAB_SETTING_OBJECT,
  curatedCard3: EXPLORE_TAB_SETTING_OBJECT,
  curatedCardBreathe: EXPLORE_TAB_SETTING_OBJECT,
  curatedCardJournal: EXPLORE_TAB_SETTING_OBJECT,
  curatedMentalStateTypes: EXPLORE_TAB_SETTING_OBJECT,
  customFeeling: EXPLORE_TAB_SETTING_OBJECT,
  customGenre: EXPLORE_TAB_SETTING_OBJECT,
  customTag: EXPLORE_TAB_SETTING_OBJECT,
  customTechnique: EXPLORE_TAB_SETTING_OBJECT,
  dailyFeature: EXPLORE_TAB_SETTING_OBJECT,
  dailyQuote: EXPLORE_TAB_SETTING_OBJECT,
  deleted: false,
  description: '',
  featuredSchools: EXPLORE_TAB_SETTING_OBJECT,
  feelingByUser: EXPLORE_TAB_SETTING_OBJECT,
  feelingList1: EXPLORE_TAB_SETTING_OBJECT,
  feelingList2: EXPLORE_TAB_SETTING_OBJECT,
  feelingList3: EXPLORE_TAB_SETTING_OBJECT,
  feelings: EXPLORE_TAB_SETTING_OBJECT,
  focusAreaFeelingByUser: EXPLORE_TAB_SETTING_OBJECT,
  focusAreaGenreByUser: EXPLORE_TAB_SETTING_OBJECT,
  focusAreaTagByUser: EXPLORE_TAB_SETTING_OBJECT,
  genre: EXPLORE_TAB_SETTING_OBJECT,
  genreByUser: EXPLORE_TAB_SETTING_OBJECT,
  genreList1: EXPLORE_TAB_SETTING_OBJECT,
  genreList2: EXPLORE_TAB_SETTING_OBJECT,
  genreList3: EXPLORE_TAB_SETTING_OBJECT,
  getInvolvedTile: EXPLORE_TAB_SETTING_OBJECT,
  heroCard: EXPLORE_TAB_SETTING_OBJECT,
  hrm: {
    categoryType: '',
    enabled: false,
    noOfSuchCategories: 1,
    refIds: [],
  },
  id: '',
  images: [],
  joinContestCard: EXPLORE_TAB_SETTING_OBJECT,
  journal: EXPLORE_TAB_SETTING_OBJECT,
  journalTile: EXPLORE_TAB_SETTING_OBJECT,
  journals: EXPLORE_TAB_SETTING_OBJECT,
  journalsByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  meTile: EXPLORE_TAB_SETTING_OBJECT,
  meditationMentors: EXPLORE_TAB_SETTING_OBJECT,
  meditationTile: EXPLORE_TAB_SETTING_OBJECT,
  meditationsByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  mentalStateTypes: EXPLORE_TAB_SETTING_OBJECT,
  modifiedBy: '',
  modifiedTime: {},
  moonCard: {
    categoryType: '',
    enabled: false,
    noOfSuchCategories: 1,
    refIds: [],
  },
  moreTile: EXPLORE_TAB_SETTING_OBJECT,
  msgTranslation: {},
  music: EXPLORE_TAB_SETTING_OBJECT,
  musicByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  myBreathe: EXPLORE_TAB_SETTING_OBJECT,
  myCourses: EXPLORE_TAB_SETTING_OBJECT,
  myJournal: EXPLORE_TAB_SETTING_OBJECT,
  myMeditations: EXPLORE_TAB_SETTING_OBJECT,
  myMentors: EXPLORE_TAB_SETTING_OBJECT,
  myPlans: EXPLORE_TAB_SETTING_OBJECT,
  myPractices: EXPLORE_TAB_SETTING_OBJECT,
  myQuotes: EXPLORE_TAB_SETTING_OBJECT,
  myStories: EXPLORE_TAB_SETTING_OBJECT,
  myTalks: EXPLORE_TAB_SETTING_OBJECT,
  myTherapy: EXPLORE_TAB_SETTING_OBJECT,
  myYoga: EXPLORE_TAB_SETTING_OBJECT,
  my_journalTile: EXPLORE_TAB_SETTING_OBJECT,
  my_libraryTile: EXPLORE_TAB_SETTING_OBJECT,
  name: '',
  onboardingPref: EXPLORE_TAB_SETTING_OBJECT,
  order: [],
  pendingPlan: EXPLORE_TAB_SETTING_OBJECT,
  popularBreathe: EXPLORE_TAB_SETTING_OBJECT,
  popularTeachers: EXPLORE_TAB_SETTING_OBJECT,
  practiceTile: EXPLORE_TAB_SETTING_OBJECT,
  quoteTile: EXPLORE_TAB_SETTING_OBJECT,
  quotesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  recentlyAdded: EXPLORE_TAB_SETTING_OBJECT,
  recommendedCourse: EXPLORE_TAB_SETTING_OBJECT,
  recommendedCuratedCard: [],
  searchCard: {
    categoryType: '',
    enabled: false,
    noOfSuchCategories: 1,
    refIds: [],
  },
  searchTile: EXPLORE_TAB_SETTING_OBJECT,
  settingsTile: EXPLORE_TAB_SETTING_OBJECT,
  shorts: EXPLORE_TAB_SETTING_OBJECT,
  shortsByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  showScoreCard: true,
  sleepTile: EXPLORE_TAB_SETTING_OBJECT,
  sound: EXPLORE_TAB_SETTING_OBJECT,
  soundArtist: EXPLORE_TAB_SETTING_OBJECT,
  soundTile: EXPLORE_TAB_SETTING_OBJECT,
  storiesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  story: EXPLORE_TAB_SETTING_OBJECT,
  storyTellers: EXPLORE_TAB_SETTING_OBJECT,
  storyTile: EXPLORE_TAB_SETTING_OBJECT,
  sunCard: {
    categoryType: '',
    enabled: false,
    noOfSuchCategories: 1,
    refIds: [],
  },
  systemType1: EXPLORE_TAB_SETTING_OBJECT,
  systemType2: EXPLORE_TAB_SETTING_OBJECT,
  tag: EXPLORE_TAB_SETTING_OBJECT,
  tagByUser: EXPLORE_TAB_SETTING_OBJECT,
  tagList1: EXPLORE_TAB_SETTING_OBJECT,
  tagList2: EXPLORE_TAB_SETTING_OBJECT,
  tagList3: EXPLORE_TAB_SETTING_OBJECT,
  talkToUsCard: EXPLORE_TAB_SETTING_OBJECT,
  talksByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  technique: EXPLORE_TAB_SETTING_OBJECT,
  techniqueByUser: EXPLORE_TAB_SETTING_OBJECT,
  therapiesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  tilePolicyId: '',
  tiles: EXPLORE_TAB_SETTING_OBJECT,
  titleTranslation: {},
  trending: EXPLORE_TAB_SETTING_OBJECT,
  tutorial: EXPLORE_TAB_SETTING_OBJECT,
  upgrade: {
    categoryType: '',
    enabled: false,
    noOfSuchCategories: 1,
    refIds: [],
  },
  welcomeCard: EXPLORE_TAB_SETTING_OBJECT,
  yogaByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  yogaTeacher: EXPLORE_TAB_SETTING_OBJECT,
  yogaTile: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList1: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList2: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList3: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList4: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList5: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList6: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList7: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList8: EXPLORE_TAB_SETTING_OBJECT,
  anyTagList9: EXPLORE_TAB_SETTING_OBJECT,
  // anyTag1: EXPLORE_TAB_SETTING_OBJECT,
  // anyTag2: EXPLORE_TAB_SETTING_OBJECT,
  // anyTag3: EXPLORE_TAB_SETTING_OBJECT,
}

export const DEFAULTS_EXPLORE_SETTINGS = {
  brouwseByCategoryIndividualContentLayout: 'rectangular_mid',
  browseByCategoryLayout: 'pill',
  browseByCategoryShowAllLayout: 'grid_square',
  category: 'journal',
  contentCardLayout: 'rectangular_mid',
  contentCardShowAllLayout: 'rectangular_mid',
  showScoreCard: false,
}

export const TAB_LAYOUTS = [
  {
    apiParam: 'tab-layout',
    id: 'pill',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Pills',
    type: 'ContentCard',
    types: ['list_of_categories'],
  },
  {
    apiParam: 'tab-layout',
    id: 'rectangular_mid',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Rectangular mid',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'vertical_list',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Vertical list',
    type: 'ContentCard',
    types: ['list_of_categories'],
  },
  {
    apiParam: 'tab-layout',
    id: 'circle',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Circle',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square',
    type: 'ContentCard',
    types: ['list_of_categories', 'list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square_mid',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square mid',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square_text_teacher_description',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square text teacher (desc)',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square_text',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square text',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square_overlay',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square Overlay',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'rectangular_large',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Rectangular large',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'journal_large_rectangle',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Journal large rectangle',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'vertical_rectangle',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Vertical rectangle',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'journal_small_rectangle',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Journal small rectangle',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'image_pill',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Image pills',
    type: 'ContentCard',
    types: ['list_of_categories'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square_text_teacher',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square text teacher',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square_large',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square large',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'list_square',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'List square',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'grid_square',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Grid square',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'grid_rectangular_small',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Grid rectangular small (Sounds only)',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'list_rectangular',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'List rectangular',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'list_circle',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'List Circle (Teachers only)',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'list_rectangle_journal',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'List Rectangular (Journal)',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'list_rectangle_with_desc_journal ',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'List Rectangle With Desc Journal',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'square_center_text',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square (Center Text)',
    type: 'ContentCard',
    types: ['list_of_categories'],
  },
  {
    apiParam: 'tab-layout',
    id: 'vertical_list_with_count',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Vertical list with zero',
    type: 'ContentCard',
    types: ['list_of_categories'],
  },
  {
    apiParam: 'tab-layout',
    id: 'tab_tile_rectangular',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Rectangular (Tiles)',
    type: 'ContentCard',
    types: ['tiles'],
  },
  {
    apiParam: 'tab-layout',
    id: 'tab_tile_circular',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Circular (Tiles)',
    type: 'ContentCard',
    types: ['tiles'],
  },
  {
    apiParam: 'tab-layout',
    id: 'tab_tile_square',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Square (Tiles)',
    type: 'ContentCard',
    types: ['tiles'],
  },
  {
    apiParam: 'tab-layout',
    id: 'new_rectangular',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'New Rectangular',
    type: 'ContentCard',
    types: ['list_of_categories'],
  },
  {
    apiParam: 'tab-layout',
    id: 'new_rectangular_show_all',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'New Rectangular (Show All)',
    type: 'ContentCard',
    types: ['see_all'],
  },
  {
    apiParam: 'tab-layout',
    id: 'new_rectangular_content_card',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'New Rectangular (Content Card)',
    type: 'ContentCard',
    types: ['list_of_entities'],
  },
  {
    apiParam: 'tab-layout',
    id: 'tab_tile_vertical',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'Vertical (Tiles)',
    type: 'ContentCard',
    types: ['tiles'],
  },
  {
    apiParam: 'tab-layout',
    id: 'on_tap_behaviour',
    enabled: true,
    deleted: false,
    idByUser: false,
    name: 'on tap behavior',
    type: 'ContentCard',
    types: ['player', 'list'],
  },
]

export function getTabLayoutsFromApi() {
  //TODO: get api from aviral and use that api to fetch the layouts instead of using aviral TAB_LAYOUTS
  return TAB_LAYOUTS
}

export function checkCategories(categoryType, orderCategoryIds, content) {
  switch (categoryType) {
    case 'service-provider':
      return !_.isEmpty(_.intersection(orderCategoryIds, content?.categories))
    case 'course':
      return !_.isEmpty(_.intersection(orderCategoryIds, content?.category))
    case 'tag':
      return orderCategoryIds?.includes(content?.type)
    case 'tiles':
      return orderCategoryIds?.includes(content?.category)
    default:
      return true
  }
}

function validateSingleOrderItem(orderItem, fillingMethod, itemIndex) {
  if (fillingMethod?.type === 'curated') {
    if (
      !!orderItem?.metaCategoryType &&
      !!orderItem?.metaCategoryIds &&
      orderItem?.metaCategoryIds?.length > 0 &&
      !!orderItem?.contentIds &&
      !!orderItem?.contentIds?.length > 0
    ) {
      return { index: itemIndex, valid: true }
    } else {
      return { index: itemIndex, valid: false }
    }
  } else {
    if (
      !!orderItem?.metaCategoryType &&
      !!orderItem?.metaCategoryIds &&
      orderItem?.metaCategoryIds?.length > 0
    ) {
      return { index: itemIndex, valid: true }
    } else {
      return { index: itemIndex, valid: false }
    }
  }
}

function dataValidateOrderItem(orderItem, fillingMethod, itemIndex) {
  const metaCategoryIds = orderItem?.metaCategoryIds
  var contentValidation = []
  if (fillingMethod?.type === 'curated') {
    if (
      !!orderItem?.metaCategoryType &&
      !!orderItem?.metaCategoryIds &&
      orderItem?.metaCategoryIds?.length > 0 &&
      !!orderItem?.contentIds &&
      !!orderItem?.contentIds?.length > 0
    ) {
      const content = orderItem?.contentIds
      contentValidation = content?.map((cont, contIdx) => {
        if (orderItem?.metaCategoryType !== cont?.metaCategoryType) {
          return { contentIndex: contIdx, index: itemIndex, valid: false }
        }
        if (checkCategories(orderItem?.metaCategoryType, metaCategoryIds, cont)) {
          return { contentIndex: contIdx, index: itemIndex, valid: true }
        } else {
          return { contentIndex: contIdx, index: itemIndex, valid: false }
        }
      })
      return contentValidation
    } else {
      return [{ index: itemIndex, valid: false }]
    }
  }
  return [{ index: itemIndex, valid: true }]
}

export function validateOrderItems(orderItems, fillingMethods) {
  const emptyValidation = orderItems.map((orderItem, idx) =>
    validateSingleOrderItem(
      orderItem,
      fillingMethods?.find((method) => method?.id === orderItem.type),
      idx,
    ),
  )
  //empty validation
  const dataValidation = orderItems.map((orderItem, idx) =>
    dataValidateOrderItem(
      orderItem,
      fillingMethods?.find((method) => method?.id === orderItem.type),
      idx,
    ),
  )
  return { emptyValidation, dataValidation }
}

export function checkValidationData(valInfo) {
  if (!valInfo?.emptyValidation?.valid) {
    return false
  } else {
    const invalidContentData = valInfo?.dataValidation?.filter((dtaVal) => dtaVal.valid === false)
    return invalidContentData?.length > 0 ? false : true
  }
}

export const allValidDataObject = {
  emptyValidation: { index: 0, valid: true },
  dataValidation: [],
}
