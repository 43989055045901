import React from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import NormalListingTableHandler from '../../NormalListingTableHandler'

export default function UserDevices({ userId, data, setData }) {
  return (
    <NormalListingTableHandler
      apiCallFunction={GetSettingsData}
      apiCallParams={{ userId: userId, type: 'track', size: 200 }}
      apiCallUrl={USERS_API_SIGNATURES_MAP.devicesAndExperience.devices.get}
      data={data}
      setData={setData}
      setupDataDependencies={[userId]}
    >
      <CCExtendedCSmartTable
        noUserNames
        items={data?.data}
        loading={data?.status === 'loading'}
        columns={columns}
        // scopedColumns={scopedColumns}
        excludeGenericFields={[
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ]}
      />
    </NormalListingTableHandler>
  )
}

const columns = [
  { key: 'manufacturer', label: 'Manufacturer', sorter: false, filter: false },
  // { key: 'teacher', label: 'Teacher', sorter: false, filter: false },
  { key: 'modelNumber', label: 'Model Number', sorter: false, filter: false },
  { key: 'platform', label: 'Platform', sorter: false, filter: false },
  { key: 'imei', label: 'UUID', sorter: false, filter: false },
  { key: 'appVersion', label: 'App Version', sorter: false, filter: false },
  { key: 'appPackage', label: 'App Package', sorter: false, filter: false },
  { key: 'modifiedTime', label: 'Last Active Time', sorter: false, filter: false },
]

// const scopedColumns = {
//   title: (item) => {
//     var toRet = '--'
//     if (item?.userPranaVayu) {
//       toRet = item?.pranaVayuTechniques?.name || ''
//     }
//     return <td>{toRet}</td>
//   },
//   teacher: (item) => {
//     return <td>{item?.teacher?.name || '--'}</td>
//   },
//   userPranaModifiedTime: (item) => {
//     return (
//       <td>
//         {item?.userPranaVayu?.modifiedTime
//           ? moment(item?.userPranaVayu?.modifiedTime).format(
//               MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
//             )
//           : '--'}
//       </td>
//     )
//   },
//   createdTime: (item) => {
//     return (
//       <td>
//         {item?.userPranaVayu?.createdTime
//           ? moment(item?.userPranaVayu?.createdTime).format(
//               MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
//             )
//           : '--'}
//       </td>
//     )
//   },
//   totalSessions: (item) => {
//     var toRet = '--'
//     if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != undefined) {
//       toRet = item?.userPranaVayu?.pranaVayuSummary?.totalSessionCount
//     }
//     return <td>{toRet}</td>
//   },
//   totalSessionTime: (item) => {
//     var toRet = '--'

//     if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != null) {
//       toRet = item?.userPranaVayu?.pranaVayuSummary?.totalSessionTime
//     }
//     return <td>{toRet}</td>
//   },
//   completedSessionCount: (item) => {
//     var toRet = '--'

//     if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != null) {
//       toRet = item?.userPranaVayu?.pranaVayuSummary?.completedSessionCount
//     }
//     return <td>{toRet}</td>
//   },
//   completedSessionTime: (item) => {
//     var toRet = '--'
//     if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != null) {
//       toRet = item?.userPranaVayu?.pranaVayuSummary?.completedSessionTime
//     }
//     return <td>{toRet}</td>
//   },
//   latestBreathTiming: (item) => {
//     var toRet = '--'
//     if (item.userPranaVayu) {
//       toRet = item?.userPranaVayu.latestBreathTiming
//     }
//     return <td>{toRet}</td>
//   },
// }
