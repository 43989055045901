export const QUOTE_OBJECT = {
  apiParam: 'quote',
  author: '',
  backdropImage: '',
  backdropImageColor: '',
  blogBanner: '',
  blogBannerColor: '',
  category: '',
  customJournalId: '',
  customShortLink: '',
  deleted: false,
  enableJournal: true,
  enabled: true,
  favCount: 0,
  feelings: [],
  genre: [],
  hashtags: [],
  heroCardImage: '',
  heroCardImageColor: '',
  imageColor: '',
  imageId: '',
  internalTitle: '',
  language: '',
  languages: ['en'],
  msg: '',
  previewImageColor: '',
  previewImageId: '',
  reflection: {},
  source: '',
  systemJournal: true,
  systemJournalId: '',
  tags: [],
  translations: {},
  reflectionMap: {},
  type: 0,
  welcomeMsg: '',
}

export const quoteOptionsList = [50, 100, 200, 400]
