import React from 'react'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { loadCategoryTypeBasedItems } from '../../utils'

export default function DLSlugItemDefault({ categoryType, item, handleChangeItem, uiContextData }) {
  return !!categoryType ? (
    <div>
      <label>{uiContextData?.linkIdSelectionLabel || 'Item'}</label>
      <ActivityItemAsyncSelect
        cacheUniqs={[categoryType]}
        classNamePrefix="activity-item-select"
        menuPortalTarget={document.body}
        value={item?.value ? item : null}
        loadOptions={loadCategoryTypeBasedItems}
        debounceTimeout={1000}
        additional={{
          page: 0,
          size: 200,
          metaCategoryTypeId: categoryType,
        }}
        onChange={(selVal) => handleChangeItem(selVal)}
      />
    </div>
  ) : (
    <></>
  )
}
