import { Apple, Email, Google, PhoneAndroid } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'

export default function SocialConnectionsComponent({ userData }) {
  const [socialConnections, setSocialConnections] = useState([])

  function getComponentForConnection(connection) {
    switch (connection) {
      case 'mobile':
        return (
          <Tooltip title="Mobile">
            <PhoneAndroid />
          </Tooltip>
        )
      case 'email':
        return (
          <Tooltip title="Email">
            <Email />
          </Tooltip>
        )
      case 'google':
        return (
          <Tooltip title="Google">
            <Google />
          </Tooltip>
        )
      case 'apple':
        return (
          <Tooltip title="Apple">
            <Apple />
          </Tooltip>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    const mobile = userData?.mobileVerified
    const email = !!userData?.email
    const google = userData?.socialConnections?.some(
      (connection) => connection.providerId === 'google',
    )
    const apple = userData?.socialConnections?.some(
      (connection) => connection.providerId === 'apple',
    )
    if (!mobile && !email && !google && !apple) {
      console.log({ userData })
    }
    var socialConnections = []
    if (mobile) {
      socialConnections.push('mobile')
    }
    if (email) {
      socialConnections.push('email')
    }
    if (google) {
      socialConnections.push('google')
    }
    if (apple) {
      socialConnections.push('apple')
    }

    setSocialConnections(socialConnections)
  }, [userData])

  return (
    <div className="d-flex flex-wrap gap-2">
      {socialConnections.map((connection, index) => (
        <div key={index} className="d-flex gap-1">
          {getComponentForConnection(connection)}
        </div>
      ))}
    </div>
  )
}
