import { getMentorsListToStore } from './services/getMentorsList'
import { updateCollectedUsers } from './services/users'

export const appSettingsExtraReducers = (builder) => {
  builder
    // fetchAvailableClinics
    .addCase(updateCollectedUsers.pending, (state) => {
      state.loadingCollectedUsers = true
    })
    .addCase(updateCollectedUsers.fulfilled, (state, action) => {
      state.loadingCollectedUsers = false
      state.collectedUsers = action.payload
    })
    .addCase(updateCollectedUsers.rejected, (state, action) => {
      state.loadingCollectedUsers = false
    })
    .addCase(getMentorsListToStore.pending, (state) => {
      state.allMentors.status = 'pending'
    })
    .addCase(getMentorsListToStore.fulfilled, (state, action) => {
      state.allMentors.status = 'success'
      state.allMentors.data = action.payload
    })
    .addCase(getMentorsListToStore.rejected, (state, action) => {
      state.allMentors.error = action.payload
    })
}
