import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const DRUG_INFO_OBJECT = {
  apiParam: 'drug-info',
  id: '',
  enabled: false,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  clinicIds: [],
  name: '',
  genericName: '',
  dosage: '0-0',
  qty: '',
  unit: '',
  timing: '',
  whatTime: '',
  appliedWhere: '',
  frequency: '',
  note: '',
  availableToAll: true,
}
