import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { getMediaImageUrl } from 'src/services'
import { BgImageText, BGSPreviewBox } from './styledComponents'

export default function BGSImagePreview({ imageObject, selectedFile, type }) {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={'center'}
        bgcolor={'#EBEBEB'}
        height="80%"
        width="100%"
        borderRadius="9px"
      >
        {selectedFile < 0 ? (
          <BgImageText>Add Images</BgImageText>
        ) : (
          <BGSPreviewBox
            bgsType={type}
            alt="bg-slide"
            src={
              !!imageObject?.image?.imageId
                ? `${getMediaImageUrl()}${imageObject?.image?.imageId}`
                : `${URL.createObjectURL(imageObject?._uploadedFile)}`
            }
          />
        )}
      </Box>
    </>
  )
}

BGSImagePreview.propTypes = {
  imageObject: PropTypes.object,
  selectedFile: PropTypes.number,
  type: PropTypes.string,
}
