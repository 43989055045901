import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { SETTINGS_API_LIST } from 'src/views/settings/utils/api-utils'

export const updateCollectedUsers = createAsyncThunk(
  'appSettings/updateCollectedUsers',
  async (usersToUpdate, { getState, dispatch, rejectWithValue }) => {
    const { appSettings } = getState()
    const collectUsers = appSettings.collectedUsers
    const usersToFind = usersToUpdate?.filter((userId) => !collectUsers?.[userId])
    var foundUsers = collectUsers

    const response = await GetSettingsData(SETTINGS_API_LIST.userNames.get.url, {
      userIds: usersToFind.join(','),
      role: 'ROLE_USER',
    })
    if (response.status === 200) {
      usersToFind?.forEach((userId) => {
        const foundUser = response.data?.find((user) => user.userId === userId)
        foundUsers = { ...foundUsers, [userId]: foundUser?.name || userId }
      })

      // response.data?.forEach((userName) => {
      //   foundUsers = { ...foundUsers, [userName.userId]: userName?.name }
      //   // foundUsers[userName.userId] = userName?.name
      // })
    }
    return foundUsers
  },
  {
    condition: (usersToUpdate, { getState }) => {
      const { appSettings } = getState()
      const collectUsers = appSettings.collectedUsers
      const usersToFind = usersToUpdate?.filter((userId) => !collectUsers?.[userId])
      return usersToFind?.length > 0
    },
  },
)
