import { Grid } from '@mui/material'
import React from 'react'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import TrackUploadComponent from 'src/components/controlCenter/common/trackAndMedia/TrackUploadComponent'
import { BACKGROUND_MUSIC_TYPES, HLS_TRACK_TYPES_MAP } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

export default function BackgroundMusicComponent({
  initialObject,
  imageObject: backgroundMusic,
  setImageObject: setBackgroundMusic,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  formMode,
  hideTypeField = false,
  hideCategoryField = false,
}) {
  const [store] = useAppSettingsStore()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CCFormInput
          id="name"
          value={backgroundMusic?.name}
          label="Name"
          colNumber={12}
          onChange={handleChangeObject}
          disabled={disabled}
          required
        />
      </Grid>
      {!hideTypeField && (
        <Grid item xs={12}>
          <CustomReactSelect
            isMulti
            id="type"
            label="Type"
            value={backgroundMusic?.type}
            options={BACKGROUND_MUSIC_TYPES}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.title}
            onChangeEvent={handleChangeObject}
            selectType={selectTypes.string}
            colNumber={12}
            disabled={disabled}
            required
          />
        </Grid>
      )}
      {!hideCategoryField && (
        <Grid item xs={12}>
          <CustomReactSelect
            isMulti
            id="categories"
            label="Categories"
            value={backgroundMusic?.categories}
            options={store?.categories?.filter((category) => category?.categoryType === 'course')}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
            onChangeEvent={handleChangeObject}
            selectType={selectTypes.string}
            colNumber={12}
            disabled={disabled}
            required
            menuPortalTarget={document.body}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TrackUploadComponent
          disabled={disabled}
          trackObject={{
            url: backgroundMusic?.hlsUrl,
            type: HLS_TRACK_TYPES_MAP.hls_audio,
          }}
          handleChange={(e) => {
            const url = e.target.value?.url
            setBackgroundMusic((prev) => ({
              ...prev,
              hlsUrl: url,
            }))
          }}
          showTrackOutsideOnDisabled
        />
      </Grid>
    </Grid>
  )
}
