import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const HEALTH_COMPLAINT_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'health-complaint',
  clinicIds: [],
  clinicId: '',
  title: '',
  availableToAll: true,
}
