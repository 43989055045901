import { ButtonBase, DialogContent, Grid, IconButton, Menu, Paper, Popover } from '@mui/material'
import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material'

export const CardSectionContainer = styled.div``
export const AppDetailsContainer = styled.div``
export const CardDetail = styled.div`
  display: grid;
  grid-template-columns: 4fr 0.5fr 5.5fr;
  align-items: center;
  gap: 5px;
  width: 100%;
`

export const ShowFieldsKey = muiStyled('div')(
  ({ theme }) => `
  font-weight: 700;
  `,
)
export const CardDetailHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`
export const BasicDetailsContainer = styled.div`
  @media (min-width: 768px) {
    margin-left: 20px;
  }
`
export const CardsInterfaceContainer = styled.div``

export const AddCardButton = styled.div``
export const AddCardDialog = styled(Popover)``
export const AddCardButtonContainer = styled.div``
export const AltTextContainer = styled.div``

export const CardItem = styled.div`
  background: url(${(props) => props.backgroundImage});
  display: inline-block;
  white-space: nowrap;
  margin: 5px;
  width: ${({ componentWidth }) => {
    return componentWidth < 500 ? '50px' : '150px'
  }};
  height: ${({ componentWidth }) => {
    return componentWidth < 500 ? '50px' : '150px'
  }};
  background-size: 100% 100%;
  flex: 0 0 auto;
  border-radius: 12px;
`

export const CardDarkOverLay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 12px;
`

export const SettingsMenu = styled(Menu)``

export const PageSettingsContainer = styled.div``

export const TranslationsButton = muiStyled(IconButton)(
  ({ theme }) => `
    background: ${theme.palette.primary.main};
    color: #ebedef;
    &:hover {
    background: ${theme.palette.primary.dark};
`,
)

export function getPreviewReorderCardColor(
  theme,
  selected,
  disabled,
  invalid,
  defaultColor,
  overrideColor,
) {
  if (overrideColor) {
    return overrideColor
  }
  if (theme.palette.mode === 'dark') {
    if (selected) {
      if (invalid) {
        return 'rgb(194 1 1)'
      }
      if (disabled) {
        return '#393a43'
      } else {
        return defaultColor || 'rgb(163 127 234 / 17%)'
      }
    } else {
      if (invalid) {
        return 'rgb(105 17 17)'
      }
      if (disabled) {
        return '#232329'
      }
      return defaultColor || 'rgba(var(--cui-body-bg-rgb))'
    }
  } else {
    if (selected) {
      if (invalid) {
        return 'rgb(255 128 128)'
      }
      return disabled ? '#d5d5d5' : '#c8bff5'
    } else {
      if (invalid) {
        return 'rgb(255 188 188)'
      }
      return disabled ? '#f8f8f8' : '#C3CDFD'
    }
  }
}

export function hoverPreviewOrderCardColor(theme, selected, disabled, invalid) {
  if (!selected) {
    if (theme.palette.mode === 'dark') {
      if (invalid) {
        return theme.palette.error.light
      }
      return 'rgb(163 127 234 / 17%)'
    } else {
      if (invalid) {
        return theme.palette.error.dark
      }
      if (disabled) {
        return '#bdbdbd'
      } else {
        return '#aca0ff'
      }
    }
  } else {
    return ''
  }
}

export const PreviewReorderCard = muiStyled('div')(
  ({ theme, selected, disabled, invalid }) => `
  background: ${getPreviewReorderCardColor(theme, selected, disabled, invalid)};
  padding: 18px 10px 14px 10px;
  border-radius: 7px;
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: stretch;
  flex-direction: column;
  gap:15px;
  text-align: start;
  cursor: pointer;
  transition: all 150ms linear;
  &:hover {
    ${!selected && `background: ${hoverPreviewOrderCardColor(theme, selected, disabled, invalid)};`}
  }
`,
)

// #bdbdbd

export const ReorderTile = muiStyled('div')(
  ({ theme, hasChanged }) => `
  background: ${theme.palette.mode === 'dark' ? 'rgba(var(--cui-body-bg-rgb))' : '#C3CDFD'};
  border: 2px solid ${hasChanged ? '#ffb831' : 'transparent'};

  padding: 13px 10px;
  border-radius: 7px;
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  gap:5px;
`,
)
export const PreviewReorderContainer = muiStyled('div')(
  ({ theme }) => `
    padding: 10px;
    border-radius: 7px;
    background: ${
      theme.palette.mode === 'dark' ? 'rgba(var(--cui-dark-rgb), var(--cui-bg-opacity))' : '#E8ECFF'
    };
`,
)
export const TileFormSection = muiStyled('div')(
  ({ theme }) => `
`,
)
export const TileFormSectionHeading = muiStyled('div')(
  ({ theme }) => `
  width: 100%;
  font-size: 25px;
  font-weight: 500;
  color: ${theme.palette.primary.main};
  padding: 0px 10px;
`,
)
export const TileFormSectionSubHeading = muiStyled('div')(
  ({ theme }) => `
    font-size: 15px;
    padding: 0px 10px;
    font-weight: 400;
    color: ${theme.palette.text.secondary}
`,
)
export const TileFormContent = muiStyled('div')(
  ({ theme }) => `
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: center;
`,
)

export const TilesMainFormContainer = muiStyled('div')(
  ({ theme }) => `
  padding: 15px 0px;
  display: grid;
  grid-template-columns: 100%;
  gap :30px;
  @media (min-width: 992px){
  grid-template-columns: calc(50% - 30px) calc(50% - 30px);
  gap :30px;
}
`,
)

export const TilesColumnContainer = muiStyled('div')`
display: flex;
flex-direction: column;
gap: 30px;
`

export const TilePolicyFormContainer = muiStyled('form')`
`

export const TileFieldLabel = muiStyled('div')(
  ({ theme, labelView }) => `
  font-size: ${labelView === 'default' ? '17px' : '25px'};
  font-weight: ${labelView === 'default' ? '400' : '500'};
  flex-grow: 0;
`,
)

export const ExploreSettingHandlerContainer = muiStyled('div')`
padding: 15px;
gap: 15px;
width: 100%;
display: flex;
flex-direction: column;
`
export const TilesReorderContainer = muiStyled(DialogContent)(
  ({ theme }) => `
`,
)
export const TileCategoriesReorderContainer = muiStyled('div')(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
`,
)

export const PopoverPaperComponent = muiStyled(Paper)`
  width: 500px;
  height:300px;
`

export const TilePageInterface = muiStyled('div')(
  ({ theme }) => `
  display: grid;
  gap: 10px;
  padding: 20px 0px 20px;
  grid-template-columns: 50% 50%;
  @media (min-width: 768px){
    grid-template-columns: 40% 60%;
  }
  @media (min-width: 992px){
    grid-template-columns: 30% 70%;
  }
`,
)

export const TilesReorderHeadingContainer = muiStyled('div')(
  ({ theme }) => `
display:flex;
justify-content: space-between;
align-items: center;
z-index: 2;
background: ${
    theme?.palette?.mode === 'dark'
      ? 'var(--cui-body-bg)'
      : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
  };
  padding: 10px 0px;
  border-radius: 10px;
`,
)
export const TileFormSectionContainer = muiStyled('div')(
  ({ theme }) => `
    box-shadow: 0 0 5px ${theme.palette.mode === 'dark' ? '#5e5ea7' : '#a6a2b1'};
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 7px;
    background: ${theme.palette.mode === 'dark' ? 'transparent' : '#E8ECFF'}
`,
)

export const TileCategoriesReorderHeading = muiStyled('div')`
  font-size: 20px;
  font-weight: 500;
`

export const TileCategoriesItemReorderHeadingContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  gap: 3px;
  align-items: flex-start;
 width: 100%;

`,
)

export const LanguageSelectContainer = muiStyled('div')(
  ({ theme }) => `
  max-width: 330px;
  `,
)
export const FieldsSection = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 40px 0px;
  max-width: 500px;
  `,
)

export const TabSectionContainer = muiStyled('div')(
  ({ theme }) => `
  background: ${
    theme.palette.mode === 'dark' ? 'rbga(var(--cui-body-bg-rgb))' : 'rgba(var(--cui-light-rgb))'
  };
  position: relative;
  padding: 10px;
  margin: 0px 3px;
  border: 1px solid rgba(var(--cui-primary-rgb));
  border-radius: 7px;
`,
)

export const TabSectionHeadingContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  border-radius: 7px;
  width: 100%;
  padding: 0px 11px;
  top: -35px;
`,
)

export const TabSectionHeadingTitle = muiStyled('h3')(
  ({ theme }) => `
  width: fit-content;
  padding: 0px 11px;
    background: ${
      theme.palette.mode === 'dark' ? 'rbga(var(--cui-body-bg-rgb))' : 'rgba(var(--cui-light-rgb))'
    }
`,
)

export const TabTileForm = muiStyled('form')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 60px;
`,
)
//  113px
export const TabTileHeader = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  position: sticky;
  padding: 30px 14px 15px 14px;
  top: var(--header-height);
  background: ${
    theme.palette.mode === 'dark' ? 'var(--cui-body-bg)' : 'rgba(var(--cui-light-rgb))'
  };
  z-index: 3;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  @media(min-width: 768px){
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`,
)

export const TileFormSubmissionButtonsContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  @media(min-width: 768px){
    justify-content: flex-end;
  }
`,
)

export const PreviewCardsListContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  overflow-x: scroll;
`,
)

export const PreviewTileHeading = muiStyled('div')(
  ({ theme }) => `
  font-size: 22px;
  font-weight: 500;
  padding: 0 0 10px 0;
`,
)

export const PageSizeContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:7px;
  flex-shrink:0;
  widtH: 170px;
  `,
)

export const ContentIdsOldIndexChip = muiStyled('div')(
  ({ theme }) => `
  background: #1500d138;
  padding: 9px;
  border-radius: 30px;
  font-size: 18px;
  flex-shrink: 0;
`,
)

export const LayoutDetailsReorderContainer = muiStyled('div')(
  ({ theme }) => `
  background: ${theme.palette.mode === 'dark' ? '#3b2f55' : '#dfe2fd'};
  border-radius: 10px;
`,
)

export const AddOrCollapseContainer = muiStyled('div')(
  ({ theme }) => `
  position: sticky;
  top: 112px;
  z-index: 1;
  padding: 7px 0px;
  background: ${
    theme?.palette?.mode === 'dark'
      ? 'var(--cui-body-bg)'
      : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
  };
`,
)

export const ContentIdsMultiValueContainer = muiStyled('div')(
  ({ theme, invalid }) => `
background: ${invalid ? theme.palette.error.contrast : 'transparent'};
`,
)
export const MetaCategoryIdsSelectContainer = muiStyled('div')(
  ({
    theme,
    invalid,
    customInvalidColor = theme.palette.error.contrast,
    customValidColor = 'transparent',
    showBorder = false,
  }) => `
  padding: 2px;
  border-radius: 6px;
  border: ${showBorder ? '1px solid rgba(0, 0, 0, 0.30)' : 'unset'};
background: ${invalid ? customInvalidColor : customValidColor};

> div {
  display: flex;
}
`,
)
