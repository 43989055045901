import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'

export async function transformListingDataForCourseUnPublishRequest({ listingData }) {
  const [unPublishingRequestsWithOnlineCourses, ocUpdatedList] = await Promise.all([
    getOnlineCoursesForUnPublishingRequests(listingData),
    getApprovedByUserNames(listingData),
  ])
  const combinedData = unPublishingRequestsWithOnlineCourses.map((unPublishingRequest) => {
    const foundUnPublishRequestWithUserDetails = ocUpdatedList.find(
      (unPublishRequestWithUserData) =>
        unPublishingRequest?.id === unPublishRequestWithUserData?.id,
    )
    return {
      ...unPublishingRequest,
      createdBy: foundUnPublishRequestWithUserDetails?.createdBy,
      onlineCourse: {
        ...unPublishingRequest?.onlineCourse,
        approvedBy: foundUnPublishRequestWithUserDetails?.onlineCourse?.approvedBy,
      },
    }
  })

  console.log({ combinedData })
  return combinedData
}

async function getApprovedByUserNames(ocList) {
  const userIdsToUse = ocList?.map(
    (unPublishingRequest) => unPublishingRequest?.onlineCourse?.approvedBy,
  )
  const createdByUserIds = ocList?.map((unPublishingRequest) => unPublishingRequest?.createdBy)
  const uniqueUserIds = [...new Set([...userIdsToUse, ...createdByUserIds])]

  const foundUsers = await getUsersSummaryFromUserIds(uniqueUserIds)
  return ocList?.map((unPublishingRequest) => {
    const foundApprovingUser = foundUsers?.find(
      (user) => user?.userId === unPublishingRequest?.onlineCourse?.approvedBy,
    )
    const foundCreatingUser = foundUsers?.find(
      (user) => user?.userId === unPublishingRequest?.createdBy,
    )
    return {
      ...unPublishingRequest,
      createdBy: foundCreatingUser?.name,
      onlineCourse: {
        ...(unPublishingRequest?.onlineCourse || {}),
        approvedBy: foundApprovingUser?.name,
      },
    }
  })
}

async function getOnlineCoursesForUnPublishingRequests(unPublishingRequests) {
  const onlineCourseIds = unPublishingRequests?.map(
    (unPublishRequest) => unPublishRequest?.contentId,
  )
  const foundOnlineCoursesResp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.onlineCourse,
    size: 999,
    ids: onlineCourseIds?.join(','),
  })
  if (foundOnlineCoursesResp?.status !== 200) return unPublishingRequests
  return unPublishingRequests?.map((unPublishRequest) => {
    const foundOnlineCourse = foundOnlineCoursesResp?.data?.content?.find(
      (onlineCourse) => onlineCourse?.id === unPublishRequest?.contentId,
    )
    return {
      ...unPublishRequest,
      onlineCourse: foundOnlineCourse,
    }
  })
}
