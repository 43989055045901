import React from 'react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useState } from 'react'
import { META_IMAGE_SLIDE } from 'src/views/settings/utils/bg-slides-utils'
import BGSImagePreview from './BGSImagePreview'
import BGSTimeLine from './BGSTimeLine'
import { useEffect } from 'react'
import { BGSPhotoContainer, TimeLineContainer } from './styledComponents'
import { DialogContent, DialogTitle, Popover } from '@mui/material'
import TimeFrameForm from './TimeFrameForm'

export default function BGSPhoto({ bgSlideObject, setBgSlideObject, type, onChange }) {
  const [selectedFile, setSelectedFile] = useState(-1)
  const [timeFrame, setTimeFrame] = useState({
    minTime: 0,
    maxTime: 5000,
  })

  function handleTimeFrame(event) {
    setTimeFrame((prev) => {
      return { ...prev, [event.target.id]: event.target.value }
    })
  }

  useEffect(() => {
    console.log(timeFrame)
  }, [timeFrame])
  useEffect(() => {
    console.log(bgSlideObject)
  }, [bgSlideObject])

  return (
    <BGSPhotoContainer>
      <BGSImagePreview
        imageObject={selectedFile >= 0 ? bgSlideObject[type][selectedFile] : []}
        selectedFile={selectedFile}
        type={type}
      />
      {!!bgSlideObject && (
        <div style={{ height: '15%' }}>
          <BGSTimeLine
            imageSlideObject={bgSlideObject}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setImageSlideObject={setBgSlideObject}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
            type={type}
          />
        </div>
      )}
    </BGSPhotoContainer>
  )
}
BGSPhoto.propTypes = {
  bgSlideObject: PropTypes.object,
  setBgSlideObject: PropTypes.func,
  onChange: PropTypes.func,
  setBgSlide: PropTypes.func,
  type: PropTypes.string,
  timeFrameRef: PropTypes.any,
  timeFrameAnchor: PropTypes.any,
  setTimeFrameAnchor: PropTypes.func,
}
