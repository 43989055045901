import { ButtonBase, Grid, styled as muiStyled } from '@mui/material'

export const NotificationImageUrlGridItem = muiStyled(Grid)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    gap: 7px;
    flex-direction: column;
    @media (min-width: ${theme.breakpoints.values.md}px) {
        flex-direction: row;
        }
`,
)

export const NotificationCustomFieldsAddButton = muiStyled(ButtonBase)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    transition: background 300ms ease-in-out;
    border-radius: 7px;
    color: ${theme.palette.mode === 'light' ? '#000000' : '#ffffff'};
    &:hover {
      background: #0000002b;
    }
`,
)
