import { IdTypes } from 'src/utilities/constants'

export function defaultHandlingForNotificationAdditionalData(notificationsObject) {
  //TODO: HANDLE THIS
  return {
    idType: notificationsObject?.customFields?.subType
      ? notificationsObject?.customFields?.subType
      : IdTypes.id,
    itemId: notificationsObject?.customFields?.sourceId || '',
  }
}
