import { COLOR_OBJECT } from './imageGallery-utils'

export const ROLE_OBJECT = {
  apiParam: 'role',
  id: '',
  createdBy: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLOR_OBJECT,
  modifiedBy: '',
  name: '',
  title: '',
  description: '',
  homePageTextBox: '',
  placeholders: [],
  portalUrl: '',
  access: [],
  order: 0,
  agreementRequired: false,
  metaCategoryTypes: [],
  modifiedByName: '',
  placeholderTxt: '',
  accessTxt: '',
}
