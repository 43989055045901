import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { SETTINGS_API_LIST } from 'src/views/settings/utils/api-utils'

export async function getUsersSummaryFromUserIds(userIds) {
  const userIdsToUse = userIds?.filter((userIds) => !!userIds)

  if (userIdsToUse?.length === 0) return []
  const resp = await GetSettingsData(SETTINGS_API_LIST.userNames.get.url, {
    userIds: userIdsToUse?.join(','),
    role: 'ROLE_USER',
  })
  if (resp.status === 200) {
    return resp.data
  }
}

export async function getUsersDetailsFromUserIds(userIds) {
  const userIdsToUse = userIds?.filter((userIds) => !!userIds)

  if (userIdsToUse?.length === 0) return []

  // map the below code to the userIdsToUse and return the response data in the map
  const dataToReturn = await Promise.all(
    userIdsToUse.map(async (userId) => {
      const resp = await GetSettingsData(USERS_API_SIGNATURES_MAP.users.getFullObjects, {
        userId,
        role: 'ROLE_USER',
      })
      if (resp.status === 200) {
        return resp.data.content?.[0]
      }
    }),
  )
  return dataToReturn
}
