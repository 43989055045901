import { Grid } from '@mui/material'
import { styled as muiStyled } from '@mui/system'

export const SectionGridItem = muiStyled(Grid)(
  ({ theme, shadow }) => `
    padding: 15px;
    border-radius: 7px;
    box-shadow: ${shadow ? '0 0 5px -1px #000' : 'unset'};
`,
)
