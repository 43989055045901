import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ListSkeleton from './ListSkeleton'
import { ButtonBase } from '@mui/material'
import { BACKGROUND_SLIDES_IMAGE_TYPES, BGS_FIELD_TO_TITLE } from '../BackgroundSlidesUpload'
import BGSPhotoFormView from './BGSPhotoFormView'
import { BGSTableDataSize } from './styledComponents'
import ConfirmationModal from '../ConfirmationModal'

function BGSGallery({ loading, slides, onChange, onChangeType }) {
  // Add your component logic here
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [idxToSet, setIdxToSet] = useState(-1)

  function handleChange(idx) {
    setIdxToSet(idx)
    setShowConfirmationDialog(true)
  }

  return (
    <>
      {loading ? (
        <ListSkeleton />
      ) : (
        <>
          {slides.length > 0 ? (
            slides?.map((slide, slideIdx) => {
              return (
                <ButtonBase
                  key={slide.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    bgcolor: '#222',
                  }}
                  focusRipple
                  onClick={() => handleChange(slideIdx)}
                >
                  {Object.entries(BACKGROUND_SLIDES_IMAGE_TYPES).map(([type, field], idx) => {
                    return (
                      <div
                        key={type}
                        className="d-flex flex-column justify-content-around align-items-center align-content-center gap-2 p-2"
                      >
                        <h5>{BGS_FIELD_TO_TITLE?.[field]}</h5>
                        <div style={{ height: '80px', width: '100%' }}>
                          {Array.isArray(slide?.[field]) ? (
                            <BGSPhotoFormView slides={slide?.[field]} />
                          ) : (
                            <span>View Not supported yet</span>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </ButtonBase>
              )
            })
          ) : (
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
              <h3>No Items Avaliable</h3>
            </div>
          )}
        </>
      )}
      <ConfirmationModal
        action="submit"
        body="Do you want to use this slide?"
        visibility={showConfirmationDialog}
        visibilitySetter={setShowConfirmationDialog}
        onSubmitFunctions={[() => onChange(slides[idxToSet])]}
      />
    </>
  )
}

BGSGallery.propTypes = {
  loading: PropTypes.bool.isRequired,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
}

export default BGSGallery
