import React from 'react'
import CardTemplateMsg from 'src/components/controlCenter/CardTemplateMsg'
import { TIME_SLOT_VIEWS } from 'src/views/settings/utils/timeSlot-utils'

export default function DisplayMsgTemplateComponent({
  initialObject = {},
  imageObject: editDisplayMessage,
  setImageObject: setEditDisplayMessage,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
}) {
  return (
    <CardTemplateMsg
      edit={!disabled}
      cardObject={editDisplayMessage}
      setCardObject={setEditDisplayMessage}
      cardType={TIME_SLOT_VIEWS.displayMsgTemplateEditWithType}
      showHeading={false}
      view="default"
    />
  )
}
