import { CSmartTable } from '@coreui/react-pro'
import { Android, Apple, CopyAll, Http, KeyboardArrowDown } from '@mui/icons-material'
import { Button, Checkbox, Divider, MenuItem } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ALL_AVAILABLE_PLATFORMS } from 'src/utilities/constants'
import OpenTilePolicyInNewTab from './OpenTilePolicyInNewTab'
import TabTableItem from './TabTableItem'
import { TilePoliciesSelectionStyledMenu } from './styledComponent'

export default function TabSelectionComponent({
  androidTilePolicies,
  webTabTilePolicies,
  iosTabTilePolicies,
  androidTabList = [],
  webTabList = [],
  iosTabList = [],
  dropDownOptions,
  handleChangeAndroid,
  handleChangeWeb,
  handleChangeIos,
  disabled,
  iconView,
}) {
  const allTabs = dropDownOptions?.allTabs
  const tdStyles = { width: '300px' }

  const platformToTabListMap = useMemo(() => {
    return {
      [ALL_AVAILABLE_PLATFORMS.android]: androidTabList,
      [ALL_AVAILABLE_PLATFORMS.web]: webTabList,
      [ALL_AVAILABLE_PLATFORMS.ios]: iosTabList,
    }
  }, [androidTabList, webTabList, iosTabList])

  const handleChangeSelectMap = useMemo(
    () => ({
      [ALL_AVAILABLE_PLATFORMS.android]: handleChangeAndroid,
      [ALL_AVAILABLE_PLATFORMS.web]: handleChangeWeb,
      [ALL_AVAILABLE_PLATFORMS.ios]: handleChangeIos,
    }),
    [handleChangeAndroid, handleChangeWeb, handleChangeIos],
  )

  const handleToggleTab = useCallback(
    (tabId, platform) => {
      handleChangeSelectMap?.[platform]((prev) => {
        const newTabList = (prev?.tabs || []).includes(tabId)
          ? [...(prev?.tabs || [])?.filter((tab) => tab !== tabId)]
          : [...(prev?.tabs || []), tabId]

        switch (platform) {
          case ALL_AVAILABLE_PLATFORMS.android:
          case ALL_AVAILABLE_PLATFORMS.ios:
            if (newTabList.length > 5 && newTabList.length > (prev?.tabs || [])?.length) {
              toast.info("You can't select more than 5 tabs")
              return prev
            }
            return {
              ...prev,
              tabs: newTabList,
            }
          default:
            return {
              ...prev,
              tabs: newTabList,
            }
        }
      })
    },
    [handleChangeSelectMap],
  )

  const handleMoveTabsPlatform = useCallback(
    (fromPlatform, toPlatform) => {
      const tabList = [...(platformToTabListMap?.[fromPlatform] || [])]
      switch (toPlatform) {
        case ALL_AVAILABLE_PLATFORMS.android:
          handleChangeSelectMap?.[ALL_AVAILABLE_PLATFORMS.android]((prev) => ({
            ...prev,
            tabs: tabList,
          }))
          break
        case ALL_AVAILABLE_PLATFORMS.web:
          handleChangeSelectMap?.[ALL_AVAILABLE_PLATFORMS.web]((prev) => ({
            ...prev,
            tabs: tabList,
          }))
          break
        case ALL_AVAILABLE_PLATFORMS.ios:
          handleChangeSelectMap?.[ALL_AVAILABLE_PLATFORMS.ios]((prev) => ({
            ...prev,
            tabs: tabList,
          }))
          break
        default:
          Object.keys(ALL_AVAILABLE_PLATFORMS).forEach((key) => {
            if (key !== fromPlatform) {
              handleChangeSelectMap?.[key]((prev) => ({ ...prev, tabs: tabList }))
            }
          })
      }
    },
    [handleChangeSelectMap, platformToTabListMap],
  )

  //   function handleToggle

  const scopedColumns = {
    tabs: (item) => {
      return (
        <TabTableItem
          item={item}
          disableShortCutMenu={item?.id === 'table___shortcut'}
          tableImageSxOverride={{ opacity: item?.id === 'table___shortcut' && 0 }}
          iconView={iconView}
        />
      )
    },
    android: (item) => {
      const currentTilePol = androidTilePolicies?.[item?.id]
      return (
        <td style={tdStyles}>
          {item?.android === 'copy-to-all' ? (
            <TabShortCutComponent
              currentPlatform={ALL_AVAILABLE_PLATFORMS.android}
              handleMoveTabsPlatform={handleMoveTabsPlatform}
              disabled={disabled}
            />
          ) : (
            <div className="d-flex align-items-center justify-content-start gap-2">
              <Checkbox
                checked={androidTabList?.includes(item?.id)}
                onChange={() => {
                  handleToggleTab(item?.id, ALL_AVAILABLE_PLATFORMS.android)
                }}
                disabled={disabled}
              />
              {item?.curatable && (
                <>
                  Attached Tile:
                  {currentTilePol
                    ? currentTilePol?.name || currentTilePol?.title || 'No Title'
                    : 'Auto Default'}
                  <OpenTilePolicyInNewTab tabId={item?.id} tileObject={currentTilePol} />
                </>
              )}
            </div>
          )}
        </td>
      )
    },
    web: (item) => {
      const currentTilePol = webTabTilePolicies?.[item?.id]
      return (
        <td style={tdStyles}>
          {item?.web === 'copy-to-all' ? (
            <TabShortCutComponent
              currentPlatform={ALL_AVAILABLE_PLATFORMS.web}
              handleMoveTabsPlatform={handleMoveTabsPlatform}
              disabled={disabled}
            />
          ) : (
            <div className="d-flex align-items-center justify-content-start gap-2">
              <Checkbox
                checked={webTabList?.includes(item?.id)}
                onChange={() => {
                  handleToggleTab(item?.id, ALL_AVAILABLE_PLATFORMS.web)
                }}
                disabled={disabled}
              />
              Attached Tile:{' '}
              {currentTilePol
                ? currentTilePol?.name || currentTilePol?.title || 'No Title'
                : 'Auto Default'}
              <OpenTilePolicyInNewTab tabId={item?.id} tileObject={currentTilePol} />
            </div>
          )}
        </td>
      )
    },
    ios: (item) => {
      const currentTilePol = iosTabTilePolicies?.[item?.id]
      return (
        <td style={tdStyles}>
          {item?.ios === 'copy-to-all' ? (
            <>
              <TabShortCutComponent
                currentPlatform={ALL_AVAILABLE_PLATFORMS.ios}
                handleMoveTabsPlatform={handleMoveTabsPlatform}
                disabled={disabled}
              />
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-start gap-2">
              <Checkbox
                checked={iosTabList?.includes(item?.id)}
                onChange={() => {
                  handleToggleTab(item?.id, ALL_AVAILABLE_PLATFORMS.ios)
                }}
                disabled={disabled}
              />
              Attached Tile:{' '}
              {currentTilePol
                ? currentTilePol?.name || currentTilePol?.title || 'No Title'
                : 'Auto Default'}
              <OpenTilePolicyInNewTab tabId={item?.id} tileObject={currentTilePol} />
            </div>
          )}
        </td>
      )
    },
  }

  const allColumns = [
    { key: 'tabs', label: 'Tab Name' },
    { key: 'android', label: 'Android (Max 5)', _style: { minWidth: '300px' } },
    { key: 'web', label: 'Web (Unlimited)', _style: { minWidth: '300px' } },
    { key: 'ios', label: 'iOS (Max 5)', _style: { minWidth: '300px' } },
  ]

  return (
    <>
      <CSmartTable
        items={[
          ...(allTabs || []),
          {
            id: 'table___shortcut',
            title: 'Shortcuts',
            android: 'copy-to-all',
            web: 'copy-to-all',
            ios: 'copy-to-all',
          },
        ]}
        scopedColumns={scopedColumns}
        columns={allColumns}
        itemsPerPage={100000}
        tableProps={{
          striped: true,
          align: 'middle',
          responsive: true,
        }}
      />
    </>
  )
}

function TabShortCutComponent({ currentPlatform, handleMoveTabsPlatform, disabled }) {
  const [openMenu, setOpenMenu] = useState(null)
  const platformDependentMenuItems = useMemo(() => {
    return [
      {
        id: ALL_AVAILABLE_PLATFORMS.android,
        component: (
          <MenuItem
            onClick={() => {
              handleMoveTabsPlatform(currentPlatform, ALL_AVAILABLE_PLATFORMS.android)
              handleCloseMenu()
            }}
            disableRipple
          >
            <Android />
            Copy to Android
          </MenuItem>
        ),
      },
      {
        id: ALL_AVAILABLE_PLATFORMS.web,
        component: (
          <MenuItem
            onClick={() => {
              handleMoveTabsPlatform(currentPlatform, ALL_AVAILABLE_PLATFORMS.web)
              handleCloseMenu()
            }}
            disableRipple
          >
            <Http />
            Copy to Web
          </MenuItem>
        ),
      },
      {
        id: ALL_AVAILABLE_PLATFORMS.ios,
        component: (
          <MenuItem
            onClick={() => {
              handleMoveTabsPlatform(currentPlatform, ALL_AVAILABLE_PLATFORMS.ios)
              handleCloseMenu()
            }}
            disableRipple
          >
            <Apple />
            Copy to iOS
          </MenuItem>
        ),
      },
    ]
  }, [currentPlatform, handleMoveTabsPlatform])

  function handleOpenMenu(event) {
    setOpenMenu(event.currentTarget)
  }
  function handleCloseMenu() {
    setOpenMenu(null)
  }

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDown />}
        onClick={handleOpenMenu}
        variant="contained"
        disabled={disabled}
      >
        Options
      </Button>
      <TilePoliciesSelectionStyledMenu
        anchorEl={openMenu}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        {platformDependentMenuItems.map((item) => (
          <>{item?.id === currentPlatform ? null : item?.component}</>
        ))}
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            handleMoveTabsPlatform(currentPlatform)
            handleCloseMenu()
          }}
          disableRipple
        >
          <CopyAll />
          Copy to All
        </MenuItem>
      </TilePoliciesSelectionStyledMenu>
    </>
  )
}
