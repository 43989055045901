import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const IN_APP_MSG_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'in-app-msgs',
  internalTitle: '',
  title: '',
  description: '',
  templateId: '',
  status: 'draft',
  templateData: {
    apiParam: 'in-app-msg-template-data',
    enabled: true,
    deleted: false,
    idByUser: false,
    title: '',
    subText: '',
    imageUrl: 'https://picsum.photos/400/400/?image=20',
    imageType: 'icon',
    actionBtn: true,
    actionBtnText: '',
    actionBtnUri: '',
    extraLink: false,
    extraLinkText: '',
    extraLinkUri: '',
    bgColor: '#1b3151',
    textColor: '#ffffff',
    crossBtnColor: '#ffffff',
    subTextColor: '#aaaaaa',
    actionBtnBgColor: '#567076',
    actionBtnTxtColor: '#ffffff',
    externalLinkColor: '#585250',
    externalLinkBgColor: '#ffffff',
    msgBoxBgColor: '#e3e3e3',
    msgBoxTxtColor: '#000000',
    textBox: true,
    metaInfo: [],
  },
  criteria: {
    userState: '',
    priority: 0,
    testUser: '',
    screens: [],
    displayFrequency: 0,
    displayMax: 0,
    countries: [],
    genders: [],
    languages: [],
    ageGroups: [],
    appPlatforms: [],
    subscriptions: [],
    subscriptionTypes: [],
    preferredTimes: [],
    primaryTags: [],
    secondaryTags: [],
    appLanguages: [],
    teacherIds: [],
    courseIds: [],
    criteriaTopics: [],
    showAlways: true,
    userTags: [],
  },
  translations: {},
  appLanguages: ['en'],
}
