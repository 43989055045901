export const SETUP_APIS = {
  tiles: {
    get: {
      url: 'tiles/summary',
    },
    getSingle: {
      url: 'tiles/detail',
    },
    save: {
      url: 'tiles/save',
    },
  },
  tilePolicies: {
    get: { url: '/policy/paginated-list' },
    getSummaryList: {
      url: '/policies/summary',
    },
    getSingle: { url: 'tile/details' },
    save: { url: 'tile/policy/save' },
  },
  pages: {
    get: { url: '/page/app-page/summary/list' },
    save: { url: '/page/app-page/save' },
  },
  cardLayoutTypes: {
    get: { url: '/page/card/layout-types' },
    save: { url: '/page/card/layout-types/save' },
  },
}
