import React, { useMemo } from 'react'
import { IdTypes } from 'src/utilities/constants'
import DLIdOrSlug from './DLIdSlug/DLIdOrSlug'
import DLType from './DLType/DLType'

export default function DeepLinkItemFactory({
  item,
  handleChangeItem,
  metaCategoryId,
  idType,
  dropDownOptions,
  setAdditionalData,
  additionalData,

  extraData,
  handleChangeExtraData,

  actionData,
  handleActionData,

  uiContextData,
  disabled,
}) {
  const ComponentToRender = useMemo(() => {
    switch (idType) {
      case IdTypes.id:
      case IdTypes.slug:
        return DLIdOrSlug
      case IdTypes.type:
        return DLType
      default:
        return React.Fragment
    }
  }, [idType])

  return (
    <>
      <ComponentToRender
        categoryType={metaCategoryId}
        {...{
          item,
          handleChangeItem,
          idType,
          dropDownOptions,
          setAdditionalData,
          additionalData,

          extraData,
          handleChangeExtraData,

          actionData,
          handleActionData,

          uiContextData,
          disabled,
        }}
      />
    </>
  )
}
