import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { GetData } from 'src/services/APIs/DailyPlay/GetLists'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import GridListingComponent from '../GridListingComponent'
import SentinelCardView from '../SentinelCardView'
import DisplayMessageTemplateView from './DisplayMessageTemplateView'

export default function DisplayTemplateMessageGrid({ onChange, type, handleClose }) {
  const [loading, setLoading] = useState(true)
  const [displayMessages, setDisplayMessages] = useState([])

  async function getDisplayMessages() {
    const response = await GetData(GENERIC_APIS.entity.getPaginated, {
      entityType: 'display-msg-template',
      type: type,
      size: 999,
    })
    if (response.status === 200) {
      setDisplayMessages(response.data?.content)
    }
  }
  async function handleClick(item, loadingSetter) {
    loadingSetter(true)
    const detailedEntity = await GetDetailedEntity('display-msg-template', item)
    onChange((prev) => {
      return {
        ...detailedEntity,
        id: prev?.id || '',
      }
    })
    loadingSetter(false)
    handleClose()
  }

  async function Setup() {
    setLoading(true)
    await Promise.all([getDisplayMessages()])
    setLoading(false)
  }

  function renderMessages(item) {
    return item.type === 'sentinelCard' ? (
      <SentinelCardView handleClick={handleClick} item={item} />
    ) : (
      <DisplayMessageTemplateView handleClick={handleClick} item={item} />
    )
  }

  useEffect(() => {
    Setup()
  }, [])

  return (
    <>
      <GridListingComponent
        items={displayMessages}
        customItemBox={renderMessages}
        itemsPerBreakPoint={{ xs: 1, md: 2 }}
        loading={loading}
      />
    </>
  )
}

DisplayTemplateMessageGrid.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
  handleClose: PropTypes.func,
}
