import React, { useState } from 'react'
import { TabWrapper } from 'src/components/controlCenter/common/sectionComponents/TabWrapper'
import { StyledTabButton, StyledTabContainer } from 'src/components/controlCenter/styledComponents'
import UserDonationHistory from './DonationHistory'
import SubscriptionHistory from './SubscriptionHistory'
import TransactionHistory from './TransactionHistory'

const tabs = {
  subscriptionHistory: {
    id: 'subscriptionHistory',
    title: 'Subscription History',
  },
  transactionHistory: {
    id: 'transactionHistory',
    title: 'Transaction History',
  },
  donationHistory: {
    id: 'donationHistory',
    title: 'Donation History',
  },
}

export default function SubscriptionTabsPage({ userId }) {
  const [selectedTab, setSelectedTab] = useState(tabs.subscriptionHistory.id)
  const [data, setData] = useState({
    subscriptionHistory: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    transactionHistory: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    donationHistory: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
  })

  function setSubscriptionHistory(prevData) {
    if (typeof prevData === 'function') {
      setData((prev) => ({
        ...prev,
        subscriptionHistory: prevData(prev.subscriptionHistory),
      }))
    } else {
      setData((prev) => ({
        ...prev,
        subscriptionHistory: prevData,
      }))
    }
  }

  function setTransactionHistory(prevData) {
    if (typeof prevData === 'function') {
      setData((prev) => ({
        ...prev,
        transactionHistory: prevData(prev.transactionHistory),
      }))
    } else {
      setData((prev) => ({
        ...prev,
        transactionHistory: prevData,
      }))
    }
  }

  function setDonationHistory(prevData) {
    if (typeof prevData === 'function') {
      setData((prev) => ({
        ...prev,
        donationHistory: prevData(prev.donationHistory),
      }))
    } else {
      setData((prev) => ({
        ...prev,
        donationHistory: prevData,
      }))
    }
  }

  function handleChangeTab(tabId) {
    setSelectedTab(tabId)
  }

  return (
    <>
      <StyledTabContainer
        sx={{
          position: 'sticky',
          top: 'calc(var(--header-height))',
          zIndex: '999',
          background: (theme) =>
            `${
              theme.palette.mode === 'dark'
                ? 'var(--cui-body-bg)'
                : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
            }`,
          borderBottom: '1px solid var(--cui-border-color)',
          padding: '15px 0px',
        }}
      >
        {Object.values(tabs).map((section) => (
          <StyledTabButton
            key={section.id}
            selected={selectedTab === section.id}
            onClick={() => handleChangeTab(section.id)}
          >
            {section.title}
          </StyledTabButton>
        ))}
      </StyledTabContainer>

      <TabWrapper
        tabBoxProps={{
          sx: {
            padding: '0px',
          },
        }}
        currentValue={selectedTab}
        tabValue={tabs.subscriptionHistory.id}
      >
        <SubscriptionHistory
          data={data.subscriptionHistory}
          setData={setSubscriptionHistory}
          userId={userId}
        />
      </TabWrapper>
      <TabWrapper
        tabBoxProps={{
          sx: {
            padding: '0px',
          },
        }}
        currentValue={selectedTab}
        tabValue={tabs.transactionHistory.id}
      >
        <TransactionHistory
          data={data.transactionHistory}
          setData={setTransactionHistory}
          userId={userId}
        />
      </TabWrapper>
      <TabWrapper
        tabBoxProps={{
          sx: {
            padding: '0px',
          },
        }}
        currentValue={selectedTab}
        tabValue={tabs.donationHistory.id}
      >
        <UserDonationHistory
          data={data.donationHistory}
          setData={setDonationHistory}
          userId={userId}
        />
      </TabWrapper>
    </>
  )
}
