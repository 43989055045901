import { FormControlLabel, Grid, Switch } from '@mui/material'
import React from 'react'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import { CCTextArea, CCFormInput } from 'src/components/controlCenter/FormComponents'

export default function MedicalBoardFormComponent({
  initialObject,
  imageObject: medicalBoardObject,
  setImageObject: setMedicalBoardObject,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
}) {
  return (
    <div className="d-flex">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                id="enabled"
                checked={!!medicalBoardObject?.enabled || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={!!medicalBoardObject?.enabled ? 'Enabled' : 'Disabled'}
          />
        </Grid>
        <Grid item xs={12}>
          <CCFormInput
            label="Name"
            id={'name'}
            value={medicalBoardObject?.name}
            onChange={handleChangeObject}
            key={'name'}
            placeholder={'Name'}
            disabled={disabled}
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <CCFormInput
            label="Designation"
            id={'designation'}
            value={medicalBoardObject?.designation}
            onChange={handleChangeObject}
            key={'description'}
            placeholder={'Designation'}
            disabled={disabled}
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            label="Description"
            id={'description'}
            value={medicalBoardObject?.description}
            onChange={handleChangeObject}
            key={'description'}
            placeholder={'Description'}
            disabled={disabled}
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <EntityBasedImageUploadComponent
            disabled={disabled}
            entity={entity}
            entityObject={medicalBoardObject}
            handleChange={handleChangeObject}
          />
        </Grid>
      </Grid>
    </div>
  )
}
