import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const META_TAG_OBJECT = {
  altTexts: null,
  apiParam: 'tag',
  breatheListable: true,
  categories: [],
  colors: COLORS_OBJECT,
  countries: [],
  courseCount: 0,
  coverIcon: '',
  coverImage: '',
  customShortLink: '',
  deleted: false,
  enabled: true,
  exploreListable: true,
  featureUsage: {},
  focusAreaUsage: {},
  id: '',
  idByUser: true,
  journalListable: true,
  languages: [],
  listabilityPolicy: '',
  longDesc: '',
  musicListable: true,
  name: '',
  onboardingImage: '',
  popularity: 0,
  premiumQuote: true,
  prioritySetting: {},
  quoteListable: true,
  sdkListable: true,
  shortDesc: '',
  sleepListable: true,
  storyListable: true,
  tag: '',
  theme: null,
  tileAltText: {},
  translations: {},
  type: '',
  usage: {},
  welcomeMsg: '',
  yogaListable: true,
}
