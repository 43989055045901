import { getReduxStoreData } from 'src/getDataFromRedux'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { SETTINGS_API_LIST } from 'src/views/settings/utils/api-utils'

export async function transformCacheEvictListingData(listingData) {
  const reduxStore = getReduxStoreData()
  var apiParams = reduxStore.apiParams

  if (reduxStore.apiParams?.length <= 0) {
    const response = await GetSettingsData(SETTINGS_API_LIST.apiParams.get.url)
    if (response.status === 200) {
      apiParams = response.data
    }
  }

  var finalListingData = Object.entries(listingData).map(([cacheKey, metrics]) => {
    return {
      id: cacheKey,
      title: apiParams?.find((prm) => prm?.id === cacheKey)?.name || cacheKey,
      ...metrics,
    }
  })

  return finalListingData
}
