import { getReduxStoreData } from 'src/getDataFromRedux'
import { fetchFunction } from 'src/services'
import { TAG_TYPE } from 'src/utilities/constants'

export async function getItemTitleForTypeType(itemId, metaCategoryId) {
  const responseData = await getItemTitleForTypeId(
    itemId,
    metaCategoryId, //this is actually the metaCategoryType
  )
  return responseData
}

async function getItemTitleForTypeId(itemId, metaCategoryType) {
  switch (metaCategoryType) {
    case 'service-provider':
    case 'course':
      //get course category or mentor category
      const allCategories = getReduxStoreData()?.categories
      const category = allCategories?.find((category) => category.id === itemId)
      return [{ label: category.title }]
    case 'tag':
      const allTagTypes = Object.entries(TAG_TYPE).map(([key, value]) => ({
        value: key,
        label: value,
      }))
      return [allTagTypes.find((tagType) => tagType.value === itemId)]
    // get tag type
    case 'tiles':
      // get metaTileTabItem
      const tabTileData = await fetchFunction('tiles/detail', { id: itemId })
      if (tabTileData) {
        return [{ ...tabTileData, label: tabTileData.title }]
      }
      return [{ label: itemId }]

    default:
      return null
  }
}
