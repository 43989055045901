import { Chip, Grid } from '@mui/material'
import React from 'react'

export default function FocusAreaHandler({ focusArea, dropDownData }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <strong>{focusArea?.title}</strong>
      </Grid>
      <Grid item xs={12} display="flex" flexWrap="wrap" gap="5px" alignItems="center">
        <span>Genre:</span>
        {focusArea?.genre?.map((genre) => {
          const genreData = dropDownData?.tags?.find((tag) => tag.id === genre)
          return <Chip key={genre} label={genreData?.title || genreData?.tag || genre} />
        })}
      </Grid>
      <Grid item xs={12} display="flex" flexWrap="wrap" gap="5px" alignItems="center">
        <span>Tags:</span>
        {focusArea?.tags?.map((tag) => {
          const tagData = dropDownData?.tags?.find((tag) => tag.id === tag)
          return <Chip key={tag} label={tagData?.title || tagData?.tag || tag} />
        })}
      </Grid>
      <Grid item xs={12} display="flex" flexWrap="wrap" gap="5px" alignItems="center">
        <span>Feeling:</span>
        {focusArea?.feeling?.map((feeling) => {
          const feelingData = dropDownData?.tags?.find((tag) => tag.id === feeling)
          return <Chip key={feeling} label={feelingData?.title || feelingData?.tag || feeling} />
        })}
      </Grid>
    </Grid>
  )
}
