import moment from 'moment'
import React from 'react'
import { ALL_GENDERS_IN_APP_MSGS } from 'src/utilities/constants'
import AppPlatformIcon from '../../common/AppPlatformIcon'

export default function UserTitleComponent({ userData }) {
  const foundGender = ALL_GENDERS_IN_APP_MSGS?.find((gendr) => gendr?.id === userData?.gender)
  const today = moment()
  const dob = moment(userData?.dob)
  const age = today.diff(dob, 'years')

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        {userData?.firstName || userData?.email || userData?.mobile}
        <span style={{ marginLeft: '5px', flexShrink: '0' }}>
          <AppPlatformIcon appPlatformId={userData?.latestUserDevice?.platform} width={15} />
        </span>
      </span>
      {!!userData?.email && <span className="fw-light">{userData?.email}</span>}
      <span className="fw-light">{foundGender?.title || userData?.gender}</span>
      {!userData?.dob ? (
        <span className="fw-light">Age: NA</span>
      ) : (
        <span className="fw-light">Age: {age} Yrs</span>
      )}
      <span>
        {userData?.personalize?.studentPersonalize?.userLanguage} |{' '}
        {userData?.personalize?.studentPersonalize?.contentLanguages?.join(', ')}
      </span>
    </div>
  )
}
