import { ExpandMore } from '@mui/icons-material'
import { ButtonBase, Chip, Grid, Paper, styled as muiStyled } from '@mui/material'
import styled from 'styled-components'

export const StyledMainHeadingCustomPlan = muiStyled('div')`
    font-size: 40px;
    font-weight: 600;
`
export const StyledMainSubHeadingCustomPlan = muiStyled('div')`
    font-size: 18px;
    font-weight: 400;
`
export const StyledCustomPlanFormGridContainer = muiStyled(Grid)`
 padding: 24px 0px;
 margin-top: 0px;
`
export const PhaseDataContainer = muiStyled('div')(
  ({ theme, isDragging }) => `
  padding-top: 10px;
  transition: all 150ms ease-in-out;
`,
)
export const PhaseTitle = muiStyled('div')`
    font-size: 24px;
    font-weight: 600;
`
export const PhaseSectionTitle = muiStyled('div')`
    font-size: 35px;
    font-weight: 600;
`
export const PhaseSectionSubTitle = muiStyled('div')`
    font-size: 15px;
    font-weight: 400;
`
export const PhaseNumberContainer = muiStyled(Chip)`
font-size: 20px;
font-weight:400;
`
export const PhaseTitleContainer = muiStyled('div')`
display: flex;
justify-content: start;
align-items: center;
gap: 15px;
`

export const PhaseDayRangeSubtitle = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: flex-start;
    align-items:center;
    gap:12px;
    font-size: 14px;
    color: rgb(0 0 0 / 48%);
    background:#001cff4f;
    border-radius: 50px;
    padding: 5px 10px;
    color: ${theme.palette.text.secondary};
    > strong{
        font-weight: 600;
    }
`,
)

export const StyledContentEditPaper = muiStyled(Paper)`
// font-family: Outfit;
    max-width: 768px !important;
    width: 100%;
    border-radius: 7px;
    height: 100%;
    margin: 0px !important;
    overflow-y: hidden !important;
`

export const CustomPlanSectionContainer = muiStyled('div')`
padding: 10px 0px`

export const PlanPhaseTabsContainer = muiStyled('div')`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0px;
    overflow-x: auto;
`
export const PlanPhaseTab = muiStyled(ButtonBase)(
  ({ theme, selected }) => `
padding: 7px 20px;
border-radius: 50px;
color: ${selected ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000'};
background: ${selected ? theme.palette.primary.main : 'transparent'};
transition: all 150ms linear;
flex-shrink: 0;
`,
)

export const PhaseContentReorderPaper = muiStyled(Paper)`
    width: 90vw;
    max-width: 100vw !important;
    height: 90vh;
    padding: 10px;
    border-radius: 10px;

`
export const CollapseIcon = muiStyled(ExpandMore)(
  ({ theme, collapsed, time = '0.4s' }) => `
    transform: ${collapsed ? 'rotate(0deg)' : 'rotate(180deg)'};
        transition: all ${time} cubic-bezier(0.42, 0.03, 0.16, 0.94);
`,
)

export const ActivityContainer = muiStyled('div')(
  ({ theme, main }) => `
    border-radius: 7px;
    padding: 18px;
`,
)

export const PlannedActivitiesTabsContainer = muiStyled('div')(
  ({ theme }) => `
    background: transparent;
    border-radius: 7px;
    padding: 7px;
    // max-height: 1010px;
    // overflow-y: auto;

`,
)

export const PlannedActivityTab = muiStyled('div')(
  ({ theme, selected }) => `
    width: 100%;
    background: ${selected ? theme.palette.primary.main : '#ebebeb'};
    padding: 10px 25px;
    border-radius: 7px;
    color: ${selected ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000'};
`,
)

export const PhaseLegendDiv = muiStyled('div')(
  ({ theme, legendColor }) => `
  position: relative;
    ::before {
      position: absolute;
      content: '';
      height: 5px;
      bottom: -2px;
      background: ${legendColor};
      width: 100%;
    }
`,
)

export const PhaseLegendVerticalDiv = muiStyled('div')(
  ({ theme, legendColor }) => `
  height: inherit;
  width: 7px;
  background: ${legendColor};
  flex-shrink:0;
`,
)

export const PhaseLegendsContainer = muiStyled('div')(
  ({ theme }) => `
position: sticky;
top: calc(var(--header-height) + 70px);
background: ${
    theme.palette.mode === 'dark'
      ? 'var(--cui-body-bg)'
      : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
  };
z-index: 3;
`,
)

export const UserActivityPlanPhaseDayItemContainer = styled.div`
  position: relative;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  ${({ noBorder }) => !noBorder && 'border-top: 1px solid #ffffff4a;'}
  transition: background 150ms cubic-bezier(0.04, 0.65, 0.39, 0.98);
  ${({ showPointer }) => showPointer && 'cursor: pointer;'}
  background: ${({ active, customBackground }) => (active ? customBackground : '#50416c')};
  padding: ${({ active }) => (active ? '15px 0px' : '0px')};
  background-size: cover;
  @media (min-width: 980px) {
    padding: ${({ active }) => (active ? '45px 0px' : '0px')};
  }
  ${
    '' /* &:hover {
    background: #0000005e;
  } */
  }
`
