import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const fetchOOHScreensForCurrentClinic = createAsyncThunk(
  'clinicFlowData/fetchOOHScreensForCurrentClinic',
  async (_, { getState, dispatch }) => {
    dispatch(fetchOOHScreensForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinicData = clinicFlowData.currentClinicData.data

    const resp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.oohMsg,
      clinicId: currentClinicData?.id,
    })
    if (resp) {
      return resp
    } else {
      dispatch(fetchOOHScreensForCurrentClinic.rejected(resp))
      return clinicFlowData.currentClinicData.data
    }
  },
)
