import {
  getSavableSubscriptionPlan,
  getUploadablePricingMatrix,
} from 'src/views/subscriptions/plans/utils'
import { transformBundleField } from './transformIndividualFields/transformBundleField'

export async function transformSendBundlePacksData(formedData, customStoreData) {
  let dataToWorkWith = getSavableSubscriptionPlan({
    ...formedData,
    pricingMatrix: getUploadablePricingMatrix(formedData?.pricingMatrix || []),
  })

  dataToWorkWith.bundle = await transformBundleField(dataToWorkWith.bundle)

  return dataToWorkWith
}
