import { ThemeProvider as MaterialThemeProvider, createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('color-theme')
    if (typeof storedPrefs === 'string') {
      return storedPrefs
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: dark)')
    if (userMedia.matches) {
      return 'dark'
    }
  }

  return 'light' // light theme as the default;
}

export const ThemeContext = React.createContext()

export const ThemeProvider = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState(getInitialTheme)
  const [store, dispatch] = useAppSettingsStore()
  const materialTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: theme,
          primary: {
            main: theme === 'dark' ? 'rgb(133, 125, 204)' : '#4335c2',
            mainRGB: theme === 'dark' ? '133, 125, 204' : '67, 53, 194',
            light: 'rgb(50, 31, 219)',
            dark: '#1f1498',
          },
          secondary: {
            main: 'rgb(157, 165, 177)',
            light: 'rgb(157, 165, 177)',
            dark: 'rgb(167, 167, 167)',
          },
          tertiary: {
            main: theme === 'dark' ? '#dda6ff' : '#846597',
            light: theme === 'dark' ? '#846597' : '#846597', // implemented in the future
            dark: theme === 'dark' ? '#846597' : '#846597', // implemented in the future
          },
          error: {
            main: 'rgb(229, 83, 83)',
            dark: 'rgb(199, 113, 113)',
            contrast: theme === 'dark' ? 'rgb(98,38,38)' : 'rgb(255,106,106)',
          },
          warning: {
            main: 'rgb(249, 177, 21)',
            dark: 'rgb(213, 164, 57)',
          },
          info: {
            main: 'rgb(51, 153, 255)',
            dark: 'rgb(82, 153, 224)',
          },
          success: {
            main: 'rgb(46, 184, 92)',
            dark: 'rgb(81, 150, 104)',
          },
          whiteBlack: {
            main: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',
            light: theme === 'dark' ? 'rgb(255, 255, 255, 0.9)' : '#383838',
          },
          background: {
            dividingContainer:
              theme !== 'dark' ? 'var(--cui-border-color)' : 'var(--cui-border-color)',
            dialogBackground: theme !== 'dark' ? '#fff' : '#383838',
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1400,
          },
        },
        typography: {
          fontFamily: 'inherit',
          h1: {
            fontSize: '40px',
            fontWeight: 600,
          },
          h2: {
            fontSize: 'calc(1.325rem + 0.9vw)',
            fontWeight: 600,
          },
          h6: {
            fontSize: 'calc(1.325rem + 0.9vw)',
            fontWeight: 600,
            '@media (min-width:768px)': {
              fontSize: '30px',
            },
          },
        },
        components: {
          MuiDialogContent: {
            styleOverrides: {
              root: {
                padding: '20px 20px',
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                fontSize: '30px',
              },
            },
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderWidth: '1px',
                borderColor: theme === 'dark' ? 'rgb(255, 255, 255, 0.9)' : 'rgb(0 ,0, 0 , 0.7)',
              },
            },
          },
        },
        spacing: (factor) => `${0.25 * factor}rem`,
      }),
    [theme],
  )
  const rawSetTheme = (rawTheme) => {
    const root = window.document.documentElement
    const isDark = rawTheme === 'dark'

    root.classList.remove(isDark ? 'light' : 'dark')
    root.classList.add(rawTheme)

    localStorage.setItem('color-theme', rawTheme)
  }

  if (initialTheme) {
    rawSetTheme(initialTheme)
  }

  React.useEffect(() => {
    dispatch({ type: 'set', theme: theme })
    rawSetTheme(theme)
  }, [theme])
  return (
    <MaterialThemeProvider theme={materialTheme}>
      <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>
    </MaterialThemeProvider>
  )
}

ThemeProvider.propTypes = {
  initialTheme: PropTypes.string,
  children: PropTypes.node.isRequired,
}
