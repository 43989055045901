import { CBadge } from '@coreui/react-pro'
import { CopyAll } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React from 'react'
import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'
import { apiURL } from 'src/services'
import { copyToClipBoard } from 'src/utilities/generalUtils'
import TableImage from '../components/TableImage'
import { MaxWidthTableData } from '../components/styledComponents'

export function IMAGE_GALLERY_CONFIG_SCOPED_COLUMNS(allImageTypes, handleImageClick) {
  return {
    id: (item) => {
      return (
        <MaxWidthTableData
          style={{ cursor: 'pointer' }}
          onClick={() => {
            copyToClipBoard('ID', item.imageId)
          }}
          className="py-2"
        >
          <div className="d-flex justify-content-start align-items-center gap-2">
            <Tooltip title={item.imageId}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <span>Copy</span>
              </div>
            </Tooltip>
            <CopyAll />
          </div>
        </MaxWidthTableData>
      )
    },
    tags: (item) => {
      return (
        <td className="py-2">
          <div onClick={(e) => e.stopPropagation()}>
            {item?.tags?.map((tag) => {
              return (
                <CBadge key={tag} color="secondary">
                  {tag}
                </CBadge>
              )
            })}
          </div>
        </td>
      )
    },
    type: (item) => {
      return (
        <td className="py-2">
          <div onClick={(e) => e.stopPropagation()}>
            {allImageTypes.find((type) => item.type === type.id)?.title || item.type}
          </div>
        </td>
      )
    },
    hasAlpha: (item) => (
      <td className="py-2">
        <div onClick={(e) => e.stopPropagation()}>{item?.hasAlpha || 'false'}</div>
      </td>
    ),
    imageId: (image, index) => (
      <>
        {image.apiParam === 'subtitle' ? (
          <td>
            <a
              href={`${apiURL}/api/media/file/${image?.fileId}`}
              rel="noreferrer"
              target="_blank"
              loading="lazy"
            >
              {image.fileId}
            </a>
          </td>
        ) : (
          <TableImage image={image} idx={index} handleImageClick={handleImageClick} />
        )}
      </>
    ),
  }
}

export const COLOR_OBJECT = COLORS_OBJECT

export const META_IMAGES_OBJECT = {
  altColor: null,
  apiParam: 'images',
  collection: '',
  altText: '',
  colors: null,
  deleted: false,
  enabled: true,
  hasBorder: true,
  hasText: false,
  id: '',
  imageId: '',
  quoteEnabled: false,
  subtype: '',
  tags: [],
  type: '',
  typeBkp: '',
}

export const ICONS_OBJECT = {
  apiParam: 'icon',
  id: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  key: '',
  name: '',
  fileId: '',
}

export const TabsToApiParamMapper = {
  0: 'images',
  1: 'avatar',
  2: 'subtitle',
  3: 'icon',
  images: 0,
  avatar: 1,
  subtitle: 2,
  icon: 3,
}
