import { putActionDataForNotificationsToItsPosition } from './transformPostDataFactory/putActionsToTheirPosition/factory'
import { putAdditionalDataForNotificationsToItsPosition } from './transformPostDataFactory/putAdditionalDataToItsPosition/factory'
import { putExtraDataForNotificationsToItsPosition } from './transformPostDataFactory/putExtraDataToItsPosition/factory'

export function transformPostNotificationData(data) {
  const categoryType = data?.dlData?.metaCategoryId
  let { dlData, ...dataToUse } = data

  dataToUse = putAdditionalDataForNotificationsToItsPosition(dataToUse, dlData, categoryType)
  dataToUse = putActionDataForNotificationsToItsPosition(dataToUse, dlData, categoryType)
  dataToUse = putExtraDataForNotificationsToItsPosition(dataToUse, dlData, categoryType)

  return {
    ...dataToUse,
    sourceId: dlData?.itemId,
    subType: dlData?.idType,
    type: dlData?.metaCategoryId,
    translations: {
      en: {
        title: data?.title,
        description: data?.description,
      },
    },
  }
}
