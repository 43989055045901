import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { checkIfSlugValid } from 'src/utilities/generalUtils'
import { CCFormInput } from '../../FormComponents'

export default function SlugCheckerComponent({
  slugValidation,
  setSlugValidation,
  value,
  initialValue,
  slugCheckApiSignature,
  slugCheckApiParams,
  onChange = () => {},
  label,
  subLabel,
  disabled,
  required,
  newEntity,
  initialInvalidationText = 'Your Slug is invalid.',
}) {
  const [slugValidationText, setSlugValidationText] = useState()
  const [slugValidationLoading, setSlugValidationLoading] = useState(false)
  const [slugText, setSlugText] = useState(value)
  const slugInvalid = useMemo(() => {
    return slugValidation?.unavailable || !slugValidation?.valid
  }, [slugValidation])

  async function validateSlug(currentSlug, slugApiParams = {}, initialSlug) {
    const validation = await checkIfSlugValid(
      initialSlug,
      currentSlug,
      slugCheckApiSignature,
      slugApiParams,
    )
    if (validation?.unavailable) {
      setSlugValidationText('This slug is already taken.')
    } else if (!validation?.valid) {
      setSlugValidationText('This slug is in the incorrect format.')
    }
    setSlugText(currentSlug)
    setSlugValidation(validation)
    setSlugValidationLoading(false)
  }

  const debSlugValidation = useCallback(
    debounce(async (slug, apiParams, initialSlug) => {
      await validateSlug(slug, apiParams, initialSlug)
    }, 500),
    [],
  )

  function handleSlug(event) {
    setSlugValidationLoading(true)
    onChange(event.target.value)

    debSlugValidation(event.target.value, slugCheckApiParams, initialValue)
  }

  useEffect(() => {
    console.log({ slugValidation })
  }, [slugValidation])

  useEffect(() => {
    if (newEntity) {
      setSlugValidation({ unavailable: false, valid: true })
    } else {
      validateSlug(value, slugCheckApiParams, initialValue)
    }
  }, [])

  return (
    <>
      <CCFormInput
        id="slug"
        label={label}
        subLabel={subLabel}
        value={value}
        disabled={disabled}
        placeholder="Enter the slug..."
        required={required}
        onChange={handleSlug}
        colNumber={12}
        invalid={slugInvalid}
        feedbackInvalid={slugValidation?.text}
      />
      {(slugValidation?.unavailable || !slugValidation?.valid) && (
        <span className="text-danger fs-6">{slugValidationText}</span>
      )}
      {(!slugValidation?.valid || slugValidation?.loading) && (
        <input value="" style={{ display: 'none' }} required={required} />
      )}
    </>
  )
}

// formData?.slug
// setFormData((prev) => ({ ...prev, slug: event.target.value }))
