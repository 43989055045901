import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppSettings } from 'src/reducers/appSettings/appSettingsReducer'

export const useAppSettingsStore = () => {
  const store = useSelector((state) => state.appSettings)
  var storeToUse = { ...store }
  const dispatch = useDispatch()

  const updateAppSettings = useCallback(
    (payload) => {
      dispatch(setAppSettings(payload))
    },
    [dispatch],
  )

  return [storeToUse, updateAppSettings]
}
