import { Grid } from '@mui/material'
import React from 'react'
import { SectionGridItem } from './styledComponents'

export default function GridSectionWrapper({
  headerComponents,
  heading,
  subheading,
  shadow = true,
  children,
  sectionContainerSx = {},
  sectionContainerProps = {},
}) {
  return (
    <SectionGridItem
      xs={12}
      {...sectionContainerProps}
      sx={sectionContainerSx}
      item
      shadow={shadow}
    >
      <Grid container spacing={2}>
        {heading && !headerComponents ? (
          <Grid item xs={12}>
            <h5>{heading}</h5>
          </Grid>
        ) : (
          headerComponents && (
            <Grid item xs={12}>
              {headerComponents}
            </Grid>
          )
        )}
        {children}
      </Grid>
    </SectionGridItem>
  )
}
