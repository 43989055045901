import React, { useState } from 'react'
import { TabWrapper } from 'src/components/controlCenter/common/sectionComponents/TabWrapper'
import { StyledTabButton, StyledTabContainer } from 'src/components/controlCenter/styledComponents'
import BreatheTechniques from './BreatheTechniques/BreatheTechniques'
import CourseSessions from './CourseSessions/CourseSessions'
import UserGuidedLive from './GuidedLive/UserGuidedLive'
import UserGuidedTrack from './GuidedTrack/UserGuidedTrack'
import OfflineClasses from './OfflineClasses/OfflineClasses'
import UserSelfPractice from './SelfPractice/UserSelfPractice'
import UserPlans from './UserPlans/UserPlans'

const tabs = {
  selfPractice: {
    id: 'selfPractice',
    title: 'Self Practice',
  },
  guidedLive: {
    id: 'guidedLive',
    title: 'Guided Live',
  },
  guidedTrack: {
    id: 'guidedTrack',
    title: 'Guided Track',
  },
  breathTechniques: {
    id: 'breathTechniques',
    title: 'Breathing Techniques',
  },
  courseSessions: {
    id: 'courseSessions',
    title: 'Course Sessions',
  },
  offlineClasses: {
    id: 'offlineClasses',
    title: 'Offline Classes',
  },
  userPlans: {
    id: 'userPlans',
    title: 'User Plans',
  },
}

export default function UsageDetailsTabs({ userId }) {
  const [selectedTab, setSelectedTab] = useState(tabs.selfPractice.id)
  const [data, setData] = useState({
    selfPractice: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    guidedLive: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    guidedTrack: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    breathTechniques: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    courseSessions: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    offlineClasses: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    userPlans: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
  })

  function setDataDynamically(prevData, key) {
    if (typeof prevData === 'function') {
      setData((prev) => ({
        ...prev,
        [key]: prevData(prev?.[key]),
      }))
    } else {
      setData((prev) => ({
        ...prev,
        [key]: prevData,
      }))
    }
  }

  function setSelfPractice(prevData) {
    setDataDynamically(prevData, 'selfPractice')
  }
  function setGuidedLive(prevData) {
    setDataDynamically(prevData, 'guidedLive')
  }
  function setGuidedTrack(prevData) {
    setDataDynamically(prevData, 'guidedTrack')
  }
  function setBreathingTechniques(prevData) {
    setDataDynamically(prevData, 'breathTechniques')
  }
  function setCourseSessions(prevData) {
    setDataDynamically(prevData, 'courseSessions')
  }
  function setOfflineClasses(prevData) {
    setDataDynamically(prevData, 'offlineClasses')
  }
  function setUserPlans(prevData) {
    setDataDynamically(prevData, 'userPlans')
  }

  function handleChangeTab(tabId) {
    setSelectedTab(tabId)
  }

  return (
    <>
      <StyledTabContainer
        sx={{
          position: 'sticky',
          top: 'calc(var(--header-height))',
          zIndex: '999',
          background: (theme) =>
            `${
              theme.palette.mode === 'dark'
                ? 'var(--cui-body-bg)'
                : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
            }`,
          borderBottom: '1px solid var(--cui-border-color)',
          padding: '15px 0px',
        }}
      >
        {Object.values(tabs).map((section) => (
          <StyledTabButton
            key={section.id}
            selected={selectedTab === section.id}
            onClick={() => handleChangeTab(section.id)}
          >
            {section.title}
          </StyledTabButton>
        ))}
      </StyledTabContainer>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.selfPractice?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <UserSelfPractice userId={userId} data={data.selfPractice} setData={setSelfPractice} />
      </TabWrapper>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.guidedLive?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <UserGuidedLive userId={userId} data={data.guidedLive} setData={setGuidedLive} />
      </TabWrapper>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.guidedTrack?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <UserGuidedTrack userId={userId} data={data.guidedTrack} setData={setGuidedTrack} />
      </TabWrapper>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.breathTechniques?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <BreatheTechniques
          userId={userId}
          data={data.breathTechniques}
          setData={setBreathingTechniques}
        />
      </TabWrapper>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.courseSessions?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <CourseSessions userId={userId} data={data.courseSessions} setData={setCourseSessions} />
      </TabWrapper>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.offlineClasses?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <OfflineClasses userId={userId} data={data.offlineClasses} setData={setOfflineClasses} />
      </TabWrapper>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.userPlans?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <UserPlans userId={userId} data={data.userPlans} setData={setUserPlans} />
      </TabWrapper>
    </>
  )
}
