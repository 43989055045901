import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import {
  assistantsForCurrentClinic,
  selectDoctorsForCurrentClinic,
  selectOOhMsgsForCurrentClinic,
  selectTherapistsForCurrentClinic,
} from 'src/reducers/clinicFlowData/clinicFlowDataReducer'
import { fetchOOHScreensForCurrentClinic } from 'src/reducers/clinicFlowData/services/oohScreens/oohScreens'
import { fetchAssistantsForCurrentClinic } from 'src/reducers/clinicFlowData/services/people/assistants'
import { fetchDoctorsForCurrentClinic } from 'src/reducers/clinicFlowData/services/people/doctors'
import { fetchTherapistsForCurrentClinic } from 'src/reducers/clinicFlowData/services/people/therapists'

export default function ClinicChamberForm({
  initialObject = {},
  imageObject: clinicChamber,
  setImageObject: setClinicChamber,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  formMode,
  formHandleSubmit,
  formRef,
}) {
  const currentClinicAssistants = useSelector(assistantsForCurrentClinic)
  const currentClinicDoctors = useSelector(selectDoctorsForCurrentClinic)
  const currentClinicTherapists = useSelector(selectTherapistsForCurrentClinic)
  const mentorDropDownOptions = [
    ...(currentClinicDoctors?.data || [])
      ?.slice()
      ?.filter((doc) => doc?.id)
      ?.map((doc) => ({
        ...doc,
        value: doc?.id,
        label: `${doc?.title} - Doctor`,
      })),
    ...(currentClinicTherapists?.data || [])
      ?.slice()
      ?.filter((therapist) => therapist?.id)
      ?.map((doc) => ({
        ...doc,
        value: doc?.id,
        label: `${doc?.title} - Therapist`,
      })),
  ]

  function changeMentor(eve) {
    handleChangeObject(eve)
    setClinicChamber((prev) => ({
      ...prev,
      assistantId: undefined,
      oohScreenId: undefined,
    }))
  }

  const oohMsgs = useSelector(selectOOhMsgsForCurrentClinic)
  const dispatch = useDispatch()

  console.log({ currentClinicAssistants })

  function changeSatelliteScreenData(event) {
    handleNestedChangeObject(event, 'satelliteScreenId')
  }

  useEffect(() => {
    dispatch(fetchAssistantsForCurrentClinic())
    dispatch(fetchDoctorsForCurrentClinic())
    dispatch(fetchOOHScreensForCurrentClinic())
    dispatch(fetchTherapistsForCurrentClinic())
  }, [])

  return (
    <div className="d-flex flex-column gap-3 w-100">
      <GridSectionWrapper heading="Basic Details">
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                id="enabled"
                checked={!!clinicChamber?.enabled || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label="Enabled: "
          />
        </Grid>
        <Grid item xs={12}>
          <CCFormInput
            id="title"
            label="Title"
            value={clinicChamber?.title}
            colNumber={12}
            onChange={handleChangeObject}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="description"
            label="Description"
            value={clinicChamber?.description}
            colNumber={12}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper heading="Participants">
        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="mentorId"
            value={clinicChamber?.mentorId}
            options={mentorDropDownOptions}
            getOptionValue={(opt) => opt?.value}
            onChangeEvent={changeMentor}
            label="Doctor/Therapist"
            disabled={disabled}
            selectType={selectTypes?.string}
            required
          />
        </Grid>
        {clinicChamber?.mentorId && (
          <>
            <Grid item xs={12} md={6}>
              <CustomReactSelect
                id="assistantId"
                value={clinicChamber?.assistantId}
                options={(currentClinicAssistants?.data || [])?.filter((assistant) => {
                  return assistant?.supportRoles?.some(
                    (role) => role?.managerId === clinicChamber?.mentorId,
                  )
                })}
                getOptionValue={(opt) => opt?.id}
                getOptionLabel={(opt) => opt?.title}
                onChangeEvent={handleChangeObject}
                label="Assistant"
                disabled={disabled}
                selectType={selectTypes?.string}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomReactSelect
                id="oohScreenId"
                value={clinicChamber?.oohScreenId}
                options={oohMsgs.data
                  ?.slice()
                  ?.filter((msg) => msg?.mentorId === clinicChamber?.mentorId)}
                getOptionValue={(opt) => opt?.id}
                getOptionLabel={(opt) => opt?.title}
                onChangeEvent={handleChangeObject}
                label="OOH Screens"
                loading={oohMsgs?.status === 'loading'}
                disabled={disabled}
                selectType={selectTypes?.string}
              />
            </Grid>
          </>
        )}
      </GridSectionWrapper>
      <GridSectionWrapper heading="Satellite Screen Details">
        <Grid item xs={12}>
          <CCFormInput
            id="title"
            label="Satellite Screen Title"
            value={clinicChamber?.satelliteScreenId?.title}
            colNumber={12}
            disabled={disabled}
            onChange={changeSatelliteScreenData}
            required
          />
        </Grid>
      </GridSectionWrapper>
    </div>
  )
}
