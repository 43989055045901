import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const CLINIC_BRAND_API_SIGNATURES = {
  approveClinicBrandPeople: {
    path: `clinic-product/${ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicBrand}/people/approve`,
  },
  checkIfPeopleAreDeletable: {
    path: `clinic-product/${ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicBrand}/people/is-deletable`,
  },
}
