import React from 'react'
import { TableAvatarComponent } from 'src/views/settings/components/TableImage'

export default function GuestTitleComponent({ guestSummaryObject }) {
  return (
    <td>
      <div className="d-flex align-items-center gap-2">
        <TableAvatarComponent imageId={guestSummaryObject?.coverIcon} />
        <div className="d-flex flex-column gap-1">
          <span>{guestSummaryObject?.title}</span>
        </div>
      </div>
    </td>
  )
}
