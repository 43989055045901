import { CFormInput, CFormLabel } from '@coreui/react-pro'
import { Add } from '@mui/icons-material'
import { Autocomplete, FormControlLabel, IconButton, Switch } from '@mui/material'
import React from 'react'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { DeleteIcon, DosageWidth } from './styledComponents'

export default function DrugInfoForm({
  initialObject = {},
  imageObject: medicineObject,
  setImageObject: setMedicineObject,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  view = 'default',
}) {
  const dosageArray = medicineObject.dosage.split('-')

  function dosageChange(e, idx) {
    const finalDosageValue = dosageArray.map((d, index) => {
      return idx === index ? e.target.value : d
    })
    handleChangeObject({ target: { id: 'dosage', value: finalDosageValue.join('-') } })
  }

  function deleteDosage(idx) {
    const finalDosageValue = dosageArray.filter((d, index) => idx !== index)
    handleChangeObject({ target: { id: 'dosage', value: finalDosageValue.join('-') } })
  }

  function addDosage() {
    const finalDosageValue = [...(dosageArray || []), '']
    handleChangeObject({ target: { id: 'dosage', value: finalDosageValue.join('-') } })
  }

  function handleChangeWhatTime(e, newValue) {
    setMedicineObject((prev) => ({ ...prev, whatTime: newValue }))
  }
  function handleChangeAppliedWhere(e, newValue) {
    setMedicineObject((prev) => ({ ...prev, appliedWhere: newValue }))
  }
  function handleChangeTiming(e, newValue) {
    setMedicineObject((prev) => ({ ...prev, timing: newValue }))
  }

  return (
    <div className="row g-3">
      <div className="col-md-12">
        <FormControlLabel
          control={
            <Switch
              size={'small'}
              id="enabled"
              checked={!!medicineObject?.enabled || false}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          }
          label={'Enabled'}
        />
      </div>
      <CCFormInput
        id="name"
        colNumber={12}
        label="Medicine Name"
        value={medicineObject.name}
        onChange={handleChangeObject}
        placeholder="Enter a medicine name...."
        required
      />
      <div className="col-md-4">
        <CFormLabel>Dosage</CFormLabel>
        <div className="d-flex flex-wrap justify-content-start align-items-center gap-2">
          {dosageArray.map((dosage, idx) => (
            <>
              <DosageWidth key={idx}>
                {dosageArray.length > 1 && <DeleteIcon onClick={() => deleteDosage(idx)} />}
                <CFormInput value={dosage} onChange={(e) => dosageChange(e, idx)} />
              </DosageWidth>
              {idx !== dosageArray.length - 1 && <div key={`-${idx}`}>-</div>}
            </>
          ))}
          <div>-</div>
          <IconButton onClick={addDosage}>
            <Add />
          </IconButton>
        </div>
      </div>
      <div className="col-md-2">
        <CustomReactSelect
          id="timing"
          value={medicineObject.timing}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          label="Time"
          options={timingOptions}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title}
        />
        {/* <Autocomplete
          id="timing"
          inputValue={medicineObject.timing}
          onInputChange={handleChangeTiming}
          freeSolo
          options={timingOptions
            ?.filter((optn) => !isNaN(medicineObject.timing))
            ?.map(
              (option) =>
                `${medicineObject.timing} ${option.title}${
                  !isNaN(medicineObject.timing) && Number(medicineObject.timing) > 1 ? 's' : ''
                }`,
            )}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <CFormInput {...params.inputProps} label="Timing" />
            </div>
          )}
        /> */}
      </div>
      <div className="col-md-3">
        <Autocomplete
          id="whatTime"
          inputValue={medicineObject.whatTime}
          onInputChange={handleChangeWhatTime}
          freeSolo
          options={whatTimeOptions.map((option) => option?.title)}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <CFormInput
                {...params.inputProps}
                label="When"
                placeholder="When to take the medicine....."
              />
            </div>
          )}
        />
      </div>
      <div className="col-md-3">
        <Autocomplete
          id="appliedWhere"
          inputValue={medicineObject.appliedWhere}
          onInputChange={handleChangeAppliedWhere}
          freeSolo
          options={appliedWhereOptions.map((option) => option.title)}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <CFormInput
                {...params.inputProps}
                label="Applied Where"
                placeholder="Where to apply the medicine....."
              />
            </div>
          )}
        />
      </div>
      <CCTextArea
        id="genericName"
        colNumber={5}
        label="Generic Name"
        placeholder="Enter the general name for this medicine..."
        value={medicineObject.genericName}
        onChange={handleChangeObject}
      />
      <div className="col-md-5">
        <CustomReactSelect
          id="frequency"
          value={medicineObject.frequency}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          label="Frequency"
          placeholder="How often to have the medicine...."
          options={frequencyOptions}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title}
        />
      </div>
      <CCFormInput
        id="qty"
        coreUiGridItem
        colNumber={2}
        label="Quantity"
        value={medicineObject.qty}
        placeholder="Qty to take..."
        // type="number"
        onChange={handleChangeObject}
      />
      <CCTextArea
        id="note"
        coreUiGridItem
        colNumber={8}
        label="Notes"
        value={medicineObject.note}
        // type="number"
        onChange={handleChangeObject}
      />
    </div>
  )
}

const whatTimeOptions = [
  { id: 'before_dinner', title: 'Before Dinner' },
  { id: 'after_dinner', title: 'After Dinner' },
  { id: 'with_food', title: 'With Food' },
  { id: 'with_tea', title: 'With Tea' },
  { id: 'empty_stomach', title: 'Empty Stomach' },
  { id: 'bed_time', title: 'Bed Time' },
  { id: 'before_bath', title: 'Before Bath' },
  { id: 'after_bath', title: 'After Bath' },
  { id: 'before_sun', title: 'Before Sun' },
  { id: 'after_sun', title: 'After Sun' },
  { id: 'sos', title: 'SoS' },
]

const appliedWhereOptions = [
  { id: 'on_scalp', title: 'On Scalp' },
  { id: 'on_face', title: 'On Face' },
  { id: 'on_burns', title: 'On Burns' },
  { id: 'on_wounds', title: 'On Wounds' },
  { id: 'on_warts', title: 'On Warts' },
  { id: 'on_pimples', title: 'On Pimples' },
  { id: 'on_acne', title: 'On Acne' },
  { id: 'on_scars', title: 'On Scars' },
  { id: 'on_scabies', title: 'On Scabies' },
  { id: 'on_itchy_areas', title: 'On Itchy Areas' },
  { id: 'on_rashes', title: 'On Rashes' },
  { id: 'on_dark_areas', title: 'On Dark Areas' },
  { id: 'on_dry_skin', title: 'On Dry Skin' },
  { id: 'on_wet_skin', title: 'On Wet Skin' },
  { id: 'on_ringworm', title: 'On Ringworm' },
  { id: 'on_wrinkles', title: 'On Wrinkles' },
  { id: 'on_stretch_marks', title: 'On Stretch Marks' },
]

const frequencyOptions = [
  { id: 'daily', title: 'Daily' },
  { id: 'alternate_day', title: 'Alternate Day' },
  { id: 'weekly', title: 'Weekly' },
  { id: 'weekly_twice', title: 'Weekly Twice' },
  { id: 'weekly_thrice', title: 'Weekly Thrice' },
  { id: 'fortnight', title: 'Fortnight' },
  { id: 'monthly', title: 'Monthly' },
  { id: 'mon', title: 'Mon' },
  { id: 'tue', title: 'Tue' },
  { id: 'wed', title: 'Wed' },
  { id: 'thu', title: 'Thu' },
  { id: 'fri', title: 'Fri' },
  { id: 'sat', title: 'Sat' },
  { id: 'sun', title: 'Sun' },
]
const timingOptions = [
  { id: '5_mins', title: '5 Mins' },
  { id: '10_mins', title: '10 Mins' },
  { id: '15_mins', title: '15 Mins' },
  { id: '20_mins', title: '20 Mins' },
  { id: '25_mins', title: '25 Mins' },
  { id: '30_mins', title: '30 Mins' },
  { id: '45_mins', title: '45 Mins' },
  { id: '60_mins', title: '1 Hr' },
]
