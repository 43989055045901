import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'
import { transformCacheEvictListingData } from '../cacheEvict/genericListing/transformListingData'
import { transformListingDataForCourseUnPublishRequest } from '../unPublishRequest/transformListingDataForCourse'
import { transformListingDataForCourseApprovalRequests } from './approvalRequest/transformListingDataForCourse'
import { transformClinicListingData } from './clinic/transformListingData'
import { transformClinicAssistantListingData } from './clinicAssistant/transformListingData'
import { transformOnlineCourseDraftListingData } from './courseDraft/transformListingData'
import { transformInitialDoctorUserListingData } from './doctorUser/transformInitialListingData'
import { transformOnlineCourseListingData } from './onlineCourse/transformListingData'
import { transformOOHMsgsData } from './oohMsgs/transformListingData'
import { transformPrescriptionListingData } from './prescriptionTemplates/transformListingData'
import { transformSegmentsListingData } from './segments/transformListingData'
import { transformUsersListingData } from './users/transformListingData'

export async function modifyListingData(apiParam, listingData) {
  switch (apiParam) {
    case ALL_ENTITY_TYPES.supportStaff:
      const supportStaffToReturn = await getSupportStaffUserNames(listingData)
      return supportStaffToReturn
    case ALL_ENTITY_TYPES.clinic:
      return await transformClinicListingData(listingData)
    case ALL_ENTITY_TYPES.oohMsg:
      return await transformOOHMsgsData(listingData)
    case ALL_ENTITY_TYPES.prescriptionTemplate:
      return await transformPrescriptionListingData(listingData)
    case ALL_ENTITY_TYPES.clinicAssistant:
      return await transformClinicAssistantListingData(listingData)
    case ALL_ENTITY_TYPES.cacheEvict:
      return await transformCacheEvictListingData(listingData)
    case ALL_ENTITY_TYPES.users:
      return transformUsersListingData({ listingData })
    case ALL_ENTITY_TYPES.segment:
      return transformSegmentsListingData({ listingData })
    case ALL_ENTITY_TYPES.courseDraft:
      return transformOnlineCourseDraftListingData({ listingData })
    case ALL_ENTITY_TYPES.onlineCourse:
      return transformOnlineCourseListingData({ listingData })
    case ALL_ENTITY_TYPES.unPublishRequest:
      return await transformListingDataForCourseUnPublishRequest({ listingData })
    case ALL_ENTITY_TYPES.approvalRequest:
      return await transformListingDataForCourseApprovalRequests({ listingData })
    case ALL_ENTITY_TYPES.doctorUsers:
      return await transformInitialDoctorUserListingData({ listingData })
    default:
      return listingData || []
  }
}

async function getSupportStaffUserNames(supportStaff) {
  const userIdsToUse = supportStaff?.map((staff) => staff?.userId)
  const foundUsers = await getUsersSummaryFromUserIds(userIdsToUse)
  return supportStaff?.map((staff) => {
    const foundUser = foundUsers?.find((user) => user?.userId === staff?.userId)
    return { ...staff, user: foundUser?.name }
  })
}
