import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import React from 'react'
import { ListingComponentUpperBar, ListingFilterContainer } from './styledComponents'

export default function ListingComponentFilterBar({
  filters,
  addButtonFunction,
  components,
  children,
}) {
  const showListingFilterContainer = filters || addButtonFunction || components

  return (
    <>
      {children}
      {showListingFilterContainer && (
        <ListingFilterContainer className="bg-sideBar dark:bg-signUpbgDark p-3 border-bottom">
          <ListingComponentUpperBar items={filters ? Object.entries(filters).length - 1 : 0}>
            {filters && (
              <div className="d-flex justify-content-start align-items-center gap-2">
                {Object.entries(filters).map(([key, value]) => (
                  <div className="d-flex flex-row justify-content-end gap-3" key={key}>
                    {value()}
                  </div>
                ))}
              </div>
            )}
            <div className="d-flex justify-content-stretch w-100 align-items-center gap-2">
              {addButtonFunction && (
                <div className="d-flex flex-row justify-content-end gap-3">
                  <IconButton
                    variant="contained"
                    onClick={() => {
                      addButtonFunction()
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              )}
              {components && components()}
            </div>
          </ListingComponentUpperBar>
        </ListingFilterContainer>
      )}
    </>
  )
}
