import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { IdTypes } from 'src/utilities/constants'

export default function ExtraOptionsComponent({
  item,
  additionalData,
  setAdditionalData,
  changeAdditionalItem,
  uiContextData,
  children,
}) {
  return (
    <>
      {item?.value && (
        <>
          <h5 className="py-2 pt-1 text-secondary">Optional</h5>
          <div>
            <CustomReactSelect
              id="idType"
              menuPortalTarget={document.body}
              value={additionalData?.idType}
              options={Object.entries(IdTypes)
                ?.filter((idType) => idType?.[0] !== IdTypes.type)
                ?.map(([key, value]) => ({
                  id: key,
                  title: value,
                }))
                ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              label={uiContextData?.extraDataLinkTypeLabel || 'Link Type'}
              placeholder="Select..."
              selectType={selectTypes.string}
              onChangeEvent={(eve) => {
                setAdditionalData(eve)
                changeAdditionalItem(null)
              }}
            />
          </div>
        </>
      )}
      {additionalData?.idType && additionalData?.idType !== IdTypes.list && children}
    </>
  )
}
