import React, { useMemo } from 'react'
import { PRESCRIPTION_LAYOUTS } from 'src/utilities/constants'
import DefaultPrescriptionHeader from './header/DefaultPrescriptionHeader'
import DefaultPatientDetails from './header/patientDetails/DefaultPatientDetails'
import ThreeColumnPrescriptionFooterPatientDetails from './header/patientDetails/ThreeColumnPrescriptionFooterPatientDetails'
import TwoColumnPrescriptionHeader from './header/TwoColumnPrescriptionHeader'

export const PrescriptionHeader = ({ prescriptionData, leaveSpace }) => {
  const PatientDetailsComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
      case PRESCRIPTION_LAYOUTS.twoColumnWithDisclaimerFooter:
        return ThreeColumnPrescriptionFooterPatientDetails
      default:
        return DefaultPatientDetails
    }
  }, [prescriptionData?.layout])

  const HeaderComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
        return TwoColumnPrescriptionHeader
      default:
        return DefaultPrescriptionHeader
    }
  }, [prescriptionData?.layout])

  return (
    <div style={{ background: '#FFF' }}>
      {!Boolean(leaveSpace) && <HeaderComponent prescriptionData={prescriptionData} />}
      {leaveSpace && <div style={{ height: '120px' }}></div>}
      <PatientDetailsComponent prescriptionData={prescriptionData} leaveSpace={leaveSpace} />
    </div>
  )
}
