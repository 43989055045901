import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { CCPaginationContainer, CCSmartPaginationStyledComponent } from './styledComponents'

export default function CCPagination({
  paginationProps,
  pageSize,
  handlePageSizeChange,
  itemsPerPageOptions,
  hidePageSize = false,
}) {
  // function handlePageChange(event, page) {
  //   handleChange(page - 1)
  // }
  function handlePgSizeChange(event) {
    handlePageSizeChange(event.target.value)
  }

  // useEffect(() => {
  //   console.log(pagination)
  // }, [pagination])

  return (
    <CCPaginationContainer>
      {/* <Pagination count={totalPages} page={pagination.page + 1} onChange={handlePageChange} /> */}
      <CCSmartPaginationStyledComponent {...paginationProps} />
      {!hidePageSize && (
        <FormControl variant="standard" sx={{ minWidth: 120 }} size="small">
          <InputLabel id="items-per-page">Items per page</InputLabel>
          <Select
            value={pageSize}
            size="small"
            labelId="items-per-page"
            onChange={handlePgSizeChange}
          >
            {itemsPerPageOptions ? (
              itemsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Options Not set</MenuItem>
            )}
          </Select>
        </FormControl>
      )}
    </CCPaginationContainer>
  )
}

CCPagination.propTypes = {
  pagination: PropTypes.object.isRequired,
  totalPages: PropTypes.number,
  handleChange: PropTypes.func,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
}
