import { Delete } from '@mui/icons-material'
import { FormControlLabel, Grid, IconButton, Switch } from '@mui/material'
import React from 'react'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import AutomaticListAddingComponent from 'src/components/controlCenter/common/AutomaticListAddingComponent'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { ROLE_ACCESS_LEVELS_ARRAY, ROLE_TYPES_ARRAY } from 'src/utilities/constants'
import { giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { AddAccessTypeButton } from './components/styledComponents'

export default function MetaUserRoles({
  initialObject,
  imageObject: userRole,
  setImageObject: setUserRole,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  hideEnabled = false,
  slugValidation,
  setSlugValidation,
}) {
  const [store] = useAppSettingsStore()

  function handleChangeAccessTypeList(e, idx) {
    setUserRole((prev) => {
      return {
        ...prev,
        allowedAccessTypeList: prev?.allowedAccessTypeList.map((item, index) => {
          return index === idx ? { ...item, ...giveGenericHandleChangedData(e) } : item
        }),
      }
    })
  }

  function handleDeleteAccessType(idx) {
    setUserRole((prev) => {
      return {
        ...prev,
        allowedAccessTypeList: prev?.allowedAccessTypeList.filter((item, index) => index !== idx),
      }
    })
  }

  console.log({ customOptions })

  function handleAddAccessType() {
    setUserRole((prev) => {
      return {
        ...prev,
        allowedAccessTypeList: [
          ...(prev?.allowedAccessTypeList || []),
          {
            apiParam: '',
            readFieldsExcluded: '',
            writeFieldsExcluded: '',
            accessType: '',
          },
        ],
      }
    })
  }

  return (
    <Grid container mt="10px" spacing={4}>
      <GridSectionWrapper heading="Basic Info" shadow={false}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                id="enabled"
                checked={!!userRole?.enabled || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={'Enabled'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CCFormInput
            id="id"
            label="Role ID"
            value={userRole?.id}
            onChange={handleChangeObject}
            disabled={!!initialObject?.id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="roleType"
            label="Role Type"
            value={userRole?.roleType}
            onChangeEvent={handleChangeObject}
            selectType={selectTypes.string}
            options={ROLE_TYPES_ARRAY}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.title}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CCFormInput
            id="title"
            label="Title"
            value={userRole?.title}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="description"
            label="Description"
            value={userRole?.description}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomReactSelect
            isMulti
            id="metaCategoryTypes"
            label="Meta Category Types"
            value={userRole?.metaCategoryTypes}
            placeholder="Select types..."
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            options={store?.metaCategoryTypes}
            selectType={selectTypes.string}
            onChangeEvent={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <AutomaticListAddingComponent
            id={'placeholders'}
            label="Placeholders"
            value={userRole?.placeholders}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper heading="Portal Related Info" shadow={false}>
        <Grid item xs={12} md={6}>
          <CCFormInput
            id="portalUrl"
            label="Portal Url"
            value={userRole?.portalUrl}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper shadow={false}>
        <Grid item xs={12}>
          <CCTextArea
            id="homePageTextBox"
            label="Home Page Text"
            value={userRole?.description}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper heading="Onboarding Related Info" shadow={false}>
        <Grid item xs={12} md={6}>
          <CCFormInput
            id="onboardingTitle"
            label="Onboarding Title"
            value={userRole?.onboardingTitle}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CCFormInput
            id="joiningUrl"
            label="Joining Url"
            value={userRole?.joiningUrl}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="onboardingDescription"
            label="Onboarding Description"
            value={userRole?.onboardingDescription}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper heading="Access Config" shadow={false}>
        {/* <Grid item xs={12} md={6}>
          <AutomaticListAddingComponent
            id={'access'}
            label="Control Center Sections Access"
            value={userRole?.access}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid> */}
        {userRole?.allowedAccessTypeList?.map((accessType, idx) => (
          <Grid item xs={12} key={idx}>
            <UserAccessTypeHandler
              value={accessType}
              customOptions={customOptions}
              handleChange={(e) => handleChangeAccessTypeList(e, idx)}
              deleteAccessType={() => {
                handleDeleteAccessType(idx)
              }}
            />
          </Grid>
        ))}
        {!disabled && (
          <Grid item xs={12}>
            <AddAccessTypeButton onClick={handleAddAccessType}>Add Access Type</AddAccessTypeButton>
          </Grid>
        )}
      </GridSectionWrapper>
      <GridSectionWrapper heading="Images" shadow={false}>
        <Grid item xs={12}>
          <EntityBasedImageUploadComponent
            disabled={disabled}
            entity={entity}
            entityObject={userRole}
            setImageObject={setUserRole}
            allImages={allImages}
            handleChange={handleChangeObject}
            noBorder
            align="start"
            noHeading
          />
        </Grid>
      </GridSectionWrapper>
    </Grid>
  )
}

function UserAccessTypeHandler({ value, handleChange, deleteAccessType, disabled, customOptions }) {
  return (
    <GridSectionWrapper>
      <Grid item xs={12} md={6}>
        <CustomReactSelect
          id="apiParam"
          label="API Param"
          value={value?.apiParam}
          options={customOptions?.allEntityParams}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          disabled={disabled}
          selectType={selectTypes.string}
          onChangeEvent={handleChange}
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <CCFormInput
          id="readFieldsExcluded"
          label="Read Fields Excluded"
          value={value?.readFieldsExcluded}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="writeFieldsExcluded"
          label="Write Fields Excluded"
          value={value?.writeFieldsExcluded}
          onChange={handleChange}
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <CustomReactSelect
          id="accessType"
          label="Access Type"
          value={value?.accessType}
          options={ROLE_ACCESS_LEVELS_ARRAY}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title}
          disabled={disabled}
          selectType={selectTypes.string}
          onChangeEvent={handleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton onClick={deleteAccessType}>
          <Delete />
        </IconButton>
      </Grid>
    </GridSectionWrapper>
  )
}
