import { Close } from '@mui/icons-material'
import { DialogTitle, IconButton } from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'
import EntityListingComponent from 'src/views/setup/userStats/EntityListingComponent'

export default function ClinicAssistantUserRoleAddition({ handleClose, handleRefresh }) {
  const [confirmationModalProps, setShowConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  async function addAssistantRole(userData) {
    const tst = toast.loading(`Adding clinic assistant role to ${userData?.firstName}`)
    const resp = await PostSettingsData(
      USERS_API_SIGNATURES_MAP.authorities.addClinicAssistant,
      {},
      {
        userId: userData?.id,
      },
    )
    if (resp.status === 200) {
      if (isCommonResponseSuccessful(resp.data.code)) {
        toast.update(tst, {
          ...TOAST_UTILS,
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          render: `Role given to ${userData?.firstName} successfully`,
        })
        handleRefresh()
        handleClose()
        return
      }
    }
    toast.update(tst, {
      ...TOAST_UTILS,
      type: toast.TYPE.ERROR,
      isLoading: false,
      render: `Something went wrong`,
    })
    return
  }

  function showGiveAssistantRoleModal(userData) {
    setShowConfirmationModalProps((prev) => ({
      action: 'add',
      body: `Do you want to give ${userData?.firstName} the role of a Clinic Assistant?`,
      buttonColor: 'success',
      onSubmitFunctions: [
        () => {
          addAssistantRole(userData)
        },
      ],
      visibility: true,
      visibilitySetter: setShowConfirmationModalProps,
    }))
  }

  function scopedActions() {
    return [
      {
        id: 'remove',
        onClick: (userData) => showGiveAssistantRoleModal(userData),
        label: 'Add Assistant Role',
        showCondition: () => true,
      },
    ]
  }

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>End Users</span>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <EntityListingComponent
        apiParam={ALL_ENTITY_TYPES_FROM_CONSTANTS.users}
        customListingApiParams={{
          excludeRole: 'ROLE_CLINIC_ASSISTANT',
        }}
        hideAddEntityButton
        customActionsOverride={scopedActions}
        hideFilterButton
        clickableRows={false}
      />
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}
