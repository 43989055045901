import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { convertArrayToPaginatedObject } from 'src/common/types/serverFetch/getArrayAsServerPaginatedObject'
import { fetchFunction } from 'src/services'
import { GetData } from 'src/services/APIs/DailyPlay/GetLists'
import { GetFeatureData } from 'src/services/APIs/Features/GetApi'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import {
  IdTypes,
  META_CATEGORY_TYPES_TO_API_PARAM,
  POPUP_SCREEN_TYPES,
} from 'src/utilities/constants'
import { isCommonResponseSuccessful, lightCheckIfArray1HasArray2 } from 'src/utilities/generalUtils'
import { ONLINE_COURSES_APIS } from 'src/views/courses/courses_utils'
import { FEATURES_API } from 'src/views/features/utils/api-utils'
import { APIs } from 'src/views/plugins/calendar/components/api-utils'

function makeRefIdsForm(
  fieldName,
  options,
  idField = 'id',
  labelField = 'title',
  rest = {},
  customLabel,
) {
  if (!options) {
    toast.warning('One of the fields have incorrect options attached')
    console.log('Incorrect field ' + fieldName)
  }
  return {
    refIds: {
      options: options,
      idField: idField,
      labelField: labelField,
      label: customLabel,
    },
    ...rest,
  }
}

const catMap = {
  myQuotes: 'quote',
  myBreathe: 'breathe',
  myJournal: 'journal',
  myPractices: 'practice',
}

const formDynamicTypes = {
  default: 'default',
  dailyAumHum: 'dailyAumHum',
}

export const dynamicRenderingExploreTabSettingHandler = {
  default: {
    dependentField: null,
    showCondition: () => true,
  },
  dailyAumHum: {
    dependentField: 'dailyFeature',
    showCondition: (dependentObject) => !!dependentObject.enabled,
  },
}

const tagTypes = {
  feeling: 'feeling',
  tag: 'tag',
  genre: 'genre',
}

function pillsValidation(tileObject, dropDownOptions) {
  //check for empty
  if (!!tileObject?.size || tileObject?.size === 0) return false
  if (!!tileObject?.contentType || tileObject?.contentType === '') return false
  if (!!tileObject?.customCategories || tileObject?.customCategories?.length === 0) return false
  return true
}

function customPillsValidation(tileObject, dropDownOptions) {
  //check for empty
  if (!!tileObject?.size || tileObject?.size === 0) return false
  if (!!tileObject?.contentType || tileObject?.contentType === '') return false
  if (!!tileObject?.customCategories || tileObject?.customCategories?.length === 0) return false
  if (!!tileObject?.tagIds || tileObject?.tagIds?.length === 0) return false
  return true
}

const formFieldTypes = {
  pills: {
    altTextTranslations: true,
    size: {
      min: 1,
      max: 100,
    },
    contentType: {
      options: 'contentTypes',
      idField: 'id',
      labelField: 'name',
    },
    customCategories: {
      filterId: 'categoryType',
      filterMorph: (category, filterValue) => {
        if (filterValue === 'course') {
          return !category?.featureCategory
        } else {
          return category?.featureCategory
        }
      },
      optionsMorph: (basedOn) => {
        return basedOn === 'feature' ? 'tabTileFeatureCategories' : 'contentCategories'
      },
      optionsBasedOn: 'contentType',
      basedOn: 'contentType',
      options: 'categories',
      idField: 'id',
      labelField: 'name',
    },
    validation: pillsValidation,
  },
  customPills: {
    altTextTranslations: true,
    size: {
      min: 1,
      max: 100,
    },
    contentType: {
      options: 'contentTypes',
      idField: 'id',
      labelField: 'name',
    },
    customCategories: {
      console: 'customCategories',
      filterMorph: (category, filterValue) => {
        if (filterValue === 'course') {
          return !category?.featureCategory
        } else {
          return category?.featureCategory
        }
      },
      basedOn: 'contentType',
      isMultiMorph: (basedOn) => {
        return basedOn === 'feature' ? true : false //this is reversed for conditional compatibility
      },
      inMultiBasedOn: 'contentType',
      optionsMorph: (basedOn) => {
        return basedOn === 'feature' ? 'tabTileFeatureCategories' : 'contentCategories'
      },
      optionsBasedOn: 'contentType',
      options: 'contentCategories',
      idField: 'id',
      labelField: 'name',
    },
    tagIds: {
      filterMorph: (tag, customCats, type) => {
        const filteringCustomCats = customCats?.map((cat) => catMap?.[cat] || cat)
        const catsToUse = [...customCats, ...filteringCustomCats]
        if (!tag.enabled) {
          return false
        }
        const filteredCatTags = tag?.categories
          ? lightCheckIfArray1HasArray2(catsToUse, tag?.categories)
          : true
        if (type && tag.type) {
          return filteredCatTags && tag.type === type
        }
        return filteredCatTags
      },
      basedOn: 'customCategories',
      options: 'tags',
      idField: 'id',
      labelField: 'name',
    },
    validation: customPillsValidation,
  },
  customTechniquePills: {
    altTextTranslations: true,
    size: {
      min: 1,
      max: 100,
    },
    contentType: {
      options: 'contentTypes',
      idField: 'id',
      labelField: 'name',
    },
    customCategories: {
      filterMorph: (category, filterValue) => {
        if (filterValue === 'course') {
          return !category?.featureCategory
        } else {
          return category?.featureCategory
        }
      },
      basedOn: 'contentType',
      options: 'categories',
      idField: 'id',
      labelField: 'name',
    },
    tagIds: {
      filterMorph: (tag, customCats, type) => {
        if (!tag.enabled) {
          return false
        }
        const filteredCatTags = tag?.categories
          ? lightCheckIfArray1HasArray2(customCats, tag?.categories)
          : true
        if (type && tag.type) {
          return filteredCatTags && tag.type === type
        }
        return filteredCatTags
      },
      basedOn: 'customCategories',
      options: 'techniques',
      idField: 'id',
      labelField: 'name',
      labelOverride: 'Techniques',
    },
  },
  systemGenerated: {
    altTextTranslations: true,
  },
  curatedContent: (fieldName, options, idField, labelField) =>
    makeRefIdsForm(fieldName, options, idField, labelField, { altTextTranslations: true }),
  tileContent: (fieldName, options, idField, labelField) =>
    makeRefIdsForm(fieldName, options, idField, labelField, {
      altTextTranslations: true,
      tileReorder: true,
    }),
  maxCategoryType: {
    noOfSuchCategories: true,
    altTextTranslations: true,
  },
  talkToUs: {
    intercom: true,
    calendly: true,
    altTextTranslations: true,
  },
  welcomeCard: {
    involved: true,
    altTextTranslations: true,
  },
  autoPlay: {
    autoPlayType: {
      options: 'autoPlayTypes',
      idField: 'id',
      labelField: 'name',
    },
    categories: {
      viewCondition: (basedOn) => basedOn === 'systemGenerated',
      basedOn: 'autoPlayType',
      options: 'tabTileFeatureCategories',
      idField: 'id',
      labelField: 'name',
    },
    altTextTranslations: true,
  },
  systemGeneratedWithSize: {
    size: {
      min: 1,
      max: 100,
    },
    altTextTranslations: true,
  },
  focusAreaDriven: {
    customCategories: {
      options: 'contentCategories',
      idField: 'id',
      labelField: 'name',
    },
    altTextTranslations: true,
  },
  curatedCourses: {
    customCategories: {
      console: 'customCategories',
      filterMorph: (category, filterValue) => {
        if (filterValue === 'course') {
          return !category?.featureCategory
        } else {
          return category?.featureCategory
        }
      },
      basedOn: 'contentType',
      isMultiMorph: (basedOn) => {
        // return basedOn === 'feature' ? true : false //this is reversed for conditional compatibility
        return true //this is reversed for conditional compatibility
      },
      inMultiBasedOn: 'contentType',
      optionsMorph: (basedOn) => {
        return basedOn === 'feature' ? 'tabTileFeatureCategories' : 'contentCategories'
      },
      optionsBasedOn: 'contentType',
      options: 'contentCategories',
      idField: 'id',
      labelField: 'name',
    },
    contentType: {
      options: 'contentTypes',
      idField: 'id',
      labelField: 'name',
    },
    appLanguage: {
      options: 'languages',
      idField: 'id',
      labelField: 'languageName',
    },
    courseRefIds: {
      typeBasedOn: 'contentType',
      basedOn: 'customCategories',
      languageBasedOn: 'appLanguage',
      options: 'courses',
      idField: 'id',
      labelField: 'title',
      label: 'Curated Content',
    },
    altTextTranslations: true,
  },
  tagIdsBased: {
    tagIds: {
      filterMorph: (tag, customCats) => {
        if (tag?.type === 'genre')
          return lightCheckIfArray1HasArray2(tag?.categories || [], ['immersive-sounds'])
        return false
      },
      options: 'tags',
      idField: 'id',
      labelField: 'name',
    },
    altTextTranslations: true,
  },
  customFocusArea: {
    altTextTranslations: true,
    tagIds: {
      filterMorph: () => true,
      options: 'tags',
      idField: 'id',
      labelField: 'name',
    },
    customCategories: {
      altTextTranslations: true,
      options: 'contentCategories',
      idField: 'id',
      labelField: 'name',
    },
  },
  customTagFields: {
    altTextTranslations: true,
    tagIds: {
      filterMorph: (tag) => tag.type === 'tag',
      options: 'tags',
      idField: 'id',
      labelField: 'name',
    },
    customCategories: {
      options: 'contentCategories',
      idField: 'id',
      labelField: 'name',
    },
  },
  customFeelingFields: {
    altTextTranslations: true,
    refIds: {
      filterMorph: (tag) => tag.type === 'feeling',
      options: 'tags',
      label: 'Content Tags',
      idField: 'id',
      labelField: 'name',
    },
    customCategories: {
      options: 'contentCategories',
      idField: 'id',
      labelField: 'name',
    },
  },
  customTechniqueFields: {
    altTextTranslations: true,
    refIds: {
      filterMorph: (tag) => true,
      options: 'tags',
      label: 'Custom Tags',
      idField: 'id',
      labelField: 'name',
    },
    customCategories: {
      options: 'contentCategories',
      idField: 'id',
      labelField: 'name',
    },
  },
  journalFields: {
    altTextTranslations: true,
    size: true,
    refIds: {
      filterMorph: (tag) => true,
      options: 'journals',
      label: 'Journals',
      idField: 'id',
      labelField: 'name',
    },
    categoryType: {
      options: 'contentCategories',
    },
  },
  customHeroCard: (fieldName, options, idField, labelField) =>
    makeRefIdsForm(fieldName, options, idField, labelField, { altTextTranslations: true }),
  scoreCard: {
    selfBoolean: true,
  },
}

export const newFormFieldTypes = {
  curated: {
    carouselSize: {
      min: 1,
      max: 100,
    },
    metaCategoryIds: {
      filterMorph: (category, filterValue) => {
        if (filterValue === 'tiles') {
          return true
        }
        return category?.categoryType === filterValue
      },
      basedOn: 'metaCategoryType',
      isMultiMorph: (basedOn) => {
        return false //this is reversed for conditional compatibility
        // return true //this is reversed for conditional compatibility
      },
      optionsMorph: (categoryType) => {
        return categoryType === 'tiles' ? 'tiles' : 'categories'
      },
      optionsBasedOn: 'metaCategoryType',
      inMultiBasedOn: 'metaCategoryType',
      options: 'categories',
      idField: 'id',
      labelField: 'name',
    },
    metaCategoryType: {
      options: 'metaCategoryTypes',
      idField: 'id',
      labelField: 'title',
    },
    appLanguage: {
      options: 'languages',
      idField: 'id',
      labelField: 'languageName',
    },
    contentIds: {
      typeBasedOn: 'metaCategoryType',
      basedOn: 'metaCategoryIds',
      languageBasedOn: 'appLanguage',
      options: 'courses',
      idField: 'value',
      labelField: 'label',
      label: 'Content',
    },
    altText: true,
  },
  preset: {
    altText: true,
    metaCategoryType: {
      options: 'metaCategoryTypes',
      idField: 'id',
      labelField: 'title',
    },
    metaCategoryIds: {
      filterMorph: (category, filterValue) => {
        return category?.categoryType === filterValue
      },
      basedOn: 'metaCategoryType',
      isMultiMorph: (basedOn) => {
        return basedOn !== 'course' && basedOn !== 'service-provider' && basedOn !== 'tag'
          ? true
          : false //this is reversed for conditional compatibility
        // return true //this is reversed for conditional compatibility
      },
      inMultiBasedOn: 'metaCategoryType',
      options: 'categories',
      idField: 'id',
      labelField: 'name',
    },
  },
}

export const exploreFormFields = {
  feelings: {
    title: 'Pills of feeling',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.pills,
    isCategoryItem: true,
  },
  genre: {
    title: 'Pills of genre',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.pills,
    isCategoryItem: true,
  },
  tag: {
    title: 'Pills of tags',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.pills,
    isCategoryItem: true,
  },
  technique: {
    title: 'Pills of techniques',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.pills,
    isCategoryItem: true,
  },
  feelingList1: {
    title: 'Pills of Custom Feeling 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.feeling },
    },
    isCategoryItem: true,
  },
  feelingList2: {
    title: 'Pills of Custom Feeling 2',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.feeling },
    },
    isCategoryItem: true,
  },
  feelingList3: {
    title: 'Pills of Custom Feeling 3',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.feeling },
    },
    isCategoryItem: true,
  },
  genreList1: {
    title: 'Pills of Custom Genre 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.genre },
    },
    isCategoryItem: true,
  },
  genreList2: {
    title: 'Pills of Custom Genre 2',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.genre },
    },
    isCategoryItem: true,
  },
  genreList3: {
    title: 'Pills of Custom Genre 3',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.genre },
    },
    isCategoryItem: true,
  },
  tagList1: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  tagList2: {
    title: 'Pills of Custom Tags 2',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  tagList3: {
    title: 'Pills of Custom Tags 3',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList1: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList2: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList3: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList4: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList5: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList6: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList7: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList8: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  anyTagList9: {
    title: 'Pills of Custom Tags 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: {
      ...formFieldTypes?.customPills,
      tagIds: { ...formFieldTypes?.customPills?.tagIds, type: tagTypes.tag },
    },
    isCategoryItem: true,
  },
  techniquesList1: {
    title: 'Pills of Custom Technique 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customTechniquePills,
    isCategoryItem: true,
  },
  techniquesList2: {
    title: 'Pills of Custom Technique 2',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customTechniquePills,
    isCategoryItem: true,
  },
  techniquesList3: {
    title: 'Pills of Custom Technique 3',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customTechniquePills,
    isCategoryItem: true,
  },
  assessments: {
    title: 'System Generated Assessments',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  curatedAssessments: {
    title: 'Curated Assessments',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.curatedContent('curatedAssessments', 'assessment', 'id', 'title'),
  },
  articles: {
    title: 'System Generated Articles',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  curatedArticles: {
    title: 'Curated Articles',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.curatedContent('curatedArticles', 'article', 'id', 'title'),
  },
  mentalStateTypes: {
    title: 'System Generated Mental State Types',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  curatedMentalStateTypes: {
    title: 'Curated Mental State Types',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.curatedContent(
      'curatedMentalStateTypes',
      'mentalStateTypes',
      'id',
      'title',
    ),
  },
  focusAreaGenreByUser: {
    previewOrderTitle: 'Focus Area Genre by User',
    title: 'Genre Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  focusAreaFeelingByUser: {
    previewOrderTitle: 'Focus Area Feeling by User',
    title: 'Feeling Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  focusAreaTagByUser: {
    previewOrderTitle: 'Focus Area Tag by User',
    title: 'Tag Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  genreByUser: {
    previewOrderTitle: 'Genre by User',
    title: 'Genre Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  tagByUser: {
    previewOrderTitle: 'Tag by User',
    title: 'Tag Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  feelingByUser: {
    previewOrderTitle: 'Feeling by User',
    title: 'Feeling Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  techniqueByUser: {
    previewOrderTitle: 'Technique by User',
    title: 'Technique Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  talkToUsCard: {
    title: 'Help And Support',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.talkToUs,
  },
  welcomeCard: {
    title: 'Welcome Card',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.welcomeCard,
  },
  tiles: {
    title: 'Tiles',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.tileContent('tiles', 'tiles', 'id', 'title'),
    isCategoryItem: true,
  },
  autoPlay: {
    title: 'Auto Play',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.autoPlay,
    isNotAnOrderItem: true,
  },
  trending: {
    title: 'Trending',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGeneratedWithSize,
  },
  recentlyAdded: {
    title: 'Recently Added',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGeneratedWithSize,
  },
  myMeditations: {
    previewOrderTitle: 'My Meditations',
    title: 'Meditations',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  myTalks: {
    title: 'Talks',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  myStories: {
    title: 'Stories',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  myTherapy: {
    title: 'Therapies',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  myYoga: {
    title: 'Yoga',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  music: {
    title: 'Music',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  shorts: {
    title: 'Shorts',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  coaching: {
    title: 'Coaching',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.focusAreaDriven,
  },
  curatedCard1: {
    title: 'Curated Card 1',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.curatedCourses,
  },
  curatedCard2: {
    title: 'Curated Card 2',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.curatedCourses,
  },
  curatedCard3: {
    title: 'Curated Card 3',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.curatedCourses,
  },
  sound: {
    title: 'Sound',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.tagIdsBased,
  },
  popularTeachers: {
    previewOrderTitle: 'Popular Teachers',
    title: 'Sound',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  meditationMentors: {
    title: 'Meditation Mentors',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  storyTellers: {
    title: 'Storytellers',
    customType: 'story-teller',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  soundArtist: {
    title: 'Artists I follow (MUSIC)',
    customType: 'sound-artist',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  yogaTeacher: {
    previewOrderTitle: 'My Yoga Teachers',
    title: 'Yoga teachers',
    customType: 'yoga-teacher',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  myMentors: {
    title: 'My Mentors',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  customGenre: {
    previewOrderTitle: 'Custom Genre',
    title: 'Genre Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customFocusArea,
  },
  customTag: {
    previewOrderTitle: 'Custom Tag',
    title: 'Tag Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customTagFields,
  },
  customFeeling: {
    previewOrderTitle: 'Custom Feeling',
    title: 'Feeling Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customFeelingFields,
  },
  customTechnique: {
    previewOrderTitle: 'Custom Technique',
    title: 'Technique Based',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customTechniqueFields,
  },
  heroCard: {
    title: 'Hero Cards',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.customHeroCard(
      'heroCard',
      'tags',
      'id',
      'name',
      {},
      'Content Tags',
    ),
  },
  upgrade: {
    previewOrderTitle: 'Upgrade',
    title: 'Upgrade',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.maxCategoryType,
  },
  sunCard: {
    title: 'Sun Card',
    dynamicType: formDynamicTypes.default,
    customType: 'sun_card',
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  moonCard: {
    title: 'Moon Card',
    customType: 'moon_card',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  searchCard: {
    title: 'Search Card',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  dailyQuote: {
    title: 'Inspirations',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  myPlans: {
    title: 'Create a plan',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  pendingPlan: {
    title: 'Mentor Plan',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  dailyFeature: {
    title: 'Daily Aumhum',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  allDailyFeatures: {
    title: 'Previous Daily AUMHUMs ( History)',
    dynamicType: formDynamicTypes.dailyAumHum,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  showScoreCard: {
    title: 'Score Card',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.scoreCard,
  },
  onboardingPref: {
    title: 'Onboarding Preference',
    customType: 'onboardingPreference',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  myPractices: {
    title: 'Technique',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  myJournal: {
    title: 'Journal',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  myBreathe: {
    title: 'Breathe',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  journal: {
    title: 'Custom Journal',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: formFieldTypes?.systemGenerated,
  },
  // allDailyFeatures: {},
  // articles: EXPLORE_TAB_SETTING_OBJECT,
  // assessments: EXPLORE_TAB_SETTING_OBJECT,
  // autoPlay: EXPLORE_TAB_SETTING_OBJECT,
  // bgImageEnabled: true,
  // breatheByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // breatheTile: EXPLORE_TAB_SETTING_OBJECT,
  // brouwseByCategoryIndividualContentLayout: '',
  // browseByCategory: EXPLORE_TAB_SETTING_OBJECT,
  // browseByCategoryLayout: '',
  // browseByCategoryShowAllLayout: '',
  // categories: [],
  // category: '',
  // challengeCard: EXPLORE_TAB_SETTING_OBJECT,
  // chatTile: EXPLORE_TAB_SETTING_OBJECT,
  // coaching: EXPLORE_TAB_SETTING_OBJECT,
  // coachingByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // completedPlanItems: EXPLORE_TAB_SETTING_OBJECT,
  // contentCardLayout: '',
  // contentCardShowAllLayout: '',
  // contestCard: EXPLORE_TAB_SETTING_OBJECT,
  // coursesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // curatedArticles: EXPLORE_TAB_SETTING_OBJECT,
  // curatedAssessments: EXPLORE_TAB_SETTING_OBJECT,
  // curatedCard1: EXPLORE_TAB_SETTING_OBJECT,
  // curatedCard2: EXPLORE_TAB_SETTING_OBJECT,
  // curatedCard3: EXPLORE_TAB_SETTING_OBJECT,
  // curatedCardBreathe: EXPLORE_TAB_SETTING_OBJECT,
  // curatedCardJournal: EXPLORE_TAB_SETTING_OBJECT,
  // curatedMentalStateTypes: EXPLORE_TAB_SETTING_OBJECT,
  // customFeeling: EXPLORE_TAB_SETTING_OBJECT,
  // customGenre: EXPLORE_TAB_SETTING_OBJECT,
  // customTag: EXPLORE_TAB_SETTING_OBJECT,
  // customTechnique: EXPLORE_TAB_SETTING_OBJECT,
  // dailyFeature: EXPLORE_TAB_SETTING_OBJECT,
  // dailyQuote: EXPLORE_TAB_SETTING_OBJECT,
  // deleted: false,
  // description: '',
  // featuredSchools: EXPLORE_TAB_SETTING_OBJECT,
  // feelingByUser: EXPLORE_TAB_SETTING_OBJECT,
  // feelingList1: EXPLORE_TAB_SETTING_OBJECT,
  // feelingList2: EXPLORE_TAB_SETTING_OBJECT,
  // feelingList3: EXPLORE_TAB_SETTING_OBJECT,
  // feelings: EXPLORE_TAB_SETTING_OBJECT,
  // focusAreaFeelingByUser: EXPLORE_TAB_SETTING_OBJECT,
  // focusAreaGenreByUser: EXPLORE_TAB_SETTING_OBJECT,
  // focusAreaTagByUser: EXPLORE_TAB_SETTING_OBJECT,
  // genre: EXPLORE_TAB_SETTING_OBJECT,
  // genreByUser: EXPLORE_TAB_SETTING_OBJECT,
  // genreList1: EXPLORE_TAB_SETTING_OBJECT,
  // genreList2: EXPLORE_TAB_SETTING_OBJECT,
  // genreList3: EXPLORE_TAB_SETTING_OBJECT,
  // getInvolvedTile: EXPLORE_TAB_SETTING_OBJECT,
  // heroCard: EXPLORE_TAB_SETTING_OBJECT,
  // hrm: {
  //   categoryType: '',
  //   enabled: true,
  //   noOfSuchCategories: 0,
  //   refIds: [],
  // },
  // id: '',
  // images: [],
  // joinContestCard: EXPLORE_TAB_SETTING_OBJECT,
  // journal: EXPLORE_TAB_SETTING_OBJECT,
  // journalTile: EXPLORE_TAB_SETTING_OBJECT,
  // journals: EXPLORE_TAB_SETTING_OBJECT,
  // journalsByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // meTile: EXPLORE_TAB_SETTING_OBJECT,
  // meditationMentors: EXPLORE_TAB_SETTING_OBJECT,
  // meditationTile: EXPLORE_TAB_SETTING_OBJECT,
  // meditationsByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // mentalStateTypes: EXPLORE_TAB_SETTING_OBJECT,
  // modifiedBy: '',
  // modifiedTime: {},
  // moonCard: {
  //   categoryType: '',
  //   enabled: true,
  //   noOfSuchCategories: 0,
  //   refIds: [],
  // },
  // moreTile: EXPLORE_TAB_SETTING_OBJECT,
  // msgTranslation: {},
  // music: EXPLORE_TAB_SETTING_OBJECT,
  // musicByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // myBreathe: EXPLORE_TAB_SETTING_OBJECT,
  // myCourses: EXPLORE_TAB_SETTING_OBJECT,
  // myJournal: EXPLORE_TAB_SETTING_OBJECT,
  // myMeditations: EXPLORE_TAB_SETTING_OBJECT,
  // myMentors: EXPLORE_TAB_SETTING_OBJECT,
  // myPlans: EXPLORE_TAB_SETTING_OBJECT,
  // myPractices: EXPLORE_TAB_SETTING_OBJECT,
  // myQuotes: EXPLORE_TAB_SETTING_OBJECT,
  // myStories: EXPLORE_TAB_SETTING_OBJECT,
  // myTalks: EXPLORE_TAB_SETTING_OBJECT,
  // myTherapy: EXPLORE_TAB_SETTING_OBJECT,
  // myYoga: EXPLORE_TAB_SETTING_OBJECT,
  // my_journalTile: EXPLORE_TAB_SETTING_OBJECT,
  // my_libraryTile: EXPLORE_TAB_SETTING_OBJECT,
  // name: '',
  // onboardingPref: EXPLORE_TAB_SETTING_OBJECT,
  // order: [],
  // pendingPlan: EXPLORE_TAB_SETTING_OBJECT,
  // popularBreathe: EXPLORE_TAB_SETTING_OBJECT,
  // popularTeachers: EXPLORE_TAB_SETTING_OBJECT,
  // practiceTile: EXPLORE_TAB_SETTING_OBJECT,
  // quoteTile: EXPLORE_TAB_SETTING_OBJECT,
  // quotesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // recentlyAdded: EXPLORE_TAB_SETTING_OBJECT,
  // recommendedCourse: EXPLORE_TAB_SETTING_OBJECT,
  // recommendedCuratedCard: [],
  // searchCard: {
  //   categoryType: '',
  //   enabled: true,
  //   noOfSuchCategories: 0,
  //   refIds: [],
  // },
  // searchTile: EXPLORE_TAB_SETTING_OBJECT,
  // settingsTile: EXPLORE_TAB_SETTING_OBJECT,
  // shorts: EXPLORE_TAB_SETTING_OBJECT,
  // shortsByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // showScoreCard: true,
  // sleepTile: EXPLORE_TAB_SETTING_OBJECT,
  // sound: EXPLORE_TAB_SETTING_OBJECT,
  // soundArtist: EXPLORE_TAB_SETTING_OBJECT,
  // soundTile: EXPLORE_TAB_SETTING_OBJECT,
  // storiesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // story: EXPLORE_TAB_SETTING_OBJECT,
  // storyTellers: EXPLORE_TAB_SETTING_OBJECT,
  // storyTile: EXPLORE_TAB_SETTING_OBJECT,
  // sunCard: {
  //   categoryType: '',
  //   enabled: true,
  //   noOfSuchCategories: 0,
  //   refIds: [],
  // },
  // systemType1: EXPLORE_TAB_SETTING_OBJECT,
  // systemType2: EXPLORE_TAB_SETTING_OBJECT,
  // tag: EXPLORE_TAB_SETTING_OBJECT,
  // tagByUser: EXPLORE_TAB_SETTING_OBJECT,
  // tagList1: EXPLORE_TAB_SETTING_OBJECT,
  // tagList2: EXPLORE_TAB_SETTING_OBJECT,
  // tagList3: EXPLORE_TAB_SETTING_OBJECT,
  // talkToUsCard: EXPLORE_TAB_SETTING_OBJECT,
  // talksByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // technique: EXPLORE_TAB_SETTING_OBJECT,
  // techniqueByUser: EXPLORE_TAB_SETTING_OBJECT,
  // therapiesByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // tilePolicyId: '',
  // tiles: EXPLORE_TAB_SETTING_OBJECT,
  // titleTranslation: {},
  // trending: EXPLORE_TAB_SETTING_OBJECT,
  // tutorial: EXPLORE_TAB_SETTING_OBJECT,
  // upgrade: {
  //   categoryType: '',
  //   enabled: true,
  //   noOfSuchCategories: 0,
  //   refIds: [],
  // },
  // welcomeCard: EXPLORE_TAB_SETTING_OBJECT,
  // yogaByFocusArea: EXPLORE_TAB_SETTING_OBJECT,
  // yogaTeacher: EXPLORE_TAB_SETTING_OBJECT,
  // yogaTile: EXPLORE_TAB_SETTING_OBJECT,
}

export const newExploreFormFields = {
  curatedCard: {
    previewOrderTitle: 'Curated',
    title: 'Curated',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: newFormFieldTypes?.curated,
  },
  presetCard: {
    previewOrderTitle: 'Preset',
    title: 'Preset',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: newFormFieldTypes?.preset,
  },
  recentlyAddedCard: {
    previewOrderTitle: 'Recently Added',
    title: 'Recently Added',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: newFormFieldTypes?.preset,
  },
  popularCard: {
    previewOrderTitle: 'Popular',
    title: 'Popular',
    dynamicType: formDynamicTypes.default,
    fieldsInUse: newFormFieldTypes?.preset,
  },
}

const translationTypes = {
  altText: 'altTextTranslations',
  subText: 'subTextTranslations',
}

export const exploreAltTexts = [
  { id: 'tiles', title: 'Tiles', type: translationTypes?.altText },
  { id: 'dailyQuote', title: 'Inspirations', type: translationTypes?.altText },
  { id: 'myPlans', title: 'Create a plan', type: translationTypes?.altText },
  { id: 'dailyFeature', title: 'Daily Aumhum', type: translationTypes?.altText },
  { id: 'allDailyFeatures', title: 'Daily Aumhum (History)', type: translationTypes?.altText },
  { id: 'talkToUsCard', title: 'Help & Support (Title)', type: translationTypes?.altText },
  { id: 'talkToUsCard', title: 'Help & Support (Subtext)', type: translationTypes?.subText },
  { id: 'myMeditations', title: 'Meditations', type: translationTypes?.altText },
  { id: 'myTalks', title: 'Talks', type: translationTypes?.altText },
  { id: 'myStories', title: 'Stories', type: translationTypes?.altText },
  { id: 'myTherapy', title: 'Therapy', type: translationTypes?.altText },
  { id: 'myYoga', title: 'Yoga', type: translationTypes?.altText },
  { id: 'music', title: 'Music', type: translationTypes?.altText },
  { id: 'shorts', title: 'Shorts', type: translationTypes?.altText },
  { id: 'coaching', title: 'Coaching', type: translationTypes?.altText },
  { id: 'feelings', title: 'Feeling', type: translationTypes?.altText },
  { id: 'genre', title: 'Genre', type: translationTypes?.altText },
  { id: 'tag', title: 'Tag', type: translationTypes?.altText },
  { id: 'technique', title: 'Technique', type: translationTypes?.altText },
  { id: 'genreList1', title: 'Pills of Custom Genre 1', type: translationTypes?.altText },
  { id: 'genreList2', title: 'Pills of Custom Genre 2', type: translationTypes?.altText },
  { id: 'genreList3', title: 'Pills of Custom Genre 3', type: translationTypes?.altText },
  { id: 'feelingList1', title: 'Pills of Feeling 1', type: translationTypes?.altText },
  { id: 'feelingList1', title: 'Pills of Feeling 2', type: translationTypes?.altText },
  { id: 'feelingList1', title: 'Pills of Feeling 3', type: translationTypes?.altText },
  { id: 'tagList1', title: 'Pills of Tag 1', type: translationTypes?.altText },
  { id: 'tagList2', title: 'Pills of Tag 2', type: translationTypes?.altText },
  { id: 'tagList3', title: 'Pills of Tag 3', type: translationTypes?.altText },
  { id: 'techniquesList1', title: 'Pills of Technique 1', type: translationTypes?.altText },
  { id: 'techniquesList2', title: 'Pills of Technique 2', type: translationTypes?.altText },
  { id: 'techniquesList3', title: 'Pills of Technique 3', type: translationTypes?.altText },
  { id: 'curatedCard1', title: 'Curated Content 1', type: translationTypes?.altText },
  { id: 'curatedCard2', title: 'Curated Content 2', type: translationTypes?.altText },
  { id: 'curatedCard3', title: 'Curated Content 3', type: translationTypes?.altText },
  { id: 'popularTeachers', title: 'Popular Teacher', type: translationTypes?.altText },
  { id: 'meditationMentors', title: 'Meditation Mentors', type: translationTypes?.altText },
  { id: 'storyTellers', title: 'Storytellers', type: translationTypes?.altText },
  { id: 'soundArtist', title: 'Artists I follow (MUSIC)', type: translationTypes?.altText },
  { id: 'yogaTeacher', title: 'Yoga Teachers', type: translationTypes?.altText },
  { id: 'myMentors', title: 'My Mentors', type: translationTypes?.altText },
  { id: 'onboardingPref', title: 'Onboarding Preference', type: translationTypes?.altText },
  { id: 'myPractices', title: 'Practice', type: translationTypes?.altText },
  { id: 'myJournal', title: 'Journal', type: translationTypes?.altText },
  { id: 'myBreathe', title: 'Breathe', type: translationTypes?.altText },
  { id: 'sleepTile', title: 'Sleep Tile', type: translationTypes?.altText },
  { id: 'yogaTile', title: 'Yoga Tile', type: translationTypes?.altText },
  { id: 'soundTile', title: 'Sound Tile', type: translationTypes?.altText },
  { id: 'storyTile', title: 'Story Tile', type: translationTypes?.altText },
  { id: 'meditationTile', title: 'Meditation Tile', type: translationTypes?.altText },
  { id: 'chatTile', title: 'Chat Tile', type: translationTypes?.altText },
  { id: 'practiceTile', title: 'Practice Tile', type: translationTypes?.altText },
  { id: 'breatheTile', title: 'Breathe Tile', type: translationTypes?.altText },
  { id: 'my_journalTile', title: 'My Journal Tile', type: translationTypes?.altText },
  { id: 'quoteTile', title: 'Quote Tile', type: translationTypes?.altText },
  { id: 'my_libraryTile', title: 'My Library Tile', type: translationTypes?.altText },
  { id: 'journalTile', title: 'Journal Tile', type: translationTypes?.altText },
  { id: 'meTile', title: 'Me Tile', type: translationTypes?.altText },
  { id: 'moreTile', title: 'More Tile', type: translationTypes?.altText },
  { id: 'getInvolvedTile', title: 'Get Involved Tile', type: translationTypes?.altText },
  { id: 'settingsTile', title: 'Settings Tile', type: translationTypes?.altText },
  { id: 'articles', title: 'Articles', type: translationTypes?.altText },
  { id: 'curatedArticles', title: 'Curated Articles', type: translationTypes?.altText },
  { id: 'curatedAssessments', title: 'Curated Assessments', type: translationTypes?.altText },
]

export const exploreTabSettingsFormMaker = {
  column1: {
    categoryCards: {
      title: 'Category Cards(Pills, Vertical List, etc)',
      form: {
        feelings: exploreFormFields.feelings,
        genre: exploreFormFields.genre,
        tag: exploreFormFields.tag,
        technique: exploreFormFields.technique,
        feelingList1: exploreFormFields.feelingList1,
        feelingList2: exploreFormFields.feelingList2,
        feelingList3: exploreFormFields.feelingList3,
        genreList1: exploreFormFields.genreList1,
        genreList2: exploreFormFields.genreList2,
        genreList3: exploreFormFields.genreList3,
        tagList1: exploreFormFields.tagList1,
        tagList2: exploreFormFields.tagList2,
        tagList3: exploreFormFields.tagList3,
        techniquesList1: exploreFormFields.techniquesList1,
        techniquesList2: exploreFormFields.techniquesList2,
        techniquesList3: exploreFormFields.techniquesList3,
      },
    },
    tiles: {
      title: 'Tiles Section',
      form: {
        tiles: exploreFormFields.tiles,
      },
    },
    systemCards: {
      title: 'System Cards',
      form: {
        sunCard: exploreFormFields.sunCard,
        moonCard: exploreFormFields.moonCard,
        searchCard: exploreFormFields.searchCard,
        upgrade: exploreFormFields.upgrade,
        dailyQuote: exploreFormFields.dailyQuote,
        myPlans: exploreFormFields.myPlans,
        pendingPlan: exploreFormFields.pendingPlan,
        dailyFeature: exploreFormFields.dailyFeature,
        allDailyFeatures: exploreFormFields.allDailyFeatures,
        showScoreCard: exploreFormFields.showScoreCard,
        talkToUsCard: exploreFormFields.talkToUsCard,
        welcomeCard: exploreFormFields.welcomeCard,
      },
    },
  },
  column2: {
    userSelectionFocusArea: {
      title: 'Based on User Selection of Focus Area',
      form: {
        focusAreaGenreByUser: exploreFormFields.focusAreaGenreByUser,
        focusAreaFeelingByUser: exploreFormFields.focusAreaFeelingByUser,
        focusAreaTagByUser: exploreFormFields.focusAreaTagByUser,
      },
    },
    userSpecificCourse: {
      title: 'User specific course',
      form: {
        genreByUser: exploreFormFields.genreByUser,
        tagByUser: exploreFormFields.tagByUser,
        feelingByUser: exploreFormFields.feelingByUser,
        techniqueByUser: exploreFormFields.techniqueByUser,
      },
    },
    systemGenerated: {
      title: 'System Generated Course',
      form: {
        autoPlay: exploreFormFields.autoPlay,
        trending: exploreFormFields.trending,
        recentlyAdded: exploreFormFields.recentlyAdded,
      },
    },
    focusAreaDrivenCourses: {
      title: 'Focus Area Driven Popular Courses (As on old me Tab)',
      form: {
        myMeditations: exploreFormFields.myMeditations,
        myTalks: exploreFormFields.myTalks,
        myStories: exploreFormFields.myStories,
        myTherapy: exploreFormFields.myTherapy,
        myYoga: exploreFormFields.myYoga,
        music: exploreFormFields.music,
        shorts: exploreFormFields.shorts,
        coaching: exploreFormFields.coaching,
      },
    },
    curatedCardCarousel: {
      title: 'Curated Card Carousel (WIP)',
      form: {
        curatedCard1: exploreFormFields.curatedCard1,
        curatedCard2: exploreFormFields.curatedCard2,
        curatedCard3: exploreFormFields.curatedCard3,
      },
    },
    soundSection: {
      title: 'Sound Section',
      form: {
        sound: exploreFormFields.sound,
      },
    },
    mentorCarousels: {
      title: 'Mentor Carousels',
      form: {
        popularTeachers: exploreFormFields.popularTeachers,
        meditationMentors: exploreFormFields.meditationMentors,
        storyTellers: exploreFormFields.storyTellers,
        soundArtist: exploreFormFields.soundArtist,
        yogaTeacher: exploreFormFields.yogaTeacher,
        myMentors: exploreFormFields.myMentors,
      },
    },
    journalCarousel: {
      title: 'Journal Carousel',
      form: {
        customGenre: exploreFormFields.customGenre,
        customTag: exploreFormFields.customTag,
        customFeeling: exploreFormFields.customFeeling,
        heroCard: exploreFormFields.heroCard,
      },
    },
    focusAreaDrivenFeatures: {
      title: 'Focus Area Driven Popular Features (As on old me Tab)',
      form: {
        onboardingPref: exploreFormFields.onboardingPref,
        myJournal: exploreFormFields.myJournal,
        myPractices: exploreFormFields.myPractices,
        myBreathe: exploreFormFields.myBreathe,
      },
    },
    assessments: {
      title: 'Assessments',
      form: {
        assessments: exploreFormFields.assessments,
        curatedAssessments: exploreFormFields.curatedAssessments,
      },
    },
    articles: {
      title: 'Articles',
      form: {
        articles: exploreFormFields.articles,
        curatedArticles: exploreFormFields.curatedArticles,
      },
    },
    mentalStateTypes: {
      title: 'Mental State Types',
      form: {
        mentalStateTypes: exploreFormFields.mentalStateTypes,
        curatedMentalStateTypes: exploreFormFields.curatedMentalStateTypes,
      },
    },
  },
  previewOrderCol1: {
    autoPlay: {
      title: 'Auto Play',
      form: {
        autoPlay: exploreFormFields.autoPlay,
      },
    },
  },
}

export function showCustomLayout(orderItem, listOfCategoryItems) {
  const commonExceptions = [
    'myPlans',
    'upgrade',
    'searchCard',
    'sun_card',
    'pendingPlan',
    'moon_card',
    'talkToUsCard',
    'welcomeCard',
    'dailyFeature',
    'tiles',
  ]

  const exceptions = {
    layout: [
      ...commonExceptions,
      'tagByUser',
      'feelingByUser',
      'genreByUser',
      'techniqueByUser',
      'dailyQuote',
      'focusAreaGenreByUser',
      'focusAreaTagByUser',
      'focusAreaFeelingByUser',
    ],
    showAllButton: [...commonExceptions, 'browseByCategory', 'allDailyFeatures'],
    showAllEntryLayout: [...commonExceptions],
    listLayout: [...commonExceptions, 'allDailyFeatures', 'genre', 'tag', 'feelings'],
    onTapBehavior: [
      'genreList1',
      'tagList1',
      'feelingList1',
      'genre',
      'tag',
      'feeling',
      'technique',
    ],
  }

  const isListOfCategoryItems = listOfCategoryItems?.includes(orderItem?.type)

  const layoutLabel = isListOfCategoryItems ? 'Browse By Category' : 'Content Card'
  const listLayoutLabel = isListOfCategoryItems
    ? 'Browse by Category Individual Content'
    : 'Content Card Show All'
  return {
    showContentCard: !exceptions.layout.includes(orderItem?.type),
    showShowAllButton: !exceptions.showAllButton.includes(orderItem?.type),
    showShowAllEntryLayout:
      !exceptions.showAllEntryLayout.includes(orderItem?.type) && isListOfCategoryItems,
    showListLayout: !exceptions.listLayout.includes(orderItem?.type),
    showOnTapBehavior: exceptions.onTapBehavior.includes(orderItem?.type) && isListOfCategoryItems,
    layoutLabel,
    listLayoutLabel,
  }
}

export function getListOfCategoryItem() {
  return Object.entries(exploreFormFields)
    .filter(([field, formValues]) => !!formValues?.isCategoryItem)
    .map(([field, formValues]) => field)
}

export const categoryTypeToOrderType = {
  sun_card: 'sunCard',
  moon_card: 'moonCard',
  onboardingPreference: 'onboardingPref',
  'yoga-teacher': 'yogaTeacher',
  'sound-artist': 'soundArtist',
  'story-teller': 'storyTellers',
}

export async function getCuratedCardOptions(
  type,
  gotCategories,
  contentLanguages,
  pageSize,
  currentPage,
  search,
) {
  const category = gotCategories.join(',')
  const languages = contentLanguages
  const params = {
    languages: languages.join(','),
    size: pageSize,
    page: currentPage,
    search: search,
    includeDisabled: false,
  }
  let toReturn = []
  switch (type) {
    case 'course':
      const courseResponse = await GetSettingsData('oc/online-courses', {
        category: category,
        ...params,
      })
      if (courseResponse.status === 200) {
        toReturn = {
          ...courseResponse.data,
          content: courseResponse.data?.content?.map((course) => {
            return {
              ...course,
              value: course.id,
              label: course?.title || course?.name,
            }
          }),
        }
      }
      break
    case 'breathe':
      const breatheResponse = await GetData(APIs.breathePrograms.url, {
        ...params,
        type: 'breathe',
      })
      if (breatheResponse.status === 200) {
        toReturn = {
          content: breatheResponse.data
            ?.filter((breathe) => {
              return breathe.enabled && breathe.translations?.[languages?.[0]]?.title
            })
            ?.map((breathe) => {
              return {
                ...breathe,
                value: breathe.id,
                label: breathe.name,
                subscriptionId: breathe.subscription?.id,
              }
            }),
          last: true,
          number: breatheResponse.data?.length,
          pageable: { pageSize: breatheResponse.data?.length },
        }
      }
      break
    case 'quote':
      const quoteResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...params,
        entityType: 'quote',
      })
      // const quoteResponse = await GetData(APIs.quotes.url, {
      //   ...params,
      //   type: 'quote',
      // })
      if (quoteResponse.status === 200) {
        console.log('inside options')
        toReturn = {
          ...quoteResponse?.data,
          content: quoteResponse?.data.content
            ?.filter(
              (quote) => quote.enabled,
              //  && quote.translations?.[languages?.[0]]?.author, is this needed?
            )
            ?.map((quote) => {
              return {
                ...quote,
                value: quote?.id,
                label: `${quote?.author} - ${quote?.msg}`,
                subscriptionId: 'subscription_basic',
              }
            }),
        }
      }
      break
    case 'journal':
      const journalResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...params,
        entityType: 'journal',
        journalType: 'regular',
      })
      if (journalResponse.status === 200) {
        toReturn = {
          ...journalResponse.data,
          content: journalResponse.data.content
            .filter(
              (journal) =>
                journal.journalType === 'regular' &&
                journal.enabled &&
                journal.translations?.[languages?.[0]]?.title,
            )
            .map((journal) => {
              return {
                ...journal,
                value: journal.id,
                label: `${journal.title || 'No Title'}`,
                subscriptionId: journal.subscription?.id,
              }
            }),
        }
      }
      break
    case 'practice':
      const practiceResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        entityType: 'practice',
        ...params,
      })
      if (practiceResponse.status === 200) {
        toReturn = {
          ...practiceResponse.data,
          content: practiceResponse.data.content
            ?.filter(
              (practice) => practice?.enabled && practice?.translations?.[languages?.[0]]?.title,
            )
            ?.map((practice) => {
              return {
                ...practice,
                value: practice?.id,
                label: practice?.title,
                subscriptionId: practice?.subscription?.id,
              }
            }),
        }
      }
      break
    default:
      const defaultResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        entityType: type,
        ...params,
      })
      if (defaultResponse.status === 200) {
        toReturn = {
          ...defaultResponse.data,
          content: defaultResponse.data.content.map((data) => ({
            ...data,
            value: data?.id,
            label: data?.title || data?.name || data?.internalTitle,
          })),
        }
      }
      break
  }
  console.log(toReturn)
  return toReturn
}

export async function newGetCuratedCardOptions(
  type,
  gotCategories,
  contentLanguages,
  pageSize,
  currentPage,
  search,
  extraParams,
) {
  const category = gotCategories?.join(',')
  const languages = contentLanguages
  const params = {
    languages: languages?.join(','),
    size: pageSize,
    page: currentPage,
    search: search,
    includeDisabled: false,
    ...extraParams,
  }
  let toReturn = []
  console.log({ type })
  switch (type) {
    case 'course':
      const courseResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        category: category,
        entityType: 'course',
        ...params,
        includeDisabled: true,
      })
      if (courseResponse.status === 200) {
        toReturn = {
          ...courseResponse.data,
          content: courseResponse.data?.content
            ?.filter((course) => {
              const array1 = _.sortBy(course.categories)
              const array2 = _.sortBy(gotCategories)
              const catsMatch =
                _.isEmpty(_.difference(array1, array2)) || _.isEmpty(_.difference(array2, array1))
              return catsMatch && course.status === 'published'
            })
            ?.map((course) => {
              return {
                ...course,
                value: course.id,
                label: course?.title || course?.name,
              }
            }),
        }
      }
      break
    case 'breathe':
      const breatheResponse = await GetData(APIs.breathePrograms.url, {
        ...params,
        type: 'breathe',
      })
      if (breatheResponse.status === 200) {
        toReturn = {
          content: breatheResponse.data
            ?.filter((breathe) => {
              return breathe.translations?.en?.title
            })
            ?.map((breathe) => {
              return {
                ...breathe,
                value: breathe.id,
                label: breathe.name,
                subscriptionId: breathe.subscription?.id,
              }
            }),
          last: true,
          number: breatheResponse.data?.length,
          pageable: { pageSize: breatheResponse.data?.length },
        }
      }
      break
    case 'quote':
      const quoteResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...params,
        entityType: 'quote',
      })
      if (quoteResponse.status === 200) {
        console.log('inside options')
        toReturn = {
          ...quoteResponse?.data,
          content: quoteResponse?.data.content?.map((quote) => {
            return {
              ...quote,
              value: quote?.id,
              label: `${quote?.author} - ${quote?.msg}`,
              subscriptionId: 'subscription_basic',
            }
          }),
        }
      }
      break
    case 'practice':
      const practiceResponse = await GetData(APIs.meditations.url, {
        ...params,
      })
      if (practiceResponse.status === 200) {
        toReturn = {
          ...practiceResponse.data,
          content: practiceResponse.data.content
            ?.filter((practice) => practice?.translations?.en?.title)
            ?.map((practice) => {
              return {
                ...practice,
                value: practice?.id,
                label: practice?.title,
                subscriptionId: practice?.subscription?.id,
              }
            }),
        }
      }
      break
    case 'mentor':
      const mentorResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        entityType: 'mentor',
        categories: category,
        ...params,
        languages: null,
      })
      if (mentorResponse.status === 200) {
        toReturn = {
          ...mentorResponse.data,
          content: mentorResponse.data?.content.map((data) => ({
            ...data,
            value: data?.id,
            label: data?.title || data?.name || data?.internalTitle,
          })),
        }
      }
      break
    case 'tag':
      const tagResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        entityType: 'tag',
        type: category,
        ...params,
      })
      if (tagResponse.status === 200) {
        toReturn = {
          ...tagResponse.data,
          content: tagResponse.data?.content
            ?.filter((tag) => {
              if (!tag.enabled) {
                return false
              }
              return true
            })
            .map((data) => ({
              ...data,
              value: data?.id,
              label: data?.title || data?.name || data?.internalTitle,
            })),
        }
      }
      break
    case 'screen':
      const screenResponse = convertArrayToPaginatedObject(POPUP_SCREEN_TYPES)
      if (screenResponse) {
        toReturn = {
          ...screenResponse,
          content: screenResponse?.content?.map((data) => ({
            ...data,
            value: data?.id,
            label: data?.title || data?.name || data?.internalTitle,
          })),
        }
      }
      break

    case 'tiles':
      const { languages, includeDisabled, ...tileParams } = params
      const tilePolicyResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        category,
        entityType: 'tile-policy',
        ...tileParams,
        size: 500,
      })
      if (tilePolicyResponse.status === 200) {
        toReturn = {
          ...tilePolicyResponse.data,
          content: tilePolicyResponse.data.content?.map((data) => ({
            ...data,
            value: data?.id,
            label: data?.title || data?.name || data?.internalTitle,
          })),
        }
      }
      break
    default:
      const defaultResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        entityType: type,
        category,
        ...params,
      })
      if (defaultResponse.status === 200) {
        toReturn = {
          ...defaultResponse.data,
          content: defaultResponse.data.content.map((data) => ({
            ...data,
            value: data?.id,
            label: data?.title || data?.name || data?.internalTitle,
          })),
        }
      }
      break
  }
  return {
    ...toReturn,
    content: toReturn?.content?.map((cont) => ({
      ...cont,
      metaCategoryType: apiParamToMetaCategoryType?.[type] || type,
    })),
  }
}

export async function getCuratedCardRefs(id, type) {
  var toReturn = {}
  switch (type) {
    case 'course':
      const response = await GetData(
        `${ONLINE_COURSES_APIS.onlineCourse.getSingleSummary}`.replace('{courseId}', id),
      )
      if (response.status === 200) {
        const courseResponse = response.data
        toReturn = {
          ...courseResponse,
          label: courseResponse?.title,
          value: courseResponse?.id,
        }
      }
      break
    case 'quote':
      const quoteResponse = await GetFeatureData(FEATURES_API.quotes.getSingle.url + id, {
        quoteId: id.itemId,
      })
      if (quoteResponse.status === 200) {
        toReturn = {
          ...quoteResponse.data,
          label: `${quoteResponse.data.author} - ${quoteResponse.data.msg}`,
          value: quoteResponse.data.id,
        }
      }
      break
    case 'breathe':
      const breatheResponse = await GetFeatureData(FEATURES_API.breathe.getSingle.url, {
        id: id,
      })
      if (breatheResponse.status === 200) {
        const foundBreathe = breatheResponse.data
        toReturn = {
          ...foundBreathe,
          label: foundBreathe?.name,
          value: quoteResponse.id,
        }
      }
      break
    case 'journal':
      const journalResponse = await GetFeatureData(FEATURES_API.journals.getSingle.url, {
        id: id,
      })
      if (journalResponse.status === 200) {
        toReturn = {
          ...journalResponse.data,
          label: `${journalResponse.data.title}`,
          value: journalResponse.data.id,
        }
      }
      break
    case 'mentor':
      const mentorResponse = await GetSettingsData('mentor/list/summary/lightweight', {
        teacherIds: id,
      })
      if (mentorResponse.status === 200) {
        const foundPractice = mentorResponse.data
        toReturn = {
          ...foundPractice,
          label: foundPractice?.title,
          value: foundPractice.id,
        }
      }
      break
    case 'tile':
      const tilePolicyResponse = await GetSettingsData(GENERIC_APIS.entity.details, {
        entityType: 'tile-policy',
      })
      if (tilePolicyResponse.status === 200) {
        toReturn = {
          ...tilePolicyResponse.data,
          label: tilePolicyResponse.data?.title,
          value: tilePolicyResponse.data.id,
        }
      }
      break
    default:
      const dataResponse = await GetSettingsData('tile/details', {
        id: id,
        // entityType: type,
      })
      if (dataResponse.status === 200) {
        const foundPractice = dataResponse.data
        toReturn = {
          ...foundPractice,
          label: foundPractice?.name || foundPractice?.name,
          value: foundPractice.id,
        }
      }
      break
  }
  return toReturn
}

export async function getCuratedCardRefsBulk(idList, metaCategoryType, idType = IdTypes.id) {
  const type = META_CATEGORY_TYPES_TO_API_PARAM?.[metaCategoryType] || metaCategoryType

  const idsParameter = {
    ids: idType === IdTypes.id ? idList.join(',') : null,
    slug: idType === IdTypes.slug ? idList.join(',') : null,
  }

  var toReturn = []
  if (!idList) {
    return toReturn
  }
  switch (type) {
    case 'course':
      const courseResp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...idsParameter,
        entityType: type,
        includeDraft: true,
      })
      if (courseResp.status === 200) {
        const foundData = courseResp.data.content
        toReturn = foundData?.map((data) => ({
          ...data,
          label: data?.title || data?.name,
          value: data.id,
        }))
      }
      break
    case 'tag':
      const tagResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ids: idList.join(','),
        entityType: type,
      })
      if (tagResponse.status === 200) {
        const foundTags = tagResponse.data.content
        toReturn = foundTags.map((tag) => ({
          ...tag,
          label: tag?.title,
          value: tag.id,
        }))
      }
      break
    case 'mentor':
      const mentorResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...idsParameter,
        entityType: 'mentor',
      })
      if (mentorResponse.status === 200) {
        const foundMentor = mentorResponse?.data?.content
        toReturn = foundMentor?.map((mentor) => ({
          ...mentor,
          label: mentor?.name,
          value: mentor.id,
        }))
      }
      break
    case 'screen':
      const screenResponse = POPUP_SCREEN_TYPES
      if (screenResponse) {
        toReturn = screenResponse
          ?.filter((screen) => idList.includes(screen.id))
          ?.map((screen) => ({
            ...screen,
            label: screen?.title,
            value: screen.id,
          }))
      }
      break
    case 'booked_event_notification':
      const bookingEventNotificationResponse = await fetchFunction(
        'user/booked-slot/details',
        { ...idsParameter, id: idsParameter?.ids },
        false,
      )
      if (
        bookingEventNotificationResponse &&
        isCommonResponseSuccessful(bookingEventNotificationResponse.code)
      ) {
        const foundBookingEvent = bookingEventNotificationResponse.data.bookedEvent
        toReturn = [
          {
            ...foundBookingEvent,
            value: foundBookingEvent?.id,
            label: `${foundBookingEvent?.mentorName}: ${foundBookingEvent?.title}@${moment(
              foundBookingEvent?.startTime,
            ).format('YYYY-MM-DD HH:mm:ss')}`,
          },
        ]
      }
      break
    default:
      const dataResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...idsParameter,
        entityType: type,
      })
      if (dataResponse.status === 200) {
        const foundData = dataResponse.data.content
        toReturn = foundData?.map((data) => ({
          ...data,
          label: data?.title || data?.name,
          value: data.id,
        }))
      }
      break
  }
  console.log({ toReturn })
  return toReturn?.map((ret) => ({
    ...ret,
    metaCategoryType: apiParamToMetaCategoryType?.[type] || type,
  }))
}

const apiParamToMetaCategoryType = {
  mentor: 'service-provider',
  'tile-policy': 'tiles',
  quote: 'quotes',
  'mental-state-type': 'mentalStateType',
}

// case 'quote':
//   const quoteResponse = await GetFeatureData(FEATURES_API.quotes.getSingle.url + id, {
//     quoteId: id.itemId,
//   })
//   if (quoteResponse.status === 200) {
//     toReturn = {
//       ...quoteResponse.data,
//       label: `${quoteResponse.data.author} - ${quoteResponse.data.msg}`,
//       value: quoteResponse.data.id,
//     }
//   }
//   break
// case 'breathe':
//   const breatheResponse = await GetFeatureData(FEATURES_API.breathe.getSingle.url, {
//     id: id,
//   })
//   if (breatheResponse.status === 200) {
//     const foundBreathe = breatheResponse.data
//     toReturn = {
//       ...foundBreathe,
//       label: foundBreathe?.name,
//       value: quoteResponse.id,
//     }
//   }
//   break
// case 'journal':
// const journalResponse = await GetFeatureData(FEATURES_API.journals.getSingle.url, {
//   id: id,
// })
// if (journalResponse.status === 200) {
//   toReturn = {
//     ...journalResponse.data,
//     label: `${journalResponse.data.title}`,
//     value: journalResponse.data.id,
//   }
// }
// break

//GET NEW CURATED CARD OPTIONS SWITCH CASE
// case 'journal':
//   const journalResponse = await GetFeatureData(FEATURES_API.journals.get.url, {
//     ...params,
//     type: 'journal',
//     journalType: 'regular',
//   })
//   if (journalResponse.status === 200) {
//     toReturn = {
//       ...journalResponse.data,
//       content: journalResponse.data.content
//         .filter(
//           (journal) => journal.journalType === 'regular' && journal.translations?.en?.title,
//         )
//         .map((journal) => {
//           return {
//             ...journal,
//             value: journal.id,
//             label: `${journal.title || 'No Title'}`,
//             subscriptionId: journal.subscription?.id,
//           }
//         }),
//     }
//   }
//   break
