import moment from 'moment'
import React from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import PaginatedListingTableHandlerWrapper from '../../PaginatedListingTableHandlerWrapper'

export default function UserSelfPractice({ userId, data, setData }) {
  return (
    <PaginatedListingTableHandlerWrapper
      apiCallFunction={GetSettingsData}
      apiCallParams={{ userId: userId, type: 'self', size: 200 }}
      apiCallUrl={USERS_API_SIGNATURES_MAP.usageDetails.selfPractices.get}
      data={data}
      setData={setData}
      setupDataDependencies={[userId]}
    >
      <CCExtendedCSmartTable
        noUserNames
        items={data?.data}
        loading={data?.status === 'loading'}
        columns={columns}
        scopedColumns={scopedColumns}
        excludeGenericFields={[
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ]}
      />
    </PaginatedListingTableHandlerWrapper>
  )
}

const columns = [
  { key: 'title', label: 'Title', sorter: false, filter: false },
  { key: 'timeOfPractice', label: 'Practice Time (seconds)', sorter: false, filter: false },
  { key: 'selfPracticeCount', label: 'Self Practice', sorter: false, filter: false },
  { key: 'selfPracticeTime', label: 'Self Practice Time', sorter: false, filter: false },
  { key: 'difficultyLevel', label: 'Difficulty', sorter: false, filter: false },
  { key: 'lastSessionDate', label: 'Last Session Date', sorter: false, filter: false },
  { key: 'createdTime', label: 'Created Time', sorter: false, filter: false },
]

const scopedColumns = {
  title: (item) => {
    return <td>{item?.meditation?.title}</td>
  },
  timeOfPractice: (item) => {
    var total = item?.selfPracticeTime + item?.guidedLiveTime + item?.guidedTrackTime
    var min = total / 60000
    return <td>{Math.round(min * 100) / 100}</td>
  },
  difficultyLevel: (item) => {
    if (item?.meditation !== undefined && item?.meditation !== null) {
      if (
        item?.meditation?.difficultyLevel !== undefined &&
        item?.meditation?.difficultyLevel !== null
      ) {
        return <td>{item?.meditation?.difficultyLevel?.level}</td>
      }
    }
    return <td>--</td>
  },
  lastSessionDate: (item) => {
    return (
      <td>
        {item?.lastSessionDate
          ? moment(item?.lastSessionDate).format(
              MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
            )
          : '--'}
      </td>
    )
  },
  createdTime: (item) => {
    return (
      <td>
        {moment(item?.createdTime).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
      </td>
    )
  },
}
