import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const SUPPORT_STAFF_OBJECT = {
  id: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  colors: COLORS_OBJECT,
  apiParam: 'support-staff',
  userId: '',
  orgId: '',
  supportRoles: [],
  startDate: '',
}

export const SUPPORT_ROLES_OBJECT = {
  clinicId: '',
  managerId: '',
  roleId: '',
  startDate: '',
}
