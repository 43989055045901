import { CFormInput } from '@coreui/react-pro'
import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import { selectSummaryListOfConsumerPlans } from 'src/reducers/subscriptions/subscriptionReducer'
import { SUBSCRIPTION_IAPS, SUBSCRIPTION_IAPS_ARRAY } from 'src/utilities/constants'

export default function AddPackagePaywall({
  paywall,
  handleClose,
  handleAddPackage,
  paymentMethods,
}) {
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState()
  const consumerSubscriptionPlans = useSelector(selectSummaryListOfConsumerPlans)
  const [paymentGateway, setPaymentGateway] = useState({
    ...SUBSCRIPTION_IAPS_ARRAY?.[3],
    title: 'Any',
  })

  const filteredSubscriptionPlansOnPlatform = consumerSubscriptionPlans?.data?.filter((plan) => {
    // If no payment methods are specified, include all plans
    if (!paymentMethods || paymentMethods.length === 0) {
      return true
    }
    // Filter plans where at least one of the payment methods is available on its platforms
    const hasRelevantPlatform = plan?.platforms?.some((platform) =>
      paymentMethods.includes(platform),
    )
    return hasRelevantPlatform
  })

  const alternateFilteredSubscriptionPlans = filteredSubscriptionPlansOnPlatform?.filter(
    (planItem) => {
      if (paymentGateway?.id === SUBSCRIPTION_IAPS.all) {
        return true
      }
      return planItem?.pymntGtyInfo?.some(
        (gtyInfo) =>
          gtyInfo?.pymntGtyId === paymentGateway?.id && Boolean(gtyInfo?.pymntGtyProductId),
      )
    },
  )

  //       pymntGtyId : "ios_iap"
  //       pymntGtyProductId : "com.aumhum.donationmin"

  return (
    <>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div>Add Package</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <GridSectionWrapper shadow={false}>
          <Grid item xs={12}>
            <CustomReactSelect
              id="paymentGateway"
              value={paymentGateway}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              options={SUBSCRIPTION_IAPS_ARRAY.map((plat) =>
                plat?.id === SUBSCRIPTION_IAPS.all ? { ...plat, title: 'Any' } : plat,
              ).sort((a, b) => a?.title.localeCompare(b?.title))}
              disabled={false}
              selectType={selectTypes.object}
              onChangeEvent={(e) => {
                setPaymentGateway(e?.target?.value)
              }}
              label={'Payment Gateway: '}
              labelPlacement={'top'}
              menuPortalTarget={document.body}
              menuPlacement="auto"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomReactSelect
              id="subscriptionPlan"
              value={selectedSubscriptionPlan}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              options={alternateFilteredSubscriptionPlans.sort((a, b) =>
                a?.title.localeCompare(b?.title),
              )}
              disabled={false}
              selectType={selectTypes.object}
              onChangeEvent={(e) => {
                setSelectedSubscriptionPlan(e.target.value)
              }}
              label={'Subscription Plan: '}
              labelPlacement={'top'}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              isClearable
            />
          </Grid>
        </GridSectionWrapper>

        {/* Add Here */}
        {selectedSubscriptionPlan && (
          <>
            <GridSectionWrapper
              hideDivider={true}
              heading="Selected Subscription Details"
              shadow={false}
            >
              <Grid item xs={12} md={12}>
                <CFormInput
                  id="androidPaymentGateway"
                  label="Android Payment Gateway"
                  labelplacement="start"
                  value={
                    selectedSubscriptionPlan?.pymntGtyInfo?.find(
                      ({ pymntGtyId }) => pymntGtyId === 'android_iap',
                    )?.pymntGtyProductId || ''
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CFormInput
                  id="iosPaymentGateway"
                  label="iOS Payment Gateway"
                  labelplacement="start"
                  value={
                    selectedSubscriptionPlan?.pymntGtyInfo?.find(
                      ({ pymntGtyId }) => pymntGtyId === 'ios_iap',
                    )?.pymntGtyProductId || ''
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CFormInput
                  id="giftUpPaymentGateway"
                  label="Gift Up Payment Gateway"
                  labelplacement="start"
                  value={
                    selectedSubscriptionPlan?.pymntGtyInfo?.find(
                      ({ pymntGtyId }) => pymntGtyId === 'gift_up',
                    )?.pymntGtyProductId || ''
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CFormInput
                  id="validity"
                  label="Validity (in days)"
                  labelplacement="start"
                  value={selectedSubscriptionPlan?.validity || ''}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Checkbox checked={selectedSubscriptionPlan?.recurring} />}
                  label="Recurrning: "
                  disabled={true}
                />
              </Grid>
            </GridSectionWrapper>
            <GridSectionWrapper hideDivider={true} shadow={false}>
              <Grid container xs={12} md={12} justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleAddPackage(selectedSubscriptionPlan)
                  }}
                >
                  Add
                </Button>
              </Grid>
            </GridSectionWrapper>
          </>
        )}
      </DialogContent>
    </>
  )
}

// const filteredSubscriptionPlans = filteredSubscriptionPlansOnPlatform?.filter((planItem) => {
//   if (paymentGateway?.length === 0) {
//     return true
//   }

//   const relevantPymntGtwyInfos = planItem?.pymntGtyInfo?.filter((gtyInfo) => {
//     return paymentGateway?.id?.includes(gtyInfo?.pymntGtyId) //
//   })

//   const pymntGtwysThatHaveProductId = relevantPymntGtwyInfos?.filter((gtwyInfo) => {
//     return !!gtwyInfo?.pymntGtyProductId
//   })

//   return pymntGtwysThatHaveProductId?.length >= paymentGateway?.id?.length
// })

// const alternateFilteredSubscriptionPlans = filteredSubscriptionPlansOnPlatform?.filter(
//   (planItem) =>
//     planItem?.pymntGtyInfo?.filter(
//       (gtyInfo) => (gtyInfo?.pymntGtyId === paymentGateway?.id) ? ((gtyInfo?.pymntGtyProductId)? true : false) : false,
//     ),
// )
