import PropTypes from 'prop-types'
import React from 'react'
import { getMediaImageUrl } from 'src/services'
import { GalleryImageContainer, GalleryImages } from '../styledComponents'
import ImagesSkeleton from './ImagesSkeleton'

ImageGallery.propTypes = {
  loading: PropTypes.bool,
  images: PropTypes.array,
  onChange: PropTypes.func,
  onChangeType: PropTypes.string,
}

export default function ImageGallery({ loading, images, onChange, onChangeType = 'id' }) {
  function handleChange(image) {
    if (onChangeType === 'object') {
      onChange(image)
    } else {
      onChange(image.imageId)
    }
  }

  return (
    <>
      {loading ? (
        <ImagesSkeleton />
      ) : (
        <GalleryImages>
          {images?.map((image) => {
            return (
              <GalleryImageContainer key={image.id} focusRipple onClick={() => handleChange(image)}>
                <img src={`${getMediaImageUrl()}${image.imageId}`} alt={image.type} />
              </GalleryImageContainer>
            )
          })}
        </GalleryImages>
      )}
    </>
  )
}
