export function getNewProspectObject(inpVal) {
  return {
    userId: inpVal,
    userName: `${inpVal} (user will be created automatically)`,
    __isNew__: true,
  }
}

export function getOptionLabelForUserInput(object) {
  return object?.name || object?.firstName || object.userName
}

export function getOptionLabelForMentorInput(object) {
  return object?.title || object.userName
}
