import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, { useEffect, useState } from 'react'

export function AccordionSectionWrapper({
  currentSection,
  heading,
  children,
  expandedSection,
  setExpandedSection,
}) {
  const isExpanded = expandedSection === currentSection

  const [expand, setExpand] = useState(false) // TODO: Make this look more like FAQS in the consumer app.

  function handleExpand() {
    if (isExpanded) {
      setExpand((prev) => !prev)
    }
    setExpandedSection(currentSection)
  }

  useEffect(() => {
    setExpand(isExpanded)
  }, [isExpanded])

  return (
    <Accordion expanded={expand} onChange={handleExpand}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <h4>{heading}</h4>
      </AccordionSummary>
      <AccordionDetails>
        <div className="d-flex flex-column gap-3">{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}
