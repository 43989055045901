import { CFormInput } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginGetFile from 'filepond-plugin-get-file'
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import 'filepond/dist/filepond.min.css'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { apiURL } from 'src/services'
import { calculateMinWidthAndHeight } from 'src/utilities/generalUtils'
import {
  IMAGE_GALLERY_PAGES,
  entityFormComponents,
  entityTitle,
} from '../../../config/common/genericListingAndFormConfig'
import '../../../dist/doka/doka.css'
import { create } from '../../../dist/doka/doka.js'
import { ImageUploadContext } from './ImageUpload'
import { ImageUploadChooseHeader } from './ImageUploadChoose'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginGetFile,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImageValidateSize,
)

export default function ImageUploadPage({ setPage, setFile }) {
  const ImageContext = useContext(ImageUploadContext)

  const [uploadedFiles, setUploadedFiles] = useState()
  const [fileUrl, setFileUrl] = useState()

  function handleChange(event) {
    if (!!event.target?.files && !!event.target.files[0]) {
      setUploadedFiles({ [ImageContext.imageType]: event.target.files[0] })
      setFileUrl(URL.createObjectURL(event.target.files[0]))
    }
  }

  return (
    <>
      <DialogTitle>
        <ImageUploadChooseHeader
          title={entityTitle[ImageContext.imageEntityType]?.uploadTitle || 'Upload'}
        />
      </DialogTitle>
      <DialogContent>
        <CFormInput
          type="file"
          {...entityFormComponents(ImageContext.imageEntityType)?.fileInput}
          size="lg"
          // onChange={handleClick}
          onChange={handleChange}
        />
        {fileUrl && <img key={fileUrl} src={fileUrl} alt="uploaded" />}
        {/* <PictureUploadComponent
          value={fileUrl}
          setFiles={setUploadedFiles}
          aspectHeight={ImageContext.imageTypeObject?.resolution?.split('x')?.[1] || 1}
          aspectWidth={ImageContext.imageTypeObject?.resolution?.split('x')?.[0] || 1}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            ImageContext.setUploadedFile(uploadedFiles)
            setPage(IMAGE_GALLERY_PAGES.imageForm)
          }}
          disabled={!uploadedFiles}
          size="large"
          variant="contained"
          color="success"
        >
          Next
        </Button>
      </DialogActions>
    </>
  )
}

export function PictureUploadComponent({
  value,
  setFormState,
  onChange,
  setFiles,
  aspectHeight,
  aspectWidth,
  title,
  id = 'imageId',
  disabled,
  ...rest
}) {
  const cssAspectRatio = useMemo(() => {
    return aspectWidth && aspectHeight && aspectWidth / aspectHeight
  }, [aspectHeight, aspectWidth])
  const minDimensions = calculateMinWidthAndHeight(cssAspectRatio)

  async function processImage(
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options,
  ) {
    // fieldName is the name of the input field
    // file is the actual file object to send
    setFiles(file)
  }

  useEffect(() => {
    if (!!value) {
      setFiles([
        {
          source: value,
          options: {
            type: 'local',
          },
        },
      ])
    } else {
    }
  }, [value])

  // async function handleImageChange(event) {
  //   setSaving(true)
  //   const file = event.target.files[0]
  //   if (file) {
  //     const formData = new FormData()
  //     formData.append('file', file)
  //     const response = await postDataWithoutParams('/api/media/file/upload', formData)
  //     if (response?.status === 200) {
  //       await sleep(1000)
  //       onChange({
  //         ...event,
  //         target: { value: response.data.fileId, id: event.target.id },
  //       })
  //     } else {
  //       toast.error('Something went wrong')
  //       return 0
  //     }
  //   }
  //   event.target.value = ''
  //   setSaving(false)
  // }

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ gap: '18px' }}
    >
      <FilePond
        allowImageEdit
        allowImageValidateSize
        imageValidateSizeMinWidth={minDimensions?.minWidth}
        imageValidateSizeMinHeight={minDimensions?.minHeight}
        imagePreviewHeight={aspectHeight}
        stylePanelLayout="compact"
        // imageEditInstantEdit
        instantUpload={true}
        // files={files}
        // onupdatefiles={setFiles}
        allowMultiple={false}
        server={{
          url: apiURL,
          // process: {
          //   url: '/media/file/upload',
          //   method: 'POST',
          //   // withCredentials: false,
          //   //   headers: {},
          //   //   timeout: 7000,
          //   onload: handleOnLoad,
          //   //   onerror: null,
          //   ondata: handleOnData,
          // },
          process: processImage,
          // load: (source, load, error, progress, abort, headers) => {
          //   var myRequest = new Request(`${mediaURL}${source}`, {
          //     headers: headers,
          //     method: 'GET',
          //     mode: 'cors',
          //   })
          //   if (myRequest) {
          //     fetch(`${mediaURL}${source}`).then(function (response) {
          //       console.log(response)
          //       response.blob().then(function (myBlob) {
          //         load(myBlob)
          //       })
          //     })
          //   }
          // },
        }}
        acceptedFileTypes={['image/*']}
        name="files"
        imageEditInstantEdit
        allowImageTransform
        styleItemPanelAspectRatio={cssAspectRatio ? 1 / cssAspectRatio : null}
        styleButtonProcessItemPosition="bottom"
        stylePanelAspectRatio={cssAspectRatio ? 1 / cssAspectRatio : null}
        labelIdle={'Drop Your Photo here or <span class="filepond--label-action">Browse</span>'}
        imageEditEditor={create({
          cropAspectRatio: [1 / cssAspectRatio],
        })}
      />
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>{title}</div>
      {/* <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleImageChange}
        accept="image/*"
        {...rest}
      /> */}
    </div>
  )
}

// function handleOnData(formData) {
//   const files = formData.getAll('files')?.[1]
//   const toReturnFormData = new FormData()
//   toReturnFormData.append('file', files)
//   return toReturnFormData
// }

// async function handleOnLoad(resData) {
//   await sleep(1000)
//   const responseData = JSON.parse(resData)
//   onChange({
//     target: { value: responseData.fileId, id: id },
//   })
// }
