import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { NOTIFICATION_ACTION_TYPES } from 'src/utilities/constants'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { loadBookedEvents } from '../../utils'

export default function DLSlugItemBookedEventNotifications({
  categoryType,
  item,
  handleChangeItem,
  uiContextData,
  actionData,
  handleActionData,
}) {
  return (
    <>
      <div>
        <label>{uiContextData?.linkIdSelectionLabel || 'Item'}</label>
        <ActivityItemAsyncSelect
          cacheUniqs={[categoryType]}
          classNamePrefix="activity-item-select"
          menuPortalTarget={document.body}
          value={item?.value ? item : null}
          loadOptions={loadBookedEvents}
          debounceTimeout={1000}
          additional={{
            page: 1,
            //add user id or something, currently the booked events for the logged in user are being fetched.
          }}
          onChange={(selVal) => handleChangeItem(selVal)}
        />
      </div>
      {item?.value && (
        <>
          <div>
            <CustomReactSelect
              label="Action"
              menuPortalTarget={document.body}
              value={actionData}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              options={NOTIFICATION_ACTION_TYPES}
              selectType={selectTypes.string}
              onChangeEvent={(e) => handleActionData(e.target.value)}
            />
          </div>
        </>
      )}
    </>
  )
}
