import { DRAFTS_API_MAP } from '../../drafts/apis'

const BASE_URL = 'oc'
const MENTOR_BASE_URL = `${BASE_URL}/mentor`
const CONTENT_EDITOR_BASE_URL = `${BASE_URL}/editor`
const CONTENT_PUBLISHER_BASE_URL = `${BASE_URL}/publisher`
const CONTENT_APPROVER_BASE_URL = `${BASE_URL}/approver`

export const ONLINE_COURSE_API_MAP = {
  save: DRAFTS_API_MAP.save,
  autoSave: `${CONTENT_EDITOR_BASE_URL}/save/auto`,
  latestDraft: `${CONTENT_EDITOR_BASE_URL}/drafts/latest`,
  submitForApproval: DRAFTS_API_MAP.submitForApproval,
  discardApprovalRequest: `${CONTENT_EDITOR_BASE_URL}/discard-approval-request`,
  getCourseDraft: DRAFTS_API_MAP.getById,
  saveCourseDraft: DRAFTS_API_MAP.save,
  // rawDrafts: `${CONTENT_EDITOR_BASE_URL}/raw-drafts`,
  getActiveDraft: DRAFTS_API_MAP.getActiveContentDraft,
  // createDraftFromUnPublish: `${BASE_URL}/draft/temp/new`,

  reject: `${CONTENT_APPROVER_BASE_URL}/reject-approval-request`,
  approve: `${CONTENT_APPROVER_BASE_URL}/approve-course`,

  publish: `${CONTENT_PUBLISHER_BASE_URL}/publish-content`,
  unPublish: `${CONTENT_PUBLISHER_BASE_URL}/unpublish-content`,
  reqToUnpublish: `${CONTENT_EDITOR_BASE_URL}/req-to-unpublish`,
  approveReqToUnpublish: `${CONTENT_PUBLISHER_BASE_URL}/approve-unpub-request-and-unpublish`,
  rejectReqToUnpublish: `${CONTENT_PUBLISHER_BASE_URL}/reject-unpub-request-and-unpublish`,

  teacherTracks: `${BASE_URL}/teacher/tracks`,
  courseGroups: `${BASE_URL}/course-groups`,
  coursesFromCourseGroups: `${BASE_URL}/course-groups/courses`,
  checkSlugValidity: `${BASE_URL}/slug/check-availability`,
  deleteCourseDraft: `${BASE_URL}/draft/delete`,
}

export const ONLINE_COURSE_MENTOR_API_MAP = {
  getCourseDraft: `${MENTOR_BASE_URL}/get-draft`,
  saveCourseDraft: `${MENTOR_BASE_URL}/save-draft`,
  reqToUnpublish: `${MENTOR_BASE_URL}/req-to-unpublish`,
}
