import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { META_SLIDES_OBJECT } from 'src/views/settings/utils/bg-slides-utils'
import BGSChoose from './backgroundSlides/BGSChoose'
import BGSGalleryChoose from './backgroundSlides/BGSGalleryChoose'
import BGSPhoto from './backgroundSlides/BGSPhoto'
import BGSVideo from './backgroundSlides/BGSVideo'
import { TOAST_UTILS } from './toast-utils'

export default function BackgroundSlidesUpload({
  id,
  object,
  onChange,
  saveButtonRef,
  galleryButtonRef,
  setShowSaveButton,
  setShowRepositoryButton,
  dialogDetails,
  onChangeType,
}) {
  const [backgroundSlide, setBackgroundSlide] = useState(META_SLIDES_OBJECT)
  const [pageStack, setPageStack] = useState([])
  const [pageType, setPageType] = useState()
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  async function Setup() {
    const response = await GetSettingsData(GENERIC_APIS.entity.details, {
      entityType: 'slide',
      id: id,
    })
    if (response) {
      setBackgroundSlide(response.data)
    }
  }
  function handleChange(event) {
    setBackgroundSlide((prev) => ({ ...prev, [event.target.id]: event.target.value }))
  }
  async function handleSave(action) {
    const tst = toast.loading('Saving Background Slide...')
    const response = await PostSettingsData(
      `${GENERIC_APIS.saveEntity.generic}slide`,
      backgroundSlide,
      {
        entityType: 'slide',
      },
    )
    if (response) {
      toast.update(tst, {
        render: 'Saved Successfully!',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      onChange(response.data, action)
      if (action !== 'proceed') {
        setBackgroundSlide(response.data)
        setPageStack([BACKGROUND_SLIDES_PAGES.choose])
      }
      handleCloseSaveDialog()
      return
    }
    toast.dismiss(tst)
  }

  function setCurrentPage(page) {
    setPageStack((prev) => [...prev, page])
  }

  function handleGoToGallery() {
    setCurrentPage(BACKGROUND_SLIDES_PAGES.repository)
  }

  function renderBgSlides(page) {
    switch (page) {
      case BACKGROUND_SLIDES_PAGES.repository:
        return (
          <BGSGalleryChoose
            onChange={onChange}
            onChangeType={onChangeType}
            dialogDetails={dialogDetails}
          />
        )
      case BACKGROUND_SLIDES_PAGES.choose:
        return (
          <BGSChoose
            bgSlide={backgroundSlide}
            handleChange={handleChange}
            setBgSlide={setBackgroundSlide}
            setCurrentPage={setCurrentPage}
            setPageType={setPageType}
          />
        )
      case BACKGROUND_SLIDES_PAGES.photo:
        return (
          <BGSPhoto
            bgSlideObject={backgroundSlide}
            setBgSlideObject={setBackgroundSlide}
            onChange={handleChange}
            type={pageType}
          />
        )
      case BACKGROUND_SLIDES_PAGES.video:
        return (
          <BGSVideo
            bgSlide={backgroundSlide}
            handleChange={handleChange}
            setBgSlide={setBackgroundSlide}
            type={pageType}
          />
        )
      default:
        return <div>Invalid</div>
    }
  }

  function handleClickSave() {
    setShowSaveDialog(true)
  }
  function handleCloseSaveDialog() {
    setShowSaveDialog(false)
  }

  useEffect(() => {
    setCurrentPage(BACKGROUND_SLIDES_PAGES.choose)
    if (!!id || !!object) {
      !!id && Promise.all([Setup()])
      !!object && setBackgroundSlide(object)
    } else {
      setBackgroundSlide(META_SLIDES_OBJECT)
    }
  }, [id, object])

  useEffect(() => {
    if (
      pageStack.lastItem !== BACKGROUND_SLIDES_PAGES.choose &&
      pageStack.lastItem !== BACKGROUND_SLIDES_PAGES.repository
    ) {
      setShowSaveButton(true)
      setShowRepositoryButton(false)
    } else {
      setShowSaveButton(false)
      setShowRepositoryButton(true)
    }
    if (pageStack.lastItem === BACKGROUND_SLIDES_PAGES.repository) {
      setShowRepositoryButton(false)
    }
  }, [pageStack.lastItem])

  return (
    <>
      {renderBgSlides(pageStack.lastItem)}
      <button style={{ display: 'none' }} ref={saveButtonRef} onClick={handleClickSave} />
      <button style={{ display: 'none' }} ref={galleryButtonRef} onClick={handleGoToGallery} />
      <Dialog open={showSaveDialog} onClose={handleCloseSaveDialog}>
        <DialogTitle>Save your data and go back?</DialogTitle>
        <DialogContent>
          Do you want to save the content and proceed or go back to adding more slides?
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseSaveDialog}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={() => handleSave('proceed')}>
            Save and Proceed
          </Button>
          <Button variant="contained" color="success" onClick={() => handleSave('back')}>
            Save and add more
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const BACKGROUND_SLIDES_IMAGE_TYPES = {
  portraitImage: 'portraitSlides',
  portraitVideo: 'portraitVideoid',
  landScapeImage: 'landscapeSlides',
  landScapeVideo: 'landscapeVideoId',
}

export const BACKGROUND_SLIDES_PAGES = {
  repository: 'repository',
  choose: 'choose',
  photo: 'photo',
  video: 'video',
}
export const BGS_FIELD_TO_TITLE = {
  portraitSlides: 'Portrait Images',
  portraitVideoid: 'Portrait Video',
  landscapeSlides: 'Landscape Images',
  landscapeVideoId: 'Landscape Video',
}

BackgroundSlidesUpload.propTypes = {
  id: PropTypes.string,
  object: PropTypes.object,
  onChange: PropTypes.func,
  onChangeType: PropTypes.string,
  saveButtonRef: PropTypes.any,
  galleryButtonRef: PropTypes.any,
  timeFrameButtonRef: PropTypes.any,
  setShowRepositoryButton: PropTypes.func,
  setShowSaveButton: PropTypes.func,
  setShowTimeFrameButton: PropTypes.func,
  dialogDetails: PropTypes.object,
}
