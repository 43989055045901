import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { TabWrapper } from 'src/components/controlCenter/common/sectionComponents/TabWrapper'
import { StyledTabButton, StyledTabContainer } from 'src/components/controlCenter/styledComponents'
import UsersBasicDetailsTab from './BasicDetailsTab/UsersBasicDetailsTab'
import DevicesAndExperienceTabs from './DevicesAndExperience/DevicesAndExperienceTabs'
import GiveUserSubscriptionPlanForm from './GiveSubscriptionPlanForm/GiveUserSubscriptionPlanForm'
import UsersOtherDetails from './OtherDetails/UsersOtherDetails'
import UserPaymentLinks from './PaymentLinks/UserPaymentLinks'
import { GeneratePaymentLinkDialogPaper } from './PaymentLinks/styledComponents'
import SubscriptionTabsPage from './SubscriptionTabs'
import UsageDetailsTabs from './UsageDetailsTabs/UsageDetailsTabs'
import './styles/user_page_recaptcha.css'

const outerSections = {
  basicDetails: { id: 'basic', title: 'Basic Details' },
  subscriptions: {
    id: 'subscriptions',
    title: 'Subscriptions',
  },
  otherDetails: {
    id: 'otherDetails',
    title: 'Other Details',
  },
  usageDetails: {
    id: 'usageDetails',
    title: 'Usage Details',
  },
  devicesAndExperience: {
    id: 'devicesAndExperience',
    title: 'Devices And Experience',
  },
  paymentLinks: {
    id: 'paymentLinks',
    title: 'Payment Links',
  },
}

export default function UsersFormComponent({
  initialObject,
  imageObject: userObject,
  setImageObject: setUserObject,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  extraActionButtonsRefObject,
}) {
  const [selectedTab, setSelectedTab] = React.useState(outerSections.basicDetails.id)
  const [onboardingName, setOnboardingName] = useState()
  const [showGiveSubscriptionPlanModal, setShowGiveSubscriptionPlanModal] = useState(false)

  function handleChangeTab(tabId) {
    setSelectedTab(tabId)
  }

  function handleGiveSubscriptionPlanClick() {
    setShowGiveSubscriptionPlanModal(true)
  }

  function closeGiveSubscriptionPlanModal() {
    setShowGiveSubscriptionPlanModal(false)
  }

  useEffect(() => {
    console.log(extraActionButtonsRefObject)
  }, [extraActionButtonsRefObject])

  return (
    <div className="w-100">
      <GoogleReCaptchaProvider reCaptchaKey="6LcIaO0pAAAAAE72u1l5wzfPtYXB4TpDBQZLpqZY">
        <button
          type="button"
          style={{ display: 'none' }}
          ref={(ele) => (extraActionButtonsRefObject.current.giveSubscriptionPlan = ele)}
          onClick={handleGiveSubscriptionPlanClick}
        ></button>
        <StyledTabContainer
          sx={{
            position: 'sticky',
            top: 'calc(var(--header-height))',
            zIndex: '999',
            background: (theme) =>
              `${
                theme.palette.mode === 'dark'
                  ? 'var(--cui-body-bg)'
                  : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
              }`,
            borderBottom: '1px solid var(--cui-border-color)',
            padding: '15px 0px',
          }}
        >
          {Object.values(outerSections).map((section) => (
            <StyledTabButton
              key={section.id}
              selected={selectedTab === section.id}
              onClick={() => handleChangeTab(section.id)}
            >
              {section.title}
            </StyledTabButton>
          ))}
        </StyledTabContainer>
        <TabWrapper
          currentValue={selectedTab}
          tabValue={outerSections.basicDetails.id}
          tabBoxProps={{
            sx: {
              p: 0,
              py: 2,
            },
          }}
        >
          <UsersBasicDetailsTab
            onboardingName={onboardingName}
            setOnboardingName={setOnboardingName}
            userObject={userObject}
            customOptions={customOptions}
            disabled
          />
        </TabWrapper>
        <TabWrapper currentValue={selectedTab} tabValue={outerSections.subscriptions.id}>
          <SubscriptionTabsPage userId={userObject?.id} />
        </TabWrapper>
        <TabWrapper currentValue={selectedTab} tabValue={outerSections.usageDetails.id}>
          <UsageDetailsTabs userId={userObject?.id} />
        </TabWrapper>
        <TabWrapper currentValue={selectedTab} tabValue={outerSections.devicesAndExperience.id}>
          <DevicesAndExperienceTabs userId={userObject?.id} />
        </TabWrapper>
        <TabWrapper currentValue={selectedTab} tabValue={outerSections.otherDetails.id}>
          <UsersOtherDetails userData={userObject} dropDownData={customOptions} />
        </TabWrapper>
        <TabWrapper currentValue={selectedTab} tabValue={outerSections.paymentLinks.id}>
          <UserPaymentLinks userId={userObject?.id} dropDownData={customOptions} />
        </TabWrapper>
        <Dialog
          open={showGiveSubscriptionPlanModal}
          onClose={closeGiveSubscriptionPlanModal}
          PaperComponent={GeneratePaymentLinkDialogPaper}
        >
          <GiveUserSubscriptionPlanForm
            userDetails={userObject}
            closeForm={closeGiveSubscriptionPlanModal}
            dropDownData={customOptions}
            setUserData={setUserObject}
          />
        </Dialog>
      </GoogleReCaptchaProvider>
    </div>
  )
}
