import { Box, Paper, styled as muiStyled } from '@mui/material'

export const PackageItemContainer = muiStyled(Box)(
  ({ theme }) => `
    width: 100%;
    background: ${theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 5%)' : '#dbdbdb'};
    padding: 14px;
    border-radius: 10px;
    box-shadow: ${theme.shadows[5]};
`,
)

export const PackagesContainer = muiStyled(Box)(
  ({ theme }) => `
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr;
    @media(min-width: ${theme.breakpoints.values.md}px) {
        grid-template-columns: 1fr 1fr;
}
    @media (min-width: ${theme.breakpoints.values.lg}px) {
    grid-template-columns: 1fr 1fr 1fr;
    }
    `,
)

export const PackageMainContainer = muiStyled(Box)(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        padding: 14px;
        border-radius: 10px;
        box-shadow: ${theme.shadows[3]};
    `,
)

export const AddPackageModalPaper = muiStyled(Paper)(
  ({ theme }) => `
    max-width: ${theme.breakpoints.values.sm}px !important;
    width: 100% !important;
    margin: 0 !important;
`,
)
