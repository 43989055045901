import { fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export async function transformSegmentsListingData({ listingData }) {
  // const idToUse = listingData?.map((segment) => segment?.id)
  const onBoardingToUse = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.onboardingPolicy,
    ids: listingData?.map((segment) => segment?.onboardPolicy)?.join(','),
  })
  const tabAndTilePolicy = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.metaTabSetting,
    ids: listingData?.map((segment) => segment?.tabAndTilePolicy)?.join(','),
  })
  return listingData?.map((segment) => {
    const foundOnboarding = onBoardingToUse?.find(
      (onboarding) => onboarding?.id === segment?.onboardPolicy,
    )
    const foundTabAndTile = tabAndTilePolicy?.find(
      (tabAndTile) => tabAndTile?.id === segment?.tabAndTilePolicy,
    )
    return {
      ...segment,
      onboardPolicy: foundOnboarding?.name || segment?.onboardPolicy,
      tabAndTilePolicy: foundTabAndTile?.name || segment?.tabAndTilePolicy,
    }
  })
}

// GetSettingsData().then((res) => {
