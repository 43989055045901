import { CPopover } from '@coreui/react-pro'
import { styled as muiStyled } from '@mui/material'
import { CustomSelect } from 'src/views/plugins/calendar/components/CustomSelect'
import styled from 'styled-components'

export const PlanFiltersPopover = styled(CPopover)`
  max-width: 500px !important;
  width: 100%;
`

export const BusinessPortalSearch = muiStyled('input')(
  ({ theme }) => `
  outline: none;
  border: 2px solid ${theme.palette.mode === 'dark' ? '#ebebeb' : theme.palette.primary.main};
  border-radius: 10px;
  background: transparent;
  width: 100%;
  color: ${theme.palette.mode === 'dark' ? '#ebebeb' : theme.palette.primary.main};
  ::placeholder {
    color: ${theme.palette.mode === 'dark' ? '#ebebeb' : theme.palette.primary.main};
  }
`,
)

export const StyledReactSelect = muiStyled(CustomSelect)(
  ({ theme }) => `
> .react-select__control > .react-select__value-container > .react-select__single-value {
  text-overflow: unset;
  white-space: unset;
}
`,
)

export const SubscriptionPlanPaymentGatewaysContainer = muiStyled('div')(
  ({ theme }) => `
border: 3px solid ${theme.palette.success.main};
padding: 25px;
padding-bottom: 1rem;
border-radius: 1.5rem;
`,
)
