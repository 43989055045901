import { CFormInput } from '@coreui/react-pro'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { CRITERIA_ITEM_OBJECT } from 'src/common/types/segments/criteriaItem'
import {
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import NormalSectionWrapper from 'src/components/controlCenter/common/sectionComponents/NormalSectionWrapper'
import { transformInitialMetaTabSettingData } from 'src/config/metaTabSetting/transformInitialData'
import { fetchFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import {
  ALL_ENTITY_TYPES_FROM_CONSTANTS,
  ALL_GENDERS_IN_APP_MSGS,
  CRITERIA_OPERATORS,
} from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import TabReOrderComponents from '../tabSettings/components/TabReOrderComponents'

export default function SegmentsFormOld({
  initialObject,
  imageObject: segment,
  setImageObject: setSegment,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  formMode,
}) {
  const [currentTabs, setCurrentTabs] = useState()
  const [loadingCurrentTabs, setLoadingCurrentTabs] = useState(true)
  const [store, dispatch] = useAppSettingsStore()
  const allTabs = store.metaTabs

  function handleChangeSegmentCriteria(e) {
    handleNestedChangeObject(e, 'criteria')
  }

  async function getCurrentTabs() {
    setLoadingCurrentTabs(true)
    const resp = await fetchFunction(GENERIC_APIS.entity.details, {
      id: segment?.tabAndTilePolicy,
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.metaTabSetting,
    })
    if (!!resp) {
      const finalData = await transformInitialMetaTabSettingData(resp)
      setCurrentTabs(finalData)
    }
    setLoadingCurrentTabs(false)
  }

  function handleAddACriteriaItem(criteriaName) {
    setSegment((prevSegment) => ({
      ...prevSegment,
      segmentCriterias: {
        ...prevSegment.segmentCriterias,
        [criteriaName]: {
          ...(prevSegment?.segmentCriterias?.[criteriaName] || {
            enabled: true,
          }),
          items: [...prevSegment?.segmentCriterias?.[criteriaName]?.items, CRITERIA_ITEM_OBJECT],
        },
      },
    }))
  }

  function handleDeleteACriteriaItem(criteriaName, index) {
    setSegment((prevSegment) => ({
      ...prevSegment,
      segmentCriterias: {
        ...prevSegment.segmentCriterias,
        [criteriaName]: {
          ...prevSegment.segmentCriterias?.[criteriaName],
          items: prevSegment.segmentCriterias?.[criteriaName]?.items.filter(
            (items, idx) => idx !== index,
          ),
        },
      },
    }))
  }
  function handlePlatformAddACriteriaItem(osType) {
    setSegment((segment) => ({
      ...segment,
      segmentCriterias: {
        ...segment.segmentCriterias,
        platform: {
          ...segment?.segmentCriterias?.platform,
          subCriteriaMap: {
            ...(segment?.segmentCriterias?.platform?.subCriteriaMap || {}),
            appVersion: [
              ...segment.segmentCriterias?.platform?.subCriteriaMap?.appVersion.map(
                (appVersionItem) => {
                  return appVersionItem.ref === osType
                    ? {
                        ...appVersionItem,
                        type: true,
                        items: [...appVersionItem.items, CRITERIA_ITEM_OBJECT],
                      }
                    : appVersionItem
                },
              ),
            ],
          },
        },
      },
    }))
  }

  function handlePlatformDeleteACriteriaItem(criteriaName, index) {
    setSegment((segment) => ({
      ...segment,
      segmentCriterias: {
        ...segment.segmentCriterias,
        [criteriaName]: {
          ...segment.segmentCriterias?.[criteriaName],
          items: segment.segmentCriterias?.[criteriaName]?.items.filter(
            (items, idx) => idx !== index,
          ),
        },
      },
    }))
  }

  useEffect(() => {
    console.log({ customOptions })
  }, [customOptions])

  useEffect(() => {
    getCurrentTabs()
  }, [segment?.tabAndTilePolicy])

  return (
    <div className="w-100">
      <NormalSectionWrapper heading="Basic Details">
        <GridSectionWrapper shadow={false}>
          <Grid item xs={12}>
            <CFormInput
              id="name"
              label="Title"
              value={segment?.name}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <CCTextArea
              id="description"
              label="Description"
              value={segment?.description}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          </Grid>
        </GridSectionWrapper>
      </NormalSectionWrapper>
      <NormalSectionWrapper heading="Behavior">
        <GridSectionWrapper shadow={false}>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="tabAndTilePolicy"
              label="Tabs"
              value={segment?.tabAndTilePolicy}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              options={customOptions?.tabAndTilePolicies}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="onboardPolicy"
              label="Onboarding"
              value={segment?.onboardPolicy}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name || option?.id}
              options={customOptions?.onboardingPolicies || []}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="focusAreaPolicy"
              label="Focus Area Policy"
              value={segment?.focusAreaPolicy}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              options={customOptions?.onboardingPolicies || []}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="acquisitionPolicy"
              label="Acquisition Policy"
              value={segment?.acquisitionPolicy}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              options={customOptions?.acquisitionPolicies || []}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="categoryPolicy"
              label="Category Policy"
              value={segment?.categoryPolicy}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              options={customOptions?.categoryPolicies || []}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="prewallPolicy"
              label="Prewall Policy"
              value={segment?.prewallPolicy}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              options={customOptions?.prewallPolicies || []}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="emailPolicy"
              label="Email Policy"
              value={segment?.emailPolicy}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              options={customOptions?.emailPolicies || []}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="trialPackage"
              label="Trial Package"
              value={segment?.trialPackage}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              options={customOptions?.trialPlans || []}
              onChangeEvent={handleChangeObject}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  id="showPrewallOnHome"
                  checked={!!segment?.showPrewallOnHome}
                  onChange={handleChangeObject}
                />
              }
              label="Show Prewall On Home: "
              disabled={disabled || segment?.id === 'default'}
            />
          </Grid>
        </GridSectionWrapper>
      </NormalSectionWrapper>

      {/* Criteria */}
      <NormalSectionWrapper
        heading="Criteria"
        subHeading="Not selected means applicable to all the values of that criteria"
      >
        <GridSectionWrapper shadow={false}>
          {/* Country */}

          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            handleChange={handleChangeSegmentCriteria}
            enabledString="countryCriteria"
            criteriaFieldString={'countries'}
            label="Country"
            options={customOptions?.countries}
          />

          {/* App Languages */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            handleChange={handleChangeSegmentCriteria}
            enabledString="languageCriteria"
            label="App Languages"
            criteriaFieldString={'languages'}
            options={customOptions?.languages?.map((lang) => ({
              id: lang?.id,
              title: lang?.languageName,
            }))}
          />

          {/* Device Language */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            handleChange={handleChangeSegmentCriteria}
            enabledString="deviceLanguageCriteria"
            label="Device Languages"
            criteriaFieldString={'deviceLanguages'}
            options={customOptions?.languages?.map((lang) => ({
              id: lang?.id,
              title: lang?.languageName,
            }))}
          />

          {/* Content Language */}

          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            label="Content Languages"
            handleChange={handleChangeSegmentCriteria}
            enabledString="contentLanguageCriteria"
            criteriaFieldString={'contentLanguages'}
            options={customOptions?.languages?.map((lang) => ({
              id: lang?.id,
              title: lang?.languageName,
            }))}
          />
          {/* Gender */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            label="Gender"
            handleChange={handleChangeSegmentCriteria}
            enabledString="genderCriteria"
            criteriaFieldString={'genders'}
            options={ALL_GENDERS_IN_APP_MSGS}
          />

          {/* Age Group */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            label="Age Groups"
            handleChange={handleChangeSegmentCriteria}
            enabledString="ageGrpCriteria"
            criteriaFieldString={'ageGrps'}
            options={customOptions?.ageGroups?.map((grp) => ({ id: grp?.id, title: grp?.name }))}
          />

          {/* Subscription */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            label="Subscription"
            handleChange={handleChangeSegmentCriteria}
            enabledString="subscriptionCriteria"
            criteriaFieldString={'subscription'}
            options={customOptions?.allPlanLevels?.map((plan) => ({
              id: plan?.id,
              title: plan?.name,
            }))}
            isSingleOption
          />

          {/* Onboarding */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            label="Onboarding Criteria"
            handleChange={handleChangeSegmentCriteria}
            enabledString="onboardingCriteria"
            criteriaFieldString={'onboardingTypes'}
            options={customOptions?.onboarding}
          />
          {/* Include Authorities */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            label="Include Authorities"
            handleChange={handleChangeSegmentCriteria}
            enabledString="includeAuthoritiesCriteria"
            criteriaFieldString={'includeAuthorities'}
            options={customOptions?.allRoles}
            showError={
              _.intersection(
                segment?.criteria?.excludeAuthorities,
                segment?.criteria?.includeAuthorities,
              ).length > 0
            }
            errorString="Include Authorities and Exclude Authorities cannot have same values"
          />
          {/* Exclude Authorities */}
          <CriteriaHandler
            segmentData={segment}
            disabled={disabled}
            label="Exclude Authorities"
            handleChange={handleChangeSegmentCriteria}
            enabledString="excludeAuthoritiesCriteria"
            criteriaFieldString={'excludeAuthorities'}
            options={customOptions?.allRoles}
            showError={
              _.intersection(
                segment?.criteria?.excludeAuthorities,
                segment?.criteria?.includeAuthorities,
              ).length > 0
            }
            errorString="Include Authorities and Exclude Authorities cannot have same values"
          />

          {/* Platform */}
          <GridSectionWrapper heading="Target" shadow={false}>
            <Grid item xs={12} md={12}>
              <CriteriaBox>
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      id="androidAppVersionCriteria"
                      checked={!!segment?.criteria?.androidAppVersionCriteria}
                      onChange={handleChangeSegmentCriteria}
                    />
                  }
                  label={`Android: `}
                  disabled={disabled}
                />
                {segment?.criteria?.androidAppVersionCriteria && (
                  <div className="d-flex align-items-end gap-1">
                    <CustomReactSelect
                      id="androidAppVersionOperator"
                      value={segment?.criteria?.androidAppVersionOperator}
                      getOptionValue={(option) => option?.id}
                      getOptionLabel={(option) => option?.title}
                      options={CRITERIA_OPERATORS}
                      disabled={disabled}
                      selectType={selectTypes.string}
                      onChangeEvent={(e) => handleChangeSegmentCriteria(e)}
                      label="Version: "
                    />
                    <div className="d-flex align-items-center w-100">
                      <div style={{ width: '100%' }}>
                        <CFormInput
                          id="androidAppVersion"
                          onChange={handleChangeSegmentCriteria}
                          value={segment?.criteria?.androidAppVersion}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </CriteriaBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <CriteriaBox>
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      id="iosAppVersionCriteria"
                      checked={!!segment?.criteria?.iosAppVersionCriteria}
                      onChange={handleChangeSegmentCriteria}
                    />
                  }
                  label={`iOS: `}
                  disabled={disabled}
                />
                {segment?.criteria?.iosAppVersionCriteria && (
                  <div className="d-flex flex-column gap-1">
                    <div className="d-flex align-items-end gap-1">
                      <CustomReactSelect
                        id="iosAppVersionOperator"
                        value={segment?.criteria?.iosAppVersionOperator}
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.title}
                        options={CRITERIA_OPERATORS}
                        disabled={disabled}
                        selectType={selectTypes.string}
                        onChangeEvent={(e) => handleChangeSegmentCriteria(e)}
                        label="Version: "
                      />
                      <div className="d-flex align-items-center w-100">
                        <div style={{ width: '100%' }}>
                          <CFormInput
                            id="iosAppVersion"
                            onChange={handleChangeSegmentCriteria}
                            value={segment?.criteria?.iosAppVersion}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            id="iPhone"
                            checked={!!segment?.criteria?.iPhone}
                            onChange={handleChangeSegmentCriteria}
                            size="small"
                          />
                        }
                        label={`iPhone`}
                        disabled={disabled}
                      />
                    </div>
                    <div style={{ width: '100%' }}>
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            id="iPad"
                            checked={!!segment?.criteria?.iPad}
                            onChange={handleChangeSegmentCriteria}
                            size="small"
                          />
                        }
                        label={`iPad`}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                )}
              </CriteriaBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <CriteriaBox>
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      id="webAppVersionCriteria"
                      checked={!!segment?.criteria?.webAppVersionCriteria}
                      onChange={handleChangeSegmentCriteria}
                    />
                  }
                  label={`Web: `}
                  disabled={disabled}
                />
                {segment?.criteria?.webAppVersionCriteria && (
                  <div className="d-flex align-items-end gap-1">
                    <CustomReactSelect
                      id="webAppVersionOperator"
                      value={segment?.criteria?.webAppVersionOperator}
                      getOptionValue={(option) => option?.id}
                      getOptionLabel={(option) => option?.title}
                      options={CRITERIA_OPERATORS}
                      disabled={disabled}
                      selectType={selectTypes.string}
                      onChangeEvent={(e) => handleChangeSegmentCriteria(e)}
                      label="Version: "
                    />
                    <div className="d-flex align-items-center w-100">
                      <div style={{ width: '100%' }}>
                        <CFormInput
                          id="webAppVersion"
                          onChange={handleChangeSegmentCriteria}
                          value={segment?.criteria?.webAppVersion}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </CriteriaBox>
            </Grid>
          </GridSectionWrapper>
        </GridSectionWrapper>
      </NormalSectionWrapper>
      <NormalSectionWrapper heading="Tab Orders and tile policies">
        <TabReOrderComponents
          androidTilePolicies={currentTabs?.android?.tilePolicyMap}
          webTabTilePolicies={currentTabs?.web?.tilePolicyMap}
          iosTabTilePolicies={currentTabs?.ios?.tilePolicyMap}
          androidTabList={currentTabs?.android?.tabs}
          webTabList={currentTabs?.web?.tabs}
          iosTabList={currentTabs?.ios?.tabs}
          dropDownOptions={{ allTabs }}
          customSize="400px"
          showTilePolicy
          // handleChangeAndroid={handleChangeAndroid}
          // handleChangeWeb={handleChangeWeb}
          // handleChangeIos={handleChangeIos}
          disabled={true}
          iconView={true}
        />
      </NormalSectionWrapper>
      {/* <NormalSectionWrapper heading="Theme">
        <GridSectionWrapper shadow={false}></GridSectionWrapper>
      </NormalSectionWrapper> */}
    </div>
  )
}

function CriteriaHandler({
  segmentData,
  enabledString,
  label,
  criteriaFieldString,
  options,
  handleChange,
  disabled,
  isSingleOption = false,
  showError = false,
  errorString,
}) {
  return (
    <Grid item xs={12} md={12}>
      <CriteriaBox>
        <FormControlLabel
          labelPlacement="end"
          control={
            <Checkbox
              id={enabledString}
              checked={!!segmentData?.criteria?.[enabledString]}
              onChange={handleChange}
            />
          }
          label={`${label}: `}
          disabled={disabled}
        />
        {segmentData?.criteria?.[enabledString] && (
          <>
            <div className="d-flex align-items-center w-100">
              <div style={{ width: '100%' }}>
                <CustomReactSelect
                  isMulti={!isSingleOption}
                  id={criteriaFieldString}
                  value={segmentData?.criteria?.[criteriaFieldString]}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option?.title}
                  options={options}
                  //   options={options?.sort((a, b) => a?.title?.localeCompare(b?.title))}
                  onChangeEvent={handleChange}
                  disabled={disabled}
                  invalid={showError}
                  selectType={selectTypes.string}
                />
              </div>
            </div>
          </>
        )}
      </CriteriaBox>
    </Grid>
  )
}

function CriteriaBox({ children }) {
  return <div className="d-flex flex-column gap-2 align-items-start w-100">{children}</div>
}

const tabTileColumn = [
  {
    key: 'tab',
    label: '',
    filter: false,
    sorter: false,
    // _style: { width: '15%' },
  },
  {
    key: 'morningAndroid',
    label: 'Morning Android',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'eveningAndroid',
    label: 'Evening Android',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'morningIos',
    label: 'Morning iOS',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'eveningIos',
    label: 'Evening iOS',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'morningWeb',
    label: 'Morning Web',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'eveningWeb',
    label: 'Evening Web',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
]
const policiesColumn = [
  {
    key: 'tab',
    label: 'Tab/Tiles',
    filter: false,
    sorter: false,
    // _style: { width: '15%' },
  },
  {
    key: 'morningAndroid',
    label: 'Morning Android',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'eveningAndroid',
    label: 'Evening Android',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'morningIos',
    label: 'Morning iOS',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'eveningIos',
    label: 'Evening iOS',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'morningWeb',
    label: 'Morning Web',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'eveningWeb',
    label: 'Evening Web',
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
]
const tabTileColumnTableData = [
  {
    tab: 'Default',
    morningAndroid: 'Home',
    eveningAndroid: 'Home',
    morningIos: 'Home',
    eveningIos: 'Home',
    morningWeb: 'Home',
    eveningWeb: 'Home',
  },
]

{
  /* <CriteriaBox>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Checkbox
                    id="enabledPlatform"
                    checked={!!segmentCriterias?.platform?.enabled}
                    onChange={(e) => {
                      handleChangeSegmentCriteriaEnabled(e, 'platform', 'enabled')
                    }}
                  />
                }
                label="Platform: "
                disabled={disabled}
              />
              {segmentCriterias?.platform?.enabled && (
                <>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={
                          !!segmentCriterias?.platform?.subCriteriaMap?.appVersion?.find(
                            (appVer) => appVer?.ref === 'android',
                          )?.enabled
                        }
                        onChange={(e) => {
                          platformHandleChangeSegmentCriteriaEnabled(e, 'android')
                        }}
                      />
                    }
                    label="Android"
                    disabled={disabled}
                  />

                  {!!segmentCriterias?.platform?.subCriteriaMap?.appVersion?.find(
                    (appVer) => appVer?.ref === 'android',
                  )?.enabled && (
                    <>
                      {segmentCriterias?.platform?.subCriteriaMap?.appVersion
                        ?.filter((appVer) => appVer?.ref === 'android')
                        ?.map((appVer, idx) => (
                          <AppPlatformComponent
                            key={idx}
                            disabled={disabled}
                            segmentAppVersion={appVer}
                            handleChangeSegmentCriteriaAppVersionItemsItems={(
                              e,
                              fieldName,
                              osType,
                            ) =>
                              handleChangeSegmentCriteriaAppVersionItemsItems(
                                e,
                                osType,
                                fieldName,
                                idx,
                              )
                            }
                            handleChangeSegmentCriteriaItems={handleChangeSegmentCriteriaItems}
                            osType={'android'}
                            idx={idx}
                          />
                        ))}
                      <IconButton
                        onClick={() => {
                          handlePlatformAddACriteriaItem('android')
                        }}
                      >
                        <Add />
                      </IconButton>
                    </>
                  )}

                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={
                          !!segmentCriterias?.platform?.subCriteriaMap?.appVersion?.find(
                            (appVer) => appVer?.ref === 'iOS',
                          )?.enabled
                        }
                        onChange={(e) => {
                          platformHandleChangeSegmentCriteriaEnabled(e, 'iOS')
                        }}
                      />
                    }
                    label="iOS"
                    disabled={disabled}
                  />

                  {!!segmentCriterias?.platform?.subCriteriaMap?.appVersion?.find(
                    (appVer) => appVer?.ref === 'iOS',
                  )?.enabled && (
                    <>
                      {segmentCriterias?.platform?.subCriteriaMap?.appVersion
                        ?.filter((appVer) => appVer?.ref === 'iOS')?.[0]
                        ?.items?.map((appVerItem, idx) => (
                          <AppPlatformComponent
                            key={idx}
                            disabled={disabled}
                            segmentAppVersionItem={appVerItem}
                            handleChangeSegmentCriteriaItems={handleChangeSegmentCriteriaItems}
                            osType="iOS"
                            idx={idx}
                            handleChangeSegmentCriteriaAppVersionItemsItems={(
                              e,
                              fieldName,
                              osType,
                            ) =>
                              handleChangeSegmentCriteriaAppVersionItemsItems(
                                e,
                                osType,
                                fieldName,
                                idx,
                              )
                            }
                            handleChangeSegmentCriteriaAppVersionItemsSubCriteriaItems={(
                              e,
                              osType,
                              subCriteriaItemIdx,
                            ) => {
                              handleChangeSegmentCriteriaAppVersionItemsSubCriteriaItems(
                                e,
                                osType,
                                idx,
                                subCriteriaItemIdx,
                              )
                            }}
                          />
                        ))}
                      <IconButton
                        onClick={() => {
                          handlePlatformAddACriteriaItem('iOS')
                        }}
                      >
                        <Add />
                      </IconButton>
                    </>
                  )}

                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={
                          !!segmentCriterias?.platform?.subCriteriaMap?.appVersion?.find(
                            (appVer) => appVer?.ref === 'web',
                          )?.enabled
                        }
                        onChange={(e) => {
                          platformHandleChangeSegmentCriteriaEnabled(e, 'web')
                        }}
                      />
                    }
                    label="Web"
                    disabled={disabled}
                  />

                  {!!segmentCriterias?.platform?.subCriteriaMap?.appVersion?.find(
                    (appVer) => appVer?.ref === 'web',
                  )?.enabled && (
                    <>
                      {segmentCriterias?.platform?.subCriteriaMap?.appVersion
                        ?.filter((appVer) => appVer?.ref === 'web')
                        ?.map((appVer, idx) => (
                          <AppPlatformComponent
                            key={idx}
                            disabled={disabled}
                            segmentAppVersion={appVer}
                            handleChangeSegmentCriteriaItems={handleChangeSegmentCriteriaItems}
                            osType={'web'}
                            idx={idx}
                            handleChangeSegmentCriteriaAppVersionItemsItems={(
                              e,
                              fieldName,
                              osType,
                            ) =>
                              handleChangeSegmentCriteriaAppVersionItemsItems(
                                e,
                                osType,
                                fieldName,
                                idx,
                              )
                            }
                          />
                        ))}
                      <IconButton
                        onClick={() => {
                          handlePlatformAddACriteriaItem('web')
                        }}
                      >
                        <Add />
                      </IconButton>
                    </>
                  )}
                </>
              )}
            </CriteriaBox> */
}
