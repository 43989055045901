export async function transformSendMetaTabSettingData(fetchedData, customStoreData) {
  const androidData = {
    ...(fetchedData?.android || {}),
    tilePolicyMap: mapTilePoliciesToTheirIds(fetchedData?.android?.tilePolicyMap, customStoreData),
  }
  const iosData = {
    ...(fetchedData?.ios || {}),
    tilePolicyMap: mapTilePoliciesToTheirIds(fetchedData?.ios?.tilePolicyMap, customStoreData),
  }
  const webData = {
    ...(fetchedData?.web || {}),
    tilePolicyMap: mapTilePoliciesToTheirIds(fetchedData?.web?.tilePolicyMap, customStoreData),
  }

  return {
    ...fetchedData,
    android: [
      { ...androidData, name: 'Morning' },
      { ...androidData, name: 'Evening' },
    ],
    ios: [
      { ...iosData, name: 'Morning' },
      { ...iosData, name: 'Evening' },
    ],
    web: [
      { ...webData, name: 'Morning' },
      { ...webData, name: 'Evening' },
    ],
  }
}

function mapTilePoliciesToTheirIds(tilePolicies, customStoreData) {
  return Object.entries(tilePolicies || {})?.reduce((acc, [key, tilePolicy]) => {
    acc[key] = tilePolicy?.id
    return acc
  }, {})
}
