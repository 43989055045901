import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getSummarizableObject } from '../abstractObjects/Summarizable'

export const SES_TEMPLATE_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.sesTemplate),
  title: '',
  subject: '',
  preHeader: '',
  body: '',
}
