import { CFormInput } from '@coreui/react-pro'
import { Grid } from '@mui/material'
import React from 'react'
import { checkIfStringIsValid } from 'src/utilities/generalUtils'

export default function KeyValuePairEditor({
  id,
  keyValuePairInArrayFormat,
  setMainObject,
  disabled,
}) {
  function handleChangeCustomFields(event, idx) {
    setMainObject((prev) => {
      let prevCurrentCustomField = prev?.[id]?.[idx]
      let currentCustomField = { ...prev?.[id]?.[idx], [event.target.id]: event.target.value }
      let nextCustomField = prev?.[id]?.[idx + 1]

      let allCustomFields = [
        ...(prev?.[id]?.map((customField, index) => {
          if (index === idx) {
            return { ...customField, [event.target.id]: event.target.value }
          }
          return customField
        }) || []),
      ]
      console.log({ idx, lengthOfArray: prev?.[id]?.length })

      if (
        !checkIfStringIsValid(currentCustomField?.customField) &&
        !checkIfStringIsValid(currentCustomField?.value) &&
        prev?.[id]?.length > 1
      ) {
        //remove this field
        allCustomFields = allCustomFields?.filter((_, index) => index !== idx)
      } else {
        if (!nextCustomField) {
          allCustomFields?.push({ customField: '', value: '' })
          //add a field ahead
        }
      }

      return {
        ...prev,
        [id]: allCustomFields,
      }
    })
  }

  return (
    <>
      {keyValuePairInArrayFormat?.map((customFieldsKeyValue, idx) => (
        <>
          <Grid item xs={6}>
            <CFormInput
              id="customField"
              value={customFieldsKeyValue?.customField}
              onChange={(e) => handleChangeCustomFields(e, idx)}
              placeholder="Enter the key..."
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <CFormInput
              id="value"
              value={customFieldsKeyValue?.value}
              onChange={(e) => handleChangeCustomFields(e, idx)}
              placeholder="Enter the value..."
              disabled={disabled}
            />
          </Grid>
        </>
      ))}
    </>
  )
}

// if (
//   idx === allCustomFields.length - 1 &&
//   (currentCustomField?.customField !== '' || currentCustomField?.value !== '')
// ) {
//   console.log('add')
//   allCustomFields = [...allCustomFields, { customField: '', value: '' }]
// }
// if (
//   idx !== allCustomFields.length - 1 &&
//   !checkIfStringIsValid(currentCustomField?.customField) &&
//   !checkIfStringIsValid(currentCustomField?.value)
// ) {
//   console.log('rem')
//   allCustomFields = allCustomFields?.filter((_, index) => index !== idx)
// }

// function addCustomField() {
//   console.log('add')
//   setMainObject((prev) => ({
//     ...prev,
//     [id]: [...prev?.customFields, { customField: '', value: '' }],
//   }))
// }
// function removeCustomField(idx) {
//   console.log('rem')
//   setMainObject((prev) => ({
//     ...prev,
//     [id]: prev?.customFields?.filter((_, index) => index !== idx),
//   }))
// }
