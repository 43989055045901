import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { TimeFrameView, TimeLineContainer, TransparentInput } from './styledComponents'
import { Box, Button, DialogContent, Popover } from '@mui/material'
import { CFormInput } from '@coreui/react-pro'
import ImageUpload from '../imageUpload/ImageUpload'
import { META_IMAGE_SLIDE } from 'src/views/settings/utils/bg-slides-utils'
import { BACKGROUND_SLIDES_IMAGE_TYPES } from '../BackgroundSlidesUpload'
import TimeLineDnD, { sortFn } from './TimeLineDnD'
import { DragDropContext } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'

export default function BGSTimeLine({
  imageSlideObject,
  setImageSlideObject,
  selectedFile,
  setSelectedFile,
  type,
  timeFrame,
  setTimeFrame,
}) {
  const photoInputRef = useRef(null)
  const [showPopover, setShowPopover] = useState(null)
  const inputWidths = {
    minTimeW: !!timeFrame?.minTime ? String(timeFrame?.minTime / 1000).length * 8 : 20,
    maxTimeW: !!timeFrame?.maxTime ? String(timeFrame?.maxTime / 1000).length * 20 : 20,
  }
  const memoImageType = useMemo(() => {
    return getBGSImageType(type)
  }, [type])

  function handleOpenPopover(event, index) {
    setShowPopover(event.currentTarget)
    setSelectedFile(index)
  }

  function handleClosePopover() {
    setShowPopover(null)
  }

  function handleAdd() {
    photoInputRef.current.click()
  }

  function handleImageAdd(object) {
    setImageSlideObject((prev) => {
      return {
        ...prev,
        [type]: [
          ...(prev[type] || []),
          {
            ...META_IMAGE_SLIDE,
            inAt: (prev?.[type]?.lastItem?.outAt || -1) + 1,
            outAt:
              (prev?.[type]?.lastItem?.outAt || -1) + (timeFrame?.maxTime - timeFrame?.minTime),
            image: object,
          },
        ],
      }
    })
  }

  function handleChangeTimeFrame(event) {
    setTimeFrame((prev) => ({ ...prev, [event.target.id]: event.target.value * 1000 }))
  }

  function handleImgObjChange(prevFunc, index) {
    setImageSlideObject((prev) => {
      let slideArrayToReturn = prev?.[type] || []

      let currentInTime = 0
      slideArrayToReturn = slideArrayToReturn.map((slide, idx) => {
        const slideToUpdate = idx === index ? prevFunc(prev[type][idx]) : slide
        const duration = slideToUpdate.outAt - slideToUpdate.inAt
        const updatedSlide = normalizeSlide(slideToUpdate, duration, currentInTime)
        currentInTime = updatedSlide.outAt + 1
        return updatedSlide
      }) //FUNCTION TO MAKE THE TIMES FINE

      return {
        ...prev,
        [type]: slideArrayToReturn,
      }
    })
    handleClosePopover()
  }

  function handleDragEnd(results) {
    const { source, destination } = results
    if (source && destination && source?.index !== destination?.index) {
      setImageSlideObject((prev) => {
        const sortedByInTimeSlides = prev?.[type]?.sort((slideA, slideB) =>
          sortFn(slideA.inAt, slideB.inAt),
        )

        let toReturnSlides = sortedByInTimeSlides.map((slide) => ({ ...slide }))

        const draggedSlide = toReturnSlides[source.index]

        toReturnSlides.splice(source.index, 1)
        toReturnSlides.splice(destination.index, 0, draggedSlide)

        let currentInTime = 0
        toReturnSlides = toReturnSlides.map((slide) => {
          const duration = slide.outAt - slide.inAt
          const updatedSlide = normalizeSlide(slide, duration, currentInTime)
          currentInTime = updatedSlide.outAt + 1
          return updatedSlide
        })

        return { ...prev, [type]: toReturnSlides }
      })
    }
  }

  useEffect(() => {
    console.log(photoInputRef)
  }, [photoInputRef])

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <TimeLineContainer>
          <TimeLineDnD
            handleOpenPopover={handleOpenPopover}
            imageSlideObject={imageSlideObject}
            timeFrame={timeFrame}
            type={type}
            selectedIndex={selectedFile}
            handleAdd={handleAdd}
          />
        </TimeLineContainer>
      </DragDropContext>
      <Box width="100%" display="flex" justifyContent={'space-between'}>
        <TimeFrameView>
          <TransparentInput
            id="minTime"
            value={timeFrame?.minTime / 1000}
            type="number"
            onChange={handleChangeTimeFrame}
            style={{ width: `${inputWidths.minTimeW}px` }}
          />
          s
        </TimeFrameView>
        <TimeFrameView>
          <TransparentInput
            id="maxTime"
            value={timeFrame?.maxTime / 1000}
            type="number"
            onChange={handleChangeTimeFrame}
            style={{ width: `${inputWidths.maxTimeW}px` }}
          />
          s
        </TimeFrameView>
      </Box>
      <ImageUpload
        chooseButtonRef={photoInputRef}
        title="Add Image to the time line"
        imageType={memoImageType}
        view="hidden"
        onChangeType="object"
        onChange={handleImageAdd}
      />
      <Popover open={Boolean(showPopover)} anchorEl={showPopover} onClose={handleClosePopover}>
        <DialogContent>
          <ChangeInOutTime
            index={selectedFile}
            type={type}
            slideObject={imageSlideObject}
            handleChange={handleImgObjChange}
            handleClose={handleClosePopover}
          />
        </DialogContent>
      </Popover>
    </>
  )
}

export function ChangeInOutTime({ slideObject, type, index, handleChange }) {
  const [tempSlide, setTempSlide] = useState()

  function handleInputChange(event) {
    setTempSlide((prev) => {
      return { ...prev, [event.target.id]: event.target.value * 1000 }
    })
  }

  function handleClick() {
    const duration = tempSlide.outAt - tempSlide.inAt
    if (duration > 0) {
      const slideToReturn = {
        ...tempSlide,
        inAt: slideObject[type][index].inAt,
        outAt: slideObject[type][index].inAt + duration,
      }
      handleChange((prev) => {
        return { ...prev, ...slideToReturn }
      }, index)
    } else {
      toast.warning("Slide's in time cannot be more than it's out time")
    }
  }

  useEffect(() => {
    setTempSlide(slideObject[type][index])
  }, [slideObject])

  return (
    <>
      <CFormInput
        id="inAt"
        label="In Time"
        type="number"
        value={tempSlide?.inAt / 1000}
        onChange={handleInputChange}
      />
      <CFormInput
        id="outAt"
        label="Out Time"
        type="number"
        value={tempSlide?.outAt / 1000}
        onChange={handleInputChange}
      />
      <Button size="small" variant="contained" onClick={handleClick}>
        Update
      </Button>
    </>
  )
}

function getBGSImageType(type) {
  switch (type) {
    case BACKGROUND_SLIDES_IMAGE_TYPES.landScapeImage:
      return 'coverimage'
    case BACKGROUND_SLIDES_IMAGE_TYPES.portraitImage:
      return 'verticalRectangle'
    default:
      return 'icon'
  }
}

ChangeInOutTime.propTypes = {
  slideObject: PropTypes.object,
  type: PropTypes.string,
  index: PropTypes.number,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
}

BGSTimeLine.propTypes = {
  imageSlideObject: PropTypes.object.isRequired,
  setImageSlideObject: PropTypes.func.isRequired,
  uploadedFile: PropTypes.any,
  setUploadedFile: PropTypes.func,
  selectedFile: PropTypes.number,
  setSelectedFile: PropTypes.func,
  type: PropTypes.string,
  timeFrame: PropTypes.string,
  setTimeFrame: PropTypes.func,
}

function normalizeSlide(slide, duration, currentInTime) {
  return { ...slide, inAt: currentInTime, outAt: currentInTime + duration }
}
