import { putActionDataForNotificationsToItsPositionDefaultHandling } from './defaultHandling'

export function putActionDataForNotificationsToItsPosition(notificationData, dlData, categoryType) {
  const tempNotificationData = notificationData

  switch (categoryType) {
    default:
      return putActionDataForNotificationsToItsPositionDefaultHandling(tempNotificationData, dlData)
  }
}
