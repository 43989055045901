import { FormControlLabel, Grid, Switch } from '@mui/material'
import React from 'react'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import SlugCheckerComponent from 'src/components/controlCenter/common/slugChecker/SlugCheckerComponent'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import UserInputComponent from 'src/components/controlCenter/userInput/UserInputComponent'
import { GENERIC_APIS } from 'src/services/genericApis'

export default function GuestListComponent({
  initialObject,
  imageObject: guest,
  setImageObject: setGuest,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  hideEnabled = false,
  slugValidation,
  setSlugValidation,
}) {
  return (
    <div className="d-flex">
      <Grid container spacing={2}>
        {!hideEnabled && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  id="enabled"
                  checked={!!guest?.enabled || false}
                  onChange={handleChangeObject}
                  disabled={disabled}
                />
              }
              label={!!guest?.enabled ? 'Enabled' : 'Disabled'}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CCFormInput
            id={'title'}
            label="Guest Name"
            value={guest?.title}
            onChange={handleChangeObject}
            placeholder={'Enter Guest Name...'}
            disabled={disabled}
            required
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id={'description'}
            label="Guest Description"
            value={guest?.description}
            onChange={handleChangeObject}
            placeholder={'Enter Guest Description...'}
            disabled={disabled}
            required
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <SlugCheckerComponent
            disabled={disabled}
            label="Slug"
            setSlugValidation={setSlugValidation}
            slugValidation={slugValidation}
            initialValue={initialObject?.slug}
            slugCheckApiParams={{
              apiParam: entity,
            }}
            slugCheckApiSignature={GENERIC_APIS.entity.slug.checkAvailability}
            value={guest?.slug}
            onChange={(value) => setGuest((prev) => ({ ...prev, slug: value }))}
            newEntity
            initialInvalidationText=""
          />
        </Grid>
        <Grid item xs={12}>
          <CustomReactSelect
            id="mentorId"
            value={guest?.mentorId}
            onChangeEvent={handleChangeObject}
            label="Select Mentor"
            selectType={selectTypes.string}
            options={customOptions?.mentors}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.title}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <UserInputComponent
            value={guest?.contact}
            disabled={disabled}
            onChange={(userValue) => {
              setGuest((prev) => ({
                ...prev,
                contact: {
                  ...prev.contact,
                  userId: userValue?.userId,
                  id: userValue?.id,
                  uid: userValue?.id,
                  email: userValue?.email,
                  title: userValue?.firstName,
                },
              }))
            }}
            getOptionLabel={(option) => option?.title}
            label="User Object"
          />
        </Grid>
        <Grid item xs={12}>
          <EntityBasedImageUploadComponent
            disabled={disabled}
            entity={entity}
            entityObject={guest}
            handleChange={handleChangeObject}
          />
        </Grid>
      </Grid>
    </div>
  )
}
