import React, { useState } from 'react'
import { TabWrapper } from 'src/components/controlCenter/common/sectionComponents/TabWrapper'
import { StyledTabButton, StyledTabContainer } from 'src/components/controlCenter/styledComponents'
import UserDevices from './Devices/UserDevices'
import UserSegments from './Segments/UserSegments'

const tabs = {
  userDevices: {
    id: 'userDevices',
    title: 'User Devices',
  },
  userSegmentsHistory: {
    id: 'userSegmentsHistory',
    title: 'User Segments History',
  },
}

export default function DevicesAndExperienceTabs({ userId }) {
  const [selectedTab, setSelectedTab] = useState(tabs.userDevices.id)
  const [data, setData] = useState({
    userDevices: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
    userSegmentsHistory: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
  })

  function setDataDynamically(prevData, key) {
    if (typeof prevData === 'function') {
      setData((prev) => ({
        ...prev,
        [key]: prevData(prev?.[key]),
      }))
    } else {
      setData((prev) => ({
        ...prev,
        [key]: prevData,
      }))
    }
  }

  function setUserDevices(prevData) {
    setDataDynamically(prevData, 'userDevices')
  }
  function setUserSegmentsHistory(prevData) {
    setDataDynamically(prevData, 'userSegmentsHistory')
  }

  function handleChangeTab(tabId) {
    setSelectedTab(tabId)
  }

  return (
    <>
      <StyledTabContainer
        sx={{
          position: 'sticky',
          top: 'calc(var(--header-height))',
          zIndex: '999',
          background: (theme) =>
            `${
              theme.palette.mode === 'dark'
                ? 'var(--cui-body-bg)'
                : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
            }`,
          borderBottom: '1px solid var(--cui-border-color)',
          padding: '15px 0px',
        }}
      >
        {Object.values(tabs).map((section) => (
          <StyledTabButton
            key={section.id}
            selected={selectedTab === section.id}
            onClick={() => handleChangeTab(section.id)}
          >
            {section.title}
          </StyledTabButton>
        ))}
      </StyledTabContainer>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.userDevices?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <UserDevices userId={userId} data={data.userDevices} setData={setUserDevices} />
      </TabWrapper>
      <TabWrapper
        currentValue={selectedTab}
        tabValue={tabs?.userSegmentsHistory?.id}
        tabBoxProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <UserSegments
          userId={userId}
          data={data.userSegmentsHistory}
          setData={setUserSegmentsHistory}
        />
      </TabWrapper>
    </>
  )
}
