import { PMPColumnedFooter, PMPFooterContainer } from '../../styledComponents'
import RelatedClinicDetails from './relatedClinicDetails/RelatedClinicDetails'

export default function TwoColumnPrescriptionFooter({ prescriptionData, noColor, hideQr = true }) {
  return (
    <>
      <PMPColumnedFooter id="two-col" key="two-col" columns={2} noColor={noColor}>
        <RelatedClinicDetails
          footerData={prescriptionData?.relatedClinicDetails?.[0]}
          containerStyle={{ fontSize: '9px' }}
        />
        <RelatedClinicDetails
          footerData={prescriptionData?.relatedClinicDetails?.[1]}
          containerStyle={{ textAlign: 'right', fontSize: '9px' }}
        />
      </PMPColumnedFooter>
      <PMPFooterContainer
        noColor={noColor}
        threeColFooter
        style={{
          display: 'flex',
          alignItems: 'flex-center',
          justifyContent: 'space-between',
          fontSize: '9px',
          padding: '2px 30px',
        }}
      >
        <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.supportTextAlt}</div>
        <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.subtext}</div>
        <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.supportText}</div>
      </PMPFooterContainer>
    </>
  )
}
