import { CButton, CButtonGroup, CFormInput } from '@coreui/react-pro'
import React, { useState } from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import {
  ALL_AVAILABLE_PLATFORMS_ARRAY,
  ALL_GENDERS,
  SUBSCRIPTION_STATUS_ARRAY,
} from 'src/utilities/constants'
import { genericHandleChange } from 'src/utilities/generalUtils'

export default function UsersHeaderComponentsFirstLayer({
  dropDownData,
  filters,
  setFilters,
  rStore,
}) {
  const [startJoinDate, setStartJoinDate] = useState(new Date())
  const [endJoinDate, setEndJoinDate] = useState(new Date())

  function handleFilterChange(event) {
    genericHandleChange(event, setFilters)
  }

  return (
    <>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="role"
          label="Roles"
          value={filters?.role}
          options={rStore?.allRoles}
          getOptionLabel={(option) => option?.title}
          getOptionValue={(option) => option?.name}
          selectType={selectTypes.string}
          onChangeEvent={handleFilterChange}
          isClearable
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="country"
          label="User Country"
          value={filters?.country}
          options={rStore?.countries}
          getOptionLabel={(option) => option?.title}
          getOptionValue={(option) => option?.id}
          selectType={selectTypes.string}
          onChangeEvent={handleFilterChange}
          isClearable
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="gender"
          label="Gender"
          value={filters?.gender}
          options={ALL_GENDERS}
          getOptionLabel={(option) => option?.title}
          getOptionValue={(option) => option?.id}
          selectType={selectTypes.string}
          onChangeEvent={handleFilterChange}
          isClearable
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '500px', width: '100%' }}>
        <CustomReactSelect
          id="subscriptionId"
          label="Subscription Plan"
          value={filters?.subscriptionId}
          options={dropDownData?.subscriptionPlans?.sort((a, b) =>
            a?.title?.localeCompare(b?.title),
          )}
          getOptionLabel={(option) => option?.title}
          getOptionValue={(option) => option?.id}
          selectType={selectTypes.string}
          onChangeEvent={handleFilterChange}
          isClearable
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="subscriptionLevel"
          label="Subscription Level"
          value={filters?.subscriptionLevel}
          options={dropDownData?.allPlanLevels}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          selectType={selectTypes.string}
          onChangeEvent={handleFilterChange}
          isClearable
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="platform"
          label="Platform"
          value={filters?.platform}
          options={ALL_AVAILABLE_PLATFORMS_ARRAY}
          getOptionLabel={(option) => option?.title}
          getOptionValue={(option) => option?.id}
          selectType={selectTypes.string}
          onChangeEvent={handleFilterChange}
          isClearable
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CFormInput
          id="startAge"
          label="Min Age"
          value={filters?.startAge}
          onChange={(event) => {
            console.log({ val: event.target.value })
            if (event.target.value === '')
              return handleFilterChange({ target: { id: event.target.id, value: null } })
            const value = Math.max(0, parseInt(event?.target?.value || '0'))
            handleFilterChange({ target: { id: event.target.id, value, type: event.target.type } })
          }}
          type="number"
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CFormInput
          id="endAge"
          label="Max Age"
          value={filters?.endAge}
          onChange={(event) => {
            if (event.target.value === '')
              return handleFilterChange({ target: { id: event.target.id, value: null } })
            const value = Math.max(0, parseInt(event?.target?.value || '0'))
            handleFilterChange({ target: { id: event.target.id, value, type: event.target.type } })
          }}
          type="number"
          isClearable
        />
      </div>
      {/* <div style={{ maxWidth: '300px', width: '100%' }}>
        <CDateRangePicker
          startDate={moment(filters?.createdTimeStart).toDate()}
          endDate={moment(filters?.createdTimeEnd).toDate()}
          locale="en-US"
          onStartDateChange={(date) => {
            if (!isNaN(date?.getTime())) {
              handleFilterChange({
                target: {
                  id: 'createdTimeStart',
                  value: moment(date).format('YYYY-MM-DD HH:mm:ss'),
                },
              })
            }
          }}
          maxDate={new Date()}
          onEndDateChange={(date) => {
            // setEndJoinDate(date)
            if (!isNaN(date?.getTime())) {
              handleFilterChange({
                target: { id: 'createdTimeEnd', value: moment(date).format('YYYY-MM-DD HH:mm:ss') },
              })
            }
          }}
        />
      </div> */}
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="acquisitionId"
          value={filters?.acquisitionId}
          options={dropDownData?.acquisitionSources?.sort((a, b) =>
            a?.title?.localeCompare(b?.title),
          )}
          isClearable
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.title}
          label="Acquisition"
          onChangeEvent={handleFilterChange}
          selectType={selectTypes.string}
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="userLanguage"
          value={filters?.userLanguage}
          options={rStore?.languages}
          isClearable
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.languageName}
          label="Preferred Language"
          onChangeEvent={handleFilterChange}
          selectType={selectTypes.string}
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="contentLanguage"
          value={filters?.contentLanguage}
          options={rStore?.languages}
          isClearable
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.languageName}
          label="Content Language"
          onChangeEvent={handleFilterChange}
          selectType={selectTypes.string}
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ maxWidth: '300px', width: '100%' }}>
        <CustomReactSelect
          id="subscriptionStatus"
          value={filters?.subscriptionStatus}
          options={SUBSCRIPTION_STATUS_ARRAY}
          isClearable
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.title}
          label="Subscription Status"
          onChangeEvent={handleFilterChange}
          selectType={selectTypes.string}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        {/* <ReactSelect
          id="subscriptionStatus"
          value={filters?.subscriptionStatus}
          options={SUBSCRIPTION_STATUS_ARRAY}
          isClearable
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.title}
          label="Subscription Status"
          onChangeEvent={handleFilterChange}
          selectType={selectTypes.string}
        /> */}
        <div style={threeWayButtonStyles}>
          <CButtonGroup variant="contained" size="sm">
            <CButton
              color={'secondary'}
              active={filters?.userOnboardedStatus === 'active'}
              onClick={() => setFilters({ target: { id: 'userOnboardedStatus', value: 'active' } })}
            >
              Onboarded Users Only
            </CButton>
            <CButton
              color={'secondary'}
              active={
                filters?.userOnboardedStatus === null || filters?.userOnboardedStatus === undefined
              }
              onClick={() => setFilters({ target: { id: 'userOnboardedStatus', value: null } })}
            >
              Show All
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.userOnboardedStatus === 'inactive'}
              onClick={() =>
                setFilters({ target: { id: 'userOnboardedStatus', value: 'inactive' } })
              }
            >
              Not Onboarded Users
            </CButton>
          </CButtonGroup>
        </div>
      </div>
    </>
  )
}
const threeWayButtonStyles = {
  maxWidth: '500px',
  display: 'flex',
  alignItems: 'flex-end',
  padding: '2px 0px',
}
