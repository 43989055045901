import {
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
} from '@coreui/react-pro'
import {
  Add,
  CheckBoxOutlineBlank,
  DragIndicator,
  ExpandLess,
  ExpandMore,
  RadioButtonUnchecked,
} from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, Collapse, Grow, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { BetterMultiSelectField } from 'src/components/controlCenter/FormComponents'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import { handleEventTargetChange } from 'src/utilities/generalUtils'
import { NAMED_ASSESSMENT_ITEM } from 'src/views/curatedLists/utils/curatedList-utils'
import { array_move } from 'src/views/curatedLists/utils/curatedListData-utils'
import {
  CustomCreatableSelect,
  CustomSelect,
} from 'src/views/plugins/calendar/components/CustomSelect'
import { getTempDomains } from '../utils/journals-utils'
import OptionsComponents from './OptionsComponents'
import {
  JournalQuestionCard,
  JournalQuestionHeadingText,
  JournalQuestionsBodyCard,
  OptionNameContainer,
  OptionStyledContainer,
  OptionsContainer,
  QuestionCard,
} from './styledComponents'

function MainJournalCard({
  title,
  id,
  data,
  completeData,
  setData,
  addFunction,
  removeFunction,
  colSize = 6,
  disabled,
  allHints,
  createHint,
  loadingHints,
  apiParam,
  assessmentDomains,
  allAssessmentOptions,
}) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1)
  const [questionsToShowIndeces, setQuestionsToShowIndeces] = useState([])
  const [dragginOption, setDraggingOption] = useState(-1)
  const [hoveredQuestion, setHoveredQuestion] = useState(-1)
  const lastDivInQuestions = useRef(null)
  const showAllIndQuestions = useMemo(() => {
    return data?.length === questionsToShowIndeces?.length
  }, [questionsToShowIndeces, data])

  function handleAddQuestion(id) {
    addFunction(id)
    setQuestionsToShowIndeces((prev) => [...(prev || []), (data || []).length])
  }

  function handleChangeQusAns(index, innerData) {
    setData((prev) => ({
      ...prev,
      [id]: prev?.[id]?.map((qusAns, messageIndex) =>
        messageIndex === index ? innerData(qusAns) : qusAns,
      ),
    }))
  }

  function handleDragEnd(results) {
    const { source, destination } = results
    if (source.index !== destination.index) {
      setData((prev) => ({
        ...prev,
        [id]: array_move(prev?.[id] || [], source.index, destination.index),
      }))
    }
  }

  function handleChange(event, index) {
    handleChangeQusAns(index, (prev) => ({ ...prev, [event.target.id]: event.target.value }))
  }

  const handleHintsChange = (selectedValue, index) => {
    setData((prev) => ({
      ...prev,
      journalQusAns: prev?.journalQusAns?.map((qusAns, messageIndex) =>
        messageIndex === index
          ? { ...qusAns, hints: selectedValue.map((val) => val.value) }
          : qusAns,
      ),
    }))
  }
  function handleQuestionMenuClose() {
    setMenuAnchor(null)
    setSelectedQuestionIndex(-1)
  }

  function handleAddOption(index) {
    handleChangeQusAns(index, (prev) => {
      console.log({ ...prev, options: [...(prev?.options || []), NAMED_ASSESSMENT_ITEM] })
      const optionsToUse = [...(prev?.options || [])]

      return {
        ...prev,
        options: [...optionsToUse, { ...NAMED_ASSESSMENT_ITEM, orderId: optionsToUse.length }],
      }
    })
    lastDivInQuestions?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    })
  }

  function handleShowToggle() {
    setQuestionsToShowIndeces((prev) => (!showAllIndQuestions ? data.map((qus, idx) => idx) : []))
  }

  function removeOption(quesIndex, optionIndex) {
    handleChangeQusAns(quesIndex, (prev) => {
      const sortedOptions = prev.options.sort((optn1, optn2) =>
        sortFn(optn1.orderId, optn2.orderId),
      )
      return { ...prev, options: sortedOptions.filter((optn, idx) => idx !== optionIndex) }
    })
  }

  function handleDragStart(questionIndex, optionIndex, event) {
    setDraggingOption(optionIndex)
    setSelectedQuestionIndex(questionIndex)
    // event.target.style.opacity = '0.2'
  }

  function handleDragEnter(e, questionIndex) {
    if (questionIndex === selectedQuestionIndex) {
      // e.target.style.padding = '5px'
    }
  }

  function handleDrop(newQuestionIndex, newOptionIndex) {
    if (newQuestionIndex === selectedQuestionIndex) {
      handleChangeQusAns(selectedQuestionIndex, (prev) => {
        const oldOptions = prev.options.sort((a, b) => sortFn(a.orderId, b.orderId))
        const newOptions = array_move(oldOptions, dragginOption, newOptionIndex)?.map(
          (opt, idx) => ({
            ...opt,
            orderId: idx + 1,
          }),
        )
        return { ...prev, options: newOptions }
      })
    }
  }

  function handleCollapseQuestion(questIndex) {
    setQuestionsToShowIndeces((prev) => {
      const found = prev.some((ques) => {
        return ques === questIndex
      })
      return found ? prev.filter((ques) => ques !== questIndex) : [...prev, questIndex]
    })
  }
  function handleChangeOption(questionIndex, optionIndex, event) {
    handleChangeQusAns(questionIndex, (prev) => {
      return {
        ...prev,
        options: prev.options
          .sort((a, b) => sortFn(a.orderId, b.orderId))
          .map((opt, idx) => {
            return idx === optionIndex ? handleEventTargetChange(opt, event) : opt
          }),
      }
    })
  }
  function handleReplaceOptionSelect(questionIndex, optionIndex, value) {
    const correctedOptionIndex = optionIndex
    handleChangeQusAns(questionIndex, (prev) => {
      return {
        ...prev,
        options: prev.options
          .sort((a, b) => sortFn(a.orderId, b.orderId))
          .map((opt, idx) => {
            return idx === correctedOptionIndex ? { ...value, orderId: opt?.orderId || 1 } : opt
          }),
      }
    })
  }
  function removeDragStyles(event) {
    event.currentTarget.style.opacity = '1'
    event.target.setAttribute('draggable', false)
  }
  function handleDragHandleOption(event) {
    console.log(event.target.tagName)
    console.log(event.target)
    if (!disabled) {
      if (event.target.tagName === 'svg')
        event.target.parentNode.parentNode.setAttribute('draggable', true)
      else {
        event.target.parentNode.parentNode.parentNode.setAttribute('draggable', true)
      }
    }
  }
  function handleDragHandleOptionRemove(event) {
    console.log(event.target.tagName)
    if (event.target.tagName === 'svg')
      event.target.parentNode.parentNode.setAttribute('draggable', false)
    else {
      event.target.parentNode.parentNode.parentNode.setAttribute('draggable', false)
    }
  }
  function copyPreviousQuestionOptions(idx) {
    handleChangeQusAns(idx, (prev) => {
      return {
        ...prev,
        options: data?.[idx - 1]?.options,
      }
    })
    handleQuestionMenuClose()
  }
  function copyPreviousQuestion(idx) {
    setData((prev) => ({
      ...prev,
      [id]: prev?.[id]?.map((qusAns, messageIndex) =>
        messageIndex === idx ? prev?.[id]?.[messageIndex - 1] : qusAns,
      ),
    }))
    handleQuestionMenuClose()
  }

  useEffect(() => {
    setQuestionsToShowIndeces(data.map((qus, idx) => idx))
  }, [])

  return (
    <CCol md={colSize} className="h-100">
      <DragDropContext onDragEnd={handleDragEnd}>
        <JournalQuestionCard className="d-flex flex-column justify-content-stretch align-items-stretch">
          <CCardHeader className="d-flex justify-content-between align-items-center">
            <CCardTitle>
              {title} ({data.length})
            </CCardTitle>
            <div className="d-flex justify-content-end align-items-center gap-2">
              {data.length > 0 && (
                <IconButton onClick={handleShowToggle}>
                  {showAllIndQuestions ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
              <GrowOnNotDisabled
                disabled={disabled}
                elements={
                  <IconButton onClick={() => handleAddQuestion(id)}>
                    <Add />
                  </IconButton>
                }
              />
            </div>
          </CCardHeader>
          <Droppable droppableId="ROOT" type="droppableItem">
            {(provided) => (
              <JournalQuestionsBodyCard
                className="bg-secondary d-flex flex-column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {data?.map((quest, questionIndex) => {
                  return (
                    <Draggable
                      key={`${questionIndex}`}
                      draggableId={`${questionIndex}`}
                      index={questionIndex}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <QuestionCard
                          key={questionIndex}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          className="mt-2"
                        >
                          <CCardHeader
                            className="d-flex justify-content-stretch align-items-center"
                            {...provided.dragHandleProps}
                          >
                            <Tooltip title={quest?.question || 'NO QUESTION SET'}>
                              <JournalQuestionHeadingText>
                                <strong>{questionIndex + 1}.</strong>{' '}
                                {quest?.question || 'NO QUESTION SET'}
                              </JournalQuestionHeadingText>
                            </Tooltip>
                            {!disabled && (
                              <DragIndicator
                                sx={{
                                  transform: 'rotate(90deg)',
                                  display: 'hidden',
                                  flexShrink: 0,
                                }}
                              />
                            )}
                            <Box
                              display="flex"
                              justifyContent={'flex-end'}
                              alignItems={'center'}
                              gap={1}
                              width="50%"
                            >
                              <IconButton onClick={() => handleCollapseQuestion(questionIndex)}>
                                {questionsToShowIndeces.some(
                                  (toShowIndex) => toShowIndex === questionIndex,
                                ) ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                              <Grow in={!disabled}>
                                <IconButton
                                  onClick={(e) => {
                                    setMenuAnchor(e.currentTarget)
                                    setSelectedQuestionIndex(questionIndex)
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Grow>
                              <Menu
                                anchorEl={menuAnchor}
                                open={
                                  Boolean(menuAnchor) && questionIndex === selectedQuestionIndex
                                }
                                onClose={handleQuestionMenuClose}
                              >
                                <MenuItem
                                  onClick={() => {
                                    removeFunction(selectedQuestionIndex)
                                    setMenuAnchor(null)
                                  }}
                                >
                                  Delete
                                </MenuItem>
                                {questionIndex !== 0 && (
                                  <MenuItem
                                    onClick={() => {
                                      copyPreviousQuestionOptions(questionIndex)
                                    }}
                                  >
                                    Copy Previous Options
                                  </MenuItem>
                                )}
                                {questionIndex !== 0 && (
                                  <MenuItem
                                    onClick={() => {
                                      copyPreviousQuestion(questionIndex)
                                    }}
                                  >
                                    Copy Previous Question
                                  </MenuItem>
                                )}
                              </Menu>
                            </Box>
                          </CCardHeader>
                          <Collapse
                            in={questionsToShowIndeces.some(
                              (toShowIndex) => toShowIndex === questionIndex,
                            )}
                          >
                            <CCardBody id="questions-container" className="row">
                              <div className="col-12">
                                <CFormInput
                                  id="question"
                                  value={quest?.question}
                                  label="Question"
                                  disabled={disabled}
                                  onChange={(event) => handleChange(event, questionIndex)}
                                />
                              </div>
                              <div className="col-12">
                                <CFormInput
                                  id="superText"
                                  value={quest?.superText}
                                  label="Super Text"
                                  disabled={disabled}
                                  onChange={(event) => handleChange(event, questionIndex)}
                                />
                              </div>
                              <div className="col-12">
                                <CFormInput
                                  id="subtext"
                                  value={quest?.subtext}
                                  disabled={disabled}
                                  label="Sub Text"
                                  onChange={(event) => handleChange(event, questionIndex)}
                                />
                              </div>
                              <div className="col-12">
                                <CFormInput
                                  id="helpText"
                                  value={quest?.helpText}
                                  label="Help Text"
                                  disabled={disabled}
                                  onChange={(event) => handleChange(event, questionIndex)}
                                />
                              </div>
                              {apiParam === 'assessment' ? (
                                <>
                                  <div className="col-md-12 col-lg-12">
                                    <div className="col-md-12">
                                      <BetterMultiSelectField
                                        colNumber={12}
                                        customLabel="Assessment Domains"
                                        disabled={disabled}
                                        id="assessmentDomainIds"
                                        valueKey="id"
                                        labelKey="name"
                                        setType="string"
                                        options={assessmentDomains.map((domain) => {
                                          return (
                                            getTempDomains()?.find(
                                              (tempDomain) => tempDomain.id === domain,
                                            ) || {}
                                          )
                                        })}
                                        setData={(value) => {
                                          handleChangeQusAns(questionIndex, value)
                                        }}
                                        value={quest?.assessmentDomainIds}
                                      />
                                    </div>
                                    <CFormSelect
                                      id="answerType"
                                      value={quest?.answerType}
                                      label="Option Type"
                                      disabled={disabled}
                                      options={optionTypeOptions}
                                      onChange={(e) => handleChange(e, questionIndex)}
                                    />
                                  </div>
                                  {quest?.answerType !== 'textBox' && (
                                    <>
                                      <strong style={{ margin: '5px 0px' }}>Options</strong>
                                      <div className="d-flex flex-column">
                                        {quest?.options
                                          ?.sort((optn1, optn2) =>
                                            sortFn(optn1.orderId, optn2.orderId),
                                          )
                                          ?.map((optn, idx) => (
                                            <OptionsContainer
                                              key={idx}
                                              onDragStart={(e) =>
                                                handleDragStart(questionIndex, idx, e)
                                              }
                                              onDragEnd={(e) => removeDragStyles(e)}
                                              onDragEnter={(e) => handleDragEnter(e, questionIndex)}
                                              onDragOver={(e) => e.preventDefault()}
                                              onDrop={() => handleDrop(questionIndex, idx)}
                                              className="mt-1"
                                            >
                                              <div
                                                style={{
                                                  cursor: disabled ? 'default' : 'all-scroll',
                                                }}
                                                className="d-flex justify-content-start align-items-center gap-3"
                                              >
                                                {!disabled && (
                                                  <DragIndicator
                                                    onMouseDown={handleDragHandleOption}
                                                    onMouseUp={handleDragHandleOptionRemove}
                                                    id="option-dragIndicator"
                                                  />
                                                )}
                                                {renderOptionTypeIcon(quest.answerType)}
                                              </div>
                                              <OptionStyledContainer>
                                                <OptionNameContainer>
                                                  <CustomSelect
                                                    placeholder={`Option - ${idx + 1}`}
                                                    value={optn}
                                                    options={allAssessmentOptions}
                                                    getOptionValue={(opt) => opt?.id}
                                                    getOptionLabel={(opt) => opt?.name}
                                                    disabled={disabled}
                                                    onChange={(selectValue) =>
                                                      handleReplaceOptionSelect(
                                                        questionIndex,
                                                        idx,
                                                        selectValue,
                                                      )
                                                    }
                                                  />
                                                </OptionNameContainer>
                                                <OptionsComponents
                                                  optn={optn}
                                                  handleChange={(e) =>
                                                    handleChangeOption(questionIndex, idx, e)
                                                  }
                                                  handleRemove={() =>
                                                    removeOption(questionIndex, idx)
                                                  }
                                                  idx={idx}
                                                  disabled={disabled}
                                                  showName={false}
                                                />
                                              </OptionStyledContainer>
                                            </OptionsContainer>
                                          ))}
                                      </div>

                                      <div className="d-flex justify-content-end align-items-center mt-3">
                                        {!disabled && (
                                          <Tooltip title="Add Option">
                                            <IconButton
                                              onClick={() => handleAddOption(questionIndex)}
                                            >
                                              <Add />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-12">
                                    <CFormLabel htmlFor={`hint-${questionIndex}`}>Hints</CFormLabel>
                                    <CustomCreatableSelect
                                      isMulti
                                      isLoading={loadingHints}
                                      isDisabled={disabled}
                                      id={`hint-${questionIndex}`}
                                      value={quest.hints?.map((hint) => ({
                                        value: hint,
                                        label: allHints.find((hintOption) => hintOption.id === hint)
                                          ?.internalTitle,
                                      }))}
                                      options={allHints.map((hintOption) => ({
                                        ...hintOption,
                                        value: hintOption.id,
                                        label: hintOption.internalTitle,
                                      }))}
                                      menuPosition="fixed"
                                      menuPlacement="auto"
                                      onCreateOption={(input) => createHint(input, questionIndex)}
                                      onChange={(selectedValue) =>
                                        handleHintsChange(selectedValue, questionIndex)
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </CCardBody>
                          </Collapse>
                        </QuestionCard>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                {!disabled && (
                  <Button color="primary" onClick={() => handleAddQuestion(id)} sx={{ mt: 1 }}>
                    <Add />
                  </Button>
                )}
              </JournalQuestionsBodyCard>
            )}
          </Droppable>
        </JournalQuestionCard>
      </DragDropContext>
    </CCol>
  )
}

function renderOptionTypeIcon(optionType) {
  const iconStyles = { opacity: 0.3 }
  switch (optionType) {
    case 'option-select-multiple':
      return <CheckBoxOutlineBlank sx={iconStyles} />
    case 'option-select-single':
      return <RadioButtonUnchecked sx={iconStyles} />
    default:
      return <></>
  }
}

const optionTypeOptions = [
  { label: 'Multiple Choice', value: 'option-select-single' },
  { label: 'Checkboxes', value: 'option-select-multiple' },
  { label: 'Text', value: 'textBox' },
]

function GrowOnNotDisabled({ disabled, elements }) {
  return <Grow in={!disabled}>{elements}</Grow>
}

export default React.memo(MainJournalCard)

export { optionTypeOptions }
MainJournalCard.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  completeData: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  addFunction: PropTypes.func.isRequired,
  removeFunction: PropTypes.func.isRequired,
  colSize: PropTypes.number,
  disabled: PropTypes.bool,
  menuAnchor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.instanceOf(Element),
  ]),
  setMenuAnchor: PropTypes.func.isRequired,
  createHint: PropTypes.func.isRequired,
  allHints: PropTypes.array.isRequired,
  loadingHints: PropTypes.bool,
  apiParam: PropTypes.string,
  assessmentDomains: PropTypes.array,
  allAssessmentOptions: PropTypes.array,
}

GrowOnNotDisabled.propTypes = {
  disabled: PropTypes.any,
  elements: PropTypes.any,
}
