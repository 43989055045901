import { Button } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { defaultConfirmationModalProps } from 'src/components/controlCenter/ConfirmationModal'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { DefaultHeaders, apiURL } from 'src/services'

export default function CacheEvictHeaderFirstLayer({ setConfirmationModalProps }) {
  async function evictAllCache() {
    const tst = toast.loading('Evicting all cache...', { autoClose: false })
    try {
      const res = await axios.get(`${apiURL}/api/secure/cc/cache/evict-all`, {
        headers: { ...DefaultHeaders() },
      })
      if (res.status === 200) {
        toast.update(tst, {
          ...TOAST_UTILS,
          isLoading: false,
          render: 'All Cache evicted',
          type: 'success',
          autoClose: 3000,
        })
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  function handleClick() {
    setConfirmationModalProps({
      ...defaultConfirmationModalProps,
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      action: 'evict',
      body: 'Are you sure you want to evict all cache?',
      buttonColor: 'warning',
      onSubmitFunctions: [() => evictAllCache()],
    })
  }

  return (
    <>
      <div style={{ width: '300px' }} className="d-flex align-items-end">
        <Button onClick={handleClick}>Evict All Cache</Button>
      </div>
    </>
  )
}
