import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CSmartTable,
} from '@coreui/react-pro'
import { MoreVert } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import moment from 'moment/moment'
import { PropTypes } from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateCollectedUsers } from 'src/reducers/appSettings/services/users'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { TableImageComponent } from 'src/views/settings/components/TableImage'
import ListingComponentFilterBar from './ListingComponentFilterBar'
import './listingComponentStyles.css'
import { ListingComponentContainer, getListingComponentStyles } from './styledComponents'

export default function CCExtendedCSmartTable({
  itemsPerPageOptions,
  addButtonFunction,
  items: propItems,
  externalFilters,
  externalComponents,
  externalUnstyledComponents,
  columns: columnsFromProps = [],
  scopedColumns: scopedColumnsFromProps,
  noItemsLabel,
  handleEnable,
  handleViewClick,
  handleFormViewClick,
  excludeGenericFields,
  customActions = [],
  noUserNames = false,
  loading,
  editLabelChanger,
  enabledParam,
  pagination,
  tableTagProps,
  viewOnly,
  ActionButtonWrapper,
  ...tableProps
}) {
  const items = useMemo(() => {
    return propItems
  }, [propItems])

  // const [availableUsernames, setAvailableUserNames] = useState({})
  const [tableItems, setTableItems] = useState([])

  const defaultColumnsToUse = useMemo(() => {
    const columnsToReturn = CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS.filter((col) => {
      const externalExclude = excludeGenericFields
        ? !!excludeGenericFields?.find((fieldId) => fieldId === col.key)
        : false
      return !externalExclude
    })

    const modifiedTest = {
      [CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime]: !!columnsToReturn.find(
        (col) => col.key === CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
      ),
      [CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy]: !!columnsToReturn.find(
        (col) => col.key === CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
      ),
    }

    if (modifiedTest?.modifiedBy && modifiedTest?.modifiedTime) {
      return [
        ...(columnsToReturn?.filter(
          (col) =>
            col.key !== CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime &&
            col.key !== CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy &&
            col.key !== CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ) || []),
        MODIFIED_DETAILS_COLUMN,
        ...(columnsToReturn?.filter(
          (col) => col.key === CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ) || []),
      ] // here the actions logic and the last filter is to make sure that the actions column is always the last column in the table
    }

    return columnsToReturn
  }, [excludeGenericFields])

  const finalColumns = useMemo(() => {
    var joinedColumns = []

    if (excludeGenericFields?.includes(CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS?.[0]?.key)) {
      joinedColumns = [
        ...columnsFromProps?.filter(
          (col) => !defaultColumnsToUse.find((defCol) => defCol.key === col.key),
        ),
        ...defaultColumnsToUse?.filter((defCol, index) => {
          return defCol.key !== defaultColumnsToUse.lastItem.key
        }),
        ...(defaultColumnsToUse?.lastItem ? [defaultColumnsToUse?.lastItem] : []),
      ]
    } else {
      joinedColumns = [
        defaultColumnsToUse[0],
        ...columnsFromProps?.filter(
          (col) => !defaultColumnsToUse.find((defCol) => defCol.key === col.key),
        ),
        ...defaultColumnsToUse?.filter((defCol, index) => {
          return (
            defCol.key !== defaultColumnsToUse.lastItem.key &&
            defCol.key !== defaultColumnsToUse[0].key
          )
        }),
        ...(defaultColumnsToUse?.lastItem ? [defaultColumnsToUse?.lastItem] : []),
      ]
    }
    if (
      defaultColumnsToUse?.some(
        (col) =>
          col?.key === CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS?.modifiedBy ||
          col?.key === CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS?.modifiedTime,
      )
    ) {
    }
    return joinedColumns
  }, [defaultColumnsToUse, columnsFromProps]) //exclude genericfield is not in the dependency array because defaultColumnsToUse
  const [store, appSettingsDispatch] = useAppSettingsStore()
  const dispatch = useDispatch()

  let foundUsers = store?.collectedUsers || {}
  const tableLoading = store?.loadingCollectedUsers || loading

  async function getDataFromApi() {}

  async function getUserNames(items) {
    const filteredItems = items?.filter((item) => !!item.modifiedBy).map((item) => item.modifiedBy)
    const noDuplicates = Array.from(new Set(filteredItems))

    const usersToFind = noDuplicates?.filter((userId) => !foundUsers?.[userId])
    await dispatch(updateCollectedUsers(usersToFind))
  }

  async function Setup() {
    var promises = [getDataFromApi()]
    if (!noUserNames) promises.push(getUserNames(items))
    await Promise.all(promises)
    setTableItems(
      (items || [])?.map((itm) => {
        var item = { ...itm }
        finalColumns.forEach((col) => {
          if (item?.[col.key] === undefined) {
            item = { ...item, [col.key]: null }
          }
        })
        return {
          ...item,
          modifiedTime: item?.modifiedTime || null,
          modifiedBy: item?.modifiedBy || null,
          _props: {
            ...item._props,
            className: `odd:bg-row1 even:bg-row2 dark:odd:bg-row1Dark listing-font-medium dark:even:bg-row2Dark ${
              item._props?.className || ''
            } ${
              enabledParam !== undefined && !item?.[enabledParam]
                ? 'row-item-disabled'
                : 'row-item-enabled'
            }`,
            style: {
              ...getListingComponentStyles(item?.[enabledParam] || false),
              ...item._props?.style,
            },
            // onMouseEnter: () => {
            //   console.log('mouseEnter')
            // },
            // onMouseLeave: () => {
            //   console.log('mouseEnter')
            // },
            _cellProps: {
              title: { rowSpan: 3 },
              action: { rowSpan: 3 },
            },
          },
        }
      }) || [],
    )
  }

  useEffect(() => {
    Setup()
  }, [items])

  return (
    <>
      <ListingComponentContainer className="tw-bg-card dark:tw-bg-signUpbgDark tw-border-bottom tw-rounded-xl tw-shadow overflow-hidden">
        {(addButtonFunction ||
          externalUnstyledComponents ||
          externalComponents ||
          externalFilters) && (
          <ListingComponentFilterBar
            addButtonFunction={addButtonFunction}
            components={externalComponents}
            filters={externalFilters}
          >
            {externalUnstyledComponents && externalUnstyledComponents()}
          </ListingComponentFilterBar>
        )}
        <div
          style={{
            overflowX: 'auto',
            // maxHeight: 'calc(100vh - var(--header-height-with-margin))'
          }}
        >
          <CSmartTable
            {...tableProps}
            items={tableItems}
            columns={finalColumns}
            itemsPerPageOptions={
              pagination
                ? itemsPerPageOptions
                  ? itemsPerPageOptions
                  : [50, 100, 200, 400]
                : [Number.MAX_SAFE_INTEGER]
            }
            tableHeadProps={{
              color: 'dark',
              className: 'listing-table-head',
            }}
            loading={loading || tableLoading}
            noItemsLabel={noItemsLabel ? noItemsLabel : 'No Items'}
            itemsPerPage={
              pagination
                ? Math.min(...(itemsPerPageOptions ? itemsPerPageOptions : [50, 100, 200, 400]))
                : Number.MAX_SAFE_INTEGER
            }
            paginationProps={
              pagination ? { ...(tableProps.paginationProps || {}), align: 'center' } : null
            }
            scopedColumns={{
              ...scopedColumnsFromProps,
              ...defaultScopedColumns(
                handleEnable,
                handleViewClick,
                handleFormViewClick,
                foundUsers,
                customActions,
                editLabelChanger,
                scopedColumnsFromProps,
                ActionButtonWrapper,
              ),
            }}
            tableBodyProps={{
              className: 'smart-table-body',
            }}
            tableProps={{
              ...tableTagProps,
              borderless: true,
              striped: true,
              align: 'middle',
              className: 'listing-table',
            }}
          />
        </div>
      </ListingComponentContainer>
    </>
  )
}

CCExtendedCSmartTable.propTypes = {
  addButtonFunction: PropTypes.func,
  items: PropTypes.array,
  itemsPerPageOptions: PropTypes.array,
  externalFilters: PropTypes.object,
  externalComponents: PropTypes.object,
  externalUnstyledComponents: PropTypes.func,
  columns: PropTypes.array,
  scopedColumns: PropTypes.object,
  handleEnable: PropTypes.func,
  handleViewClick: PropTypes.func,
  handleFormViewClick: PropTypes.func,
  noItemsLabel: PropTypes.string,
  customActions: PropTypes.array,
  loading: PropTypes.bool,
  excludeGenericFields: PropTypes.array,
  editLabelChanger: PropTypes.string,
}

const CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS = [
  { key: 'title', label: 'Title', filter: false, sorter: true, _style: { minWidth: '330px' } },
  {
    key: 'modifiedTime',
    label: 'Modified Time',
    filter: false,
    sorter: true,
    _style: { minWidth: '200px' },
  },
  {
    key: 'modifiedBy',
    label: 'Modified By',
    filter: false,
    sorter: true,
    _style: { minWidth: '200px' },
  },
  {
    key: 'actions',
    label: 'Actions',
    filter: false,
    sorter: false,
    _style: { minWidth: '50px' },
  },
]

const MODIFIED_DETAILS_COLUMN = {
  key: 'modifiedDetails',
  label: 'Modified Details',
  filter: false,
  sorter: true,
  _style: { minWidth: '200px' },
}

export const CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS = {
  title: 'title',
  modifiedTime: 'modifiedTime',
  modifiedBy: 'modifiedBy',
  actions: 'actions',
}

function defaultScopedColumns(
  handleEnable,
  handleViewClick,
  handleFormViewClick,
  availableUsernames,
  customActions,
  editLabelChanger,
  inputtedScopedColumns,
  ActionButtonWrapper = React.Fragment,
) {
  return {
    title: !!inputtedScopedColumns?.title
      ? inputtedScopedColumns?.title
      : (item) => (
          <td className="py-2">
            <div>{item?.title || item.name || item.internalTitle}</div>
          </td>
        ),
    coverIcon: !!inputtedScopedColumns?.coverIcon
      ? inputtedScopedColumns?.coverIcon
      : (item) => (
          <td className="py-2">
            {item?.coverIcon ? (
              <TableImageComponent
                colors={item?.colors}
                imageId={item?.coverIcon}
                handleClick={() => {}}
              />
            ) : (
              <span>null</span>
            )}
          </td>
        ),
    actions: (item, idx) => {
      const totalActions = [
        ...customActions?.filter((actn) => actn?.showCondition(item)),
        ...[handleViewClick, handleFormViewClick, handleEnable]?.filter((func) => !!func),
      ]

      return (
        <td className="py-2 table-actions">
          <div onClick={(e) => e.stopPropagation()}>
            {totalActions?.length > 0 && (
              <CDropdown variant="dropdown">
                <CDropdownToggle custom={true}>
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                </CDropdownToggle>
                <CDropdownMenu>
                  {handleViewClick && (
                    <CDropdownItem
                      onClick={() => {
                        const { _props, ...cleanedItem } = item
                        handleViewClick(cleanedItem, idx)
                      }}
                    >
                      {editLabelChanger || 'Edit'}
                    </CDropdownItem>
                  )}
                  {handleFormViewClick && (
                    <CDropdownItem
                      onClick={() => {
                        const { _props, ...cleanedItem } = item
                        handleFormViewClick(cleanedItem, idx)
                      }}
                    >
                      View
                    </CDropdownItem>
                  )}
                  {handleEnable && (
                    <CDropdownItem
                      onClick={() => {
                        handleEnable(item)
                      }}
                    >
                      {!item.enabled ? 'Enable' : 'Disable'}
                    </CDropdownItem>
                  )}
                  {customActions
                    ?.filter((actn) =>
                      !!actn?.showCondition ? actn?.showCondition(item, idx) : true,
                    )
                    ?.map((actn, actnIdx) => {
                      const finalLabel =
                        typeof actn?.label === 'function' ? actn?.label(item, idx) : actn?.label
                      return (
                        <CDropdownItem
                          key={idx}
                          onClick={() => {
                            actn?.onClick(item, idx)
                          }}
                        >
                          {finalLabel || 'Error'}
                        </CDropdownItem>
                      )
                    })}
                </CDropdownMenu>
              </CDropdown>
            )}
          </div>
        </td>
      )
    },
    modifiedBy: (item) => {
      return <ModifiedByComponent item={item} availableUsernames={availableUsernames} />
    },
    modifiedTime: (item) => {
      return <ModifiedTimeComponent item={item} />
    },
    modifiedDetails: (item) => {
      return (
        <td className="py-2">
          <div className="d-flex flex-column gap-0">
            <ModifiedByComponent item={item} availableUsernames={availableUsernames} />
            <ModifiedTimeComponent item={item} />
          </div>
        </td>
      )
    },
  }
}
//  <ActionButtonWrapper item={item} idx={idx}>
//             </ActionButtonWrapper>
function ModifiedTimeComponent({ item }) {
  const localTime = moment.utc(item.modifiedTime).local()
  const formattedTime = item.modifiedTime
    ? localTime.format(MOMENT_FORMATS.completeTimeDetails)
    : 'No Data'
  return <td className="py-2">{formattedTime}</td>
}

function ModifiedByComponent({ item, availableUsernames }) {
  return (
    <td className="py-2">
      {availableUsernames?.[item.modifiedBy] || item?.modifiedBy || 'No Data for modified by user'}
    </td>
  )
}
