import { getVideoFileMetaData, giveMediaTypeBasedOnMimeType } from '../../utilities/generalUtils'

export const TRACK_UPLOAD_API_URLS = {
  s3: '/api/media/file/upload/s3/complete',
}

export async function handleResults(result) {
  if (result.failed.length === 0) {
    const successfulFile = result.successful?.[0]
    const response = successfulFile?.response
    const s3MultiPart = successfulFile?.s3Multipart
    const uploadedRawFile = successfulFile?.data
    let videoDuration = 0
    let videoWidth = 0
    let videoHeight = 0
    let contentType = successfulFile.type
    let objectKey = s3MultiPart?.key
    let fileSize = successfulFile.size
    let accessLevel = 'private'
    let type = giveMediaTypeBasedOnMimeType(successfulFile.type)
    let fileName = successfulFile.name

    const setVideoDuration = (duration) => {
      videoDuration = duration
    }
    const setVideoWidth = (width) => {
      videoWidth = width
    }
    const setVideoHeight = (height) => {
      videoHeight = height
    }

    await getVideoFileMetaData(uploadedRawFile)
      .then(({ duration, width, height }) => {
        // handleChange({ target: { id: 'duration', value: duration } })
        setVideoDuration(duration)
        setVideoWidth(width)
        setVideoHeight(height)
      })
      .catch((error) => {
        console.error(error)
      })
    console.log({
      response,
      s3MultiPart,
      uploadedRawFile,
      videoDuration,
      videoWidth,
      videoHeight,
      successfulFile,
    })
    return {
      accessLevel,
      contentType,
      duration: videoDuration,
      fileSize,
      height: videoHeight,
      objectKey,
      type,
      width: videoWidth,
      fileName,
    }
  }
  return null
}
