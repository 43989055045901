import moment from 'moment'
import React from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import NormalListingTableHandler from '../../NormalListingTableHandler'

export default function UserSegments({ userId, data, setData }) {
  return (
    <NormalListingTableHandler
      apiCallFunction={GetSettingsData}
      apiCallParams={{ userId: userId, type: 'track', size: 200 }}
      apiCallUrl={USERS_API_SIGNATURES_MAP.devicesAndExperience.segmentHistory.get}
      data={data}
      setData={setData}
      setupDataDependencies={[userId]}
    >
      <CCExtendedCSmartTable
        noUserNames
        items={data?.data}
        loading={data?.status === 'loading'}
        columns={columns}
        scopedColumns={scopedColumns}
        excludeGenericFields={[
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ]}
      />
    </NormalListingTableHandler>
  )
}

const columns = [
  { key: 'segmentId', label: 'Segment Id', sorter: false, filter: false },
  // { key: 'teacher', label: 'Teacher', sorter: false, filter: false },
  { key: 'segmentName', label: 'Segment Name', sorter: false, filter: false },
  { key: 'segmentDescription', label: 'Segment Description', sorter: false, filter: false },
  { key: 'createdTime', label: 'Created Time', sorter: false, filter: false },
]

const scopedColumns = {
  createdTime: (item) => {
    return (
      <td>
        {item?.createdTime
          ? moment(item?.createdTime).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)
          : '--'}
      </td>
    )
  },
  segmentDescription: (item) => {
    return <td>{item?.segmentDescription || '--'}</td>
  },
}
