import { CFormInput } from '@coreui/react-pro'
import { Close } from '@mui/icons-material'
import { Box, Button, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import NormalSectionWrapper from 'src/components/controlCenter/common/sectionComponents/NormalSectionWrapper'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import {
  ALL_AVAILABLE_PLATFORMS,
  ALL_AVAILABLE_PLATFORMS_ARRAY,
  ALL_GENDERS_IN_APP_MSGS,
  SUPPORTED_DEVICE_TYPE,
} from 'src/utilities/constants'
import { genericHandleChange } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

export default function SegmentTester({ listingData, handleClose, customOptions }) {
  // setStatefunction
  const [storeData] = useAppSettingsStore()
  const [segmentTestData, setSegmentTestData] = useState()
  const [subscriptionData, setSubscriptionData] = useState()
  const [resp, setResp] = useState()
  const [loading, setLoading] = useState(false)
  const [deviceTypeStatus, setDeviceTypeStatus] = useState(true)

  function handleFormSubmit() {
    setLoading(true)
    PostSettingsData('segment/test', segmentTestData, {}, undefined, true)
      .then((response) => {
        setResp(response?.data)
        setLoading(false)
        console.log(response?.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function handleChangeObject(e) {
    genericHandleChange(e, setSegmentTestData)
  }
  function openSegmentInNewTab(segmentItem, tab) {
    console.log('handelActionMenuClick', segmentItem)
    const baseRoutes = '/app-behavior/segment'
    const url = `${window.location.origin}${baseRoutes}/${segmentItem?.id}?mode=${tab}`
    window.open(url, '_blank')
    // http://localhost:3000/app-behavior/segment/SGM-5005198b37ea4d479d65e760edb6c876?mode=View
  }

  useEffect(() => {
    if (!segmentTestData?.platform) {
      ///meaning if platform is empty
      setDeviceTypeStatus(false)
    }

    if (segmentTestData?.platform === ALL_AVAILABLE_PLATFORMS.ios) {
      setDeviceTypeStatus(false)
    } else {
      setSegmentTestData((prev) => {
        return {
          ...prev,
          deviceType: [],
        }
      })

      setDeviceTypeStatus(true)
    }
  }, [segmentTestData?.platform])

  return (
    <>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div>Segment Tester</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="countryCode"
              value={segmentTestData?.countryCode}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              options={storeData?.countries}
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'User Country'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="gender"
              value={segmentTestData?.gender}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              options={ALL_GENDERS_IN_APP_MSGS}
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'Gender'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="appLanguage"
              value={segmentTestData?.appLanguage}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              options={storeData?.languages}
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'App Language'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="deviceLanguage"
              value={segmentTestData?.deviceLanguage}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              options={storeData?.languages}
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'Device Language'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              isMulti
              id="contentLanguages"
              value={segmentTestData?.contentLanguages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              options={storeData?.languages}
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'Content Language'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="subscriptionId"
              value={segmentTestData?.subscriptionId}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              options={customOptions?.allPlanLevels}
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'Subscription'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="onboardingType"
              value={segmentTestData?.onboardingType}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title || option?.name} //change it
              options={(customOptions?.onboardingTypes || [])?.sort((a, b) =>
                a.title.localeCompare(b.title),
              )} //change it
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'Onboarding Type'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormInput
              id="age"
              label="Age"
              labelPlacement="top"
              value={segmentTestData?.age}
              onChange={handleChangeObject}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="platform"
              value={segmentTestData?.platform}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              options={ALL_AVAILABLE_PLATFORMS_ARRAY}
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'Platform'}
              isClearable
            />
          </Grid>
          {!deviceTypeStatus && (
            <Grid item xs={12} md={6}>
              <CustomReactSelect
                isMulti
                id="deviceType"
                value={segmentTestData?.deviceType}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.title}
                options={SUPPORTED_DEVICE_TYPE}
                disabled={deviceTypeStatus}
                selectType={selectTypes.string}
                onChangeEvent={handleChangeObject}
                label={'Device Type'}
                isClearable
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <CFormInput
              id="appVersion"
              label="App Version"
              labelPlacement="top"
              value={segmentTestData?.appVersion}
              onChange={handleChangeObject}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              isMulti
              id="authorities"
              value={segmentTestData?.authorities}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title || option?.name} //change it
              options={(customOptions?.allRoles || [])?.sort((a, b) =>
                a.title.localeCompare(b.title),
              )} //change it
              disabled={false}
              selectType={selectTypes.string}
              onChangeEvent={handleChangeObject}
              label={'Authorities'}
              labelPlacement={'top'}
              isClearable
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
              Test Segment
            </Button>
          </Grid>
        </Grid>
        <NormalSectionWrapper>
          <CCExtendedCSmartTable
            items={resp}
            loading={loading}
            excludeGenericFields={[CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy]}
            // handleFormViewClick={() => {}}
            handleFormViewClick={(e) => openSegmentInNewTab(e, 'View')}
            handleViewClick={(e) => openSegmentInNewTab(e, 'Edit')}
            // handleViewClick={() => {}}
          />
        </NormalSectionWrapper>
      </DialogContent>
    </>
  )
}
