import { dlGetExtraItemTitlesForTypeMetaCatAndItemId } from 'src/utilities/helpers/linksMakerUtils/utils'

export async function getExtraItemTitleForTypeId(
  itemId,
  metaCategoryId,
  extraItemId,
  extraItemCategoryType,
  extraItemIdType,
  baseItem,
) {
  console.log('fetching extra item title for type id')

  const responseData = await dlGetExtraItemTitlesForTypeMetaCatAndItemId(
    itemId,
    metaCategoryId, //this is actually the metaCategoryType
    extraItemId,
    extraItemCategoryType,
    extraItemIdType,
    baseItem,
  )
  return responseData
}
