import { toast } from 'react-toastify'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetData } from 'src/services/APIs/DailyPlay/GetLists'
import { GetFeatureData } from 'src/services/APIs/Features/GetApi'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import store from 'src/store'
import { FEATURES_API } from 'src/views/features/utils/api-utils'
import {
  DISPLAY_MESSAGE_TEMPLATES_OBJECT,
  SENTINEL_CARD_OBJECT,
} from 'src/views/settings/utils/display-message-template'
import { APIs } from './components/api-utils'

export const DAILY_PLAY_OBJECT = {
  apiParam: 'daily-play',
  allDailyFeatureTitle: '',
  color: '',
  contestId: '',
  countryCode: '',
  dailyFeatureTitle: '',
  date: '',
  deleted: true,
  endTime: '',
  heroItem: {},
  id: '',
  langCode: '',
  otherItems: [],
  publishAt: '',
  quotes: [],
  sectionSettings: {},
  startTime: '',
  status: 'draft',
  timeSlot: 'fullday',
  title: '',
}

export const DAILY_FEATURE_OBJECT = {
  altText: '',
  coins: 0,
  itemId: '',
  onlineCourse: {},
  type: '',
}

//Object Utils

export const dailyPlayNavClasses = 'bg-light dark:bg-dark rounded-md'
export const dailyPlayNavProps = {
  wrapped: true,
  sx: { width: '120px' },
}
export const dndFabStyles = {
  position: 'sticky',
  bottom: 0,
}

//HTML Classes,styles,props

export function getCalendarTitle(timeSlots, actualEvent, cards) {
  return `${
    timeSlots.find((timeSlot) => {
      return timeSlot.id === actualEvent?.timeSlot
    })?.title
  }: ${cards.length} Cards`
}

function getDate(date, timeSlot, type = 'start') {
  if (type === 'start') {
    return serverDatetoJsDate(date.split(' ')?.[0] + ' ' + timeSlot?.startTime)
  } else {
    let dateObject = serverDatetoJsDate(date.split(' ')?.[0] + ' ' + timeSlot?.endTime)
    let startDate = serverDatetoJsDate(date.split(' ')?.[0] + ' ' + timeSlot?.startTime)
    let nextDay = new Date(dateObject)
    nextDay.setDate(dateObject.getDate() + 1)
    if (dateObject < startDate) {
      return nextDay
    }
    return dateObject
  }
}

export function createCalendarEvents(responseEvents, timeSlots) {
  return responseEvents?.map((event) => {
    var eventTimeSlot = timeSlots.find((slot) => {
      if (event.timeSlot === 'anytime') {
        return slot.id === 'fullday'
      }
      return slot.id === event.timeSlot
    })

    const startDate = getDate(event.date, eventTimeSlot, 'start')
    const endDate = getDate(event.date, eventTimeSlot, 'end')
    return {
      ...event,
      allDay: eventTimeSlot?.id === 'fullday' ? true : false,
      eventDate: event?.date,
      backgroundColor: eventTimeSlot?.color || '#efefef',
      start: startDate,
      timeSlot: event.timeSlot === 'anytime' ? 'fullday' : event.timeSlot,
      end: endDate,
      eventColor: event?.color,
    }
  })
}

//GENERAL UTILS

function toIsoString(date) {
  var tzo = -date?.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num
    }

  return (
    date?.getFullYear() +
    '-' +
    pad(date?.getMonth() + 1) +
    '-' +
    pad(date?.getDate()) +
    'T' +
    pad(date?.getHours()) +
    ':' +
    pad(date?.getMinutes()) +
    ':' +
    pad(date?.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  )
}

export function formatDate(date) {
  let formattedDate = toIsoString(date)?.split('T')?.[0] + ' 00:00:00'
  return formattedDate
}

export function getOnlyDate(date) {
  let mainDate = new Date(date)
  return mainDate.toDateString()
}

export function serverDatetoJsDate(date) {
  const jsStringDate = date.split(' ')?.[0] + 'T' + date.split(' ')?.[1]
  const returnDate = new Date(jsStringDate)
  return returnDate
}

export function jsToServerDate(date) {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }

  const formattedDate = date.toLocaleString('en-US', options).replace(',', '')

  return formattedDate
}

export function getCurrentDateAndTime() {
  const date = new Date()
  let nowDate = toIsoString(date).split('T')?.[0] + ' ' + date?.toTimeString().split(' ')?.[0]
  return nowDate
}

export function timeSlotTimeGetter(slot) {
  return (
    ' (' +
    [slot.timeSlot?.startTime.split(':')?.[0], slot.timeSlot?.startTime.split(':')?.[1]].join(':') +
    ' - ' +
    [slot.timeSlot?.endTime.split(':')?.[0], slot.timeSlot?.endTime.split(':')?.[1]].join(':') +
    ')'
  )
}

//DATE UTILITIES
export function getCategoryValue(selectedCategory, categories) {
  const foundCategory = categories?.find((category) => {
    return category.id === selectedCategory
  })
  return foundCategory
}

//Category Utils

export function dayEventsMaker(
  timeSlots,
  selectInfo,
  eventsToday,
  selectedDate,
  selectedEndDate,
  playSettings,
  selectedLanguage,
  selectedCountry,
) {
  let newObject = {
    ...DAILY_PLAY_OBJECT,
    title: 'DP - Full day',
    startTime: formatDate(selectedDate),
    endTime: formatDate(selectedEndDate),
    dailyFeatureTitle: playSettings?.dailyFeature?.altText?.[selectedLanguage.id],
    allDailyFeatureTitle: '',
    countryCode: selectedCountry?.id,
    date: formatDate(selectedDate),
    langCode: selectedLanguage?.id,
    timeSlot: 'fullday',
    status: 'draft',
    heroItem: {},
    publishAt: formatDate(selectedDate),
    sectionSettings: playSettings.sectionSettings,
    eventDate: formatDate(selectedDate),
  }
  return timeSlots?.map((time) => {
    let startTime = new Date(
      [selectInfo?.startStr?.split('T')?.[0], time?.timeSlot?.startTime].join(' '),
    )
    let endTime = new Date(
      [selectInfo?.startStr?.split('T')?.[0], time?.timeSlot?.endTime].join(' '),
    )

    return eventsToday?.some((event) => {
      return event.timeSlot === time.id
    })
      ? eventsToday?.find((event) => event.timeSlot === time.id)
      : {
          ...newObject,
          title: 'DP - ' + time?.title,
          start: startTime,
          end: endTime,
          timeSlot: time?.id,
          completedSlotMsg: time?.completedSlotMsg || DISPLAY_MESSAGE_TEMPLATES_OBJECT,
          emptySlotMsg: time?.emptySlotMsg || DISPLAY_MESSAGE_TEMPLATES_OBJECT,
          sentinelCard: time?.sentinelCard || SENTINEL_CARD_OBJECT,
        }
  })
}

export function courseHeaderTitleMaker(course) {
  return course?.episode?.sessionId
    ? '(' +
        course?.episode?.duration / 60000 +
        ' mins) ' +
        course?.course?.createdForName +
        ' - ' +
        course?.course?.internalTitle
    : 'Not Set'
}

export function modalSubTitle(selectedLanguage, selectedCountry, whiteListedCountries) {
  const whiteListed = whiteListedCountries
    .filter((whiteListed) => whiteListed.id !== 'row')
    .map((whiteListed) => whiteListed.name)
  return `Only visible to ${selectedLanguage?.language} Users in
  ${
    selectedCountry?.id === 'row'
      ? `the world except ${whiteListed?.join(', ')}`
      : selectedCountry?.name
  }`
}

//Modal Utils

export function backgroundStylesDndItem(index, selectedCardIndex, selectedCard) {
  if (selectedCard?.title === '' || selectedCard?.title === undefined) {
    return 'bg-danger' //BG-NO-ITEM-SELECTEDCARD
  }
  return 'shadow-md bg-[rgb(0,0,0,0.4)] hover:shadow-lg text-white' //BG-NOT-SELECTED-NON-HEROCARD
}

//DND CARD UTILS

export async function getOptions(
  type,
  category,
  contentlanguages,
  pageSize,
  currentPage,
  search,
  useCourseIdWithSessionId = false,
) {
  const languages = contentlanguages?.map((lang) => lang?.id)
  const params = {
    languages: languages?.join(','),
    size: pageSize,
    page: currentPage,
    search: search,
    includeDisabled: false,
  }
  let toReturn = []
  switch (type) {
    case 'course':
      const courseResponse = await GetData(APIs.episodes.url, {
        includeDisabled: false,
        category: category,
        language: languages.join(','),
      })
      if (courseResponse.status === 200) {
        toReturn = {
          content: courseResponse.data
            ?.filter((course) =>
              search !== ''
                ? course?.episode.title.toLowerCase().includes(search?.toLowerCase())
                : true,
            )
            .map((course) => {
              return {
                ...course,
                value: useCourseIdWithSessionId ? course?.course?.id : course.sessionId,
                label: `${course?.course.createdForName} - ${course?.episode.title}`,
              }
            }),
          last: true,
          number: 1,
          pageable: { pageSize: courseResponse.data?.length },
        }
      }
      break
    case 'breathe':
      const breatheResponse = await GetData(APIs.breathePrograms.url, {
        ...params,
        type: 'breathe',
      })
      if (breatheResponse.status === 200) {
        toReturn = {
          content: breatheResponse.data
            ?.filter((breathe) => {
              return breathe.enabled && breathe.translations?.[languages?.[0]]?.title
            })
            ?.map((breathe) => {
              return {
                ...breathe,
                value: breathe.id,
                label: breathe.name,
                subscriptionId: breathe.subscription?.id,
              }
            }),
          last: true,
          number: breatheResponse.data?.length,
          pageable: { pageSize: breatheResponse.data?.length },
        }
      }
      break
    case 'quote':
      const quoteResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...params,
        entityType: ALL_ENTITY_TYPES.quote,
      })
      if (quoteResponse.status === 200) {
        toReturn = {
          ...quoteResponse?.data,
          content: quoteResponse?.data.content?.map((quote) => {
            return {
              ...quote,
              value: quote?.id,
              label: `${quote?.author} - ${quote?.msg}`,
              subscriptionId: 'subscription_basic',
            }
          }),
        }
      }
      break
    case 'journal':
      const journalResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...params,
        entityType: ALL_ENTITY_TYPES.journal,
        journalType: 'regular',
      })
      if (journalResponse.status === 200) {
        toReturn = {
          ...journalResponse.data,
          content: journalResponse.data.content.map((journal) => {
            return {
              ...journal,
              value: journal.id,
              label: `${journal.title || 'No Title'}`,
              subscriptionId: journal.subscription?.id,
            }
          }),
        }
      }
      break
    case 'practice':
      const practiceResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        ...params,
        entityType: ALL_ENTITY_TYPES.technique,
        sadhana: true,
        status: 'published',
      })
      if (practiceResponse.status === 200) {
        toReturn = {
          ...practiceResponse.data,
          content: practiceResponse.data.content?.map((practice) => {
            return {
              ...practice,
              value: practice?.id,
              label: practice?.title,
              subscriptionId: practice?.subscription?.id,
            }
          }),
        }
      }
      break
    default:
      const defaultResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
        entityType: type,
        ...params,
      })
      if (defaultResponse.status === 200) {
        toReturn = {
          ...defaultResponse.data,
          content: defaultResponse.data.content.map((data) => ({
            ...data,
            value: data?.id,
            label: data?.title || data?.name || data?.internalTitle,
          })),
        }
      }
      break
  }
  return toReturn
}

export async function getReflectionData(id) {
  if (!!id && id !== '' && id !== 'default-journal') {
    const response = await GetFeatureData(FEATURES_API.journals.getSingle.url, { id: id })
    if (response.status === 200) {
      return response.data
    }
  } else {
    return 'default reflection'
  }
}

export async function searchCourseEpisodes(search, allOptionsObject) {
  return {
    ...allOptionsObject,
    content: allOptionsObject.content?.filter((course) =>
      `${course?.course.createdForName} - ${course?.episode.title}`
        .toLowerCase()
        .includes(search?.toLowerCase()),
    ),
  }
}

export function returnLoadOptions(optionsData, contextData = {}) {
  return {
    options: optionsData?.content,
    hasMore: !optionsData.last,
    additional: {
      page: optionsData.number + 1,
      size: optionsData.pageable.pageSize,
      ...contextData,
    },
  }
}

export async function handleDropDown(selectedValue, tempCard, setTempCard, getReflection) {
  if (tempCard.type === 'course') {
    setTempCard((prev) => {
      const { value, label, sessionId, ...rest } = selectedValue
      return {
        ...prev,
        itemId: sessionId,
        onlineCourse: rest,
        title: label,
        extraItem: {},
      }
    })
  } else {
    tempCard.type === 'quote' && getReflection(selectedValue.journalId)
    setTempCard((prev) => {
      return {
        ...prev,
        itemId: selectedValue.value,
        title: selectedValue.label,
        extraItem: selectedValue,
      }
    })
  }
}

export function flushedCard(
  selectedCard,
  selectedCategory,
  orignalCards,
  selectedIndex,
  allCategories,
  timeSlot,
) {
  const foundCategory = allCategories?.find((cat) => cat.id === selectedCategory)
  if (selectedCard.type === 'course') {
    if (
      selectedCard.type === orignalCards?.[selectedIndex]?.type &&
      selectedCategory === orignalCards?.[selectedIndex]?.onlineCourse?.episode?.category?.[0]
    ) {
      return orignalCards?.[selectedIndex]
    }
    return {
      ...selectedCard,
      itemId: '',
      onlineCourse: {},
      title: '',
      altText: foundCategory?.dailyPlayAltText?.[timeSlot] || '',
    }
  } else {
    if (selectedCard.type === orignalCards?.[selectedIndex]?.type) {
      return orignalCards?.[selectedIndex]
    } else {
      return {
        ...selectedCard,
        itemId: '',
        onlineCourse: {},
        title: '',
        altText: foundCategory?.dailyPlayAltText?.[timeSlot] || '',
      }
    }
  }
}

//CARD UTILS

export function checkAllSaved(indCards) {
  let toReturn = true
  Object.entries(indCards).forEach(([key, chngMade]) => {
    if (toReturn) {
      if (chngMade === true) {
        toReturn = false
      }
    }
  })
  return toReturn
}

export function getSaveTooltip(cards) {
  const addFiltered = cards?.filter((card) => card?._new)
  return addFiltered?.length
}

export function validateAllTimeSlotCards(allTimeSlotCards) {
  for (var i in Object.entries(allTimeSlotCards)) {
    if (i?.[1]?.length === 0) return true
  }
  return false
}

export function isEditable(timeSlotEditable) {
  let toReturn = false
  Object.entries(timeSlotEditable)?.forEach(([key, value]) => {
    if (!toReturn) {
      toReturn = value
    }
  })
  return toReturn
}

////CHANGE THESE TO ADD COMPATIBILITY FOR MORE CHANGES

//SAVE UTILS

export function getUpdatedObject(actualEvent, cards, timeSlots) {
  return {
    ...actualEvent,
    sectionSettings: {
      ...actualEvent.sectionSettings,
      history: true,
      heroCard: true,
      otherCards: true,
    },
    allDailyFeatureTitle: actualEvent?.dailyFeatureTitle, //NEED TO CHANGE THIS  THIS IS HARDCODED!!!!
    deleted: false,
    heroItem: cleanCard(cards?.[0]),
    otherItems: cleanOtherItems(cards),
    modifiedDate: getCurrentDateAndTime(),
    publishAt: actualEvent.publishAt,
    status: actualEvent.status,
    title: getCalendarTitle(timeSlots, actualEvent, cards),
  }
}

export function cleanCard(card) {
  const fixedCard = {
    ...card,
    title: card?.title || '',
    onlineCourse: card?.onlineCourse || {},
    _new: card?._new || false,
  }
  var { title, extraItem, onlineCourse, _new, ...cardToDestruct } = fixedCard
  var cardToReturn = cardToDestruct
  switch (card?.type) {
    case 'course':
      cardToReturn = { ...cardToReturn, onlineCourse: onlineCourse }
      break
    case 'mental-state-type':
      cardToReturn = { ...cardToReturn, mentalStateType: extraItem }
      break
    case 'assessment':
      cardToReturn = { ...cardToReturn }
      break
    default:
      break
  }
  return cardToReturn
}

export function cleanOtherItems(allCards) {
  return allCards.length > 1
    ? allCards.slice(1, allCards.length).map((card) => {
        return cleanCard(card)
      })
    : []
}

export function getFinalDayEvents(dayCards, timeSlots, allEvents, saveStatus) {
  let submitObject = []
  let valid = true

  timeSlots.forEach((timeSlot) => {
    if (valid) {
      const toPush = {
        ...getUpdatedObject(
          allEvents?.find((event) => timeSlot.id === event.timeSlot),
          dayCards?.[timeSlot.id],
          timeSlots,
        ),
        status: saveStatus,
      }
      const findUndef = dayCards?.[timeSlot.id].some((tCard) => {
        return tCard.itemId === ''
      })
      if (findUndef) {
        valid = false
      } else {
        if (dayCards?.[timeSlot?.id]?.length !== 0) submitObject.push(toPush)
      }
    }
  })
  return { data: submitObject, valid: valid }
}

export function getFinalTimeSlotEvent(timeSlotCards, currentEvent, saveStatus, timeSlots) {
  const toPush = {
    ...getUpdatedObject(currentEvent, timeSlotCards, timeSlots),
    status: saveStatus,
  }
  const valid = !timeSlotCards.some((tCard) => {
    return tCard.itemId === ''
  })
  if (timeSlotCards.length === 0) {
    toast.warning('You cannot save a slot with no cards!')
    return { data: [], valid: false }
  }
  return { data: toPush, valid: valid }
}

export async function getAndSetCardTitles(card, category = 'meditation') {
  let titleToReturn = card?.title
  let extraItem
  const cardCategoryTypeConvert = {
    practice: ALL_ENTITY_TYPES.technique,
  }
  const typeToUse = cardCategoryTypeConvert?.[card.type] || card.type

  if (!card?.title && card?.itemId !== '') {
    console.log({ initType: typeToUse })
    switch (typeToUse) {
      case 'course':
        titleToReturn = `${card.onlineCourse?.course?.createdForName} - ${card.onlineCourse?.episode?.title}`
        break
      case 'quote':
        const quoteResponse = await GetFeatureData(
          FEATURES_API.quotes.getSingle.url + card.itemId,
          {
            quoteId: card.itemId,
          },
        )
        if (quoteResponse.status === 200) {
          titleToReturn = `${quoteResponse.data.author} - ${quoteResponse.data.msg}`
          extraItem = quoteResponse.data
        }
        break
      case 'breathe':
        const breatheResponse = await GetSettingsData(GENERIC_APIS.entity.details, {
          id: card.itemId,
          entityType: ALL_ENTITY_TYPES.breathe,
        })
        if (breatheResponse.status === 200) {
          const foundBreathe = breatheResponse.data
          titleToReturn = foundBreathe?.name
          extraItem = foundBreathe
        }
        break
      case 'journal':
        const journalResponse = await GetFeatureData(FEATURES_API.journals.getSingle.url, {
          id: card.itemId,
        })
        if (journalResponse.status === 200) {
          titleToReturn = `${journalResponse.data.title}`
          extraItem = journalResponse.data
        }
        break
      default:
        console.log(`fetching default ${typeToUse}`)
        const dataResponse = await GetSettingsData(GENERIC_APIS.entity.details, {
          id: card.itemId,
          entityType: typeToUse,
        })
        if (dataResponse.status === 200) {
          const foundPractice = dataResponse.data
          titleToReturn =
            foundPractice?.title || foundPractice?.name || foundPractice?.internalTitle
          extraItem = foundPractice
        }
        break
    }
  }
  return { ...card, title: titleToReturn, extraItem: extraItem || {} }
}

export async function getDPFullOptionData(fullObject, category) {
  let dataToReturn = fullObject

  switch (category) {
    case 'course':
      return fullObject
    case 'quote':
      const quoteResponse = await GetFeatureData(
        FEATURES_API.quotes.getSingle.url + fullObject.value,
        {
          quoteId: fullObject.itemId,
        },
      )
      if (quoteResponse.status === 200) {
        dataToReturn = { ...quoteResponse.data, value: fullObject?.value, label: fullObject?.label }
      }
      break
    case 'breathe':
      const breatheResponse = await GetFeatureData(FEATURES_API.breathe.getSingle.url, {
        id: fullObject?.value,
      })
      if (breatheResponse.status === 200) {
        const foundBreathe = breatheResponse.data
        dataToReturn = { ...foundBreathe, value: fullObject?.value, label: fullObject?.label }
      }
      break
    case 'journal':
      const journalResponse = await GetFeatureData(FEATURES_API.journals.getSingle.url, {
        id: fullObject?.value,
      })
      if (journalResponse.status === 200) {
        dataToReturn = {
          ...journalResponse.data,
          value: fullObject?.value,
          label: fullObject?.label,
        }
      }
      break

    default:
      const dataResponse = await GetSettingsData(GENERIC_APIS.entity.details, {
        id: fullObject?.value,
        entityType: category,
      })
      if (dataResponse.status === 200) {
        dataToReturn = {
          ...dataResponse.data,
          value: fullObject?.value,
          label: fullObject?.label,
        }
      }
      break
  }
  return dataToReturn
}

export async function makeOrignalCards(
  actualEvent,
  setCards = () => {},
  allCategories,
  timeSlot,
  settingsLanguage,
) {
  let builtCards
  if (actualEvent?.heroItem?.itemId === undefined) {
    builtCards = []
  } else {
    builtCards = await Promise.all(
      [actualEvent?.heroItem || {}, ...(actualEvent?.otherItems || [])].map(async (card) => {
        //making categories alt text
        const foundCategory = allCategories?.find(
          (cat) => cat?.id === dailyPlayTypeToCategory(card.type, card),
        )
        const cardToUpdate = {
          ...card,
          altText:
            card?.altText === '' || card.altText === undefined
              ? foundCategory?.dailyPlayAltText?.[timeSlot] || ''
              : card?.altText || '',
        }

        //made categories alt text
        return await getAndSetCardTitles(
          cardToUpdate,
          cardToUpdate?.type === 'course' && cardToUpdate?.onlineCourse?.episode?.category?.[0],
        )
      }),
    )
  }
  setCards(builtCards)
  return builtCards
}

export async function updateCardDropDown(
  tempCard,
  setFinalCard,
  selectedIndex,
  selectedTimeSlot,
  getReflection,
  mentalStateOptions,
) {
  setFinalCard((prev) => {
    switch (tempCard.type) {
      case 'course':
        return {
          ...prev,
          [selectedTimeSlot]: prev?.[selectedTimeSlot].map((card, index) => {
            const { itemId, title, onlineCourse, ...rest } = tempCard
            return selectedIndex === index
              ? {
                  ...tempCard,
                  coins: parseInt(tempCard.coins ? tempCard.coins : 0),
                  itemId: itemId,
                  onlineCourse: onlineCourse,
                  title: title,
                  extraItem: {},
                }
              : card
          }),
        }
      case 'mental-state-type':
        return {
          ...prev,
          [selectedTimeSlot]: prev?.[selectedTimeSlot].map((card, index) => {
            return selectedIndex === index
              ? {
                  ...tempCard,
                  itemId: tempCard.itemId,
                  coins: parseInt(tempCard.coins ? tempCard.coins : 0),
                  title: tempCard.title,
                  extraItem: { ...tempCard.extraItem, options: mentalStateOptions },
                }
              : card
          }),
        }
      default:
        return {
          ...prev,
          [selectedTimeSlot]: prev?.[selectedTimeSlot].map((card, index) => {
            return selectedIndex === index
              ? {
                  ...tempCard,
                  itemId: tempCard.itemId,
                  coins: parseInt(tempCard.coins ? tempCard.coins : 0),
                  title: tempCard.title,
                  extraItem: tempCard.extraItem,
                }
              : card
          }),
        }
    }
  })
}

export function dailyPlayTypeToDailyFeatureType(playType, selectedCard) {
  if (playType === 'mental-state-type') {
    return playType
  } else {
    return 'daily-feature'
  }
}

export function categoryToDailyPlayType(category, rStore = store.getState().appSettings) {
  const catMap = {
    myQuotes: 'quote',
    myBreathe: 'breathe',
    myJournal: 'journal',
    myPractices: 'practice', //TODO: CHECK WHERE THIS CHANGE IS BREAKING A FEATURE, FIX IT.
    'mental-state-type': 'mental-state-type',
    assessment: 'assessment',
  }
  const featuredCats = rStore.categories.filter((c) => c.categoryType !== 'course')
  const filteredCategories = featuredCats.filter((c) => !catMap?.[c.id])
  const finalCategories = filteredCategories.reduce((newCatMap, category) => {
    return { ...newCatMap, [category.id]: category.id }
  }, catMap)
  return finalCategories?.[category] || 'course'
}

export function dpCategoryToDailyPlayType(category, rStore = store.getState().appSettings) {
  const catMap = {
    myQuotes: 'quote',
    myBreathe: 'breathe',
    myJournal: 'journal',
    myPractices: 'practice',
    'mental-state-type': 'mental-state-type',
    assessment: 'assessment',
  }
  const featuredCats = rStore.categories.filter((c) => c.categoryType !== 'course')
  const filteredCategories = featuredCats.filter((c) => !catMap?.[c.id])
  const finalCategories = filteredCategories.reduce((newCatMap, category) => {
    return { ...newCatMap, [category.id]: category.id }
  }, catMap)
  return finalCategories?.[category] || 'course'
}

export function dailyPlayTypeToCategory(
  playType,
  selectedCard,
  rStore = store.getState().appSettings,
) {
  const catMap = {
    quote: 'myQuotes',
    breathe: 'myBreathe',
    journal: 'myJournal',
    practice: 'myPractices',
    assessment: 'assessment',
  }
  const featuredCats = rStore.categories.filter((c) => c.categoryType !== 'course')
  const filteredCategories = featuredCats.filter((c) => !catMap?.[c.id])
  const finalCategories = filteredCategories.reduce((newCatMap, category) => {
    const found = Object.values(catMap).find((cats) => cats === category.id)
    if (!!found) {
      return { ...newCatMap }
    }
    return { ...newCatMap, [category.id]: category.id }
  }, catMap)
  return (
    finalCategories?.[playType] ||
    (selectedCard?.onlineCourse?.episode?.category?.length >= 0 &&
    selectedCard?.onlineCourse?.episode?.category?.length !== undefined
      ? selectedCard?.onlineCourse?.episode?.category?.[0]
      : 'meditation')
  )
}
