import { CForm } from '@coreui/react-pro'
import { Edit, Send } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, LinearProgress } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import GenericImageForm from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/GenericImageForm'
import { entityFormComponents } from 'src/config/common/genericListingAndFormConfig'
import { transformFetchedEntity } from 'src/config/genericForm/fetch/fetchEntityTransformer'
import { GetDetailedEntity, SaveEntity } from 'src/utilities/apiGetters'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import {
  genericHandleChange,
  genericHandleNestedFields,
  genericHandleNestedNestedFields,
  genericHandleReactSelectChange,
  genericHandleRichTextChange,
} from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

export default function UserStatsDialog({
  data,
  entity,
  handleClose,
  handleRefresh,
  allData,
  customOptions,
  mode,
  toggleMode,
  storeData,
  setChangedData,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const allImageTypes = store.imageTypes
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [formData, setFormDataReact] = useState()
  const [confirmationProps, setConfirmationProps] = useState(defaultConfirmationModalProps)
  const CustomComponent = useMemo(() => {
    return entityFormComponents(entity)?.customComponent || GenericImageForm
  }, [entity])
  const [slugValidation, setSlugValidation] = useState({})
  const [titleValid, setTitleValid] = useState(true)

  function setFormData(data) {
    setFormDataReact(data)
    setChangedData(true)
  }

  async function GetDataSingle() {
    const detailedEntity = await GetDetailedEntity(entity, data)
    await setTransformedFormData(detailedEntity)
  }

  async function setTransformedFormData(data) {
    const transformedData = await transformFetchedEntity(entity, data)
    setFormDataReact(transformedData)
  }

  function handleChangeObject(event) {
    genericHandleChange(event, setFormData)
  }

  function handleReactSelectChange(selectedValue, id) {
    genericHandleReactSelectChange(selectedValue, id, setFormData)
  }

  function handleRichTextChange({ string, raw }) {
    console.log(string)
    console.log(raw)
    genericHandleRichTextChange({ string: string, raw: raw }, setFormData)
  }

  function handleNestedNestedChange(event, outerId, outerOuterId) {
    genericHandleNestedNestedFields(event, outerId, outerOuterId, setFormData)
  }
  function handleNestedChangeObject(event, outerId) {
    genericHandleNestedFields(event, outerId, setFormData)
  }

  function handleImageChange(image, idField) {
    setFormData((prev) => {
      return { ...prev, [idField]: image }
    })
  }

  async function SaveData() {
    setSaving(true)
    const response = await SaveEntity(
      entity,
      formData,
      handleRefresh,
      () => {
        handleClose({ force: true })
      },
      {
        ...storeData,
        ...customOptions,
      },
    )
    setSaving(false)
  }

  function handleSubmit(event) {
    const form = event.currentTarget
    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      if (entity === ALL_ENTITY_TYPES_FROM_CONSTANTS.investigation) {
        if (!titleValid) {
          setConfirmationProps((prev) => ({
            action: 'submit',
            body: 'An investigation of this name already exists. Do you still want to save this data?',
            buttonColor: 'warning',
            visibility: true,
            visibilitySetter: setConfirmationProps,
            onSubmitFunctions: [() => SaveData()],
            closeButtonText: 'Close and Review',
            submitButtonText: 'Save Anyway',
          }))
          return
        }
      }

      setConfirmationProps((prev) => ({
        action: 'submit',
        body: 'Do you want to save this data?',
        buttonColor: 'success',
        visibility: true,
        visibilitySetter: setConfirmationProps,
        onSubmitFunctions: [() => SaveData()],
      }))
    }
  }

  async function Setup() {
    setLoading(true)
    if (!!data.id) {
      await GetDataSingle()
    } else {
      await setTransformedFormData(data)
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log(formData)
  }, [formData])

  useEffect(() => {
    Setup()
    console.log(customOptions)
  }, [data])

  return (
    <>
      <DialogContent sx={{ display: 'flex' }}>
        {loading ? (
          <LinearProgress sx={{ width: '100%' }} />
        ) : (
          <CForm onSubmit={handleSubmit} id="score-form" className="flex-grow-1 w-100">
            {/* <GenericImageForm
              initialObject={data}
              imageObject={formData}
              setImageObject={setFormData}
              entity={entity}
              handleChangeObject={handleChangeObject}
              handleReactSelectChange={handleReactSelectChange}
              handleImageChange={handleImageChange}
              handleNestedNestedChangeObject={handleNestedNestedChange}
              allImages={allData}
              handleRichTextChange={handleRichTextChange}
              customOptions={customOptions}
              disabled={mode === 'View'}
            /> */}
            {CustomComponent && (
              <CustomComponent
                initialObject={data}
                imageObject={formData}
                setImageObject={setFormData}
                entity={entity}
                handleChangeObject={handleChangeObject}
                handleReactSelectChange={handleReactSelectChange}
                handleImageChange={handleImageChange}
                handleNestedChangeObject={handleNestedChangeObject}
                handleNestedNestedChangeObject={handleNestedNestedChange}
                allImages={allData}
                handleRichTextChange={handleRichTextChange}
                customOptions={customOptions}
                disabled={mode === 'View'}
                slugValidation={slugValidation}
                setSlugValidation={setSlugValidation}
                titleValid={titleValid}
                setTitleValid={setTitleValid}
                setSaving={setSaving}
              />
            )}
          </CForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
        {mode === 'View' ? (
          <Button key={'edit-button'} color="warning" onClick={toggleMode} endIcon={<Edit />}>
            Edit
          </Button>
        ) : (
          <Button
            disabled={loading || saving}
            key={'save-button'}
            variant="contained"
            color="success"
            type="submit"
            form="score-form"
            endIcon={<Send />}
          >
            Save
          </Button>
        )}
      </DialogActions>
      <ConfirmationModal {...confirmationProps} newImplementation />
    </>
  )
}
