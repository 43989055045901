import { createSlice } from '@reduxjs/toolkit'
import { clinicDataStatusReducers } from './statusReducers'

export function setClinicFlowDataToStore(clinicFlowState, { data, field }) {
  return { ...clinicFlowState, [field]: data }
}

const initialState = {
  availableClinicsForCurrentClinicBrand: {
    data: [],
    error: null,
    status: 'idle',
  },
  allClinicBrands: {
    data: [],
    error: null,
    status: 'idle',
  },

  doctorsForCurrentClinic: {
    data: [],
    error: null,
    status: 'idle',
  },
  doctorsFromCurrentClinicBrand: {
    data: [],
    error: null,
    status: 'idle',
  },

  therapistsForCurrentClinic: {
    data: [],
    error: null,
    status: 'idle',
  },
  therapistsFromCurrentClinicBrand: {
    data: [],
    error: null,
    status: 'idle',
  },

  assistantsForCurrentClinic: {
    data: [],
    error: null,
    status: 'idle',
  },
  assistantsForCurrentClinicBrand: {
    data: [],
    error: null,
    status: 'idle',
  },

  clinicChambersForCurrentClinic: {
    data: [],
    error: null,
    status: 'idle',
  },

  oohMsgsForCurrentClinic: {
    data: [],
    error: null,
    status: 'idle',
  },

  currentClinicData: {
    data: null,
    error: null,
    status: 'idle',
  },
  currentClinicBrandData: {
    data: null,
    error: null,
    status: 'idle',
  },

  currentClinicChamberData: {
    data: null,
    error: null,
    status: 'idle',
  },

  currentAssistantData: {
    data: null,
    error: null,
    status: 'idle',
  },

  loadingClinicBrandData: false,
  loadingClinicData: false,
  loadingCurrentClinicData: false,
}

const clinicFlowSlice = createSlice({
  name: 'clinicFlowData',
  initialState: initialState,
  reducers: {
    setCurrentClinicData(state, action) {
      return { ...state, currentClinicData: { ...state.currentClinicData, data: action.payload } }
    },
    setCurrentClinicBrandData(state, action) {
      console.log({ clinicBrandData: action.payload })
      return {
        ...state,
        currentClinicBrandData: { ...state.currentClinicBrandData, data: action.payload },
      }
    },
    setAvailableClinicsForCurrentClinicBrand(state, action) {
      return {
        ...state,
        availableClinicsForCurrentClinicBrand: {
          ...state.setAvailableClinicsForCurrentClinicBrand,
          data: action.payload,
        },
      }
    },
  },
  extraReducers: clinicDataStatusReducers,
  // extraReducers: clinicDataStatusReducers,
})

export const setClinicFlowData = (state) => state.clinicFlowData

export const selectCurrentClinicData = (state) => state.clinicFlowData?.currentClinicData
export const selectAvailableClinicsForCurrentClinicBrand = (state) =>
  state.clinicFlowData.availableClinicsForCurrentClinicBrand

export const selectCurrentClinicBrandData = (state) => state.clinicFlowData.currentClinicBrandData
export const selectAllClinicBrands = (state) => state.clinicFlowData.allClinicBrands

export const selectDoctorsForCurrentClinic = (state) => state.clinicFlowData.doctorsForCurrentClinic
export const selectTherapistsForCurrentClinic = (state) =>
  state.clinicFlowData.therapistsForCurrentClinic

export const selectAssistantsForCurrentClinic = (state) =>
  state.clinicFlowData.assistantsForCurrentClinic

export const selectOOhMsgsForCurrentClinic = (state) => state.clinicFlowData.oohMsgsForCurrentClinic

export const selectClinicChambersForCurrentClinic = (state) =>
  state.clinicFlowData.clinicChambersForCurrentClinic

export const assistantsForCurrentClinic = (state) => state.clinicFlowData.assistantsForCurrentClinic
export const assistantsForCurrentClinicBrand = (state) =>
  state.clinicFlowData.assistantsForCurrentClinicBrand
export const selectCurrentAssistantData = (state) => state.clinicFlowData.currentAssistantData

export const selectCurrentClinicBrandDoctors = (state) =>
  state.clinicFlowData.doctorsFromCurrentClinicBrand

export const selectCurrentClinicBrandTherapists = (state) =>
  state.clinicFlowData.therapistsFromCurrentClinicBrand

export const {
  setCurrentClinicData,
  setCurrentClinicBrandData,
  setAvailableClinicsForCurrentClinicBrand,
} = clinicFlowSlice.actions

export const clinicFlowReducer = clinicFlowSlice.reducer
