import { RANGE_OBJECT } from 'src/common/types/investigation/investigationObject'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export async function transformInitialInvestigationData({ fetchedData }) {
  const foundTests = await getInvestigationsTests(fetchedData?.testIds)

  return {
    ...fetchedData,
    testIds: foundTests,
    referenceRange: fetchedData?.referenceRange || [RANGE_OBJECT],
  }
}

async function getInvestigationsTests(testIds) {
  if (!!testIds && testIds?.length > 0) {
    const testResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.investigation,
      ids: testIds?.join(','),
      size: '999',
    })
    if (testResponse.status === 200) {
      return testResponse.data.content
    }
  }
  return testIds?.map((id) => ({ id })) || []
}
