import { getUsersDetailsFromUserIds } from 'src/utilities/helpers/usersHelpers'
import {
  getNewProspectObject,
  getOptionLabelForUserInput,
} from 'src/views/healthcare/clinicBrand/utils'

export async function transformInitialClinicBrandData(fetchedData) {
  var usersToFind = [
    ...(fetchedData?.ownerUserIds || []),
    ...(fetchedData?.attachedDoctorUserIds || []),
    ...(fetchedData?.attachedTherapistUserIds || []),
    ...(fetchedData?.attachedClinicAssistantUserIds || []),
    ...(fetchedData?.workforce?.doctors?.map((doc) => {
      return doc?.userId
    }) || []),
    ...(fetchedData?.workforce?.therapists?.map((therapist) => {
      return therapist?.userId
    }) || []),
    ...(fetchedData?.workforce?.clinicAssistants?.map((assistant) => {
      return assistant?.userId
    }) || []),
  ]

  usersToFind = [...new Set(usersToFind)]

  const [foundUsers] = await Promise.all([getUsersDetailsFromUserIds(usersToFind)])

  const nonProspectDoctors = [
    ...new Set([
      ...(fetchedData?.attachedDoctorUserIds || []),
      ...(fetchedData?.workforce?.doctors || [])?.map((doc) => {
        return doc?.userId
      }),
    ]),
  ]
    ?.map((docUserId) => {
      return foundUsers?.find((usrId) => usrId?.userId === docUserId)
    })
    ?.filter((docUserId) => !!docUserId)
    ?.map((docId) => {
      return {
        ...docId,
        userId: docId?.userId,
        title: getOptionLabelForUserInput(docId),
        __isNew__: false,
      }
    })

  const nonProspectTherapists = [
    ...new Set([
      ...(fetchedData?.attachedTherapistUserIds || []),
      ...(fetchedData?.workforce?.therapists || [])?.map((therapist) => {
        return therapist?.userId
      }),
    ]),
  ]
    ?.map((therapistId) => {
      return foundUsers?.find((ther) => ther?.userId === therapistId)
    })
    ?.filter((ther) => !!ther)
    ?.map((ther) => ({
      ...ther,
      userId: ther?.userId,
      title: getOptionLabelForUserInput(ther),
      __isNew__: false,
    }))

  const nonProspectAssistants = [
    ...new Set([
      ...(fetchedData?.attachedClinicAssistantUserIds || []),
      ...(fetchedData?.workforce?.clinicAssistants || [])?.map((assistant) => {
        return assistant?.userId
      }),
    ]),
  ]
    ?.map((assistantId) => {
      return foundUsers?.find((usr) => usr?.userId === assistantId)
    })
    ?.filter((assistant) => !!assistant)

  const prospectDoctorsIds = [
    ...new Set([
      ...(fetchedData?.prospectDoctorEmailIds || [])?.filter((pDoc) => pDoc),
      ...(fetchedData?.prospects?.doctors || [])
        ?.filter((pDoc) => pDoc?.email || pDoc?.mobile || pDoc?.userId)
        ?.map((pDoc) => pDoc?.email || pDoc?.mobile || pDoc?.userId),
    ]),
  ]

  const prospectTherapistsIds = [
    ...new Set([
      ...(fetchedData?.prospectTherapistEmailIds || [])?.filter((pDoc) => pDoc),
      ...(fetchedData?.prospects?.therapists || [])
        ?.filter((pDoc) => pDoc?.email || pDoc?.mobile || pDoc?.userId)
        ?.map((pDoc) => pDoc?.email || pDoc?.mobile || pDoc?.userId),
    ]),
  ]

  const prospectAssistantIds = [
    ...new Set([
      ...(fetchedData?.prospectClinicAssistantEmailIds || [])?.filter((pDoc) => pDoc),
      ...(fetchedData?.prospects?.clinicAssistants || [])
        ?.filter((pDoc) => pDoc?.email || pDoc?.mobile || pDoc?.userId)
        ?.map((pDoc) => pDoc?.email || pDoc?.mobile || pDoc?.userId),
    ]),
  ]

  return {
    ...fetchedData,
    ownerUserIds: [
      ...(fetchedData?.ownerUserIds || [])?.map(
        (userId) =>
          foundUsers?.find((user) => (user?.id || user?.userId) === userId) ||
          getNewProspectObject(userId),
      ),
      ...(fetchedData?.prospectOwnerEmailId ? [fetchedData?.prospectOwnerEmailId] : []),
    ],
    attachedDoctorUserIds: [
      ...nonProspectDoctors,
      ...(prospectDoctorsIds || [])
        ?.filter((pDoc) => pDoc)
        ?.map((docEmail) => getNewProspectObject(docEmail)),
    ],
    attachedTherapistUserIds: [
      ...nonProspectTherapists,
      ...(prospectTherapistsIds || [])
        ?.filter((pTherapist) => pTherapist)
        ?.map((therapistEmail) => getNewProspectObject(therapistEmail)),
    ],
    attachedClinicAssistantUserIds: [
      ...nonProspectAssistants,
      ...(prospectAssistantIds || [])
        ?.filter((pAssistant) => pAssistant)
        ?.map((assistantEmail) => getNewProspectObject(assistantEmail)),
    ],
    brandName: fetchedData?.brandName || fetchedData?.title,
  }
}

// ...(fetchedData?.attachedTherapistIds || [])?.map((therapistId) => {
//   return (
//     foundMentors?.find((mentor) => mentor?.id === therapistId) || {
//       id: therapistId,
//       title: therapistId,
//     }
//   )
// }),
