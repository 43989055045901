import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { postFunctionSettings } from 'src/config/genericForm/post/postDataConfig'
import { fetchFunction } from 'src/services'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { approveClinic } from 'src/views/settings/utils/teacher-utils'
import { fetchAvailableClinics } from './clinicBrand'

export const fetchCurrentClinic = createAsyncThunk(
  'clinicFlowData/fetchCurrentClinic',
  async (clinicId, { getState, dispatch }) => {
    dispatch(fetchCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinicData = clinicFlowData.currentClinicData.data
    const currentClinicId = currentClinicData?.id

    if (clinicId === currentClinicId) {
      dispatch(fetchCurrentClinic.fulfilled(clinicFlowData.currentClinicData.data))
    }
    const resp = await fetchFunction(GENERIC_APIS.entity.details, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinic,
      id: clinicId,
    })
    if (resp) {
      return resp
    } else {
      dispatch(fetchCurrentClinic.rejected(resp))
      return clinicFlowData.currentClinicData.data
    }
  },
)

export const saveCurrentClinic = createAsyncThunk(
  'clinicFlowData/saveCurrentClinic',
  async (
    { dataToSave, navigate = () => {}, afterSaveFunction = () => {} },
    { getState, dispatch, rejectWithValue },
  ) => {
    const tst = toast.loading('Saving...')
    const { clinicFlowData, appSettings } = getState()

    const config = postFunctionSettings(ALL_ENTITY_TYPES_FROM_CONSTANTS.clinic, dataToSave, {
      dropDownData: { ...appSettings },
      refreshList: () => {},
      closeFunction: () => {},
      showToast: true,
      defaultApiSignature: GENERIC_APIS.saveEntity.generic_without_slash,
      defaultParams: { entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinic },
    })
    const postApiConfig = config.functionAndParams

    const resp = await PostSettingsData(
      postApiConfig.url,
      {
        ...dataToSave,
        doctors: dataToSave.doctors?.map((doc) => {
          return {
            ...doc,
            uid: doc?.id,
          }
        }),
        therapists: dataToSave.therapists?.map((therapist) => {
          return {
            ...therapist,
            uid: therapist?.id,
          }
        }),
        doctorAllowedActions: null,
        pharmacistAllowedActions: null,
        therapistAllowedActions: null,
        clinicAssistantAllowedActions: null,
      },
      postApiConfig.params,
      tst,
    )
    if (resp.status === 200) {
      if (resp.data.code === 0 || resp.data.code === 200 || resp.data.code === 2000) {
        const clinicResponseData = resp.data.data.clinic
        navigate(clinicResponseData.id)
        afterSaveFunction(clinicResponseData)
        return clinicResponseData
      } else {
        return rejectWithValue(resp.data.message)
      }
    } else {
      dispatch(saveCurrentClinic.rejected(resp.message))
      return clinicFlowData.currentClinicData.data
    }
  },
)

export const approveCurrentClinic = createAsyncThunk(
  'clinicFlowData/approveClinic',
  async (_, { getState, dispatch }) => {
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const currentClinicBrand = clinicFlowData.currentClinicBrandData.data

    const resp = await approveClinic(currentClinic, () => {})
    if (resp.status === 200) {
      if (resp.data.code === 0 || resp.data.code === 200 || resp.data.code === 2000) {
        //refresh clinic and brand
        dispatch(fetchCurrentClinic(currentClinic?.id))
        dispatch(fetchAvailableClinics(currentClinicBrand?.id))
        return resp.data
      } else {
        // handle error
        dispatch(approveCurrentClinic.rejected(resp.data.message))
        return clinicFlowData.currentClinicData.data
      }
    } else {
      dispatch(approveCurrentClinic.rejected(resp.message))
      return clinicFlowData.currentClinicData.data
    }
  },
)
