import { CFormInput } from '@coreui/react-pro'
import PropTypes from 'prop-types'
import React, { useContext, useMemo, useRef } from 'react'
import Select from 'react-select'
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate'
import AsyncSelect from 'react-select/async'
import { default as Creatable, default as CreatableSelect } from 'react-select/creatable'
import { ThemeContext } from 'src/context/ThemeContext'

const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

function getMultiValueBackgroundColor(theme, options, state) {
  if (!!options) {
    if (
      options?.some((option) => option.value === state?.data?.value) ||
      state?.selectProps?.noInvalid
    ) {
      return theme === 'dark' ? '#34353e' : 'rgba(0, 0, 0, 0.2)'
    } else {
      return 'var(--cui-danger)'
    }
  } else {
    return theme === 'dark' ? '#34353e' : 'rgba(0, 0, 0, 0.2)'
  }
}

function getControlBorderColor(theme, options, state) {
  const values = state?.getValue()
  const getOptionValue = state?.selectProps?.getOptionValue
  const invalid = state?.selectProps?.invalid
  if (invalid) {
    return 'var(--cui-danger)'
  }
  return theme === 'dark' ? 'rgba(255, 255, 255, 0.09)' : '#b1b7c1'
  // if (!!options) {
  //   if (options?.some((option) => option.value === state?.data?.value)) {
  //     return theme === 'dark' ? 'rgba(255, 255, 255, 0.09)' : '#b1b7c1'
  //   } else {
  //     return 'var(--cui-danger)'
  //   }
  // } else {
  // }
}

function getControlBorder(theme, options, state) {
  const values = state?.getValue()
  const getOptionValue = state?.selectProps?.getOptionValue
  const invalid = state?.selectProps?.invalid
  if (invalid) {
    return 'var(--cui-border-width) solid var(--cui-danger)'
  }
  return theme === 'dark'
    ? 'rgba(255, 255, 255, 0.09)'
    : 'var(--cui-border-width) solid var(--cui-input-border-color,var(--cui-border-color))'
  // var(--cui-border-width) solid var(--cui-input-border-color,var(--cui-border-color))
}

const selectStyles = (theme, options) => ({
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  container: (baseStyles, state) => {
    var backgroundColor = 'white'
    if (theme === 'dark') {
      backgroundColor = 'rgba(255, 255, 255, 0.05)'
    }
    if (!!state.selectProps.makeSelectContainerTransparent) {
      backgroundColor = 'rgba(255, 255, 255, 0.05)'
    }

    return {
      ...baseStyles,
      borderRadius: 5,
      backgroundColor: backgroundColor,
      textColor: theme === 'dark' ? 'white' : '#000015',
    }
  },
  input: (baseStyles, state) => ({
    ...baseStyles,
    color: theme === 'dark' ? 'white' : '#000015',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    overFlow: 'hidden',
    borderRadius: 5,
    backgroundColor:
      theme === 'dark'
        ? state.isDisabled
          ? 'rgba(255, 255, 255, 0.07)'
          : 'rgba(255, 255, 255, 0.01)'
        : state.isDisabled
        ? '#d8dbe0'
        : 'white',
    textColor: theme === 'dark' ? 'white' : '#000015',
    borderColor: getControlBorderColor(theme, options, state),
    border: getControlBorder(theme, options, state),
    transition: 'all 150ms linear',
    boxShadow: 'none',
  }),
  placeHolder: (baseStyles, state) => ({
    ...baseStyles,
    color: theme === 'dark' ? 'white' : '#b1b7c1',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: theme === 'dark' ? '#rgba(255, 255, 255, 0.05)' : 'white',
  }),
  option: (baseStyles, { isFocused, isSelected }) => {
    return {
      ...baseStyles,
      color: theme === 'dark' ? 'white' : '#000015',
      backgroundColor: isSelected
        ? '#5d5e66'
        : isFocused
        ? theme === 'dark'
          ? '#46474f'
          : 'rgba(0, 0, 0, 0.2)'
        : theme === 'dark'
        ? '#34353e'
        : 'white',
    }
  },
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: theme === 'dark' ? 'white' : '#000015',
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: getMultiValueBackgroundColor(theme, options, state),
    color: 'white',
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    color: theme === 'dark' ? 'white' : '#000015',
  }),
})

function RequiredSelect({ requiredSelectContainerClasses = 'w-auto h-auto', ...props }) {
  const value = useMemo(() => {
    return props.value
  }, [props.value])
  const selectRef = useRef(null)
  const { SelectComponent, required, ...selectProps } = props
  const { isDisabled } = selectProps
  const enableRequired = !isDisabled
  return (
    <div className={requiredSelectContainerClasses}>
      <SelectComponent {...selectProps} ref={selectRef} />
      {enableRequired && required && (
        <CFormInput
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: '50px',
            height: 0,
            position: 'absolute',
          }}
          value={value || ''}
          onChange={() => {}}
          onFocus={() => selectRef?.current?.focus()}
          required={required}
        />
      )}
    </div>
  )
}

export const CustomSelect = (props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <RequiredSelect
      {...props}
      SelectComponent={Select}
      styles={selectStyles(theme, props?.options, props.styles)}
      menuPlacement="auto"
    />
  )
}
export const CustomCreatableSelect = (props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <RequiredSelect
      noInvalid
      {...props}
      SelectComponent={CreatableSelect}
      styles={selectStyles(theme, props?.options, props.styles)}
      menuPlacement="auto"
    />
  )
}
export const CustomAsyncSelect = (props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <RequiredSelect
      {...props}
      SelectComponent={AsyncSelect}
      styles={selectStyles(theme)}
      menuPlacement="auto"
    />
  )
}

export const CustomAsyncPaginate = (props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <RequiredSelect
      {...props}
      SelectComponent={AsyncPaginate}
      styles={selectStyles(theme)}
      menuPlacement="auto"
    />
  )
}
export const CustomCreatableAsyncPaginate = (props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <RequiredSelect
      {...props}
      SelectComponent={CreatableAsyncPaginate}
      styles={selectStyles(theme)}
      menuPlacement="auto"
    />
  )
}

RequiredSelect.propTypes = {
  value: PropTypes.any,
  SelectComponent: PropTypes.elementType.isRequired,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

CustomSelect.propTypes = {
  ...Select.propTypes,
}
CustomCreatableSelect.propTypes = {
  ...CreatableSelect.propTypes,
}
CustomAsyncSelect.propTypes = {
  ...AsyncSelect.propTypes,
}
CustomAsyncPaginate.propTypes = {
  ...AsyncPaginate.propTypes,
}
CustomCreatableAsyncPaginate.propTypes = {
  ...CreatableAsyncPaginate.propTypes,
}
