import { Face3TwoTone, Face6TwoTone, Transgender } from '@mui/icons-material'
import React from 'react'
import { getMediaImageUrl } from 'src/services'
import {
  ALL_GENDERS,
  ALL_SUBSCRIPTION_LEVELS,
  SUBSCRIPTION_TYPES_LAYMAN,
} from 'src/utilities/constants'
import { TableAvatarComponent } from 'src/views/settings/components/TableImage'
import CountryCodeFlag from '../../common/CountryCodeFlag'

export default function ProfilePicComponent({ userData }) {
  var IconComponent = undefined

  switch (userData?.gender) {
    case ALL_GENDERS?.[0]?.id:
      IconComponent = Face6TwoTone
      break
    case ALL_GENDERS?.[1]?.id:
      IconComponent = Face3TwoTone
      break
    case ALL_GENDERS?.[2]?.id:
      IconComponent = Transgender
      break
    default:
  }

  return (
    <div className="d-flex gap-2 flex-wrap">
      <TableAvatarComponent
        imageId={userData?.profilePicId?.includes('MEDIA') ? userData?.profilePicId : undefined}
        customSrc={userData?.profilePicId?.includes('MEDIA') ? undefined : userData?.profilePicId}
        CustomAvatarIcon={IconComponent}
      >
        <div style={{ position: 'absolute', bottom: '0px', right: '0px', zIndex: '2' }}>
          <CountryCodeFlag
            countryCode={userData?.location?.countryCode}
            isCircular
            iconStyles={{
              width: '20px',
            }}
          />
        </div>
        <div style={{ position: 'absolute', top: '-5px', left: '-5px', zIndex: '2' }}>
          <SubscriptionLevelAndSubscriptionTypeIconSelector
            subscriptionLevelId={userData?.subscriptionType?.id}
            subscriptionTypeId={
              userData?.activeSubscriptions?.subscription?.subscriptionPlan?.subscriptionPlanType
            }
          />
        </div>
      </TableAvatarComponent>
    </div>
  )
}

function SubscriptionLevelAndSubscriptionTypeIconSelector({
  subscriptionLevelId: propSubscriptionLevelId,
  subscriptionTypeId: propSubscriptionTypeId,
}) {
  const subscriptionLevelId = propSubscriptionLevelId || ALL_SUBSCRIPTION_LEVELS?.[0]?.id
  const subscriptionTypeId = propSubscriptionTypeId || SUBSCRIPTION_TYPES_LAYMAN?.[0]?.id
  const iconStyle = { padding: '1px', background: 'white', borderRadius: '20px' }

  if (
    subscriptionLevelId === ALL_SUBSCRIPTION_LEVELS?.[0]?.id &&
    subscriptionTypeId === SUBSCRIPTION_TYPES_LAYMAN?.[0]?.id
  ) {
    return (
      <img
        width="25"
        src={`${getMediaImageUrl()}MEDIA-869cbccd74434a7cb97c862467b05643`}
        alt="trial"
        style={{ ...iconStyle, borderRadius: '5px' }}
      />
    )
  }
  if (
    subscriptionLevelId === ALL_SUBSCRIPTION_LEVELS?.[1]?.id &&
    subscriptionTypeId === SUBSCRIPTION_TYPES_LAYMAN?.[0]?.id
  ) {
    return (
      <img
        width="25"
        src={`${getMediaImageUrl()}MEDIA-e41d7d3fc75c4d878ffb1d9946027bd4`}
        alt="crown"
        style={{ ...iconStyle, padding: '3px' }}
      />
    )
  }
  return <></>
}
