import moment from 'moment'
import { toast } from 'react-toastify'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { ObjectId } from 'src/utilities/generators'
import { COLORS_OBJECT } from '../../../components/controlCenter/imageUpload/imageUploadUtils'
import { newSlot } from '../components/ClinicSchedule'

export const DEFAULT_WEEKLY_HOUR_MAP = {
  sunday: getDefaultWeeklyHours('Sunday', false),
  monday: getDefaultWeeklyHours('Monday'),
  tuesday: getDefaultWeeklyHours('Tuesday'),
  wednesday: getDefaultWeeklyHours('Wednesday'),
  thursday: getDefaultWeeklyHours('Thursday'),
  friday: getDefaultWeeklyHours('Friday'),
  saturday: getDefaultWeeklyHours('Saturday', false),
}

export function getDefaultWeeklyHours(title, available = true) {
  return { available: available, title: title, slotList: available ? [newSlot] : null }
}

export const NEW_SCHEDULE_OBJECT = {
  id: `clinic_schedule-${ObjectId()}`,
  title: 'Clinic Schedule',
  weeklyHourMap: DEFAULT_WEEKLY_HOUR_MAP,
}

export const CLINIC_OBJECT = {
  id: '',
  createdBy: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinic,
  modifiedBy: '',
  title: '',
  description: '',
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  verticalRectangle: '',
  verticalRectangleColor: '',
  heroCardImage: '',
  heroCardImageColor: '',
  blogBanner: '',
  blogBannerColor: '',
  backdropImage: '',
  backdropImageColor: '',
  customShortLink: '',
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  profilePicId: '',
  profilePicColor: '',
  location: {},
  favorite: true,
  languages: [],
  services: {
    recorded: false,
    live: false,
    inPerson: true,
    groupLive: true,
  },
  schedules: [NEW_SCHEDULE_OBJECT],
  liveSessionsCount: 0,
  rating: 0,
  noOfRating: 0,
  afflliationIds: [],
  instituteIds: [],
  categories: [],
  shortDesc: '',
  listableInApp: false,
  favCount: 0,
  donationEnabled: false,
  chatEnabled: false,
  onboardingImage: '',
  backgroundImage: '',
  welcomeMsg: '',
  chatWelcomeMsg: '',
  outGoingAumhumEmail: '',
  notificationSettings: {},
  personalize: {},
  customLongLink: '',
  timeZone: {},
  allowedToPrescribe: true,
  doctors: [],
  therapists: [],
  staffMemberIds: [],
  pharmacyIds: [],
  management: [],
  availableServices: [],
  ownerId: '',
  name: '',
  longDesc: '',
  appLanguages: [],
}

export const USER_CONTACT_OBJECT = {
  id: '',
  userId: '',
  email: '',
  mobile: '',
}

export const DISPLAY_NAMES_OF_WEEKDAYS = {
  sunday: {
    shortName: 'Sun',
    longName: 'Sunday',
  },
  monday: {
    shortName: 'Mon',
    longName: 'Monday',
  },
  tuesday: {
    shortName: 'Tue',
    longName: 'Tuesday',
  },
  wednesday: {
    shortName: 'Wed',
    longName: 'Wednesday',
  },
  thursday: {
    shortName: 'Thu',
    longName: 'Thursday',
  },
  friday: {
    shortName: 'Fri',
    longName: 'Friday',
  },
  saturday: {
    shortName: 'Sat',
    longName: 'Saturday',
  },
}

export function validateClinicFunction(clinicObject) {
  const schedules = clinicObject.schedules
  const schedulesValidation = schedules.map((schedule) => {
    const weeklyHourMap = schedule.weeklyHourMap
    const validateWeeklyHourMap = Object.entries(weeklyHourMap || {}).every(
      ([dayKey, daySchedule]) => {
        if (!daySchedule.available) {
          return true
        }
        const validateSLotList = (daySchedule?.slotList || [])?.every((slot, idx) => {
          const startTimeEndTimeNoConflict =
            moment(slot.startTime, 'HH:mm:ss') < moment(slot.endTime, 'HH:mm:ss')
          var overLapPrev = false
          if (idx !== 0) {
            overLapPrev =
              moment(slot.startTime, 'HH:mm:ss') <
              moment(daySchedule?.slotList?.[idx - 1].endTime, 'HH:mm:ss')
          }
          console.log({ startTimeEndTimeNoConflict, overLapPrev })
          return startTimeEndTimeNoConflict && !overLapPrev
        })
        console.log({ validateSLotList })

        return validateSLotList
      },
    )

    console.log({ validateWeeklyHourMap })

    return validateWeeklyHourMap
  })
  if (!schedulesValidation.every((sch) => sch)) {
    toast.error('Cannot submit with erroneous schedules')
  }
  return {
    schedules: schedulesValidation.every((sch) => sch),
  }
}

export const CLINIC_FORM_VIEWS = {
  basicDetails: 'basicDetails',
  schedules: 'schedules',
  people: 'people',
  oohMsgs: 'oohMsgs',
}
