import { colorIdObject } from '../ColorGradientPicker'

export const IMAGE_UPLOAD_UTILS = {
  gallery: 'images/list/paginated',
  gallerySave: 'images/save',
  colors: 'color/list/paginated',
  colorSave: 'color/save',
}

export const COLORS_OBJECT = {
  apiParam: 'color',
  colorIds: [colorIdObject, colorIdObject],
  degrees: 0,
  deleted: false,
  enabled: true,
  id: '',
  numberOfColors: 1,
  type: 'solid',
}

export function getUniqueArrayKeys(array, keyName, value) {
  const uniqKeys = {}
  array.forEach((arr) => {
    uniqKeys[arr[keyName]] = true
  })
  return array.filter((arr) => {
    if (uniqKeys[arr[keyName]]) {
      uniqKeys[arr[keyName]] = false
      return true
    }
  })
}
