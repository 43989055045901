export const ONLINE_COURSES_APIS = {
  onlineCourse: {
    get: 'oc/online-courses',
    getSingle: 'oc/{courseId}',
    getSingleSummary: 'oc/{courseId}/summary',
    getCourseGroup: 'oc/course-group/{grpId}/course-summary/list',
    getMinimalSummaryList: 'oc/sessions/summary/list/minimal',
  },
}

export const ONLINE_COURSE_DRAFT_TYPES = {
  draft: 'draft',
  onlineCourses: 'non-draft',
}
