export const LEGACY_TABS_FOR_IN_APP_MSG = [
  { id: 'explore_tab', title: 'Explore Tab' },
  { id: 'practice_tab', title: 'Practice Tab' },
  { id: 'chat_tab', title: 'Chat Tab' },
  { id: 'calendar_tab', title: 'Calendar Tab' },
  { id: 'more_tab', title: 'More Tab' },

  { id: 'payment_failed', title: 'Payment Failed  Tab' },
  { id: 'yoga', title: 'Yoga Tab' },
  { id: 'story_screen', title: 'Story Tab' },
  { id: 'sleep_tab', title: 'Sleep Tab' },
  { id: 'settings', title: 'Setting Tab' },

  { id: 'player_end_screen', title: 'Player End Screen' },
  { id: 'sound', title: 'Sound Screen' },
  { id: 'journal', title: 'Journal Screen' },

  { id: 'quote', title: 'Quote Screen' },
  { id: 'my_library', title: 'Library Screen' },
  { id: 'my_journal', title: 'My Journal Screen' },
  { id: 'me_tab', title: 'Me Tab' },
  { id: 'breathe_tab', title: 'Breathe Tab' },
  { id: 'get_involved', title: 'Get Involved' },
  { id: 'play', title: 'Checkin' },
]

export const TEXT_USERS_FOR_IN_APP_MSG = [
  { id: 'only_test_user', title: 'Only Unverified Users' },
  { id: 'only_non_test_user', title: 'Only Verified Users' },
]

export const IN_APP_MSG_PRIORITY = [
  { id: 4, title: 'Very High' },
  { id: 3, title: 'High' },
  { id: 2, title: 'Medium' },
  { id: 1, title: 'Low' },
  { id: 0, title: 'Very Low' },
]

// export const LEGACY_TABS_FOR_IN_APP_MSG = [
//     { id: 'explore_tab', title: 'Explore Tab (Legacy)' },
//     { id: 'practice_tab', title: 'Practice Tab (Legacy)' },
//     { id: 'chat_tab', title: 'Chat Tab (Legacy)' },
//     { id: 'calendar_tab', title: 'Calendar Tab (Legacy)' },
//     { id: 'more_tab', title: 'More Tab (Legacy)' },

//     { id: 'payment_failed', title: 'Payment Failed  Tab (Legacy)' },
//     { id: 'yoga', title: 'Yoga Tab (Legacy)' },
//     { id: 'story_screen', title: 'Story Tab (Legacy)' },
//     { id: 'sleep_tab', title: 'Sleep Tab (Legacy)' },
//     { id: 'settings', title: 'Setting Tab (Legacy)' },

//     { id: 'player_end_screen', title: 'Player End Screen (Legacy)' },
//     { id: 'sound', title: 'Sound Screen (Legacy)' },
//     { id: 'journal', title: 'Journal Screen (Legacy)' },

//     { id: 'quote', title: 'Quote Screen (Legacy)' },
//     { id: 'my_library', title: 'Library Screen (Legacy)' },
//     { id: 'my_journal', title: 'My Journal Screen (Legacy)' },
//     { id: 'me_tab', title: 'Me Tab (Legacy)' },
//     { id: 'breathe_tab', title: 'Breathe Tab (Legacy)' },
//     { id: 'get_involved', title: 'Get Involved (Legacy)' },
//     { id: 'play', title: 'Checkin (Legacy)' },
//   ]
