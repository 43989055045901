import { PlayArrow, PlayDisabled } from '@mui/icons-material'
import { CircularProgress, Dialog, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getMomentDateFromMilliseconds } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { TableImageComponent } from 'src/views/settings/components/TableImage'
import { OverflowingArrayChips } from '../../../FormComponents'
import OnlineCourseListingTrackPlayersComponent from './components/OnlineCourseListingTrackPlayersComponent'

export default function OnlineCourseTitleHeaderComponent({
  onlineCourse,
  dropDownData,
  rStore: rStoreProps,
  hideMentor = false,
}) {
  const [reduxStore] = useAppSettingsStore()

  const rStore = rStoreProps || reduxStore

  const [showTrackPlayers, setShowTrackPlayers] = useState(false)
  const [backgroundMusic, setBackgroundMusic] = useState()
  const ocDuration = getMomentDateFromMilliseconds(onlineCourse?.duration)

  const foundMentor = dropDownData?.mentors?.find(
    (mentor) => mentor?.id === onlineCourse?.createdFor,
  )

  const isPlayable = onlineCourse?.noOfEpisode > 0 && onlineCourse?.duration > 0

  const [detailedOc, setDetailedOc] = useState(null)
  const [loadingPlayers, setLoadingPlayers] = useState(false)

  const courseLanguages = onlineCourse?.languages?.map((lang) =>
    rStore?.languages?.find((rLang) => rLang?.id === lang),
  )

  function handleCloseShowTrackPlayers() {
    setShowTrackPlayers(false)
  }

  async function getDetailedOnlineCourse() {
    const resp = await GetDetailedEntity(ALL_ENTITY_TYPES_FROM_CONSTANTS.onlineCourse, {
      id: onlineCourse?.id,
    })
    if (resp) {
      setDetailedOc(resp)
      return resp
    }
  }

  async function handlePlayClick() {
    setLoadingPlayers(true)
    const detailedOC = await getDetailedOnlineCourse()
    setShowTrackPlayers(true)
    setLoadingPlayers(false)
  }

  return (
    <td>
      <div className="w-100 d-flex">
        <div>
          <div
            style={{ position: 'relative', margin: '10px' }}
            onClick={(e) => (isPlayable ? e.stopPropagation() : null)}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 2,
                height: '100%',
                width: '100%',
                borderRadius: '6px',
                overflow: 'hidden',
                backdropFilter: 'brightness(0.8)',
              }}
              onClick={isPlayable && handlePlayClick}
              className="d-flex justify-content-center align-items-center"
            >
              {isPlayable ? (
                <div
                  className="d-flex justify-content-center align-items-center p-2"
                  style={{
                    background: 'rgba(0, 0, 0, 0.56)',
                    borderRadius: '50%',
                    height: '60px',
                    width: '60px',
                  }}
                >
                  {!loadingPlayers ? (
                    <PlayArrow onClick={handlePlayClick} sx={{ color: '#fff', fontSize: '40px' }} />
                  ) : (
                    <CircularProgress
                      sx={{ height: '30px !important', width: '30px !important' }}
                      color="secondary"
                    />
                  )}
                </div>
              ) : (
                <Tooltip title="There are no episodes or duration of the track is 0 mins.">
                  <div
                    className="d-flex justify-content-center align-items-center p-2"
                    style={{
                      background: 'rgba(0, 0, 0, 0.56)',
                      borderRadius: '50%',
                      height: '60px',
                      width: '60px',
                    }}
                  >
                    <PlayDisabled sx={{ color: '#fff', fontSize: '40px' }} />
                  </div>
                </Tooltip>
              )}
            </div>
            <TableImageComponent
              imageId={onlineCourse?.coverIcon}
              sx={{
                padding: '0px',
              }}
              hideBrokenImage
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <strong className="mb-2 fs-5">{onlineCourse?.title || <em>Title not set</em>}</strong>
          {!hideMentor && (
            <div className="d-flex align-items-center gap-1">
              <TableImageComponent
                imageId={foundMentor?.profilePicId}
                sx={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  padding: '0px',
                }}
              />
              <span className="fw-light">{foundMentor?.title || onlineCourse?.createdForName}</span>
            </div>
          )}
          <span className="fw-light">
            <OverflowingArrayChips
              allItems={courseLanguages}
              getTitle={(itm) => itm?.languageName}
              chipProps={{
                size: 'small',
              }}
            />
          </span>
          <div className="d-flex align-items-center gap-2">
            <div>
              {onlineCourse?.noOfEpisode >= 0 ? onlineCourse?.noOfEpisode : 0} Episode
              {(onlineCourse?.noOfEpisode || 0) > 1 ? 's' : ''}
            </div>
          </div>
          <div>
            <span className="fw-light">{ocDuration.format('HH:mm:ss')}</span>
          </div>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <Dialog
            open={showTrackPlayers}
            PaperProps={{
              sx: {
                margin: '0px !important',
                maxWidth: (theme) => theme.breakpoints.values.sm,
                width: '100%',
              },
            }}
            onClose={handleCloseShowTrackPlayers}
          >
            <OnlineCourseListingTrackPlayersComponent
              onlineCourse={detailedOc}
              backgroundMusic={backgroundMusic}
              handleClose={handleCloseShowTrackPlayers}
            />
          </Dialog>
        </div>
      </div>
    </td>
  )
}
