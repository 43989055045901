import { styled as muiStyled } from '@mui/material'

export const HyperLinkContainer = muiStyled('div')(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #0000001f;
    border-radius: 7px;
`,
)
export const DeepLinkViewContainer = muiStyled('div')(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    background: #0000001f;
    border-radius: 7px;
    @media(min-width:576px){
      flex-direction: row;
    }
`,
)
