import { ButtonBase, Paper, styled as muiStyled } from '@mui/material'
import { CustomAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'

export const DetailsInTextDialogStyles = muiStyled(Paper)(
  ({ theme }) => `
  width: 100%;
  height: 100%;
`,
)
export const DescriptionSelectionDialogStyles = muiStyled(Paper)(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  max-height: 400px !important;
  max-width: 650px !important;
  margin: 0 !important;
`,
)

export const DescriptionSelectionButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 150px;
  width: 230px;
  padding: 10px;
  background: #00000036;
    border-radius: 15px;
    transition: background 0.3s ease;
    &:hover {
      background: #0000005e;
    }
`,
)

export const DescriptionSelectionButtonsContainer = muiStyled('div')(
  ({ theme }) => `
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
flex-direction:column;
padding: 10px 0px;
@media(min-width:576px){
  flex-direction: row;
}
`,
)

export const DescriptionButtonText = muiStyled('span')(
  ({ theme }) => `
font-size: 16px;
font-weight: 500;
`,
)

export const ActivityItemAsyncSelect = muiStyled(CustomAsyncPaginate)(
  ({ theme }) => `
> .activity-item-select__control > .activity-item-select__value-container > .activity-item-select__single-value {
  text-overflow: unset;
  white-space: unset;
}
`,
)
