import React, { useMemo } from 'react'
import { LINK_TYPES } from 'src/utilities/constants'
import { detectIfDeepLink } from '../../linksMakerUtils'
import ViewDeepLinkComponent from './deepLink/ViewDeepLinkComponent'
import ViewHyperLinkComponent from './hyperLink/ViewHyperLinkComponent'

export default function LinksMakerViewFactoryComponent(props) {
  const ComponentToUse = useMemo(() => {
    const linkType = detectIfDeepLink(props.externalLinksData.uri)
      ? LINK_TYPES.deep
      : LINK_TYPES.hyperlink

    switch (linkType) {
      case LINK_TYPES.deep:
        return ViewDeepLinkComponent
      case LINK_TYPES.hyperlink:
        return ViewHyperLinkComponent
      default:
        return ViewHyperLinkComponent
    }
  }, [props.externalLinksData])

  return (
    <div className="d-flex flex-column w-100 gap-3">
      <ComponentToUse {...props} />
    </div>
  )
}
