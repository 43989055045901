export const AVATAR_IMAGE_OBJECT = {
  apiParam: 'avatar',
  collection: '',
  colors: null,
  deleted: false,
  enabled: true,
  gender: 'male',
  hasAlpha: true,
  id: '',
  idByUser: false,
  tags: [],
}
