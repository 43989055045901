import { CFormInput } from '@coreui/react-pro'
import { Button } from '@mui/material'
import { ContentState, EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { ThemeContext } from 'src/context/ThemeContext'
import { apiURL, getMediaImageUrl } from 'src/services'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { FAQEditor } from 'src/views/miscellaneous/faqs/components/styledComponents'
import { entityFormComponents } from '../../../config/common/genericListingAndFormConfig'
import { getAspectRatioFromResolutionString } from '../imageUpload/ImageSizeSelect'
import { ImageUploadContext } from '../imageUpload/ImageUpload'
import CCOverlay from '../loadingOverlay/CCOverlay'
import { GalleryImages, PreviewBox, PreviewImage } from '../styledComponents'
import { getFileFromUrl, getTextFromTextFile } from './utils'

export function ImageFormImagePreview({
  imageId,
  imageType,
  imageTypeObject = {},
  imageObject,
  uploadedFile,
  colors,
  showColors,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const allImageTypes = store.imageTypes
  const currentImageType = imageType
    ? allImageTypes?.find((type) => type.id === imageType)
    : imageTypeObject

  useEffect(() => {
    console.log(uploadedFile)
  }, [uploadedFile])
  return (
    <GalleryImages>
      {Object.entries(
        Object.entries(uploadedFile)?.length === 0 ? { [imageType]: imageObject } : uploadedFile,
      ).map(([key, image], index) => (
        <PreviewBox
          key={key}
          justifyContent="center"
          type={colors?.type}
          degree={colors?.degrees || 0}
          colorIds={showColors ? colors.colorIds : []}
        >
          <PreviewImage
            aspectRatio={getAspectRatioFromResolutionString(currentImageType?.resolution || '1x1')}
            src={
              !uploadedFile[key] ? `${getMediaImageUrl()}${imageId}` : URL.createObjectURL(image)
            }
            alt={key || imageId || 'undefined'}
          />
        </PreviewBox>
      ))}
    </GalleryImages>
  )
}

export function RichTextPreview({ imageObject, uploadedFile }) {
  const editor = useRef(null)
  const [richText, setRichText] = useState('')
  const [loading, setLoading] = useState(false)
  const { theme } = useContext(ThemeContext)

  function focusEditor() {
    editor.current.editor.focus()
  }

  async function Setup() {
    setLoading(true)
    const numberOfLocalFiles = Object.keys(uploadedFile).length
    const txt = await Promise.all(
      Object.entries(numberOfLocalFiles > 0 ? uploadedFile : { localFiles: false }).map(
        async ([type, file]) => {
          if (!!file) {
            return file
          }
          return await getFileFromUrl(`${apiURL}/api/media/file/${imageObject?.fileId}`)
        },
      ),
    )
    const finalText = await getTextFromTextFile(txt.lastItem)
    setRichText(EditorState.createWithContent(ContentState.createFromText(finalText)))
    setLoading(false)
  }

  useEffect(() => {
    Setup()
  }, [imageObject])

  return (
    <CCOverlay active={loading} spinner>
      <FAQEditor className="dark:text-white my-2" theme={theme} onClick={focusEditor}>
        <Editor
          ref={editor}
          toolbarStyle={{ flexShrink: 0 }}
          editorStyle={{
            height: 'auto',
            zIndex: '99',
            // '::-webkit-scrollbar': {
            //   display: 'none',
            // },
            // '-ms-overflow-style': 'none',
            // 'scrollbar-width': 'none',
          }}
          editorState={richText}
          onEditorStateChange={setRichText}
          placeholder="Write something!"
        />
      </FAQEditor>
    </CCOverlay>
  )
}

export function BasicSubtitleView({ imageObject, uploadedFile, setUploadedFile }) {
  console.log(imageObject)
  const ImageContext = useContext(ImageUploadContext)
  function handleChangeFile(event) {
    if (!!event.target?.files && !!event.target.files[0]) {
      setUploadedFile({ [ImageContext.imageType]: event.target.files[0] })
    }
  }
  return (
    <>
      {imageObject?.fileId && (
        <a
          href={`${apiURL}/api/media/file/${imageObject?.fileId}`}
          rel="noreferrer"
          target="_blank"
        >
          <Button color="secondary" variant="contained">
            Download
          </Button>
        </a>
      )}
      {!imageObject?.fileId && (
        <CFormInput
          type="file"
          onChange={handleChangeFile}
          {...entityFormComponents('subtitle')?.fileInput}
        />
      )}
    </>
  )
}

RichTextPreview.propTypes = {
  imageObject: PropTypes.object.isRequired,
  uploadedFile: PropTypes.any,
}
BasicSubtitleView.propTypes = {
  imageObject: PropTypes.object.isRequired,
  uploadedFile: PropTypes.any,
  setUploadedFile: PropTypes.func,
}

ImageFormImagePreview.propTypes = {
  imageId: PropTypes.string,
  imageType: PropTypes.string,
  imageObject: PropTypes.object,
  uploadedFile: PropTypes.object,
  colors: PropTypes.shape({
    type: PropTypes.string,
    degrees: PropTypes.number,
    colorIds: PropTypes.arrayOf(PropTypes.object),
  }),
  showColors: PropTypes.bool,
}
