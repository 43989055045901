import { Add, Delete } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import {
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import SummarizableFormFieldsWrapper from 'src/components/controlCenter/common/SummarizableFormFieldsWrapper'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'

export default function PaymentGatewaySkuForm({
  initialObject = {},
  imageObject: paymentGatewaySku,
  setImageObject: setPaymentGatewaySku,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
}) {
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  function addGty() {
    setPaymentGatewaySku((prev) => ({
      ...prev,
      paymentGatewayList: [...prev.paymentGatewayList, ''],
    }))
  }
  function deleteGty(idx) {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'delete',
      body: 'Are you sure you want to delete this payment gateway?',
      buttonColor: 'error',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [
        () => {
          setPaymentGatewaySku((prev) => ({
            ...prev,
            paymentGatewayList: prev.paymentGatewayList.filter((_, i) => i !== idx),
          }))
        },
      ],
    }))
  }

  function handleChangePaymentGatewayList(eve, idx) {
    const { value } = eve.target
    setPaymentGatewaySku((prev) => ({
      ...prev,
      paymentGatewayList: prev.paymentGatewayList.map((itm, i) => (i === idx ? value : itm)),
    }))
  }

  const gtyFilter = (gty, idx) => {
    return (
      gty.id === paymentGatewaySku?.paymentGatewayList?.[idx] ||
      !paymentGatewaySku?.paymentGatewayList?.some((skuGtyItm) => skuGtyItm === gty?.id)
    )
  }

  return (
    <Grid container spacing={2}>
      <GridSectionWrapper heading="Basic Details" shadow={false}>
        <SummarizableFormFieldsWrapper
          data={paymentGatewaySku}
          handleChange={handleChangeObject}
          disabled={disabled}
          showIdField
          idDisabled={!!initialObject?.id}
          allListData={allImages}
          initialObject={initialObject}
          hideTitleField
        />
        <Grid item xs={12}>
          <CCTextArea
            id="description"
            label="Description"
            value={paymentGatewaySku.description}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomReactSelect
            id="grpId"
            value={paymentGatewaySku.grpId}
            label="Plan Group"
            options={customOptions?.allPlanGroups}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            selectType={selectTypes.string}
            onChangeEvent={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper
        headerComponents={
          <div className="d-flex justify-content-between align-items-center">
            <h5>Payment Gateway List</h5>
            {paymentGatewaySku?.paymentGatewayList?.length <
              customOptions?.paymentGateways?.length && (
              <IconButton onClick={addGty}>
                <Add />
              </IconButton>
            )}
          </div>
        }
        gridSectionWrapperProps={{ xs: 12 }}
        shadow={false}
      >
        {paymentGatewaySku?.paymentGatewayList?.map((gtyItm, idx) => (
          <Grid item xs={12} key={gtyItm} display="flex" alignItems="center" gap="7px">
            <CustomReactSelect
              value={gtyItm}
              options={customOptions?.paymentGateways
                ?.slice()
                ?.filter((gty) => gtyFilter(gty, idx))}
              onChangeEvent={(eve) => handleChangePaymentGatewayList(eve, idx)}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              disabled={disabled}
              onChange={(eve) => handleChangePaymentGatewayList(eve, idx)}
              selectType={selectTypes.string}
              invalid={!gtyItm}
              required
            />
            {paymentGatewaySku?.paymentGatewayList?.length > 0 && (
              <IconButton onClick={() => deleteGty(idx)}>
                <Delete />
              </IconButton>
            )}
          </Grid>
        ))}
      </GridSectionWrapper>
      <ConfirmationModal newImplementation {...confirmationModalProps} />
    </Grid>
  )
}
