import { createSlice } from '@reduxjs/toolkit'
import { subscriptionsExtraReducers } from './statusReducers'

const initialState = {
  consumerSummaryList: {
    data: [],
    status: 'idle',
    error: null,
  },
  corporateSummaryList: {
    data: [],
    status: 'idle',
    error: null,
  },
  subscriptionLevels: {
    data: [],
    loading: false,
    error: null,
  },
  planTypes: {
    data: [],
    loading: false,
    error: null,
  },
  planGroups: {
    data: [],
    loading: false,
    error: null,
  },
}

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: subscriptionsExtraReducers,
})

export const selectSummaryListOfConsumerPlans = (state) => state.subscriptions.consumerSummaryList

export const selectSummaryListOfCorporatePlans = (state) => state.subscriptions.corporateSummaryList

export const { setAppSettings, setProfileAuthorities } = subscriptionSlice.actions
export const subscriptionsReducers = subscriptionSlice.reducer
