import { Help } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useMemo } from 'react'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

export default function CountryCodeFlag({
  countryCode,
  hideCountryNameTooltip,
  iconStyles = {},
  isCircular = false,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const countryName =
    store.countries?.find((country) => country?.id === countryCode)?.title || countryCode

  const TooltipComponent = useMemo(() => {
    if (!hideCountryNameTooltip) {
      return Tooltip
    }
    return React.Fragment
  }, [hideCountryNameTooltip])

  if (isCircular) {
    return (
      <TooltipComponent title={countryName}>
        {countryCode ? (
          <img
            src={`https://hatscripts.github.io/circle-flags/flags/${countryCode}.svg`}
            width="40"
            alt={countryCode}
            style={iconStyles}
          />
        ) : (
          <Help />
        )}
      </TooltipComponent>
    )
  }

  return (
    <TooltipComponent title={countryName}>
      <img
        src={`https://flagcdn.com/w40/${countryCode}.png`}
        srcSet={`https://flagcdn.com/w80/${countryCode}.png 2x`}
        width="40"
        alt={countryCode}
        style={iconStyles}
      />
    </TooltipComponent>
  )
}
