export const ALT_TEXT_OBJECT = {
  explore: '',
  story: '',
  sleep: '',
  yoga: '',
  sdk: '',
  sound: '',
  journal: '',
  home: '',
}
