import moment from 'moment'
import { SUPPORT_ROLE_OBJECT } from './supportRole'

export const CLINIC_ASSISTANT_OBJECT = {
  apiParam: 'clinic-assistant',
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: null,
  userId: '',
  clinicId: '',
  clinicLocationId: '',
  clinicBrandId: '',
  supportRoles: [SUPPORT_ROLE_OBJECT],
  startDate: moment.utc(moment(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
}
