import { Add, CopyAll, Upload } from '@mui/icons-material'
import {
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import AwsS3 from '@uppy/aws-s3'
import { Uppy } from '@uppy/core'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import { Dashboard } from '@uppy/react'
import Webcam from '@uppy/webcam'
import '@uppy/webcam/dist/style.min.css'
import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { getCSmartTableColumnData } from 'src/config/genericListing/columnsAndHeaders/getListOfColumnsForSmartTable'
import { ThemeContext } from 'src/context/ThemeContext'
import { getTokenFromLocalStorage } from 'src/services/BrowserStorage/localStorageController'
import {
  HLS_TRACK_TYPES,
  HLS_TRACK_TYPES_MAP,
  VIDEO_ORIENTATION_TYPES,
} from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { TrackResponse } from '../../../../common/types/TrackClass'
import {
  TRACK_UPLOAD_API_URLS,
  handleResults,
} from '../../../../common/types/uppy-media-upload-utils'
import { getMediaImageUrl, getMediaUrl, getUppyUrl, postFunction } from '../../../../services'
import { checkIfStringIsValid, copyToClipBoard } from '../../../../utilities/generalUtils'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from '../../CCExtendedCSmartTable'
import { TrackUploadDialogPaper } from '../../styledComponents'
import { TrackPlayerDialogPaper } from './styledComponents'
import TrackSubtitles from './trackSubtitles/TrackSubtitles'
import { getRequiredTrueForTrack } from './utils'

const TYPE_TO_MIME_TYPE = {
  [HLS_TRACK_TYPES?.[0]?.id]: 'audio/*',
  [HLS_TRACK_TYPES?.[1]?.id]: 'video/*',
}

function TrackUploadComponent({
  propUppy,
  trackObject,
  handleChange = () => {},
  entityName,
  hideTrackTypeChange = false,
  showTrackOutsideOnDisabled = false,
  updateDurationChangeTransformer = (duration) => {
    return duration
  },
  required = false,
  disabled,
  children,
  contentBesideUploadButton,
}) {
  const { theme } = useContext(ThemeContext)
  const [showModal, setShowModal] = useState(false)
  const [handlingUploads, setHandlingUploads] = useState(false)
  const [showTrackOnDialog, setShowTrackOnDialog] = useState(false)
  const currentTrackType = HLS_TRACK_TYPES?.find((trkTyp) => trkTyp.id === trackObject?.type)
  const [store] = useAppSettingsStore()
  const subtitlesListing = Object.entries(trackObject?.subtitles || {}).map(([key, value]) => ({
    languageCode: key,
    ...value,
  }))
  const [showSubtitleUploadDialog, setShowSubtitleUploadDialog] = useState({
    open: false,
    subtitleData: null,
  })

  const [uppy] = useState(
    () =>
      propUppy ||
      new Uppy({
        debug: true,
        autoProceed: true,
        restrictions: {
          allowedFileTypes: [
            TYPE_TO_MIME_TYPE?.[HLS_TRACK_TYPES_MAP.hls_audio],
            TYPE_TO_MIME_TYPE?.[HLS_TRACK_TYPES_MAP.hls_video],
          ],
          maxNumberOfFiles: 1,
        },
      })
        .use(Webcam)
        .use(AwsS3, {
          shouldUseMultipart: true,
          companionUrl: `${getUppyUrl()}/companion`,
          // companionUrl: 'http://172.16.45.7:3000/companion',
        }),
  )

  function toggleShowModal() {
    uppy.cancelAll()
    setShowModal((prev) => !prev)
  }

  function changeTrackType(e) {
    handleChange((prev) => ({ ...TrackResponse, type: e.target.value }))
    uppy.setOptions({
      restrictions: { allowedFileTypes: [TYPE_TO_MIME_TYPE?.[e.target.value] || ''] },
    })
  }

  function changeSubtitle(subtitleId, subtitleLanguage) {
    handleChange((prev) => ({
      ...prev,
      subtitles: {
        ...prev?.subtitles,
        [subtitleLanguage]: {
          subtitleFileId: subtitleId,
          subtitleLanguageCode: subtitleLanguage,
        },
      },
    }))
  }

  function closeSubtitleDialog() {
    setShowSubtitleUploadDialog({ open: false, subtitleData: null })
  }

  uppy?.off('complete', null)?.once('complete', async (result) => {
    const metaDataToUpload = await handleResults(result, trackObject)

    if (!!metaDataToUpload && Object.keys(metaDataToUpload)?.length > 0 && !handlingUploads) {
      setHandlingUploads(true)
      const resp = await postFunction(TRACK_UPLOAD_API_URLS.s3, metaDataToUpload, false, false)

      if (resp) {
        const responseTrackType = resp?.mimeType?.includes('audio')
          ? HLS_TRACK_TYPES?.[0]?.id
          : HLS_TRACK_TYPES?.[1]?.id
        let orientation = ''
        if (responseTrackType === HLS_TRACK_TYPES_MAP.hls_video) {
          orientation =
            parseInt(resp?.height) > parseInt(resp?.width)
              ? VIDEO_ORIENTATION_TYPES[1]?.id
              : VIDEO_ORIENTATION_TYPES[0]?.id
        }

        handleChange((prev) => ({
          ...prev,
          duration: updateDurationChangeTransformer(resp?.duration),
          fileId: resp?.fileId,
          url: `${getMediaUrl()}/${resp?.transcoding?.hls?.url}`,
          type: resp?.mimeType?.includes('audio')
            ? HLS_TRACK_TYPES?.[0]?.id
            : HLS_TRACK_TYPES?.[1]?.id,
          trackOrientation: orientation,
        }))
      }
      setHandlingUploads(false)
    }
  })

  useEffect(() => {
    !checkIfStringIsValid(trackObject?.type) &&
      handleChange({ target: { id: 'type', value: HLS_TRACK_TYPES?.[1]?.id } })
    // uppy.setOptions({
    //   restrictions: {
    //     allowedFileTypes: [
    //       TYPE_TO_MIME_TYPE?.[HLS_TRACK_TYPES_MAP.hls_audio],
    //       TYPE_TO_MIME_TYPE?.[HLS_TRACK_TYPES_MAP.hls_audio],
    //     ],
    //   },
    // })
  }, [trackObject?.type, disabled])

  return (
    <div className="d-flex flex-column align-items-center gap-2 w-100">
      <div className="d-flex align-items-end gap-2 w-100">
        {disabled && (
          <div className="d-flex flex-column align-items-start gap-2">
            {currentTrackType ? (
              <ButtonBase
                disableTouchRipple
                className="w-100"
                sx={
                  showTrackOutsideOnDisabled
                    ? { cursor: 'default !important' }
                    : { color: 'blue', textDecoration: 'underline' }
                }
                onClick={() => (showTrackOutsideOnDisabled ? () => {} : setShowTrackOnDialog(true))}
              >
                Track Type: {currentTrackType?.title}
              </ButtonBase>
            ) : (
              <div>Track Type: NA</div>
            )}
          </div>
        )}

        {!disabled && !hideTrackTypeChange && children && (
          <div className="d-flex flex-column gap-2 w-100">
            {!disabled && !hideTrackTypeChange && (
              <CustomReactSelect
                id="type"
                menuPortalTarget={document.body}
                value={trackObject?.type}
                disabled={disabled}
                label={'Track Type'}
                selectType={selectTypes.string}
                getOptionLabel={(option) => option?.title}
                getOptionValue={(option) => option?.id}
                options={HLS_TRACK_TYPES}
                onChangeEvent={changeTrackType}
              />
            )}
            {children}
          </div>
        )}
        {!disabled && (
          <div className="d-flex align-items-center gap-2">
            <Button
              onClick={toggleShowModal}
              sx={{ flexShrink: 0 }}
              variant="contained"
              endIcon={<Upload />}
            >
              {trackObject?.url ? 'Change' : 'Upload'}
            </Button>
            {contentBesideUploadButton}
          </div>
        )}
      </div>
      <Dialog open={showModal} onClose={toggleShowModal} PaperComponent={TrackUploadDialogPaper}>
        <DialogTitle>Upload Media</DialogTitle>
        <DialogContent>
          <Dashboard
            uppy={uppy}
            waitForThumbnailsBeforeUpload
            plugins={['Webcam']}
            height={500}
            doneButtonHandler={toggleShowModal}
            theme={theme === 'dark' ? 'dark' : 'light'}
          />
        </DialogContent>
      </Dialog>
      {disabled && showTrackOutsideOnDisabled && (
        <InLineTrackWrapper trackObject={trackObject} currentTrackType={currentTrackType}>
          {trackObject?.url ? (
            <ReactPlayer
              url={`${trackObject?.url}`}
              style={{
                padding: '10px',
                maxWidth: '400px',
                maxHeight: '200px',
              }}
              config={{
                file: {
                  hlsOptions: {
                    forceHLS: true,
                    debug: true,
                    xhrSetup: function (xhr, url) {
                      xhr.setRequestHeader('Authorization', getTokenFromLocalStorage())
                    },
                  },
                },
              }}
              height={trackObject?.type === HLS_TRACK_TYPES?.[0].id ? '70px' : '200px'}
              width={'100%'}
              controls={true}
            />
          ) : (
            <div
              className={`border border-2 ${
                getRequiredTrueForTrack(required, trackObject?.url)
                  ? 'border-danger'
                  : 'border-dark'
              } p-3 rounded w-100`}
              style={{ maxWidth: '400px' }}
            >
              <em>No Track Uploaded</em>
            </div>
          )}
        </InLineTrackWrapper>
      )}

      {!disabled && trackObject?.url && (
        <InLineTrackWrapper trackObject={trackObject} currentTrackType={currentTrackType}>
          <ReactPlayer
            url={`${trackObject?.url}`}
            style={{
              padding: '10px',
              maxWidth: '400px',
              maxHeight: '200px',
            }}
            height={trackObject?.type === HLS_TRACK_TYPES?.[0].id ? '70px' : '200px'}
            width={'100%'}
            controls={true}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'true',
                },
                tracks: [
                  {
                    kind: 'subtitles',
                    src: `${getMediaImageUrl()}${trackObject?.subtitleFileId}`,
                    srcLang: trackObject?.subtitleLanguage,
                    default: true,
                  },
                ],
              },
            }}
          />
        </InLineTrackWrapper>
      )}

      <div className="d-flex flex-column gap-2 w-100">
        <div className="d-flex align-items-center justify-content-between">
          <h6 style={{ alignSelf: 'flex-start' }}>Subtitles</h6>
          {!disabled && (
            <IconButton
              onClick={() => {
                setShowSubtitleUploadDialog({
                  open: true,
                  subtitleData: {
                    languageCode: null,
                    subtitleFileId: null,
                  },
                })
              }}
            >
              <Add />
            </IconButton>
          )}
        </div>
        <CCExtendedCSmartTable
          items={subtitlesListing}
          columns={[
            getCSmartTableColumnData({
              key: 'languageCode',
              label: 'Subtitle Language',
              filter: false,
              sorter: true,
              _style: { minWidth: '200px' },
            }),
          ]}
          viewOnly={disabled}
          scopedColumns={subtitleScopedColumns(store.languages)}
          onRowClick={(item) => setShowSubtitleUploadDialog({ open: true, subtitleData: item })}
          clickableRows
          noUserNames
          excludeGenericFields={[
            CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
            CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
            CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
            CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
          ]}
        />
      </div>

      <Dialog
        open={showSubtitleUploadDialog.open}
        onClose={closeSubtitleDialog}
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
      >
        <TrackSubtitles
          allSubtitles={subtitlesListing}
          selectedSubtitle={showSubtitleUploadDialog?.subtitleData}
          closeSubtitleDialog={closeSubtitleDialog}
          changeSubtitles={changeSubtitle}
          trackObject={trackObject}
        />
      </Dialog>

      <Dialog
        open={showTrackOnDialog}
        onClose={() => setShowTrackOnDialog(false)}
        PaperComponent={TrackPlayerDialogPaper}
      >
        <DialogContent>
          <ReactPlayer
            url={`${trackObject?.url}`}
            style={{
              padding: '10px',
              maxWidth: '400px',
              maxHeight: '200px',
            }}
            height={trackObject?.type === HLS_TRACK_TYPES?.[0].id ? '70px' : '200px'}
            width={'100%'}
            controls={true}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

function subtitleScopedColumns(languages) {
  return {
    languageCode: (item) => {
      return <td>{languages.find((lang) => lang.id === item.languageCode)?.languageName}</td>
    },
  }
}

function InLineTrackWrapper({ children, trackObject, currentTrackType }) {
  return (
    <div className={'d-flex flex-column gap-2 w-100 align-items-center'}>
      <h6 className={'align-self-start'}>
        Uploaded {currentTrackType?.title || 'Media'}{' '}
        {trackObject?.url && (
          <Tooltip title={`${trackObject?.url}`}>
            <IconButton
              onClick={() => {
                copyToClipBoard(`${currentTrackType?.title} URL`, trackObject?.url)
              }}
            >
              <CopyAll />
            </IconButton>
          </Tooltip>
        )}
      </h6>
      {children}
    </div>
  )
}

export default TrackUploadComponent
