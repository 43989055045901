import { CFormInput } from '@coreui/react-pro'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import React from 'react'
import { validateID } from 'src/views/setup/userStats/stats-utils'

export default function SummarizableFormFieldsWrapper({
  data,
  handleChange,
  disabled,
  showIdField = false,
  allListData,
  initialObject,
  idDisabled = false,
  hideTitleField,
}) {
  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          labelPlacement="start"
          control={<Switch id="enabled" checked={!!data?.enabled} onChange={handleChange} />}
          label="Enabled: "
          disabled={disabled}
        />
      </Grid>
      {showIdField && (
        <Grid item xs={6}>
          <CFormInput
            id="id"
            label="ID"
            value={data?.id || ''}
            disabled={disabled || idDisabled}
            placeholder="Enter ID..."
            required
            onChange={handleChange}
            invalid={validateID(data, allListData, 'id', initialObject)}
          />
          <input
            value=""
            style={{ display: 'none' }}
            required={validateID(data, allListData, 'id', initialObject)}
          />
        </Grid>
      )}
      {!hideTitleField && (
        <Grid item xs={12} md={showIdField ? 6 : 12}>
          <CFormInput
            id="title"
            label="Title"
            value={data?.title || ''}
            disabled={disabled}
            placeholder="Enter title..."
            required
            onChange={handleChange}
          />
        </Grid>
      )}
    </>
  )
}
