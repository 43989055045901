import { Paper } from '@mui/material'
import React from 'react'
import { openInNewTab } from 'src/utilities/generalUtils'
import {
  InAppMessageLandScapeImage,
  InAppMessagePortraitActionBtn,
  InAppMessagePortraitImage,
  InAppMessagePortraitInteractionContainer,
  InAppMessagePortraitMessageBox,
  InAppMessagePortraitSubTitle,
  InAppMessagePortraitTitle,
  LandScapeInAppMessageContainer,
  PortraitInAppMessageContainer,
} from './styledComponents'

export default function InAppMessagePreviewComponent({ messageData }) {
  return (
    <Paper className="w-100 d-flex flex-column gap-2 h-100 p-3">
      <h3>Device Preview</h3>
      <p className="fs-7">
        This preview provides a general idea of how your message will appear on a mobile device.
        Actual message rendering will vary depending on the device. Test with a real device for
        actual results.
      </p>
      <div className="w-100 d-flex align-items-center gap-3 flex-wrap">
        <PortraitInAppMessageComponent inAppMessage={messageData} />
        <LandScapeInAppMessageComponent inAppMessage={messageData} />
      </div>
    </Paper>
  )
}

function PortraitInAppMessageComponent({ inAppMessage }) {
  return (
    <PortraitInAppMessageContainer style={{ backgroundColor: inAppMessage?.templateData?.bgColor }}>
      <InAppMessagePortraitImage src={inAppMessage?.templateData?.imageUrl} />
      <div className="h-100">
        <InAppMessagePortraitInteractionContainer>
          <InAppMessagePortraitTitle style={{ color: inAppMessage?.templateData?.textColor }}>
            {inAppMessage?.templateData?.title}
          </InAppMessagePortraitTitle>
          <InAppMessagePortraitSubTitle style={{ color: inAppMessage?.templateData?.subTextColor }}>
            {inAppMessage?.templateData?.subText}
          </InAppMessagePortraitSubTitle>
          {inAppMessage?.templateData?.textBox && (
            <InAppMessagePortraitMessageBox
              style={{
                color: inAppMessage?.templateData?.msgBoxTxtColor,
                background: inAppMessage?.templateData?.msgBoxBgColor,
              }}
            >
              {inAppMessage?.templateData?.textBoxText}
            </InAppMessagePortraitMessageBox>
          )}
          {inAppMessage?.templateData?.actionBtn && (
            <InAppMessagePortraitActionBtn
              onClick={() => {
                openInNewTab(inAppMessage?.templateData?.actionBtnUri)
              }}
            >
              {inAppMessage?.templateData?.actionBtnText}
            </InAppMessagePortraitActionBtn>
          )}
          {inAppMessage?.templateData?.actionBtn && inAppMessage?.templateData?.extraLink && (
            <div style={{ color: 'white' }}> OR</div>
          )}
          {inAppMessage?.templateData?.extraLink && (
            <InAppMessagePortraitActionBtn
              style={{ background: 'unset' }}
              onClick={() => {
                openInNewTab(inAppMessage?.templateData?.extraLinkUri)
              }}
            >
              {inAppMessage?.templateData?.extraLinkText}
            </InAppMessagePortraitActionBtn>
          )}
        </InAppMessagePortraitInteractionContainer>
      </div>
    </PortraitInAppMessageContainer>
  )
}

function LandScapeInAppMessageComponent({ inAppMessage }) {
  return (
    <LandScapeInAppMessageContainer
      style={{ backgroundColor: inAppMessage?.templateData?.bgColor }}
    >
      <InAppMessageLandScapeImage src={inAppMessage?.templateData?.imageUrl} />
      <InAppMessagePortraitInteractionContainer>
        <InAppMessagePortraitTitle style={{ color: inAppMessage?.templateData?.textColor }}>
          {inAppMessage?.templateData?.title}
        </InAppMessagePortraitTitle>
        <InAppMessagePortraitSubTitle style={{ color: inAppMessage?.templateData?.subTextColor }}>
          {inAppMessage?.templateData?.subText}
        </InAppMessagePortraitSubTitle>
        {inAppMessage?.templateData?.textBox && (
          <InAppMessagePortraitMessageBox
            style={{
              color: inAppMessage?.templateData?.msgBoxTxtColor,
              background: inAppMessage?.templateData?.msgBoxBgColor,
            }}
          >
            {inAppMessage?.templateData?.textBoxText}
          </InAppMessagePortraitMessageBox>
        )}
        {inAppMessage?.templateData?.actionBtn && (
          <InAppMessagePortraitActionBtn
            onClick={() => {
              openInNewTab(inAppMessage?.templateData?.actionBtnUri)
            }}
          >
            {inAppMessage?.templateData?.actionBtnText}
          </InAppMessagePortraitActionBtn>
        )}
        {inAppMessage?.templateData?.actionBtn && inAppMessage?.templateData?.extraLink && (
          <div style={{ color: 'white' }}> OR</div>
        )}
        {inAppMessage?.templateData?.extraLink && (
          <InAppMessagePortraitActionBtn
            style={{ background: 'unset' }}
            onClick={() => {
              openInNewTab(inAppMessage?.templateData?.extraLinkUri)
            }}
          >
            {inAppMessage?.templateData?.extraLinkText}
          </InAppMessagePortraitActionBtn>
        )}
      </InAppMessagePortraitInteractionContainer>
    </LandScapeInAppMessageContainer>
  )
}
