import { ButtonBase, SwipeableDrawer, styled as muiStyled } from '@mui/material'

export const StyledColorPickerContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    background: #00000030;
    padding: 7px;
    border-radius: 7px;
    flex-shrink: 0;
    & label {
        margin: 0 !important;
    }
`,
)

export const PortraitInAppMessageContainer = muiStyled('div')(
  ({ theme }) => `
    aspect-ratio: 0.46;
    height: 400px;
    display: flex;
    flex-direction: column;
`,
)

export const InAppMessagePortraitImage = muiStyled('img')(
  ({ theme }) => `
  width: 100%;
  flex-shrink:0;
`,
)

export const InAppMessageLandScapeImage = muiStyled('img')(
  ({ theme }) => `
  height: 100%;
  flex-shrink:0;
`,
)
export const InAppMessagePortraitInteractionContainer = muiStyled('div')(
  ({ theme, background }) => `
  width: 100%;
  flex-grow:1;
  background : ${background};
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items:center;
  justify-content: center;
  font-size: 10px;
  text-align:center;
  padding: 6px;
`,
)

export const InAppMessagePortraitTitle = muiStyled('h1')(
  ({ theme }) => `
  font-size: 1.5em;
  margin-bottom: 0px;
`,
)

export const InAppMessagePortraitSubTitle = muiStyled('div')(
  ({ theme }) => `
  font-size: 1.1em;
`,
)

export const InAppMessagePortraitMessageBox = muiStyled('div')(
  ({ theme }) => `
  font-size: 1em;
  border-radius: 0.3em;
  padding: 0.3em;
`,
)

export const InAppMessagePortraitActionBtn = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 0.3em 0.7em;
  border-radius: 0.3em;
  background: ${theme.palette.primary.main};
  color: white;
`,
)
export const LandScapeInAppMessageContainer = muiStyled('div')(
  ({ theme }) => `
    aspect-ratio: 16/9;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 450px;
`,
)

export const InAppMsgSwipeablePreview = muiStyled(SwipeableDrawer)(
  ({ theme, drawerBleed }) => `
  & .MuiDrawer-paper {  
    height: calc(100% - ${drawerBleed});
    overflow-y: auto;
  }
`,
)

export const InAppMsgSwipeablePreviewOpenButton = muiStyled(ButtonBase)(
  ({ theme, open }) => `
  width: 100%;
  background: ${theme.palette.background.paper};
  height: 40px;
  > .MuiSvgIcon-root {
    transform: rotate(${!open ? '0deg' : '180deg'});
  }
`,
)
