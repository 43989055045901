import axios from 'axios'
import { toast } from 'react-toastify'
import { apiURL } from 'src/services'

const API_URL = [apiURL, '/api/secure/cc/feature'].join('')

const token = localStorage.getItem('token')
const Headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
}

export function GetFeatureData(url, Params = {}) {
  try {
    const response = axios.get(`${API_URL}${url}`, {
      headers: Headers,
      params: Params,
    })
    return response
  } catch (error) {
    console.log(error)
    toast.error('Something went wrong while getting feature data')
    return error
  }
}
