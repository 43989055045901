import { ArrowBack } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { LINK_TYPES } from 'src/utilities/constants'
import { genericHandleChange } from 'src/utilities/generalUtils'
import { LinkMakerComponentsContext, defaultLinkMakerContextData } from '../../LinksMakerComponents'
import { buildURI } from './buildFInalURIs'
import ChooseLinkTypeComponent from './chooseLinkType/ChooseLinkTypeComponent'
import DeepLinkComponent from './deepLink/DeepLinkComponent'
import HyperLinkComponent from './hyperLink/HyperLinkComponent'

export default function LinksMakerFactoryComponent({
  externalLinksData,
  handleChange,
  itemTitle,
  closeEditDialog,
}) {
  const { linkMakerContextData, setLinkMakerContextData } = useContext(LinkMakerComponentsContext)
  const [pageStack, setPageStack] = useState([])
  const [externalLinks, setExternalLinks] = useState()
  const [externalLinksContextData, setExternalLinksContextData] = useState({
    ...defaultLinkMakerContextData,
    extraData: null,
  })

  const ComponentToUse = useMemo(() => {
    switch (pageStack[pageStack.length - 1]) {
      case 'choose':
        return ChooseLinkTypeComponent
      case LINK_TYPES.deep:
        return DeepLinkComponent
      case LINK_TYPES.hyperlink:
        return HyperLinkComponent
      default:
        return React.Fragment
    }
  }, [pageStack])

  function addPage(page) {
    setPageStack([...pageStack, page])
  }
  function popPage() {
    setPageStack([...pageStack].slice(0, -1))
  }

  function changeExternalLinks(evePrev) {
    genericHandleChange(evePrev, setExternalLinks)
  }
  function renderTitle() {
    switch (pageStack[pageStack.length - 1]) {
      case LINK_TYPES.deep:
        return 'Choose Description Type'
      case LINK_TYPES.hyperlink:
        return 'Change Description'
      default:
        return 'Edit Link'
    }
  }

  function handleSubmit() {
    const linkType = pageStack[pageStack.length - 1]
    if (linkType === LINK_TYPES.deep) {
      if (!externalLinksContextData.metaCategoryId) {
        return toast.warn('Please select a link type')
      }
    } else {
      if (!externalLinks.uri) {
        return toast.warn('Please enter a link')
      }
    }

    const dataToSet = buildURI(externalLinks, externalLinksContextData, linkType)
    linkType === LINK_TYPES.hyperlink && setLinkMakerContextData(defaultLinkMakerContextData)
    handleChange(dataToSet)

    closeEditDialog()
  }

  useEffect(() => {
    setPageStack(['choose'])
    setExternalLinks(externalLinksData)
    setExternalLinksContextData(linkMakerContextData)
  }, [])

  useEffect(() => {
    console.log({ externalLinks, externalLinksContextData })
  }, [externalLinks, externalLinksContextData])

  return (
    <>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: pageStack.length > 1 ? '10px' : '30px',
          gap: '10px',
        }}
      >
        {pageStack.length > 1 && (
          <IconButton onClick={popPage}>
            <ArrowBack sx={{ fontSize: '25px' }} />
          </IconButton>
        )}
        <span>{renderTitle()}</span>
      </DialogTitle>
      <DialogContent>
        <ComponentToUse
          externalLinksData={externalLinks}
          handleChangeExternalLinks={changeExternalLinks}
          // popPage={popPage}
          addPage={addPage}
          externalLinksContextData={externalLinksContextData}
          setExternalLinksContextData={setExternalLinksContextData}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeEditDialog} color="secondary">
          Close
        </Button>
        {pageStack?.length > 1 && (
          <Button onClick={handleSubmit} variant="contained">
            Set
          </Button>
        )}
      </DialogActions>
    </>
  )
}
