const usersApiBasePath = 'end-user'

export const USERS_API_SIGNATURES_MAP = {
  authorities: {
    addClinicAssistant: `${usersApiBasePath}/add-clinic-assistant-role`,
    removeClinicAssistant: `${usersApiBasePath}/remove-clinic-assistant-role`,
  },
  users: {
    // get: `${usersApiBasePath}/list/all/summary/paginated`,
    get: `${usersApiBasePath}/list/all/paginated`,
    getFullObjects: `${usersApiBasePath}/list/all/paginated`,
  },
  location: {
    get: `${usersApiBasePath}/location/short-links`,
  },
  userTags: {
    get: `${usersApiBasePath}/user-tags`,
  },

  subscriptions: {
    subscriptionHistory: {
      get: `${usersApiBasePath}/subscriptions`,
    },
    donationHistory: {
      get: `${usersApiBasePath}/donations`,
    },
    transactionHistory: {
      get: `${usersApiBasePath}/payments-and-transactions`,
    },
  },

  usageDetails: {
    selfPractices: {
      get: `${usersApiBasePath}/techniques`,
    },
    pranaVayu: {
      get: `${usersApiBasePath}/prana-vayu-techniques`,
    },
    onlineCourseSessions: {
      get: `${usersApiBasePath}/online-course/sessions/details`,
    },
    plans: {
      get: `${usersApiBasePath}/plans`,
    },
    offlineClasses: {
      get: `${usersApiBasePath}/offline-classes`,
    },
  },
  devicesAndExperience: {
    devices: {
      get: `${usersApiBasePath}/devices`,
    },
    segmentHistory: {
      get: `${usersApiBasePath}/segments/history`,
    },
  },
  paymentLinks: {
    linksDetails: {
      get: `${usersApiBasePath}/payment-links`,
      save: `${usersApiBasePath}/payment-links`,
      notify: `${usersApiBasePath}/payment-links/notify`,
    },
  },
  legacyPlans: {
    customPlans: {
      get: `${usersApiBasePath}/custom-plans`,
    },
  },
  subscription: {
    add: {
      post: `${usersApiBasePath}/subscription/add`,
    },
  },

  appPolicy: {
    get: `${usersApiBasePath}/app-policy`,
  },
}
