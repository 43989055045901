import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const fetchAllFieldTypes = createAsyncThunk(
  'courseCmsForm/fetchAllFieldTypes',
  async (_, { getState, fulfillWithValue, rejectWithValue, dispatch }) => {
    fetchAllFieldTypes.pending()
    const { courseCmsForm } = getState()
    const allFieldTypes = courseCmsForm.allFieldTypes.data

    //TODO: CACHE IMPLEMENTATION
    // if()

    const resp = await GetSettingsData(GENERIC_APIS.entity.getSummary, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.courseCmsFieldType,
    })
    if (resp.status === 200) {
      return resp.data
    } else {
      return rejectWithValue(resp.message)
    }
  },
)
