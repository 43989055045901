import { Close } from '@mui/icons-material'
import { DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { CustomReactPlayer } from 'src/components/controlCenter/common/customReactPlayer/CustomReactPlayer'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import {
  ALL_ENTITY_TYPES_FROM_CONSTANTS,
  HLS_TRACK_TYPES,
  ONLINE_COURSE_TYPES,
} from 'src/utilities/constants'
import { getCorrectTargetValueForEvent } from 'src/utilities/generalUtils'

export default function OnlineCourseListingTrackPlayersComponent({ onlineCourse, handleClose }) {
  const [selectedSession, setSelectedSession] = useState(null)
  const [loading, setLoading] = useState(false)
  const [bgMusic, setBgMusic] = useState()

  const trackProps = (trackObject) => ({
    style: {
      padding: '10px',
      maxWidth: '400px',
      maxHeight: '200px',
    },
    height: trackObject?.type === HLS_TRACK_TYPES?.[0].id ? '70px' : '200px',
    width: '100%',
    useBasicReactPlayer: true,
  })

  const trackOptions = useMemo(() => {
    return onlineCourse?.sessions?.map((sess, idx) => ({ ...sess, sessIdx: idx }))
  }, [onlineCourse])

  function noTrackUploadBox() {
    return <></>
  }

  function trackPlayer(type) {
    return (
      <CustomReactPlayer
        url={selectedSession?.steps?.[type]?.track?.url}
        controls
        {...trackProps(selectedSession?.steps?.[type]?.track)}
      />
    )
  }
  async function getBGMusic(bgMusicId) {
    const resp = await GetDetailedEntity(ALL_ENTITY_TYPES_FROM_CONSTANTS.backgroundMusic, {
      id: bgMusicId,
    })
    if (resp) {
      setBgMusic(resp)
      return resp
    }
  }

  async function Setup() {
    setLoading(true)
    if (selectedSession?.bgMusicEnabled) {
      await setBgMusic()
      getBGMusic(selectedSession?.bgMusicId)
    }
    setLoading(false)
  }

  useEffect(() => {
    Setup()
  }, [selectedSession])

  useEffect(() => {
    if (trackOptions.length) {
      setSelectedSession(trackOptions?.[0])
    }
  }, [trackOptions])

  return (
    <>
      <DialogTitle display="flex" alignItems="center" justifyContent="space-between">
        <span>Course Tracks</span>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '10px!important' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {onlineCourse?.type === ONLINE_COURSE_TYPES?.[1]?.id ? (
              <CustomReactSelect
                id="session-tracks"
                label="Session"
                options={trackOptions}
                getOptionLabel={(optn) => `#${optn?.sessIdx + 1}. ${optn?.title}`}
                getOptionValue={(optn) => optn?.sessIdx}
                value={selectedSession}
                onChangeEvent={(e) => setSelectedSession(getCorrectTargetValueForEvent(e))}
                selectType={selectTypes.object}
                menuPlacement="auto"
                menuPortalTarget={document.body}
              />
            ) : (
              <span>
                <strong>Session: </strong>
                <span>{selectedSession?.title}</span>
              </span>
            )}
          </Grid>
          {!selectedSession?.steps?.preInstructions?.track?.url &&
            !selectedSession?.steps?.preparatory?.track?.url &&
            !selectedSession?.steps?.meditate?.track?.url &&
            !selectedSession?.steps?.postInstructions?.track?.url &&
            !selectedSession?.bgMusicEnabled && (
              <Grid item xs={12}>
                <h6>
                  <em>No Track are available for this session</em>
                </h6>
              </Grid>
            )}
          <Grid item xs={12}>
            {selectedSession?.steps?.preInstructions?.track?.url ? (
              <>
                <h4>Pre Track</h4>
                {trackPlayer('preInstructions')}
              </>
            ) : (
              noTrackUploadBox()
            )}
          </Grid>
          <Grid item xs={12}>
            {selectedSession?.steps?.preparatory?.track?.url ? (
              <>
                <h4>Preparatory Track</h4>
                {trackPlayer('preparatory')}
              </>
            ) : (
              noTrackUploadBox()
            )}
          </Grid>
          <Grid item xs={12}>
            {selectedSession?.steps?.meditate?.track?.url ? (
              <>
                <h4>Main Track</h4>
                {trackPlayer('meditate')}
              </>
            ) : (
              noTrackUploadBox()
            )}
          </Grid>
          <Grid item xs={12}>
            {selectedSession?.steps?.postInstructions?.track?.url ? (
              <>
                <h4>Post Track</h4>
                {trackPlayer('postInstructions')}
              </>
            ) : (
              noTrackUploadBox()
            )}
          </Grid>
          {selectedSession?.bgMusicEnabled && (
            <>
              {bgMusic ? (
                <Grid item xs={12}>
                  <h4>Background Music: {loading ? 'loading...' : ''}</h4>
                  <CustomReactPlayer
                    url={bgMusic?.hlsUrl}
                    controls
                    {...trackProps({ type: HLS_TRACK_TYPES?.[0].id })}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <div
                    className="border border-2 border-dark border-dark p-3 rounded w-100"
                    style={{ maxWidth: '400px' }}
                  >
                    <em>No Background Track Uploaded</em>
                  </div>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
    </>
  )
}
