import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'

export async function transformListingDataForCourseApprovalRequests({ listingData }) {
  // const [ocUpdatedList] = await Promise.all([getApprovedByUserNames(listingData)])

  return listingData
}

///work on this was left mid way because i thought it was not needed
async function getApprovedByUserNames(ocList) {
  const userIdsToUse = []
  const createdByUserIds = ocList?.map((approvalReq) => approvalReq?.courseDraft?.createdBy)
  const uniqueUserIds = [...new Set([...userIdsToUse, ...createdByUserIds])]

  const foundUsers = await getUsersSummaryFromUserIds(uniqueUserIds)
  return ocList?.map((unPublishingRequest) => {
    const foundCreatingUser = foundUsers?.find(
      (user) => user?.userId === unPublishingRequest?.createdBy,
    )
    return {
      ...unPublishingRequest,

      createdBy: foundCreatingUser?.name,
      onlineCourse: {
        ...(unPublishingRequest?.onlineCourse || {}),
      },
    }
  })
}

async function getCourseDraftsForApprovalRequests(requests) {
  const draftIds = requests?.map((request) => request?.contentId)
  const foundDraftsResp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.courseDraft,
    size: 999,
    ids: draftIds?.join(','),
  })
  if (foundDraftsResp?.status !== 200) return requests
  return requests?.map((approvalRequest) => {
    const foundDraft = foundDraftsResp?.data?.content?.find(
      (draft) => draft?.id === approvalRequest?.contentId,
    )
    return {
      ...approvalRequest,
      courseDraft: foundDraft,
    }
  })
}
