import { CopyAll } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS, currency_list } from 'src/utilities/constants'
import { copyToClipBoard } from 'src/utilities/generalUtils'

export default function SubscriptionHistory({ userId, data, setData }) {
  const [loading, setLoading] = useState(false)

  async function getSubscriptionHistory() {
    const resp = await GetSettingsData(
      USERS_API_SIGNATURES_MAP.subscriptions.subscriptionHistory.get,
      { userId, size: 200 },
    )
    if (resp.status === 200) {
      setData((prev) => ({ ...prev, data: resp.data.content }))
    }
  }

  async function Setup() {
    if (data?.data?.length <= 0 || !data?.data) {
      setLoading(true)
      await getSubscriptionHistory()
    }
    setLoading(false)
  }

  useEffect(() => {
    Setup()
  }, [userId])

  return (
    <CCExtendedCSmartTable
      noUserNames
      items={data?.data}
      loading={loading}
      excludeGenericFields={[
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
      ]}
      columns={columns}
      scopedColumns={scopedColumns}
    />
  )
}

const columns = [
  {
    key: 'txnId',
    label: 'ID',
    filter: false,
    sorter: false,
  },
  {
    key: 'title',
    label: 'Package Name',
    filter: false,
    sorter: false,
    _style: { minWidth: '330px' },
  },
  {
    key: 'packageType',
    label: 'Package Type',
    filter: false,
    sorter: false,
  },
  {
    key: 'type',
    label: 'Event',
    filter: false,
    sorter: false,
  },
  {
    key: 'purchase',
    label: 'Biller',
    filter: false,
    sorter: false,
  },
  {
    key: 'amount',
    label: 'Amount',
    filter: false,
    sorter: false,
  },
  {
    key: 'currencyCode',
    label: 'Currency',
    filter: false,
    sorter: false,
  },
  {
    key: 'subscriptionTime',
    label: 'Start Time',
    filter: false,
    sorter: false,
  },
]

const scopedColumns = {
  title: (item) => {
    return (
      <td>
        <Tooltip title={item?.userSubscription?.subscriptionPlan?.id}>
          <div className="d-flex align-items-center gap-2">
            <div>{item?.userSubscription?.subscriptionPlan?.title}</div>
            <IconButton
              onClick={() => {
                copyToClipBoard('Package ID', item?.userSubscription?.subscriptionPlan?.id)
              }}
            >
              <CopyAll />
            </IconButton>
          </div>
        </Tooltip>
      </td>
    )
  },
  packageType: (item) => {
    return <td>{item?.userSubscription?.subscriptionPlan?.subscriptionType?.name}</td>
  },
  purchase: (item) => {
    if (item?.platformType !== '' && item?.platformType !== null) {
      return <td>{item?.platformType}</td>
    }
    if (item?.txnId.includes('GPA')) {
      return <td>Android</td>
    }
    return <td>iOS</td>
  },
  currencyCode: (item) => {
    if (item?.userSubscription) {
      return (
        <td>
          {currency_list?.find(
            (curList) => curList?.code === item?.userSubscription?.currencyCode?.toLowerCase(),
          )?.symbol || item?.userSubscription?.currencyCode?.toLowerCase()}
        </td>
      )
    }
    return <td>NA</td>
  },

  amount: (item) => {
    if (item?.userSubscription) {
      return <td>{item.userSubscription.amount}</td>
    }
    return <td>NA</td>
  },
  subscriptionTime: (item) => {
    return (
      <td>
        {moment(item?.subscriptionTime).format(
          MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
        )}
      </td>
    )
  },
}
