import { Switch } from '@mui/material'
import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { IdTypes, MENTOR_EXTRA_ITEM_OPTIONS } from 'src/utilities/constants'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { loadCategoryTypeBasedItems, loadDLMentorEvents } from '../../utils'

export default function DLSlugItemMentor({
  categoryType,
  item,
  handleChangeItem,
  extraData,
  handleChangeExtraData,
  toggleExtraData,
  extraItem,
  changeExtraItem,
  dropDownOptions,
  uiContextData,
}) {
  console.log({ extraItem })

  return (
    <>
      <div>
        <label>{uiContextData?.linkIdSelectionLabel || 'Item'}</label>
        <ActivityItemAsyncSelect
          cacheUniqs={[categoryType]}
          classNamePrefix="activity-item-select"
          menuPortalTarget={document.body}
          value={item?.value ? item : null}
          loadOptions={loadCategoryTypeBasedItems}
          debounceTimeout={1000}
          additional={{
            page: 0,
            size: 200,
            metaCategoryTypeId: categoryType,
          }}
          onChange={(selVal) => handleChangeItem(selVal)}
        />
      </div>
      {item?.value && (
        <>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="py-2 pt-1 text-secondary">Extra Info</h5>
            <Switch checked={!!extraData} onChange={toggleExtraData} size="small" />
          </div>
          {!!extraData && (
            <>
              <div>
                <CustomReactSelect
                  id="metaCategoryId"
                  menuPortalTarget={document.body}
                  value={extraData?.metaCategoryId}
                  options={MENTOR_EXTRA_ITEM_OPTIONS}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.title}
                  label="Link Category Type"
                  placeholder="Select the type of category for this deep link..."
                  selectType={selectTypes.string}
                  onChangeEvent={(eve) => {
                    handleChangeExtraData(eve)
                    changeExtraItem(null)
                  }}
                />
              </div>
              {!!extraData?.metaCategoryId && (
                <>
                  <div>
                    <CustomReactSelect
                      id="idType"
                      menuPortalTarget={document.body}
                      value={extraData?.idType}
                      options={Object.entries(IdTypes)
                        ?.filter((idType) => idType?.[0] !== IdTypes.type)
                        ?.map(([key, value]) => ({
                          id: key,
                          title: value,
                        }))}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.title}
                      label="Link Type"
                      placeholder="Select the type of deep link"
                      selectType={selectTypes.string}
                      onChangeEvent={(eve) => {
                        handleChangeExtraData(eve)
                        changeExtraItem(null)
                      }}
                    />
                  </div>
                  {!!extraData?.idType && extraData?.idType !== IdTypes.list && (
                    <div>
                      <label className="mb-2">Booked Events</label>
                      <ActivityItemAsyncSelect
                        cacheUniqs={[categoryType]}
                        classNamePrefix="activity-item-select"
                        menuPortalTarget={document.body}
                        value={extraItem?.value ? extraItem : null}
                        loadOptions={loadDLMentorEvents}
                        debounceTimeout={1000}
                        additional={{
                          page: 0,
                          size: 200,
                          itemId: item?.value,
                        }}
                        onChange={(selVal) => changeExtraItem(selVal)}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
