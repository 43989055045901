import { Chip } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { MOMENT_FORMATS } from 'src/utilities/constants'

export default function CourseUnpublishRequestStatusComponent({ unPublishRequest }) {
  function renderChip() {
    if (unPublishRequest.status === 'pending') {
      return <Chip label="Pending" color="warning" />
    }
    if (unPublishRequest.status === 'approved') {
      return <Chip label="Approved" color="success" />
    }
    if (unPublishRequest.status === 'rejected') {
      return <Chip label="Rejected" color="error" />
    }
    return <></>
  }
  return (
    <td>
      Created By: {unPublishRequest?.createdBy} on{' '}
      {moment
        .utc(unPublishRequest?.createdTime)
        .local()
        .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
      <br />
      {renderChip()}
    </td>
  )
}
