import { toast } from 'react-toastify'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { ONLINE_COURSE_API_MAP } from 'src/services/apiSignatureMaps/onlineCourse/courseBase/apis'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'

const errorHandler = ({
  tst,
  errorMessage = 'Something went wrong',
  setDisabledActionButtons = () => {},
}) => {
  toast.update(tst, {
    render: errorMessage,
    type: toast.TYPE.ERROR,
    isLoading: false,
    ...TOAST_UTILS,
  })
  setDisabledActionButtons((prev) => ({ ...prev, manipulatorButtons: false }))
}

// Utility function to handle repetitive API call logic
async function handleOnlineCoursePostApiAction({
  apiMapKey,
  actionParams,
  loadingMessage,
  successMessage,
  navigatePath,
  setDisabledActionButtons,
  afterSuccessFunction = () => {},
  navigate,
  postData = {},
}) {
  const tst = toast.loading(loadingMessage)
  setDisabledActionButtons &&
    setDisabledActionButtons((prev) => ({ ...prev, manipulatorButtons: true }))

  try {
    const response = await PostSettingsData(
      ONLINE_COURSE_API_MAP[apiMapKey],
      postData,
      actionParams,
      undefined,
      true,
      undefined,
    )

    if (response.status === 200 && isCommonResponseSuccessful(response.data.code)) {
      toast.update(tst, {
        render: successMessage,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      navigate && navigate(navigatePath)
      afterSuccessFunction(response)
    } else {
      errorHandler({ tst, setDisabledActionButtons })
    }
  } catch (err) {
    errorHandler({ tst })
  } finally {
    setDisabledActionButtons &&
      setDisabledActionButtons((prev) => ({ ...prev, manipulatorButtons: false }))
  }
}

export async function unPublishOnlineCourseApiCall({
  setDisabledActionButtons,
  courseId,
  courseCategory,
  afterSuccessFunction,
  navigate,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'unPublish',
    actionParams: { courseId },
    loadingMessage: 'UnPublishing Course...',
    successMessage: 'Course unpublished successfully!',
    navigatePath: `/online-courses/categories/${courseCategory}/unpublished/${courseId}?mode=View`,
    setDisabledActionButtons,
    afterSuccessFunction,
    navigate,
  })
}

export async function publishOnlineCourseApiCall({
  setDisabledActionButtons,
  courseId,
  courseCategory,
  afterSuccessFunction,
  navigate,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'publish',
    actionParams: { courseId },
    loadingMessage: 'Publishing Course',
    successMessage: 'Course Published',
    navigatePath: `/online-courses/categories/${courseCategory}/content-publisher/published/${courseId}?mode=View`,
    setDisabledActionButtons,
    afterSuccessFunction,
    navigate,
  })
}

export async function rejectReqToUnpublishOnlineCourseApiCall({
  setDisabledActionButtons,
  requestId,
  courseId,
  courseCategory,
  afterSuccessFunction,
  navigate,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'rejectReqToUnpublish',
    actionParams: { requestId },
    loadingMessage: 'Rejecting Request to Unpublish Course',
    successMessage: 'Request to Unpublish Rejected',
    navigatePath: `/online-courses/categories/${courseCategory}/content-publisher/published/${courseId}?mode=View`,
    setDisabledActionButtons,
    afterSuccessFunction,
    navigate,
  })
}

export async function approveReqToUnpublishOnlineCourseApiCall({
  setDisabledActionButtons,
  requestId,
  courseId,
  courseCategory,
  afterSuccessFunction,
  navigate,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'approveReqToUnpublish',
    actionParams: { requestId },
    loadingMessage: 'Accepting Request to Unpublish Course',
    successMessage: 'Request to Unpublish Accepted',
    navigatePath: `/online-courses/categories/${courseCategory}/content-publisher/unpublished/${courseId}?mode=View`,
    setDisabledActionButtons,
    afterSuccessFunction,
    navigate,
  })
}

export async function reqToUnpublishOnlineCourseApiCall({
  setDisabledActionButtons,
  courseId,
  courseCategory,
  afterSuccessFunction,
  navigate,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'reqToUnpublish',
    actionParams: {},
    loadingMessage: 'Requesting to Unpublish Course',
    successMessage: 'Request to Unpublish Sent',
    navigatePath: `/online-courses/categories/${courseCategory}/content-publisher/published/${courseId}?mode=View`,
    setDisabledActionButtons,
    afterSuccessFunction,
    navigate,
    postData: {
      contentId: courseId,
      contentType: ALL_ENTITY_TYPES.onlineCourse,
      apiParam: ALL_ENTITY_TYPES.unPublishRequest,
    },
  })
}

/////////////////////////////FOR CONTENT EDITOR ///////////////////////////////////////////////////////////////////
export async function submitCourseDraftForApprovalApiCall({
  setDisabledActionButtons,
  draftId,
  contentType,
  courseCategory,
  afterSuccessFunction,
  navigate,
  approvalRequestObject,
  navigatePath,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'submitForApproval',
    actionParams: { draftId, contentType },
    loadingMessage: 'Submitting Request for Approval',
    successMessage: 'Request Submitted Successfully',
    setDisabledActionButtons,
    afterSuccessFunction,
    postData: approvalRequestObject,
    navigate: navigate,
    navigatePath,
  })
}

export async function discardApprovalRequestApiCall({
  setDisabledActionButtons,
  draftId,
  courseCategory,
  afterSuccessFunction,
  navigate,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'discardApprovalRequest',
    actionParams: { draftId },
    loadingMessage: 'Discarding approval request.',
    successMessage: 'Request discarded successfully',
    setDisabledActionButtons,
    afterSuccessFunction,
  })
}

export async function rejectCourseDraftApiCall({
  setDisabledActionButtons,
  draftId,
  courseCategory,
  afterSuccessFunction,
  navigate,
  feedBackObject,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'reject',
    actionParams: { draftId },
    loadingMessage: 'Rejecting Course Draft',
    successMessage: 'Course Draft Rejected',
    setDisabledActionButtons,
    afterSuccessFunction,
    postData: feedBackObject,
  })
}

export async function approveCourseDraftApiCall({
  setDisabledActionButtons,
  draftId,
  courseCategory,
  afterSuccessFunction,
  navigate,
  feedBackObject,
}) {
  await handleOnlineCoursePostApiAction({
    apiMapKey: 'approve',
    actionParams: { draftId },
    loadingMessage: 'Approving Course Draft',
    successMessage: 'Course Draft Approved',
    setDisabledActionButtons,
    afterSuccessFunction,
    postData: feedBackObject,
  })
}
