export const DISPLAY_MESSAGE_TEMPLATES_OBJECT = {
  apiParam: 'display-msg-template',
  actionBtn: true,
  actionBtnText: '',
  actionBtnUri: '',
  colors: null,
  enabled: true,
  extraLink: false,
  extraLinkText: '',
  extraLinkUri: '',
  id: '',
  imageId: '',
  imageType: '',
  imageUrl: '',
  subText: '',
  title: '',
}

export const SENTINEL_CARD_OBJECT = {
  actionBtn: true,
  actionBtnText: '',
  actionBtnUri: '',
  colors: null,
  deleted: true,
  enabled: true,
  extraLink: true,
  extraLinkText: '',
  extraLinkUri: '',
  id: '',
  imageId: '',
  imageType: '',
  imageUrl: '',
  subText: '',
  tapActionLinkId: '',
  tapActionType: '',
  title: '',
  titleList: [],
  type: '',
}
