export default function ClinicAssistantForm({
  initialObject = {},
  imageObject: clinicAssistant,
  setImageObject: setClinicAssistant,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  formMode,
}) {
  const { clinicBrandId, clinicId } = clinicAssistant

  return <div>hello world</div>
}
