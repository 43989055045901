import { CFormInput } from '@coreui/react-pro'
import { Delete } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import React, { useMemo, useState } from 'react'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import {
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import { loadCategoryTypeBasedItems } from 'src/components/controlCenter/linksMaker/components/editLink/deepLink/IdTypesComponents/utils'
import { FEATURE_BUNDLE_TYPES_ARRAY } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'

export default function FeatureBundleComponent({
  bundle,
  changeBundle,
  deleteBundle,
  disabled,
  allSubscriptionPlans,
}) {
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )
  const [store, dispatch] = useAppSettingsStore()

  const subscriptionPlansToUse = useMemo(() => {
    if (bundle?.metaCategoryType === 'app-subscription') {
      return allSubscriptionPlans.filter(
        (plan) =>
          plan?.planGrpId === 'subscription' && plan?.subscriptionType?.id === bundle?.metaCategory,
      )
    }
    return allSubscriptionPlans
  }, [allSubscriptionPlans, bundle])

  function handleDelete() {
    setConfirmationModalProps({
      ...confirmationModalProps,
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [deleteBundle],
      action: 'delete',
      body: 'Are you sure you want to remove this bundle from the list?',
      buttonColor: 'error',
    })
  }

  return (
    <>
      <GridSectionWrapper>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="title"
            label="Title"
            value={bundle?.title}
            onChange={changeBundle}
            placeholder="Enter title..."
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CCTextArea
            id="description"
            label="Description"
            value={bundle?.description}
            onChange={changeBundle}
            placeholder="Enter description..."
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="type"
            label="Type"
            value={bundle?.type}
            options={FEATURE_BUNDLE_TYPES_ARRAY}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            placeholder="Select feature bundle type..."
            onChangeEvent={changeBundle}
            disabled={disabled}
            selectType={selectTypes.string}
            required
          />
        </Grid>
        {bundle?.type === FEATURE_BUNDLE_TYPES_ARRAY?.[0]?.id && (
          <Grid id="maxCountInput" item xs={12} md={6}>
            <CFormInput
              id="maxCount"
              label="Max Count of purchasable features"
              value={bundle.maxCount}
              onChange={changeBundle}
              placeholder="Enter max count..."
              disabled={disabled}
              required
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <CFormInput
            id="featureAndCreditValidityInDays"
            label="Feature and Credit Validity In Days"
            value={bundle?.featureAndCreditValidityInDays}
            onChange={changeBundle}
            type="number"
            placeholder="Enter validity in days..."
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="metaCategoryType"
            label="Feature Bundle Category Type"
            value={bundle?.metaCategoryType}
            options={store.metaCategoryTypes
              ?.filter((type) => type?.scope === 'app')
              ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            placeholder="Select feature bundle meta category type..."
            onChangeEvent={changeBundle}
            disabled={disabled}
            selectType={selectTypes.string}
            required
          />
        </Grid>
        {bundle?.metaCategoryType && (
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="metaCategory"
              label="Feature Bundle Category"
              value={bundle?.metaCategory}
              options={store.categories
                ?.filter((cat) => cat?.categoryType === bundle?.metaCategoryType)
                ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              placeholder="Select feature bundle category..."
              onChangeEvent={changeBundle}
              disabled={disabled}
              selectType={selectTypes.string}
              required
            />
          </Grid>
        )}
        {bundle?.metaCategory && bundle?.metaCategoryType !== 'app-subscription' && (
          <Grid item xs={12}>
            <label>Features</label>
            <ActivityItemAsyncSelect
              isMulti
              cacheUniqs={[bundle?.metaCategoryType, bundle?.metaCategory]}
              classNamePrefix="activity-item-select"
              menuPortalTarget={document.body}
              value={bundle?.featureIds}
              loadOptions={loadCategoryTypeBasedItems}
              isDisabled={disabled}
              debounceTimeout={1000}
              additional={{
                page: 0,
                size: 200,
                metaCategoryTypeId: bundle?.metaCategoryType,
                categoryIds: bundle?.metaCategory ? [bundle?.metaCategory, ''] : undefined,
              }}
              onChange={(selVal) => changeBundle({ target: { id: 'featureIds', value: selVal } })}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="pricingPackId"
              label="Pricing Pack Id"
              value={bundle?.pricingPackId}
              options={subscriptionPlansToUse}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              placeholder="Select subscription plan..."
              onChangeEvent={changeBundle}
              disabled={disabled}
              selectType={selectTypes.string}
              required
            />
          </Grid>
          {/* <label>Pricing Pack Id</label>
          <AsyncPaginate
            cacheUniqs={[bundle?.featureIds]}
            classNamePrefix="activity-item-select"
            menuPortalTarget={document.body}
            value={bundle?.pricingPackId}
            loadOptions={loadOptionsForAsyncPaginate}
            debounceTimeout={500}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            additional={{
              getFunction: GetSettingsData,
              apiUrl: GENERIC_APIS.entity.getPaginated,
              otherParams: {
                entityType: ALL_ENTITY_TYPES.consumerSubscriptionPlan,
              },
            }}
            onChange={(selVal) => changeBundle({ target: { id: 'pricingPackId', value: selVal } })}
          /> */}
        </Grid>
        {deleteBundle && !disabled && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleDelete} color="error" startIcon={<Delete />}>
              Delete Bundle
            </Button>
          </Grid>
        )}
      </GridSectionWrapper>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}
