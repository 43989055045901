import { Add } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { getMediaImageUrl } from 'src/services'
import { SlideTimeStampContainer, TimeLineImage } from './styledComponents'

export default function TimeLineDnD({
  imageSlideObject,
  type,
  timeFrame,
  handleOpenPopover,
  handleAdd,
  selectedIndex,
}) {
  return (
    <Droppable id="timeline-item-scroll" droppableId="ROOT" type="group" direction="horizontal">
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="w-100 d-flex justify-content-start align-items-center h-100 gap-2"
        >
          {imageSlideObject?.[type]
            ?.sort((slideA, slideB) => sortFn(slideA?.inAt, slideB?.inAt))
            .map((slide, idx) => (
              <Draggable
                key={`${slide.id}-${idx}`}
                draggableId={`${slide.inAt}-${idx}`}
                index={idx}
              >
                {(provided) => (
                  <TimeLineImage
                    imgWidth={getImageWidth(slide.inAt, slide.outAt, timeFrame.maxTime)}
                    key={idx}
                    img={`${getMediaImageUrl()}${slide?.image?.imageId}`}
                    selected={selectedIndex === idx}
                    onClick={(event) => handleOpenPopover(event, idx)}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className="d-flex justify-content-between align-items-end"
                  >
                    <SlideTimeStampContainer>
                      <span>{Number((slide.inAt / 1000).toFixed(1))} s</span>
                    </SlideTimeStampContainer>
                    <SlideTimeStampContainer>
                      <span>{Number((slide.outAt / 1000).toFixed(1))} s</span>
                    </SlideTimeStampContainer>
                  </TimeLineImage>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
          <IconButton
            sx={{
              background: '#8A5ED4',
              ':hover': { background: '#9d6bf2' },
            }}
            onClick={handleAdd}
          >
            <Add />
          </IconButton>
        </div>
      )}
    </Droppable>
  )
}

TimeLineDnD.propTypes = {
  imageSlideObject: PropTypes.object,
  type: PropTypes.string,
  timeFrame: PropTypes.object,
  handleOpenPopover: PropTypes.func,
  handleAdd: PropTypes.func,
  selectedIndex: PropTypes.number,
}

export function sortFn(obj1, obj2) {
  if (obj1 === obj2) {
    return 0
  } else {
    return obj1 > obj2 ? 1 : -1
  }
}

export function getImageWidth(inAt, outAt, maxTime) {
  if (inAt === outAt) return 0
  return ((outAt - inAt) / maxTime) * 100
}
