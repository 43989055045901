import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import {
  entityFormComponents,
  entityGalleryFilters,
} from '../../../config/common/genericListingAndFormConfig'
import GalleryFilters from '../GalleryFilters'
import CCOverlay from '../loadingOverlay/CCOverlay'
import { ImageUploadContext } from './ImageUpload'
import ImagesSkeleton from './ImagesSkeleton'

const PAGINATION_INIT = {
  pageSize: 50,
  pageNumber: 0,
  offset: 10,
}

export default function ImageGalleryChoose({ dialogDetails, onChange, imageType, onChangeType }) {
  const ImageContext = useContext(ImageUploadContext)
  const [loading, setLoading] = useState(true)
  const galleryRef = useRef()
  const [overLay, setOverLay] = useState(false)
  const [images, setImages] = useState()
  const [noMoreFetch, setNoMoreFetch] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [fetching, setFetching] = useState(false)
  const [pagination, setPagination] = useState({
    ...PAGINATION_INIT,
  })
  const GalleryComponent = useMemo(() => {
    return entityFormComponents(ImageContext.imageEntityType)?.GalleryComponent?.component
  }, [ImageContext.imageEntityType])

  async function getGallery(search = searchInput, page = PAGINATION_INIT.pageNumber) {
    const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      page: page,
      size: PAGINATION_INIT.pageSize,
      entityType: ImageContext.imageEntityType,
      types: [imageType, ImageContext.imageTypeObject?.alias || ''].join(','),
      search: search,
      tags: selectedTags.map((tag) => tag.id)?.join(',') || '',
    })
    if (response.status === 200) {
      const filteredData =
        entityGalleryFilters?.[ImageContext.imageEntityType]?.filterFunction(
          response.data.content,
          entityGalleryFilters?.[ImageContext.imageEntityType].field,
          imageType?.split(','),
        ) || response.data.content
      if (filteredData.length !== 0 && !response.data.last) {
        setImages(filteredData)
        setNoMoreFetch(response.data.last)
        setPagination(response.data.pageable)
      } else {
        if (response.data.last) {
          setImages(filteredData)
          setNoMoreFetch(response.data.last)
          setPagination(response.data.pageable)
        } else {
          await getGallery(searchInput, page + 1)
        }
      }
    } else {
      toast.error("Entity Couldn't be retrieved")
    }
    return
  }
  async function updateGallery(page = pagination.pageNumber) {
    const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      page: page,
      size: pagination.pageSize,
      types: [imageType, ImageContext.imageTypeObject?.alias || ''].join(','),
      entityType: ImageContext.imageEntityType,
      search: searchInput,
      tags: selectedTags.map((tag) => tag.id)?.join(',') || '',
    })
    if (response.status === 200) {
      const filteredData =
        entityGalleryFilters?.[ImageContext.imageEntityType]?.filterFunction(
          response.data.content,
          entityGalleryFilters?.[ImageContext.imageEntityType].field,
          imageType?.split(','),
        ) || response.data.content
      setImages((prev) => [...(prev || []), ...filteredData])
      setPagination(response.data.pageable)
      setNoMoreFetch(response.data.last || response.data.last)
      setFetching(false)
    } else {
      toast.error("Images Couldn't be retrieved")
    }
  }
  async function Setup() {
    setLoading(true)
    await Promise.all([getGallery()])
    setLoading(false)
  }

  function BottomScrollFunction() {
    const ScrollLeft = dialogDetails.scrollHeight - dialogDetails.clientHeight - 200
    if (dialogDetails.scrollTop >= ScrollLeft) {
      setFetching(true)
      !fetching && updateGallery(pagination.pageNumber + 1)
    }
  }

  async function SearchUpdate() {
    setOverLay(true)
    await getGallery()
    setOverLay(false)
  }

  const debSetSearch = debounce((input) => {
    setSearchInput(input)
  }, 1000)

  useEffect(() => {
    if (dialogDetails && !noMoreFetch && images) BottomScrollFunction()
  }, [dialogDetails?.scrollTop])

  useEffect(() => {
    !!images && SearchUpdate()
  }, [searchInput, selectedTags])

  useEffect(() => {
    Setup()
  }, [])

  return (
    <CCOverlay active={overLay} spinner text="Fetching Images">
      <div
        ref={galleryRef}
        className="d-flex flex-column justify-content-stretch align-items-stretch"
      >
        <GalleryFilters
          search={searchInput}
          setSearch={debSetSearch}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        {images?.length > 0 && !loading ? (
          <GalleryComponent
            loading={loading}
            images={images}
            onChange={onChange}
            onChangeType={onChangeType}
          />
        ) : (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <h3>No Items Avaliable</h3>
          </div>
        )}
        {!noMoreFetch && <ImagesSkeleton />}
      </div>
    </CCOverlay>
  )
}

ImageGalleryChoose.propTypes = {
  dialogDetails: PropTypes.any,
  onChange: PropTypes.func,
  imageType: PropTypes.string,
  onChangeType: PropTypes.string,
}
