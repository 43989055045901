import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const DIAGNOSIS_OBJECT = {
  id: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'diagnosis',
  modifiedBy: '',
  clinicIds: [],
  clinicId: '',
  title: '',
  availableToAll: true,
  approvedForAllBy: '',
  approvedForAllAt: '',
}
