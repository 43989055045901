import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'

export async function transformOnlineCourseDraftListingData({ listingData }) {
  let ocUpdatedList = await getApprovedByUserNames(listingData)

  var relatedOnlineCoursesToTheseCourseDrafts = []
  if (ocUpdatedList.length > 0) {
    relatedOnlineCoursesToTheseCourseDrafts = await getRelatedOnlineCoursesToTheseCourseDrafts(
      ocUpdatedList,
    )
  }

  console.log({ relatedOnlineCoursesToTheseCourseDrafts })

  return relatedOnlineCoursesToTheseCourseDrafts?.map((courseDraft) => {
    return {
      ...courseDraft,
    }
  })
}

async function getApprovedByUserNames(ocList) {
  const userIdsToUse = ocList?.map((courseDraft) => courseDraft?.onlineCourse?.approvedBy)
  const foundUsers = await getUsersSummaryFromUserIds(userIdsToUse)
  return ocList?.map((courseDraft) => {
    const foundUser = foundUsers?.find(
      (user) => user?.userId === courseDraft?.onlineCourse?.approvedBy,
    )
    return {
      ...courseDraft,
      onlineCourse: {
        ...(courseDraft?.onlineCourse || {}),
        approvedBy: foundUser?.name,
      },
    }
  })
}

async function getRelatedOnlineCoursesToTheseCourseDrafts(courseDrafts) {
  const onlineCourseIds = courseDrafts?.map((courseDraft) => courseDraft?.onlineCourse?.id)
  const foundOnlineCoursesResp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.onlineCourse,
    size: 999,
    ids: onlineCourseIds?.join(','),
  })
  if (foundOnlineCoursesResp?.status !== 200) return courseDrafts
  return courseDrafts?.map((courseDraft) => {
    const foundOnlineCourse = foundOnlineCoursesResp?.data?.content?.find(
      (onlineCourse) => onlineCourse?.id === courseDraft?.onlineCourse?.id,
    )
    return {
      ...courseDraft,
      relatedOC: foundOnlineCourse,
    }
  })
}
