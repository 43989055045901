export const SubscriptionApis = {
  allPlans: {
    url: 'subscription/plans/summary',
  },
  planGroups: {
    url: 'subscription/plan-groups',
  },
  planDetails: {
    url: 'subscription/plan/',
  },
  corporatePlanDetails: {
    url: 'subscription/plan/corporate/',
  },
  planTypes: {
    url: 'subscription/plan-types',
  },
  subscriptionLevels: {
    url: 'subscription/levels',
  },
  corporatePlans: {
    url: 'subscription/plans/corporate/summary',
  },
  paymentGateways: {
    get: {
      url: 'subscription/payment-gateways',
    },
    save: {
      url: 'subscription/payment-gateways',
    },
  },
  testimonials: {
    get: {
      url: 'testimonials',
    },
    save: {
      url: 'testimonial/save',
    },
  },
  prewalls: {
    get: {
      url: 'prewalls',
    },
    save: {
      url: 'prewall/save',
    },
  },
}
