import { APP_LISTABLE_OBJECT } from '../abstractObjects/AppListable'
import { getSummarizableObject } from '../abstractObjects/Summarizable'
const { ALL_ENTITY_TYPES_FROM_CONSTANTS } = require('src/utilities/constants')

export const CLINIC_CHAMBERS_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicChamber),
  ...APP_LISTABLE_OBJECT,
  mentorId: '',
  assistantId: '',
  oohScreenId: '',
  satelliteScreenId: '',
  clinicId: '',
  clinicBrandId: '',
}
