import { CFormInput } from '@coreui/react-pro'
import { Add } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import CCExtendedCSmartTable from 'src/components/controlCenter/CCExtendedCSmartTable'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import { currency_list } from 'src/utilities/constants'
import {
  checkIfStringIsValid,
  getCorrectTargetValueForEvent,
  isValidNumber,
} from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { PRICING_DETAIL_OBJECT, filterOtherSelectedCountries } from '../../../utils'
import { StyledReactSelect } from '../../styledComponents'

export default function PricingMatrixComponent({
  pricingMatrix,
  disabled,
  setPricingMatrix,
  subheading,
}) {
  const [rStore, dispatch] = useAppSettingsStore()
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  function handleChangePricingMatrix(event, idx) {
    const value = getCorrectTargetValueForEvent(event)
    setPricingMatrix((prev) =>
      prev?.map((p, i) => {
        let valueToReturn = { ...p, [event.target.id]: value }
        return i === idx ? valueToReturn : p
      }),
    )
  }

  function countryWisePricingHeader() {
    return (
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div>
          <h4>Country Wise Pricing</h4>
          {subheading && <h6>{subheading}</h6>}
        </div>
        <div>
          {!disabled && (
            <Tooltip label="Add">
              <IconButton onClick={() => addAnotherItemToPricingMatrix()}>
                <Add />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    )
  }

  function deleteCountryWisePricing(item, idx) {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'delete',
      buttonColor: 'error',
      body: 'Do you want to delete this price?',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [() => setPricingMatrix((prev) => prev.filter((p, i) => i !== idx))],
    }))
  }

  function addAnotherItemToPricingMatrix() {
    setPricingMatrix((prev) => [PRICING_DETAIL_OBJECT, ...prev])
  }

  const scopedColumns = {
    countryCode: (item, idx) => {
      const currentOptions = filterOtherSelectedCountries(
        rStore?.countries
          ?.slice()
          ?.sort((countryA, countryB) => sortFn(countryA.title, countryB.title)),
        pricingMatrix,
        item?.countryCode,
      )

      function changeCountryCode(selectValue, idx) {
        setPricingMatrix((prev) =>
          prev?.map((p, i) =>
            i === idx
              ? {
                  ...p,
                  countryCode: selectValue?.id,
                  currencyId: selectValue?.currency,
                }
              : p,
          ),
        )
      }

      return (
        <td>
          <CustomReactSelect
            value={item?.countryCode}
            options={currentOptions}
            selectType={selectTypes.string}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.title}
            disabled={disabled || item?.countryCode === 'row'}
            id="countryCode"
            menuPortalTarget={document.body}
            // onChangeEvent={(event) => handleChangePricingMatrix(event, idx)}
            onChange={(selectValue) => changeCountryCode(selectValue, idx)}
            SelectComponent={StyledReactSelect}
            required
            invalid={!checkIfStringIsValid(item?.countryCode)}
          />
        </td>
      )
    },
    currencyId: (item, idx) => {
      return (
        <td>
          <CustomReactSelect
            value={item?.currencyId}
            options={currency_list.map((currency) => ({
              ...currency,
              name: `(${currency.symbol}) ${currency.name}`,
            }))}
            selectType={selectTypes.string}
            getOptionValue={(option) => option?.code}
            getOptionLabel={(option) => option?.name}
            disabled={disabled}
            id="currencyId"
            menuPortalTarget={document.body}
            onChangeEvent={(event) => handleChangePricingMatrix(event, idx)}
            SelectComponent={StyledReactSelect}
            required
            invalid={!checkIfStringIsValid(item?.currencyId)}
          />
        </td>
      )
    },
    price: (item, idx) => {
      return (
        <td>
          <CFormInput
            id="price"
            value={item?.price === null ? '' : item?.price}
            disabled={disabled}
            onChange={(event) => handleChangePricingMatrix(event, idx)}
            type="number"
            required
            invalid={!isValidNumber(item?.price)}
          />
        </td>
      )
    },
    introductoryPrice: (item, idx) => {
      return (
        <td>
          <CFormInput
            id="introductoryPrice"
            value={item?.introductoryPrice === null ? '' : item?.introductoryPrice}
            disabled={disabled}
            type="number"
            // placeholder={item?.price}
            onChange={(event) => handleChangePricingMatrix(event, idx)}
          />
        </td>
      )
    },
    discountedPrice: (item, idx) => {
      return (
        <td>
          <CFormInput
            id="discountedPrice"
            value={item?.discountedPrice === null ? '' : item?.discountedPrice}
            disabled={disabled}
            onChange={(event) => handleChangePricingMatrix(event, idx)}
            // placeholder={item?.price}
            type="number"
          />
        </td>
      )
    },
  }

  return (
    <>
      <CCExtendedCSmartTable
        items={pricingMatrix}
        externalComponents={countryWisePricingHeader}
        columns={pricingMatrixColumns}
        noUserNames
        excludeGenericFields={['title', 'modifiedTime', 'modifiedBy', disabled ? 'actions' : '']}
        loading={false}
        scopedColumns={scopedColumns}
        customActions={[
          {
            onClick: deleteCountryWisePricing,
            label: 'Delete',
            showCondition: (item) => item?.countryCode !== 'row',
          },
        ]}
      />
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}

const pricingMatrixColumns = [
  {
    key: 'countryCode',
    label: 'Country',
    _style: { minWidth: '220px' },
    filter: false,
    sorter: false,
  },
  {
    key: 'currencyId',
    label: 'Currency',
    _style: { minWidth: '220px' },
    filter: false,
    sorter: false,
  },
  { key: 'price', label: 'Price', _style: { minWidth: '170px' }, filter: false, sorter: false },
  {
    key: 'introductoryPrice',
    label: 'Introductory Price',
    _style: { minWidth: '170px' },
    filter: false,
    sorter: false,
  },
  {
    key: 'discountedPrice',
    label: 'Discounted Price',
    _style: { minWidth: '170px' },
    filter: false,
    sorter: false,
  },
  // {
  //   key: 'liveSessionsPSPMPrice',
  //   label: 'Live Sessions PSPM Price',
  //   _style: { minWidth: '170px' },
  //   filter: false,
  //   sorter: false,
  // },
  // {
  //   key: 'liveSessionPSPMDiscountedPrice',
  //   label: 'Live Session PSPM Discounted Price',
  //   _style: { minWidth: '170px' },
  //   filter: false,
  //   sorter: false,
  // },
  // {
  //   key: 'liveSessionPSPMIntroductoryPrice',
  //   label: 'Live Session PSPM Introductory Price',
  //   _style: { minWidth: '170px' },
  //   filter: false,
  //   sorter: false,
  // },
  // {
  //   key: 'groupSessionIntroductoryPrice',
  //   label: 'Group Session Introductory Price',
  //   _style: { minWidth: '170px' },
  //   filter: false,
  //   sorter: false,
  // },
  // {
  //   key: 'groupSessionUnitPrice',
  //   label: 'Group Session Unit Price',
  //   _style: { minWidth: '170px' },
  //   filter: false,
  //   sorter: false,
  // },
  // {
  //   key: 'groupSessionDiscountedPrice',
  //   label: 'Group Session Discounted Price',
  //   _style: { minWidth: '170px' },
  //   filter: false,
  //   sorter: false,
  // },
]
