import { CFormInput } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { ThemeContext } from 'src/context/ThemeContext'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { PAYMENT_MODES, currency_list } from 'src/utilities/constants'
import { genericHandleChange } from 'src/utilities/generalUtils'

export default function GiveUserSubscriptionPlanForm({
  userDetails,
  dropDownData,
  closeForm,
  setUserData,
}) {
  const [formData, setFormData] = useState({})
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )
  const [saving, setSaving] = useState(false)
  const [captchaToken, setCaptchaToken] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { theme } = useContext(ThemeContext)

  const disableSaveButton = useMemo(() => {
    if (
      formData?.subscriptionPlanId &&
      formData?.currencyCode &&
      formData?.refTxnId &&
      formData?.modeOfTxn &&
      formData?.amount
    ) {
      return false // button is not disabled
    }
    return true
  }, [formData])

  const formIsDisabled = false

  async function giveSubscriptionPlan() {
    setSaving(true)
    const resp = await PostSettingsData(
      USERS_API_SIGNATURES_MAP.subscription.add.post,
      { ...formData, userId: userDetails?.id },
      {},
      undefined,
      undefined,
      (error) => 'Could not give subscription plan to user. Please try again later.',
    )
    if (resp?.status === 200) {
      setUserData(resp?.data)
      closeForm()
    }
    // setRefreshReCaptcha((prev) => !prev)
    setSaving(false)
  }
  function handleChange(eve) {
    genericHandleChange(eve, setFormData)
  }

  function submitForm() {
    setConfirmationModalProps({
      ...confirmationModalProps,
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      action: 'submit',
      body: 'Are you sure you want to this user the selected subscription plan?',
      onSubmitFunctions: [
        () => {
          giveSubscriptionPlan()
        },
      ],
    })
  }

  useEffect(() => {
    console.log({ captchaToken })
  }, [captchaToken])

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    const recTokenResult = await executeRecaptcha('contactForm')
    setCaptchaToken(recTokenResult)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return (
    <>
      <DialogTitle>Add Subscription</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomReactSelect
              id="subscriptionPlanId"
              label="Subscription Plan"
              value={formData?.subscriptionPlanId}
              options={dropDownData?.subscriptionPlans
                ?.filter((plan) => plan?.enabled)
                ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
              getOptionValue={(optn) => optn?.id}
              getOptionLabel={(optn) => optn?.title}
              onChangeEvent={handleChange}
              selectType={selectTypes.string}
              disabled={formIsDisabled}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomReactSelect
              id="currencyCode"
              label="Currency"
              value={formData?.currencyCode}
              options={currency_list}
              getOptionValue={(optn) => optn?.code}
              getOptionLabel={(optn) => `${optn?.name} - ${optn?.symbol}`}
              onChangeEvent={handleChange}
              selectType={selectTypes.string}
              disabled={formIsDisabled}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CFormInput
              id="refTxnId"
              label="Reference ID"
              value={formData?.refTxnId}
              onChange={handleChange}
              colNumber={12}
              disabled={formIsDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomReactSelect
              id="modeOfTxn"
              label="Mode"
              value={formData?.modeOfTxn}
              options={PAYMENT_MODES}
              getOptionValue={(optn) => optn?.id}
              getOptionLabel={(optn) => optn?.title}
              onChangeEvent={handleChange}
              selectType={selectTypes.string}
              disabled={formIsDisabled}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CFormInput
              id="amount"
              label="Amount"
              value={formData?.amount}
              onChange={handleChange}
              type="number"
              disabled={formIsDisabled}
              required
            />
          </Grid>
          <Grid item xs={12}>
            {/* <GoogleReCaptcha onVerify={onVerifyCaptcha} refreshReCaptcha={refreshReCaptcha} /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeForm} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disableSaveButton || saving || !captchaToken}
          onClick={submitForm}
        >
          {saving ? 'Adding...' : 'Add'}
        </Button>
      </DialogActions>
      <ConfirmationModal newImplementation {...confirmationModalProps} />
    </>
  )
}
