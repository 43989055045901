export const GENERIC_APIS = {
  entity: {
    getSummary: 'entity/summary/list',
    getPaginated: 'entity/summary/paginated',
    details: 'entity/details',
    enable: 'entity/enable',
    disable: 'entity/disable',
    save: 'entity/save',
    saveCsv: 'entity/upload-csv',
    slug: {
      checkAvailability: 'entity/slug/check-availability',
    },
  },
  saveEntity: {
    imageTypes: 'save/image-types',
    generic: 'entity/save/',
    generic_without_slash: 'entity/save',
  },
}

export const PUBLIC_APIS = {
  timeSlots: {
    get: '/timeslots',
  },
  oneOnOnePlans: {
    get: '/subscription-plan/1on1',
  },
}
