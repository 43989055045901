import { CFormInput } from '@coreui/react-pro'
import React from 'react'
import EditAndViewButtons from '../EditAndViewButtons'
import { HyperLinkContainer } from '../styledComponents'

export default function ViewHyperLinkComponent({
  externalLinksData,
  openDialog,
  disabled,
  handleChange,
}) {
  return (
    <>
      <div>
        <CFormInput
          label="Button Text"
          value={externalLinksData?.btnTxt}
          onChange={(e) => handleChange({ uri: externalLinksData?.uri, btnTxt: e.target.value })}
        />
      </div>
      <HyperLinkContainer>
        <span>
          <strong>URI: </strong>
          {externalLinksData?.uri}
        </span>
        <EditAndViewButtons
          openDialog={openDialog}
          uri={externalLinksData?.uri}
          hoverTitle={externalLinksData?.uri}
          disabled={disabled}
        />
      </HyperLinkContainer>
    </>
  )
}
