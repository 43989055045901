export const USER_BADGE_OBJECT = {
  apiParam: 'badge',
  categories: [],
  colors: null,
  currentCount: 0,
  deleted: false,
  description: '',
  earned: true,
  enabled: true,
  iconId: '',
  id: '',
  idByUser: true,
  maxCount: 0,
  title: '',
  userId: '',
}

export const USER_STATS_OBJECT = {
  apiParam: 'user-stats',
  categories: [],
  colors: null,
  deleted: false,
  enabled: true,
  iconId: '',
  id: '',
  idByUser: true,
  sectionId: '',
  sectionTitle: '',
  title: '',
  userId: '',
  value: '',
  valueType: 'number',
}

export const USER_MENTAL_STATE_OBJECT = {
  apiParam: 'mental-state',
  colors: null,
  deleted: false,
  description: '',
  enabled: false,
  icon: '',
  id: '',
  idByUser: true,
  name: '',
  orderId: 0,
  type: '',
}

export const USER_MENTAL_STATE_TYPE_OBJECT = {
  apiParam: 'mental-state-type',
  colors: null,
  dailyPlayAltText: {},
  deleted: false,
  description: '',
  enabled: false,
  iconId: '',
  id: '',
  idByUser: true,
  question: '',
  title: '',
}

export const BLOG_OBJECT = {
  ageGroups: [],
  allowPreview: true,
  apiParam: 'article',
  approved: false,
  availableForPlan: false,
  avgDuration: 0,
  colors: null,
  content: '',
  countries: [],
  courseType: '',
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  createdByName: '',
  createdFor: '',
  createdForName: '',
  createdForNumber: '',
  deleted: false,
  difficultyLevel: {
    id: '',
    level: '',
    order: 0,
  },
  duration: 0,
  enabled: false,
  exploreListable: false,
  favCount: 0,
  featured: false,
  id: '',
  idByUser: false,
  labels: [],
  languages: [],
  locked: false,
  longDesc: '',
  maxDuration: 0,
  meditationIds: [],
  minDuration: 0,
  noOfEpisode: 0,
  noOfRating: 0,
  preRequisiteIds: [],
  primaryTags: [],
  prioritySetting: {},
  rating: 0,
  recommendedForKids: false,
  relativeCourses: [],
  reqToUnpublish: false,
  secondaryTags: [],
  sessions: [],
  shareLink: '',
  shortDesc: '',
  shortLink: '',
  status: 'draft',
  subscription: {
    id: '',
    name: '',
  },
  testimonials: [],
  title: '',
  type: '',
  welcomeMsg: '',
}

export const AGREEMENT_OBJECT = {
  title: '',
  shortDesc: '',
  content: '',
  type: 'agreement',
  roles: ['ROLE_TEACHER', 'ROLE_CREATOR'],
  version: 1,
  includedCountries: [],
  excludedCountries: [],
  applicableForServices: [],
  applicableSince: '2023-10-13 12:30:45',
  apiParam: 'agreement',
}

export function validateID(object, allData, fieldId, initObject) {
  if (object?.[fieldId] !== initObject?.[fieldId] && object?.[fieldId])
    return (
      !!allData.find((imgType) => imgType?.[fieldId] === (object?.[fieldId] || '')) &&
      object?.[fieldId]
    )
  if (!object?.[fieldId]) return true
  return false
}

export async function getSavableMentalStateTypeObject(object) {
  return object
}
