import { CircularProgress, Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import GridListingComponent from 'src/components/controlCenter/GridListingComponent'
import ListingComponentFilterBar from 'src/components/controlCenter/ListingComponentFilterBar'
import SentinelCardView from 'src/components/controlCenter/SentinelCardView'
import DisplayMessageTemplateView from 'src/components/controlCenter/displayMessageTemplateForm/DisplayMessageTemplateView'
import { GetData } from 'src/services/APIs/DailyPlay/GetLists'
import { GENERIC_APIS } from 'src/services/genericApis'
import DisplayMessagesEditPage from './components/DisplayMessagesEditPage'
import { SettingsContainer } from './components/styledComponents'
import { DISPLAY_MESSAGE_TEMPLATES_OBJECT } from './utils/display-message-template'

export default function DisplayMessageTemplates() {
  const [loading, setLoading] = useState(true)
  const [displayMessages, setDisplayMessages] = useState([])
  const [selectedDisplayMessage, setSelectedDisplayMessage] = useState()
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const [edit, setEdit] = useState(false)

  async function getDisplayMessages(apiPage = 0, insert = false) {
    const response = await GetData(GENERIC_APIS.entity.getPaginated, {
      entityType: 'display-msg-template',
      page: apiPage,
    })
    if (response.status === 200) {
      setPage(response.data.pageable.pageNumber)
      setHasMore(!response.data.last)
      if (insert) {
        setDisplayMessages([...displayMessages, ...response.data.content])
        return
      }
      setDisplayMessages(response.data.content)
    } else {
      setHasMore(false)
    }
  }

  async function fetchMoreData() {
    const nextPage = page + 1
    getDisplayMessages(nextPage, true)
  }

  function handleAdd() {
    setEdit(true)
    setSelectedDisplayMessage(DISPLAY_MESSAGE_TEMPLATES_OBJECT)
    setShowEditDialog(true)
  }

  function handleEdit(item) {
    setEdit(false)
    setSelectedDisplayMessage(item)
    setShowEditDialog(true)
  }

  async function Setup() {
    setLoading(true)
    await Promise.all([getDisplayMessages()])
    setLoading(false)
  }
  function handleCloseDialog() {
    setShowEditDialog(false)
  }
  function handleCloseDialogAndRefresh() {
    Setup()
    setShowEditDialog(false)
  }

  function renderMessages(item) {
    return item.type === DISPLAY_MESSAGE_TEMPLATE_OPTIONS.sentinelCard ? (
      <SentinelCardView handleClick={handleEdit} item={item} />
    ) : (
      <DisplayMessageTemplateView handleClick={handleEdit} item={item} />
    )
  }

  useEffect(() => {
    Setup()
  }, [])
  return (
    <SettingsContainer>
      <ListingComponentFilterBar addButtonFunction={handleAdd} />
      {loading ? (
        <CircularProgress key={0} />
      ) : (
        <InfiniteScroll
          pageStart={-1}
          loadMore={fetchMoreData}
          hasMore={hasMore}
          loader={<CircularProgress key={0} />}
          useWindow={false}
        >
          <GridListingComponent
            // loading={loading}
            items={displayMessages}
            customItemBox={renderMessages}
          />
        </InfiniteScroll>
      )}
      {/* <CCPagination /> */}
      <Dialog
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '992px !important',
            margin: '0 !important',
          },
        }}
        open={showEditDialog}
        onClose={handleCloseDialog}
      >
        <DisplayMessagesEditPage
          edit={edit}
          setEdit={setEdit}
          type={'displayMessageTemplate'}
          displayMessage={selectedDisplayMessage}
          handleClose={handleCloseDialog}
          handleCloseAndRefresh={handleCloseDialogAndRefresh}
        />
      </Dialog>
    </SettingsContainer>
  )
}

export const DISPLAY_MESSAGE_TEMPLATE_OPTIONS = {
  sentinelCard: 'sentinelCard',
  emptySlotMsg: 'emptySlotMsg',
  completedSlotMsg: 'completedSlotMsg',
  'landing-card': 'landing-card',
}
