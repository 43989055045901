import { CFormInput } from '@coreui/react-pro'
import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import {
  OptionNameContainer,
  OptionRemoveButtonContainer,
  OptionScoreFieldContainer,
} from './styledComponents'

export default function OptionsComponents({
  idx,
  optn,
  disabled,
  handleChange,
  handleRemove,
  showName = true,
}) {
  return (
    <>
      {showName && (
        <OptionNameContainer>
          <CFormInput
            id="name"
            placeholder={`Option - ${idx + 1}`}
            value={optn.name}
            disabled={disabled}
            onChange={handleChange}
          />
        </OptionNameContainer>
      )}
      <OptionScoreFieldContainer>
        <CFormInput
          id="score"
          placeholder={`Score`}
          value={optn?.score}
          type="number"
          disabled={disabled}
          onChange={handleChange}
        />
      </OptionScoreFieldContainer>
      {!disabled && (
        <OptionRemoveButtonContainer>
          <IconButton onClick={handleRemove}>
            <Close />
          </IconButton>
        </OptionRemoveButtonContainer>
      )}
    </>
  )
}

OptionsComponents.propTypes = {
  idx: PropTypes.number.isRequired,
  optn: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  showName: PropTypes.bool,
}
