import { Switch, styled as muiStyled } from '@mui/material'

export const TwoWaySwitchComponent = muiStyled(Switch)(
  ({ theme, size }) => `
    width: ${size === 'small' ? '60px' : '70px'};
    > .MuiSwitch-switchBase { 
        color: ${theme.palette.primary.main} !important;
    }
    > .MuiSwitch-track {
        background-color: ${theme.palette.whiteBlack.main} !important;
    }
    .MuiSwitch-switchBase.Mui-checked{
        transform: translateX(35px)!important;
    }
`,
)
