import { CDatePicker, CFormLabel } from '@coreui/react-pro'
import { Add, Delete } from '@mui/icons-material'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Switch,
} from '@mui/material'
import moment from 'moment'
import { array, bool, func, number, object, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { GetData } from 'src/services/APIs/DailyPlay/GetLists'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { genericHandleChange, getCorrectTargetValueForEvent } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { CustomAsyncPaginate } from '../plugins/calendar/components/CustomSelect'
import './components/popoverTimePickerStyles.css'
import { PopoverTimePicker, SupportRoleBox } from './components/styledComponents'
import { SETTINGS_API_LIST } from './utils/api-utils'
import { SUPPORT_ROLES_OBJECT } from './utils/support-staff-utils'

export default function SupportStaffForm({
  initialObject = {},
  imageObject: supportStaffObj,
  setImageObject: setSupportStaffObj,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  view = 'default',
}) {
  const [store, dispatch] = useAppSettingsStore()
  const [loadingUser, setLoadingUser] = useState(false)
  const dropDownOptions = {
    ...store,
    categories: store.categories,
    languages: store.languages,
    tags: store.tags,
    imageTypes: store.imageTypes,
    mentalStateTypes: store.mentalStateTypes,
    ...customOptions,
  }
  const [supportRolesAnchor, setSupportRolesAnchor] = useState(null)
  const supportRolePopOpen = Boolean(supportRolesAnchor)
  const [supportRoleToAdd, setSupportRoleToAdd] = useState()
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )
  const [currentUserName, setCurrentUserName] = useState('')

  async function loadUsers(search, loadedOptions, { page }) {
    setLoadingUser(true)
    const resp = await GetData('roles/users', {
      role: 'ROLE_USER',
      page,
      search: search,
    })
    var dataToReturn = resp.data.content
    setLoadingUser(false)
    return {
      options: dataToReturn,
      hasMore: !resp.data.last,
      additional: {
        page: page + 1,
      },
    }
  }

  function handleDateTimeChange(id, date) {
    const formattedDate = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    handleChangeObject({
      target: { id: id, value: formattedDate === 'Invalid Date' ? null : formattedDate },
    })
  }

  function handleOpenPopver(event) {
    setSupportRolesAnchor(event.currentTarget)
    addSupportRole()
  }

  function handleChangeSupportRole(event, changeIndex) {
    setSupportStaffObj((prev) => ({
      ...prev,
      supportRoles: (prev?.supportRoles || []).map((role, index) =>
        index === changeIndex
          ? { ...role, [event.target.id]: getCorrectTargetValueForEvent(event) }
          : role,
      ),
    }))
  }

  function delSupportRole(index) {
    setSupportStaffObj((prev) => ({
      ...prev,
      supportRoles: (prev?.supportRoles || []).filter((role, idx) => index !== idx),
    }))
  }

  function onCloseSupportRolePopover() {
    setSupportRolesAnchor(null)
  }

  function addSupportRole() {
    setSupportRoleToAdd({
      ...SUPPORT_ROLES_OBJECT,
      clinicId: supportStaffObj?.orgId || null,
      startDate: supportStaffObj?.startDate || null,
    })
  }

  function deleteSupportRole(index) {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'delete',
      body: 'Do you want to delete this support role?',
      buttonColor: 'error',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [() => delSupportRole(index)],
    }))
  }

  function handleAddSupportRole(supportRoleObject) {
    const oldSupportRoles = supportStaffObj?.supportRoles
    handleChangeObject({
      target: { id: 'supportRoles', value: [supportRoleObject, ...(oldSupportRoles || [])] },
    })
  }
  function handleChangeUserId(selectedUser) {
    handleChangeObject({ target: { id: 'userId', value: selectedUser?.id } })
    setCurrentUserName(selectedUser?.title)
  }

  async function GetUserData(userId) {
    setLoadingUser(true)
    const response = await GetSettingsData(SETTINGS_API_LIST.userNames.get.url, {
      userIds: userId,
      // search: dataToReturn?.map((dta) => dta?.userId)?.join(','),
      authorities: 'ROLE_USER',
    })
    if (response.status === 200) {
      const user_name =
        response.data?.find((apiUserName) => apiUserName?.userId === userId)?.name || userId
      setCurrentUserName(user_name)
    }
    setLoadingUser(false)
  }

  useEffect(() => {
    GetUserData(supportStaffObj?.userId)
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                id="enabled"
                checked={!!supportStaffObj?.enabled || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={'Enabled'}
          />
        </Grid>
        <Grid item xs={12}>
          <CFormLabel>User*</CFormLabel>
          <CustomAsyncPaginate
            value={
              supportStaffObj?.userId
                ? { userId: supportStaffObj?.userId, title: currentUserName }
                : null
            }
            isLoading={loadingUser}
            loadOptions={loadUsers}
            getOptionValue={(object) => object.userId}
            getOptionLabel={(object) => object.title || object.firstName}
            onChange={handleChangeUserId}
            selectType={selectTypes.string}
            placeholder="Select a user..."
            debounceTimeout={500}
            additional={{ page: 0 }}
            required
          />
        </Grid>
        {/* <Grid item xs={12}>
          <CustomReactSelect
            id="userId"
            value={supportStaffObj?.userId}
            label="User"
            options={dropDownOptions.mentorUsers}
            getOptionValue={(object) => object.userId}
            getOptionLabel={(object) => object.title || object.firstName}
            onChangeEvent={handleChangeObject}
            selectType={selectTypes.string}
            placeholder="Select a user..."
            required
          />
        </Grid> */}
        {view === 'default' && (
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="orgId"
              value={supportStaffObj?.orgId}
              label="Clinic"
              options={dropDownOptions.clinics}
              getOptionValue={(object) => object.id}
              getOptionLabel={(object) => object.title || object.firstName || object?.id}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
              placeholder="Select a clinic..."
              required
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <CDatePicker
            id="startDate"
            date={
              supportStaffObj?.startDate
                ? moment
                    .utc(supportStaffObj?.startDate, 'YYYY-MM-DD HH:mm:ss')
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')
                : null
            }
            label="Start Date"
            confirmButton={false}
            cancelButton={false}
            onDateChange={(date) => handleDateTimeChange('startDate', date)}
            timepicker
            required
            cleaner={false}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Support Roles</h3>
            <IconButton onClick={handleOpenPopver} sx={{ flexShrink: 0 }}>
              <Add />
            </IconButton>
          </div>
          {supportStaffObj?.supportRoles?.map((supportRole, idx) => (
            <SupportRoleComponent
              key={idx}
              roleObject={supportRole}
              dropDownData={dropDownOptions}
              index={idx}
              handleChange={(e) => handleChangeSupportRole(e, idx)}
              deleteSupportRole={() => deleteSupportRole(idx)}
            />
          ))}
        </Grid>
      </Grid>
      <Popover
        PaperProps={{
          sx: {
            width: '448px',
            height: '368px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        anchorEl={supportRolesAnchor}
        open={supportRolePopOpen}
        onClose={onCloseSupportRolePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <AddSupportRoleComponent
          supportRoleToAdd={supportRoleToAdd}
          dropDownData={dropDownOptions}
          addSupportRole={handleAddSupportRole}
          closePopover={onCloseSupportRolePopover}
        />
      </Popover>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}
function AddSupportRoleComponent({ supportRoleToAdd, dropDownData, addSupportRole, closePopover }) {
  const [supportRoleObject, setSupportRoleObject] = useState({ ...SUPPORT_ROLES_OBJECT })
  function handleAdd() {
    addSupportRole(supportRoleObject)
    closePopover()
  }

  function handleChangeObject(event) {
    genericHandleChange(event, setSupportRoleObject)
  }
  function handleDateTimeChange(id, date) {
    const formattedDate = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    handleChangeObject({
      target: { id: id, value: formattedDate === 'Invalid Date' ? null : formattedDate },
    })
  }

  useEffect(() => {
    const todayDate = new Date()
    const todayFormatted = moment
      .utc(todayDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm:ss')
    setSupportRoleObject({
      ...supportRoleToAdd,
      startDate: supportRoleToAdd?.startDate || todayFormatted,
    })
  }, [supportRoleToAdd])

  return (
    <>
      <DialogTitle>Add a support role</DialogTitle>
      <DialogContent>
        {/* <CustomReactSelect
          id="clinicId"
          value={supportRoleObject?.clinicId}
          label="Clinic"
          options={dropDownData.clinics}
          getOptionValue={(object) => object.id}
          getOptionLabel={(object) =>
            object.singleTile || object.title || object.firstName || object?.id
          }
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          placeholder="Select a clinic..."
          menuPortalTarget={document.body}
          required
          setFirstByDefault
        /> */}
        <CustomReactSelect
          id="roleId"
          value={supportRoleObject?.roleId}
          label="Role"
          options={dropDownData.categories?.filter((c) => c.categoryType === 'support-staff')}
          getOptionValue={(object) => object.id}
          getOptionLabel={(object) => object.title || object.firstName}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          placeholder="Select a clinic..."
          menuPortalTarget={document.body}
          required
          setFirstByDefault
        />
        <PopoverTimePicker
          id="startDate"
          date={
            supportRoleObject?.startDate
              ? moment
                  .utc(supportRoleObject?.startDate, 'YYYY-MM-DD HH:mm:ss')
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              : null
          }
          label="Start Date"
          confirmButton={false}
          cancelButton={false}
          onDateChange={(date) => handleDateTimeChange('startDate', date)}
          timepicker
          required
          cleaner={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAdd}>Add</Button>
      </DialogActions>
    </>
  )
}

function SupportRoleComponent({
  roleObject,
  dropDownData,
  handleChange,
  deleteSupportRole,
  index,
}) {
  function handleDateTimeChange(id, date) {
    const formattedDate = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    handleChange({
      target: { id: id, value: formattedDate === 'Invalid Date' ? null : formattedDate },
    })
  }

  return (
    <SupportRoleBox>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomReactSelect
            id="clinicId"
            value={roleObject?.clinicId}
            label="Clinic"
            options={dropDownData.clinics}
            getOptionValue={(object) => object.id}
            getOptionLabel={(object) =>
              object.singleTile || object.title || object.firstName || object?.id
            }
            onChangeEvent={handleChange}
            selectType={selectTypes.string}
            placeholder="Select a clinic..."
            menuPortalTarget={document.body}
            required
            setFirstByDefault
          />
        </Grid>
        <Grid item xs={4}>
          <CustomReactSelect
            id="roleId"
            value={roleObject?.roleId}
            label="Role"
            options={dropDownData.categories?.filter((c) => c.categoryType === 'support-staff')}
            getOptionValue={(object) => object.id}
            getOptionLabel={(object) => object.title || object.firstName}
            onChangeEvent={handleChange}
            selectType={selectTypes.string}
            placeholder="Select a clinic..."
            menuPortalTarget={document.body}
            required
            setFirstByDefault
          />
        </Grid>
        <Grid item xs={4}>
          <CDatePicker
            id="startDate"
            date={
              roleObject?.startDate
                ? moment
                    .utc(roleObject?.startDate, 'YYYY-MM-DD HH:mm:ss')
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')
                : null
            }
            label="Start Date"
            confirmButton={false}
            cancelButton={false}
            onDateChange={(date) => handleDateTimeChange('startDate', date)}
            timepicker
            required
            cleaner={false}
          />
        </Grid>
      </Grid>
      <div>
        <IconButton onClick={deleteSupportRole}>
          <Delete />
        </IconButton>
      </div>
    </SupportRoleBox>
  )
}

SupportStaffForm.propTypes = {
  initialObject: object.isRequired,
  imageObject: object,
  setImageObject: func,
  handleChangeObject: func,
  handleNestedChangeObject: func,
  handleNestedNestedChangeObject: func,
  handleReactSelectChange: func,
  handleRichTextChange: func,
  handleImageChange: func,
  entity: string,
  allImages: array,
  customOptions: array,
  disabled: bool,
  view: string,
}
AddSupportRoleComponent.propTypes = {
  supportRoleToAdd: object,
  dropDownData: object,
  addSupportRole: object,
  closePopover: func,
}
SupportRoleComponent.propTypes = {
  roleObject: object,
  dropDownData: object,
  index: number,
  handleChange: func,
  deleteSupportRole: func,
}
