export const SETTINGS_API_LIST = {
  apiParams: {
    get: {
      url: 'config/api-params',
    },
    save: {
      url: 'config/api-param/save',
    },
  },
  translators: {
    get: {
      url: 'roles/translator',
    },
    save: {
      url: 'roles/translator/save',
    },
  },
  developers: {
    get: {
      url: 'roles/developer',
    },
    save: {
      url: 'roles/developer/save',
    },
  },
  ccFeatures: {
    get: {
      url: 'config/cc-features',
    },
    save: {
      url: 'config/cc-feature/save',
    },
  },
  roles: { get: { url: 'roles/all' }, approve: { url: 'roles/approve/role' } },
  cards: { get: { url: 'tile/page/cards/summary/list' }, save: { url: 'tile/page/cards/save' } },
  onTapTypes: {
    get: { url: 'tile/page/card-on-tap-types' },
    save: { url: 'tile/page/card-on-tap-types/save' },
  },
  itemTypes: {
    get: { url: 'tile/page/card-item-type/summary/list' },
    save: { url: 'tile/page/card-item-type/save' },
  },
  entityLayoutTypes: {
    get: { url: 'tile/page/card-layout-type/summary/list' },
    save: { url: 'tile/page/card-layout-type/save' },
  },
  cardFillingMethods: {
    get: { url: 'tile/page/card-filling-method/summary/list' },
    save: {
      url: 'tile/page/card-filling-method/save',
    },
  },
  cateogryTypes: {
    get: { url: 'tile/page/card-category-type/summary/list' },
    save: { url: 'tile/page/card-category-type/save' },
  },
  imageTypes: {
    get: { url: 'image-types/list/paginated' },
    save: { url: 'image-types/save' },
  },
  track: {
    get: { url: 'track/files/' },
    save: {
      url: 'track/file/update',
    },
  },
  mentors: {
    get: { url: 'mentor/mentor/' },
    approve: { url: 'roles/approve/creator' },
    allowPrescribe: { url: 'mentor/write-prescription/allow' },
    denyPrescribe: { url: 'mentor/write-prescription/deny' },
  },
  clinics: {
    approve: { url: 'roles/approve/clinic-prospect' },
  },
  company: {
    approve: { url: 'roles/approve/company-prospect' },
  },
  userNames: {
    get: {
      url: 'roles/search-cc-users',
    },
  },
  listabilityPolicy: { get: { url: 'policy/listability' } },
}
