import { putAdditionalDataForNotificationsToItsPositionDefaultHandling } from './defaultHandling'

export function putAdditionalDataForNotificationsToItsPosition(
  notificationData,
  dlData,
  categoryType,
) {
  const tempNotificationData = notificationData

  switch (categoryType) {
    default:
      return putAdditionalDataForNotificationsToItsPositionDefaultHandling(
        tempNotificationData,
        dlData,
      )
  }
}
