import React from 'react'
import BaseSubscriptionPlanComponent from '../plans/components/SubscriptionPlanFormComponents/BaseSubscriptionPlanComponent'

export default function FeatureBundleForm({
  initialObject = {},
  imageObject: subscription,
  setImageObject: setSubscription,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  formMode,
}) {
  return (
    <div className="row g-3">
      <BaseSubscriptionPlanComponent
        allPlanGroups={customOptions?.allPlanGroups}
        allPlanLevels={customOptions?.allPlanLevels}
        mode={formMode}
        setSubscription={setSubscription}
        subscription={subscription}
        allSubscriptionPlans={customOptions?.allSubscriptionPlans}
        hidePlanGroup
        hideCustomerType
      />
    </div>
  )
}
