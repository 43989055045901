import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { getSummarizableObject } from '../abstractObjects/Summarizable'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

const PAYWALL_ITEM_PRICE = {
  // productId: '',
  price: 0,
  discount: 0,
  finalPrice: 0,
  currency: '',
}

export const PAYWALL_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.paywalls),
  title: '',
  currencyCode: '',
  currencySymbol: '',
  planGrp: '',
  customPricing: '',
  items: [],
}

export const PAYWALL_ITEM = {
  title: '',
  description: '',
  successText: '',
  planId: '',
  preselected: false,
  popular: false,
  price: PAYWALL_ITEM_PRICE,
}
