import React, { useEffect } from 'react'

export default function NormalListingTableHandler({
  apiCallUrl,
  apiCallFunction,
  apiCallParams,
  data,
  setData,

  setupDataDependencies = [],

  children,
}) {
  async function getSubscriptionHistory() {
    const resp = await apiCallFunction(apiCallUrl, { ...apiCallParams })
    if (resp.status === 200) {
      setData((prev) => ({ ...prev, data: resp.data }))
    }
  }

  async function Setup() {
    if (data?.data?.length <= 0 || !data?.data) {
      if (data?.status !== 'success') {
        setData((prev) => ({ ...prev, status: 'loading' }))
      }
      await getSubscriptionHistory()
    }
    setData((prev) => ({ ...prev, status: 'success' }))
  }

  useEffect(() => {
    Setup()
  }, setupDataDependencies)

  return <>{children}</>
}
