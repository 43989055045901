import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { COURSE_CMS_FORM_FIELDS_SECTIONS_API_SIGNATURE_MAP } from 'src/services/apiSignatureMaps/onlineCourse/courseConfig/apis'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'

export const fetchSectionsAndFieldsForACategory = createAsyncThunk(
  'courseCmsForm/fetchSectionsAndFieldsForACategory',
  async (
    { categoryId, forCourseForm = false },
    { getState, fulfillWithValue, rejectWithValue, dispatch },
  ) => {
    fetchSectionsAndFieldsForACategory.pending()
    const { courseCmsForm } = getState()
    const sectionsAndFields = courseCmsForm.sectionsAndFields.data
    const resp = await GetSettingsData(
      COURSE_CMS_FORM_FIELDS_SECTIONS_API_SIGNATURE_MAP.sectionsAndFieldsForACategory.get,
      {
        'course-category-id': categoryId,
        'for-course-form': forCourseForm,
      },
    )
    if (resp.status === 200) {
      if (isCommonResponseSuccessful(resp.data.code)) {
        return resp.data.data
      } else {
        return rejectWithValue(resp.data.message)
      }
    } else {
      return rejectWithValue('Something went wrong')
    }
  },
)

export const saveSectionAndFieldsForACategory = createAsyncThunk(
  'courseCmsForm/saveSectionAndFieldsForACategory',
  async (
    { categoryId, sections, fields, afterSuccessfulFunction = () => {} },
    { getState, fulfillWithValue, rejectWithValue, dispatch },
  ) => {
    saveSectionAndFieldsForACategory.pending()
    const tst = toast.loading('Saving...')
    const { courseCmsForm } = getState()
    const sectionsAndFields = courseCmsForm.sectionsAndFields.data
    const resp = await PostSettingsData(
      COURSE_CMS_FORM_FIELDS_SECTIONS_API_SIGNATURE_MAP.sectionsAndFieldsForACategory.save,
      {
        sections: sections,
        fields: fields,
      },
      {
        'course-category-id': categoryId,
      },
    )
    if (resp.status === 200) {
      if (isCommonResponseSuccessful(resp.data.code)) {
        toast.update(tst, {
          render: 'Saved Successfully',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          ...TOAST_UTILS,
        })
        afterSuccessfulFunction()
        return resp.data.data
      } else {
        toast.update(tst, {
          render: 'Something went wrong. Please try again.',
          type: toast.TYPE.ERROR,
          isLoading: false,
          ...TOAST_UTILS,
        })
        return rejectWithValue(resp.data.message)
      }
    } else {
      toast.update(tst, {
        render: 'Something went wrong. Please try again.',
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
      return rejectWithValue('Something went wrong')
    }
  },
)
