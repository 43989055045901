import { CFormInput } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { COMMUNICATION_TYPES_ARRAY } from 'src/utilities/constants'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'

export default function UploadCsvDialog({ apiParam, handleRefresh, closeDialog }) {
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )
  const [uploadedCsvFile, setUploadedCsvFile] = useState()
  const [csvDelimiter, setCsvDelimiter] = useState()
  const [listDelimiter, setListDelimiter] = useState()
  const [communicationTypes, setCommunicationTypes] = useState()

  function setShowConfirmation() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'save',
      body: 'Do you want to save the data in the uploaded CSV?',
      buttonColor: 'success',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      submitButtonText: 'Save Data',
      closeButtonText: 'Cancel',
      onSubmitFunctions: [
        () => {
          saveDataToDb()
        },
      ],
    }))
  }

  async function saveDataToDb() {
    const tst = toast.loading('Saving Bulk Data')
    const formData = new FormData()
    formData.append('file', uploadedCsvFile)

    const resp = await PostSettingsData(GENERIC_APIS.entity.saveCsv, formData, {
      entityType: apiParam,
      'csv-delimiter': csvDelimiter,
      'list-delimiter': listDelimiter,
      'communication-type': communicationTypes?.join(','),
    })
    if (resp.status === 200) {
      if (isCommonResponseSuccessful(resp.data.code)) {
        toast.update(tst, {
          ...TOAST_UTILS,
          render: "Save operation has begin. The admins will be notified once it's done", 
          closeOnClick: false,
          autoClose: 5000,
          isLoading: false,
          type: toast.TYPE.SUCCESS,
        })
        handleRefresh()
        closeDialog()
        return
      }
    }
  }

  function handleCsvUpload(event) {
    event.preventDefault()
    if (!!event.target?.files && !!event.target.files[0]) {
      setUploadedCsvFile(event.target.files[0])
    }
  }

  return (
    <>
      <DialogTitle>Import Patients From CSV file</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <CCFormInput
              id="csv-delimiter"
              value={csvDelimiter}
              label="CSV Delimiter"
              placeholder=","
              subLabel="Character that separates the headers and cells in the CSV"
              onChange={(e) => {
                const value = e.target.value
                if (value.length <= 1) {
                  setCsvDelimiter(value)
                }
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CCFormInput
              id="list-delimiter"
              value={listDelimiter}
              label="List Delimiter"
              placeholder=";"
              subLabel="Characters that separate the list values in the uploaded csv"
              onChange={(e) => {
                setListDelimiter(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomReactSelect
              isMulti
              id="comm-modes"
              value={communicationTypes}
              label="Communication Modes"
              options={COMMUNICATION_TYPES_ARRAY}
              selectType={selectTypes.string}
              getOptionLabel={(option) => option?.title}
              getOptionValue={(option) => option?.id}
              subLabel="Note that if None is in this list, no communication will be sent"
              onChangeEvent={(e) => {
                setCommunicationTypes(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CFormInput type="file" accept=".csv" onChange={handleCsvUpload} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!uploadedCsvFile}
          color="success"
          onClick={() => setShowConfirmation()}
        >
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}
