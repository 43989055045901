import { SATELLITE_SCREEN_OBJECT } from 'src/common/types/satelliteScreen/satelliteScreenObject'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export async function transformInitialClinicChamberData(data, locationParams) {
  const { clinicId, clinicBrandId } = locationParams
  var satelliteScreenData = SATELLITE_SCREEN_OBJECT
  if (data?.id) {
    if (data.satelliteScreenId) {
      satelliteScreenData = await GetDetailedEntity(ALL_ENTITY_TYPES_FROM_CONSTANTS.satelliteCall, {
        id: data.satelliteScreenId,
      })
    }
  }

  return { ...data, clinicId, clinicBrandId, satelliteScreenId: satelliteScreenData }
}
