export async function transformInitialDoctorUserListingData({ listingData }) {
  var consolidatedClinicBrands = []

  // const clinicBrandResponse = await GetSettingsData(
  //   DOCTORS_API_SIGNATURES_MAP.getClinicBrandAssociations,
  //   {
  //     userIds: listingData?.map((user) => user?.userId)?.join(','),
  //   },
  // )
  // if (clinicBrandResponse.status === 200) {
  //   consolidatedClinicBrands = clinicBrandResponse.data

  //   const clinicLocationResponse = await GetSettingsData(
  //     DOCTORS_API_SIGNATURES_MAP.getClinicAssociations,
  //     {
  //       userIds: listingData?.map((user) => user?.userId)?.join(','),
  //     },
  //   )
  //   if (clinicLocationResponse.status === 200) {
  //     consolidatedClinicBrands = putClinicObjectsInRelevantClinicBrandObjects(
  //       clinicBrandResponse.data,
  //       clinicLocationResponse.data,
  //     )
  //   }
  // }

  console.log({ consolidatedClinicBrands })

  return listingData?.map((user) => {
    const foundClinicBrand = consolidatedClinicBrands.find((clinicBrand) => {
      return clinicBrand?.workforce?.doctors?.find((doctor) => doctor?.userId === user?.userId)
    })
    return {
      ...user,
      associatedClinicBrand: foundClinicBrand,
    }
  })
}
