import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import {
  PHASE_VIEW_TYPES,
  PhaseContainer,
} from 'src/views/subscriptions/customPlans/components/Phases'

export default function PlanPhaseComponent({
  initialObject = {},
  imageObject: planPhaseObject,
  setImageObject: setPlanPhaseObject,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  view = 'default',
}) {
  return (
    <>
      <div className="col-md-12">
        <FormControlLabel
          control={
            <Switch
              size={'small'}
              id="enabled"
              checked={!!planPhaseObject?.enabled || false}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          }
          label={'Enabled'}
        />
      </div>
      <PhaseContainer
        collapseAllPhases={false}
        disableFields={disabled}
        phase={planPhaseObject}
        handleChange={handleChangeObject}
        view={PHASE_VIEW_TYPES.default}
      />
    </>
  )
}
