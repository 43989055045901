import { CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { genericHandleChangeButtonClickData } from 'src/utilities/generalUtils'
import { TIME_SLOT_VIEWS } from 'src/views/settings/utils/timeSlot-utils'
import ColorGradientPicker from '../ColorGradientPicker'
import ImageUpload from '../imageUpload/ImageUpload'
import { COLORS_OBJECT } from '../imageUpload/imageUploadUtils'
import LinksMakerComponents from '../linksMaker/LinksMakerComponents'
import {
  ColorGradientBorder,
  DispLayMsgDetailsSectionContainer,
  ImageUploadSectionContainer,
} from '../styledComponents'
import DisplayMessageTemplateView from './DisplayMessageTemplateView'
import DisplayTemplateMessageGrid from './DisplayTemplateMessageGrid'

export default function DisplayMessageTemplateForm({
  currentCard,
  edit,
  setCurrentCard,
  view = 'repo',
}) {
  const [openDialog, setOpenDialog] = useState(false)

  function handleChange(event) {
    setCurrentCard((prev) => {
      return {
        ...prev,
        [event.target.id]:
          event.target.type !== 'checkbox' ? event.target.value : event.target.checked,
      }
    })
  }
  function handleListItemChange(event, index, id) {
    setCurrentCard((prev) => {
      return {
        ...prev,
        [id]: prev[id]?.map((lst, idx) => (idx === index ? event.target.value : lst)),
      }
    })
  }
  function handleAddTitleList() {
    setCurrentCard((prev) => {
      return {
        ...prev,
        titleList: [...(prev?.titleList || []), ''],
      }
    })
  }

  async function handleClick(prevItemfunc) {
    setCurrentCard(() => prevItemfunc())
  }

  function handleSetColors(colorSetter) {
    setCurrentCard((prev) => {
      return {
        ...prev,
        colors: colorSetter(prev.colors),
      }
    })
  }
  function handleShowColors() {
    setCurrentCard((prev) => {
      return { ...prev, colors: !!prev?.colors ? null : COLORS_OBJECT }
    })
  }
  function handleCloseDialog() {
    setOpenDialog(false)
  }

  useEffect(() => {
    console.log(edit)
  }, [edit])

  return (
    <div>
      {edit === true && view === 'repo' && (
        <Button onClick={() => setOpenDialog(true)} color="secondary" variant="contained">
          Repository
        </Button>
      )}
      <div className="row px-3">
        <div style={{ position: 'sticky', top: '0px', zIndex: '3' }}>
          <DisplayMessageTemplateView item={currentCard} />
        </div>
        {edit === true ? (
          <>
            <DispLayMsgDetailsSectionContainer className="row col-md-12 g-1 p-3">
              <h3>Card Details</h3>
              {(view === TIME_SLOT_VIEWS.displayMessageEdit ||
                TIME_SLOT_VIEWS.displayMsgTemplateEditWithType) && (
                <div className="col-md-12">
                  <CFormSelect
                    id="type"
                    value={currentCard.type}
                    onChange={handleChange}
                    label="Card Type"
                  >
                    <option value={undefined || '' || null} selected disabled hidden>
                      Select a Type
                    </option>
                    {Object.entries(messageTemplatesOptions).map(([key, value]) => (
                      <option value={key} key={key}>
                        {value}
                      </option>
                    ))}
                  </CFormSelect>
                </div>
              )}
              <div className={colMediaAsPerView?.[view] || 'col-md-6'}>
                <CFormInput
                  id="title"
                  label="Title"
                  value={currentCard.title}
                  onChange={handleChange}
                />
              </div>
              {/* {currentCard.type === 'sentinelCard' && (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6>Title List</h6>
                    <IconButton onClick={handleAddTitleList}>
                      <Add />
                    </IconButton>
                  </div>
                  <div className="d-flex flex-column align-items-start justify-content-stretch">
                    {currentCard?.titleList?.map((list, index) => (
                      <CFormInput
                        key={index}
                        label={`Title List ${index + 1}`}
                        value={list}
                        onChange={(e) => handleListItemChange(e, index, 'titleList')}
                      />
                    ))}
                  </div>
                </>
              )} */}
              <div className={colMediaAsPerView?.[view] || 'col-lg-6'}>
                <CFormInput
                  id="subText"
                  label="SubText"
                  value={currentCard.subText}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 d-flex justify-content-between align-items-center mt-2">
                <CFormLabel>Action Button</CFormLabel>
                <Switch id="actionBtn" checked={currentCard.actionBtn} onChange={handleChange} />
              </div>
              {currentCard.actionBtn && (
                <div className={colMediaAsPerView?.[view] || 'col-md-12'}>
                  <LinksMakerComponents
                    btnTxt={currentCard.actionBtnText}
                    uri={currentCard.actionBtnUri}
                    handleChange={(btnTxtAndUri) =>
                      genericHandleChangeButtonClickData(
                        btnTxtAndUri,
                        'actionBtnText',
                        'actionBtnUri',
                        setCurrentCard,
                      )
                    }
                  />
                </div>
              )}
              <div className="col-md-12 d-flex justify-content-between align-items-center mt-2">
                <CFormLabel>Extra Link</CFormLabel>
                <Switch id="extraLink" checked={currentCard.extraLink} onChange={handleChange} />
              </div>
              {currentCard.extraLink && (
                <div className={colMediaAsPerView?.[view] || 'col-md-12'}>
                  <LinksMakerComponents
                    btnTxt={currentCard.extraLinkText}
                    uri={currentCard.extraLinkURI}
                    handleChange={(btnTxtAndUri) =>
                      genericHandleChangeButtonClickData(
                        btnTxtAndUri,
                        'extraLinkText',
                        'extraLinkURI',
                        setCurrentCard,
                      )
                    }
                  />
                </div>
              )}
            </DispLayMsgDetailsSectionContainer>
            <ColorGradientBorder>
              <ColorGradientPicker
                colors={currentCard?.colors}
                sectionTitle="Colors"
                edit={edit}
                showColors={!!currentCard?.colors}
                setColors={handleSetColors}
                setShowColors={handleShowColors}
              />
            </ColorGradientBorder>
            <ImageUploadSectionContainer>
              <h3>Images</h3>
              <div className="d-flex justify-content-start align-items-center m-2">
                <ImageUpload
                  disabled={false}
                  imageId={currentCard.imageId}
                  imageType="icon"
                  title="Display Message Image"
                  view="displayMessageImage"
                  onChange={(imgID) => handleChange(handleImageIdChange(imgID, 'imageId'))}
                />
              </div>
            </ImageUploadSectionContainer>
          </>
        ) : (
          <></>
        )}
      </div>
      <Dialog
        PaperProps={{
          sx: {
            width: '100%!important',
            maxHeight: '89vh',
            height: '100%!important',
            maxWidth: '1200px !important',
          },
        }}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          <h1>Display Template Message Repository</h1>
        </DialogTitle>
        <DialogContent>
          <DisplayTemplateMessageGrid
            onChange={handleClick}
            handleClose={handleCloseDialog}
            type={currentCard.type}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export function handleImageIdChange(imgId, id) {
  return { target: { value: imgId, id: id, type: 'file' } }
}

export const messageTemplatesOptions = {
  sentinelCard: 'Sentinel Card',
  emptySlotMsg: 'Empty Slot Card',
  completedSlotMsg: 'Completed Slot Card',
  'landing-card': 'Landing Card',
}

export const colMediaAsPerView = {
  assessment: 'col-md-12',
}

// <div className={colMediaAsPerView?.[view] || 'col-md-6'}>
//   <CFormInput
//     id="actionBtnText"
//     label="Action Button Text"
//     value={currentCard.actionBtnText}
//     onChange={handleChange}
//   />
// </div>
// <div className={colMediaAsPerView?.[view] || 'col-md-6'}>
//   <CFormInput
//     id="actionBtnUri"
//     label="Action Button URI"
//     value={currentCard.actionBtnUri}
//     onChange={handleChange}
//   />
// </div>

// <div className={colMediaAsPerView?.[view] || 'col-md-6'}>
//   <CFormInput
//     id="extraLinkUri"
//     label="Extra Link URI"
//     value={currentCard.extraLinkURI}
//     onChange={handleChange}
//   />
// </div>
// <div className={colMediaAsPerView?.[view] || 'col-md-6'}>
//   <CFormInput
//     id="extraLinkText"
//     label="Extra Link Text"
//     value={currentCard.extraLinkText}
//     onChange={handleChange}
//   />
// </div>
