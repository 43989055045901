import { ImageOutlined, VideoFile } from '@mui/icons-material'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { BACKGROUND_SLIDES_IMAGE_TYPES, BACKGROUND_SLIDES_PAGES } from '../BackgroundSlidesUpload'
import { getAspectRatioFromResolutionString } from '../imageUpload/ImageSizeSelect'
import { AspectRatioContainer, BGSButtonOverLay, ChooseUploadButtons } from '../styledComponents'
import { BGSChooseButtonBackground, BGSChooseButtonContentContainer } from './styledComponents'

export default function BGSChoose({
  bgSlide,
  handleChange,
  setBgSlide,
  setCurrentPage,
  setPageType,
}) {
  const aspectRatios = {
    landScape: getAspectRatioFromResolutionString('1920x1080'),
    portrait: getAspectRatioFromResolutionString('1080x1920'),
  }
  const memoBGImages = useMemo(() => {
    const toRet = Object.entries(BACKGROUND_SLIDES_IMAGE_TYPES).reduce(
      (accum, [type, value], idx) => {
        return {
          ...accum,
          [value]: Array.isArray(bgSlide?.[value])
            ? bgSlide?.[value]?.map((slide) => {
                return slide.image.imageId
              })
            : bgSlide?.[value],
        }
      },
      {},
    )
    return toRet
  }, [bgSlide])
  function handlePhotoUpload() {
    setCurrentPage(BACKGROUND_SLIDES_PAGES.photo)
  }
  function handleVideoUpload() {
    setCurrentPage(BACKGROUND_SLIDES_PAGES.video)
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      columns={{ sm: 6, md: 6, xl: 6 }}
      spacing={4}
      height="100%"
    >
      <Grid item>
        <ChooseUploadButtons
          {...buttonProps}
          onClick={() => {
            setPageType(BACKGROUND_SLIDES_IMAGE_TYPES.landScapeImage)
            handlePhotoUpload()
          }}
        >
          <div style={squareDivStyles}>
            <AspectRatioContainer uploaded={true} aspectRatio={aspectRatios.landScape}>
              <BGSChooseButtonBackground
                images={memoBGImages?.[BACKGROUND_SLIDES_IMAGE_TYPES.landScapeImage]}
              >
                <BGSButtonOverLay />
                <BGSChooseButtonContentContainer>
                  <ImageOutlined sx={iconStyles} />
                  Landscape Image
                </BGSChooseButtonContentContainer>
              </BGSChooseButtonBackground>
            </AspectRatioContainer>
          </div>
        </ChooseUploadButtons>
      </Grid>
      <Grid item>
        <ChooseUploadButtons
          {...buttonProps}
          onClick={() => {
            setPageType(BACKGROUND_SLIDES_IMAGE_TYPES.portraitImage)
            handlePhotoUpload()
          }}
        >
          <div style={squareDivStyles}>
            <AspectRatioContainer uploaded={true} aspectRatio={aspectRatios.portrait}>
              <BGSChooseButtonBackground
                images={memoBGImages?.[BACKGROUND_SLIDES_IMAGE_TYPES.portraitImage]}
              >
                <BGSButtonOverLay />
                <BGSChooseButtonContentContainer>
                  <ImageOutlined sx={iconStyles} />
                  Portrait Image
                </BGSChooseButtonContentContainer>
              </BGSChooseButtonBackground>
            </AspectRatioContainer>
          </div>
        </ChooseUploadButtons>
      </Grid>
      <Grid item>
        <ChooseUploadButtons
          {...buttonProps}
          onClick={() => {
            setPageType(BACKGROUND_SLIDES_IMAGE_TYPES.landScapeVideo)
            handleVideoUpload()
          }}
        >
          <div style={squareDivStyles}>
            <AspectRatioContainer uploaded={true} aspectRatio={aspectRatios.landScape}>
              <BGSChooseButtonBackground>
                <BGSChooseButtonContentContainer>
                  <VideoFile sx={iconStyles} />
                  Landscape Video
                </BGSChooseButtonContentContainer>
              </BGSChooseButtonBackground>
            </AspectRatioContainer>
          </div>
        </ChooseUploadButtons>
      </Grid>
      <Grid item>
        <ChooseUploadButtons
          {...buttonProps}
          onClick={() => {
            setPageType(BACKGROUND_SLIDES_IMAGE_TYPES.portraitVideo)
            handleVideoUpload()
          }}
        >
          <div style={squareDivStyles}>
            <AspectRatioContainer uploaded={true} aspectRatio={aspectRatios.portrait}>
              <BGSChooseButtonBackground>
                <BGSChooseButtonContentContainer>
                  <VideoFile sx={iconStyles} />
                  Portrait Video
                </BGSChooseButtonContentContainer>
              </BGSChooseButtonBackground>
            </AspectRatioContainer>
          </div>
        </ChooseUploadButtons>
      </Grid>
    </Grid>
  )
}

BGSChoose.propTypes = {
  bgSlide: PropTypes.object,
  handleChange: PropTypes.func,
  setBgSlide: PropTypes.func,
  setCurrentPage: PropTypes.func,
  setUploadedFile: PropTypes.func,
  setPageType: PropTypes.func,
}

const squareDivStyles = {
  width: '100%',
  aspectRatio: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
}

const buttonProps = {
  customBackgroundColor: '#EBF0FF',
  height: '300px',
  width: '300px',
}
const iconStyles = { fontSize: 60 }
