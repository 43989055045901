import { createSlice } from '@reduxjs/toolkit'
import {
  getUserAuthoritiesFromLocalStorage,
  setUserAuthoritiesToLocalStorage,
} from 'src/services/BrowserStorage/localStorageController'
import { appSettingsExtraReducers } from './statusReducers'

const initialState = {
  userId: '',
  sidebarShow: true,
  sidebarUnfoldable: true,
  asideShow: false,
  headerMargin: true,
  theme: 'default',
  roles: [],
  tags: [],
  categories: [],
  timeSlots: [],
  countries: [],
  languages: [],
  dailyPlaySettings: [],
  imageTypes: [],
  mentalStateTypes: [],
  metaCategoryTypes: [],
  storeDropDownData: {},
  apiParams: [],
  selectedMentorForCourseFlow: null,
  allMentors: {
    data: [],
    status: 'idle',
    error: null,
  },

  collectedUsers: {},
  loadingCollectedUsers: false,
}

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setAppSettings(state, action) {
      return { ...state, ...action.payload }
    },
    setProfileAuthorities(state, action) {
      var roleToSet
      if (action?.payload) {
        roleToSet = action.payload
        setUserAuthoritiesToLocalStorage(action.payload)
      } else {
        roleToSet = getUserAuthoritiesFromLocalStorage()
      }
      return { ...state, roles: roleToSet }
    },
    setSelectedMentorForCourseFlow(state, action) {
      return { ...state, selectedMentorForCourseFlow: action.payload }
    },
  },
  extraReducers: appSettingsExtraReducers,
})

export const getUserId = (state) => state.appSettings.userId
export const getSidebarShow = (state) => state.appSettings.sidebarShow
export const getSidebarUnfoldable = (state) => state.appSettings.sidebarUnfoldable
export const getAsideShow = (state) => state.appSettings.asideShow
export const getHeaderMargin = (state) => state.appSettings.headerMargin
export const getTheme = (state) => state.appSettings.theme
export const getRoles = (state) => state.appSettings.roles
export const getTags = (state) => state.appSettings.tags
export const getCategories = (state) => state.appSettings.categories
export const getTimeSlots = (state) => state.appSettings.timeSlots
export const getCountries = (state) => state.appSettings.countries
export const getLanguages = (state) => state.appSettings.languages
export const getDailyPlaySettings = (state) => state.appSettings.dailyPlaySettings
export const getImageTypes = (state) => state.appSettings.imageTypes
export const getMentalStateTypes = (state) => state.appSettings.mentalStateTypes
export const getMetaCategoryTypes = (state) => state.appSettings.metaCategoryTypes
export const getStoreDropDownData = (state) => state.appSettings.storeDropDownData
export const getApiParams = (state) => state.appSettings.apiParams
export const getSelectedMentorForCourseFlow = (state) =>
  state.appSettings.selectedMentorForCourseFlow
export const getAllMentors = (state) => state.appSettings.allMentors
export const getCollectedUsers = (state) => state.appSettings.collectedUsers
export const getLoadingCollectedUsers = (state) => state.appSettings.loadingCollectedUsers

export const { setAppSettings, setProfileAuthorities, setSelectedMentorForCourseFlow } =
  appSettingsSlice.actions
export const appSettingsReducer = appSettingsSlice.reducer
