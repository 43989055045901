import {
  ALL_ENTITY_TYPES_FROM_CONSTANTS,
  INVESTIGATION_TEST_TYPES,
  INVESTIGATION_TYPES,
} from 'src/utilities/constants'
import { getSummarizableObject } from '../abstractObjects/Summarizable'

export const RANGE_OBJECT = {
  min: '',
  max: '',
  normalRange: false,
  unit: '',
}

export const INVESTIGATION_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.investigation),
  title: '',
  description: '',
  type: INVESTIGATION_TEST_TYPES.pathology,
  investigationType: INVESTIGATION_TYPES.test,
  units: [],
  category: ['biochemistry'],
  // referenceRange: [{ ...RANGE_OBJECT, normalRange: true }],
  referenceRange: [],
  availableToAll: true,
}
