import { PLAN_GROUP_TYPES } from 'src/utilities/constants'
import { SUBSCRIPTION_PLAN_OBJECT } from 'src/views/subscriptions/plans/utils'
import { FEATURE_BUNDLE_OBJECT } from './featureBundle/featureBundleObject'

export const PLAN_BUNDLE_PACKS_OBJECT = {
  ...SUBSCRIPTION_PLAN_OBJECT,
  apiParam: 'plan-bundle-pack',
  planGrpId: PLAN_GROUP_TYPES.bundle,
  bundle: [FEATURE_BUNDLE_OBJECT],
  type: '',
  pricingType: '',
  minSeats: '',
  maxSeats: '',
}
