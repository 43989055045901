import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { MediaPreviewObject, VideoUploadButton } from './styledComponents'

import ReactPlayer from 'react-player'
import { getVideoMediaUrl } from 'src/services'

export default function BGSVideoPreview({ uploadedFile, uploadButtonRef }) {
  function handleClick() {
    uploadButtonRef.current.click()
  }
  // const vidUrlPrefix = `${apiURL}/api/media/file/`
  const vidUrlPrefix = `${getVideoMediaUrl()}`

  return (
    <div>
      <Box {...MediaPreviewObject}>
        {!!uploadedFile ? (
          <ReactPlayer
            playing
            loop
            muted
            controls
            width="100%"
            height="100%"
            stopOnUnmount
            url={`${vidUrlPrefix}${uploadedFile}`}
          >
            Sorry your browser does not support video playback
          </ReactPlayer>
        ) : (
          <VideoUploadButton onClick={handleClick}>
            <h1>Click to upload video</h1>
          </VideoUploadButton>
        )}
      </Box>
    </div>
  )
}

BGSVideoPreview.propTypes = {
  uploadedFile: PropTypes.any,
  uploadButtonRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}
