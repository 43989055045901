export const DRAFTS_API_MAP = {
  getActiveContentDraft: 'draft/get-active-content-draft',
  getById: 'draft/get-by-id',
  activate: 'draft/activate',
  save: 'draft/save',
  submitForApproval: 'draft/submit-for-approval',
  activateRejectedDraft: 'draft/activate-rejected-draft',
  approvalRequests: 'draft/approval-requests',
  retractApprovalRequest: 'draft/retract-approval',
}
