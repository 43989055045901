import { CFormInput } from '@coreui/react-pro'
import { Grid } from '@mui/material'
import React from 'react'
import {
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import NormalSectionWrapper from 'src/components/controlCenter/common/sectionComponents/NormalSectionWrapper'
import { defaultLinkMakerContextData } from 'src/components/controlCenter/linksMaker/LinksMakerComponents'
import DeepLinkBuilder from 'src/components/controlCenter/linksMaker/components/editLink/deepLink/DeepLinkBuilder'
import { NOTIFICATIONS_MEDIUMS } from 'src/utilities/constants'
import { giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { TableImageComponent } from 'src/views/settings/components/TableImage'
import { NotificationImageUrlGridItem } from './components/styledComponents'

const outerSections = {
  basicDetails: 'Basic Details',
  mediumDetails: 'Target',
  payloadDetails: 'Additional Options',
  images: 'Images',
}

const sectionsMap = {
  basicDetails: 'basicDetails',
  mediumDetails: 'mediumDetails',
  payloadDetails: 'payloadDetails',
}

export default function NotificationsComponent({
  initialObject,
  imageObject: notificationsObject,
  setImageObject: setNotificationsObject,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const dropDownData = {
    ...customOptions,
    ...store,
    metaCategoryTypes: [
      ...(store?.metaCategoryTypes || []),
      {
        id: 'booked_event_notification',
        title: 'Booked Event Notification',
        enabled: true,
        scope: 'app',
      },
    ],
  }

  console.log({ dropDownData })

  function handleChangeDlPart(eve) {
    setNotificationsObject((prev) => ({
      ...prev,
      dlData: giveGenericHandleChangedData(eve, prev?.dlData),
    }))
  }
  function handleChangeDlPartAdditionalData(eve) {
    setNotificationsObject((prev) => ({
      ...prev,
      dlData: {
        ...prev?.dlData,
        additionalData: giveGenericHandleChangedData(eve, prev?.dlData?.additionalData),
      },
    }))
  }

  function handleChangeDlPartExtraData(eve) {
    setNotificationsObject((prev) => ({
      ...prev,
      dlData: {
        ...prev?.dlData,
        extraData: giveGenericHandleChangedData(eve, prev?.dlData?.extraData),
      },
    }))
  }

  return (
    <div className="w-100">
      <NormalSectionWrapper heading={outerSections?.basicDetails}>
        <GridSectionWrapper shadow={false}>
          <Grid item xs={12} md={6}>
            <CFormInput
              id="internalTitle"
              label="Internal Title"
              value={notificationsObject?.internalTitle}
              onChange={handleChangeObject}
              disabled={disabled}
              placeholder="Enter the internal title..."
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormInput
              id="title"
              label="Title"
              value={notificationsObject?.title}
              onChange={handleChangeObject}
              disabled={disabled}
              placeholder="Enter the notification title..."
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CCTextArea
              id="description"
              label="Notification Description"
              placeholder="Enter the notification description..."
              value={notificationsObject?.description}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          </Grid>
        </GridSectionWrapper>
      </NormalSectionWrapper>
      <NormalSectionWrapper heading={outerSections?.mediumDetails}>
        <GridSectionWrapper shadow={false}>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              isMulti
              id="medium"
              value={notificationsObject?.medium}
              label="Notification Medium"
              placeholder="Select the notification mediums..."
              options={NOTIFICATIONS_MEDIUMS}
              disabled={disabled}
              getOptionValue={(opt) => opt?.id}
              getOptionLabel={(opt) => opt?.title}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <UserInputComponent
              isMulti
              disabled={disabled}
              onChange={handleChangeUsers}
              value={notificationsObject?.userIds}
              label="Users to Notify"
              placeholder="All Users"
              required
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="role"
              value={notificationsObject?.role}
              label="User Role"
              placeholder="Select the role of the users that will receive this notification..."
              options={dropDownData?.allRoles
                ?.slice()
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              disabled={disabled}
              getOptionValue={(opt) => opt?.name}
              getOptionLabel={(opt) => opt?.title}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
              required
            />
          </Grid>
        </GridSectionWrapper>
      </NormalSectionWrapper>
      <NormalSectionWrapper heading={outerSections?.payloadDetails}>
        <GridSectionWrapper heading="Auto Generated" shadow={false}>
          <Grid item xs={12}>
            <DeepLinkBuilder
              deepLinkPart={notificationsObject?.dlData || { ...defaultLinkMakerContextData }}
              dropDownOptions={dropDownData}
              setDeepLinkPart={handleChangeDlPart}
              setAdditionalData={handleChangeDlPartAdditionalData}
              handleChangeExtraData={handleChangeDlPartExtraData}
              linkCategoryTypeLabel="Notification Type"
              linkTypeLabel="Notification Sub Type"
              linkIdSelectionLabel="Source ID"
              extraDataItemIdSelectionLabel="Extra Data Source ID"
              extraDataLinkTypeLabel="Extra Data Sub Type"
              disabled={disabled}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="type"
              value={notificationsObject?.type}
              label="Notification Type"
              placeholder="Select the type of notification..."
              options={dropDownData?.metaCategoryTypes
                ?.filter((type) => type.scope === META_CATEGORY_SCOPE_TYPES?.[0]?.id)
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              disabled={disabled}
              getOptionValue={(opt) => opt?.id}
              getOptionLabel={(opt) => opt?.title}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="subType"
              value={notificationsObject?.subType}
              label="Sub Type"
              placeholder="Select the sub type..."
              options={Object.entries(IdTypes)?.map(([key, value]) => ({
                id: value,
                title: capitalize(key),
              }))}
              disabled={disabled}
              getOptionValue={(opt) => opt?.id}
              getOptionLabel={(opt) => opt?.title}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
            />
          </Grid>
          {notificationsObject?.subType !== IdTypes.list && !!notificationsObject?.subType && (
            <Grid item xs={12} md={12}>
              <div className="d-flex w-100 flex-column">
                <CFormLabel
                  htmlFor="sourceId"
                  className={`${!!notificationsObject?.type ? '' : 'mb-0'}`}
                >
                  Source ID
                </CFormLabel>
                {!notificationsObject?.type && (
                  <CFormLabel htmlFor="sourceId" className="text-info fs-8">
                    You need to select a notification type to interact with this field.
                  </CFormLabel>
                )}
              </div>
              <CustomAsyncPaginate
                id="sourceId"
                cacheUniqs={[notificationsObject?.type]}
                value={notificationsObject?.sourceId}
                placeholder="Select the source ID..."
                isDisabled={disabled || !notificationsObject?.type}
                getOptionValue={(opt) => opt?.id}
                getOptionLabel={(opt) => opt?.title}
                loadOptions={loadSrcOptions}
                debounceTimeout={500}
                additional={{
                  page: 0,
                  metaCategoryId: notificationsObject?.type,
                  filterLang: ['en'],
                  categoryFilters: [],
                }}
                onChange={handleChangeSrcId}
                selectType={selectTypes.string}
              />
            </Grid>
          )} */}
        </GridSectionWrapper>
        {/* <GridSectionWrapper heading="Custom Fields" shadow={false}>
          <KeyValuePairEditor
            disabled={disabled}
            id="customFields"
            keyValuePairInArrayFormat={notificationsObject?.customFields}
            setMainObject={setNotificationsObject}
          />
        </GridSectionWrapper> */}
      </NormalSectionWrapper>
      <NormalSectionWrapper heading={outerSections?.images}>
        <GridSectionWrapper shadow={false}>
          <NotificationImageUrlGridItem item xs={12} md={12}>
            <div className="w-100">
              <CFormInput
                id="imageUrl"
                label="Image Url"
                value={notificationsObject?.imageUrl}
                onChange={handleChangeObject}
                placeholder="Enter an image url..."
                disabled={disabled}
              />
            </div>
            <TableImageComponent
              customSrc={notificationsObject?.imageUrl}
              sx={{ height: '100px', width: '100px' }}
            />
          </NotificationImageUrlGridItem>
          <Grid item xs={12} sx={{ zIndex: 0 }}>
            <EntityBasedImageUploadComponent
              disabled={disabled}
              entity={entity}
              entityObject={notificationsObject}
              handleChange={handleChangeObject}
              noBorder
              noHeading
            />
          </Grid>
        </GridSectionWrapper>
      </NormalSectionWrapper>
    </div>
  )
}

// function handleChangeUsers(values) {
//   const tempEvent = { target: { id: 'userIds', value: values, type: 'react-async-select' } }
//   handleChangeObject(tempEvent)
// }

// function handleChangeSrcId(value) {
//   const tempEvent = { target: { id: 'sourceId', value: value, type: 'react-select' } }
//   handleChangeObject(tempEvent)
// }

// function handleChangeCustomFields(event, idx) {
//   setNotificationsObject((prev) => {
//     if (
//       idx === prev?.customFields.length - 1 &&
//       (prev?.customFields?.[idx]?.customField !== '' || prev?.customFields?.[idx]?.value !== '')
//     ) {
//       addCustomField()
//     }
//     if (
//       idx !== prev?.customFields.length - 1 &&
//       !checkIfStringIsValid(prev?.customFields?.[idx]?.customField) &&
//       !checkIfStringIsValid(prev?.customFields?.[idx]?.value)
//     ) {
//       removeCustomField(idx)
//     }

//     return {
//       ...prev,
//       customFields: prev?.customFields?.map((customField, index) => {
//         if (index === idx) {
//           return { ...customField, [event.target.id]: event.target.value }
//         }
//         return customField
//       }),
//     }
//   })
// }

// function addCustomField() {
//   console.log('add')
//   setNotificationsObject((prev) => ({
//     ...prev,
//     customFields: [...prev?.customFields, { customField: '', value: '' }],
//   }))
// }
// function removeCustomField(idx) {
//   console.log('rem')
//   setNotificationsObject((prev) => ({
//     ...prev,
//     customFields: prev?.customFields?.filter((_, index) => index !== idx),
//   }))
// }
