import React from 'react'

export default function NormalSectionWrapper({
  heading,
  subHeading,
  children,
  hideDivider = false,
}) {
  return (
    <div className="d-flex flex-column gap-0">
      <div className="w-100 ps-2">
        <h4>{heading}</h4>
      </div>
      {subHeading && (
        <div className="w-100 ps-2">
          <span className="fs-7">{subHeading}</span>
        </div>
      )}
      <div className="w-100">
        <div className="d-flex flex-column gap-3">{children}</div>
      </div>
      {!hideDivider && <hr className="mx-2"></hr>}
    </div>
  )
}
