import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { INVESTIGATION_OBJECT } from 'src/common/types/investigation/investigationObject'
import AutomaticListAddingComponent from 'src/components/controlCenter/common/AutomaticListAddingComponent'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { INVESTIGATION_API_MAP } from 'src/services/apiSignatureMaps/investigation/apiMap'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'

export default function MultiUploadInvestigations({ handleClose, handleRefresh }) {
  const [investigationTitles, setInvestigationTitles] = useState([])
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  async function saveMultipleInvestigations() {
    let titlesToSave = investigationTitles.filter((title) => title && title.trim() !== '')
    titlesToSave = [...new Set(titlesToSave)]
    const dataToSave = titlesToSave.map((title) => ({ ...INVESTIGATION_OBJECT, title }))
    const resp = await PostSettingsData(
      INVESTIGATION_API_MAP.saveMulti,
      dataToSave,
      {},
      undefined,
      undefined,
      undefined,
      true,
    )
    if (resp.status === 200) {
      if (isCommonResponseSuccessful(resp.data.code)) {
        handleRefresh()
        handleClose()
      }
    }
  }

  function saveInvestigationsConfirmation() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'save',
      body: 'Are you sure you want to save these investigations?',
      buttonColor: 'success',
      onSubmitFunctions: [saveMultipleInvestigations],
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
    }))
  }

  return (
    <>
      <DialogTitle>Investigations</DialogTitle>
      <DialogContent sx={{ paddingTop: '20px' }}>
        <AutomaticListAddingComponent
          id="invest"
          value={investigationTitles}
          onChange={(e) => {
            setInvestigationTitles(e.target.value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="success" onClick={saveInvestigationsConfirmation}>
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal newImplementation {...confirmationModalProps} />
    </>
  )
}
