import { toast } from 'react-toastify'
import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'
import { GetFeatureData } from 'src/services/APIs/Features/GetApi'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { SaveEntity } from 'src/utilities/apiGetters'
import { checkIfStringIsValid } from 'src/utilities/generalUtils'
import { ONLINE_COURSES_APIS } from 'src/views/courses/courses_utils'
import { FEATURES_API } from 'src/views/features/utils/api-utils'
import { categoryToDailyPlayType } from 'src/views/plugins/calendar/event-utils'
import { TrackResponse } from '../../../common/types/TrackClass'

export const PHASE_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'plan-phase',
  title: '',
  description: '',
  why: '',
  impact: '',
  firstDay: '',
  lastDay: '',
}

export const ACTIVITY_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'activity',
  descriptionSentence: '',
  descriptionZ: '',
  descriptionW: '',
  customDescription: '',
  metaCategoryId: '',
  customCategoryTitle: '',
  internalNote: '',
  activityId: '',
  activityTitle: '',
  whatItDoes: '',
  whyOffered: '',
  activityType: 'plan',
  track: TrackResponse,
}

export const PLANNED_ACTIVITY_OBJECT = {
  id: '',
  createdBy: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: null,
  apiParam: 'planned-activity',
  modifiedBy: '',
  plannedActivityId: '',
  otherActivityIds: [],
  planId: '',
  plannedActivity: null,
  otherActivities: [ACTIVITY_OBJECT],
}

export const ACTIVITY_PLAN_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: null,
  apiParam: 'activity-plan',
  title: '',
  description: '',
  totalDays: 1,
  phaseIds: [],
  phaseList: [],
  // phaseList: [{ ...PHASE_OBJECT, days: 1, firstDay: 1, lastDay: 1, title: 'Phase 1' }],
  plannedActivity: null,
  plannedActivityIds: [],
  plannedActivities: [PLANNED_ACTIVITY_OBJECT],
  status: 'draft',
  visibility: 'public',
}

export async function getCustomPlan() {
  return []
}

export async function saveCustomPlan() {}

export async function savePhases() {}

async function getAndSetActivityTitle(card, category = 'meditation') {
  let titleToReturn = card?.title
  let extraItem
  if (!card?.title && card?.itemId !== '') {
    switch (card.type) {
      case 'course':
        const courseResponse = await GetSettingsData(
          ONLINE_COURSES_APIS.onlineCourse.getMinimalSummaryList,
          { sessionIds: card.itemId?.split(' : ')?.[0] },
        )
        const sessionId = card.itemId
        const courseId = card?.customFields?.courseId
        const foundCourseSessionsArray = courseResponse?.data
        const foundCourse = foundCourseSessionsArray?.find((courseSess) => {
          if (checkIfStringIsValid(courseId)) {
            return courseSess?.sessionId === sessionId && courseSess?.courseId === courseId
          } else {
            return true
          }
        })
        const teacherResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
          entityType: 'mentor',
          ids: foundCourse?.createdFor,
        })
        console.log({ sessionId, courseId, foundCourseSessionsArray })
        titleToReturn = `${teacherResponse?.data?.content?.[0]?.title} - ${foundCourse?.title}`
        break
      case 'quote':
        const quoteResponse = await GetFeatureData(
          FEATURES_API.quotes.getSingle.url + card.itemId,
          {
            quoteId: card.itemId,
          },
        )
        if (quoteResponse.status === 200) {
          titleToReturn = `${quoteResponse.data.author} - ${quoteResponse.data.msg}`
          extraItem = quoteResponse.data
        }
        break
      case 'breathe':
        const breatheResponse = await GetFeatureData(FEATURES_API.breathe.getSingle.url, {
          id: card.itemId,
        })
        if (breatheResponse.status === 200) {
          const foundBreathe = breatheResponse.data
          titleToReturn = foundBreathe?.name
          extraItem = foundBreathe
        }
        break
      case 'journal':
        const journalResponse = await GetFeatureData(FEATURES_API.journals.getSingle.url, {
          id: card.itemId,
        })
        if (journalResponse.status === 200) {
          titleToReturn = `${journalResponse.data.title}`
          extraItem = journalResponse.data
        }
        break
      default:
        const dataResponse = await GetSettingsData(GENERIC_APIS.entity.details, {
          id: card.itemId,
          entityType: card.type,
        })
        if (dataResponse.status === 200) {
          const foundPractice = dataResponse.data
          titleToReturn =
            foundPractice?.title || foundPractice?.name || foundPractice?.internalTitle
          extraItem = foundPractice
        }
        break
    }
  }
  console.log({ extraItem })
  return { ...card, title: titleToReturn, extraItem: extraItem || {} }
}

function validateContent(content) {
  if (!content.contentItem) {
    return false
  }
  return true
}

export async function savePlanItems(rawContent) {
  const content = content.filter((cont) => !cont?._delete)
  const validated = content.reduce((acc, cont) => {
    if (!acc) {
      return acc
    }
    return validateContent(cont)
  }, true)
  if (!validated) {
    toast.error('One of the content items is in incorrect format!')
    return { validated: validated }
  }
}

export async function handleCustomPlanContentDropDown(
  selectedValue,
  tempCard,
  setTempCard,
  getReflection,
) {
  tempCard.type === 'quote' && getReflection(selectedValue.journalId)
  setTempCard((prev) => {
    const { value, label, sessionId, ...rest } = selectedValue
    return {
      ...prev,
      contentItem: selectedValue,
    }
  })
}

export async function getActivityObject(activityData, categoryId, store) {
  const convertedActivityIdObject = {
    itemId: activityData?.activityId,
    customFields: activityData?.customFields,
    type: categoryToDailyPlayType(categoryId, store),
  }
  const dpCardFoundObject = await getAndSetActivityTitle(convertedActivityIdObject)
  console.log({ dpCardFoundObject })
  return {
    value: activityData?.activityId,
    label: dpCardFoundObject.title || activityData?.activityId,
  }
}

export function fixStartDaysAndEndDays(phases) {
  let pStartDay = 1
  let toReturn = []
  for (let i = 0; i < phases?.length || 0; ++i) {
    toReturn.push({
      ...phases?.[i],
      firstDay: pStartDay,
      lastDay: pStartDay + (phases?.[i]?.days || 1) - 1,
    })
    pStartDay += phases?.[i]?.days || 1
  }
  return toReturn
}

export async function getAndSetActivitiesInPlannedActivities(plannedActivities, store) {
  const allActivities = plannedActivities.reduce((acc, pActivity) => {
    const currentActivities = pActivity?.otherActivities?.map((activity) => ({
      pActId: pActivity?.id,
      value: activity?.activityId,
      metaCategoryId: activity?.metaCategoryId,
    }))
    return [...acc, ...currentActivities]
  }, [])
  const setOfUniqueCategories = new Set(allActivities?.map((activity) => activity?.metaCategoryId))

  console.log({ setOfUniqueCategories })

  const allActivitiesWithTheirTitles = await Promise.all(
    allActivities?.map(async (activity) => {
      const response = await getActivityObject(activity?.value, activity?.metaCategoryId, store)
      return { ...activity, ...response }
    }),
  )
  console.log({ allActivitiesWithTheirTitles })
}

export function fixDaysAndContent(plan, allPhases, plannedActivities) {
  const totalDays = allPhases[allPhases.length - 1].lastDay

  for (let phaseIndex = 0; phaseIndex < allPhases?.length || 0; phaseIndex++) {
    const currentPhase = allPhases[phaseIndex]
    const currentPlannedActivities = plannedActivities.filter(
      (activity, index) =>
        index + 1 >= (currentPhase?.firstDay || 0) && index + 1 <= (currentPhase?.lastDay || 0),
    )

    if (currentPlannedActivities?.length < currentPhase?.days) {
      for (
        let dayIndex = currentPhase?.firstDay || 0;
        dayIndex <= currentPhase?.lastDay || 0;
        dayIndex++
      ) {
        if (!currentPlannedActivities.find((activity, index) => index + 1 === dayIndex)) {
          plannedActivities.push({
            ...PLANNED_ACTIVITY_OBJECT,
            planId: plan.id || '',
          })
        }
      }
    } else {
      plannedActivities = plannedActivities.map((activity, index) => {
        const activityDay = index + 1
        return activityDay >= currentPhase.firstDay && activityDay <= currentPhase.lastDay
          ? { ...activity, _delete: false }
          : { ...activity, _delete: true }
      })
    }
  }

  return plannedActivities
}

export function filterContentBasedOnPhsCondition(contentDay, phase) {
  return contentDay < phase?.lastDay && contentDay >= phase?.firstDay
}

export function formatBeforeSavingActivityPlan(activityPlan) {}

export function validateActivityPlan(activityPlan) {
  let validationPoints = {
    title: { valid: true, message: '' },
    phases: { valid: true, message: '' },
    plannedActivities: Array.from(
      { length: activityPlan?.plannedActivities?.length - activityPlan?.totalDays },
      (_, index) => ({ valid: true, message: '' }),
    ),
  }

  ///VALIDATION PHASES
  if (!checkIfStringIsValid(activityPlan?.title)) {
    validationPoints.title.valid = false
    validationPoints.title.message = 'Activity Plan Title cannot be empty.'
  }
  const phaseValidity = checkPhasesValidity(activityPlan)
  validationPoints = { ...validationPoints, phases: phaseValidity }
  validationPoints = {
    ...validationPoints,
    plannedActivities: activityPlan?.plannedActivities
      ?.filter((pAct, idx) => idx < activityPlan?.totalDays)
      .map((plannedActivity, idx) => {
        const allValid = plannedActivity.otherActivities.some((activity) => {
          return (
            checkIfStringIsValid(activity.metaCategoryId) &&
            checkIfStringIsValid(activity.customCategoryTitle) &&
            checkIfStringIsValid(activity.activityTitle) &&
            checkIfStringIsValid(activity.descriptionW) &&
            checkIfStringIsValid(activity.descriptionZ)
          )
        })
        if (!allValid) {
          return {
            valid: false,
            message: `Day ${idx + 1}`,
          }
        } else {
          return {
            valid: true,
            message: '',
          }
        }
      }),
  }
  return validationPoints
  /////////
}

export async function formSavableActivityPlan(activityPlan) {
  let activityPlanToUse = activityPlan
  activityPlanToUse = {
    ...activityPlan,
    phaseList: activityPlan.phaseList.map((phase) => {
      const { days, ...restPhase } = phase
      return restPhase
    }),
  }
  const plannedActivitiesToUse = activityPlanToUse.plannedActivities?.filter((pAct, idx) => {
    return idx < activityPlan?.totalDays
  })
  let plannedActivitiesWithSavedActivities = await Promise.all(
    plannedActivitiesToUse.map(async (plannedActivity) => {
      const formedPlannedActivity = await getPlannedActivityWithSavedActivities(plannedActivity)
      return formedPlannedActivity
    }),
  )
  plannedActivitiesWithSavedActivities = await Promise.all(
    plannedActivitiesWithSavedActivities.map(async (plannedActivity) => {
      const savedPlannedActivityResponse = await SaveEntity(
        'planned-activity',
        {
          ...plannedActivity,
          planId: activityPlanToUse.id,
        },
        undefined,
        undefined,
        undefined,
        false,
      )
      if (savedPlannedActivityResponse.status === 200) {
        return savedPlannedActivityResponse.data
      }
    }),
  )

  let savedPhases = await Promise.all(
    activityPlanToUse.phaseList.map(async (phase) => {
      const { _newPhase, ...phaseToUse } = phase

      const savedPhaseResponse = await SaveEntity(
        'plan-phase',
        phaseToUse,
        undefined,
        undefined,
        undefined,
        false,
      )
      if (savedPhaseResponse.status === 200) {
        return savedPhaseResponse.data
      }
    }),
  )

  const countMap = plannedActivitiesWithSavedActivities?.reduce((acc, pAct) => {
    // const allActivities = [...pAct.otherActivities, pAct.plannedActivity]
    const allActivities = [pAct.plannedActivity]
    allActivities.forEach((activity) => {
      acc[activity.metaCategoryId] = acc[activity.metaCategoryId]
        ? acc[activity.metaCategoryId] + 1
        : 1
    })
    return acc
  }, {})

  return {
    ...activityPlanToUse,
    phaseList: savedPhases,
    phaseIds: savedPhases.map((phase) => phase.id),
    plannedActivities: plannedActivitiesWithSavedActivities,
    countMap,
    plannedActivityIds: plannedActivitiesWithSavedActivities.map(
      (plannedActivity) => plannedActivity.id,
    ),
  }
}

async function getPlannedActivityWithSavedActivities(plannedActivity) {
  const savedPlannedActivities = await Promise.all(
    plannedActivity.otherActivities.map(async (otherActivity) => {
      const activityToUse = {
        ...otherActivity,
        activityId:
          typeof otherActivity.activityId === 'string'
            ? { value: otherActivity.activityId }
            : otherActivity.activityId,
      }
      const { _newActivity, ...cleanedActivity } = activityToUse
      if (
        checkIfStringIsValid(cleanedActivity.metaCategoryId) &&
        checkIfStringIsValid(cleanedActivity.customCategoryTitle) &&
        checkIfStringIsValid(cleanedActivity.activityTitle) &&
        checkIfStringIsValid(cleanedActivity.descriptionW) &&
        checkIfStringIsValid(cleanedActivity.descriptionZ)
      ) {
        const savedActivityResponse = await SaveEntity(
          'activity',
          cleanedActivity,
          undefined,
          undefined,
          undefined,
          false,
        )
        if (savedActivityResponse.status === 200) {
          return savedActivityResponse.data
        }
      } else {
        return { ...cleanedActivity, activityId: cleanedActivity.activityId?.value }
      }
    }),
  )
  const mainActivity = savedPlannedActivities?.[0]
  const actualOtherActivities = savedPlannedActivities?.slice(1) || []
  return {
    ...plannedActivity,
    plannedActivity: mainActivity,
    otherActivities: actualOtherActivities,
    otherActivityIds: actualOtherActivities.map((activity) => activity.id),
    plannedActivityId: mainActivity.id,
  }
}

export function checkPhasesValidity(activityPlan) {
  if (
    activityPlan?.phaseList?.[activityPlan?.phaseList?.length - 1]?.lastDay >
    activityPlan?.totalDays
  ) {
    return {
      valid: false,
      message: 'The last day of the last phase is exceeding the total number of days of the plan.',
    }
  } else {
    if (
      activityPlan?.phaseList?.[activityPlan?.phaseList?.length - 1]?.lastDay <
      activityPlan?.totalDays
    ) {
      return {
        valid: false,
        message:
          'The the days in the phases are not adding up to the total number of days of the plan.',
      }
    }
    return {
      valid: true,
      message: '',
    }
  }
}
