import { Delete } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React from 'react'

export default function InAppMsgTimeSlotsComponent({
  preferredTime,
  changePreferredTime,
  deletePreferredTime,
  disabled,
}) {
  console.log({ startTime: moment(preferredTime?.startTimePlain) })
  return (
    <>
      <Grid item xs={12} md={6}>
        <TimePicker
          label="Start Time"
          value={moment(`1970-01-01 ${preferredTime?.startTimePlain}`)}
          slotProps={{ textField: { variant: 'filled' } }}
          onChange={(date) => {
            changePreferredTime({
              target: { id: 'startTimePlain', value: date.format('HH:mm:ss') },
            })
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TimePicker
          label="End Time"
          value={moment(`1970-01-01 ${preferredTime?.endTimePlain}`)}
          slotProps={{ textField: { variant: 'filled' } }}
          onChange={(date) =>
            changePreferredTime({ target: { id: 'endTimePlain', value: date.format('HH:mm:ss') } })
          }
          disabled={disabled}
        />
      </Grid>
      {deletePreferredTime && !disabled && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="error" startIcon={<Delete />} onClick={deletePreferredTime}>
            Delete
          </Button>
        </Grid>
      )}
    </>
  )
}
