import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { defaultLinkMakerContextData } from 'src/components/controlCenter/linksMaker/LinksMakerComponents'
import { IdTypes } from 'src/utilities/constants'
import DLSlugItemBookedEventNotifications from './components/DLSlugItemBookedEventNotifications'
import DLSlugItemCourse from './components/DLSlugItemCourse'
import DLSlugItemDefault from './components/DLSlugItemDefault'
import DLSlugItemMentor from './components/DLSlugItemMentor'
import DLSlugItemTiles from './components/DLSlugItemTiles'

export default function DLIdOrSlug({
  categoryType,
  item,
  handleChangeItem,
  idType,
  dropDownOptions,
  extraData,
  handleChangeExtraData,
  actionData,
  handleActionData,
  uiContextData,
  disabled,
}) {
  const ComponentToRender = useMemo(() => {
    switch (categoryType) {
      case 'course':
        return DLSlugItemCourse
      case 'tiles':
        return DLSlugItemTiles
      case 'service-provider':
        return DLSlugItemMentor
      case 'booked_event_notification':
        return DLSlugItemBookedEventNotifications
      default:
        return DLSlugItemDefault
    }
  }, [categoryType])

  function changeItem(selVal) {
    let idToUse = null

    if (idType === IdTypes.id) {
      idToUse = selVal?.value
    }

    if (idType === IdTypes.slug) {
      idToUse = selVal?.slug
    }

    if (idType === IdTypes.slug) {
      if (!idToUse) {
        toast.warn('This item may not have a slug')
        return
      }
    }

    if (idToUse) handleChangeItem({ ...selVal, value: idToUse })
  }

  function changeExtraItem(selVal) {
    let itemIdToUse = null

    if (idType === IdTypes.id) {
      itemIdToUse = selVal?.value
    }

    if (idType === IdTypes.slug) {
      itemIdToUse = selVal?.slug
    }

    if (idType === IdTypes.slug) {
      if (!itemIdToUse) {
        toast.warn('This item may not have a slug')
        return
      }
    }

    console.log({ itemIdToUse, selVal })

    if (itemIdToUse)
      handleChangeExtraData((prev) => ({ ...prev, itemId: itemIdToUse, itemTitle: selVal?.label }))
  }

  function toggleExtraData() {
    handleChangeExtraData((prev) => (!!prev ? null : { ...defaultLinkMakerContextData.extraData }))
  }

  return (
    <ComponentToRender
      {...{
        categoryType,
        item,
        handleChangeItem: changeItem,
        idType,
        dropDownOptions,

        extraData,
        handleChangeExtraData,
        changeExtraItem,
        extraItem: { value: extraData?.itemId, label: extraData?.itemTitle },
        toggleExtraData,

        actionData,
        handleActionData,

        uiContextData,
        disabled,
      }}
    />
  )
}
