
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useState } from 'react';

export default function CCOverlay({active, children}) {

    return (
        <>
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={active}
      >
        <CircularProgress color="inherit" />
                </Backdrop>
                {children}
            </div>
        </>
  );
}