import { CFormInput } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  CCTextArea,
  CoreMuiDateTimePicker,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS, currency_list } from 'src/utilities/constants'
import { genericHandleChange } from 'src/utilities/generalUtils'

export default function GeneratePaymentLinkForm({
  initialPaymentLinkData,
  refreshAllPaymentLinks,
  closePaymentLinkModal,
  dropDownData,
}) {
  const { id: userId } = useParams()

  const [paymentLinkData, setPaymentLinkData] = useState()

  const formIsDisabled = !!paymentLinkData?.id
  const [saving, setSaving] = useState(false)

  const saveButtonDisabled = useMemo(() => {
    if (
      paymentLinkData?.currency &&
      paymentLinkData?.amount &&
      paymentLinkData?.paymentLinkExpiredTime
    ) {
      return false /// button is not disabled
    }
    return true
  }, [paymentLinkData])

  function handleChange(eve) {
    genericHandleChange(eve, setPaymentLinkData)
  }

  async function savePaymentLink() {
    setSaving(true)

    const resp = await PostSettingsData(USERS_API_SIGNATURES_MAP.paymentLinks.linksDetails.save, {
      ...paymentLinkData,
      userId,
    })
    if (resp?.status === 200) {
      // closePaymentLinkModal()
      refreshAllPaymentLinks()
      setPaymentLinkData(resp.data)
    } else {
    }
    setSaving(false)
  }

  async function sendMail() {
    setSaving(true)
    const resp = await GetSettingsData(USERS_API_SIGNATURES_MAP.paymentLinks.linksDetails.notify, {
      linkId: paymentLinkData.id,
    })
    if (resp?.status === 200) {
      toast.success('Mail sent successfully')
    }
    setSaving(false)
  }

  function cancelClick() {
    closePaymentLinkModal()
  }

  useEffect(() => {
    if (initialPaymentLinkData) {
      setPaymentLinkData(initialPaymentLinkData)
    } else {
      setPaymentLinkData({
        paymentLinkExpiredTime: moment().format(MOMENT_FORMATS.formatThatServerSends),
      })
    }
  }, [initialPaymentLinkData])

  useEffect(() => {
    console.log({ paymentLinkData })
  }, [paymentLinkData])

  return (
    <>
      <DialogTitle>
        {paymentLinkData?.id ? 'Send Payment Link Mail' : 'Generate Payment Link'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomReactSelect
              id="subscriptionId"
              label="Subscription Plan"
              value={paymentLinkData?.subscriptionId}
              options={dropDownData?.subscriptionPlans?.filter((plan) => plan?.enabled)}
              getOptionValue={(optn) => optn?.id}
              getOptionLabel={(optn) => optn?.title}
              onChangeEvent={handleChange}
              selectType={selectTypes.string}
              disabled={formIsDisabled}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomReactSelect
              id="currency"
              label="Currency"
              value={paymentLinkData?.currency}
              options={currency_list}
              getOptionValue={(optn) => optn?.code}
              getOptionLabel={(optn) => `${optn?.name} - ${optn?.symbol}`}
              onChangeEvent={handleChange}
              selectType={selectTypes.string}
              disabled={formIsDisabled}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CFormInput
              id="amount"
              label="Amount"
              value={paymentLinkData?.amount}
              onChange={handleChange}
              type="number"
              disabled={formIsDisabled}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CCTextArea
              id="desc"
              label="Description"
              value={paymentLinkData?.desc}
              onChange={handleChange}
              colNumber={12}
              disabled={formIsDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <CoreMuiDateTimePicker
              inputId={'paymentLinkExpiredTime'}
              label="Payment Link Expiry Time"
              minDate={moment()}
              value={moment(paymentLinkData?.paymentLinkExpiredTime)}
              onChange={(date) =>
                handleChange({
                  target: {
                    id: 'paymentLinkExpiredTime',
                    value: date.format(MOMENT_FORMATS.formatThatServerSends),
                    // value: date.utc().format('YYYY-MM-DD HH:mm:ss'),
                  },
                })
              }
              defaultValue={moment()}
              disabled={formIsDisabled}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelClick} color="secondary">
          Cancel
        </Button>
        {!formIsDisabled ? (
          <Button
            onClick={savePaymentLink}
            disabled={saveButtonDisabled || saving}
            color="primary"
            variant="contained"
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        ) : (
          <>
            <Button
              onClick={sendMail}
              disabled={!paymentLinkData?.id || saving}
              color="primary"
              variant="contained"
            >
              {saving ? (
                'Sending...'
              ) : (
                <>
                  {!paymentLinkData?.id ? 'There is an issue with this payment link' : 'Send Mail'}
                </>
              )}
            </Button>
          </>
        )}
      </DialogActions>
    </>
  )
}
