import { OpenInNew } from '@mui/icons-material'
import { IconButton, MenuItem } from '@mui/material'
import React from 'react'
import { openInNewTab } from 'src/utilities/generalUtils'

export default function OpenTabInNewTab({ tabId }) {
  function openTabInNewTab(tabId) {
    openInNewTab()
  }

  return (
    <>
      <IconButton
        href={`${window.location.origin}/setup/tiles/settings/${tabId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <OpenInNew />
      </IconButton>
    </>
  )
}
export function OpenTabInNewMenuItem({ tabId, handleClose }) {
  return (
    <>
      <a
        href={`${window.location.origin}/setup/tiles/tabs/${tabId}?mode=View`}
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClose}
      >
        <MenuItem disabledRipple>
          <OpenInNew />
          Open tab item in a new tab
        </MenuItem>
      </a>
    </>
  )
}
