import moment from 'moment'
import React from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import TrueFalseHandler from 'src/components/controlCenter/genericListing/TrueFalseHandler'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import PaginatedListingTableHandlerWrapper from '../../PaginatedListingTableHandlerWrapper'

export default function CourseSessions({ userId, data, setData }) {
  return (
    <PaginatedListingTableHandlerWrapper
      apiCallFunction={GetSettingsData}
      apiCallParams={{ userId: userId, size: 200 }}
      apiCallUrl={USERS_API_SIGNATURES_MAP.usageDetails.onlineCourseSessions.get}
      data={data}
      setData={setData}
      setupDataDependencies={[userId]}
    >
      <CCExtendedCSmartTable
        noUserNames
        items={data?.data || []}
        loading={data?.status === 'loading'}
        columns={columns}
        scopedColumns={scopedColumns}
        excludeGenericFields={[
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ]}
      />
    </PaginatedListingTableHandlerWrapper>
  )
}

const columns = [
  { key: 'courseTitle', label: 'Course Title', sorter: false, filter: false },
  { key: 'sessionTitle', label: 'Session Title', sorter: false, filter: false },
  { key: 'category', label: 'Category', sorter: false, filter: false },
  { key: 'teacher', label: 'Teacher', sorter: false, filter: false },
  { key: 'sessionDuration', label: 'Actual Duration (min)', sorter: false, filter: false },
  { key: 'targetDuration', label: 'Target Duration (min)', sorter: false, filter: false },
  { key: 'isCompleted', label: 'Completed', sorter: false, filter: false },
  { key: 'pointsEarned', label: 'Point Earned', sorter: false, filter: false },
  { key: 'userSubscriptions', label: 'User Type', sorter: false, filter: false },
  { key: 'subscriptionType', label: 'Course Type', sorter: false, filter: false },
  { key: 'status', label: 'Status', sorter: false, filter: false },
  { key: 'createdTime', label: 'Created Time', sorter: false, filter: false },
]

const scopedColumns = {
  category: (item) => {
    if (item?.category) {
      var source = item?.category?.join(', ') || ''
      return <td>{source}</td>
    }
    return <td>--</td>
  },
  teacher: (item) => {
    return <td>{item?.teacher?.name || '--'}</td>
  },
  sessionDuration: (item) => {
    return <td>{item?.sessionDuration ? Math.round(item?.sessionDuration / 60000) : '--'}</td>
  },
  targetDuration: (item) => {
    var dataToReturn =
      item?.targetDuration === 0
        ? Math?.round(item?.sessionDuration / 60000)
        : Math?.round(item?.targetDuration / 60000)
    return <td>{dataToReturn || ''}</td>
  },
  pointsEarned: (item) => {
    if (item?.pointsEarned !== undefined) {
      if (item?.pointsEarned?.total !== undefined) {
        return <td>{item?.pointsEarned?.total || '--'}</td>
      }
    }
    return <td>--</td>
  },
  userSubscriptions: (item) => {
    if (item?.userSubscriptionType) {
      if (item?.userSubscriptionType?.id === 'subscription_free') {
        return <td>Free</td>
      }
      return <td>{item?.userSubscriptionType?.name || ''}</td>
    }
    return <td>Free</td>
  },
  subscriptionType: (item) => {
    if (item?.subscriptionType) {
      if (item?.subscriptionType?.id === 'subscription_free') {
        return <td>Free</td>
      }
      return <td>{item?.subscriptionType?.name || ''}</td>
    }
    return <td>Free</td>
  },
  isCompleted: (item) => {
    return (
      <td>
        <TrueFalseHandler value={item?.isCompleted} />
      </td>
    )
  },
  status: (item) => {
    const toRet = item?.status === 'captured' ? 'Captured' : 'Not Captured'
    return <td>{toRet}</td>
  },
  createdTime: (item) => {
    return (
      <td>
        {moment(item?.createdTime || '').format(
          MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
        )}
      </td>
    )
  },
}
