import { CFormInput } from '@coreui/react-pro'
import { Add, ExpandLess } from '@mui/icons-material'
import { Button, FormControlLabel, Grid, Switch } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { META_AGE_GROUPS } from 'src/common/types/ageGroups/metaAgeGroups'
import { IN_APP_MSG_PLATFORM_OBJECT } from 'src/common/types/inAppMsgs/inAppMsgPlatformObject'
import {
  CCFormInput,
  CCTextArea,
  CoreMuiDateTimePicker,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import KeyValuePairEditor from 'src/components/controlCenter/common/KeyValuePairEditor'
import { AccordionSectionWrapper } from 'src/components/controlCenter/common/sectionComponents/AccordionSectionWrapper'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import ImageUpload from 'src/components/controlCenter/imageUpload/ImageUpload'
import LinksMakerComponents from 'src/components/controlCenter/linksMaker/LinksMakerComponents'
import TwoWaySwitch from 'src/components/muiCustomized/TwoWaySwitch'
import { getMediaImageUrl } from 'src/services'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import {
  ALL_GENDERS_IN_APP_MSGS,
  ALL_USER_STATES,
  SUBSCRIPTION_TYPES_LAYMAN,
} from 'src/utilities/constants'
import {
  giveGenericHandleChangedData,
  isValidNumber,
  loadOptionsForAsyncPaginate,
} from 'src/utilities/generalUtils'
import { useScreenSize } from 'src/utilities/hooks/useScreenSize'
import { NotificationImageUrlGridItem } from 'src/views/marketing/notifications/components/styledComponents'
import {
  CustomAsyncPaginate,
  CustomSelect,
} from 'src/views/plugins/calendar/components/CustomSelect'
import { TableImageComponent } from 'src/views/settings/components/TableImage'
import AgeGroupsComponent from './components/AgeGroupsComponent'
import AppPlatformsComponent from './components/AppPlatformsComponent'
import CriteriaTopicsComponent from './components/CriteriaTopicsComponent'
import InAppMessagePreviewComponent from './components/InAppMessagePreviewComponent'
import InAppMsgTimeSlotsComponent from './components/InAppMsgTimeSlotsComponent'
import {
  InAppMsgSwipeablePreview,
  InAppMsgSwipeablePreviewOpenButton,
  StyledColorPickerContainer,
} from './components/styledComponents'
import { IN_APP_MSG_PRIORITY, LEGACY_TABS_FOR_IN_APP_MSG, TEXT_USERS_FOR_IN_APP_MSG } from './utils'

const sections = {
  additionalOptions: 'additionalOptions',
  campaignDetails: 'campaignDetails',
  scheduling: 'scheduling',
  styleAndContent: 'styleAndContent',
  target: 'target',
}

export default function InAppMessagesComponent({
  initialObject,
  imageObject: inAppMessageObject,
  setImageObject: setInAppImageObject,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
}) {
  const [expandedSection, setExpandedSection] = useState(sections.campaignDetails)
  const [showPreview, setShowPreview] = useState(false)
  const drawerBleeding = 40
  const { width } = useScreenSize()

  const toggleDrawer = (newOpen) => () => {
    newOpen ? setShowPreview(newOpen) : setShowPreview((prev) => !prev)
  }

  function handleChangeCriteria(event) {
    handleNestedChangeObject(event, 'criteria')
  }

  function handleChangeTemplateData(event) {
    handleNestedChangeObject(event, 'templateData')
  }

  function handleChangeTemplateButtonClickData(btnTxtAndUri, btnTxtKey, btnUriKey) {
    const { btnTxt, uri } = btnTxtAndUri
    handleChangeTemplateData({ target: { value: btnTxt, id: btnTxtKey } })
    handleChangeTemplateData({ target: { value: uri, id: btnUriKey } })
  }

  function handleChangeTemplateImage(image) {
    handleChangeTemplateData({ target: { value: image, id: 'imageId' } })
    handleChangeTemplateData({ target: { value: `${getMediaImageUrl()}${image}`, id: 'imageUrl' } })
  }

  function handleChangePlatform(eve, idx) {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        appPlatforms: prev?.appPlatforms?.map((platform, index) =>
          index === idx ? giveGenericHandleChangedData(eve, platform, true) : platform,
        ),
      }
    })
  }

  function deletePlatform(idx) {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        appPlatforms: prev?.appPlatforms?.filter((platform, index) => index !== idx),
      }
    })
  }

  function addPlatform() {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        appPlatforms: [
          ...(prev?.appPlatforms || []),
          {
            ...IN_APP_MSG_PLATFORM_OBJECT,
            ...{
              appPackageName: 'com.aumhum.aumhum',
              appVersionCode: 0,
              appVersion: '1.1.1',
              versionCriteria: 'eq',
              platform: 'ios',
            },
          },
        ],
      }
    })
  }

  function handleChangeAgeGroups(eve, idx) {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        ageGroups: prev?.ageGroups?.map((platform, index) =>
          index === idx ? giveGenericHandleChangedData(eve, platform, true) : platform,
        ),
      }
    })
  }

  function deleteAgeGroup(idx) {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        ageGroups: prev?.ageGroups?.filter((platform, index) => index !== idx),
      }
    })
  }

  function addAgeGroup() {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        ageGroups: [...(prev?.ageGroups || []), META_AGE_GROUPS],
      }
    })
  }

  function changePreferredTime(eve, idx) {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        preferredTimes: prev?.preferredTimes?.map((time, index) =>
          index === idx ? giveGenericHandleChangedData(eve, time) : time,
        ),
      }
    })
  }

  function deletePreferredTime(idx) {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        preferredTimes: prev?.preferredTimes?.filter((time, index) => index !== idx),
      }
    })
  }

  function addPreferredTime() {
    handleChangeCriteria((prev) => {
      return {
        ...prev,
        preferredTimes: [
          ...(prev?.preferredTimes || []),
          { startTimePlain: '00:00:00', endTimePlain: '12:00:00', enabled: true },
        ],
      }
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <AccordionSectionWrapper
          heading="1. Campaign Details"
          currentSection={sections.campaignDetails}
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
        >
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CFormInput
                id="title"
                value={inAppMessageObject.title}
                label="Campaign name"
                onChange={handleChangeObject}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <CCTextArea
                id="description"
                value={inAppMessageObject.description}
                label="Campaign Description"
                onChange={handleChangeObject}
                disabled={disabled}
              />
            </Grid>
          </GridSectionWrapper>
        </AccordionSectionWrapper>
        <AccordionSectionWrapper
          heading="2. Style and content"
          currentSection={sections.styleAndContent}
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
        >
          <GridSectionWrapper name="title and subtext">
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <label>Headline</label>
              <div className="d-flex w-100 gap-2">
                <CFormInput
                  id="textColor"
                  value={inAppMessageObject?.templateData?.textColor}
                  type="color"
                  onChange={handleChangeTemplateData}
                  disabled={disabled}
                />
                <div className="w-100">
                  <CFormInput
                    id="title"
                    value={inAppMessageObject?.templateData?.title}
                    onChange={handleChangeTemplateData}
                    placeholder="Enter a headline..."
                    disabled={disabled}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <label>Sub Heading</label>
              <div className="d-flex w-100 gap-2">
                <CFormInput
                  id="subTextColor"
                  value={inAppMessageObject?.templateData?.subTextColor}
                  type="color"
                  onChange={handleChangeTemplateData}
                  disabled={disabled}
                />
                <CFormInput
                  id="subText"
                  value={inAppMessageObject?.subtext}
                  onChange={handleChangeTemplateData}
                  placeholder="Enter a sub heading..."
                  disabled={disabled}
                />
              </div>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper name="textbox">
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    size={'small'}
                    id="textBox"
                    checked={!!inAppMessageObject?.templateData?.textBox || false}
                    disabled={disabled}
                    onChange={handleChangeTemplateData}
                  />
                }
                label="Long Message"
              />
            </Grid>
            {inAppMessageObject?.templateData?.textBox && (
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <CCTextArea
                  id="textBoxText"
                  hideLabel
                  value={inAppMessageObject?.templateData?.textBoxText}
                  onChange={handleChangeTemplateData}
                  placeholder="Enter a long message..."
                  disabled={disabled}
                />
                <div className="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                  <StyledColorPickerContainer>
                    <CFormInput
                      id="msgBoxBgColor"
                      value={inAppMessageObject?.templateData?.msgBoxBgColor}
                      type="color"
                      label="Long Message Background Color: "
                      onChange={handleChangeTemplateData}
                      disabled={disabled}
                    />
                  </StyledColorPickerContainer>
                  <StyledColorPickerContainer>
                    <CFormInput
                      id="msgBoxTxtColor"
                      value={inAppMessageObject?.templateData?.msgBoxTxtColor}
                      type="color"
                      label="Long Message Text Color: "
                      onChange={handleChangeTemplateData}
                      disabled={disabled}
                    />
                  </StyledColorPickerContainer>
                </div>
              </Grid>
            )}
          </GridSectionWrapper>
          <GridSectionWrapper name="Action Button">
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    size={'small'}
                    id="actionBtn"
                    checked={!!inAppMessageObject?.templateData?.actionBtn || false}
                    disabled={disabled}
                    onChange={handleChangeTemplateData}
                  />
                }
                label="Action Button"
              />
            </Grid>
            {inAppMessageObject?.templateData?.actionBtn && (
              <Grid item xs={12}>
                <LinksMakerComponents
                  btnTxt={inAppMessageObject?.templateData?.actionBtnText}
                  uri={inAppMessageObject?.templateData?.actionBtnUri}
                  handleChange={(btnTxtAndUri) =>
                    handleChangeTemplateButtonClickData(
                      btnTxtAndUri,
                      'actionBtnText',
                      'actionBtnUri',
                    )
                  }
                  disabled={disabled}
                />
              </Grid>
            )}
          </GridSectionWrapper>
          <GridSectionWrapper name="Extra Button">
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    size={'small'}
                    id="extraLink"
                    checked={!!inAppMessageObject?.templateData?.extraLink || false}
                    disabled={disabled}
                    onChange={handleChangeTemplateData}
                  />
                }
                label="Extra Link"
              />
            </Grid>
            {inAppMessageObject?.templateData?.extraLink && (
              <Grid item xs={12}>
                <LinksMakerComponents
                  btnTxt={inAppMessageObject?.templateData?.extraLinkText}
                  uri={inAppMessageObject?.templateData?.extraLinkUri}
                  handleChange={(btnTxtAndUri) =>
                    handleChangeTemplateButtonClickData(
                      btnTxtAndUri,
                      'extraLinkText',
                      'extraLinkUri',
                    )
                  }
                  disabled={disabled}
                />
              </Grid>
            )}
          </GridSectionWrapper>
          <GridSectionWrapper heading="More Styles...">
            <Grid item xs={12} md={6}>
              <div className="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                <StyledColorPickerContainer>
                  <CFormInput
                    id="bgColor"
                    value={inAppMessageObject?.templateData?.bgColor}
                    type="color"
                    label="Card Background Color :"
                    onChange={handleChangeTemplateData}
                    disabled={disabled}
                  />
                </StyledColorPickerContainer>
                <StyledColorPickerContainer>
                  <CFormInput
                    id="crossBtnColor"
                    value={inAppMessageObject?.templateData?.crossBtnColor}
                    type="color"
                    label="Cross/Close Button Color :"
                    onChange={handleChangeTemplateData}
                    disabled={disabled}
                  />
                </StyledColorPickerContainer>
              </div>
            </Grid>
            <NotificationImageUrlGridItem item xs={12} md={12}>
              <div className="w-100">
                <CFormInput
                  id="imageUrl"
                  label="Image Url"
                  value={inAppMessageObject?.templateData?.imageUrl}
                  onChange={handleChangeTemplateData}
                  placeholder="Enter an image url..."
                  disabled={disabled}
                />
              </div>
            </NotificationImageUrlGridItem>
            {/* <Grid item xs={12}>
            <EntityBasedImageUploadComponent
              disabled={disabled}
              entity={entity}
              entityObject={inAppMessageObject?.templateData}
              handleChange={handleChangeTemplateData}
              noBorder
              noHeading
            />
          </Grid> */}
            <Grid item xs={12} md={6}>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <TableImageComponent customSrc={inAppMessageObject?.templateData?.imageUrl} />
                <span>Image URl Image</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <ImageUpload
                  imageId={inAppMessageObject?.templateData?.imageId}
                  onChange={handleChangeTemplateImage}
                  title="Template Hero Image"
                  imageType="coverIcon"
                  disabled={disabled}
                />
              </div>
            </Grid>
          </GridSectionWrapper>
        </AccordionSectionWrapper>
        <AccordionSectionWrapper
          heading="3. Target/Filters"
          currentSection={sections.target}
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
        >
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="screens"
                label="Screens *"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={inAppMessageObject?.criteria?.screens?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="screens"
                  value={inAppMessageObject?.criteria?.screens}
                  options={[...LEGACY_TABS_FOR_IN_APP_MSG]?.sort((a, b) =>
                    a.title.localeCompare(b.title),
                  )}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  disabled={disabled}
                  selectType={selectTypes.string}
                  onChangeEvent={handleChangeCriteria}
                  required
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="genders"
                label="Genders"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={inAppMessageObject?.criteria?.genders?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="genders"
                  value={inAppMessageObject?.criteria?.genders}
                  options={ALL_GENDERS_IN_APP_MSGS}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  disabled={disabled}
                  selectType={selectTypes.string}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="ageGroups"
                label="Age Groups"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={inAppMessageObject?.criteria?.ageGroups?.length > 0}
              >
                <Grid container spacing={1}>
                  {inAppMessageObject?.criteria?.ageGroups?.map((ageGroup, idx) => (
                    <AgeGroupsComponent
                      key={idx}
                      ageGroup={ageGroup}
                      changeAgeGroup={(eve) => handleChangeAgeGroups(eve, idx)}
                      deleteAgeGroup={() => deleteAgeGroup(idx)}
                      disabled={disabled}
                    />
                  ))}
                  {!disabled && (
                    <Grid item xs={12}>
                      <Button
                        sx={{ width: '100%' }}
                        color="primary"
                        startIcon={<Add />}
                        onClick={addAgeGroup}
                      >
                        Add Age Group
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="userState"
                label="User States"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={!!inAppMessageObject?.criteria?.userState}
              >
                <CustomSelect
                  id="userState"
                  value={
                    inAppMessageObject?.criteria?.userState
                      ? {
                          id: inAppMessageObject?.criteria?.userState,
                          title: ALL_USER_STATES?.find(
                            (state) => state?.id === inAppMessageObject?.criteria?.userState,
                          )?.title,
                        }
                      : null
                  }
                  options={ALL_USER_STATES?.sort((a, b) => a.title.localeCompare(b.title))}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  isDisabled={disabled}
                  selectType={selectTypes.string}
                  onChange={(selectValue) =>
                    handleChangeCriteria({
                      target: { id: 'userState', value: selectValue?.id || null },
                    })
                  }
                  isClearable
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="subscriptions"
                label="Subscription Levels"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={inAppMessageObject?.criteria?.subscriptions?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="subscriptions"
                  value={inAppMessageObject?.criteria?.subscriptions}
                  options={customOptions?.allPlanLevels}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.name}
                  disabled={disabled}
                  selectType={selectTypes.object}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="preferredTimes"
                label="Preferred Times"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={inAppMessageObject?.criteria?.preferredTimes?.length > 0}
              >
                <Grid container spacing={1}>
                  {inAppMessageObject?.criteria?.preferredTimes?.map((preferredTime, idx) => (
                    <InAppMsgTimeSlotsComponent
                      key={idx}
                      preferredTime={preferredTime}
                      changePreferredTime={(eve) => changePreferredTime(eve, idx)}
                      deletePreferredTime={() => deletePreferredTime(idx)}
                      disabled={disabled}
                    />
                  ))}
                  {!disabled && (
                    <Grid item xs={12}>
                      <Button
                        sx={{ width: '100%' }}
                        color="primary"
                        startIcon={<Add />}
                        onClick={addPreferredTime}
                      >
                        Add Preferred Time
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="Countries"
                changeCriteriaObject={handleChangeCriteria}
                id="countries"
                valueIsValid={inAppMessageObject?.criteria?.countries?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="countries"
                  value={inAppMessageObject?.criteria?.countries}
                  options={customOptions?.countries
                    ?.slice()
                    ?.filter((country) => country?.enabled && !country?.deleted)
                    ?.sort?.((a, b) => a?.title.localeCompare(b?.title))}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  disabled={disabled}
                  selectType={selectTypes.string}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="Unverified User"
                changeCriteriaObject={handleChangeCriteria}
                id="testUser"
                valueIsValid={!!inAppMessageObject?.criteria?.testUser}
              >
                <CustomSelect
                  id="testUser"
                  value={
                    inAppMessageObject?.criteria?.testUser
                      ? {
                          id: inAppMessageObject?.criteria?.testUser,
                          title: TEXT_USERS_FOR_IN_APP_MSG?.find(
                            (state) => state?.id === inAppMessageObject?.criteria?.testUser,
                          )?.title,
                        }
                      : null
                  }
                  options={TEXT_USERS_FOR_IN_APP_MSG?.sort((a, b) =>
                    a.title.localeCompare(b.title),
                  )}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  isDisabled={disabled}
                  selectType={selectTypes.string}
                  onChange={(selectValue) =>
                    handleChangeCriteria({
                      target: { id: 'testUser', value: selectValue?.id || null },
                    })
                  }
                  isClearable
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="Priority"
                changeCriteriaObject={handleChangeCriteria}
                id="priority"
                valueIsValid={isValidNumber(inAppMessageObject?.criteria?.priority)}
              >
                <CustomReactSelect
                  id="priority"
                  value={inAppMessageObject?.criteria?.priority}
                  options={IN_APP_MSG_PRIORITY}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  disabled={disabled}
                  isClearable
                  selectType={selectTypes.string}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="Content Languages"
                changeCriteriaObject={handleChangeCriteria}
                id="languages"
                valueIsValid={inAppMessageObject?.criteria?.languages?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="languages"
                  value={inAppMessageObject?.criteria?.languages}
                  options={customOptions?.languages
                    ?.slice()
                    ?.sort((a, b) => a?.languageName?.localeCompare(b?.languageName))}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.languageName}
                  disabled={disabled}
                  selectType={selectTypes.string}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="Subscription Types"
                changeCriteriaObject={handleChangeCriteria}
                id="subscriptionTypes"
                valueIsValid={inAppMessageObject?.criteria?.subscriptionTypes?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="subscriptionTypes"
                  value={inAppMessageObject?.criteria?.subscriptionTypes}
                  options={SUBSCRIPTION_TYPES_LAYMAN}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  disabled={disabled}
                  selectType={selectTypes.string}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="App Languages"
                changeCriteriaObject={handleChangeCriteria}
                id="appLanguages"
                valueIsValid={inAppMessageObject?.criteria?.appLanguages?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="appLanguages"
                  value={inAppMessageObject?.criteria?.appLanguages?.sort((a, b) =>
                    a?.languageName?.localeCompare(b?.languageName),
                  )}
                  options={customOptions?.languages}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.languageName}
                  disabled={disabled}
                  selectType={selectTypes.string}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="Primary Tags"
                changeCriteriaObject={handleChangeCriteria}
                id="primaryTags"
                valueIsValid={inAppMessageObject?.criteria?.primaryTags?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="primaryTags"
                  value={inAppMessageObject?.criteria?.primaryTags}
                  options={customOptions?.tags
                    ?.slice()
                    ?.sort((a, b) => a.title.localeCompare(b.title))}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  disabled={disabled}
                  selectType={selectTypes.object}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                label="Secondary Tags"
                changeCriteriaObject={handleChangeCriteria}
                id="secondaryTags"
                valueIsValid={inAppMessageObject?.criteria?.secondaryTags?.length > 0}
              >
                <CustomReactSelect
                  isMulti
                  id="secondaryTags"
                  value={inAppMessageObject?.criteria?.secondaryTags}
                  options={customOptions?.tags
                    ?.slice()
                    ?.sort((a, b) => a.title.localeCompare(b.title))}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  disabled={disabled}
                  selectType={selectTypes.object}
                  onChangeEvent={handleChangeCriteria}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="courses"
                label="Courses"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={inAppMessageObject?.criteria?.courseIds?.length > 0}
              >
                <CustomAsyncPaginate
                  isMulti
                  id="courseIds"
                  value={inAppMessageObject?.criteria?.courseIds}
                  loadOptions={loadOptionsForAsyncPaginate}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  isDisabled={disabled}
                  debounceTimeout={500}
                  additional={{
                    apiUrl: GENERIC_APIS.entity.getPaginated,
                    getFunction: GetSettingsData,
                    otherParams: {
                      entityType: 'course',
                      size: 30,
                    },
                  }}
                  onChange={(value) => handleChangeCriteria({ target: { id: 'courseIds', value } })}
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <CriteriaTopicsComponent
                id="teachers"
                label="Teachers"
                changeCriteriaObject={handleChangeCriteria}
                valueIsValid={inAppMessageObject?.criteria?.teacherIds?.length > 0}
              >
                <CustomAsyncPaginate
                  isMulti
                  id="teacherIds"
                  value={inAppMessageObject?.criteria?.teacherIds}
                  loadOptions={loadOptionsForAsyncPaginate}
                  getOptionValue={(optn) => optn.id}
                  getOptionLabel={(optn) => optn.title}
                  isDisabled={disabled}
                  debounceTimeout={500}
                  additional={{
                    apiUrl: GENERIC_APIS.entity.getPaginated,
                    getFunction: GetSettingsData,
                    otherParams: {
                      entityType: 'mentor',
                      size: 30,
                    },
                  }}
                  onChange={(value) =>
                    handleChangeCriteria({ target: { id: 'teacherIds', value } })
                  }
                />
              </CriteriaTopicsComponent>
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper heading="Platforms">
            {inAppMessageObject?.criteria?.appPlatforms?.map((platform, idx) => (
              <Grid item xs={12} key={idx} sx={{ p: 1 }}>
                <AppPlatformsComponent
                  appPlatform={platform}
                  changeAppPlatform={(eve) => handleChangePlatform(eve, idx)}
                  deletePlatform={() => deletePlatform(idx)}
                  disabled={disabled}
                />
              </Grid>
            ))}
            {!disabled && (
              <Grid item xs>
                <Button sx={{ width: '100%' }} onClick={addPlatform} startIcon={<Add />}>
                  Add Platform
                </Button>
              </Grid>
            )}
          </GridSectionWrapper>
        </AccordionSectionWrapper>
        <AccordionSectionWrapper
          heading="4. Scheduling"
          currentSection={sections.scheduling}
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
        >
          <GridSectionWrapper>
            <Grid item xs={12} sm={6}>
              <CoreMuiDateTimePicker
                inputId={'startDate'}
                label="Start Date"
                minDate={moment().utc()}
                value={moment.utc(inAppMessageObject?.criteria?.startDate)}
                onChange={(date) =>
                  handleChangeCriteria({
                    target: { id: 'startDate', value: date.utc().format('YYYY-MM-DD HH:mm:ss') },
                  })
                }
                defaultValue={moment().utc()}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CoreMuiDateTimePicker
                inputId={'endDate'}
                label="End Date"
                minDate={moment().utc()}
                value={moment.utc(inAppMessageObject?.criteria?.endDate)}
                onChange={(date) =>
                  handleChangeCriteria({
                    target: { id: 'endDate', value: date.utc().format('YYYY-MM-DD HH:mm:ss') },
                  })
                }
                defaultValue={moment().utc()}
                disabled={disabled}
              />
            </Grid>
          </GridSectionWrapper>
          <GridSectionWrapper>
            <Grid item xs={12}>
              <TwoWaySwitch
                leftText="Show finite number of times"
                size={'small'}
                id="showAlways"
                checked={!!inAppMessageObject?.criteria?.showAlways || false}
                disabled={disabled}
                onChange={handleChangeCriteria}
                rightText="Show always"
              />
            </Grid>
            {!inAppMessageObject?.criteria?.showAlways && (
              <>
                <Grid item xs={12} sm={6}>
                  <CCFormInput
                    id="displayFrequency"
                    type="number"
                    value={inAppMessageObject?.criteria?.displayFrequency}
                    label="Msg Interval In days"
                    subLabel="Every {Msg Interval In days} days i.e frequency is 2 every 2nd day"
                    onChange={handleChangeCriteria}
                    placeholder="Enter a number of days..."
                    colNumber={12}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CCFormInput
                    id="displayMax"
                    type="number"
                    subLabel="This is the max number of times a user will see this message in a day."
                    value={inAppMessageObject?.criteria?.displayMax}
                    label="# Number of Msgs in a day"
                    onChange={handleChangeCriteria}
                    placeholder="Enter a number of times a user will see this message in a day..."
                    colNumber={12}
                    disabled={disabled}
                  />
                </Grid>
              </>
            )}
          </GridSectionWrapper>
        </AccordionSectionWrapper>
        <AccordionSectionWrapper
          heading="5. Additional options - non functional"
          currentSection={sections.additionalOptions}
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
        >
          <GridSectionWrapper heading="Custom Payload">
            <KeyValuePairEditor
              disabled={disabled}
              keyValuePairInArrayFormat={inAppMessageObject?.templateData?.metaInfo}
              setMainObject={handleChangeTemplateData}
              id={'metaInfo'}
            />
          </GridSectionWrapper>
        </AccordionSectionWrapper>
      </Grid>
      {width >= 768 && (
        <Grid item xs={12} md={6}>
          <InAppMessagePreviewComponent messageData={inAppMessageObject} />
        </Grid>
      )}
      {width < 768 && (
        <InAppMsgSwipeablePreview
          anchor="bottom"
          open={showPreview}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          SwipeAreaProps={{
            onClick: toggleDrawer(),
            sx: {
              cursor: 'pointer',
            },
            children: (
              <InAppMsgSwipeablePreviewOpenButton disableTouchRipple>
                <ExpandLess />
              </InAppMsgSwipeablePreviewOpenButton>
            ),
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {showPreview && (
            <InAppMsgSwipeablePreviewOpenButton
              onClick={toggleDrawer()}
              open={showPreview}
              disableTouchRipple
            >
              <ExpandLess />
            </InAppMsgSwipeablePreviewOpenButton>
          )}
          <div className="w-100">
            <InAppMessagePreviewComponent messageData={inAppMessageObject} />
          </div>
        </InAppMsgSwipeablePreview>
      )}
    </Grid>
  )
}
