import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material'
import React from 'react'

export default function TrueFalseHandler({ value, justifyClass = 'justify-content-center' }) {
  return (
    <div className={`w-100 d-flex ${justifyClass} align-items-center`}>
      {value ? <CheckCircle /> : <RadioButtonUnchecked />}
    </div>
  )
}
