export const APP_LISTABLE_OBJECT = {
  title: '',
  description: '',
  iconImage: '',
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  verticalRectangle: '',
  verticalRectangleColor: '',
  heroCardImage: '',
  heroCardImageColor: '',
  blogBanner: '',
  blogBannerColor: '',
  backdropImage: '',
  backdropImageColor: '',
  customShortLink: '',
  customFields: {},
}
