import { CFormInput, CFormLabel } from '@coreui/react-pro'
import { Delete, Refresh } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { RANGE_OBJECT } from 'src/common/types/investigation/investigationObject'
import AutomaticListAddingComponent, {
  AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS,
} from 'src/components/controlCenter/common/AutomaticListAddingComponent'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import {
  ALL_ENTITY_TYPES_FROM_CONSTANTS,
  INVESTIGATION_TEST_TYPES_ARRAY,
  INVESTIGATION_TYPES,
  INVESTIGATION_TYPES_ARRAY,
  UNITS_ARRAY,
} from 'src/utilities/constants'
import {
  giveGenericHandleChangedData,
  loadOptionsForAsyncPaginate,
} from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'

export default function InvestigationForm({
  initialObject,
  imageObject: investigation,
  setImageObject: setInvestigation,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  hideEnabled = false,
  slugValidation,
  setSlugValidation,
  setTitleValid,
  titleValid,
  setSaving,
}) {
  const [store] = useAppSettingsStore()
  const [refreshCache, setRefreshCache] = useState(0)
  // const { getCategories } = useContext(LocalCacheContext)
  const [loadingCategories, setLoadingCategories] = useState(false)

  async function checkTitleValidation(title) {
    const resp = await GetSettingsData('investigation/check-field', {
      field: 'title',
      value: title,
    })
    if (resp.status === 200) {
      setTitleValid(resp.data)
    }
  }
  const debounceCheckTitleValidation = useCallback(
    debounce(async (event, initialValue) => {
      if (initialValue === event.target.value) {
        setTitleValid(true)
        setSaving(false)
        return
      }
      await checkTitleValidation(event.target.value)
      setSaving(false)
    }, 1000),
    [], // Empty dependency array ensures this is only created once
  )

  // const debounceCheckTitleValidation = debounce(async (event) => {
  //   await checkTitleValidation(event.target.value)
  //   setSaving(false)
  // }, 1000)

  function handleChangeReferenceRange(e, idx) {
    setInvestigation((prev) => ({
      ...prev,
      referenceRange: prev?.referenceRange.map((item, index) => {
        return index === idx ? { ...item, ...giveGenericHandleChangedData(e, item) } : item
      }),
    }))
  }

  function handleAddRange() {
    setInvestigation((prev) => ({
      ...prev,
      referenceRange: [
        ...(prev?.referenceRange || []),
        {
          ...RANGE_OBJECT,
          normalRange: (prev?.referenceRange || [])?.length > 0 ? false : true,
          unit: investigation?.units?.[0] || '',
        },
      ],
    }))
  }

  function deleteRange(idx) {
    setInvestigation((prev) => ({
      ...prev,
      referenceRange: prev?.referenceRange.filter((item, index) => index !== idx),
    }))
  }

  return (
    <Grid container spacing={2} sx={{ my: 2 }}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              size={'small'}
              id="enabled"
              checked={!!investigation?.enabled || false}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          }
          label={'Enabled'}
        />
      </Grid>
      <Grid item xs={12}>
        <CCFormInput
          id="title"
          label="Title"
          value={investigation?.title}
          invalid={!titleValid}
          feedbackInvalid="This title is already taken or in invalid format."
          onChange={(e) => {
            setSaving(true)
            debounceCheckTitleValidation(e, initialObject?.title)
            handleChangeObject(e)
          }}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="altTitles"
          label="Alternative Titles"
          direction={AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.HORIZONTAL}
          maxWidthOfItems="49%"
          value={investigation?.altTitles}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <CCTextArea
          id="description"
          label="Description"
          value={investigation?.description}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomReactSelect
          id="investigationType"
          label={'Investigation Type'}
          value={investigation?.investigationType}
          options={INVESTIGATION_TYPES_ARRAY?.sort((a, b) => a?.title.localeCompare(b?.title))}
          onChangeEvent={handleChangeObject}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title}
          disabled={disabled}
          selectType={selectTypes.string}
        />
      </Grid>
      {investigation?.investigationType === INVESTIGATION_TYPES.panel && (
        <Grid item xs={12}>
          <CFormLabel>Tests</CFormLabel>
          <div className="d-flex align-items-center gap-1">
            <div className="w-100">
              <ActivityItemAsyncSelect
                isMulti
                cacheUniqs={[refreshCache]}
                classNamePrefix="activity-item-select"
                menuPortalTarget={document.body}
                value={investigation?.testIds}
                loadOptions={loadOptionsForAsyncPaginate}
                debounceTimeout={1000}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.title}
                isDisabled={disabled}
                additional={{
                  page: 0,
                  transformDataToReturn: (data) =>
                    data?.filter((dat) => dat?.id !== initialObject?.id),
                  apiUrl: GENERIC_APIS.entity.getPaginated,
                  getFunction: GetSettingsData,
                  otherParams: {
                    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.investigation,
                  },
                }}
                onChange={(selVal) => setInvestigation((prev) => ({ ...prev, testIds: selVal }))}
              />
            </div>
            <Tooltip title="Reset Cached Tests">
              <IconButton
                onClick={() => {
                  setRefreshCache((prev) => prev + 1)
                }}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomReactSelect
          isMulti
          id="category"
          label="Category"
          value={investigation?.category}
          options={store?.categories
            ?.slice()
            ?.filter((cat) => cat?.categoryType === 'investigation')}
          onChangeEvent={handleChangeObject}
          isLoading={loadingCategories}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title || option?.name}
          disabled={disabled}
          selectType={selectTypes.string}
        />
      </Grid>
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="associatedConditions"
          label="Associated Conditions"
          value={investigation?.associatedConditions}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      {investigation?.investigationType !== INVESTIGATION_TYPES.panel && (
        <>
          <Grid item xs={12}>
            <CCFormInput
              id="marker"
              label="Marker"
              value={investigation?.marker}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              isClearable
              id="type"
              label="Test Type"
              value={investigation?.type}
              options={INVESTIGATION_TEST_TYPES_ARRAY?.sort((a, b) =>
                a?.title.localeCompare(b?.title),
              )}
              onChangeEvent={handleChangeObject}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="sampleType"
              label="Sample Type (Deprecated)"
              value={investigation?.sampleType}
              options={customOptions.investigationSample?.sort((a, b) =>
                a?.title.localeCompare(b?.title),
              )}
              onChangeEvent={handleChangeObject}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              isMulti
              id="sampleTypes"
              label="Sample Types"
              value={investigation?.sampleTypes}
              options={customOptions.investigationSample?.sort((a, b) =>
                a?.title.localeCompare(b?.title),
              )}
              onChangeEvent={handleChangeObject}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutomaticListAddingComponent
              id="units"
              label="Units"
              direction={AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.HORIZONTAL}
              maxWidthOfItems="200px"
              disabled={disabled}
              value={investigation?.units}
              onChange={handleChangeObject}
              InputComponent={UnitInputDropDown}
            />
          </Grid>
          <Grid item xs={12}>
            <CFormLabel>Unit Ranges</CFormLabel>
            {investigation?.referenceRange?.map((rng, idx) => (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                  gap: '7px',
                  boxShadow: '0 0 5px -1px #000',
                  padding: '15px',
                  borderRadius: '7px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size={'small'}
                      id="normalRange"
                      checked={!!rng?.normalRange || false}
                      onChange={(e) => {
                        handleChangeReferenceRange(e, idx, 'referenceRange')
                      }}
                      disabled={disabled}
                    />
                  }
                  label={'Normal Range'}
                  labelPlacement="top"
                />
                <div className="w-100">
                  <CCFormInput
                    id="min"
                    label="Min"
                    value={rng?.min}
                    onChange={(e) => handleChangeReferenceRange(e, idx, 'referenceRange')}
                    disabled={disabled}
                  />
                </div>
                <div className="w-100">
                  <CCFormInput
                    id="max"
                    label="Max"
                    value={rng?.max}
                    onChange={(e) => handleChangeReferenceRange(e, idx, 'referenceRange')}
                    disabled={disabled}
                  />
                </div>
                <div className="w-100">
                  <CustomReactSelect
                    id={`unit`}
                    label="Unit"
                    value={rng?.unit}
                    options={
                      investigation?.units?.map((unit) => ({
                        id: unit,
                        title: unit,
                      })) || []
                    }
                    onChangeEvent={(e) => handleChangeReferenceRange(e, idx)}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.title}
                    disabled={disabled}
                    selectType={selectTypes.string}
                  />
                </div>
                {!disabled && (
                  <IconButton onClick={() => deleteRange(idx)}>
                    <Delete />
                  </IconButton>
                )}
              </div>
            ))}
          </Grid>
          {!disabled && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleAddRange}>Add Range</Button>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="usage"
          label="Usage"
          value={investigation?.usage}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="limitations"
          label="Limitations"
          value={investigation?.limitations}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

function UnitInputDropDown({ value, onChange, valueId, disabled }) {
  return (
    <Autocomplete
      inputValue={value}
      disabled={disabled}
      onInputChange={(e, newValue) => onChange({ target: { id: valueId, value: newValue } })}
      freeSolo
      options={UNITS_ARRAY?.map((unit) => unit?.title)}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <CFormInput disabled={disabled} {...params.inputProps} />
        </div>
      )}
    />
  )
}

/* <Tooltip title="Refresh categories">
<IconButton
  size="small"
  onClick={async () => {
    setLoadingCategories(true)
    await getCategories({ force: true })
    setLoadingCategories(false)
  }}
>
  <Refresh />
</IconButton>
</Tooltip> */
