import { getCuratedCardRefsBulk } from 'src/views/setup/tiles/components/legacyTiles/tileFormUtils'

export async function getItemTitleForTypeId(itemId, type, metaCategoryId) {
  const responseData = await getCuratedCardRefsBulk(
    [itemId],
    metaCategoryId, //this is actually the metaCategoryType
    type,
  )
  return responseData
}
