const PAGINATION_OBJECT = {
  content: [],
  pageable: {
    pageNumber: 0,
    pageSize: 20,
    sort: {
      empty: true,
      sorted: false,
      unsorted: true,
    },
    offset: 0,
    unpaged: false,
    paged: true,
  },
  last: true,
  totalElements: 4,
  totalPages: 1,
  size: 20,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true,
  },
  first: true,
  numberOfElements: 4,
  empty: false,
}

export function convertArrayToPaginatedObject(array = []) {
  return {
    ...PAGINATION_OBJECT,
    content: array,
    totalElements: array.length,
    totalPages: 1,
    numberOfElements: array.length,
  }
}
