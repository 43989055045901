import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getSummarizableObject } from '../abstractObjects/Summarizable'

export const RANGE_OBJECT = {
  min: '',
  max: '',
  normalRange: false,
  unit: '',
}

export const INVESTIGATION_SAMPLE_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.investigationSample),
  title: '',
}
