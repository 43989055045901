export function convertKeyValuePairsArrayObject(keyValuePairs = {}) {
  const formedObject = Object.entries(keyValuePairs || {})
  return (formedObject?.length > 0 ? formedObject : [['', '']])?.map(([key, value]) => {
    return { customField: key, value }
  })
}

export function revertKeyValuePairArrayToKeyValueToObjectFormat(keyValuePairs = []) {
  return keyValuePairs?.length > 0
    ? keyValuePairs.reduce((acc, field) => {
        if (field.customField) {
          acc[field.customField] = field.value
        }
        return acc
      }, {})
    : null
}
