import React from 'react'
import { selectTypes } from 'src/components/controlCenter/FormComponents'
import { CustomSelect } from 'src/views/plugins/calendar/components/CustomSelect'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { loadCategoryTypeBasedItems } from '../../utils'
import ExtraOptionsComponent from './ExtraOptionsComponent'

export default function DLTypeTeacher({
  item,
  handleChangeItem,
  dropDownOptions,
  setAdditionalData,
  additionalData,
  changeAdditionalItem,
  uiContextData,
}) {
  return (
    <>
      <div>
        <label className="mb-2">{uiContextData?.linkIdSelectionLabel || 'Teacher Category'}</label>
        <CustomSelect
          menuPortalTarget={document.body}
          value={item?.value ? item : null}
          options={dropDownOptions.categories
            ?.filter((cat) => cat.categoryType === 'service-provider')
            ?.sort((a, b) => a?.title.localeCompare(b?.title))
            .map((cat) => ({ value: cat.id, label: cat.title }))}
          onChange={handleChangeItem}
          selectType={selectTypes.object}
          placeholder="Select teacher category..."
        />
      </div>
      <ExtraOptionsComponent
        {...{ item, additionalData, changeAdditionalItem, setAdditionalData, uiContextData }}
      >
        <div className="pb-2">
          <label>{uiContextData?.extraDataItemIdSelectionLabel || 'Additional Item'}</label>
          <ActivityItemAsyncSelect
            cacheUniqs={[item?.value, additionalData?.idType]}
            classNamePrefix="activity-item-select"
            menuPortalTarget={document.body}
            value={additionalData?.value ? additionalData : null}
            loadOptions={loadCategoryTypeBasedItems}
            debounceTimeout={1000}
            additional={{
              page: 0,
              size: 200,
              metaCategoryTypeId: 'mentor',
              additionalFilters: {
                teacherCategory: item?.value,
              },
            }}
            onChange={(selVal) => changeAdditionalItem(selVal)}
          />
        </div>
      </ExtraOptionsComponent>
    </>
  )
}
