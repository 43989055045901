import { CSmartTable } from '@coreui/react-pro'
import React, { useCallback, useMemo, useState } from 'react'
import { components } from 'react-select'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_AVAILABLE_PLATFORMS } from 'src/utilities/constants'
import { loadOptionsForAsyncPaginate } from 'src/utilities/generalUtils'
import OpenTilePolicyInNewTab from './OpenTilePolicyInNewTab'
import TabTableItem from './TabTableItem'
import { TileAsyncPaginatedSelect, TileSingleSelectContainer } from './styledComponent'

export default function TabPoliciesComponents({
  // androidTabDetailedList,
  // webTabDetailedList,
  // iosTabDetailedList,
  androidTilePolicies,
  webTabTilePolicies,
  iosTabTilePolicies,
  dropDownOptions,
  disabled,
  handleChangeAndroid,
  handleChangeWeb,
  handleChangeIos,
  iconView,
}) {
  const allTabs = dropDownOptions?.allTabs?.filter((tab) => !!tab?.curatable)
  const tdStyles = { width: '300px' }
  const origin = window.location.origin

  const handleChangeSelectMap = useMemo(
    () => ({
      [ALL_AVAILABLE_PLATFORMS.android]: handleChangeAndroid,
      [ALL_AVAILABLE_PLATFORMS.web]: handleChangeWeb,
      [ALL_AVAILABLE_PLATFORMS.ios]: handleChangeIos,
    }),
    [handleChangeAndroid, handleChangeWeb, handleChangeIos],
  )

  const handleChangeTilePolicy = useCallback(
    (tileId, tilePolicy, platform) => {
      handleChangeSelectMap?.[platform]((prev) => {
        return {
          ...prev,
          tilePolicyMap: { ...prev?.tilePolicyMap, [tileId]: tilePolicy },
        }
      })
    },
    [handleChangeSelectMap],
  )

  const scopedColumns = {
    tabs: (item) => {
      return <TabTableItem item={item} iconView={iconView} />
    },
    android: (item) => {
      return (
        <td style={tdStyles}>
          <div className="d-flex justify-content-stretch align-items-center gap-2">
            <TilesAsyncSelect
              value={androidTilePolicies?.[item?.id]}
              onChange={(selectedOption) => {
                handleChangeTilePolicy(item?.id, selectedOption, ALL_AVAILABLE_PLATFORMS.android)
              }}
              disabled={disabled}
              tabItem={item}
            />
            <OpenTilePolicyInNewTab tabId={item?.id} tileObject={androidTilePolicies?.[item?.id]} />
          </div>
        </td>
      )
    },
    web: (item) => {
      return (
        <td style={tdStyles}>
          <div className="d-flex justify-content-stretch align-items-center gap-2">
            <TilesAsyncSelect
              value={webTabTilePolicies?.[item?.id]}
              onChange={(selectedOption) => {
                handleChangeTilePolicy(item?.id, selectedOption, ALL_AVAILABLE_PLATFORMS.web)
              }}
              disabled={disabled}
              tabItem={item}
            />
            <OpenTilePolicyInNewTab tabId={item?.id} tileObject={webTabTilePolicies?.[item?.id]} />
          </div>
        </td>
      )
    },
    ios: (item) => {
      return (
        <td style={tdStyles}>
          <div className="d-flex justify-content-stretch align-items-center gap-2">
            <TilesAsyncSelect
              value={iosTabTilePolicies?.[item?.id]}
              onChange={(selectedOption) => {
                handleChangeTilePolicy(item?.id, selectedOption, ALL_AVAILABLE_PLATFORMS.ios)
              }}
              disabled={disabled}
              tabItem={item}
            />
            <OpenTilePolicyInNewTab tabId={item?.id} tileObject={iosTabTilePolicies?.[item?.id]} />
          </div>
        </td>
      )
    },
  }

  const allColumns = [
    { key: 'tabs', label: 'Tab Name' },
    { key: 'android', label: 'Android', _style: { minWidth: '300px' } },
    { key: 'web', label: 'Web', _style: { minWidth: '300px' } },
    { key: 'ios', label: 'iOS', _style: { minWidth: '300px' } },
  ]

  return (
    <CSmartTable
      items={allTabs}
      scopedColumns={scopedColumns}
      columns={allColumns}
      itemsPerPage={100000}
      tableProps={{
        striped: true,
        align: 'middle',
        responsive: true,
      }}
    />
  )
}

function TileSelectContainer({ children, ...propData }) {
  return (
    <TileSingleSelectContainer>
      <components.SelectContainer {...propData}>{children}</components.SelectContainer>
    </TileSingleSelectContainer>
  )
}

function TilesAsyncSelect({ value, onChange, disabled, tabItem }) {
  const [loading, setLoading] = useState(false)

  const selectAdditional = {
    page: 0,
    getFunction: GetSettingsData,
    apiUrl: GENERIC_APIS.entity.getPaginated,
    setLoading,
    otherParams: {
      entityType: ALL_ENTITY_TYPES.tilePolicy,
      category: tabItem?.id,
    },
  }

  async function Setup() {
    const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.tilePolicy,
      category: tabItem?.id,
      active: true,
    })
    if (response.status === 200) {
      onChange(response.data?.content?.[0])
    }
  }

  // useEffect(() => {
  //   if (!value || value === 'default') {
  //     Setup()
  //   }
  // })

  return (
    <TileAsyncPaginatedSelect
      value={value}
      menuPortalTarget={document.body}
      classNamePrefix="activity-item-select"
      loadOptions={loadOptionsForAsyncPaginate}
      additional={selectAdditional}
      onChange={onChange}
      isLoading={loading}
      getOptionLabel={(option) => option?.name}
      getOptionValue={(option) => option?.id}
      isDisabled={disabled}
      debounceTimeout={500}
      requiredSelectContainerClasses="h-auto w-100"
      placeholder="Auto Default"
    />
  )
}

////CODE TO BE REMOVED

// async function openTilePolicyInNewTab(tilePolicyId, tabId) {
//   if (tilePolicyId) {
//     openInNewTab(`${origin}/setup/tiles/${tilePolicyId}?mode=View`)
//     return
//   }
//   const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
//     entityType: ALL_ENTITY_TYPES.tilePolicy,
//     category: tabId,
//     active: true,
//   })
//   if (response.status === 200) {
//     if (response.data?.content?.length > 0) {
//       openInNewTab(`${origin}/setup/tiles/${response.data?.content?.[0]?.id}?mode=View`)
//     } else {
//       toast.error(`No default tile policy found for ${tabId} tab`)
//     }
//   }
// }
