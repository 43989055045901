import { defaultLinkMakerContextData } from 'src/components/controlCenter/linksMaker/LinksMakerComponents'
import { initURIContextDataTitleForDeepLinks } from 'src/components/controlCenter/linksMaker/components/viewLink/deepLink/utils/initURIs'
import { getReduxStoreData } from 'src/getDataFromRedux'
import { fetchFunction } from 'src/services'
import { getSetupDataFromLocalStorage } from 'src/services/BrowserStorage/localStorageController'
import { GENERIC_APIS } from 'src/services/genericApis'
import { IdTypes } from 'src/utilities/constants'
import { additionalDataForNotificationsFactory } from './transformInitialDataFactory/additionalDataTransformers/factory'
import { extraDataTransformersFactory } from './transformInitialDataFactory/extraDataTransformers/factory'

export async function transformInitialNotificationData(fetchedData) {
  // const foundSourceIdData = await getCuratedCardRefsBulk([fetchedData?.sourceId], fetchedData?.type)
  const allMetaCategoryTypes =
    (await getReduxStoreData()?.metaCategoryTypes) ||
    getSetupDataFromLocalStorage()?.metaTabs ||
    fetchFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: 'meta-category-type',
      size: 999,
    })

  let dlData = {
    ...defaultLinkMakerContextData,
    metaCategoryId: fetchedData?.type,
    itemId: fetchedData?.sourceId,
    idType: fetchedData?.subType ? fetchedData?.subType : IdTypes.id,
    additionalData: additionalDataForNotificationsFactory(fetchedData, fetchedData?.type),
    extraData: extraDataTransformersFactory(fetchedData, fetchedData?.type),
    action: fetchedData?.customFields?.action,
  }

  const dlDataToPut = await initURIContextDataTitleForDeepLinks(dlData, allMetaCategoryTypes)
  return {
    ...fetchedData,
    dlData: {
      ...dlData,
      ...dlDataToPut,
      additionalData: {
        ...dlData?.additionalData,
        ...dlDataToPut?.additionalData,
      },
      extraData: dlData?.extraData?.idType
        ? dlData?.extraData?.itemId && {
            ...dlData?.extraData,
            ...dlDataToPut?.extraData,
          }
        : null,
    },
    // customFields: convertKeyValuePairsArrayObject(fetchedData?.customFields),
  }
}
