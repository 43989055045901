import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const REST_OBEJCT = {
  id: '',
  createdBy: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'rest',
  modifiedBy: '',
  title: '',
  description: '',
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  verticalRectangle: '',
  verticalRectangleColor: '',
  heroCardImage: '',
  heroCardImageColor: '',
  blogBanner: '',
  blogBannerColor: '',
  backdropImage: '',
  backdropImageColor: '',
  customShortLink: '',
  subscription: null,
  altTexts: {
    explore: '',
    story: '',
    sleep: '',
    yoga: '',
    sdk: '',
    sound: '',
    journal: '',
    home: '',
  },
  tags: [],
  internalTitle: '',
}
