import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { AMHM_EVENT } from '../abstractObjects/AmhmEvent'
import { getSummarizableObject } from '../abstractObjects/Summarizable'

export const SATELLITE_SCREEN_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.satelliteCall),
  ...AMHM_EVENT,
  satelliteReceiverId: '',
  satelliteClinicId: '',
  satelliteReceiverType: '',
}
