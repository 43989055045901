import {
  PMPHeaderDoctorText,
  PMPHeaderLeftPart,
  PMPHeaderRightPart,
  PMPHeadingBanner,
} from '../../styledComponents'

export default function TwoColumnPrescriptionHeader({ prescriptionData, leaveSpace }) {
  return (
    <>
      <PMPHeadingBanner noColor={true}>{prescriptionData?.header?.title}</PMPHeadingBanner>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PMPHeaderLeftPart>
          <PMPHeaderDoctorText main>{prescriptionData?.doctorName}</PMPHeaderDoctorText>
          <PMPHeaderDoctorText>{prescriptionData?.designation}</PMPHeaderDoctorText>
          <PMPHeaderDoctorText>{prescriptionData?.listOfDegrees?.join(', ')}</PMPHeaderDoctorText>
          {prescriptionData?.contactEmail && (
            <PMPHeaderDoctorText>
              Email: <b>{prescriptionData?.contactEmail}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.website && (
            <PMPHeaderDoctorText>
              Website: <b>{prescriptionData?.website}</b>
            </PMPHeaderDoctorText>
          )}
        </PMPHeaderLeftPart>
        <PMPHeaderRightPart
          style={{
            flexShrink: 0,
            width: 'unset',
          }}
        >
          {prescriptionData?.regId && (
            <PMPHeaderDoctorText>
              <b>Regn No.: {prescriptionData?.regId}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.cmoRegId && (
            <PMPHeaderDoctorText>
              <b>C.M.O. Regd. No.: {prescriptionData?.cmoRegId}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.affiliations?.map((affiliation, affIndex) => (
            <PMPHeaderDoctorText key={affIndex}>
              <b>{affiliation}</b>
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderRightPart>
      </div>
    </>
  )
}
