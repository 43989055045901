import { CFormInput } from '@coreui/react-pro'
import { Add, Delete } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
} from '@mui/material'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { components } from 'react-select'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { ThemeContext } from 'src/context/ThemeContext'
import { timezones_list } from 'src/utilities/constants'
import { getCorrectTargetValueForEvent } from 'src/utilities/generalUtils'
import { MetaCategoryIdsSelectContainer } from 'src/views/setup/tiles/components/styledComponents'
import { CollapseIcon } from 'src/views/subscriptions/customPlans/components/styledComponents'
import { NEW_SCHEDULE_OBJECT } from '../utils/clinic-utils'
import './clinic-schedule-styles.css'
import {
  ClinicTimeSlotSelectionContainer,
  ScheduleInputContainer,
  ScheduleTitleInputContainer,
} from './styledComponents'

function SlotTimeSelectControl({ children, ...propData }) {
  console.log(propData?.selectProps?.isInvalid)
  return (
    <MetaCategoryIdsSelectContainer
      customValidColor={'transparent'}
      // customInvalidColor={'#edbbbb'}
      showBorder={true}
      invalid={propData?.selectProps?.isInvalid}
    >
      <components.SelectContainer {...propData}>{children}</components.SelectContainer>
    </MetaCategoryIdsSelectContainer>
  )
}

export default function ClinicSchedule({
  teacherData,
  setTeacherData,
  disabled,
  pushHeadingToTop = false,
}) {
  const schedules = teacherData?.schedules || []
  const [allExpand, setAllExpand] = useState(true)
  const { theme } = useContext(ThemeContext)

  const handleChangeSchedule = useCallback(
    (event, idx) => {
      if (typeof event === 'function') {
        setTeacherData((prev) => ({
          ...prev,
          schedules: (prev?.schedules || []).map((schedule, index) =>
            index === idx ? event(schedule) : schedule,
          ),
        }))
      } else {
        setTeacherData((prev) => ({
          ...prev,
          schedules: (prev?.schedules || []).map((schedule, index) =>
            index === idx
              ? { ...schedule, [event.target.id]: getCorrectTargetValueForEvent(event || {}) }
              : schedule,
          ),
        }))
      }
    },
    [setTeacherData],
  )

  function handleChangeWeeklyHourMap(event, idx, day) {
    if (typeof event === 'function') {
      handleChangeSchedule(
        (prev) => ({
          ...prev,
          weeklyHourMap: {
            ...(prev.weeklyHourMap || {}),
            [day]: event(prev.weeklyHourMap?.[day] || {}),
          },
        }),
        idx,
      )
    } else {
      handleChangeSchedule(
        (prev) => ({
          ...prev,
          weeklyHourMap: {
            ...(prev.weeklyHourMap || {}),
            [day]: {
              ...(prev.weeklyHourMap?.[day] || {}),
              [event.target.id]: getCorrectTargetValueForEvent(event),
            },
          },
        }),
        idx,
      )
    }
  }

  function toggleAllExpand() {
    setAllExpand((prev) => !prev)
  }

  function changeDefault(e, idx) {
    setTeacherData((prev) => ({
      ...prev,
      schedules: (prev?.schedules || []).map((schedule, index) =>
        index === idx
          ? { ...schedule, defaultSchedule: e.target.checked }
          : { ...schedule, defaultSchedule: !e.target.checked },
      ),
    }))
  }

  function addSchedule() {
    setTeacherData((prev) => ({
      ...prev,
      schedules: [
        ...(prev?.schedules || []),
        {
          ...NEW_SCHEDULE_OBJECT,
          title: `${NEW_SCHEDULE_OBJECT.title} ${
            (prev?.schedules || []).length === 0 ? '' : (prev?.schedules || []).length + 1
          }`,
          timeZone:
            teacherData.timeZone.offset || teacherData.timeZone.offset !== 'undefined'
              ? teacherData.timeZone.offset
              : encodeURIComponent('+05:30'),
        },
      ],
    }))
  }
  function deleteSchedule(idx) {
    setTeacherData((prev) => {
      if (prev?.schedules?.[idx]?.defaultSchedule) {
        return {
          ...prev,
          schedules: (prev?.schedules || [])
            .filter((schedule, index) => index !== idx)
            .map((schedule, index) =>
              index === 0 ? { ...schedule, defaultSchedule: true } : schedule,
            ),
        }
      } else {
        return {
          ...prev,
          schedules: (prev?.schedules || []).filter((schedule, index) => index !== idx),
        }
      }
    })
  }

  useEffect(() => {
    console.log({ outerAllExpand: allExpand })
  }, [allExpand])

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          pb: 1,
          position: 'sticky',
          top: pushHeadingToTop ? 'var(--header-height)' : '200px',
          zIndex: 3,
          background:
            theme === 'dark'
              ? 'var(--cui-body-bg)'
              : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))',
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <h3>Schedule</h3>
          <div className="d-flex justify-content-end align-items-center gap-2">
            <IconButton onClick={toggleAllExpand}>
              <CollapseIcon collapsed={!allExpand} />
            </IconButton>
            {!disabled && (
              <IconButton onClick={addSchedule}>
                <Add />
              </IconButton>
            )}
          </div>
        </div>
      </Grid>
      {schedules?.map((schedule, idx) => (
        <Grid item key={idx} sx={{ paddingLeft: '18px !important', width: '100%' }}>
          <ScheduleComponent
            schedule={schedule}
            changeDefault={changeDefault}
            disabled={disabled}
            handleChangeSchedule={handleChangeSchedule}
            handleChangeWeeklyHourMap={handleChangeWeeklyHourMap}
            deleteSchedule={deleteSchedule}
            idx={idx}
            allExpand={allExpand}
            totalSchedules={schedules?.length || 0}
          />
        </Grid>
      ))}
    </Grid>
  )
}

function ScheduleComponent({
  schedule,
  idx,
  handleChangeSchedule,
  changeDefault,
  handleChangeWeeklyHourMap,
  deleteSchedule,
  disabled,
  allExpand,
  totalSchedules,
}) {
  const [expand, setExpand] = useState(false)
  const [allExpandChangeCount, setAllExpandChangeCount] = useState(0)
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  function toggleAccordion() {
    setExpand((prev) => !prev)
  }

  function handleDeleteSchedule() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'delete',
      buttonColor: 'error',
      body: `Do you want to delete this schedule?`,
      onSubmitFunctions: [() => deleteSchedule(idx)],
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
    }))
  }

  function handleChangeDefault(e) {
    const cachedEvent = {
      target: { id: e.target.id, checked: e.target.checked, type: e.target.type },
    }
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'make schedule default',
      buttonColor: 'warning',
      body: `${schedule?.title} will become the default schedule for the clinic!`,
      onSubmitFunctions: [() => changeDefault(cachedEvent, idx)],
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
    }))
  }

  function addAllExpandChange() {
    setAllExpandChangeCount((prev) => prev + 1)
  }

  useEffect(() => {
    console.log({ allExpand, allExpandChangeCount })
    if (allExpandChangeCount !== 0) {
      setExpand(allExpand)
    } else {
      if (idx === 0) {
        setExpand(true)
      }
    }
    addAllExpandChange()
  }, [allExpand])

  return (
    <>
      <Accordion
        sx={{
          border: !!schedule?.defaultSchedule
            ? '2px solid var(--cui-primary)'
            : '2px solid transparent',
          transition: 'all 150ms ease-in-out',
        }}
        expanded={expand}
      >
        <AccordionSummary
          focusVisibleClassName="mui-focus-remove-background"
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ cursor: 'unset !important' }}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <ScheduleInputContainer className="d-flex flex-wrap justify-content-stretch align-items-center gap-3 pb-3">
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Radio
                    size={'small'}
                    id="available"
                    checked={!!schedule?.defaultSchedule || false}
                    onChange={handleChangeDefault}
                    disabled={disabled}
                  />
                }
                label={'Default'}
              />
              <ScheduleTitleInputContainer>
                <CFormInput
                  id="title"
                  value={schedule?.title}
                  label="Schedule Title"
                  disabled={disabled}
                  onChange={(e) => handleChangeSchedule(e, idx)}
                />
              </ScheduleTitleInputContainer>
              <div style={{ minWidth: '250px' }}>
                <CustomReactSelect
                  id="timeZone"
                  disabled={disabled}
                  value={schedule.timeZone ? decodeURIComponent(schedule.timeZone) : null}
                  options={timezones_list}
                  getOptionValue={(option) => option?.gmtvalue}
                  getOptionLabel={(option) => option?.name || option?.title}
                  onChangeEvent={(e) => {
                    handleChangeSchedule(
                      { ...e, target: { ...e.target, value: encodeURIComponent(e.target.value) } },
                      idx,
                    )
                  }}
                  selectType={selectTypes.string}
                  label="Time Zone"
                />
              </div>
            </ScheduleInputContainer>
            <div className="d-flex justify-content-end align-items-center gap-2">
              {!disabled && totalSchedules > 1 && (
                <IconButton onClick={handleDeleteSchedule}>
                  <Delete />
                </IconButton>
              )}
              <IconButton>
                <CollapseIcon collapsed={!expand} onClick={toggleAccordion} />
              </IconButton>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <hr style={{ width: '100%' }}></hr>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {Object.entries(schedule?.weeklyHourMap || {}).map(([key, daySchedule]) => (
                  <Grid item xs={12} key={key}>
                    <WeekSchedule
                      schedule={daySchedule}
                      changeSchedule={(e) => {
                        handleChangeWeeklyHourMap(e, idx, key)
                      }}
                      disabled={disabled}
                      timeZone={schedule.timeZone}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}

function WeekSchedule({ schedule, changeSchedule, disabled, timeZone }) {
  const slotList = schedule.slotList || []

  function changeAvailability(event) {
    if (slotList.length < 1 && event.target.checked) {
      addScheduleSlot()
    }
    changeSchedule(event)
  }

  function handleChangeSlot(event, idx) {
    if (typeof event === 'function') {
      changeSchedule((prev) => ({
        ...prev,
        slotList: (prev.slotList || []).map((slot, index) => (index === idx ? event(slot) : slot)),
      }))
    } else {
      changeSchedule((prev) => ({
        ...prev,
        slotList: (prev.slotList || []).map((slot, index) =>
          index === idx
            ? { ...slot, [event.target.id]: getCorrectTargetValueForEvent(event) }
            : slot,
        ),
      }))
    }
  }

  function addScheduleSlot() {
    var slotToAdd = newSlot
    if (slotList?.length !== 0) {
      slotToAdd = {
        ...slotToAdd,
        startTime: moment(slotList?.[slotList.length - 1]?.endTime || newSlot.startTime, 'HH:mm:ss')
          ?.add(1, 'hour')
          .format('HH:mm:ss'),
        endTime: moment(slotList?.[slotList.length - 1]?.endTime || newSlot.endTime, 'HH:mm:ss')
          ?.add(2, 'hour')
          .format('HH:mm:ss'),
      }
    }

    changeSchedule((prev) => {
      return { ...prev, slotList: [...(prev.slotList || []), slotToAdd] }
    })
  }
  function deleteScheduleSlot(idx) {
    changeSchedule((prev) => ({
      ...prev,
      slotList: (prev.slotList || []).filter((slot, index) => index !== idx),
    }))
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={1} sx={{ maxWidth: '400px' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                size={'small'}
                id="available"
                checked={!!schedule?.available || false}
                onChange={changeAvailability}
                disabled={disabled}
              />
            }
            label={schedule?.title}
          />
          {!disabled && !!schedule?.available && (
            <IconButton onClick={addScheduleSlot}>
              <Add />
            </IconButton>
          )}
        </Grid>
        {!!schedule?.available ? (
          <Grid item xs={12}>
            {slotList.map((slot, idx) => (
              <Grid container key={idx} sx={{ pl: 3 }}>
                <Grid item xs={12}>
                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <ClinicTimeSlotSelectionContainer>
                        <CustomReactSelect
                          id="startTime"
                          value={moment(slot.startTime, 'HH:mm:ss').format('HH:mm:ss')}
                          options={getSlotTimeOptions()}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          selectType={selectTypes.string}
                          onChangeEvent={(e) => handleChangeSlot(e, idx)}
                          isInvalid={
                            moment(slot.startTime, 'HH:mm:ss') >=
                              moment(slot.endTime, 'HH:mm:ss') ||
                            (idx !== 0 &&
                              moment(slot.startTime, 'HH:mm:ss') <
                                moment(slotList?.[idx - 1].endTime, 'HH:mm:ss'))
                          }
                          makeSelectContainerTransparent={true}
                          components={{
                            Control: SlotTimeSelectControl,
                          }}
                          disabled={disabled}
                        />
                        {/* {moment(slot.startTime, 'HH:mm:ss').utcOffset(decodedTimeZone).format('HH:mm:A')} */}
                      </ClinicTimeSlotSelectionContainer>
                      <div>to</div>
                      <ClinicTimeSlotSelectionContainer>
                        <CustomReactSelect
                          id="endTime"
                          value={moment(slot.endTime, 'HH:mm:ss').format('HH:mm:ss')}
                          options={getSlotTimeOptions()}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          components={{
                            Control: SlotTimeSelectControl,
                          }}
                          isInvalid={
                            moment(slot.startTime, 'HH:mm:ss') >=
                              moment(slot.endTime, 'HH:mm:ss') ||
                            (idx < slotList.length - 1 &&
                              moment(slotList?.[idx + 1]?.startTime, 'HH:mm:ss') <
                                moment(slot.endTime, 'HH:mm:ss'))
                          }
                          makeSelectContainerTransparent={true}
                          selectType={selectTypes.string}
                          onChangeEvent={(e) => handleChangeSlot(e, idx)}
                          disabled={disabled}
                        />
                      </ClinicTimeSlotSelectionContainer>
                    </div>
                    {(slotList?.length || 0) > 1 && !disabled && (
                      <div>
                        <IconButton onClick={() => deleteScheduleSlot(idx)}>
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </div>
                  {moment(slot.startTime, 'HH:mm:ss') >= moment(slot.endTime, 'HH:mm:ss') && (
                    <small className="text-danger">
                      Start time cannot be higher or equal to End time
                    </small>
                  )}
                  {idx !== 0 &&
                    moment(slot.startTime, 'HH:mm:ss') <
                      moment(slotList?.[idx - 1].endTime, 'HH:mm:ss') && (
                      <small className="text-danger">
                        This slot is overlapping with the previous slot
                      </small>
                    )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ paddingLeft: '24px !important' }}>
            <strong>Closed</strong>
          </Grid>
        )}
      </Grid>
      <hr style={{ width: '100%' }}></hr>
    </div>
  )
}

export const getSlotTimeOptions = () => {
  const options = []
  const startMoment = moment()?.startOf('day')
  const endMoment = moment()?.endOf('day')
  while (startMoment?.isBefore(endMoment)) {
    const timeValue = startMoment?.format('HH:mm:ss')
    const displayText = startMoment?.format('h:mmA')

    options?.push({ value: timeValue, label: displayText })
    startMoment?.add(5, 'minutes')
  }
  return options
}

export const newSlot = { startTime: '09:00:00', endTime: '20:00:00' }
