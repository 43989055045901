import { additionalDataBuilderForBookedEventNotification } from './customHandling/booked_event_notification_handling'
import { defaultHandlingForNotificationAdditionalData } from './defaultHandling'

export function additionalDataForNotificationsFactory(notificationsObject, baseType) {
  switch (baseType) {
    case 'booked_event_notification':
      return additionalDataBuilderForBookedEventNotification(notificationsObject)
    default:
      return defaultHandlingForNotificationAdditionalData(notificationsObject)
  }
}
