import { SaveEntity } from 'src/utilities/apiGetters'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export async function transformPostClinicChamberData(data, storeData) {
  const { satelliteScreenId: satelliteScreenData, ...actualData } = data
  var satelliteScreenId = ''

  var satelliteScreenDataToSend = {
    ...satelliteScreenData,
    mentorId: actualData.mentorId,
    satelliteReceiverId: actualData.assistantId,
    satelliteClinicId: actualData.clinicId,
    clinicBrandId: actualData.clinicBrandId,
  }

  const satelliteResponse = await SaveEntity(
    ALL_ENTITY_TYPES_FROM_CONSTANTS.satelliteCall,
    satelliteScreenDataToSend,
    () => {},
    () => {},
    storeData,
  )
  if (satelliteResponse.status === 200) {
    satelliteScreenId = satelliteResponse.data.id
  }

  return {
    ...actualData,
    satelliteScreenId,
  }
}
