import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'

export default function RestFeatureComponent({
  initialObject = {},
  imageObject: restObject,
  setImageObject: setRestObject,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  view = 'default',
}) {
  return (
    <>
      <div className="col-md-12">
        <FormControlLabel
          control={
            <Switch
              size={'small'}
              id="enabled"
              checked={!!restObject?.enabled || false}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          }
          label={'Enabled'}
        />
      </div>
      <CCFormInput
        id="title"
        colNumber={12}
        label="Title"
        value={restObject.title}
        onChange={handleChangeObject}
        placeholder="Enter a title...."
        required
      />
      <CCTextArea
        id="description"
        colNumber={12}
        label="Description"
        value={restObject.description}
        onChange={handleChangeObject}
        placeholder="Enter a description...."
        required
      />
      <CustomReactSelect
        id="subscription"
        value={restObject.subscription}
        onChangeEvent={handleChangeObject}
        selectType={selectTypes.object}
        label="Subscription Level"
        options={customOptions.allPlanLevels}
        getOptionValue={(option) => option?.id}
        getOptionLabel={(option) => option?.name}
      />
      <EntityBasedImageUploadComponent
        disabled={disabled}
        entity={entity}
        entityObject={restObject}
        handleChange={handleChangeObject}
      />
    </>
  )
}
