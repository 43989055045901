import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { CustomSelect } from 'src/views/plugins/calendar/components/CustomSelect'
import {
  ImageDialogBackButton,
  ImageDialogCloseButton,
  ImageUploadContext,
} from './imageUpload/ImageUpload'
import {
  GalleryFiltersContainer,
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from './styledComponents'

GalleryFilters.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  selectedTags: PropTypes.array,
  setSelectedTags: PropTypes.func,
}

export default function GalleryFilters({ search, setSearch, selectedTags, setSelectedTags }) {
  const ImageContext = useContext(ImageUploadContext)
  const [store, dispatch] = useAppSettingsStore()
  const allTags = store.tags
  function handleSearchChange(event) {
    setSearch(event.target.value)
  }
  function handleTagChange(selectedValues) {
    setSelectedTags(selectedValues)
  }
  return (
    <GalleryFiltersContainer>
      <div className="d-flex justify-content-start align-items-center gap-3">
        {ImageContext.pageStack.length > 0 && <ImageDialogBackButton />}
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            defaultValue={search}
            onChange={handleSearchChange}
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
        <CustomSelect
          isMulti
          value={selectedTags}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          options={allTags}
          placeholder="Tags..."
          onChange={handleTagChange}
        />
      </div>
      <ImageDialogCloseButton />
    </GalleryFiltersContainer>
  )
}
