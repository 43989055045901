import styled from 'styled-components'

export const PMPParent = styled.div`
  margin: 20px auto;
  border: ${(props) => (props?.noBorder ? '' : '1px solid #000')};
  width: 100%;
  max-width: 794px;
  // min-height: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  background: #fff;
`

export const PMPHeadingBanner = styled.div`
  width: 100%;
  background: ${(props) => (props?.noColor ? '#FFF' : '#397c73')};
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
  border-bottom: 1px solid #000;
  letter-spacing: 1.5px;
  font-weight: 700;
  font-size: 18px;
  padding: 2px;
`

export const PMPHeaderLeftPart = styled.div`
  width: 50%;
  padding: 5px 20px;
`

export const PMPHeaderDoctorText = styled.div`
  color: ${(props) => (props?.main ? '#000' : '#080808')};
  font-weight: ${(props) => (props?.main ? '600' : '400')};
  font-size: ${(props) => (props?.main ? '14px' : '12px')};
  ${(props) => props?.textRight && `text-align: right`};
`

export const PMPHeaderRightPart = styled.div`
  width: 50%;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const PMPHeaderDetails = styled.div`
  background: ${(props) => (props?.noColor ? '#FFF' : '#c9eee8')};
  padding: 5px 20px;
  color: #000;
  border-bottom: 1px solid #000;
`

export const PMPFooterContainer = styled.div`
  background: ${({ noColor, accentColor = '#397c73' }) => (noColor ? '#FFF' : accentColor)};
  padding: 5px 20px;
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-top: 1px solid #000;
`

export const PMPThreeColumnFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid #000;
  ${(props) => props?.printType !== 'withHeadingAndBottomInformation' && 'display: none;'};
  background: ${(props) => (props?.noColor ? '#FFF' : '#397c73')};
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
`

export const PMPColumnedFooter = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props?.columns || 3}, 1fr)`};
  border-top: 1px solid #000;
  background: ${(props) => (props?.noColor ? '#FFF' : '#397c73')};
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
`
