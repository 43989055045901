import { ContentCopy } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import React from 'react'
import { copyToClipBoard } from 'src/utilities/generalUtils'

export default function CopyIosIconIdMenuItem({ tabItem, handleClose }) {
  return (
    <>
      <MenuItem
        onClick={() => {
          copyToClipBoard(`iOS Icon ID - ${tabItem?.icon}`, tabItem?.icon)
          handleClose()
        }}
        disableRipple
      >
        <ContentCopy />
        Copy iOS Icon ID
      </MenuItem>
    </>
  )
}
