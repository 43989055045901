import moment from 'moment'
import React from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import PaginatedListingTableHandlerWrapper from '../../PaginatedListingTableHandlerWrapper'

export default function UserPlans({ userId, data, setData }) {
  return (
    <PaginatedListingTableHandlerWrapper
      apiCallFunction={GetSettingsData}
      apiCallParams={{ userId: userId, size: 200 }}
      apiCallUrl={USERS_API_SIGNATURES_MAP.usageDetails.plans.get}
      data={data}
      setData={setData}
      setupDataDependencies={[userId]}
    >
      <CCExtendedCSmartTable
        noUserNames
        items={data?.data}
        loading={data?.status === 'loading'}
        columns={columns}
        scopedColumns={scopedColumns}
        excludeGenericFields={[
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ]}
      />
    </PaginatedListingTableHandlerWrapper>
  )
}

const columns = [
  { key: 'createdTime', label: 'Created Time', sorter: false, filter: false },
  { key: 'assignedDays', label: 'No of Days', sorter: false, filter: false },
  { key: 'event', label: 'Event', sorter: false, filter: false },
  { key: 'state', label: 'State', sorter: false, filter: false },
  { key: 'completedOn', label: 'Completed On', sorter: false, filter: false },
]

const scopedColumns = {
  completedOn: (item) => {
    return (
      <td>
        {item?.completedOn
          ? moment(item?.completedOn).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)
          : '--'}
      </td>
    )
  },
  createdTime: (item) => {
    return (
      <td>
        {moment(item?.createdTime).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
      </td>
    )
  },
  event: (item) => {
    return <td>{item?.event === 'auto_renewal' ? 'Renewal' : item?.event || '--'}</td>
  },
}
