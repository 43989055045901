import { Checkbox, FormControlLabel, Grid, Switch } from '@mui/material'
import { useEffect } from 'react'
import {
  CCFormInput,
  CCFormInputPhoneNumber,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { ESCALATION_LEVEL, SUPPORT_MODE } from 'src/utilities/constants'

export default function SupportUserComponent({
  initialObject,
  imageObject: supportUser,
  setImageObject: setSupportUser,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
}) {
  useEffect(() => {
    console.log({ supportUser })
  }, [supportUser])

  return (
    <div className="d-flex">
      <Grid container spacing={2}>
        <Grid item xs={12} key={'switch'}>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                id="enabled"
                checked={!!supportUser?.enabled || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={!!supportUser?.enabled ? 'Enabled' : 'Disabled'}
          />
        </Grid>
        <Grid item xs={12}>
          <CCFormInput
            id={'screenName'}
            label="Screen Name"
            value={supportUser?.screenName}
            onChange={handleChangeObject}
            placeholder={'Enter Screen Name...'}
            disabled={disabled}
            required
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomReactSelect
            isMulti
            id="supportMode"
            value={supportUser?.supportMode}
            onChangeEvent={handleChangeObject}
            label="Support Mode"
            selectType={selectTypes.string}
            options={SUPPORT_MODE}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.title}
            disabled={disabled}
          />
        </Grid>
        {(SUPPORT_MODE.filter((mode) => supportUser?.supportMode?.includes(mode?.id)) || []).map(
          (modeOptions) => (
            <Grid item xs={12} key={`${modeOptions?.id}`}>
              {modeOptions?.id === 'supportPhoneNumber' ? (
                <CCFormInputPhoneNumber
                  id={modeOptions?.id}
                  label={modeOptions?.subTitle}
                  value={supportUser?.[modeOptions?.id]}
                  onChange={(data) => setSupportUser({ ...supportUser, [modeOptions?.id]: data })}
                  placeholder={`Enter ${modeOptions?.subTitle}...`}
                  disabled={disabled}
                  required
                  colNumber={12}
                  type={'number'}
                />
              ) : (
                <CCFormInput
                  id={modeOptions?.id}
                  label={modeOptions?.subTitle}
                  value={supportUser?.[modeOptions?.id]}
                  onChange={(event) =>
                    setSupportUser({ ...supportUser, [modeOptions?.id]: event.target.value })
                  }
                  placeholder={`Enter ${modeOptions?.subTitle}...`}
                  disabled={disabled}
                  required
                  colNumber={12}
                />
              )}
            </Grid>
          ),
        )}
        {/* <Grid item xs={12}>
          <CCFormInput
            id={'supportWhatsappId'}
            label="Whatsapp Id"
            value={supportUser?.supportWhatsappId}
            onChange={handleChangeObject}
            placeholder={'Enter Support Whatsapp Id...'}
            disabled={disabled}
            required
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <CCFormInput
            id={'supportEmail'}
            label="Email Id"
            value={supportUser?.supportEmail}
            onChange={handleChangeObject}
            placeholder={'Enter Support Email Id...'}
            disabled={disabled}
            required
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <CCFormInputPhoneNumber
            id={'supportPhoneNumber'}
            label="Phone Number"
            country={'IN'}
            value={supportUser?.supportPhoneNumber}
            onChange={(supportPhoneNumber) => setSupportUser({ ...supportUser, supportPhoneNumber })}
            placeholder={'Enter Phone Number...'}
            disabled={disabled}
            required
            colNumber={12}
            type={'number'}
          />
        </Grid> */}
        <Grid item xs={12}>
          <CustomReactSelect
            isMulti
            id="affiliation"
            value={supportUser?.affiliation}
            onChangeEvent={handleChangeObject}
            label="Affiliation"
            selectType={selectTypes.string}
            options={customOptions?.clinics} // Update options
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.title}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomReactSelect
            id="escalationLevel"
            value={supportUser?.escalationLevel}
            onChangeEvent={handleChangeObject}
            label="Escalation Level"
            selectType={selectTypes.string}
            options={ESCALATION_LEVEL} // Update options
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.title}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                id="cloudClinic"
                checked={supportUser?.openToAll}
                onChange={() =>
                  setSupportUser({ ...supportUser, openToAll: !supportUser?.openToAll })
                }
              />
            }
            label="Open To All: "
          />
        </Grid>
      </Grid>
    </div>
  )
}
