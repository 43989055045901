import { Http, Link } from '@mui/icons-material'
import React from 'react'
import { LINK_TYPES } from 'src/utilities/constants'
import {
  DescriptionSelectionButton,
  DescriptionSelectionButtonsContainer,
} from 'src/views/userFeatures/activityPlan/components/styled-components'

export default function ChooseLinkTypeComponent({ externalLinksData, addPage }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center gap-4 h-100">
      {externalLinksData.uri && (
        <span className="d-flex flex-column align-items-center justify-content-center gap-2">
          <strong>Current Link</strong>
          <span style={{ textAlign: 'center' }}>{externalLinksData.uri}</span>
        </span>
      )}
      <DescriptionSelectionButtonsContainer>
        <DescriptionSelectionButton color="secondary" onClick={() => addPage(LINK_TYPES.deep)}>
          <Link sx={{ fontSize: '40px' }} />
          <div className="d-flex flex-column align-items-center justify-content-center gap-1 h-50">
            <strong>Deep Link</strong>
            <small>Opens exactly the items you have selected, regardless the device.</small>
          </div>
        </DescriptionSelectionButton>
        <DescriptionSelectionButton color="secondary" onClick={() => addPage(LINK_TYPES.hyperlink)}>
          <Http sx={{ fontSize: '40px' }} />
          <div className="d-flex flex-column align-items-center justify-content-start gap-1 h-50">
            <strong>Custom Link</strong>
            <small>Customized links created by you.</small>
          </div>
        </DescriptionSelectionButton>
      </DescriptionSelectionButtonsContainer>
    </div>
  )
}
