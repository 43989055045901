import { CFormLabel } from '@coreui/react-pro'
import { Info } from '@mui/icons-material'
import {
  ButtonBase,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import CCRichTextEditor from 'src/components/controlCenter/CCRichTextEditor'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import TrackUploadComponent from 'src/components/controlCenter/common/trackAndMedia/TrackUploadComponent'
import { RichTextContentWrapper } from 'src/components/controlCenter/styledComponents'
import { checkIfStringIsValid, getCorrectTargetValueForEvent } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { CustomSelect } from 'src/views/plugins/calendar/components/CustomSelect'
import {
  categoryToDailyPlayType,
  getOptions,
  getReflectionData,
  returnLoadOptions,
  searchCourseEpisodes,
} from 'src/views/plugins/calendar/event-utils'
import { getActivityObject } from 'src/views/subscriptions/customPlans/utils'
import { TrackResponse } from '../../../common/types/TrackClass'
import ActivityDescriptionSelection from './components/ActivityDescriptionSelection'
import {
  ActivityItemAsyncSelect,
  DescriptionSelectionDialogStyles,
  DetailsInTextDialogStyles,
} from './components/styled-components'

function ActivityComponent({
  initialObject = {},
  imageObject: activityObject,
  setImageObject: setActivityObject,
  handleChangeObject,
  customOptions = {},
  disabled = false,
  view = 'default',
  day,
  children,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const [loading, setLoading] = useState({ activityId: false, activityTitle: false })
  const [courseOptions, setCourseOptions] = useState()
  const [showDescriptionModal, setShowDescriptionModal] = useState(false)
  const dropDownOptions = {
    ...store,
    ...customOptions,
  }
  const [activityOptionsFilters, setActivityOptionsFilters] = useState({
    languages: [],
  })

  const allCategories = dropDownOptions.categories
    ?.filter((category) => {
      const foundCategoryType = store.metaCategoryTypes?.find(
        (type) => type.id === category?.categoryType,
      )
      return !!foundCategoryType?.enabledForDailyPlay && !!category?.enabled
    })
    ?.sort((cat1, cat2) => sortFn(cat1?.title, cat2.title))
  const selectedCategory = allCategories.find((cat) => cat.id === activityObject?.metaCategoryId)

  const descriptionSentence = `We are offering you ${activityObject?.customCategoryTitle?.toLowerCase()} ${
    day !== undefined && `on day ${day}`
  } so as to ${activityObject?.descriptionZ?.toLowerCase()} because ${activityObject?.descriptionW?.toLowerCase()}`

  async function changeActivityType(event) {
    handleChangeObject(event)
    handleChangeObject({
      target: {
        value: null,
        id: 'activityId',
      },
    })
  }

  async function loadOptions(inputValue, callback, { page, size }) {
    // tempCard.type === 'course' ? cardLanguages : languages,
    setLoading((prev) => ({ ...prev, activityId: true }))
    const dpType = categoryToDailyPlayType(activityObject?.metaCategoryId, dropDownOptions)
    const foundEnglish = dropDownOptions.languages.find((lang) => lang.id === 'en')
    let dropDownOptionsData
    if (courseOptions === undefined) {
      dropDownOptionsData = await getOptions(
        dpType,
        activityObject?.metaCategoryId,
        activityOptionsFilters?.languages?.length > 0
          ? activityOptionsFilters?.languages
          : [foundEnglish],
        size,
        page,
        inputValue,
        false,
      )
      dpType === 'course' && setCourseOptions(dropDownOptionsData)
    } else {
      dropDownOptionsData = await searchCourseEpisodes(inputValue, courseOptions)
    }
    setLoading((prev) => ({ ...prev, activityId: false }))
    return returnLoadOptions(dropDownOptionsData)
  }

  async function changeActivityId(selectedValue) {
    setLoading((prev) => ({ ...prev, activityId: true }))
    const selectedCategory = allCategories.find((cat) => cat.id === activityObject?.metaCategoryId)
    let currentCategoryTitle = activityObject?.customCategoryTitle
    const customFields =
      selectedCategory?.categoryType === 'course' ? { courseId: selectedValue?.course?.id } : {}
    if (selectedCategory?.categoryType === 'quotes') {
      const foundReflection = await getReflectionData(selectedValue?.journalId)
      currentCategoryTitle = foundReflection?.title
    }

    setLoading((prev) => ({ ...prev, activityId: false }))
    setActivityObject((prev) => ({
      ...prev,
      activityId: selectedValue,
      customFields: customFields,
      customCategoryTitle: currentCategoryTitle,
    }))
  }

  function handleChangeTrack(eventPrev) {
    if (typeof eventPrev === 'object') {
      setActivityObject((prev) => {
        return {
          ...prev,
          track: {
            ...(prev?.track || TrackResponse),
            [eventPrev.target.id]: getCorrectTargetValueForEvent(eventPrev),
          },
        }
      })
    } else {
      setActivityObject((prev) => ({
        ...prev,
        track: eventPrev(prev?.track || {}),
      }))
    }
  }

  function handleChangePostInstructionsTrack(eventPrev) {
    if (typeof eventPrev === 'object') {
      setActivityObject((prev) => {
        return {
          ...prev,
          postInstructionsVideo: {
            ...(prev?.postInstructionsVideo || TrackResponse),
            [eventPrev.target.id]: getCorrectTargetValueForEvent(eventPrev),
          },
        }
      })
    } else {
      setActivityObject((prev) => ({
        ...prev,
        postInstructionsVideo: eventPrev(prev?.postInstructionsVideo || {}),
      }))
    }
  }

  async function Setup() {
    if (!!activityObject?.activityId) {
      setLoading((prev) => ({ ...prev, activityId: true }))
      const isIdTitleAlreadyThere = typeof activityObject?.activityId === 'object'
      let foundActivityId = activityObject?.activityId
      if (!isIdTitleAlreadyThere) {
        foundActivityId = await getActivityObject(
          activityObject,
          activityObject?.metaCategoryId,
          dropDownOptions,
        )
      }
      setActivityObject((prev) => ({
        ...prev,
        activityId: foundActivityId,
      }))
      setLoading((prev) => ({ ...prev, activityId: false }))
    }
  }

  useEffect(() => {
    console.log({ allCategories })
  }, [allCategories])

  useEffect(() => {
    Setup()
  }, [activityObject.id])

  useEffect(() => {
    setCourseOptions(undefined)
  }, [activityObject?.metaCategoryId, activityOptionsFilters.languages])

  useEffect(() => {
    setActivityObject((prev) => ({
      ...prev,
      descriptionSentence: descriptionSentence,
    }))
  }, [activityObject.customCategoryTitle, activityObject.descriptionZ, activityObject.descriptionW])

  return disabled ? (
    <ActivityComponentReadOnly imageObject={activityObject} allCategories={allCategories} />
  ) : (
    <div className="row g-2">
      {view === 'default' && (
        <div className="col-md-12">
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                id="enabled"
                checked={!!activityObject?.enabled || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={'Enabled'}
          />
        </div>
      )}
      <hr></hr>
      <SectionComponent
        heading="Card Details"
        subHeading="These details will be visible in the list view"
        first
      >
        <CCFormInput
          id="activityTitle"
          coreUiGridItem
          colNumber={6}
          label="Title (as visible to user)"
          value={activityObject.activityTitle}
          onChange={handleChangeObject}
          disabled={disabled}
          placeholder="Enter activity title...."
          invalid={!checkIfStringIsValid(activityObject.activityTitle)}
          required
        />
        <CCFormInput
          id="customCategoryTitle"
          coreUiGridItem
          colNumber={6}
          label="Activity Type"
          value={activityObject.customCategoryTitle}
          onChange={handleChangeObject}
          disabled={disabled}
          invalid={!checkIfStringIsValid(activityObject.customCategoryTitle)}
          placeholder="Enter Activity Type...."
          required
        />
      </SectionComponent>
      <SectionComponent
        heading="On Tap Details"
        subHeading="These details are shown after the user taps on an activity."
      >
        <ButtonBase
          sx={{
            padding: '10px',
            borderRadius: '7px',
            alignItems: 'flex-start',
            justifyContent: 'center',
            textAlign: 'left',
          }}
          onClick={() => {
            setShowDescriptionModal(true)
          }}
        >
          <span style={{ width: '100%' }}>
            <strong>Description Sentence: </strong>
            {activityObject.customDescription ? (
              <span>{activityObject.customDescription}</span>
            ) : (
              <span>
                We are offering you{' '}
                <strong style={underLineStyled}>
                  {activityObject.customCategoryTitle?.toLowerCase() ||
                    'Custom Activity Type Title'}
                </strong>{' '}
                {day !== undefined && (
                  <>
                    on <strong>day {day} </strong>
                  </>
                )}
                so as to{' '}
                <strong style={underLineStyled}>
                  {activityObject.descriptionZ?.toLowerCase() ||
                    'What will this activity do to you'}
                </strong>{' '}
                because{' '}
                <strong style={underLineStyled}>
                  {activityObject.descriptionW?.toLowerCase() || 'Why am I offering you this today'}
                </strong>
              </span>
            )}
          </span>
        </ButtonBase>
      </SectionComponent>
      <SectionComponent
        heading="Activity Details"
        subHeading="Pre-Instructions to get the user prepared for the activity."
      >
        <div style={{ zIndex: 0 }} className="col-md-12">
          <div className="d-flex flex-column gap-2 mb-3">
            <h5 className="mb-0">Pre-Instructions in Audio/Video Format</h5>
            <div className="d-flex gap-1 text-info fs-7 align-items-center">
              <span>This is prioritized over text</span>
              <Info />
            </div>
          </div>
          <TrackUploadComponent
            trackObject={activityObject.track}
            handleChange={handleChangeTrack}
            disabled={disabled}
          />
        </div>
        <div style={{ height: '400px', zIndex: 0 }} className="col-md-12 mb-4 p-1">
          <h5 className="mb-3">Pre Instructions in Text Format</h5>
          <CCRichTextEditor
            disabled={disabled}
            id="content"
            handleChangeHtml={handleChangeObject}
            htmlContent={activityObject.content}
            initialContent={initialObject.content}
          />
        </div>
        <div style={{ zIndex: 0 }} className="col-md-12 p-1 mt-4">
          <div className="d-flex flex-column gap-2 mb-3">
            <h5 className="mb-0">Post Instructions in Audio/Video Format</h5>
            <div className="d-flex gap-1 text-info fs-7 align-items-center">
              <span>This is prioritized over text</span>
              <Info />
            </div>
          </div>
          <TrackUploadComponent
            trackObject={activityObject.postInstructionsVideo}
            handleChange={handleChangePostInstructionsTrack}
            disabled={disabled}
          />
        </div>
        <div style={{ height: '400px', zIndex: 0 }} className="col-md-12 mb-4 p-1">
          <h5>Post Instructions in Text Format</h5>
          <CCRichTextEditor
            disabled={disabled}
            id="postInstructionsContent"
            handleChangeHtml={handleChangeObject}
            htmlContent={activityObject.postInstructionsContent}
            initialContent={initialObject.postInstructionsContent}
          />
        </div>
      </SectionComponent>
      <SectionComponent
        heading="Actual Aid Used"
        subHeading="The actual AUMHUM feature that will help the user to do this activity"
      >
        <div className="col-md-12">
          <CustomReactSelect
            id="metaCategoryId"
            value={activityObject?.metaCategoryId}
            options={allCategories}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
            label="Category"
            selectType={selectTypes.string}
            onChangeEvent={changeActivityType}
            disabled={disabled}
            required
            invalid={!checkIfStringIsValid(activityObject?.metaCategoryId)}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
          />
        </div>
        <Collapse orientation="vertical" in={selectedCategory?.categoryType === 'course'}>
          <div className="col-md-12">
            <label className="p-1">Content Languages</label>
            <CustomSelect
              menuPortalTarget={document.body}
              isMulti
              value={activityOptionsFilters.languages}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.languageName}
              options={dropDownOptions.languages}
              onChange={(value) => {
                setActivityOptionsFilters((prev) => ({ ...prev, languages: value }))
                setCourseOptions()
              }}
              placeholder="Select Language"
            />
          </div>
        </Collapse>
        <div className="col-md-12 d-flex flex-column align-items-stretch">
          <CFormLabel htmlFor="activityItem">Aid</CFormLabel>
          <ActivityItemAsyncSelect
            classNamePrefix="activity-item-select"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            cacheUniqs={[activityObject?.metaCategoryId, activityOptionsFilters]}
            id="activityItem"
            value={activityObject?.activityId}
            loadOptions={loadOptions}
            isLoading={loading?.activityId}
            debounceTimeout={
              categoryToDailyPlayType(activityObject?.metaCategoryId, dropDownOptions) === 'course'
                ? 0
                : 500
            }
            additional={{ page: 0, size: 100 }}
            isDisabled={disabled}
            onChange={changeActivityId}
            placeholder={`Select a ${
              selectedCategory?.singleTitle || selectedCategory?.title || 'Aid'
            }`}
            // invalid={!activityObject?.activityId}
          />
        </div>
      </SectionComponent>
      <SectionComponent heading="More...">
        <CCTextArea
          id="whatItDoes"
          colNumber={6}
          label="What it does"
          value={activityObject.whatItDoes}
          disabled={disabled}
          onChange={handleChangeObject}
          placeholder="Enter details...."
        />
        <CCTextArea
          id="whyOffered"
          colNumber={6}
          label="Why offered"
          value={activityObject.whyOffered}
          disabled={disabled}
          onChange={handleChangeObject}
          placeholder="Enter details...."
        />
        <CCTextArea
          id="internalNote"
          colNumber={12}
          label="Internal Notes"
          value={activityObject.internalNote}
          disabled={disabled}
          onChange={handleChangeObject}
          placeholder="Enter your notes...."
        />
        {children}
      </SectionComponent>
      <Dialog
        PaperComponent={DescriptionSelectionDialogStyles}
        open={showDescriptionModal}
        onClose={() => setShowDescriptionModal(false)}
      >
        <ActivityDescriptionSelection
          mainActivityObject={activityObject}
          disabled={disabled}
          handleChangeMainActivityObject={handleChangeObject}
          closeModal={() => setShowDescriptionModal(false)}
          day={day}
        />
      </Dialog>
    </div>
  )
}

function ActivityComponentReadOnly({ imageObject, allCategories }) {
  const [showDetailsInText, setShowDetailsInText] = useState(false)
  const [showPostTrackDetailsInText, setShowPostTrackDetailsInText] = useState(false)
  const selectedCategory = allCategories.find((cat) => cat.id === imageObject?.metaCategoryId)

  return (
    <div className="row g-2">
      <SectionComponent
        heading="On Tap Details"
        subHeading="These details are shown after the user taps on an activity."
      >
        <div className="py-2">
          {checkIfStringIsValid(imageObject.customDescription)
            ? imageObject.customDescription
            : imageObject.descriptionSentence}
        </div>
      </SectionComponent>
      <SectionComponent
        heading="Activity Details"
        subHeading="Pre And Post Instructions to get the user prepared for the activity."
      >
        <h5>Pre Instructions</h5>
        <div style={{ zIndex: 0 }} className="col-md-12">
          <TrackUploadComponent trackObject={imageObject.track} entityName="Activity" disabled />
        </div>
        <div className="col-md-12 mt-2">
          <ButtonBase
            disableTouchRipple
            onClick={() => {
              setShowDetailsInText(true)
            }}
            sx={{ color: 'blue', textDecoration: 'underline' }}
          >
            Details in Text
          </ButtonBase>
        </div>
        <h5 className="mt-4">Post Instructions Instructions</h5>
        <div className="col-md-12">
          <TrackUploadComponent trackObject={imageObject.track} entityName="Activity" disabled />
        </div>
        <div className="col-md-12 mt-2">
          <ButtonBase
            disableTouchRipple
            onClick={() => {
              setShowPostTrackDetailsInText(true)
            }}
            sx={{ color: 'blue', textDecoration: 'underline' }}
          >
            Details in Text
          </ButtonBase>
        </div>
      </SectionComponent>
      <SectionComponent
        heading="Actual Aid Used"
        subHeading="The actual AUMHUM feature that will help the user to do this activity"
      >
        <div className="col-md-12">
          <strong>Category</strong>: {selectedCategory?.title}
        </div>
        <div className="col-md-12 mt-2">
          <strong>Aid</strong>: {imageObject?.activityId?.label || 'Loading...'}
        </div>
      </SectionComponent>
      <SectionComponent heading="More...">
        <div className="col-md-12">
          <strong>What it does</strong>: {imageObject.whatItDoes}
        </div>
        <div className="col-md-12 mt-2">
          <strong>Why offered</strong>: {imageObject.whyOffered}
        </div>
        <div className="col-md-12 mt-2">
          <strong>Internal Notes</strong>: {imageObject.internalNote}
        </div>
      </SectionComponent>
      <Dialog
        PaperComponent={DetailsInTextDialogStyles}
        open={showDetailsInText}
        onClose={() => setShowDetailsInText(false)}
      >
        <DialogTitle>Pre Instructions Details In Text</DialogTitle>
        <DialogContent>
          {!!imageObject?.content ? (
            <RichTextContentWrapper>{parse(imageObject?.content || '')}</RichTextContentWrapper>
          ) : (
            <div>No Content</div>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        PaperComponent={DetailsInTextDialogStyles}
        open={showPostTrackDetailsInText}
        onClose={() => setShowPostTrackDetailsInText(false)}
      >
        <DialogTitle>Post Instructions Details In Text</DialogTitle>
        <DialogContent>
          {!!imageObject?.postInstructionsContent ? (
            <RichTextContentWrapper>
              {parse(imageObject?.postInstructionsContent || '')}
            </RichTextContentWrapper>
          ) : (
            <div>No Content</div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
function SectionComponent({ heading, subHeading, first, children }) {
  return (
    <div className="row col-md-12 py-2 w-100">
      {!first && <hr></hr>}
      {heading && (
        <div className="col-md-12 pb-2 d-flex flex-column mb-3">
          <h4>{heading}</h4>
          {subHeading && <span className="fs-7 fw-light">{subHeading}</span>}
        </div>
      )}
      {children}
    </div>
  )
}
export default React.memo(ActivityComponent)

const underLineStyled = {
  textDecoration: 'underline',
}
