import { IdTypes } from 'src/utilities/constants'
import { getExtraItemTitleForTypeId } from './forID'

export async function getExtraItemTitlesForAnyIdType(
  itemId,
  type,
  metaCategoryType,
  extraItemId,
  extraItemCategoryType,
  extraItemIdType,
  baseItem,
) {
  switch (type) {
    case IdTypes.type:
      // return getItemTitleForTypeType(itemId, metaCategoryType)
      return null
    case IdTypes.slug:
    case IdTypes.id:
      return getExtraItemTitleForTypeId(
        itemId,
        metaCategoryType,
        extraItemId,
        extraItemCategoryType,
        extraItemIdType,
        baseItem,
      )
    default:
      return null
  }
}
