import { Delete, Edit } from '@mui/icons-material'
import { Checkbox, Dialog, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { selectSummaryListOfConsumerPlans } from 'src/reducers/subscriptions/subscriptionReducer'
import { SUBSCRIPTION_IAPS } from 'src/utilities/constants'
import EditPackageItem from './EditPackageItem'
import { AddPackageModalPaper } from './styledComponents'

export default function PackageItem({
  paywallPackageItem,
  paywallPackageItemIdx,
  handleChangeItem,
  handleDeletePackageItem,
  formMode,
}) {
  const [open, setOpen] = useState(false)
  const [conformationModalProps, setConfirmationProps] = useState(defaultConfirmationModalProps)

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  function handleDeleteButton() {
    setConfirmationProps({
      ...conformationModalProps,
      visibility: true,
      action: 'delete',
      body: 'Are you sure you want to delete this package item?',
      onSubmitFunctions: [
        () => {
          handleDeletePackageItem()
        },
      ],
      visibilitySetter: setConfirmationProps,
      buttonColor: 'error',
    })
  }

  const consumerSubscriptionPlans = useSelector(selectSummaryListOfConsumerPlans)
  const currentSubPlan = consumerSubscriptionPlans?.data?.find((item) => {
    return item?.id === paywallPackageItem?.planId
  })

  function getIap(type) {
    switch (type) {
      case 'android':
        return (
          currentSubPlan?.pymntGtyInfo?.find(
            (item) => item?.pymntGtyId === SUBSCRIPTION_IAPS.android,
          )?.pymntGtyProductId || 'N/A'
        )
      case 'ios':
        return (
          currentSubPlan?.pymntGtyInfo?.find((item) => item?.pymntGtyId === SUBSCRIPTION_IAPS.ios)
            ?.pymntGtyProductId || 'N/A'
        )
      case 'gift':
        return (
          currentSubPlan?.pymntGtyInfo?.find(
            (item) => item?.pymntGtyId === SUBSCRIPTION_IAPS.giftUp,
          )?.pymntGtyProductId || 'N/A'
        )
      default:
        return null
    }
  }

  useEffect(() => {
    console.log(consumerSubscriptionPlans)
  }, [consumerSubscriptionPlans])

  return (
    <>
      <div className="d-flex align-items-center">
        <div>
          <h6 className="d-flex flex-column justify-content-center w-100">
            <span>
              {currentSubPlan?.title} | ({currentSubPlan?.internalTitle})
            </span>
            <span className="fw-normal mt-2">{paywallPackageItem?.title || 'No Custom Title'}</span>
          </h6>
        </div>
        {formMode !== 'View' && (
          <>
            <IconButton onClick={handleClickOpen} disabled={formMode === 'View'}>
              <Edit />
            </IconButton>
            <IconButton onClick={handleDeleteButton}>
              <Delete />
            </IconButton>
          </>
        )}
      </div>
      <br />
      <div className="fs-6">
        <b>Android Payment Gateway:</b> {getIap('android')}
        <br />
        <b>iOS Payment Gateway:</b> {getIap('ios')}
        <br />
        <b>Gift Up Payment Gateway:</b> {getIap('gift')}
        <br />
        <b>Price:</b> {paywallPackageItem?.price?.price}
        <br />
        <b>Discount:</b> {paywallPackageItem?.price?.discount}
        <br />
        <b>Final Price: </b>
        {paywallPackageItem?.price?.finalPrice}
        <br />
        <b>Popular:</b>{' '}
        <Checkbox checked={paywallPackageItem?.popular || false} disabled color="primary" />
        <b>Preselected:</b>{' '}
        <Checkbox checked={paywallPackageItem?.preselected || false} disabled color="primary" />
        <br />
      </div>
      <ConfirmationModal newImplementation {...conformationModalProps} />
      <Dialog PaperComponent={AddPackageModalPaper} open={open} onClose={handleClose}>
        <EditPackageItem
          packageItem={paywallPackageItem}
          handleChangeObjectItem={handleChangeItem}
          handleClose={handleClose}
        />
      </Dialog>
    </>
  )
}
