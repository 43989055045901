import { revertKeyValuePairArrayToKeyValueToObjectFormat } from '../common/commonConfigHelpers'

export async function transformSendInAppMsgData(formedData, customStoreData) {
  return {
    ...formedData,
    criteria: {
      ...(formedData?.criteria || {}),
      teacherIds: formedData?.criteria?.teacherIds?.map((teacher) => teacher.id),
      courseIds: formedData?.criteria?.courseIds?.map((course) => course.id),
    },
    templateData: {
      ...(formedData?.templateData || {}),
      metaInfo: revertKeyValuePairArrayToKeyValueToObjectFormat(formedData?.templateData?.metaInfo),
    },
    translations: {
      en: {
        title: formedData?.templateData?.title,
        subText: formedData?.templateData?.subText,
        actionBtnText: formedData?.templateData?.actionBtnText,
        extraLinkText: formedData?.templateData?.extraLinkText,
        textBoxText: formedData?.templateData?.textBoxText,
      },
    },
  }
}
