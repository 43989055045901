import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import {
  FeatureImagesContainer,
  FeatureImageUploadContainer,
} from 'src/views/features/journals/components/styledComponents'
import { disableForContentWriter } from '../../../../config/common/genericListingAndFormConfig'
import ImageUpload from '../../imageUpload/ImageUpload'

const IMAGES_ALIGNMENT_MAP = {
  center: 'align-items-center',
  left: 'align-items-start',
  right: 'align-items-end',
}

export default function EntityBasedImageUploadComponent({
  entityObject,
  entity,
  handleChange,
  disabled: imageUploadDisabled,
  cuiBg = false,
  align = 'center',
  noBorder,
  noHeading,
  headingOverride,
}) {
  const [store] = useAppSettingsStore()
  const disabled = useMemo(() => {
    if (entity === 'article') return imageUploadDisabled
    else {
      return imageUploadDisabled || disableForContentWriter()
    }
  }, [entity, imageUploadDisabled])
  function handleImageChange(image, idField) {
    handleChange({ target: { id: idField, value: image } })
  }

  function getDarkModeBackground(theme) {
    return cuiBg ? 'var(--cui-body-bg)' : '#383838'
  }
  function getLightModeBackground(theme) {
    return cuiBg
      ? 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
      : theme.palette.background.paper
  }

  return (
    <>
      {filterImageTypes(store.imageTypes, entity)?.length > 0 && (
        <FeatureImagesContainer
          id="images"
          className={`col-md-12 border ${IMAGES_ALIGNMENT_MAP?.[align]} ${
            noBorder ? '' : 'border-primary'
          } mt-4`}
        >
          {!noHeading && (
            <Box
              className="position-absolute w-auto rounded-md"
              sx={{
                top: '-20px',
                left: '20px',
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? getDarkModeBackground(theme)
                    : getLightModeBackground(theme),
              }}
            >
              <h3 className="text-bold">{headingOverride || 'Images'}</h3>
            </Box>
          )}
          {filterImageTypes(store.imageTypes, entity)?.map((imageType) => (
            <FeatureImageUploadContainer key={imageType.id}>
              <ImageUpload
                imageId={entityObject?.[imageType?.id]}
                onChange={(image) => handleImageChange(image, imageType?.id)}
                title={imageType?.title}
                imageType={imageType?.id}
                imageTypeObject={imageType}
                disabled={disabled}
              />
            </FeatureImageUploadContainer>
          ))}
        </FeatureImagesContainer>
      )}
    </>
  )
}

export function filterImageTypes(imageTypes, entity) {
  return imageTypes?.filter((imageTypes) => {
    return (
      imageTypes?.enabledForFeatures?.some((feature) => feature === entity) && imageTypes.enabled
    )
  })
}
