export function putExtraDataForNotificationsToItsPositionDefaultHandling(notificationData, dlData) {
  const extraData = dlData?.extraData
  if (!!extraData?.itemId) {
    notificationData = {
      ...notificationData,
      customFields: {
        ...notificationData?.customFields,
        [extraData?.itemType]: extraData?.itemId,
      },
    }
  }
  return notificationData
}
