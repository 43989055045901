import React from 'react'
import { getMediaImageUrl } from 'src/services'
import { ALL_AVAILABLE_PLATFORMS } from 'src/utilities/constants'

export default function AppPlatformIcon({ appPlatformId, width }) {
  switch (appPlatformId) {
    case ALL_AVAILABLE_PLATFORMS.android:
      return (
        <img
          width={width}
          src={`${getMediaImageUrl()}MEDIA-e728ba10d1fb4978a31192eb7696e1e1`}
          alt="Android"
        />
      )
    case ALL_AVAILABLE_PLATFORMS.ios:
      return (
        <img
          width={width}
          src={`${getMediaImageUrl()}MEDIA-27cda15c47304b35be7453145b8773f9`}
          alt="iOS"
        />
      )
    case ALL_AVAILABLE_PLATFORMS.web:
      return (
        <img
          width={width}
          src={`${getMediaImageUrl()}MEDIA-9fe89a2c73534a3391a8e03207c3f0d2`}
          alt="Web"
        />
      )
    default:
      return (
        <img
          width={width}
          src={`${getMediaImageUrl()}MEDIA-27cda15c47304b35be7453145b8773f9`}
          alt="iOS"
        />
      )
    // return <img src="https://cdn.iconscout.com/icon/free/png-256/android-2038873-1720090.png" alt="Android" />
  }
}
