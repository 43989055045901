import React from 'react'
import { selectTypes } from 'src/components/controlCenter/FormComponents'
import { CustomSelect } from 'src/views/plugins/calendar/components/CustomSelect'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { loadCategoryTypeBasedItems } from '../../utils'
import ExtraOptionsComponent from './ExtraOptionsComponent'

export default function DLTypeCourse({
  item,
  handleChangeItem,
  dropDownOptions,
  setAdditionalData,
  additionalData,
  changeAdditionalItem,
  uiContextData,
}) {
  console.log({ uiContextData })

  return (
    <>
      <div>
        <label className="mb-2">Course Category</label>
        <CustomSelect
          menuPortalTarget={document.body}
          value={item?.value ? item : null}
          options={dropDownOptions.categories
            ?.filter((cat) => cat.categoryType === 'course')
            ?.sort((a, b) => a?.title.localeCompare(b?.title))
            .map((cat) => ({ value: cat.id, label: cat.title }))}
          onChange={handleChangeItem}
          selectType={selectTypes.object}
          placeholder="Select course category..."
        />
      </div>
      <ExtraOptionsComponent
        {...{ item, additionalData, changeAdditionalItem, setAdditionalData, uiContextData }}
      >
        <div className="pb-2">
          <label>{uiContextData?.extraDataItemIdSelectionLabel || 'Course Item'}</label>
          <ActivityItemAsyncSelect
            cacheUniqs={[item?.value, additionalData?.idType]}
            classNamePrefix="activity-item-select"
            menuPortalTarget={document.body}
            value={additionalData?.value ? additionalData : null}
            loadOptions={loadCategoryTypeBasedItems}
            debounceTimeout={1000}
            additional={{
              page: 0,
              size: 200,
              metaCategoryTypeId: 'course',
              additionalFilters: {
                category: item?.value,
              },
            }}
            onChange={(selVal) => changeAdditionalItem(selVal)}
          />
        </div>
      </ExtraOptionsComponent>
    </>
  )
}
