import { Avatar } from '@mui/material'
import { components } from 'react-select'
import { getMediaImageUrl } from 'src/services'

export const MentorOption = (props) => {
  const { children, ...rest } = props

  const currentOption = props.data

  return (
    <components.Option {...rest}>
      <div className="d-flex justify-content-start align-items-center gap-4">
        {!currentOption?.__isNew__ ? (
          <>
            <Avatar src={`${getMediaImageUrl()}${currentOption?.profilePicId}`} />
            <div className="d-flex flex-column align-items-start justify-content-center gap-1">
              {children}
              {currentOption?.email && (
                <span className="fw-light fs-8" span={{ margin: 0 }}>
                  {currentOption?.email}
                </span>
              )}
              {currentOption?.mobile && (
                <span className="fw-light fs-8" span={{ margin: 0 }}>
                  {currentOption?.mobile}
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            <Avatar />
            <span>Invite: {currentOption?.value}</span>
          </>
        )}
      </div>
    </components.Option>
  )
}
