import { SUBSCRIPTION_TYPES_LAYMAN } from 'src/utilities/constants'
import { getPaymentGateways, getUsablePricingMatrix } from 'src/views/subscriptions/plans/utils'

export async function transformInitialSubscriptionPlanData(responseData) {
  var paymentGateways = getPaymentGateways(responseData)
  let subscriptionToUse = { ...responseData }
  const englishTranslationsObject = responseData?.translations?.en
  const pricingMatrixToSet = getUsablePricingMatrix(responseData?.pricingMatrix)

  if (subscriptionToUse?.subscriptionPlanType === SUBSCRIPTION_TYPES_LAYMAN?.[1]?.id) {
    paymentGateways = [
      {
        pymntGtyId: 'free',
        pymntGtyProductId: '',
      },
      ...(paymentGateways || []),
    ]
  }

  subscriptionToUse = {
    ...subscriptionToUse,
    pymntGtyInfo: paymentGateways,
    pricingMatrix: pricingMatrixToSet,
    translations: {
      ...subscriptionToUse.translations,
      en: {
        ...englishTranslationsObject,
        title: subscriptionToUse?.title || '',
        description: subscriptionToUse?.description || '',
        subscriptionSuccessMsg: subscriptionToUse?.subscriptionSuccessMsg || '',
        benefits: subscriptionToUse?.benefits || [],
      },
    },
  }

  return subscriptionToUse
}
