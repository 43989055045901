import React, { useEffect } from 'react'

export default function CriteriaTopicsComponent({
  id,
  label,
  changeCriteriaObject,
  valueIsValid,
  children,
}) {
  useEffect(() => {
    if (valueIsValid) {
      changeCriteriaObject((prev) => ({
        ...prev,
        criteriaTopics: [...(prev?.criteriaTopics || [])?.filter((topic) => topic !== id), id],
      }))
    } else {
      changeCriteriaObject((prev) => ({
        ...prev,
        criteriaTopics: (prev?.criteriaTopics || [])?.filter((topic) => topic !== id),
      }))
    }
  }, [valueIsValid, id])

  return (
    <div className="w-100 d-flex flex-column gap-1">
      <div className="d-flex justify-content-between align-items-center">
        <span>{label}</span>
        {/* <div>{value?.length > 0 && <Chip size="small" label={value?.length} />}</div> */}
      </div>
      {children}
    </div>
  )
}
