import { ArrowBack } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, Fab } from '@mui/material'
import parse from 'html-react-parser'
import React, { createContext, useContext, useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { toast } from 'react-toastify'
import { PostImageData, UploadImage } from 'src/services/APIs/ImageUpload/Post'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { TableImageComponent } from 'src/views/settings/components/TableImage'
import uploadFromGalleryIcon from '../../../assets/from_gallery.png'
import uploadFromLocalIcon from '../../../assets/from_local.png'
import {
  IMAGE_GALLERY_PAGES,
  entityChooseGalleryTitle,
  entityPageSkip,
  formatUploadObject,
  getFullSaveObject,
  pageToRender,
} from '../../../config/common/genericListingAndFormConfig'
import {
  ButtonImage,
  ChooseUploadButtons,
  DialogBar,
  ImageContainer,
  ImageDimentionsContainer,
  ImageTypeContainer,
  UploadOrGalleryContainer,
} from '../styledComponents'
import { TOAST_UTILS } from '../toast-utils'
import ImageForm from './ImageForm'
import ImageGalleryChoose from './ImageGalleryChoose'
import ImageSizeSelect from './ImageSizeSelect'
import ImageUploadPage from './ImageUploadPage'
import { IMAGE_UPLOAD_UTILS } from './imageUploadUtils'

export const ImageUploadContext = createContext()

export async function fileUploadToApi(file, returnFullObject = false) {
  const formData = new FormData()
  formData.append('file', file)
  const response = await UploadImage(formData)
  if (response?.status === 200) {
    if (returnFullObject) {
      return response.data
    }
    return response.data.fileId
  } else {
    toast.error('Upload to database error')
    return 0
  }
  // uploading image, updating profile and updating profile data
}
export default function ImageUpload({
  imageId,
  imageObject,
  title,
  imageType,
  imageTypeObject,
  disabled,
  view,
  onChange,
  onChangeType = 'id',
  externalShowUploadModal,
  externalSetShowUploadModal,
  setup,
  chooseButtonRef,
  imageEntityType = 'images',
  galleryFilterValue,
  allImages = [],
}) {
  const [uploadedFile, setUploadedFile] = useState()
  const [routeStack, setRouteStack] = useState(['choose'])
  const [dialogDetails, setDialogDetails] = useState({
    scrollTop: 0,
    clientHeight: 0,
    scrollHeight: 0,
  })
  const [showUploadModal, setShowUploadModal] = useState(false)
  const showingUploadModalToUse = externalShowUploadModal || showUploadModal

  ///FORM STATES
  const [selectedType, setSelectedType] = useState()
  const [store, dispatch] = useAppSettingsStore()
  const allImageTypes = store.imageTypes
  const [loadingImage, setLoadingImage] = useState(true)

  async function GalleryUpload(file, id, type) {
    const response = await PostSettingsData(
      `${GENERIC_APIS.saveEntity.generic}${imageEntityType}`,
      {
        ...file,
        ...formatUploadObject(imageEntityType, id, type),
      },
    )
    if (response?.status === 200) {
      return response.data
    } else {
      return 0
    }
  }
  async function UploadColor(colors) {
    const response = await PostImageData(IMAGE_UPLOAD_UTILS.colorSave, colors)
    if (response?.status === 200) {
      return response.data
    } else {
      return 0
    }
  }

  function handleChange(imgId) {
    onChange(imgId)
    handleDialogClose()
  }

  async function handleSubmit(details) {
    var finalType = details?.type ? details?.type : imageObject?.type || imageType
    console.log({ finType: finalType })
    const id = toast.loading('Saving Images to gallery')
    let uploadedIds = [[finalType, imageId]]
    if (!(imageObject?.imageId || imageObject?.fileId))
      uploadedIds = await Promise.all(
        Object.entries(uploadedFile).map(async ([type, file]) => {
          const imgId = await fileUploadToApi(file)
          return [!!type ? type : finalType, imgId]
        }),
      )

    let colorsUploadResponse = null
    if (details.colors) {
      colorsUploadResponse = await UploadColor(details.colors)
    }

    console.log({ colorsUploadResponse: colorsUploadResponse, detailsColors: details.colors })

    if (!!colorsUploadResponse === !!details.colors) {
      const finalData = {
        ...details,
        colors: colorsUploadResponse,
      }
      console.log({ finalData: finalData })
      const submitMap = await Promise.all(
        uploadedIds.map(async ([type, imgId]) => {
          if (!imgId) {
            toast.update(id, {
              render: 'Something went wrong',
              type: toast.TYPE.ERROR,
              isLoading: false,
              ...TOAST_UTILS,
            })
            throw new Error('Image Id not found')
          }
          return await GalleryUpload(finalData, imgId, type)
        }),
      )

      //VALIDATION AFTER USING THE SAVE API
      if (submitMap.some((subm) => !!subm)) {
        handleDialogClose()
        if (view !== 'gallery') {
          if (onChangeType === 'object') {
            onChange(submitMap.lastItem)
          } else {
            onChange(uploadedIds?.lastItem?.lastItem)
          }
        }
        view === 'gallery' && setup()
        toast.update(id, {
          render: 'Saved successfully',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          ...TOAST_UTILS,
        })
      } else {
        toast.update(id, {
          render: 'Something went wrong',
          type: toast.TYPE.ERROR,
          isLoading: false,
          ...TOAST_UTILS,
        })
      }
    } else {
      toast.update(id, {
        render: 'Something went wrong',
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
    }
  }

  function setPage(page, reset = false) {
    setRouteStack((prev) => (reset ? [page] : [...prev, page]))
  }
  function popPage() {
    setRouteStack((prev) => {
      const finalStack = prev.slice(0, -1)
      return finalStack
    })
  }

  function renderPage() {
    switch (routeStack.lastItem) {
      case IMAGE_GALLERY_PAGES.choose:
        return <UploadOrGallery setPage={setPage} />
      case IMAGE_GALLERY_PAGES.gallery:
        return (
          <ImageGalleryChoose
            dialogDetails={dialogDetails.clientHeight !== 0 ? dialogDetails : null}
            setPage={setPage}
            onChange={handleChange}
            imageType={!!imageType ? imageType : galleryFilterValue || ''}
            onChangeType={onChangeType}
          />
        )
      case IMAGE_GALLERY_PAGES.selectSize:
        return (
          <ImageSizeSelect
            setPage={setPage}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        )
      case IMAGE_GALLERY_PAGES.localUpload:
        return <ImageUploadPage setPage={setPage} file={uploadedFile} setFile={setUploadedFile} />
      case IMAGE_GALLERY_PAGES.imageForm:
        return <ImageForm handleSaveAndUpload={handleSubmit} setPage={setPage} />
      default:
        return <>Invalid</>
    }
  }

  function handleDialogClose() {
    externalSetShowUploadModal ? externalSetShowUploadModal(false) : setShowUploadModal(false)
    setUploadedFile(undefined)
  }

  function handleAfterDialogClose() {
    setRouteStack([])
  }

  useEffect(() => {
    console.log({ view: view, imageId: imageId, imageObject: imageObject })
    if (view === 'gallery') {
      if (imageId) {
        setPage(pageToRender?.[imageEntityType]?.edit || pageToRender?.fallback?.edit, true)
      } else {
        setPage(pageToRender?.[imageEntityType]?.new || pageToRender?.fallback?.new, true)
        // IMAGE_GALLERY_PAGES.localUpload
      }
    } else {
      if (imageId) {
        setPage(IMAGE_GALLERY_PAGES?.choose, true)
        // setPage(IMAGE_GALLERY_PAGES?.imageForm)
      } else {
        setPage(IMAGE_GALLERY_PAGES?.choose, true)
        // IMAGE_GALLERY_PAGES.localUpload
      }
    }
  }, [showingUploadModalToUse, imageId, imageObject, view, imageEntityType])

  return (
    <div className="d-flex flex-column align-items-center gap-2">
      {view !== 'gallery' && view !== 'hidden' && (
        <>
          <ImageContainer
            containerType={disabled ? '' : 'button'}
            ref={chooseButtonRef}
            onClick={() => {
              !disabled && setShowUploadModal(true)
            }}
          >
            <TableImageComponent imageId={imageId} />
          </ImageContainer>
          {allImageTypes.find((type) => type.id === imageType)?.resolution && (
            <ImageDimentionsContainer>
              {allImageTypes.find((type) => type.id === imageType)?.resolution}
            </ImageDimentionsContainer>
          )}
          <ImageTypeContainer>{title}</ImageTypeContainer>
        </>
      )}
      {/* <Grow in={!disabled}>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            sx={() => chooseButtonSx(view)}
            onClick={() => setShowUploadModal(true)}
            ref={}
            variant="contained"
            size="medium"
          >
            {imageId ? 'Change' : 'Set'}
          </Button>
        </div>
      </Grow> */}
      <Dialog
        open={showingUploadModalToUse}
        onClose={handleDialogClose}
        TransitionProps={{ onExited: handleAfterDialogClose }}
        PaperProps={{
          sx: DialogPaperPropStyles,
          onScroll: (e) =>
            setDialogDetails({
              scrollTop: e.currentTarget.scrollTop,
              scrollHeight: e.currentTarget.scrollHeight,
              clientHeight: e.currentTarget.clientHeight,
            }),
        }}
      >
        <ImageUploadContext.Provider
          value={{
            view: view,
            imageId: imageId,
            imageObject: !!imageObject ? imageObject : getFullSaveObject(imageEntityType),
            imageType: imageObject?.type || imageType || '',
            imageTypeObject: imageTypeObject,
            closeFunction: handleDialogClose,
            pageStack: routeStack,
            backFunction: popPage,
            subtitle: view,
            uploadedFile: uploadedFile,
            setUploadedFile: setUploadedFile,
            imageEntityType: imageEntityType,
            allImages: allImages,
          }}
        >
          {renderPage()}
        </ImageUploadContext.Provider>
      </Dialog>
    </div>
  )
}

//OTHER COMPONENTS
export function UploadOrGallery({ setPage }) {
  const ImageContext = useContext(ImageUploadContext)
  function handleGalleryClick() {
    setPage(IMAGE_GALLERY_PAGES.gallery)
  }
  function handleLocalClick() {
    const nextPage =
      entityPageSkip?.[ImageContext.imageEntityType]?.[IMAGE_GALLERY_PAGES.localUpload] ||
      IMAGE_GALLERY_PAGES.localUpload
    setPage(nextPage)
  }
  console.log(entityChooseGalleryTitle?.[ImageContext.imageEntityType])

  return (
    <>
      <DialogBar className="d-flex justify-content-end align-items-center">
        <ImageDialogCloseButton />
      </DialogBar>
      <UploadOrGalleryContainer>
        <ChooseUploadButtons onClick={handleGalleryClick} focusRipple variant="contained">
          <ButtonImage src={uploadFromGalleryIcon} alt="Gallery" />
          {parse(
            entityChooseGalleryTitle?.[ImageContext.imageEntityType]?.innerHtml ||
              '<span>From Gallery</span>',
          )}
        </ChooseUploadButtons>
        <ChooseUploadButtons onClick={handleLocalClick} focusRipple variant="contained">
          <ButtonImage src={uploadFromLocalIcon} alt="Local File" /> <span>From</span>
          <h5>Local Files</h5>
        </ChooseUploadButtons>
      </UploadOrGalleryContainer>
    </>
  )
}

export function ImageDialogCloseButton() {
  const ImageContext = useContext(ImageUploadContext)
  return (
    <Fab color="secondary" size="small" sx={{ boxShadow: 0 }} onClick={ImageContext.closeFunction}>
      <CloseIcon />
    </Fab>
  )
}
export function ImageDialogBackButton() {
  const ImageContext = useContext(ImageUploadContext)
  return (
    <Fab color="secondary" size="small" sx={{ boxShadow: 0 }} onClick={ImageContext.backFunction}>
      <ArrowBack />
    </Fab>
  )
}


//CONSTANTS
export const DialogPaperPropStyles = {
  height: '90vh',
  width: '90vw',
  maxWidth: '90vw',
  maxHeight: '90vh',
}

function chooseButtonSx(view) {
  return {
    width: '130px',
    borderRadius: '30px',
    alignSelf: 'stretch',
    display: view === 'gallery' || view === 'hidden' ? 'none' : 'block',
  }
}

//FOR ENTITES
