import {
  fetchConsumerSubscriptionPlans,
  fetchCorporateSubscriptions,
} from './services/subscriptions'

export const subscriptionsExtraReducers = (builder) => {
  builder
    // fetchAvailableClinics
    .addCase(fetchConsumerSubscriptionPlans.pending, (state) => {
      state.consumerSummaryList.status = 'loading'
    })
    .addCase(fetchConsumerSubscriptionPlans.fulfilled, (state, action) => {
      state.consumerSummaryList.status = 'succeeded'
      state.consumerSummaryList.data = action.payload
    })
    .addCase(fetchConsumerSubscriptionPlans.rejected, (state, action) => {
      state.consumerSummaryList.status = 'failed'
    })
    .addCase(fetchCorporateSubscriptions.pending, (state) => {
      state.corporateSummaryList.status = 'loading'
    })
    .addCase(fetchCorporateSubscriptions.fulfilled, (state, action) => {
      state.corporateSummaryList.status = 'succeeded'
      state.corporateSummaryList.data = action.payload
    })
    .addCase(fetchCorporateSubscriptions.rejected, (state, action) => {
      state.corporateSummaryList.status = 'failed'
      state.corporateSummaryList.error = action.error.message
    })
}
