import { CForm } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, LinearProgress } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CardTemplateMsg from 'src/components/controlCenter/CardTemplateMsg'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetData } from 'src/services/APIs/DailyPlay/GetLists'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import {
  DISPLAY_MESSAGE_TEMPLATES_OBJECT,
  SENTINEL_CARD_OBJECT,
} from '../utils/display-message-template'

export default function DisplayMessagesEditPage({
  edit,
  setEdit,
  displayMessage,
  handleClose,
  handleCloseAndRefresh,
  type,
}) {
  const [loading, setLoading] = useState(true)
  const [editDisplayMessage, setEditDisplayMessage] = useState(
    type === 'sentinelCard' ? SENTINEL_CARD_OBJECT : DISPLAY_MESSAGE_TEMPLATES_OBJECT,
  )

  async function handleSave() {
    const tst = toast.loading('Saving Template...')
    const response = await PostSettingsData(
      `${GENERIC_APIS.saveEntity.generic}${ALL_ENTITY_TYPES.displayMessageTemplates}`,
      {
        ...editDisplayMessage,
        apiParam: 'display-msg-template',
      },
    )
    if (response.status === 200) {
      toast.update(tst, {
        render: 'Saved Successfully',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      handleCloseAndRefresh()
    } else {
      toast.update(tst, {
        render: 'Something went wrong...',
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
    }
  }
  function handleSubmit(event) {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      event.preventDefault()
      //   console.log(editDisplayMessage)
      handleSave()
      event.stopPropagation()
    }
  }

  async function getSingle() {
    const response = await GetData(GENERIC_APIS.entity.details, {
      entityType: type === 'sentinelCard' ? 'sentinel-card' : 'display-msg-template',
      id: displayMessage.id,
    })
    if (response.status === 200) {
      setEditDisplayMessage(response.data)
    } else {
      toast.error('Something went wrong')
      handleClose()
    }
  }

  async function Setup() {
    setLoading(true)
    if (displayMessage.id) {
      await Promise.all([getSingle()])
    } else {
      setEditDisplayMessage(displayMessage)
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log(editDisplayMessage)
  }, [editDisplayMessage])

  useEffect(() => {
    Setup()
  }, [displayMessage])
  return (
    <>
      <DialogContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <CForm onSubmit={handleSubmit} id="display-msg-form">
            <CardTemplateMsg
              edit={edit}
              cardObject={editDisplayMessage}
              setCardObject={setEditDisplayMessage}
              cardType={type}
            />
          </CForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        {edit ? (
          <Button
            key="submit-button"
            type="submit"
            disabled={loading}
            variant="contained"
            form="display-msg-form"
          >
            Save
          </Button>
        ) : (
          <Button color="warning" onClick={() => setEdit(true)} variant="contained">
            Edit
          </Button>
        )}
      </DialogActions>
    </>
  )
}

DisplayMessagesEditPage.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.bool,
  displayMessage: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  handleCloseAndRefresh: PropTypes.func,
  type: PropTypes.string,
}
