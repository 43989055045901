export function putAdditionalDataForNotificationsToItsPositionDefaultHandling(
  notificationData,
  dlData,
) {
  const additionalData = dlData?.additionalData
  if (!!additionalData?.itemId) {
    notificationData = {
      ...notificationData,
      customFields: {
        ...notificationData?.customFields,
        [additionalData?.idType]: additionalData?.itemId,
      },
    }
  }
  return notificationData
}
