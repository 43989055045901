const { COLORS_OBJECT } = require('src/components/controlCenter/imageUpload/imageUploadUtils')

export const NOTIFICATION_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'push-notification-template',
  title: '',
  description: '',
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  verticalRectangle: '',
  verticalRectangleColor: '',
  heroCardImage: '',
  heroCardImageColor: '',
  blogBanner: '',
  blogBannerColor: '',
  backdropImage: '',
  backdropImageColor: '',
  customShortLink: '',
  customFields: {},
  internalTitle: '',
  iconImage: '',
  bannerImage: '',
  imageUrl: '',
  type: '',
  sourceId: '',
  subType: '',
  medium: ['PUSH'],
  role: '',
  payload: {},
}
