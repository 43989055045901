import { DragHandle, South } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ConfirmationModal from 'src/components/controlCenter/ConfirmationModal'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import ImageRenderComponent from 'src/components/controlCenter/imageUpload/ImageRenderComponent'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity, SaveEntity } from 'src/utilities/apiGetters'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { array_move } from 'src/views/curatedLists/utils/curatedListData-utils'
import { CustomSelect } from 'src/views/plugins/calendar/components/CustomSelect'

export default function ReorderMentalState({ listingData: states, handleClose, handleRefresh }) {
  const [reOrderState, setReOrderState] = useState([])
  const [changes, setChanges] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [store, dispatch] = useAppSettingsStore()
  const mentalStateTypes = store.mentalStateTypes
  const [selectedMentalStateType, setMentalStateType] = useState()
  const [allMentalStates, setAllMentalStates] = useState([])
  const [loading, setLoading] = useState(false)

  async function saveOrder() {
    const response = await Promise.all(
      reOrderState.map(async (state, idx) => {
        const mentalStateToSave = await GetDetailedEntity(ALL_ENTITY_TYPES.mentalState, state)
        const resp = await SaveEntity(
          ALL_ENTITY_TYPES.mentalState,
          { ...mentalStateToSave, orderId: idx },
          () => {},
          () => {},
        )
      }),
    )
    handleRefresh()
    handleClose()
  }

  async function getMentalState() {
    const mentalStateResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.mentalState,
      page: 0,
      pageSize: 999,
      // enabled: true,
      type: selectedMentalStateType?.id,
    })
    if (mentalStateResponse.status === 200) {
      const mentalStateResponseData = mentalStateResponse.data.content
      setAllMentalStates(mentalStateResponseData)
      setReOrderState(
        mentalStateResponseData
          .filter((state) => state.enabled)
          .sort((state1, state2) => sortFn(state1.orderId, state2.orderId))
          .map((state, idx) => ({ ...state, orderId: idx })),
      )
    }
  }

  function handleDragEnd(results) {
    const { source, destination } = results
    if (source && destination && source?.index !== destination?.index) {
      setReOrderState((prev) => {
        const newArray = array_move(prev, source.index, destination.index)
        var toReturn = false
        newArray?.forEach((state, idx) => {
          if (!toReturn) {
            if (state.id !== allMentalStates?.[idx]?.id) {
              toReturn = true
            }
          }
        })
        setChanges(toReturn)
        return newArray
      })
    }
  }

  async function Setup() {
    setLoading(true)
    const functions = [getMentalState()]
    await Promise.all(functions)
    setLoading(false)
  }

  useEffect(() => {
    setMentalStateType(mentalStateTypes?.[0])
  }, [states, mentalStateTypes])

  useEffect(() => {
    if (!!selectedMentalStateType) {
      Setup()
    }
  }, [selectedMentalStateType])
  return (
    <>
      <DialogTitle>Re-Order Mental States</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div>
          <label htmlFor="type-filter">Mental State Type Filter</label>
          <CustomSelect
            id="type-filter"
            value={selectedMentalStateType}
            options={mentalStateTypes.filter((type) => type.enabled)}
            menuPlacement="auto"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name || option.title}
            onChange={(value) => setMentalStateType(value)}
          />
        </div>
        {loading ? (
          <CircularProgress />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable id="dnd-faq-category" droppableId="Cats" type="group">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ bgcolor: 'background.paper' }}
                >
                  <ListItem>
                    <ListItemAvatar sx={listItemStyles}>
                      <Box>
                        <span>#</span>
                        <South fontSize="small" />
                      </Box>
                      <Box sx={{ width: '18px' }}></Box>
                    </ListItemAvatar>
                    <ListItemText primary="Mental State" />
                  </ListItem>
                  {reOrderState?.map((state, idx) => (
                    <Draggable key={state.id} draggableId={`${state.id}`} index={idx}>
                      {(provided) => (
                        <div
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <ListItemButton id={state.id}>
                            <ListItemAvatar sx={listItemStyles}>
                              <span>{idx}</span>
                              <Avatar>
                                <ImageRenderComponent src={state?.iconId} />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={state?.name || 'Title Not Set'} />
                            <ListItemIcon>
                              <DragHandle />
                            </ListItemIcon>
                          </ListItemButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          disabled={!changes}
          onClick={() => setShowConfirmation(true)}
        >
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal
        action="submit"
        body="Do you want to save"
        visibility={showConfirmation}
        visibilitySetter={setShowConfirmation}
        onSubmitFunctions={[() => saveOrder()]}
      />
    </>
  )
}

ReorderMentalState.propTypes = {
  states: PropTypes.object,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func,
}

const listItemStyles = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: 2,
  px: 2,
}
