export const SUBTITLE_OBJECT = {
  apiParam: 'subtitle',
  colors: null,
  deleted: false,
  enabled: true,
  fileId: '',
  id: '',
  idByUser: false,
  languageCode: 'en',
  type: '',
}
