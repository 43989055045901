import { CFormLabel } from '@coreui/react-pro'
import React from 'react'
import { CCFormInput } from '../FormComponents'

export const AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}

export default function AutomaticListAddingComponent({
  id,
  value = [],
  onChange,
  label,
  direction = AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.VERTICAL,
  maxWidthOfItems = '100%',
  InputComponent = CCFormInput,
  disabled,
}) {
  function changeData(e, idx) {
    var updatedValue = value
    if (idx >= value?.length) {
      // add the new value to the array
      updatedValue = [...(updatedValue || []), e.target.value]
    } else {
      //udpate the index item
      updatedValue = updatedValue?.map((val, updatedValIdx) => {
        return idx === updatedValIdx ? e.target.value : val
      })
    }

    //remove the empty items from array
    updatedValue = updatedValue?.filter((val) => !!val)
    onChange({
      target: {
        id: id,
        value: updatedValue,
      },
    })
  }

  const CLASSES_TO_USE = {
    [AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.VERTICAL]: 'd-flex flex-column gap-1',
    [AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.HORIZONTAL]: 'd-flex gap-1 flex-wrap',
  }

  return (
    <div>
      {label && <CFormLabel>{label}</CFormLabel>}
      <div
        className={
          CLASSES_TO_USE?.[direction] ||
          CLASSES_TO_USE?.[AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.VERTICAL]
        }
      >
        {[...(value || []), '']?.map((val, idx) => (
          <InputComponent
            key={idx}
            value={val}
            showLabel={false}
            containerStyles={{
              maxWidth: maxWidthOfItems,
              width: '100%',
            }}
            disabled={disabled}
            id="val"
            valueId={id}
            onChange={(e) => {
              changeData(e, idx)
            }}
          />
        ))}
      </div>
    </div>
  )
}
