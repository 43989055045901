import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { entityTitle } from '../../../config/common/genericListingAndFormConfig'
import { MainHeaderUpload, SmallHeaderUpload } from '../styledComponents'
import { ImageDialogBackButton, ImageDialogCloseButton, ImageUploadContext } from './ImageUpload'

ImageUploadChoose.propTypes = { setPage: PropTypes.func, closeButton: PropTypes.any }
ImageUploadChooseHeader.propTypes = {
  closeButton: PropTypes.any,
  title: PropTypes.string,
}

export default function ImageUploadChoose({ setPage, closeButton }) {
  return (
    <div>
      <ImageUploadChooseHeader closeButton={closeButton} title="Upload Image" />
    </div>
  )
}

export function ImageUploadChooseHeader({ closeButton, title }) {
  const ImageContext = useContext(ImageUploadContext)

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div id="upload-header" className="d-flex justify-content-start align-items-center gap-2">
        {ImageContext.pageStack.length > 1 && <ImageDialogBackButton />}
        <div className='className="d-flex flex-column justify-content-center align-items-stretch"'>
          <SmallHeaderUpload>
            {entityTitle[ImageContext.imageEntityType]?.subtitle || 'Add/Edit'}
          </SmallHeaderUpload>
          <MainHeaderUpload>{title}</MainHeaderUpload>
        </div>
      </div>
      {closeButton ? <closeButton /> : <ImageDialogCloseButton />}
    </div>
  )
}
