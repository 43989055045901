import { CopyAll } from '@mui/icons-material'
import { Checkbox, Chip, FormControlLabel, IconButton, Tooltip } from '@mui/material'
import moment from 'moment'
import ReactPlayer from 'react-player'
import { OverflowingArrayChips } from 'src/components/controlCenter/FormComponents'
import CopyOrOpenInANewTabInWebApp from 'src/components/controlCenter/common/CopyOrOpenInANewTabInWebApp'
import CountryCodeFlag from 'src/components/controlCenter/common/CountryCodeFlag'
import { CustomReactPlayer } from 'src/components/controlCenter/common/customReactPlayer/CustomReactPlayer'
import TrueFalseHandler from 'src/components/controlCenter/genericListing/TrueFalseHandler'
import GuestTitleComponent from 'src/components/controlCenter/genericListing/guest/GuestTitleComponent'
import OCCheckDraftOrPublished from 'src/components/controlCenter/genericListing/onlineCourse/OCCheckDraftOrPublished/OCCheckDraftOrPublished'
import OnlineCourseStatusComponent from 'src/components/controlCenter/genericListing/onlineCourse/courseListingStatus/OnlineCourseStatusComponent'
import CourseUnpublishRequestStatusComponent from 'src/components/controlCenter/genericListing/onlineCourse/courseUnpublishRequestStatus/CourseUnpublishRequestStatusComponent'
import OnlineCourseTitleHeaderComponent from 'src/components/controlCenter/genericListing/onlineCourse/titleHeader/OnlineCourseTitleHeaderComponent'
import LoginDataComponent from 'src/components/controlCenter/genericListing/users/LoginDataComponent'
import ProfilePicComponent from 'src/components/controlCenter/genericListing/users/ProfilePicComponent'
import UserTitleComponent from 'src/components/controlCenter/genericListing/users/UserTitleComponent'
import ImageRenderComponent from 'src/components/controlCenter/imageUpload/ImageRenderComponent'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import {
  ALL_AVAILABLE_PLATFORMS_ARRAY,
  COURSE_DRAFT_STATUS_TYPES,
  INVESTIGATION_TEST_TYPES_ARRAY,
  INVESTIGATION_TYPES,
  INVESTIGATION_TYPES_ARRAY,
  MOMENT_FORMATS,
  OOH_MSG_TYPES,
  OOH_MSG_TYPES_ARRAY,
} from 'src/utilities/constants'
import {
  checkIfStringIsValid,
  copyToClipBoard,
  isValidYouTubeUrl,
} from 'src/utilities/generalUtils'
import TabTableItem from 'src/views/appBehaviour/tabSettings/components/TabTableItem'
import { LEGACY_TABS_FOR_IN_APP_MSG } from 'src/views/miscellaneous/in-app-messages/utils'
import { TableAvatarComponent, TableImageComponent } from 'src/views/settings/components/TableImage'
import { MaxWidthTableData } from 'src/views/settings/components/styledComponents'
import { ShowPricingDetails } from 'src/views/subscriptions/plans/components/sub-plan-helper-components'

export function getExtraScopedColumnsForSmartTable(apiParam, dropDownData, rStore, contentData) {
  switch (apiParam) {
    case ALL_ENTITY_TYPES.agreement:
      return {
        applicableSince: (item) => (
          <td>
            {moment
              .utc(item?.applicableSince, 'YYYY-MM-DD HH:mm:ss')
              .local()
              .format('DD MMMM YYYY HH:mm:ss')}
          </td>
        ),
        excludedCountries: (item) => (
          <td>
            {item?.excludedCountries
              ?.map(
                (countryId) =>
                  rStore?.countries?.find((country) => country?.id === countryId)?.title ||
                  countryId,
              )
              ?.join(', ')}
          </td>
        ),
        includedCountries: (item) => (
          <td>
            {item?.includedCountries
              ?.map(
                (countryId) =>
                  rStore?.countries?.find((country) => country?.id === countryId)?.title ||
                  countryId,
              )
              .join(', ')}
          </td>
        ),
        roles: (item) => (
          <td>
            <div className="d-flex gap-2 flex-wrap">
              {item?.roles?.map((role) => {
                return <Chip label={role} key={role} />
              }) || 'No Data'}
            </div>
          </td>
        ),
        metaCategories: (item) => (
          <td>
            <div className="d-flex gap-2 flex-wrap">
              {item?.metaCategories?.map((category) => {
                const foundCat = rStore?.categories?.find((cat) => cat?.id === category)
                return <Chip label={foundCat?.title || category} key={category} />
              }) || 'No Data'}
            </div>
          </td>
        ),
      }
    case ALL_ENTITY_TYPES.mentor:
      return {
        profilePicId: (item) => (
          <td>
            <div className="d-flex gap-2 flex-wrap">
              <TableAvatarComponent imageId={item?.profilePicId} />
            </div>
          </td>
        ),
        services: (item) => (
          <td>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: 'unset',
                },
              }}
              control={
                <Checkbox
                  size={'small'}
                  id="intercom"
                  disabled
                  checked={!!item?.services?.live || false}
                  // onChange={handleMuiSwitch}
                />
              }
              label="1 - 1 Sessions"
            />
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: 'unset',
                },
              }}
              control={
                <Checkbox
                  size={'small'}
                  id="intercom"
                  disabled
                  checked={!!item?.services?.groupLive || false}
                  // onChange={handleMuiSwitch}
                />
              }
              label="Group Sessions"
            />
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: 'unset',
                },
              }}
              control={
                <Checkbox
                  size={'small'}
                  id="intercom"
                  disabled
                  checked={!!item?.services?.recorded || false}
                  // onChange={handleMuiSwitch}
                />
              }
              label="Publish Content"
            />
          </td>
        ),
        categories: (item) => (
          <td>
            <div className="d-flex gap-2 flex-wrap">
              {item?.categories?.map((category) => {
                const titleToReturn =
                  rStore?.categories?.find((cat) => cat?.id === category)?.title || category
                return <Chip label={titleToReturn} key={category} />
              }) || 'No Data'}
            </div>
          </td>
        ),
        kycStatus: (item) => (
          <td>
            <div className="d-flex gap-2 flex-wrap">
              {item?.kycStatus === 'approved' ? (
                <Chip color="success" label="Approved" />
              ) : (
                <Chip color="warning" label="Pending" />
              )}
            </div>
          </td>
        ),
        country: (item) => (
          <td>
            {item?.location?.countryCode ? (
              <CountryCodeFlag countryCode={item?.location?.countryCode} />
            ) : (
              'No Data'
            )}
          </td>
        ),
        slug: (item) => (
          <td>
            <CopyOrOpenInANewTabInWebApp
              postFixUrl={checkIfStringIsValid(item?.slug) && `mentor/${item?.slug}`}
            />
          </td>
        ),
      }
    case ALL_ENTITY_TYPES.journal:
      return {
        tags: (item) => {
          const foundTags = item?.tags?.map((tag) => {
            const foundTag = rStore?.tags?.find((rTag) => rTag?.id === tag)
            return !!foundTag ? foundTag : { id: tag, title: tag }
          })
          return (
            <td>
              <OverflowingArrayChips
                allItems={foundTags}
                getTitle={(tag) => tag?.title || tag?.name || tag?.tag}
                maxChips={3}
              />
            </td>
          )
        },
        subscription: (item) => {
          return <td>{item?.subscription?.name}</td>
        },
        journalQusAns: (item) => {
          return <td>{item?.journalQusAns?.length}</td>
        },
        preMsgs: (item) => {
          return <td>{item?.preMsgs?.length || 0}</td>
        },
        postMsgs: (item) => {
          return <td>{item?.postMsgs?.length || 0}</td>
        },
        slug: (item) => (
          <td>
            <CopyOrOpenInANewTabInWebApp
              postFixUrl={checkIfStringIsValid(item?.slug) && `journal/${item?.slug}`}
            />
          </td>
        ),
      }
    case ALL_ENTITY_TYPES.assessment:
      return {
        tags: (item) => {
          const foundTags = item?.tags?.map((tag) => {
            const foundTag = rStore?.tags?.find((rTag) => rTag?.id === tag)
            return !!foundTag ? foundTag : { id: tag, title: tag }
          })
          return (
            <td>
              <OverflowingArrayChips
                allItems={foundTags}
                getTitle={(tag) => tag?.title || tag?.name || tag?.tag}
                maxChips={3}
              />
            </td>
          )
        },
        subscription: (item) => {
          return <td>{item?.subscription?.name}</td>
        },
        journalQusAns: (item) => {
          return <td>{item?.journalQusAns?.length}</td>
        },
        preMsgs: (item) => {
          return <td>{item?.preMsgs?.length || 0}</td>
        },
        postMsgs: (item) => {
          return <td>{item?.postMsgs?.length || 0}</td>
        },
        assessmentDomainList: (item) => {
          console.log(rStore)
          const foundTags = item?.assessmentDomainList?.map((tag) => {
            const foundTag = rStore?.tags?.find((rTag) => rTag?.id === tag)
            return !!foundTag ? foundTag : { id: tag, title: tag }
          })
          return (
            <td>
              <OverflowingArrayChips
                allItems={foundTags}
                getTitle={(tag) => tag?.title || tag?.name || tag?.tag}
                maxChips={3}
              />
            </td>
          )
        },
        slug: (item) => (
          <td>
            <CopyOrOpenInANewTabInWebApp
              postFixUrl={checkIfStringIsValid(item?.slug) && `assessment/${item?.slug}`}
            />
          </td>
        ),
      }
    case ALL_ENTITY_TYPES.metaCategoryType:
      return {
        itemType: (item) => {
          const foundItemTypes = item?.itemType?.map((itemType) => {
            return dropDownData?.itemTypes?.find((ddnItemType) => ddnItemType?.id === itemType)
          })
          return (
            <td>
              <OverflowingArrayChips
                allItems={foundItemTypes}
                getTitle={(itmType) => itmType?.title || itmType?.name || itmType?.id}
                maxChips={3}
              />
            </td>
          )
        },
        scope: (item) => {
          return <td>{item?.scope === 'app' ? 'App' : 'Server'}</td>
        },
        enabledForDailyPlay: (item) => {
          return (
            <td>
              <TrueFalseHandler value={item?.enabledForDailyPlay} />
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES?.clinic:
      return {
        kycStatus: (item) => (
          <td>
            <div className="d-flex gap-2 flex-wrap">
              {item?.kycStatus === 'approved' ? (
                <Chip color="success" label="Approved" />
              ) : (
                <Chip color="warning" label="Pending" />
              )}
            </div>
          </td>
        ),
        cloudClinic: (item) => {
          return (
            <td>
              <TrueFalseHandler value={item?.cloudClinic} />
            </td>
          )
        },
        ownerId: (item) => <td>{item?.owner || item?.ownerId || 'No Data'}</td>,
        availableServices: (item) => {
          // const foundAvailableServices = item?.availableServices?.map((service) => {
          //   return service.title
          // })
          return (
            <td>
              <OverflowingArrayChips
                allItems={item.availableServices}
                getTitle={(itmType) => itmType?.title || itmType?.name || itmType?.id}
                maxChips={3}
              />
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.quote:
      return {
        title: (item) => {
          return <td>{item?.title}</td>
        },
        slug: (item) => (
          <td>
            <CopyOrOpenInANewTabInWebApp
              postFixUrl={checkIfStringIsValid(item?.slug) && `quotes/${item?.slug}`}
            />
          </td>
        ),
      }
    case ALL_ENTITY_TYPES?.activityPlan:
      return {
        description: (item) => {
          return (
            <td>
              {checkIfStringIsValid(item?.description) ? item?.description : 'No Description'}
            </td>
          )
        },
        subscriptionType: (item) => {
          const foundSubscriptionType = dropDownData?.allPlanLevels?.find(
            (planLevel) => planLevel?.id === item?.subscriptionType,
          )
          return <td>{foundSubscriptionType?.name || 'None'}</td>
        },
        pricePlan: (item) => {
          const foundPricePlan = dropDownData?.subscriptionPlans?.find(
            (plan) => plan?.id === item?.pricePlan,
          )
          return <td>{foundPricePlan?.title || 'None'}</td>
        },
        domainIds: (item) => {
          const foundDomainIds = item?.domainIds?.map((domainId) => {
            return rStore?.tags?.find((ddnDomain) => ddnDomain?.id === domainId)
          })
          return (
            <td>
              <OverflowingArrayChips
                allItems={foundDomainIds}
                getTitle={(ddnDomain) => ddnDomain?.title || ddnDomain?.name || ddnDomain?.id}
                maxChips={3}
              />
            </td>
          )
        },
        numberOfPhases: (item) => {
          return <td>{!!item?.phaseIds?.length ? item?.phaseIds?.length : 'None'}</td>
        },
        numberOfPlannedActivities: (item) => {
          return (
            <td>
              {!!item?.plannedActivityIds?.length ? item?.plannedActivityIds?.length : 'None'}
            </td>
          )
        },
        status: (item) => {
          return (
            <td>
              {item?.status === 'draft' ? (
                <Chip label="Draft" color="warning" />
              ) : (
                <Chip label="Published" color="success" />
              )}
            </td>
          )
        },
        slug: (item) => (
          <td>
            <CopyOrOpenInANewTabInWebApp
              postFixUrl={checkIfStringIsValid(item?.slug) && `plan/${item?.slug}`}
            />
          </td>
        ),
      }
    case ALL_ENTITY_TYPES?.activity:
      return {
        metaCategoryId: (item) => {
          const foundCategory = rStore?.categories?.find(
            (rCategory) => rCategory?.id === item?.metaCategoryId,
          )
          return <td>{foundCategory?.singleTitle || foundCategory?.title || item?.id}</td>
        },
      }
    case ALL_ENTITY_TYPES?.article:
      return {
        slug: (item) => (
          <td>
            <CopyOrOpenInANewTabInWebApp
              postFixUrl={checkIfStringIsValid(item?.slug) && `article/${item?.slug}`}
            />
          </td>
        ),
        subscription: (item) => {
          const foundSubscriptionType = dropDownData?.allPlanLevels?.find(
            (planLevel) => planLevel?.id === item?.subscription?.id,
          )
          return <td>{foundSubscriptionType?.name || 'Not Set'}</td>
        },
        coverIcon: (item) => {
          return (
            <td>
              <TableImageComponent
                sx={{ height: '90px', width: '90px' }}
                colors={item?.colors}
                imageId={item?.coverIcon}
                handleClick={() => {}}
              />
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES?.oohMsg:
      return {
        orderId: (item) => {
          return <td>{item?.orderId}</td>
        },
        imageType: (item) => {
          return (
            <td>
              {OOH_MSG_TYPES_ARRAY?.find((type) => type?.id === item?.imageType)?.title ||
                item?.imageType}
            </td>
          )
        },
        media: (item) => {
          return item?.imageType === OOH_MSG_TYPES.image ? (
            <td>
              {
                <ImageRenderComponent
                  style={{ width: '300px', height: '190px' }}
                  src={item?.imageId}
                />
              }
            </td>
          ) : (
            <ReactPlayer
              url={item?.youtubeUrl}
              style={{
                padding: '10px',
                maxWidth: '300px',
                maxHeight: '200px',
              }}
            />
          )
        },
      }
    case ALL_ENTITY_TYPES?.notifications:
      return {
        iconImage: (item) => {
          return (
            <td>
              {
                <td>
                  <TableImageComponent
                    sx={{ height: '90px', width: '90px' }}
                    colors={item?.colors}
                    customSrc={item?.iconImage}
                    handleClick={() => {}}
                    // debug={true}
                  />
                </td>
              }
            </td>
          )
        },
        youtubeUrl: (item) => {
          return (
            <td>
              {isValidYouTubeUrl(item?.youtubeUrl) && (
                <ReactPlayer
                  url={item?.youtubeUrl}
                  style={{
                    padding: '10px',
                    maxWidth: '300px',
                    maxHeight: '200px',
                  }}
                />
              )}
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES?.metaTabSetting:
      return {
        description: (item) => {
          return (
            <td>
              {checkIfStringIsValid(item?.description) ? item?.description : 'No Description'}
            </td>
          )
        },
        iosTabs: (item) => {
          const iosTabObjects = item?.ios?.[0]?.tabs?.map((tabId) =>
            rStore?.metaTabs?.find((tab) => tab?.id === tabId),
          )
          return <td>{iosTabObjects?.map((tab) => tab?.title)?.join(', ')}</td>
        },
        androidTabs: (item) => {
          const androidTabObjects = item?.android?.[0]?.tabs?.map((tabId) =>
            rStore?.metaTabs?.find((tab) => tab?.id === tabId),
          )
          return (
            <td>
              {androidTabObjects?.map((tab) => tab?.title)?.join(', ')}
              {/* <OverflowingArrayChips
                  allItems={androidTabObjects}
                  getTitle={(itm) => itm?.title}
                  maxChips={3}
                /> */}
            </td>
          )
        },
        webTabs: (item) => {
          const webTabObjects = item?.web?.[0]?.tabs?.map((tabId) =>
            rStore?.metaTabs?.find((tab) => tab?.id === tabId),
          )
          return (
            <td>
              {webTabObjects?.map((tab) => tab?.title)?.join(', ')}
              {/* <OverflowingArrayChips
                  allItems={webTabObjects}
                  getTitle={(itm) => itm?.title}
                  maxChips={3}
                /> */}
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.tab:
      return {
        image: (item) => {
          return (
            <td>
              <TabTableItem
                // sx={{ height: '90px', width: '90px', padding: '5px' }}
                // colors={item?.colors}
                // imageId={item?.image}
                // handleClick={() => {}}
                item={item}
                disableShortCutMenu
              />
            </td>
          )
        },
        iosIcon: (item) => {
          return (
            <td>
              <MaxWidthTableData
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  copyToClipBoard(`iOS Icon - ${item?.icon}`, item.icon)
                }}
                className="py-2"
              >
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <Tooltip title={'Copy'}>
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <span>{item?.icon}</span>
                    </div>
                  </Tooltip>
                  <CopyAll />
                </div>
              </MaxWidthTableData>
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.inAppMessages:
      return {
        status: (item) => {
          return (
            <td>
              {item?.status === 'published' ? (
                <Chip label="Published" color="success" />
              ) : (
                <Chip label="Draft" color="warning" />
              )}
            </td>
          )
        },
        screens: (item) => {
          return (
            <td>
              {item?.criteria?.screens
                ?.map((screen) => {
                  const foundScreen = LEGACY_TABS_FOR_IN_APP_MSG?.find((tab) => tab?.id === screen)
                  return foundScreen?.title || screen
                })
                .join(', ')}
            </td>
          )
        },
        countries: (item) => {
          return (
            <td>
              {item?.criteria?.countries
                ?.map((country) => {
                  const foundCountry = rStore?.countries?.find((country) => country?.id === country)
                  return foundCountry?.title || country
                })
                .join(', ')}
            </td>
          )
        },
        startDate: (item) => {
          return (
            <td>
              {moment(item?.criteria?.startDate).format('DD MMMM YYYY HH:mm:ss')}
              {/* {item?.startDate} */}
            </td>
          )
        },
        expiryDate: (item) => {
          return (
            <td>
              {moment(item?.criteria?.expiryDate).format('DD MMMM YYYY HH:mm:ss')}
              {/* {item?.expiryDate} */}
            </td>
          )
        },
        showFrequency: (item) => {
          return (
            <td>
              {item?.criteria.showAlways
                ? 'Show always'
                : `Every ${item?.criteria?.displayFrequency} day, ${item?.criteria?.displayMax} times a day`}
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.planBundlePack:
      return {
        planGrpId: (item) => {
          const foundGrpId = rStore?.allPlanGroups?.find((grp) => grp.id === item?.planGrpId)
          return <td>{foundGrpId?.name || item?.planGrpId}</td>
        },
        recurring: (item) => {
          return (
            <td>
              <TrueFalseHandler value={item?.recurring} />{' '}
            </td>
          )
        },
        rowPrice: (item) => {
          const priceToUse = item?.pricingMatrix?.ROW
          return (
            <td>
              <ShowPricingDetails priceToUse={priceToUse} />
            </td>
          )
        },
        rowFinalPrice: (item) => {
          const priceToUse = item?.pricingMatrix?.ROW
          return (
            <td>
              <ShowPricingDetails
                priceToUse={priceToUse}
                getPrice={(priceToUse) => priceToUse?.discountedPrice}
              />
            </td>
          )
        },
        inPrice: (item) => {
          const priceToUse = item?.pricingMatrix?.IN
          return (
            <td>
              <ShowPricingDetails priceToUse={priceToUse} />
            </td>
          )
        },
        inFinalPrice: (item) => {
          const priceToUse = item?.pricingMatrix?.IN
          return (
            <td>
              <ShowPricingDetails
                priceToUse={priceToUse}
                getPrice={(priceToUse) => priceToUse?.discountedPrice}
              />
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.clinicBrand:
      return {
        iconId: (item) => {
          return (
            <td>
              <TableImageComponent
                sx={{ height: '90px', width: '90px' }}
                colors={item?.colors}
                imageId={item?.iconImage}
                handleClick={() => {}}
              />
            </td>
          )
        },
        brandName: (item) => {
          return <td>{item?.title || item?.brandName}</td>
        },
        slug: (item) => (
          <td>
            <CopyOrOpenInANewTabInWebApp
              postFixUrl={checkIfStringIsValid(item?.slug) && `clinic/${item?.slug}`}
            />
          </td>
        ),
        kycStatus: (item) => (
          <td>
            <div className="d-flex gap-2 flex-wrap">
              {item?.kycStatus === 'approved' ? (
                <Chip color="success" label="Approved" />
              ) : (
                <Chip color="warning" label="Pending" />
              )}
            </div>
          </td>
        ),
      }
    case ALL_ENTITY_TYPES.prescriptionTemplate:
      return {
        title: (item) => {
          return (
            <td>
              {item?.header?.title} - ({item?.id})
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.approvalRequest:
      return {
        title: (item) => {
          const courseItem = item?.courseDraft?.onlineCourse
          return (
            <OnlineCourseTitleHeaderComponent
              dropDownData={dropDownData}
              rStore={rStore}
              onlineCourse={courseItem}
            />
          )
        },
        courseStatus: (item) => {
          const courseItem = item?.courseDraftonlineCourse
          return <OnlineCourseStatusComponent item={courseItem} />
        },
        status: (item) => {
          return <CourseUnpublishRequestStatusComponent unPublishRequest={item} />
        },
      }
    case ALL_ENTITY_TYPES.unPublishRequest:
      return {
        title: (item) => {
          const courseItem = item?.onlineCourse
          return (
            <OnlineCourseTitleHeaderComponent
              dropDownData={dropDownData}
              rStore={rStore}
              onlineCourse={courseItem}
            />
          )
        },
        courseStatus: (item) => {
          const courseItem = item?.onlineCourse
          return <OnlineCourseStatusComponent item={courseItem} />
        },
        status: (item) => {
          return <CourseUnpublishRequestStatusComponent unPublishRequest={item} />
        },
      }
    case ALL_ENTITY_TYPES.courseDraft:
      return {
        title: (item) => {
          return (
            <OnlineCourseTitleHeaderComponent
              dropDownData={dropDownData}
              rStore={rStore}
              onlineCourse={item?.onlineCourse}
            />
          )
        },
        state: (item) => {
          if (item?.state === COURSE_DRAFT_STATUS_TYPES.active) {
            return (
              <td>
                <Chip label="Active" color="success" />
              </td>
            )
          }
          if (item?.state === COURSE_DRAFT_STATUS_TYPES.stashed) {
            return (
              <td>
                <Chip label="Stashed" color="warning" />
              </td>
            )
          }
          if (item?.state === COURSE_DRAFT_STATUS_TYPES.submittedSoInActive) {
            return (
              <td>
                {item?.onlineCourse?.status !== 'pending' &&
                item?.onlineCourse?.status !== 'draft' ? (
                  <Chip label="Approved" color="success" />
                ) : (
                  <Chip label="Submitted for approval" color="secondary" />
                )}
              </td>
            )
          }
          return <td>{item?.state}</td>
        },
        status: (itm) => {
          const item = itm?.onlineCourse
          return <OnlineCourseStatusComponent item={item} />
        },
        publicLink: (itm) => {
          const item = itm?.onlineCourse
          return (
            <td>
              <CopyOrOpenInANewTabInWebApp
                postFixUrl={
                  checkIfStringIsValid(item?.slug) &&
                  item?.category?.[0] &&
                  `c/${item?.category?.[0]}/${item?.slug}`
                }
              />
            </td>
          )
        },
        checkDraftOrCourse: (itm) => {
          return (
            <OCCheckDraftOrPublished relatedOnlineCourse={itm?.relatedOC} listingType="draft" />
          )
        },
      }
    case ALL_ENTITY_TYPES.onlineCourse:
      return {
        title: (item) => {
          return (
            <OnlineCourseTitleHeaderComponent
              dropDownData={dropDownData}
              rStore={rStore}
              onlineCourse={item}
            />
          )
        },
        status: (item) => <OnlineCourseStatusComponent item={item} />,
        publicLink: (item) => {
          return (
            <td>
              <CopyOrOpenInANewTabInWebApp
                postFixUrl={
                  checkIfStringIsValid(item?.slug) &&
                  item?.category?.[0] &&
                  `c/${item?.category?.[0]}/${item?.slug}`
                }
              />
            </td>
          )
        },
        checkDraftOrCourse: (itm) => {
          return (
            <OCCheckDraftOrPublished
              relatedOnlineCourse={itm?.relatedDraft?.onlineCourse}
              listingType="non-draft"
            />
          )
        },
      }
    case ALL_ENTITY_TYPES.paymentGateways:
      return {
        active: (item) => {
          return (
            <td>
              <TrueFalseHandler value={item?.active} />
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      return {
        gateways: (item) => {
          const foundGtwys = dropDownData?.paymentGateways?.filter((gateway) =>
            item?.paymentGatewayList?.includes(gateway?.id),
          )
          return <td>{foundGtwys?.map((gateway) => gateway?.title).join(', ')}</td>
        },
        grpId: (item) => {
          const foundPaymentGroup = dropDownData?.allPlanGroups?.find(
            (grp) => grp?.id === item?.grpId,
          )
          return <td>{foundPaymentGroup?.name || foundPaymentGroup?.title || item?.grpId}</td>
        },
      }
    case ALL_ENTITY_TYPES.doctorUsers:
    case ALL_ENTITY_TYPES.clinicAssistantUser:
    case ALL_ENTITY_TYPES.users:
      return {
        title: (item) => {
          return (
            <td>
              <UserTitleComponent userData={item} />
            </td>
          )
        },
        profilePicId: (item) => {
          return (
            <td>
              <ProfilePicComponent userData={item} />
            </td>
          )
        },
        authorities: (item) => {
          const foundAuthorities = item?.authorities?.map((authority) => {
            return (
              rStore?.allRoles?.find((auth) => auth?.name === authority) || {
                id: authority,
                title: authority,
              }
            )
          })

          return (
            <td>
              <OverflowingArrayChips
                allItems={foundAuthorities}
                getTitle={(itm) => itm?.title}
                maxChips={3}
              />
            </td>
          )
        },
        userId: (item) => {
          return (
            <td>
              <Tooltip title={item?.userId}>
                <IconButton
                  className="flex-shrink-0"
                  onClick={(event) => {
                    event.stopPropagation()
                    copyToClipBoard('User ID', item?.userId)
                  }}
                >
                  <CopyAll />
                </IconButton>
              </Tooltip>
            </td>
          )
        },
        segmentId: (item) => {
          return (
            <td>
              <LoginDataComponent userData={item} dropDownData={dropDownData} />
            </td>
          )
        },
        platform: (item) => {
          return (
            <td>
              {ALL_AVAILABLE_PLATFORMS_ARRAY?.find(
                (pltfrm) => pltfrm?.id === item?.latestUserDevice?.platform,
              )?.title ||
                item?.latestUserDevice?.platform ||
                'NA'}
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.backgroundMusic:
      return {
        track: (item) => {
          return (
            <td>
              {item?.hlsUrl ? (
                <CustomReactPlayer useBasicReactPlayer src={item?.hlsUrl} controls width="100%" />
              ) : (
                <strong>No Track uploaded</strong>
              )}
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.guest:
      return {
        title: (item) => {
          return <GuestTitleComponent guestSummaryObject={item} />
        },
        publicLink: (item) => {
          return (
            <td>
              <CopyOrOpenInANewTabInWebApp
                postFixUrl={checkIfStringIsValid(item?.slug) && `guest/${item?.slug}`}
              />
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.role:
      return {
        agreementRequired: (item) => {
          return (
            <td>
              <TrueFalseHandler value={item?.agreementRequired} />
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.investigation:
      return {
        units: (item) => {
          return (
            <td>
              <OverflowingArrayChips
                allItems={item?.units}
                getTitle={(item) => item}
                maxChips={7}
              />
            </td>
          )
        },
        investigationType: (item) => {
          return (
            <td>
              {INVESTIGATION_TYPES_ARRAY?.find((type) => type?.id === item?.investigationType)
                ?.title || item?.investigationType}
            </td>
          )
        },
        type: (item) => {
          return (
            <td>
              {item?.investigationType === INVESTIGATION_TYPES.panel
                ? ''
                : INVESTIGATION_TEST_TYPES_ARRAY?.find((type) => type?.id === item?.type)?.title ||
                  item?.type}
            </td>
          )
        },
      }
    case ALL_ENTITY_TYPES.patientInfo:
    default:
      return {
        dateOfBirth: (item) => {
          return (
            <td>
              {moment(item?.dateOfBirth).format(
                MOMENT_FORMATS.completeDateDetailsWithMonthShortForm,
              )}
            </td>
          )
        },
      }
  }
}
