import { CFormInput } from '@coreui/react-pro'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import React from 'react'
import SummarizableFormFieldsWrapper from 'src/components/controlCenter/common/SummarizableFormFieldsWrapper'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'

export default function PaymentGatewaysForm({
  initialObject = {},
  imageObject: paymentGateways,
  setImageObject: setPaymentGateways,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
}) {
  return (
    <Grid container spacing={2}>
      <GridSectionWrapper heading="Basic Details" shadow={false}>
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                id="active"
                checked={!!paymentGateways?.active}
                onChange={handleChangeObject}
              />
            }
            label="Active: "
            disabled={disabled}
          />
        </Grid>
        <SummarizableFormFieldsWrapper
          data={paymentGateways}
          handleChange={handleChangeObject}
          disabled={disabled}
          idDisabled={!!initialObject?.id}
          showIdField
          allListData={allImages}
          initialObject={initialObject}
        />
        <Grid item xs={6}>
          <CFormInput
            id="refundPolicy"
            label="Refund Policy"
            value={paymentGateways.refundPolicy}
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
      </GridSectionWrapper>
    </Grid>
  )
}
