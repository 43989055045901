export const CURATED_LIST_TYPE_OBJECT = {
  apiParam: '',
  getAllApi: '',
  id: '',
  itemHeaders: [],
  listHeaders: [],
  title: '',
  type: 'id',
  idField: '',
  idFieldName: '',
}

export const CURATED_LIST_OBJECT = {
  enabled: false,
  id: '',
  list: [],
  name: '',
  type: '',
}

export const namedItem = {
  category: [],
  iconId: '',
  id: '',
  name: '',
  orderId: 0,
  recommended: true,
  searchable: true,
  sectionIds: [],
  title: '',
}

export const NAMED_ASSESSMENT_ITEM = {
  ...namedItem,
  assessmentDomainId: '',
}

export const getAllApiResponseType = [
  { value: 'List', label: 'List' },
  { value: 'Page', label: 'Page' },
]
