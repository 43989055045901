export function UICValidateSelectedUsers(value, isMulti, required) {
  let toReturn = {
    valid: false,
    error: !!value?.userId ? '' : '',
    color: !!value?.userId ? 'text-info' : 'text-danger',
    formValid: !!value?.userId,
  }
  if (isMulti) {
    if (value?.length > 0) {
      toReturn = { ...toReturn, formInvalid: true }
    }
  }
  if (!required) {
    toReturn.error = null
  }
  return toReturn
}

export function UICGetValue(value, isMulti) {
  let valToReturn = value
  if (isMulti) {
    valToReturn = value?.length > 0 ? value?.filter((val) => !!val?.userId) : null
  } else {
    valToReturn = value?.userId ? value : null
  }
  return valToReturn
}
