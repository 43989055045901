import axios from 'axios'
import { toast } from 'react-toastify'
import { apiURL } from 'src/services'
import { TOAST_UTILS } from '../../../components/controlCenter/toast-utils'

const token = localStorage.getItem('token')
const Headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
}

const API_URL = [apiURL, '/api/secure/cc/'].join('')

// export async function postSubscription(subscription) {
//   const Body = subscription
//   try {
//     const response = await axios.post(`${apiURL}/api/secure/cc/subscription/plan/save`, Body, {
//       headers: Headers,
//     })
//     return response
//   } catch (error) {
//     console.log(error)
//     return error
//   }
// }
export async function postSubscription(subscriptionPlan, tst) {
  const promToast = tst ? tst : toast.loading('Saving subscription plan.')
  return axios
    .post(`${apiURL}/api/secure/cc/subscription/plan/save`, subscriptionPlan, {
      headers: Headers,
    })
    .then((response) => {
      toast.update(promToast, {
        render: 'Subscription Plan Saved Successfully',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      return response
    })
    .catch((error) => {
      toast.update(promToast, {
        render: 'Something went wrong',
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
      console.log(error)
    })
}

export async function postCorporateSubscriptionPlan(subscriptionPlan, tst) {
  const promToast = tst ? tst : toast.loading('Saving subscription translations.')
  return axios
    .post(`${apiURL}/api/secure/cc/subscription/corporate/save`, subscriptionPlan, {
      headers: Headers,
    })
    .then((response) => {
      toast.update(promToast, {
        render: 'Subscription Plan Saved Successfully',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      return response
    })
    .catch((error) => {
      toast.update(promToast, {
        render: 'Something went wrong',
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
      console.log(error)
    })
}

export async function postSubscriptionTranslation(subscription, tst) {
  const promToast = tst ? tst : toast.loading('Saving subscription translations.')
  axios
    .post(`${apiURL}/api/secure/cc/translation/subscription-plan`, subscription, {
      headers: Headers,
    })
    .then((response) => {
      toast.update(promToast, {
        render: 'Subscription Plan Saved Successfully',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      return response
    })
    .catch((error) => {
      toast.update(promToast, {
        render: 'Something went wrong',
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
      console.log(error)
    })
}

export function PostSubscriptionData(url, Body, Params = {}) {
  try {
    const response = axios.post(`${API_URL}${url}`, Body, {
      headers: Headers,
      params: Params,
    })
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}
