import { ButtonBase, styled as muiStyled } from '@mui/material'

export const AddAccessTypeButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 7px 14px;
    border-radius: 5px;
    background: ${theme.palette.primary.main};
    color: #fff;
`,
)
