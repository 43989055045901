import { CFormInput } from '@coreui/react-pro'
import { Button, Dialog, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PAYWALL_ITEM } from 'src/common/types/paywall/paywallObject'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import { fetchConsumerSubscriptionPlans } from 'src/reducers/subscriptions/services/subscriptions'
import { selectSummaryListOfConsumerPlans } from 'src/reducers/subscriptions/subscriptionReducer'
import { ALL_AVAILABLE_PLATFORMS_ARRAY, currency_list } from 'src/utilities/constants'
import AddPackagePaywall from './components/AddPackagePaywall'
import Packages from './components/Packages'
import { AddPackageModalPaper } from './components/styledComponents'

const PAYWALL_MODAL_TYPES = {
  ADD_PACKAGE: 'add-package',
  ADD_PACKAGE_ITEM: 'add-package-item',
}

export default function PaywallsForm({
  initialObject,
  imageObject: paywall,
  setImageObject: setPaywall,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  formMode,
}) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const consumerSubscriptionPlans = useSelector(selectSummaryListOfConsumerPlans)
  const [modalType, setModalType] = useState(PAYWALL_MODAL_TYPES.ADD_PACKAGE)
  const [selectedPaywallIndex, setSelectedPaywallIndex] = useState(-1)

  const handleClickOpen = () => {
    setOpen(true)
    setModalType(PAYWALL_MODAL_TYPES.ADD_PACKAGE)
    console.log(consumerSubscriptionPlans?.data)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedPaywallIndex(-1)
  }

  function handleAddPackage(selectedSubscriptionPlan) {
    setPaywall((prevPaywall) => ({
      ...prevPaywall,
      items: [
        ...prevPaywall.items,
        [
          {
            ...PAYWALL_ITEM,
            title: selectedSubscriptionPlan?.title || '',
            description: selectedSubscriptionPlan?.description || '',
            successText: selectedSubscriptionPlan?.subscriptionSuccessMsg || '',
            planId: selectedSubscriptionPlan?.id || '',
            preselected: selectedSubscriptionPlan?.preselected || false,
            popular: selectedSubscriptionPlan?.popular || false,
            price: {
              price: selectedSubscriptionPlan?.price || 0,
              discount: selectedSubscriptionPlan?.discount || 0,
              finalPrice: selectedSubscriptionPlan?.finalPrice || 0,
              currency: prevPaywall?.currencyCode || '',
            },
          },
        ],
      ],
    }))
    handleClose()
  }

  function handleOpenPackageModal(idx) {
    setOpen(true)
    setModalType(PAYWALL_MODAL_TYPES.ADD_PACKAGE_ITEM)
    setSelectedPaywallIndex(idx)
  }

  function handleAddPackageItem(selectedSubplan) {
    if (selectedPaywallIndex !== -1) {
      // Add to existing package
      setPaywall((prevPaywall) => ({
        ...prevPaywall,
        items: prevPaywall.items.map((packageItem, idx) => {
          if (idx === selectedPaywallIndex) {
            return [
              ...packageItem,
              {
                ...PAYWALL_ITEM,
                title: selectedSubplan?.title || '',
                description: selectedSubplan?.description || '',
                successText: selectedSubplan?.subscriptionSuccessMsg || '',
                planId: selectedSubplan?.id || '',
                preselected: selectedSubplan?.preselected || false,
                popular: selectedSubplan?.popular || false,
                price: {
                  price: selectedSubplan?.price || 0,
                  discount: selectedSubplan?.discount || 0,
                  finalPrice: selectedSubplan?.finalPrice || 0,
                  currency: prevPaywall?.currencyCode || '',
                },
              },
            ]
          }
          return packageItem
        }),
      }))
    }
    handleClose()
  }

  function handleAddSubPlanItemFromModal(subPlan) {
    switch (modalType) {
      case PAYWALL_MODAL_TYPES.ADD_PACKAGE:
        handleAddPackage(subPlan)
        break
      case PAYWALL_MODAL_TYPES.ADD_PACKAGE_ITEM:
        handleAddPackageItem(subPlan)
        break
      default:
        console.error('Invalid modal type')
        break
    }
  }

  useEffect(() => {
    dispatch(fetchConsumerSubscriptionPlans())
  }, [])

  return (
    <div className="w-100">
      <GridSectionWrapper shadow={false}>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="title"
            label={<span style={{ fontWeight: 'bold' }}>Title</span>}
            value={paywall?.title}
            onChange={handleChangeObject}
            disabled={formMode === 'View'}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="currencyCode"
            value={paywall?.currencyCode}
            getOptionValue={(option) => option?.code}
            getOptionLabel={(option) => option?.name}
            options={currency_list}
            disabled={formMode === 'View'}
            selectType={selectTypes.string}
            onChangeEvent={handleChangeObject}
            label={<span style={{ fontWeight: 'bold' }}>Currency</span>}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="platform"
            value={paywall?.platform}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.title}
            options={ALL_AVAILABLE_PLATFORMS_ARRAY.sort((a, b) => a?.title.localeCompare(b?.title))}
            disabled={formMode === 'View'}
            selectType={selectTypes.string}
            onChangeEvent={handleChangeObject}
            label={<span style={{ fontWeight: 'bold' }}>Platform</span>}
            labelPlacement={'top'}
            required
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper shadow={false}>
        {formMode !== 'View' && (
          <Grid container xs={12} md={12} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              disabled={formMode === 'View'}
            >
              Add Package
            </Button>
          </Grid>
        )}
        {/* <Grid container xs={12} md={12}> */}

        <div className="d-flex flex-column gap-5 w-100 mt-4">
          <Packages
            paywall={paywall}
            setPaywall={setPaywall}
            handleAdd={handleOpenPackageModal}
            formMode={formMode}
          />
        </div>
        {/* </Grid> */}

        <Dialog
          PaperComponent={AddPackageModalPaper}
          open={open}
          // TransitionComponent={Transition}
          // keepMounted
          onClose={handleClose}
          // aria-labelledby="alert-dialog-slide-title"
        >
          <AddPackagePaywall
            paywall={paywall}
            paymentMethods={paywall?.platform}
            handelChangeObject={handleChangeObject}
            handleClose={handleClose}
            setPaywall={setPaywall}
            handleAddPackage={handleAddSubPlanItemFromModal}
          />
        </Dialog>
      </GridSectionWrapper>
    </div>
  )
}
