import PropTypes from 'prop-types'
import React from 'react'
import DisplayMessageTemplateView from './displayMessageTemplateForm/DisplayMessageTemplateView'

export default function SentinelCardView(props) {
  // const [fetchingDetails, setFetchingDetails] = useState(false)

  // async function handleItemClick() {
  //   setFetchingDetails(true)
  //   handleClick(item, setFetchingDetails)
  //   setFetchingDetails(false)
  // }

  return (
    // <DispMsgViewContainer
    //   msgColor={item?.colors}
    //   clickFunction={!!handleClick}
    //   onClick={handleClick ? handleItemClick : () => {}}
    // >
    //   {fetchingDetails ? (
    //     <CircularProgress />
    //   ) : (
    //     <>
    //       <DisplayMsgViewDetails>
    //         <h5 className="mb-0">
    //           <DisplayTempBadge color="info">
    //             {RENDER_DISP_MSG(type || item?.type)}
    //           </DisplayTempBadge>
    //         </h5>
    //         <Collapse in={!collapsed}>
    //           {item?.titleList?.map((title, idx) => (
    //             <h5 key={idx}>{title || ''}</h5>
    //           ))}
    //           <DisplayMsgViewSubText>{item?.subText || '< NO SUB TEXT >'}</DisplayMsgViewSubText>
    //           {item?.actionBtn && (
    //             <DisplayMsgViewButtonText>
    //               Button Text -
    //               <DisplayMsgViewButtonLink
    //                 target="_blank"
    //                 rel="noreferrer"
    //                 href={item?.actionBtnUri}
    //               >
    //                 &quot;{item?.actionBtnText}&quot;
    //               </DisplayMsgViewButtonLink>
    //             </DisplayMsgViewButtonText>
    //           )}
    //         </Collapse>
    //       </DisplayMsgViewDetails>
    //       <DisplayMsgViewImg src={`${getMediaImageUrl()}${item.imageId}`} alt={item.imageId} />
    //     </>
    //   )}
    // </DispMsgViewContainer>
    <DisplayMessageTemplateView {...props} />
  )
}

SentinelCardView.propTypes = {
  item: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  collapsed: PropTypes.bool,
  type: PropTypes.string,
}
