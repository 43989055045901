import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'

export default function TransactionHistory({ data, setData, userId }) {
  const [loading, setLoading] = useState(false)
  // const [transactionHistory, setTransactionHistory] = React.useState([])
  const transactionHistory = data?.data

  async function getSubscriptionHistory() {
    const resp = await GetSettingsData(
      USERS_API_SIGNATURES_MAP.subscriptions.transactionHistory.get,
      { userIds: [userId]?.join(', '), size: 200 },
    )
    if (resp.status === 200) {
      setData((prev) => ({ ...prev, data: resp.data.content }))
    }
  }

  async function Setup() {
    if (transactionHistory?.length <= 0 || !transactionHistory) {
      setLoading(true)
      await getSubscriptionHistory()
    }
    setLoading(false)
  }

  useEffect(() => {
    Setup()
  }, [userId])

  return (
    <CCExtendedCSmartTable
      noUserNames
      items={transactionHistory}
      loading={loading}
      columns={columns}
      scopedColumns={scopedColumns}
      excludeGenericFields={[
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
      ]}
    />
  )
}

const columns = [
  { key: 'clientTransactionId', label: 'Transaction Id', sorter: true },
  { key: 'source', label: 'Source', sorter: true },
  { key: 'playTillAttempt', label: 'Play Till Attempt', sorter: true },
  { key: 'timeSpentOnPaymentScreen', label: 'Time Spent (min)', sorter: true },
  { key: 'status', label: 'Status' },
  { key: 'paywall', label: 'Paywall' },
  { key: 'paywallContext', label: 'Paywall Context' },
  { key: 'paywallDetails', label: 'Paywall Details' },
  {
    key: 'subscriptionPlan',
    label: 'Package',
  },
  { key: 'paymentMethod', label: 'Method' },
  { key: 'paymentGty', label: 'Gateway' },
  {
    key: 'platform',
    label: 'Platform',
  },
  { key: 'deviceName', label: 'Device' },
  { key: 'appVersion', label: 'App Version' },
  { key: 'country', label: 'Country' },
  {
    key: 'createdTime',
    label: 'Created Date',
    sorter: true,
  },
  {
    key: 'deviceDateTime',
    label: 'Device Time',
    sorter: true,
  },
]

const scopedColumns = {
  subscriptionPlan: (item) => {
    const val = item?.subscriptionPlan

    if (val) {
      var pkg = ''
      if (item.platform === 'android') {
        val.pymntGtyInfo.forEach(function (gtwy) {
          if (gtwy.pymntGtyId === 'android_iap') {
            pkg = gtwy.pymntGtyProductId
          }
        })
      } else if (item.platform === 'ios') {
        val.pymntGtyInfo.forEach(function (gtwy) {
          if (gtwy.pymntGtyId === 'ios_iap') {
            pkg = gtwy.pymntGtyProductId
          }
        })
      } else {
        val.pymntGtyInfo.forEach(function (gtwy) {
          if (gtwy.pymntGtyId === item.paymentGty) {
            pkg = gtwy.pymntGtyProductId
          }
        })
      }
      return <td>{pkg}</td>
    }
    return <td></td>
  },
  platform: (item) => {
    const val = item?.platform

    var platform = val?.toLowerCase()
    if (platform === 'android') {
      return <td>Android</td>
    } else {
      return <td>iOS</td>
    }
  },
  createdTime: (item) => {
    return (
      <td>
        {moment(item?.createdTime).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
      </td>
    )
  },
  deviceDateTime: (item) => {
    return (
      <td>
        {moment(item?.deviceDateTime).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
      </td>
    )
  },
}
