import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'
import { META_IMAGES_OBJECT } from './imageGallery-utils'

export const META_SLIDES_OBJECT = {
  apiParam: 'slide',
  colors: null,
  deleted: false,
  enabled: true,
  id: '',
  landscapeSlides: [],
  landscapeVideoId: '',
  portraitSlides: [],
  portraitVideoid: '',
}

export const META_IMAGE_SLIDE = {
  apiParam: 'image-slide',
  colors: COLORS_OBJECT,
  deleted: false,
  enabled: true,
  id: '',
  idByUser: false,
  image: META_IMAGES_OBJECT,
  inAt: 0,
  outAt: 3,
  preselected: true,
}
