import PropTypes from 'prop-types'
import React from 'react'
import { getMediaImageUrl } from 'src/services'

export default function ImageRenderComponent({ src, customSrc, styledComponent, ...restProps }) {
  const srcToUse = customSrc ? customSrc : `${getMediaImageUrl()}${src}`
  return (
    <>
      {styledComponent ? (
        <styledComponent {...restProps}></styledComponent>
      ) : (
        <img {...restProps} src={srcToUse} alt={restProps.alt || src} />
      )}
    </>
  )
}

ImageRenderComponent.propTypes = {
  src: PropTypes.string,
  styledComponent: PropTypes.any,
}
