import { Button, DialogContent, DialogTitle, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useContext, useRef, useState } from 'react'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { IMAGE_GALLERY_PAGES } from '../../../config/common/genericListingAndFormConfig'
import {
  AspectRatioContainer,
  GalleryImageContainer,
  ImageSizeSelectContent,
  ImageSizeSelectFooter,
  SelectImageTypeImageHeading,
} from '../styledComponents'
import { ImageUploadContext } from './ImageUpload'
import { ImageUploadChooseHeader } from './ImageUploadChoose'

export default function ImageSizeSelect({ setPage, selectedType, setSelectedType }) {
  const ImageContext = useContext(ImageUploadContext)
  const [uploadedFiles, setUploadedFiles] = useState({})
  const [fileUrls, setFileUrls] = useState({})
  const [store, dispatch] = useAppSettingsStore()
  const allImageTypes = store.imageTypes
  const inputRef = useRef({})

  function handleNextPage() {
    setPage(IMAGE_GALLERY_PAGES.imageForm)
    ImageContext.setUploadedFile(uploadedFiles)
  }
  function handleClick(type) {
    inputRef.current[type.id].click()
  }
  function handleInputChange(event, type) {
    if (!!event.target?.files && !!event.target.files[0]) {
      setUploadedFiles((prev) => {
        return { ...prev, [type.id]: event.target.files[0] }
      })
      setFileUrls((prev) => {
        return { ...prev, [type.id]: URL.createObjectURL(event.target.files[0]) }
      })
    }
  }
  //   useEffect(() => Setup(), [])

  return (
    <>
      <DialogTitle>
        <ImageUploadChooseHeader title="Enter Details for the Image" />
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column align-items-stretch justify-content-stretch">
          <ImageSizeSelectContent>
            {allImageTypes?.map((type) => (
              <Tooltip key={type.id} title={type.description}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <GalleryImageContainer onClick={() => handleClick(type)} className="p-4">
                    <AspectRatioContainer
                      uploaded={!fileUrls[type.id]}
                      aspectRatio={getAspectRatioFromResolutionString(type.resolution)}
                    >
                      {!fileUrls[type.id] ? (
                        getAspectRatioInText(type.resolution)
                      ) : (
                        <img
                          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                          src={fileUrls[type.id]}
                          alt="uploaded"
                        />
                      )}
                    </AspectRatioContainer>
                  </GalleryImageContainer>
                  <input
                    onChange={(e) => handleInputChange(e, type)}
                    multiple
                    type="file"
                    accept="image/*"
                    ref={(el) => (inputRef.current[type.id] = el)}
                    style={{ display: 'none' }}
                  />
                  <small>Min. Upload</small>
                  <small>{type.resolution}</small>
                  <SelectImageTypeImageHeading>{type.title}</SelectImageTypeImageHeading>
                </div>
              </Tooltip>
            ))}
          </ImageSizeSelectContent>
        </div>
      </DialogContent>
      <ImageSizeSelectFooter>
        <Button
          disabled={Object.keys(uploadedFiles).length === 0}
          onClick={handleNextPage}
          variant="contained"
          color="success"
          size="large"
        >
          Next
        </Button>
      </ImageSizeSelectFooter>
    </>
  )
}

ImageSizeSelect.propTypes = {
  setPage: PropTypes.func.isRequired,
  selectedType: PropTypes.object.isRequired,
  setSelectedType: PropTypes.func.isRequired,
}

export function getAspectRatioFromResolutionString(resolution) {
  const splitRes = resolution.split('x')
  const width = parseInt(splitRes[0])
  const height = parseInt(splitRes[1])
  return width / height
}

function gcd(a, b) {
  if (!b) {
    return a
  }

  return gcd(b, a % b)
}

function getAspectRatioInText(resolution) {
  const splitRes = resolution.split('x')
  const width = parseInt(splitRes[0])
  const height = parseInt(splitRes[1])
  const foundGcd = gcd(width, height) || 1
  return `${width / foundGcd}:${height / foundGcd}`
}
