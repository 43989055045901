export const CLINIC_BRAND_OBJECT = {
  id: '',
  createdBy: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  slug: '',
  colors: null,
  apiParam: 'clinic-brand',
  ownerUserIds: [],
  brandName: '',
  brandShortName: '',
  iconId: '',
  coverIcon: '',
  coverImage: '',
  adminUserIds: [],
}
