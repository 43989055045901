import { ArrowBack, Article, Assistant } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CCTextArea } from 'src/components/controlCenter/FormComponents'
import { checkIfStringIsValid, genericHandleChange } from 'src/utilities/generalUtils'
import {
  DescriptionSelectionButton,
  DescriptionSelectionButtonsContainer,
} from './styled-components'

const ACTIVITY_DESCRIPTION_PAGES = {
  selectionPage: 'selectionPage,',
  default: 'default',
  customDescription: 'custom',
}

export default function ActivityDescriptionSelection({
  mainActivityObject,
  disabled,
  handleChangeMainActivityObject,
  day,
  closeModal,
}) {
  const [activityObject, setActivityObject] = useState()
  const [pageStack, setPageStack] = useState([])

  function handleChangeObject(event) {
    genericHandleChange(event, setActivityObject)
  }

  function addPage(page) {
    setPageStack((prev) => [...prev, page])
  }

  function popPage() {
    setPageStack((prev) => prev.slice(0, -1))
  }

  function Setup() {
    setPageStack([ACTIVITY_DESCRIPTION_PAGES?.selectionPage])
    setActivityObject(mainActivityObject)
    console.log({ mainActivityObject })
  }
  function handleSubmit() {
    // handleChangeMainActivityObject(activityObject)
    if (pageStack[pageStack.length - 1] === ACTIVITY_DESCRIPTION_PAGES.customDescription) {
      handleChangeMainActivityObject({
        target: { value: activityObject.customDescription, id: 'customDescription' },
      })
    } else {
      handleChangeMainActivityObject({
        target: { value: null, id: 'customDescription' },
      })
      handleChangeMainActivityObject({
        target: { value: activityObject?.descriptionZ, id: 'descriptionZ' },
      })
      handleChangeMainActivityObject({
        target: { value: activityObject?.descriptionW, id: 'descriptionW' },
      })
    }
    closeModal()
  }

  function renderPage() {
    switch (pageStack[pageStack.length - 1]) {
      case ACTIVITY_DESCRIPTION_PAGES.selectionPage:
        return <SelectionPage addPage={addPage} />
      case ACTIVITY_DESCRIPTION_PAGES.default:
        return (
          <DefaultDescription
            activityObject={activityObject}
            handleChangeObject={handleChangeObject}
            disabled={disabled}
            day={day}
          />
        )
      case ACTIVITY_DESCRIPTION_PAGES.customDescription:
        return (
          <CustomDescription
            activityObject={activityObject}
            handleChangeObject={handleChangeObject}
            disabled={disabled}
            day={day}
          />
        )
      default:
        return <SelectionPage addPage={addPage} />
    }
  }

  function renderTitle() {
    switch (pageStack[pageStack.length - 1]) {
      case ACTIVITY_DESCRIPTION_PAGES.selectionPage:
        return 'Choose Description Type'
      case ACTIVITY_DESCRIPTION_PAGES.default:
        return 'Change Description'
      case ACTIVITY_DESCRIPTION_PAGES.customDescription:
        return 'Set your description'
      default:
        return 'Choose Description Type'
    }
  }

  useEffect(() => {
    Setup()
  }, [mainActivityObject])
  return (
    <>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: pageStack.length > 1 ? '10px' : '30px',
          gap: '10px',
        }}
      >
        {pageStack.length > 1 && (
          <IconButton onClick={popPage}>
            <ArrowBack sx={{ fontSize: '25px' }} />
          </IconButton>
        )}
        <span>{renderTitle()}</span>
      </DialogTitle>
      <DialogContent>{renderPage()}</DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          Close
        </Button>
        {pageStack?.length > 1 && (
          <Button onClick={handleSubmit} variant="contained">
            Set
          </Button>
        )}
      </DialogActions>
    </>
  )
}

function DefaultDescription({ activityObject, handleChangeObject, disabled, day }) {
  return (
    <>
      <div className="d-flex flex-column gap-1 align-items-start mb-4">
        <h4>Description Sentence </h4>
        <span style={{ width: '100%' }}>
          We are offering you{' '}
          <strong style={underLineStyled}>
            {activityObject?.customCategoryTitle?.toLowerCase() || 'Custom Activity Type Title'}
          </strong>{' '}
          {day !== undefined && (
            <>
              on <strong>day {day} </strong>
            </>
          )}
          so as to{' '}
          <strong style={underLineStyled}>
            {activityObject?.descriptionZ?.toLowerCase() || 'What will this activity do to you'}
          </strong>{' '}
          because{' '}
          <strong style={underLineStyled}>
            {activityObject?.descriptionW?.toLowerCase() || 'Why am I offering you this today'}
          </strong>
        </span>
      </div>
      <div className="d-flex gap-2">
        <CCTextArea
          id="descriptionZ"
          colNumber={6}
          label="What will this activity do to you"
          value={activityObject?.descriptionZ}
          onChange={handleChangeObject}
          placeholder="Enter details...."
          disabled={disabled}
          invalid={!checkIfStringIsValid(activityObject.descriptionZ)}
          required
        />
        <CCTextArea
          id="descriptionW"
          colNumber={6}
          label="Why am I offering you this today"
          value={activityObject?.descriptionW}
          onChange={handleChangeObject}
          placeholder="Enter details...."
          disabled={disabled}
          invalid={!checkIfStringIsValid(activityObject.descriptionW)}
          required
        />
      </div>
    </>
  )
}
function CustomDescription({ activityObject, handleChangeObject, disabled, day }) {
  return (
    <CCTextArea
      id="customDescription"
      colNumber={12}
      label="Custom Description (Will override the description sentence)"
      value={activityObject?.customDescription}
      disabled={disabled}
      onChange={handleChangeObject}
      placeholder="Enter details...."
    />
  )
}

function SelectionPage({ addPage }) {
  return (
    <DescriptionSelectionButtonsContainer>
      <DescriptionSelectionButton
        color="secondary"
        onClick={() => addPage(ACTIVITY_DESCRIPTION_PAGES.default)}
      >
        <Assistant sx={{ fontSize: '40px' }} />
        <span>Use Default Description</span>
      </DescriptionSelectionButton>
      <DescriptionSelectionButton
        color="secondary"
        onClick={() => addPage(ACTIVITY_DESCRIPTION_PAGES.customDescription)}
      >
        <Article sx={{ fontSize: '40px' }} />
        <span>Use Custom Description</span>
      </DescriptionSelectionButton>
    </DescriptionSelectionButtonsContainer>
  )
}

const underLineStyled = {
  textDecoration: 'underline',
}
