import { FormControlLabel, Grid, Switch } from '@mui/material'
import React from 'react'
import CCRichTextEditor from 'src/components/controlCenter/CCRichTextEditor'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import CopyOrOpenInANewTabInWebApp from 'src/components/controlCenter/common/CopyOrOpenInANewTabInWebApp'
import { disableForContentWriter } from 'src/config/common/genericListingAndFormConfig'
import { ARTICLE_TYPES, TAG_TYPE } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

export default function ArticleViewComponent({
  initialObject = {},
  imageObject,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const dropDownOptions = {
    ...store,
    categories: store.categories,
    languages: store.languages,
    tags: store.tags,
    imageTypes: store.imageTypes,
    mentalStateTypes: store.mentalStateTypes,
    gender: [
      { id: 'male', name: 'male' },
      { id: 'female', name: 'Female' },
      { id: 'neutral', name: 'Neutral' },
    ],
    subTitleType: [
      { id: 'vtt', name: 'VTT' },
      { id: 'srt', name: 'SRT' },
      { id: 'txt', name: 'Text' },
    ],
    trackType: [
      {
        id: 'audio',
        name: 'Audio',
      },
      {
        id: 'video',
        name: 'Video',
      },
    ],
    statValueType: [
      {
        id: 'number',
        name: 'Number',
      },
      {
        id: 'words',
        name: 'Words',
      },
      {
        id: 'seconds',
        name: 'Time in seconds',
      },
      {
        id: 'days',
        name: 'Time in Days',
      },
    ],
    tagType: [
      {
        id: 'tag',
        name: 'Tag',
        enabled: true,
      },
      {
        id: 'domain',
        name: 'Domain',
        enabled: true,
      },
      {
        id: 'genre',
        name: 'Genre',
        enabled: true,
      },
      {
        id: 'feeling',
        name: 'Feeling',
        enabled: true,
      },
    ],
    ...customOptions,
  }

  return (
    <Grid container spacing={1} sx={{ height: '100%' }}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              size={'small'}
              id="enabled"
              checked={!!imageObject?.enabled || false}
              onChange={handleChangeObject}
              disabled={disabled || disableForContentWriter()}
            />
          }
          label={!!imageObject?.enabled ? 'Enabled' : 'Disabled'}
        />
      </Grid>
      <Grid item xs={12}>
        <CCFormInput
          id="title"
          label="Title"
          value={imageObject?.title}
          onChange={handleChangeObject}
          placeholder="Enter title..."
          required
          colNumber={12}
          invalid={imageObject?.title === ''}
        />
      </Grid>
      <Grid item xs={12}>
        <CCTextArea
          id="shortDesc"
          label="Short Description"
          value={imageObject?.shortDesc}
          onChange={handleChangeObject}
          placeholder="Enter a short description...."
          rows={3}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomReactSelect
          id="subscription"
          label="Subscription Type"
          placeholder="Select Subscription Type..."
          value={imageObject?.subscription}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title || option.name}
          options={dropDownOptions?.allPlanLevels}
          col={6}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.object}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomReactSelect
          id="type"
          label="Article Type"
          placeholder="Select Article Type..."
          value={imageObject?.type}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title || option.name}
          options={ARTICLE_TYPES}
          col={6}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <CustomReactSelect
          id="domains"
          label="Domain Ids"
          placeholder="Select Domain Ids..."
          value={imageObject?.domains}
          isMulti={true}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title || option.name}
          options={dropDownOptions.tags.filter((tag) => tag.type === TAG_TYPE.domain)}
          col={6}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomReactSelect
          id="faqCategories"
          label="FAQ Categories"
          placeholder="Select FAQ Categories..."
          value={imageObject?.faqCategories}
          isMulti={true}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title || option.name}
          options={dropDownOptions.faqCategories}
          col={6}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomReactSelect
          id="primaryTags"
          label="Primary Tags"
          placeholder="Select Tag..."
          value={imageObject?.primaryTags}
          isMulti={true}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title || option.name}
          options={dropDownOptions?.tags}
          col={6}
          onChangeEvent={handleChangeObject}
          selectType={selectTypes.string}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomReactSelect
          id="secondaryTags"
          value={imageObject?.secondaryTags}
          label="Secondary Tags"
          placeholder="Select Tag..."
          isMulti={true}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title || option.name}
          options={dropDownOptions?.tags}
          col={6}
          selectType={selectTypes.string}
          onChangeEvent={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="start" alignItems="center">
        <strong>Public Link: </strong>
        <CopyOrOpenInANewTabInWebApp postFixUrl={`articles/${imageObject?.slug}`} />
      </Grid>
      <Grid item xs={12} sx={{ height: '100%' }}>
        <CCRichTextEditor
          htmlContent={imageObject?.content}
          handleChangeHtml={handleChangeObject}
          handleChangeRaw={() => {}}
          disabled={disabled}
          id={'content'}
          initialContent={initialObject?.content}
        />
      </Grid>
      <Grid item xs={12}>
        <EntityBasedImageUploadComponent
          disabled={disabled}
          entity={entity}
          entityObject={imageObject}
          handleChange={handleChangeObject}
        />
      </Grid>
    </Grid>
  )
}
