import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const fetchCurrentClinicChambers = createAsyncThunk(
  'clinicFlowData/fetchCurrentClinicChambers',
  async (props, { getState, fulfillWithValue, rejectWithValue, dispatch }) => {
    fetchCurrentClinicChambers.pending()
    const { clinicFlowData } = getState()
    const currentClinicData = clinicFlowData.currentClinicData.data
    const currentClinicId = currentClinicData?.id
    const currentClinicChambers = clinicFlowData.clinicChambersForCurrentClinic.data

    const clinicId = props?.clinicId || currentClinicId
    const clinicBrandId = props?.clinicBrandId || clinicFlowData.currentClinic?.data?.clinicBrandId

    if (clinicId === currentClinicChambers?.[0]?.clinicId) {
      dispatch(fetchCurrentClinicChambers.fulfilled(currentClinicChambers))
    }

    const resp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicChamber,
      clinicId: clinicId,
      clinicBrandId: clinicBrandId,
    })
    if (resp) {
      return resp
    } else {
      return rejectWithValue('Something went wrong')
    }
  },
)
