import { convertArrayToPaginatedObject } from 'src/common/types/serverFetch/getArrayAsServerPaginatedObject'
import { convertMetaCategoryTypeToSwitchCaseType } from 'src/common/utils/categoryType/converters'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import { IdTypes } from 'src/utilities/constants'

export async function getAdditionalItemData({
  catType,
  itemId,
  idType = IdTypes.id,
  page,
  search,
}) {
  const categoryType = convertMetaCategoryTypeToSwitchCaseType(catType)
  const toReturn = convertArrayToPaginatedObject([])
  console.log({ categoryType, catType })

  switch (categoryType) {
    case 'mentor':
      const mentorResponseData = await GetDetailedEntity('mentor', { id: itemId })
      if (mentorResponseData) {
        toReturn.content =
          mentorResponseData?.eventTypes
            ?.filter((eventItem) => eventItem?.uid && eventItem?.publicEvent && eventItem?.enabled)
            ?.map((eventItem) => ({
              ...eventItem,
              label: `${eventItem?.title} (${eventItem?.durationInMins} Mins) ${
                eventItem?.free ? '(Free)' : ''
              }`,
              value: eventItem?.uid,
              slug: eventItem?.slug,
            })) || []
      }
      break
    default:
      toReturn.content = []
      break
  }
  return toReturn
}

export async function dlGetExtraItemTitlesForTypeMetaCatAndItemId(
  itemId,
  metaCategoryType,
  extraItemId,
  extraItemCategoryType,
  extraItemIdType,
  baseItem,
) {
  const categoryType = convertMetaCategoryTypeToSwitchCaseType(metaCategoryType)
  let toReturnTitle
  console.log({ categoryType, metaCategoryType })
  switch (categoryType) {
    case 'mentor':
      const mentorResponseData = await GetDetailedEntity('mentor', { id: itemId })
      console.log({ mentorResponseData })
      if (mentorResponseData) {
        const foundEvent = mentorResponseData?.eventTypes?.find((evt) => evt?.uid === extraItemId)
        console.log({ foundEvent, extraItemId, mentorResponseData })
        toReturnTitle = [
          {
            label: foundEvent
              ? `${foundEvent?.title} (${foundEvent?.durationInMins} Mins) ${
                  foundEvent?.free ? '(Free)' : ''
                }`
              : ``,
          },
        ]
      }
      break
    default:
      toReturnTitle = []
      break
  }
  return toReturnTitle
}
