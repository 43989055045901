import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { Suspense, useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoadingSpinner from './components/controlCenter/LoadingSuspense'
import { AuthContextProvider } from './context/AuthContext'
import { ThemeContext } from './context/ThemeContext'
import './scss/style.scss'
import { defaultApiUrl } from './services'
import ProtectedRoute from './views/pages/login/ProtectedRoute'

// const loading = (
// )

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/signIn'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  sessionStorage.setItem(
    'languageCode',
    sessionStorage.getItem('languageCode') ||
      (navigator?.language || navigator?.userLanguage).split('-')[0],
  )
  localStorage.setItem('apiUrl', localStorage.getItem('apiUrl') || defaultApiUrl)
  const { theme } = useContext(ThemeContext)
  if (moment.locale() !== 'en-us') {
    moment.locale('en-us')
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-us'}>
          <AuthContextProvider>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                path="*"
                name="Home"
                element={
                  <ProtectedRoute>
                    <DefaultLayout />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthContextProvider>
        </LocalizationProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          theme={theme}
          pauseOnFocusLoss
          draggable
          transition={Slide}
          pauseOnHover
        />
      </Suspense>
    </BrowserRouter>
  )
}

export default App
