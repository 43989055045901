import { META_CATEGORY_TYPES_TO_API_PARAM } from 'src/utilities/constants'

export function convertMetaCategoryTypeToSwitchCaseType(categoryType) {
  return META_CATEGORY_TYPES_TO_API_PARAM[categoryType] || categoryType
}
export function convertMetaCategoryTypeToMetaCategoryTypeForDL(catType) {
  const categoryType = convertMetaCategoryTypeToSwitchCaseType(catType)
  const catTypeToOptionTypeMap = {
    mentor: 'teacher',
    'tile-policy': 'tile',
  }
  return catTypeToOptionTypeMap[categoryType] || categoryType
}

export function convertDLMetaCategoryTypeToSwitchCaseMetaCategoryType(catType) {
  const optionTypeToCatTypeMap = {
    teacher: 'mentor',
    tile: 'tiles',
  }
  return optionTypeToCatTypeMap[catType] || catType
}

export function convertSwitchCaseCategoryTypeToMetaCategoryType(categoryType) {
  const invertedMetaCategoryTypesToApiParam = {}
  for (const key in META_CATEGORY_TYPES_TO_API_PARAM) {
    if (META_CATEGORY_TYPES_TO_API_PARAM.hasOwnProperty(key)) {
      const value = META_CATEGORY_TYPES_TO_API_PARAM[key]
      invertedMetaCategoryTypesToApiParam[value] = key
    }
  }
  return invertedMetaCategoryTypesToApiParam[categoryType] || categoryType
}

export function convertDLMetaCategoryTypeToMetaCategoryType(catType) {
  const switchCaseMetaCategoryType = convertDLMetaCategoryTypeToSwitchCaseMetaCategoryType(catType)
  return convertSwitchCaseCategoryTypeToMetaCategoryType(switchCaseMetaCategoryType)
}
