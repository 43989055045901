import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'

export async function transformClinicAssistantListingData(clinicAssistants) {
  var clinicAssistantsToReturn = await getClinicAssistantUserNames(clinicAssistants)
  //   clinicAssistantsToReturn = await getClinicAssistantClinicNames(clinicAssistantsToReturn)
  //   clinicAssistantsToReturn = await getClinicAssistantClinicBrandNames(clinicAssistantsToReturn)
  return clinicAssistants
}

async function getClinicAssistantUserNames(clinicAssistants) {
  const userIdsToUse = clinicAssistants?.map((clinicAssistant) => clinicAssistant?.userId)
  const foundUsers = await getUsersSummaryFromUserIds(userIdsToUse)
  return clinicAssistants?.map((clinicAssistant) => {
    const foundUser = foundUsers?.find((user) => user?.userId === clinicAssistant?.userId)
    return { ...clinicAssistant, user: foundUser?.name }
  })
}
