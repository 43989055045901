import { Chip } from '@mui/material'
import React from 'react'

export default function OnlineCourseCourseStatusChip({ onlineCourse }) {
  if (!onlineCourse?.enabled) {
    return <Chip label="Disabled" color="secondary" />
  }

  if (onlineCourse?.status === 'pending') {
    return <Chip label="Approval Pending" color="secondary" />
  }
  if (onlineCourse?.status === 'published') {
    return <Chip label="Published" color="success" />
  }
  if (onlineCourse?.status === 'unpublished') {
    return <Chip label="Unpublished" color="secondary" />
  }
  if (onlineCourse?.status === 'draft') {
    return <Chip label="Draft" color="warning" />
  }
  if (onlineCourse?.status === 'approved') {
    return <Chip label="Approved" color="primary" />
  }
  if (onlineCourse?.status === 'rejected') {
    return <Chip label="Rejected" color="error" />
  }

  //   <p v-if="onlineCourse.status=='draft'" class="text-info pt-1 font-weight-bold">Draft</p>
  //   <p v-if="onlineCourse.status=='pending'" class="text-warning pt-1 font-weight-bold">Pending</p>
  //   <p v-if="onlineCourse.status=='approved'" class="text-primary pt-1 font-weight-bold">Approved</p>
  //   <p v-if="onlineCourse.status=='published'" class="text-success pt-1 font-weight-bold">Published</p>
  //   <p v-if="onlineCourse.status=='rejected'" class="text-danger pt-1 font-weight-bold">Rejected</p>
  //   <p v-if="onlineCourse.status=='unpublished'" class="text-secondary pt-1 font-weight-bold">Unpublied</p>

  return <></>
}
