import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'

export default function PaymentGatewaySKUHeaderFirstLayer({ filters, dropDownData, setFilters }) {
  return (
    <>
      <div style={{ width: '300px' }}>
        <CustomReactSelect
          id="grpId"
          value={filters?.grpId}
          options={dropDownData?.allPlanGroups}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option?.name}
          placeholder="Select a plan groups..."
          label="Plan Groups"
          isClearable
          onChangeEvent={setFilters}
          selectType={selectTypes.string}
        />
      </div>
      <div style={{ width: '300px' }}>
        <CustomReactSelect
          id="paymentGatewayList"
          isMulti
          value={filters?.paymentGatewayList}
          options={dropDownData?.paymentGateways}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option?.title}
          placeholder="Select a gateway..."
          label="Payment Gateways"
          onChangeEvent={setFilters}
          selectType={selectTypes.string}
        />
      </div>
    </>
  )
}
