import { APP_FEATURE_OBJECT } from './AppFeature'

export const AMHM_EVENT = {
  ...APP_FEATURE_OBJECT,
  durationInMins: 20,
  preBufferMins: 0,
  postBufferMins: 0,
  type: '1on1', //group-interactive, group-broadcast
  free: false,
  publicEvent: true,
  companyIds: [],
  meetingSrc: '',
  meetingSrcLink: '',
  shortLink: '',
  panelIds: [],
  minUsers: 1,
  maxUsers: 1,
  contextId: '',
  location: null,
  preActions: [],
  postActions: [],
  clinicBrandId: '',
}
