import { toast } from 'react-toastify'
import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { SETTINGS_API_LIST } from './api-utils'

export const TEACHER_OBJECT = {
  afflliationIds: [],
  altTexts: {
    explore: '',
    home: '',
    journal: '',
    sdk: '',
    sleep: '',
    sound: '',
    story: '',
    yoga: '',
  },
  apiParam: 'mentor',
  backdropImage: '',
  backdropImageColor: '',
  backgroundImage: '',
  blogBanner: '',
  blogBannerColor: '',
  categories: [],
  categoryPlaceholder: '',
  chatEnabled: true,
  chatWelcomeMsg: '',
  colors: COLORS_OBJECT,
  compliments: {
    attitude: 0,
    instructions: 0,
    onTime: 0,
    peace: 0,
    voiceQuality: 0,
  },
  connectedCalendars: [],
  contentCategories: [],
  countries: [],
  courseWelcome: true,
  courseWelcomeMsg: '',
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  customLongLink: '',
  customShortLink: '',
  defaultContentCategory: '',
  deleted: false,
  description: '',
  donationEnabled: true,
  email: '',
  enabled: true,
  entityItemType: '',
  eventTypes: [],
  exploreListable: true,
  favCount: 0,
  favorite: true,
  featureTeacherOrder: 0,
  featuredTeacher: true,
  firstName: '',
  freePlanCreated: true,
  heroCardImage: '',
  heroCardImageColor: '',
  hrsTaught: 0,
  id: '',
  idByUser: false,
  instituteIds: [],
  instituteName: '',
  instituteTeacher: true,
  journalListable: true,
  kycStatus: '',
  languages: [],
  lastName: '',
  level: 0,
  listableInApp: true,
  listableInWeb: true,
  liveSessionsCount: 0,
  location: {
    address: '',
    city: '',
    country: '',
    countryCode: '',
    id: '',
    lat: 0,
    lng: 0,
    pincode: '',
    resolvedBy: '',
    state: '',
    stateCode: '',
  },
  master: true,
  masteredMeditationIds: [],
  meditationIds: [],
  mobile: '',

  noOfRating: 0,
  notificationSettings: {
    chat: true,
    comment: true,
    donation: true,
    notification: true,
  },
  onboardingImage: '',
  outGoingAumhumEmail: '',
  paidPlanCreated: true,
  personalize: {
    affiliations: {
      missingSchools: [],
      missingStudios: [],
      onlineDetails: [],
      schools: [],
      studio: [],
    },
    bankDetailsProvided: true,
    callbackRequestAcknowledged: true,
    certificates: [],
    done: true,
    experties: {
      iAmTeacher: true,
      iWantToBeATeacher: true,
    },
    iDontTeachStatus: '',
    identityVerification: {
      idProof: '',
      location: {
        address: '',
        city: '',
        country: '',
        countryCode: '',
        id: '',
        lat: 0,
        lng: 0,
        pincode: '',
        resolvedBy: '',
        state: '',
        stateCode: '',
      },
    },
    partnerWith: {
      certifiedTeacher: true,
      ownPractices: true,
      schoolPractices: true,
    },
    sendCallbackRequest: true,
    teacherTrainingCompleted: true,
    teacherTraningProgress: {},
    whatITeach: {
      meditation: true,
      yoga: true,
    },
    whereILikeToLearn: [],
    whereITeach: {
      iDontTeach: true,
      iTeachAtHome: true,
      iTeachAtMyOwnStudio: true,
      iTeachAtSchool: true,
      iTeachAtStudio: true,
      iTeachOnline: true,
    },
  },
  planSectionTitle: '',
  planWelcome: true,
  planWelcomeMsg: '',
  prioritySetting: {},
  profilePicColor: '',
  profilePicId: '',
  rating: 0,
  schedules: [],
  schoolId: '',
  sdkListable: true,
  services: {
    inPerson: true,
    live: true,
    recorded: true,
  },
  shortDesc: '',
  shortLink: '',
  showMyProfileAs: '',
  sleepListable: true,
  slug: '',
  soundListable: true,
  storyListable: true,
  studentsCount: 0,
  testimonials: [],
  timeZone: {},
  title: '',
  translations: {},
  unlockPoints: 0,
  userId: '',
  userNumber: '',
  verticalRectangle: '',
  verticalRectangleColor: '',
  welcomeMsg: '',
  yogaListable: true,
}
export function getApprovedCondition(item) {
  return item?.kycStatus !== 'approved'
}

export async function approveTeacher(teacherItem, refreshFunc) {
  // const tst = toast.loading('Approving Teacher...')
  // const response = await GetSettingsData(SETTINGS_API_LIST.mentors.approve.url, {
  //   teacherId: teacherItem?.id,
  // })
  // if (response.status === 200) {
  //   toast.update(tst, {
  //     render: 'Teacher Approved',
  //     type: toast.TYPE.SUCCESS,
  //     isLoading: false,
  //     ...TOAST_UTILS,
  //   })
  // } else {
  //   toast.update(tst, {
  //     render: 'Teacher could not be approved',
  //     type: toast.TYPE.ERROR,
  //     isLoading: false,
  //     ...TOAST_UTILS,
  //   })
  // }
  await approveRole(teacherItem, 'ROLE_CREATOR', 'mentor', refreshFunc)
}

export async function approveDisapprovePrescription(teacherItem) {
  var tst
  var urlToUse = SETTINGS_API_LIST.mentors.allowPrescribe.url

  if (teacherItem?.allowedToPrescribe) {
    tst = toast.loading('Denying to prescribe...')
    urlToUse = SETTINGS_API_LIST.mentors.denyPrescribe.url
  } else {
    tst = toast.loading('Allowing to prescribe...')
  }
  const response = await PostSettingsData(urlToUse, {}, { mentorIds: teacherItem?.id })
  if (response.status === 200) {
    toast.update(tst, {
      render: 'Success',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      ...TOAST_UTILS,
    })
  } else {
    toast.update(tst, {
      render: 'Something went wrong',
      type: toast.TYPE.ERROR,
      isLoading: false,
      ...TOAST_UTILS,
    })
  }
}
export async function getMentorUserDetails(teacherItem) {
  const userIdsToUse = teacherItem?.userId

  const resp = await GetSettingsData(SETTINGS_API_LIST.userNames.get.url, {
    userIds: userIdsToUse,
    role: 'ROLE_USER',
  })
  if (resp.status === 200) {
    return resp.data?.[0] || {}
  }
}

export async function approveRole(formData, role, apiParam, refreshFunc) {
  const tst = toast.loading(`Approving ${apiParam}...`)
  const response = await GetSettingsData(SETTINGS_API_LIST.roles.approve.url, {
    id: formData?.id,
    role: role,
  })
  if (response.status === 200) {
    const approvalResponse = response.data
    if (response.data.code === 0) {
      toast.update(tst, {
        render: `${apiParam} approved`,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      await refreshFunc()
    } else {
      toast.update(tst, {
        render: approvalResponse.message,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
    }
  } else {
    toast.update(tst, {
      render: `${apiParam} could not be approved`,
      type: toast.TYPE.ERROR,
      isLoading: false,
      ...TOAST_UTILS,
    })
  }
  return response
}

export async function approveClinic(formData, refreshFunc) {
  return await approveRole(formData, 'ROLE_CLINIC_PROSPECT', 'clinic', refreshFunc)
}
