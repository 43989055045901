import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'

export async function transformClinicListingData(fetchedData) {
  const listWithOwnerNames = await getClinicOwnerNames(fetchedData)
  const listWithClinicBrandNames = await getClinicBrandNames(listWithOwnerNames)

  return listWithClinicBrandNames
}

async function getClinicOwnerNames(clinicList) {
  const userIdsToUse = clinicList?.map((clinic) => clinic?.ownerId)
  const foundUsers = await getUsersSummaryFromUserIds(userIdsToUse)
  return clinicList?.map((clinic) => {
    const foundUser = foundUsers?.find((user) => user?.userId === clinic?.userId)
    return { ...clinic, owner: foundUser?.name }
  })
}

async function getClinicBrandNames(clinicList) {
  const brandIdsToUse = clinicList?.map((clinic) => clinic?.clinicBrandId)?.filter((id) => !!id)
  const foundBrands = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES.clinicBrand,
    ids: brandIdsToUse?.join(','),
  })
  return clinicList?.map((clinic) => {
    const foundBrand = foundBrands?.find((brand) => brand?.id === clinic?.clinicBrandId)
    return { ...clinic, brandName: foundBrand?.title || foundBrand?.brandName }
  })
}
