import styled from '@emotion/styled'
import { BrokenImage, Person } from '@mui/icons-material'
import { Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { NoImage, PreviewBox } from 'src/components/controlCenter/styledComponents'
import { getMediaImageUrl } from 'src/services'
import { useImageLoaded } from '../../../utilities/hooks/useImageLoaded'
import { StyledTableAvatarComponent } from './styledComponents'

const GalleryImage = styled.img`
  max-height: 100%;
  border-radius: 6px;
  opacity: ${({ showImage }) => (showImage ? '1' : '0.2')};
  transition: all 0.3s ease-in-out;
`

export default function TableImage({ image, idx, handleImageClick }) {
  return (
    <td>
      <div onClick={(e) => e.stopPropagation()}>
        <TableImageComponent
          colors={image?.colors}
          imageId={image.fileId || image.imageId}
          handleClick={() => handleImageClick(image, idx)}
        />
      </div>
    </td>
  )
}

export function TableImageComponent({
  colors,
  imageId,
  customSrc,
  handleClick,
  debug = false,
  hideBrokenImage = false,
  sx,
  ...rest
}) {
  const [ref, loaded, onLoad, resetLoad] = useImageLoaded()

  const srcToUse = imageId ? `${getMediaImageUrl()}${imageId}` : customSrc

  useEffect(() => {
    resetLoad()
  }, [srcToUse])

  return (
    <PreviewBox
      sx={sx}
      justifyContent="center"
      type={colors?.type || ''}
      degree={colors?.degrees || 0}
      colorIds={!!colors ? colors?.colorIds : []}
      onClick={handleClick}
    >
      {!loaded && !!srcToUse && true && (
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backdropFilter: 'blur(3px)',
          }}
        >
          <CircularProgress
            sx={{
              height: '30% !important',
              width: '30% !important',
              maxHeight: '40px',
              maxWidth: '40px',
            }}
          />
        </div>
      )}
      {!srcToUse && !hideBrokenImage ? (
        <NoImage style={{ borderRadius: '7px', backgroundColor: '#9f9f9f69' }}>
          <BrokenImage />
        </NoImage>
      ) : (
        <GalleryImage
          ref={ref}
          key={srcToUse}
          src={srcToUse}
          alt={srcToUse}
          loading="lazy"
          showImage={loaded}
          onLoad={onLoad}
        />
      )}
    </PreviewBox>
  )
}

export function TableAvatarComponent({
  imageId,
  customSrc = '',
  CustomAvatarIcon = Person,
  children,
}) {
  const [ref, loaded, onLoad, resetLoad] = useImageLoaded()
  const urlToUse = imageId ? `${getMediaImageUrl()}${imageId}` : customSrc

  useEffect(() => {
    resetLoad()
  }, [urlToUse])
  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      {!loaded && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
      <StyledTableAvatarComponent
        ref={ref}
        key={urlToUse}
        sx={{ width: 56, height: 56 }}
        loading="lazy"
        src={urlToUse}
        onLoad={onLoad}
      >
        <CustomAvatarIcon />
      </StyledTableAvatarComponent>
      {children}
    </Box>
  )
}

// api/media/file

TableImageComponent.propTypes = {
  colors: PropTypes.object,
  imageId: PropTypes.string,
  handleClick: PropTypes.func,
}
TableAvatarComponent.propTypes = {
  imageId: PropTypes.string,
}
TableImage.propTypes = {
  image: PropTypes.object,
  idx: PropTypes.number,
  handleImageClick: PropTypes.func,
}
