import { Slider, styled as muiStyled } from '@mui/material'
import ReactPlayer from 'react-player'

export const CustomPlayerSliderContainer = muiStyled('div')(
  ({ theme }) => `
  width: 85%;
  color: white;
  display: flex;
  gap: 17px;
  align-items: center;
  
  .duration-text {
    font-size: 14px;
    color: #fff;
    flex-shrink: 0;
    font-family: "Roboto Mono", monospace;
  }
  
  @media (min-width: 768px) {
    width: 90%;
    .duration-text {
    font-size: 16px;
  }
  }
  @media (min-width: 1300px) {
      width: 95%;
  }
  `,
)

export const CustomPlayerControlsContainer = muiStyled('div')(
  ({ theme }) => `
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 2px 14px;
      position:absolute;
      bottom:0;
      width: 100%;
      background: #0000008c;
      backdrop-filter: blur(15px);
      @media(min-width: 576px){
        padding: 5px 14px;
      }
      @media (min-width: 768px) {
        padding: 5px 14px;
      }
      
  `,
)

export const ContainerWithBoxShadowBlurred = muiStyled('div')(
  ({ theme }) => `
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      flex-direction: column;
      padding: 80px 30px;
      justify-content: center;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      gap:40px;
      align-items: center;
      text-align: center;
      background:#efcbffa6;
      z-index: 1;
      color: #000;
      box-shadow: 0px 21px 11px 8px #efcbffa6;
      &::before {
        content: "";
        position: absolute;
        z-index:-1;
        inset:-100px;
        -webkit-backdrop-filter: blur(13.1px);  // for safari
        backdrop-filter: blur(13.1px);
        mask: 
        linear-gradient(to top, transparent 0%, red 90px calc(100% - 90px), transparent 100%), 
        linear-gradient(to left, transparent 0%, red 90px calc(100% - 90px), transparent 100%);
      -webkit-mask: 
        linear-gradient(to top, transparent 0%, red 90px calc(100% - 90px), transparent 100%), 
        linear-gradient(to left, transparent 0%, red 90px calc(100% - 90px), transparent 100%);
        mask-composite: intersect;
        -webkit-mask-composite: source-in;
      }
  
  
  
      .card-details-text {
        font-size: 34px;
        width: 100%;
        font-weight: 600;
      }
      .card-details-text-secondary {
        font-size: 16px;
        width: 100%;
        font-weight: 400;
      }
      @media(min-width: 768px){
        width: 50%;
        box-shadow: 14px 0px 24px 42px #efcbffa6;
      }
  `,
)

export const StyledCustomReactPlayer = muiStyled(ReactPlayer)(
  ({ theme }) => `
    .react-player__shadow {
      background: radial-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%) 60%) !important;
      border-radius: 64px;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .react-player__play-icon {
      border-style: solid;
      border-width: 16px 0px 16px 26px;
      border-color: transparent black transparent #ffffffbf !important;
      margin-left: 7px;
    }
  `,
)

export const StyledCustomReactPlayerSlider = muiStyled(Slider)(
  ({ theme }) => `
    color: #fff;
    padding: 13px 0 !important;
    .MuiSlider-thumb.Mui-Active{
      box-shadow: 0px 0px 0px 2px rgb(255 255 255);
    }
  `,
)

export const CustomPlayerContainer = muiStyled('div')(
  ({ theme }) => `
      display: flex;
      flex-direction: column;
      position: relative;
      height:100%;
      width:100%;
  `,
)
