import { CCard, CCardBody } from '@coreui/react-pro'
import styled from 'styled-components'

export const JournalInterfaceContainer = styled.div`
  height: 100%;
  overflow: scroll;
  border-radius: 10px;
  @media (min-width: 768px) {
    overflow: hidden;
  }
`
export const JournalAllQuestionsCard = styled(CCard)
export const JournalEnableSwitchContainer = styled.div``
export const JournalsQuestionMessageContainer = styled.div`
  height: calc(100vh - var(--header-height));
`
export const FeatureImageUploadContainer = styled.div`
  margin-top: 10px;
`
export const FeatureImagesContainer = styled.div`
  position: relative;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 65px;
`

export const JournalImagesContainer = styled.div`
  position: relative;
  padding: 1rem;
  border-radius: 0.5rem;
`

export const JournalTagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`
export const JournalQuestionCard = styled(CCard)`
  height: 100% !important;
  overflow: hidden;
`
export const JournalQuestionsBodyCard = styled(CCardBody)`
  height: 100% !important;
  overflow-y: scroll;
`
export const JournalMessagesCard = styled(CCard)`
  height: 100% !important;
  overflow: hidden;
`
export const JournalMessagesBodyCard = styled(CCardBody)`
  height: 100% !important;
  overflow-y: scroll;
`
export const JournalQuestionHeadingText = styled.div`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 5px;
`

export const QuestionCard = styled(CCard)``

export const StartScreenDetails = styled.div`
  background: var(--cui-bg-light);
  height: 100%;
  overflow-y: scroll;
  border-radius: 10px;
`
export const ScoringRange = styled.div`
  border-radius: 10px;
`
export const ScoringRangeSectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`
export const OptionStyledContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 20% 10%;
  gap: 10px;
  align-items: center;
  padding-right: 15px;
  width: ${(props) => (props.question ? 'auto' : '100%')};
`
export const OptionNameContainer = styled.div``
export const OptionScoreFieldContainer = styled.div``
export const OptionRemoveButtonContainer = styled.div``
