import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import CCOverlay from '../loadingOverlay/CCOverlay'
import BGSGallery from './BGSGallery'
import ListSkeleton from './ListSkeleton'

export default function BGSGalleryChoose({ dialogDetails, onChange, onChangeType }) {
  const [loading, setLoading] = useState(true)
  const galleryRef = useRef()
  const [overLay, setOverLay] = useState(false)
  const [slides, setSlides] = useState()
  const [noMoreFetch, setNoMoreFetch] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [fetching, setFetching] = useState(false)
  const [pagination, setPagination] = useState({
    ...PAGINATION_INIT,
  })

  async function getGallery(search = searchInput, page = PAGINATION_INIT.pageNumber) {
    const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      page: page,
      size: PAGINATION_INIT.pageSize,
      entityType: 'slide',
      // types: [imageType, ImageContext.imageTypeObject?.alias || ''].join(','),
      search: search,
      tags: selectedTags.map((tag) => tag.id)?.join(',') || '',
    })
    if (response.status === 200) {
      setSlides(response.data.content)
      setNoMoreFetch(response.data.last)
      setPagination(response.data.pageable)
    } else {
      toast.error("Entity Couldn't be retrieved")
    }
    return
  }
  async function updateGallery(page = pagination.pageNumber) {
    const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      page: page,
      size: pagination.pageSize,
      // types: [imageType, ImageContext.imageTypeObject?.alias || ''].join(','),
      entityType: 'slide',
      search: searchInput,
      tags: selectedTags.map((tag) => tag.id)?.join(',') || '',
    })
    if (response.status === 200) {
      setSlides(response.data.content)
      setPagination(response.data.pageable)
      setNoMoreFetch(response.data.last || response.data.last)
      setFetching(false)
    } else {
      toast.error("slides Couldn't be retrieved")
    }
  }
  async function Setup() {
    setLoading(true)
    await Promise.all([getGallery()])
    setLoading(false)
  }

  function BottomScrollFunction() {
    const ScrollLeft = dialogDetails.scrollHeight - dialogDetails.clientHeight - 200
    if (dialogDetails.scrollTop >= ScrollLeft) {
      setFetching(true)
      !fetching && updateGallery(pagination.pageNumber + 1)
    }
  }

  async function SearchUpdate() {
    setOverLay(true)
    await getGallery()
    setOverLay(false)
  }

  const debSetSearch = debounce((input) => {
    setSearchInput(input)
  }, 1000)

  useEffect(() => {
    if (dialogDetails && !noMoreFetch && slides) BottomScrollFunction()
  }, [dialogDetails?.scrollTop])

  useEffect(() => {
    !!slides && SearchUpdate()
  }, [searchInput, selectedTags])

  useEffect(() => {
    Setup()
  }, [])

  return (
    <CCOverlay active={overLay} spinner text="Fetching slides">
      <div
        ref={galleryRef}
        className="d-flex flex-column justify-content-stretch align-items-stretch"
      >
        <BGSGallery
          loading={loading}
          slides={slides}
          onChange={onChange}
          onChangeType={onChangeType}
        />
        {!noMoreFetch && <ListSkeleton />}
      </div>
    </CCOverlay>
  )
}

BGSGalleryChoose.propTypes = {
  dialogDetails: PropTypes.any,
  onChange: PropTypes.func,
  onChangeType: PropTypes.string,
}

const PAGINATION_INIT = {
  pageSize: 50,
  pageNumber: 0,
  offset: 10,
}
