import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getSummarizableObject } from '../abstractObjects/Summarizable'
import { ONLINE_COURSE_EPISODE_OBJECT } from './onlineCourseEpisodeObject'

export const ONLINE_COURSE_OBJECT = {
  ageGroups: [],
  allowPreview: false,
  allowPreviewForFirstNEpisode: 0,
  approved: false,
  approvedBy: '',
  autoPlay: false,
  availableForPlan: false,
  avgDuration: 0,
  blogBanner: '',
  blogBannerColor: '',
  category: [],
  countries: [],
  courseGrpId: '',
  courseType: '',
  coverIcon: '',
  coverIconColor: '',
  coverIconHasText: false,
  coverImage: '',
  coverImageColor: '',
  createdBy: '',
  createdByName: '',
  createdFor: '',
  createdForListableInApp: false,
  createdForName: '',
  createdForNumber: '',
  customLongLink: '',
  customShortLink: '',
  deleted: false,
  difficultyLevel: null,
  duplicateCourses: [],
  duplicateIdentifier: '',
  enabled: true,
  duration: 0,
  exploreListable: false,
  favCount: 0,
  featured: false,
  hashtags: [],
  heroCardImage: '',
  heroCardImageColor: '',
  id: '',
  internalTitle: '',
  journalListable: false,
  labels: [],
  languages: [],
  launchMinPlayer: false,
  listabilityPolicy: '',
  locked: false,
  longDesc: '',
  maxDuration: 0,
  meditationIds: [],
  minDuration: 0,
  noOfEpisode: 1,
  noOfRating: 0,
  preRequisiteIds: [],
  primaryTags: [],
  prioritySetting: {},
  rating: 0,
  recommendedForKids: false,
  relativeCourses: [],
  reqToUnpublish: false,
  sdkListable: false,
  secondaryTags: [],
  sessions: [ONLINE_COURSE_EPISODE_OBJECT],
  shareLink: '',
  shortDesc: '',
  shortLink: '',
  sleepListable: false,
  soundListable: false,
  status: '',
  storyListable: true,
  subscription: null,
  testimonials: [],
  title: '',
  type: '',
  verticalRectangle: '',
  verticalRectangleColor: '',
  welcomeMsg: '',
  yogaListable: false,
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.onlineCourse),
}
