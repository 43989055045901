export const META_CATEGORY_OBJECT = {
  apiParam: 'categories',
  audioContinuePlayType: '',
  backdropImage: '',
  backdropImageColor: '',
  bgMusic: true,
  blogBanner: '',
  blogBannerColor: '',
  colors: null,
  continuePlayType: 'result_screen',
  coverIcon: '',
  coverIconColor: '',
  coverImage: '',
  coverImageColor: '',
  dailyPlayAltText: {},
  defaultPlayer: '',
  deleted: false,
  idByUser: true,
  emptyPageScreen: {
    btnText: '',
    images: ['', '', ''],
    subtext: '',
    title: '',
  },
  enabled: true,
  endJournal: true,
  featureCategory: true,
  heroCardImage: '',
  heroCardImageColor: '',
  icon: '',
  iconImage: '',
  id: '',
  internalTitle: '',
  landscapeVideoContinuePlayType: '',
  loopedContinuePlayType: '',
  maxMusicVolume: 0,
  maxVoiceVolume: 100,
  minMusicVolume: 0,
  minVoiceVolume: 100,
  name: '',
  plan: true,
  playerType: '',
  portraitVideoContinuePlayType: '',
  singleTitle: '',
  sliderPosition: 50,
  slides: [],
  tab: '',
  tabs: [],
  translations: {},
  usedDailyPlayListId: '',
  type: '',
}

export const META_CATEGORY_TYPE_OBJECT = {
  apiParam: 'meta-category-type',
  colors: null,
  deleted: false,
  enabled: true,
  id: '',
  idByUser: true,
  player: false,
  title: '',
}
