export const HINT_OBJECT = {
  apiParam: 'hints',
  categories: [],
  deleted: false,
  enabled: true,
  hint: '',
  hintTranslation: {},
  id: '',
  internalTitle: 'New Hint',
  name: '',
  types: [],
}

export const JOURNAL_QUS_ANS = {
  answere: '',
  hints: [],
  question: '',
  qusId: '',
  subtext: '',
}
