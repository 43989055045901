import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetData } from 'src/services/APIs/Subscriptions/Plans'
import { SubscriptionApis } from 'src/views/subscriptions/api-utils'

export const fetchConsumerSubscriptionPlans = createAsyncThunk(
  'subscriptions/fetchConsumerSubscriptionPlans',
  async (_, { getState, dispatch, rejectWithValue }) => {
    dispatch(fetchConsumerSubscriptionPlans.pending())
    const { subscriptions } = getState()
    const currentSubscriptionsList = subscriptions.consumerSummaryList.data

    if (currentSubscriptionsList?.length > 0) {
      dispatch(fetchConsumerSubscriptionPlans.fulfilled(currentSubscriptionsList))
    }
    const resp = await GetData(SubscriptionApis.allPlans.url, {})
    if (resp.status === 200) {
      return resp.data
    } else {
      return rejectWithValue(currentSubscriptionsList)
    }
  },
)

export const fetchCorporateSubscriptions = createAsyncThunk(
  'subscriptions/fetchCorporateSubscriptions',
  async (_, { getState, dispatch }) => {
    dispatch(fetchCorporateSubscriptions.pending())
    const { subscriptions } = getState()
    const currentSubscriptionsList = subscriptions.corporateSummaryList.data

    if (currentSubscriptionsList?.length > 0) {
      dispatch(fetchCorporateSubscriptions.fulfilled(SubscriptionApis.corporatePlans.url))
    }
    const resp = await GetData(SubscriptionApis.corporatePlans.url, {})
    if (resp.status === 200) {
      return resp.data
    } else {
      dispatch(fetchCorporateSubscriptions.rejected(resp))
      return currentSubscriptionsList
    }
  },
)
