import { APP_LISTABLE_OBJECT } from './AppListable'

const { ALT_TEXT_OBJECT } = require('./AltText')

export const APP_FEATURE_OBJECT = {
  ...APP_LISTABLE_OBJECT,
  pricingPlanId: '',
  bundlePlanIds: [],
  subscription: {},
  altTexts: ALT_TEXT_OBJECT,
  tags: [],
  internalTitle: '',
  domains: [],
  faqCategories: [],
  mentorId: '',
}
