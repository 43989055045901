import { CCol, CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { ContentCopy } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Input,
  Popover,
  Slider,
  Switch,
  Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { copyColor } from 'src/utilities/generalUtils'
import { ColorBox, ColorGradInputWidth, ColorStripContainer } from './styledComponents'

function ColorGradientPicker({
  sectionTitle,
  colors,
  setColors,
  showColors,
  setShowColors,
  edit = true,
  showPreview = true,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  function handleChangeColor(event) {
    setColors((prev) => {
      return {
        ...prev,
        [event.target.id]: event.target.value,
      }
    })
  }
  function handleColorId(event) {
    const idAndIndex = event.target.id.split('_')
    console.log({ id: event.target.id, index: event.target.value })
    console.log(idAndIndex)
    setColors((prev) => {
      return {
        ...prev,
        colorIds: prev?.colorIds.map((colorId, colorIndex) => {
          return colorIndex === parseInt(idAndIndex[1])
            ? { ...colorId, [idAndIndex[0]]: event.target.value }
            : colorId
        }),
      }
    })
  }
  function handleAddColor() {
    setColors((prev) => {
      return {
        ...prev,
        colorIds: [...(prev?.colorIds || []), colorIdObject],
      }
    })
  }
  function handleDeleteColor(index) {
    setColors((prev) => {
      return {
        ...prev,
        colorIds: prev?.colorIds.filter((colorId, idx) => idx !== index),
      }
    })
  }
  function handleOpenColorPicker(event) {
    setAnchorEl(event.currentTarget)
  }
  function handleColorPickerClose(event) {
    setAnchorEl(null)
  }

  useEffect(() => {
    console.log(colors)
  }, [colors])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        {sectionTitle && <h3>{sectionTitle}</h3>}
        <div className="d-flex justify-content-stretch align-items-center">
          {setShowColors && (
            <Switch
              disabled={!edit}
              checked={showColors}
              onChange={(e) => setShowColors(e.target.checked)}
            />
          )}
        </div>
      </div>
      {showColors && (
        <>
          {showPreview && (
            <ColorStripContainer>
              <ButtonBase
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: '#E0E0E0',
                  gap: '29px',
                  color: '#5B5B5B !important',
                  padding: '10px 15px',
                  borderRadius: '9px',
                }}
                onClick={handleOpenColorPicker}
              >
                <ColorBoxPreview colors={colors} />
              </ButtonBase>
              <Tooltip title="Copy Color">
                <IconButton
                  onClick={() => {
                    copyColor(colors)
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </ColorStripContainer>
          )}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleColorPickerClose}
            PaperProps={{
              width: '50%',
              minWidth: '50%',
              maxWidth: '50%',
            }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <DialogContent sx={{ width: '600px' }}>
              <div className="row pb-2">
                <CCol md={5}>
                  <ColorGradInputWidth className={SidewaysInputClasses}>
                    <CFormSelect
                      id="type"
                      value={colors?.type || ''}
                      onChange={handleChangeColor}
                      label="Type"
                      disabled={!edit}
                      options={colorTypeOptions}
                    />
                  </ColorGradInputWidth>
                  {colors?.type !== 'solid' && (
                    <ColorGradInputWidth className={SidewaysInputClasses}>
                      <CFormInput
                        id="degrees"
                        type="number"
                        value={colors.degrees}
                        disabled={!edit}
                        onChange={handleChangeColor}
                        label="Degrees"
                      />
                    </ColorGradInputWidth>
                  )}
                </CCol>
                <CCol md={7} className="d-flex justify-content-end align-items-center">
                  <Fade in={colors?.type !== 'solid' && showColors}>
                    <Tooltip title="Add a color">
                      <IconButton
                        sx={{ backgroundColor: 'theme.pallete.primary.main' }}
                        onClick={handleAddColor}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </Fade>
                </CCol>
              </div>
              {colors?.type !== 'solid' &&
                colors.colorIds.map((colorId, index) => (
                  <ColorPickerFields
                    key={index}
                    colorObject={colors}
                    colorId={colorId}
                    index={index}
                    edit={edit}
                    handleColorId={handleColorId}
                    handleDelete={() => handleDeleteColor(index)}
                  />
                ))}
              {colors?.type === 'solid' && (
                <ColorPickerFields
                  colorObject={colors}
                  edit={edit}
                  colorId={colors.colorIds?.lastItem || colorIdObject}
                  handleColorId={handleColorId}
                  index={colors.colorIds?.lastIndex}
                />
              )}
            </DialogContent>
          </Popover>
        </>
      )}
    </>
  )
}

const colorTypeOptions = [
  { label: 'Linear Gradient', value: 'linear-gradient' },
  { label: 'Solid', value: 'solid' },
  { label: 'Angular Gradient', value: 'angular-gradient' },
]

function ColorPickerFields({ colorObject, colorId, index, handleColorId, handleDelete, edit }) {
  const [anchor, setAnchor] = useState(null)

  function handleCancel() {
    setAnchor(null)
  }
  function handleOpenPopover(event) {
    console.log(colorObject.colorIds)
    colorObject.colorIds.length > 2 && setAnchor(event.currentTarget)
    if (colorObject.colorIds.length <= 2) {
      toast.info('You cannot have a gradient with less than two colors')
    }
  }

  const handleSliderChange = (event, newValue) => {
    handleColorId({ target: { value: String(newValue / 100), id: `opacity_${index}` } })
  }
  function handlePositionChange(event) {
    handleColorId({
      target: { value: `${event.target.value}%`, id: event.target.id },
    })
  }

  function handleDeleteFromDialog() {
    handleDelete()
    setAnchor(null)
  }

  return (
    <div className="d-flex justify-content-start align-items-center gap-3">
      {colorObject.type !== 'solid' && <h4>{index + 1}.</h4>}
      <div>
        <CFormInput
          key={index}
          type="color"
          value={colorId.hexCode}
          id={`hexCode_${index}`}
          onChange={handleColorId}
          label="Color picker"
          title="Choose your color"
          disabled={!edit}
        />
      </div>
      <div>
        <CFormLabel htmlFor={`opacity_${index}`}>Opacity</CFormLabel>
        <Slider
          id={`opacity_slider_${index}`}
          value={colorId.opacity * 100}
          min={0}
          max={100}
          disabled={!edit}
          onChange={handleSliderChange}
        />
        <Input
          inputProps={{
            step: 0.01,
            min: 0,
            max: 1,
            type: 'number',
          }}
          id={`opacity_${index}`}
          size="small"
          value={colorId.opacity}
          disabled={!edit}
          onChange={handleColorId}
          label="Opacity"
        />
      </div>
      {colorObject.type !== 'solid' && (
        <div>
          <CFormInput
            id={`position_${index}`}
            type="number"
            value={colorId.position.slice(0, -1)}
            disabled={!edit}
            onChange={handlePositionChange}
            label="Position"
          />
        </div>
      )}
      {colorObject.type !== 'solid' && (
        <IconButton disabled={!edit} onClick={handleOpenPopover}>
          <DeleteIcon />
        </IconButton>
      )}
      <Dialog open={Boolean(anchor)} onClose={() => setAnchor(null)}>
        <DialogTitle>Do you want to delete this color?</DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDeleteFromDialog}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function ColorBoxPreview({ colors }) {
  return (
    <>
      <ColorBox
        type={colors?.type || ''}
        degree={colors?.degrees || 0}
        colorIds={colors?.colorIds}
        width="30px"
        height="30px"
      />
      {colors.type === 'solid' ? (
        <>
          <span>{colors.colorIds.lastItem.hexCode}</span>
          <span style={{ width: '50px' }}>
            {Math.round(colors.colorIds.lastItem.opacity * 100)} %
          </span>
          <span>Position: {colors.colorIds.lastItem.position}</span>
        </>
      ) : (
        <span>Linear-Gradient</span>
      )}
    </>
  )
}

export const colorIdObject = {
  hexCode: '#000',
  opacity: 1,
  position: '0%',
}
const SidewaysInputClasses = 'd-flex align-items-center justify-content-start gap-2 pt-2'

export default ColorGradientPicker
