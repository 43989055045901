import { toast } from 'react-toastify'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { CLINIC_PRODUCTS_PATH } from '..'

export default async function approveClinicBrand(clinicBrand, refreshFunction = () => {}) {
  const { id } = clinicBrand
  const tst = toast.loading(`Approving clinic brand...`)

  const response = await GetSettingsData(
    `${CLINIC_PRODUCTS_PATH}${ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicBrand}/approve`,
    {
      id,
    },
  )
  if (response.status === 200) {
    const approvalResponse = response.data
    if (
      approvalResponse.code === 0 ||
      approvalResponse.code === 200 ||
      approvalResponse.code === 2000
    ) {
      toast.update(tst, {
        render: `Clinic brand approved`,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
      refreshFunction()
    } else {
      toast.update(tst, {
        render: approvalResponse.message,
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
    }
  }
}
