import { CSpinner } from '@coreui/react-pro'
import { Fade } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import {
  GridListingContainer,
  GridListingItem,
  OverlayLoaderContainer,
} from 'src/components/controlCenter/styledComponents'
import { ThemeContext } from 'src/context/ThemeContext'

export default function GridListingComponent({
  loading,
  items,
  customItemBox,
  handleChange,
  itemsPerBreakPoint = { xs: 1, sm: 2, md: 2, lg: 3, xl: 4 },
}) {
  const { theme } = useContext(ThemeContext)
  const itemsPerBreakPointToUse = getValuesPerBreakPoint(itemsPerBreakPoint, 1)

  return (
    <>
      <div className="h-100 w-100 position-relative">
        <Fade in={loading}>
          <OverlayLoaderContainer>
            <CSpinner color="primary" variant="grow" />
          </OverlayLoaderContainer>
        </Fade>
        <GridListingContainer itemsPerBreakPoint={itemsPerBreakPointToUse} theme={theme}>
          {items?.map((item, idx) =>
            customItemBox ? (
              customItemBox(item, idx)
            ) : (
              <GridListingItem key={item.id} onClick={() => handleChange(item)}>
                <h5>
                  <strong>ID:</strong> {item?.id}
                </h5>
                {(item?.title || item?.name || item?.internalTitle) && (
                  <h5>
                    <strong>Title:</strong> {item?.title || item?.name || item?.internalTitle}
                  </h5>
                )}
              </GridListingItem>
            ),
          )}
        </GridListingContainer>
      </div>
    </>
  )
}

GridListingComponent.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  customItemBox: PropTypes.func,
  handleChange: PropTypes.func,
}

function getValuesPerBreakPoint(defaultPerBreakPointValues, defaultBaseValue = 1) {
  const keys = ['xs', 'sm', 'md', 'lg', 'xl']
  return keys.reduce((acc, key, i) => {
    acc[key] = defaultPerBreakPointValues?.[key] || (i > 0 ? acc[keys?.[i - 1]] : defaultBaseValue)
    return acc
  }, {})
}
