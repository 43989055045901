import { Dialog } from '@mui/material'
import React, { useState } from 'react'
import { IdTypes } from 'src/utilities/constants'
import LinksMakerFactoryComponent from './components/editLink/LinksMakerFactoryComponent'
import { LinksMakerDialogPaper } from './components/editLink/styledComponents'
import LinksMakerViewFactoryComponent from './components/viewLink/LinksMakerViewFactoryComponent'

export const LinkMakerComponentsContext = React.createContext()

export const defaultLinkMakerContextData = {
  metaCategoryId: '',
  itemId: '',
  itemTitle: '',
  idType: IdTypes.id,
  additionalData: {
    idType: '',
    itemId: '',
    itemTitle: '',
  },
  extraData: {
    metaCategoryId: '',
    itemId: '',
    itemTitle: '',
    idType: IdTypes.id,
  },
  action: '',
}

export default function LinksMakerComponents({ btnTxt, uri, handleChange, disabled }) {
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [linkMakerContextData, setLinkMakerContextData] = useState({
    ...defaultLinkMakerContextData,
    extraData: null,
  })

  function openDialog() {
    setShowEditDialog(true)
  }

  function closeDialog() {
    setShowEditDialog(false)
  }

  return (
    <>
      <LinkMakerComponentsContext.Provider
        value={{ linkMakerContextData, setLinkMakerContextData }}
      >
        <LinksMakerViewFactoryComponent
          externalLinksData={{ btnTxt, uri }}
          handleChange={handleChange}
          openDialog={openDialog}
          closeDialog={closeDialog}
          disabled={disabled}
        />
        <Dialog
          PaperComponent={LinksMakerDialogPaper}
          open={showEditDialog}
          maxWidth="md"
          onClose={closeDialog}
        >
          <LinksMakerFactoryComponent
            externalLinksData={{ btnTxt, uri }}
            handleChange={handleChange}
            itemTitle="Hello world"
            closeEditDialog={closeDialog}
          />
        </Dialog>
      </LinkMakerComponentsContext.Provider>
    </>
  )
}

//   function Setup() {}

//   useEffect(() => {
//     Setup()
//   }, [externalLinksData])
