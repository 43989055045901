import React, { useMemo } from 'react'
import { PRESCRIPTION_LAYOUTS } from 'src/utilities/constants'
import DefaultPrescriptionFooter from './footer/DefaultPrescriptionFooter'
import ThreeColumnPrescriptionFooter from './footer/ThreeColumnPrescriptionFooter'
import TwoColumnPrescriptionFooter from './footer/TwoColumnPrescriptionFooter'
import TwoColumnPrescriptionWithDisclaimerFooter from './footer/TwoColumnPrescriptionWithDisclaimerFooter'

export const PrescriptionFooter = ({ prescriptionData, leaveSpace }) => {
  const FooterComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
        return ThreeColumnPrescriptionFooter
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
        return TwoColumnPrescriptionFooter
      case PRESCRIPTION_LAYOUTS.twoColumnWithDisclaimerFooter:
        return TwoColumnPrescriptionWithDisclaimerFooter
      default:
        return DefaultPrescriptionFooter
    }
  }, [prescriptionData?.layout])

  if (leaveSpace) {
    return <div style={{ height: '120px' }}></div>
  }

  return <FooterComponent prescriptionData={prescriptionData} noColor={true} />
}
