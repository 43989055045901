import { colorIdObject } from 'src/components/controlCenter/ColorGradientPicker'

export function formatAndCleanColors(colors) {
  if (!colors) {
    return null
  }
  switch (colors.type) {
    case 'solid':
      return { ...colors, colorIds: [colors.colorIds.lastItem], numberOfColors: 1 }
    default:
      return { ...colors, numberOfColors: colors.colorIds.length }
  }
}

export function initializeColors(colors) {
  if (!colors) {
    return null
  }
  switch (colors.type) {
    case 'solid':
      return colors.colorIds.length > 1
        ? colors
        : { ...colors, colorIds: [colorIdObject, colors.colorIds.lastItem], numberOfColors: 1 }
    default:
      return colors
  }
}
