import { putExtraDataForNotificationsToItsPositionDefaultHandling } from './defaultHandling'

export function putExtraDataForNotificationsToItsPosition(notificationData, dlData, categoryType) {
  const tempNotificationData = notificationData

  switch (categoryType) {
    default:
      return putExtraDataForNotificationsToItsPositionDefaultHandling(tempNotificationData, dlData)
  }
}
