import moment from 'moment'
import { toast } from 'react-toastify'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'
import {
  DISPLAY_NAMES_OF_WEEKDAYS,
  USER_CONTACT_OBJECT,
} from 'src/views/settings/utils/clinic-utils'

export async function transformInitialClinicFormData(
  clinicData,
  { fetchTeachers = true, fetchManagement = true, fetchOwner = true },
) {
  var allSupportStaff = clinicData?.staffMemberIds
  const doctorsListAndTherapistsList = [
    ...(clinicData?.doctors || []),
    ...(clinicData.therapists || []),
  ]

  const managementList = clinicData?.management || []

  const [doctorsAndTherapistsToUse, managementToUse, ownerData, supportStaffToUse] =
    await Promise.all([
      fetchTeachers
        ? getTeachersFromSchedule(doctorsListAndTherapistsList?.map((doc) => doc.id))
        : () => doctorsListAndTherapistsList,
      fetchManagement
        ? getUsersSummaryFromUserIds(managementList?.map((management) => management.id))
        : () => managementList,
      fetchOwner
        ? getUsersSummaryFromUserIds(clinicData?.userId ? [clinicData?.userId] : [])
        : () => clinicData?.userId,
      updateSupportStaff(allSupportStaff),
    ])

  console.log({ allSupportStaff })

  const scheduleToUse =
    clinicData?.schedules?.length > 0 && !!clinicData?.schedules
      ? clinicData?.schedules?.map((schedule, idx) => {
          const weeklyHourMap = schedule.weeklyHourMap
          const cleanedWeeklyHourMap = Object.entries(weeklyHourMap || {})
            .sort(([dayKey1], [dayKey2]) => {
              const daysOrder = [
                'sunday',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
              ]
              return daysOrder.indexOf(dayKey1) - daysOrder.indexOf(dayKey2)
            })
            .reduce((acc, [dayKey, value]) => {
              const slotList = value?.slotList

              return {
                ...acc,
                [dayKey]: {
                  ...value,
                  title: DISPLAY_NAMES_OF_WEEKDAYS?.[dayKey]?.longName,
                  slotList: slotList?.sort((sl1, sl2) =>
                    sortFn(moment(sl1.startTime, 'HH:mm:ss'), moment(sl2.startTime, 'HH:mm:ss')),
                  ),
                },
              }
            }, {})
          return { ...schedule, weeklyHourMap: cleanedWeeklyHourMap }
        })
      : []

  return {
    ...clinicData,
    doctors: clinicData.doctors?.map((doc) => {
      const foundTherapist = doctorsAndTherapistsToUse?.find((docTher) => docTher.id === doc.id)
      return { ...(foundTherapist || { title: doc?.id || doc?.email || doc?.mobile }), ...doc }
    }),
    therapists: clinicData.therapists?.map((ther) => {
      const foundTherapist = doctorsAndTherapistsToUse?.find((docTher) => docTher.id === ther.id)
      return {
        ...(foundTherapist || { title: ther.id || ther?.email || ther?.mobile }),
        ...ther,
      }
    }),
    management: clinicData.management?.map((management) => {
      const foundManagement = managementToUse?.find(
        (managementUser) => managementUser.id === management.id,
      )
      return { ...(foundManagement || {}), ...management }
    }),
    staffMemberIds: supportStaffToUse,
    schedules: scheduleToUse,
    userId: ownerData?.[0],
    ownerId: ownerData?.[0],
  }
}

async function getTeachersFromSchedule(teacherIds) {
  if (teacherIds?.filter((teacherId) => !!teacherId)?.length === 0) {
    return []
  }
  const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
    entityType: 'mentor',
    ids: teacherIds?.join(', '),
  })

  if (response.status === 200) {
    return response.data?.content?.map((doc) => ({
      ...USER_CONTACT_OBJECT,
      id: doc.id,
      userId: doc.userId,
      title: doc.title,
    }))
  }
}

async function getAllSupportStaff(supportStaff) {
  const resp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
    entityType: 'support-staff',
    ids: supportStaff?.join(','),
    includeDisabled: true,
    size: 100,
  })
  var dataToReturn
  if (resp.status === 200) {
    dataToReturn = resp.data.content
    const userIdsToFetch = dataToReturn?.map((data) => data?.userId)
    console.log({ userIdsToFetch })
    const foundUserIds = await getUsersSummaryFromUserIds(userIdsToFetch)
    dataToReturn = dataToReturn?.map((data) => {
      const user_name =
        foundUserIds?.find((apiUserName) => apiUserName?.userId === data?.userId)?.name ||
        data?.userId
      return { ...data, user_name }
    })
    return dataToReturn
  } else {
    toast.error('Something went wrong')
  }
}

async function updateSupportStaff(allSupportStaff) {
  if (allSupportStaff?.staffMemberIds?.length > 0) {
    allSupportStaff = await getAllSupportStaff(allSupportStaff?.staffMemberIds)
  }
  return allSupportStaff
}
