import React, { useEffect } from 'react'

export default function PaginatedListingTableHandlerWrapper({
  apiCallUrl,
  apiCallFunction,
  apiCallParams,
  data,
  setData,

  setupDataDependencies = [],

  filterComponentFunction = (props) => <></>,

  children,
}) {
  async function getDataFunction() {
    const resp = await apiCallFunction(apiCallUrl, { ...apiCallParams })
    if (resp.status === 200) {
      setData((prev) => ({ ...prev, data: resp.data.content }))
    } else {
      setData((prev) => ({ ...prev, status: 'error' }))
    }
  }

  async function Setup() {
    if (data?.data?.length <= 0 || !data?.data) {
      if (data?.status !== 'success') {
        setData((prev) => ({ ...prev, status: 'loading' }))
      }
      await getDataFunction()
    }
    setData((prev) => ({ ...prev, status: 'success' }))
  }

  useEffect(() => {
    Setup()
  }, setupDataDependencies)

  return (
    <>
      {filterComponentFunction()}
      {children}
    </>
  )
}
