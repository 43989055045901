import { Grid } from '@mui/material'
import React from 'react'
import { CCFormInput, CCTextArea } from 'src/components/controlCenter/FormComponents'

export default function SesTemplateEditor({
  initialObject,
  imageObject: sesTemplate,
  setImageObject: setNotificationsObject,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CCFormInput
          id="id"
          label="Template ID"
          value={sesTemplate?.id}
          onChange={handleChangeObject}
          disabled={disabled || initialObject?.id}
          placeholder="Enter the template Id"
          required
          colNumber={12}
        />
      </Grid>
      <Grid item xs={12}>
        <CCFormInput
          id="title"
          label="Title"
          value={sesTemplate?.title}
          onChange={handleChangeObject}
          disabled={disabled}
          placeholder="Enter the title..."
          required
          colNumber={12}
        />
      </Grid>
      <Grid item xs={12}>
        <CCFormInput
          id="subject"
          label="Subject"
          value={sesTemplate?.subject}
          onChange={handleChangeObject}
          disabled={disabled}
          placeholder="Enter the subject..."
          required
          colNumber={12}
        />
      </Grid>
      <Grid item xs={12}>
        <CCTextArea
          id="preHeader"
          label="Pre-Header"
          value={sesTemplate?.preHeader}
          onChange={handleChangeObject}
          disabled={disabled}
          placeholder="Enter the pre header..."
          required
        />
      </Grid>
      <Grid item xs={12}>
        <CCTextArea
          id="body"
          label="Html Body"
          value={sesTemplate?.body}
          onChange={handleChangeObject}
          disabled={disabled}
          placeholder="Enter the body..."
          required
        />
      </Grid>
    </Grid>
  )
}
