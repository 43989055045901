export function putActionDataForNotificationsToItsPositionDefaultHandling(
  notificationData,
  dlData,
) {
  const actionData = dlData?.action
  if (!!actionData) {
    notificationData = {
      ...notificationData,
      customFields: {
        ...notificationData?.customFields,
        action: actionData,
      },
    }
  }
  return notificationData
}
