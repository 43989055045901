import { toast } from 'react-toastify'
import {
  postCorporateSubscriptionPlan,
  postSubscription,
} from 'src/services/APIs/Subscriptions/UpdateApi'
import {
  PLAN_GROUP_TYPES,
  SUBSCRIPTION_TYPES_LAYMAN,
  TRUE_FALSE_OPTIONS,
  currency_list,
} from 'src/utilities/constants'

export const PRICING_DETAIL_OBJECT = {
  currencyId: '',
  price: '',
  discountedPrice: '',
  introductoryPrice: '',
}

export const FREE_TRIAL_PRICING_DETAILS = [
  {
    currencyId: 'usd',
    price: 0,
    discountedPrice: 0,
    introductoryPrice: 0,
    countryCode: 'row',
  },
]

export const SUBSCRIPTION_PLAN_OBJECT = {
  apiParam: 'consumer-subscription-plan',
  active: true,
  androidIAPPrices: {},
  autoRenew: false,
  benefits: [],
  corporatePackage: false,
  coverIcon: '',
  coverImage: '',
  currency: '',
  currencySymbol: '',
  description: '',
  discount: 0,
  finalPrice: 0,
  id: '',
  internalTitle: '',
  planGrpId: 'subscription',
  platforms: [],
  popular: true,
  position: 0,
  preselected: true,
  price: 0,
  pymntGtyInfo: [],
  recurring: false,
  isCorporate: false,
  subscriptionPlanType: SUBSCRIPTION_TYPES_LAYMAN?.[0]?.id,
  subscriptionSuccessMsg: '',
  pricingMatrix: { ROW: { ...PRICING_DETAIL_OBJECT, currencyId: 'usd' } },
  subscriptionType: {
    id: 'subscription_normal',
    name: 'Premium',
  },
  title: '',
  translations: {},
  trialDays: '',
  validity: '',
}

export async function PostSubscription(subscription, tst) {
  const {
    benefits,
    currencySymbol,
    popular,
    position,
    appLanguages,
    isCorporate,
    _selected,
    ...updatedSubscription
  } = subscription
  const resp = await postSubscription(
    {
      ...updatedSubscription,
    },
    tst,
  )
  return resp
}

export async function PostCorporateSubscription(subscription, tst) {
  const { currencySymbol, position, appLanguages, _selected, ...updatedSubscription } = subscription

  console.log({ updatedSubscription })

  return await postCorporateSubscriptionPlan(
    {
      ...updatedSubscription,
    },
    tst,
  )
}

export function getSavableSubscriptionPlan(subscriptionPlan) {
  const recurringField =
    subscriptionPlan?.subscriptionPlanType === SUBSCRIPTION_TYPES_LAYMAN?.[1]?.id
      ? false
      : subscriptionPlan?.recurring

  const subToPost = {
    ...subscriptionPlan,
    autoRenew: recurringField ? subscriptionPlan.autoRenew : false,
    validity:
      subscriptionPlan.validity === '' || subscriptionPlan?.planGrpId !== 'subscription'
        ? 0
        : subscriptionPlan.validity,
    trialDays:
      subscriptionPlan.trialDays === '' || subscriptionPlan?.planGrpId !== 'subscription'
        ? 0
        : subscriptionPlan.trialDays,
    price: subscriptionPlan?.pricingMatrix?.row?.price,
    finalPrice: subscriptionPlan?.pricingMatrix?.row?.discountedPrice,
    discount: Math.max(
      subscriptionPlan?.pricingMatrix?.row?.price -
        subscriptionPlan?.pricingMatrix?.row?.discountedPrice,
      0,
    ),
    currency: subscriptionPlan?.pricingMatrix?.row?.currency || 'usd',
    pricingMatrix: getValidatedPricingMatrix(subscriptionPlan?.pricingMatrix),
  }
  return subToPost
}

export async function saveSubscriptionPlan(subscriptionPlan, tst, planType) {
  switch (planType) {
    case PLAN_GROUP_TYPES.corporate:
      return await PostCorporateSubscription(subscriptionPlan, tst)
    case PLAN_GROUP_TYPES.featureBundle:
      toast.warn("Please save feature bundle from 'Bundle Packs' page.")
      break
    default:
      return await PostSubscription(subscriptionPlan, tst)
  }
}

export function filterOtherSelectedCountries(countries, pricingMatrix, currentCountry) {
  return countries.filter((country) => {
    return (
      country.id === currentCountry ||
      !pricingMatrix.some((pricing) => pricing.countryCode === country.id)
    )
  })
}

export function getPaymentGateways(subPlan) {
  return paymentGatewaysToUse?.map((gateway) => {
    const foundGateway = subPlan?.pymntGtyInfo?.find((gatewayItem) => {
      return gatewayItem?.pymntGtyId === gateway?.pymntGtyId
    })
    return { pymntGtyId: gateway.pymntGtyId, ...(foundGateway || {}) }
  })
}

const paymentGatewaysToUse = [
  { pymntGtyId: 'ios_iap' },
  { pymntGtyId: 'android_iap' },
  { pymntGtyId: 'gift_up' },
  { pymntGtyId: 'razorpay' },
  { pymntGtyId: 'stripe' },
]

export async function cloneSubscriptionPlan(subscription) {
  let { id, actions, _props, ...postObject } = subscription
  return await saveSubscriptionPlan({
    ...postObject,
    title: `${postObject?.title} Clone`,
    internalTitle: `${postObject?.internalTitle} Clone`,
  })
}

export function getUsablePricingMatrix(pricingMatrix) {
  const { row, ROW, ...cleanedPricingMatrix } = {
    ...pricingMatrix,
    row: pricingMatrix?.row || null,
    ROW: pricingMatrix?.ROW || null,
  }
  const rowPricingValue = ROW || row

  return Object.entries(
    {
      ...cleanedPricingMatrix,
      row: rowPricingValue ? rowPricingValue : { ...PRICING_DETAIL_OBJECT, currencyId: 'usd' },
    } || {},
  ).map(([countryCode, pricingDetails]) => {
    const pricingDetailsToUse = { ...PRICING_DETAIL_OBJECT, ...pricingDetails }
    return {
      ...pricingDetailsToUse,
      countryCode: countryCode.toLowerCase(),
    }
  })
}

export function getUploadablePricingMatrix(pricingMatrix) {
  return pricingMatrix?.reduce((acc, pricingDetails) => {
    const { countryCode, ...pricingDetailsToUse } = pricingDetails
    const countryCodeToUse = countryCode?.toUpperCase()
    return { ...acc, [countryCodeToUse]: pricingDetailsToUse }
  }, {})
}

export function getValidatedPricingMatrix(pricingMatrix) {
  return Object.keys(pricingMatrix).reduce((result, countryCode) => {
    const pricingDetail = pricingMatrix?.[countryCode]
    const { currencyId, ...rest } = pricingDetail
    const currencySymbol = currency_list?.find((currency) => currency?.code === currencyId)?.symbol

    Object.keys(rest).forEach((key) => {
      if (
        key !== 'currencyId' &&
        (rest?.[key] === '' || rest?.[key] === null || rest?.[key] === undefined)
      ) {
        rest[key] = 0
      }
    })
    result[countryCode] = {
      currencyId,
      currencySymbol,
      ...rest,
    }
    return result
  }, {})
}

//////////Listing Page //////////

export function filterSubscriptionPlansInList(subscriptionPlans, filters, searchInput) {
  let tempResults = subscriptionPlans

  //FILTERING
  tempResults = tempResults?.filter((result) => {
    let valid = true
    if (
      filters?.recurring !==
        TRUE_FALSE_OPTIONS({
          showAll: true,
        })?.[2]?.id &&
      filters?.recurring !== result?.recurring
    ) {
      valid = false
    }
    if (filters.planType?.length !== 0) {
      if (
        !filters.planType.some((planType) => {
          return planType === result?.subscriptionPlanType
        })
      ) {
        valid = false
      }
    }
    if (filters.validity?.length !== 0) {
      if (
        !filters.validity?.some((filterValidity) => {
          return filterValidity === result?.validity
        })
      ) {
        valid = false
      }
    }
    if (filters?.planGroups?.length !== 0) {
      if (
        !filters.planGroups?.some((planGroup) => {
          return planGroup === result?.planGrpId
        })
      ) {
        valid = false
      }
    }
    if (!!filters?.iap && filters?.iap !== 'all') {
      const foundIap = result?.pymntGtyInfo?.find((iap) => iap.pymntGtyId === filters?.iap)
      if (!foundIap?.pymntGtyProductId) {
        valid = false
      }
    }
    return valid
  })

  //SORTING
  if (filters.titleSortAscending) {
    tempResults = tempResults?.sort((sub1, sub2) => {
      return sub1.title > sub2.title ? -1 : 1
    })
  } else {
    tempResults = tempResults?.sort((sub1, sub2) => {
      return sub1.title > sub2.title ? 1 : -1
    })
  }
  if (filters.modifiedTime) {
    tempResults = tempResults?.sort((sub1, sub2) => {
      return sub1.modifiedTime > sub2.modifiedTime ? -1 : 1
    })
  } else {
    tempResults = tempResults?.sort((sub1, sub2) => {
      return sub1.modifiedTime > sub2.modifiedTime ? 1 : -1
    })
  }

  if (searchInput !== '') {
    tempResults = subscriptionPlans.filter((item) => {
      return item?.title?.toLowerCase().includes(searchInput?.toLowerCase())
    })
  }

  return tempResults
}
