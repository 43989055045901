import { MoreVert, OpenInNew } from '@mui/icons-material'
import { Chip, ClickAwayListener, Grow, IconButton, MenuItem, MenuList } from '@mui/material'
import Paper from '@mui/material/Paper'
import React, { useMemo, useRef, useState } from 'react'
import { TAB_ICON_VIEWS } from 'src/utilities/constants'
import { TableImageComponent } from 'src/views/settings/components/TableImage'
import CopyIosIconIdMenuItem from './CopyIosIconIdMenuItem'
import { OpenTabInNewMenuItem } from './OpenTabInNewTab'
import OpenTilePolicyInNewTab from './OpenTilePolicyInNewTab'
import { TilePoliciesSelectionStyledMenuPopper } from './styledComponent'

export default function TabTableItem({
  item,
  disableShortCutMenu,
  disableTabImage,
  tableImageSxOverride,
  concurrentTilePolicy,
  showTilePolicy,
  hideButtonToOpenConcurrentTilePolicy = true,
  iconView = TAB_ICON_VIEWS.any,
}) {
  const tableImageId = useMemo(() => {
    switch (iconView) {
      case TAB_ICON_VIEWS.icon:
        return item?.iconImage
      case TAB_ICON_VIEWS.coverIcon:
        return item?.image
      default:
        return item?.iconImage || item?.image
    }
  }, [iconView, item?.iconImage, item?.image])

  return (
    <td style={{ height: '87px', width: '100%' }}>
      <div className="d-flex align-items-center justify-content-between gap-2 h-100">
        <div className="d-flex align-items-center gap-2 h-100">
          {!disableTabImage && (
            <TableImageComponent
              colors={item?.colors}
              imageId={tableImageId}
              sx={{
                width: '70px',
                height: '70px',
                maxHeight: '70px',
                padding: '5px',
                borderRadius: '4px',
                ...tableImageSxOverride,
              }}
            />
          )}
          <div className="d-flex flex-column align-items-start gap-1">
            <strong>
              {item?.title}
              {concurrentTilePolicy ? '*' : ''}
            </strong>
            {showTilePolicy && item?.curatable && (
              <span className="fs-small">{concurrentTilePolicy?.name}</span>
            )}
            {item?.curatable && (
              <Chip label="Curated" size="small" color="primary" sx={{ px: 2 }} />
            )}
          </div>
        </div>
        {!disableShortCutMenu && (
          <div className="d-flex align-items-center gap-1 h-100">
            <TabMenu
              tabItem={item}
              concurrentTilePolicy={concurrentTilePolicy}
              hideButtonToOpenConcurrentTilePolicy={hideButtonToOpenConcurrentTilePolicy}
            />
          </div>
        )}
      </div>
    </td>
  )
}

function TabMenu({ tabItem, concurrentTilePolicy, hideButtonToOpenConcurrentTilePolicy = true }) {
  const [openMenu, setOpenMenu] = useState(false)
  const openButtonRef = useRef()

  function handleOpenMenu() {
    setOpenMenu(true)
  }
  function handleCloseMenu() {
    setOpenMenu(false)
  }
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenMenu(false)
    } else if (event.key === 'Escape') {
      setOpenMenu(false)
    }
  }

  console.log({ concurrentTilePolicy })

  return (
    <>
      <IconButton ref={openButtonRef} onClick={handleOpenMenu}>
        <MoreVert />
      </IconButton>
      <TilePoliciesSelectionStyledMenuPopper
        anchorEl={openButtonRef.current}
        open={openMenu}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={openMenu} onKeyDown={handleListKeyDown}>
                  <OpenTabInNewMenuItem tabId={tabItem?.id} handleClose={handleCloseMenu} />
                  <CopyIosIconIdMenuItem tabItem={tabItem} handleClose={handleCloseMenu} />
                  {!hideButtonToOpenConcurrentTilePolicy && tabItem?.curatable && (
                    <OpenTilePolicyInNewTab
                      tabId={tabItem?.id}
                      tileObject={concurrentTilePolicy}
                      openButtonRenderFunction={(props) => (
                        <a {...props}>
                          <MenuItem disabledRipple>
                            <OpenInNew />
                            Open attached tile policy in new tab
                          </MenuItem>
                        </a>
                      )}
                      loadingComponentRenderFunction={(props) => (
                        <MenuItem {...props} disabled disabledRipple>
                          Loading...
                        </MenuItem>
                      )}
                    />
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </TilePoliciesSelectionStyledMenuPopper>
    </>
  )
}
