import React from 'react'
import { GalleryImages, ImageContainer } from '../styledComponents'
import { Skeleton } from '@mui/material'

export default function ImagesSkeleton() {
  return (
    <GalleryImages>
      {Array.from({ length: 5 }, () => 1).map((image, index) => {
        return (
          <ImageContainer key={index}>
            <Skeleton width="100%" height="100%" animation="wave" />
          </ImageContainer>
        )
      })}
    </GalleryImages>
  )
}
