import { CFormInput } from '@coreui/react-pro'
import React, { useEffect, useMemo, useState } from 'react'
import { IdTypes } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { LinkMakerComponentsContext } from '../../../LinksMakerComponents'
import EditAndViewButtons from '../EditAndViewButtons'
import { DeepLinkViewContainer } from '../styledComponents'
import {
  initURIContextDataForDeepLinks,
  initURIContextDataTitleForDeepLinks,
} from './utils/initURIs'

export default function ViewDeepLinkComponent({
  openDialog,
  externalLinksData: linksData,
  disabled,
  handleChange,
}) {
  const { linkMakerContextData, setLinkMakerContextData } = React.useContext(
    LinkMakerComponentsContext,
  )
  const [loading, setLoading] = useState(false)
  const [store, dispatch] = useAppSettingsStore()
  const metaCategoryTypes = store.metaCategoryTypes
  const externalLinksData = useMemo(() => linksData, [linksData.uri])

  async function Setup() {
    setLoading(true)
    const initialLinkMakerDataToSet = initURIContextDataForDeepLinks(
      externalLinksData,
      metaCategoryTypes,
    )
    setLinkMakerContextData(initialLinkMakerDataToSet)
    let linkMakerTitleToSet = await initURIContextDataTitleForDeepLinks(
      initialLinkMakerDataToSet,
      metaCategoryTypes,
    )
    setLinkMakerContextData((prev) => {
      return {
        ...prev,
        ...linkMakerTitleToSet,
        additionalData: {
          ...prev.additionalData,
          ...linkMakerTitleToSet.additionalData,
        },
        extraData:
          !!linkMakerTitleToSet?.itemId || linkMakerTitleToSet?.idType === IdTypes.list
            ? {
                ...prev.extraData,
                ...linkMakerTitleToSet.extraData,
              }
            : null,
      }
    })
    setLoading(false)
  }

  useEffect(() => {
    Setup()
  }, [externalLinksData])

  useEffect(() => {
    console.log({ linkMakerContextData })
  }, [linkMakerContextData])

  return (
    <>
      <div>
        <CFormInput
          label="Button Text"
          value={linksData?.btnTxt}
          onChange={(e) => handleChange({ uri: externalLinksData?.uri, btnTxt: e.target.value })}
        />
      </div>
      <DeepLinkViewContainer>
        <div className="d-flex justify-content-start gap-2 align-items-center">
          <div className="d-flex flex-column justify-content-center gap-1">
            <span>
              <strong>URI: </strong>
              <span>{linksData?.uri}</span>
            </span>
            <span>
              <strong>Type: </strong>
              {metaCategoryTypes?.find(
                (catType) => catType?.id === linkMakerContextData?.metaCategoryId,
              )?.title || linkMakerContextData?.metaCategoryId}
            </span>
            {linkMakerContextData?.itemId ? (
              <span>
                <strong>Item: </strong>
                {loading ? 'Loading...' : linkMakerContextData?.itemTitle}
              </span>
            ) : (
              <span>
                <strong>On Click Action: </strong>
                Open all Action
              </span>
            )}
            {linkMakerContextData?.additionalData?.itemId && (
              <span>
                <strong>Additional Item: </strong>
                {loading ? 'Loading...' : linkMakerContextData?.additionalData?.itemTitle}
              </span>
            )}
            {linkMakerContextData?.extraData?.itemId && (
              <>
                <h5 className="mb-0 mt-1 text-secondary">Extra Info</h5>
                <span>
                  <strong>Type: </strong>
                  {metaCategoryTypes?.find(
                    (catType) => catType?.id === linkMakerContextData?.extraData?.metaCategoryId,
                  )?.title || linkMakerContextData?.extraData?.metaCategoryId}
                </span>
                <span>
                  <strong>Item: </strong>
                  {loading ? 'Loading...' : linkMakerContextData?.extraData?.itemTitle}
                </span>
              </>
            )}
          </div>
        </div>
        <EditAndViewButtons
          openDialog={openDialog}
          uri={externalLinksData?.uri}
          hoverTitle={externalLinksData?.uri}
          disabled={disabled}
        />
      </DeepLinkViewContainer>
    </>
  )
}
