import { CFormInput } from '@coreui/react-pro'
import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import { selectSummaryListOfConsumerPlans } from 'src/reducers/subscriptions/subscriptionReducer'
import { currency_list } from 'src/utilities/constants'
import { genericHandleChange, genericHandleNestedFields } from 'src/utilities/generalUtils'

export default function EditPackageItem({ packageItem, handleChangeObjectItem, handleClose }) {
  const consumerSubscriptionPlans = useSelector(selectSummaryListOfConsumerPlans)
  const [formPackageItem, setFormPackageItem] = useState()
  const handleChangeNestedFields = (event, oId) => {
    // Create a new event object with the correct id
    genericHandleNestedFields(event, oId, setFormPackageItem)
  }

  function handleChangeObject(e) {
    genericHandleChange(e, setFormPackageItem)
  }

  function handleChangePrice(e) {
    handleChangeNestedFields(e, 'price')
  }

  function handleSave() {
    handleChangeObjectItem(formPackageItem)
    handleClose()
  }

  useEffect(() => {
    console.log(formPackageItem)
  }, [formPackageItem])

  useEffect(() => {
    setFormPackageItem(packageItem)
  }, [packageItem])

  return (
    <div className="w-100">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div>Edit Package Item</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <GridSectionWrapper shadow={false}>
        <Grid item xs={12}>
          <CustomReactSelect
            id="planId"
            value={formPackageItem?.planId}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.title}
            options={consumerSubscriptionPlans?.data?.slice()?.sort((a, b) => {
              if (!!a?.title) return a?.title?.localeCompare(b?.title)
              return false
            })}
            disabled={false}
            selectType={selectTypes.string}
            onChangeEvent={handleChangeObject}
            label={'Subscription Plan: '}
            labelPlacement={'top'}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        </Grid>
        <Grid item xs={12}>
          <CFormInput
            id="title"
            label="Title"
            value={formPackageItem?.title}
            onChange={handleChangeObject}
            disabled={false}
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="successText"
            label="Success Text"
            value={formPackageItem?.successText}
            onChange={handleChangeObject}
            disabled={false}
            labelPlacement="start"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CFormInput
            id="price"
            label="Price"
            value={formPackageItem?.price?.price}
            onChange={handleChangePrice}
            disabled={false}
            labelPlacement="start"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="discount"
            label="Discount"
            value={formPackageItem?.price?.discount}
            onChange={handleChangePrice}
            disabled={false}
            labelPlacement="start"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="finalPrice"
            label="Final Price"
            value={formPackageItem?.price?.finalPrice}
            onChange={handleChangePrice}
            disabled={false}
            labelPlacement="start"
            type="number"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="currency"
            label="Currency"
            getOptionValue={(option) => option?.code}
            getOptionLabel={(option) => option?.name}
            options={currency_list?.sort((a, b) => a?.name?.localeCompare(b?.name))}
            selectType={selectTypes.string}
            value={formPackageItem?.price?.currency}
            onChangeEvent={handleChangePrice}
            disabled={false}
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            // id="popular"
            labelPlacement="start"
            control={<Checkbox id="popular" checked={formPackageItem?.popular || false} />}
            onChange={handleChangeObject}
            label="Popular: "
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            // id="preselected"
            labelPlacement="start"
            control={<Checkbox id="preselected" checked={formPackageItem?.preselected || false} />}
            onChange={handleChangeObject}
            label="Preselected: "
            disabled={false}
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper hideDivider={true} shadow={false}>
        <Grid container xs={12} md={12} justifyContent="flex-end">
          <Button color="primary" variant="contained" onClick={handleSave}>
            Set
          </Button>
        </Grid>
      </GridSectionWrapper>
    </div>
  )
}
