import { Paper, styled as muiStyled } from '@mui/material'

export const LinksMakerDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
    max-width: 768px !important;
    max-height: 678px!important;
    margin: 0 !important;
    height: 100%;
    width: 100%;
`,
)
