import { convertArrayToPaginatedObject } from 'src/common/types/serverFetch/getArrayAsServerPaginatedObject'
import { fetchFunction } from 'src/services'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import { returnLoadOptions } from 'src/views/plugins/calendar/event-utils'
import { newGetCuratedCardOptions } from 'src/views/setup/tiles/components/legacyTiles/tileFormUtils'
import { SETUP_APIS } from 'src/views/setup/utils/api-utils'

export async function loadDLTileItems(searchInput, prevOptions, { page, metaCategoryId }) {
  const dropDownOptionsData = await fetchFunction(SETUP_APIS.tiles.get.url)

  return returnLoadOptions(
    convertArrayToPaginatedObject(
      dropDownOptionsData
        ?.map((tabTile) => ({
          ...tabTile,
          value: tabTile?.id,
          label: `${tabTile?.title} ${tabTile?.curated ? '(Curated)' : ''}`,
        }))
        ?.sort((a, b) => a?.label?.localeCompare(b?.label)),
    ),
    { metaCategoryId },
  )
}
export async function loadDLMentorEvents(searchInput, prevOptions, { page, itemId }) {
  const dropDownOptionsData = await GetDetailedEntity('mentor', { id: itemId })

  return returnLoadOptions(
    convertArrayToPaginatedObject(
      dropDownOptionsData?.eventTypes
        ?.filter((eventItem) => eventItem?.uid && eventItem?.publicEvent && eventItem?.enabled)
        ?.map((event) => ({
          ...event,
          value: event?.uid,
          label: `${event?.title} ${
            event?.durationInMins !== null && event?.durationInMins !== undefined
              ? `(${event?.durationInMins} mins)`
              : ''
          } ${event?.free ? '(Free)' : ''}`,
        })) || [],
    ),
    { itemId },
  )
}

export async function loadCategoryTypeBasedItems(
  searchInput,
  prevOptions,
  {
    page,
    metaCategoryTypeId,
    categoryIds = [],
    languageIds = [],
    additionalFilters = { journalType: 'regular' },
  },
) {
  const catTypeToOptionTypeMap = {
    quotes: 'quote',
    mentalStateType: 'mental-state-type',
    'service-provider': 'mentor',
  }

  console.log({ categoryIds })

  const dropDownOptionsData = await newGetCuratedCardOptions(
    catTypeToOptionTypeMap?.[metaCategoryTypeId] || metaCategoryTypeId,
    categoryIds?.length > 0 ? categoryIds : null, //category
    languageIds?.length > 0 ? languageIds : null,
    50,
    page,
    searchInput,
    additionalFilters,
  )
  return returnLoadOptions(dropDownOptionsData, { metaCategoryTypeId })
}

export async function loadBookedEvents(
  searchInput,
  prevOptions,
  { page, userId = 'USR-be1183093c0a4ae3b68ea493775f7d05' },
) {
  const dropDownOptionsData = await fetchFunction(
    'api/secure/user/booked-slots',
    { page, pageSize: 100 },
    false,
  )

  return returnLoadOptions(
    convertArrayToPaginatedObject(
      dropDownOptionsData
        ?.map((bookedEvent) => ({
          ...bookedEvent,
          value: bookedEvent?.id,
          label: `${bookedEvent?.title}`,
        }))
        ?.sort((a, b) => a?.label?.localeCompare(b?.label)),
    ),
    { userId },
  )
}
