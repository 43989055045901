import { IdTypes } from 'src/utilities/constants'
import { getItemTitleForTypeId } from './forID'
import { getItemTitleForTypeType } from './forType'

export async function getItemTitlesForAnyIdType(itemId, type, metaCategoryType) {
  switch (type) {
    case IdTypes.type:
      return getItemTitleForTypeType(itemId, metaCategoryType)
    case IdTypes.slug:
    case IdTypes.id:
      return getItemTitleForTypeId(itemId, type, metaCategoryType)
    default:
      return null
  }
}
