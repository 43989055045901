export const PRESCRIPTION_TEMPLATES_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  colors: null,
  apiParam: 'prescription-template',
  doctorId: '',
  clinicLocationId: '',
  clinicId: '',
  headline: '',
  iconId: '',
  headlineBgColor: '',
  doctorName: '',
  designation: '',
  listOFDegrees: [],
  listOfIssues: [],
  affiliations: [],
  regId: '',
  cmoRegId: '',
  contactEmail: '',
  contactAddress: '',
  emergencyNumber: '',
  website: '',
  header: {
    bgColorId: '',
    iconId: '',
    title: '',
  },
  footer: {
    headline: '',
    subtext: '',
    supportText: '',
    primaryLanguageId: '',
    secondaryLanguageId: '',
    headlineAlt: '',
    subtextAlt: '',
    supportTextAlt: '',
  },
  watermarkId: '',
}
