export const FEATURE_BUNDLE_OBJECT = {
  title: '',
  description: '',
  featureAndCreditValidityInDays: 7,
  expiryDate: null,
  type: '', // 'max-count-based' | 'unlimited-count-based'
  maxCount: '',
  metaCategoryType: '', // 'ask user to enter this separately'
  metaCategory: '', // 'ask user to enter this separately'
  featureIds: [], // based on the above two fields, we will get the features. Priority will be given to the metaCategoryType and then to the metaCategory
  pricingPackId: '', // these are based on the featureIds pricing packs
}
