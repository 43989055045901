import { CFormLabel } from '@coreui/react-pro'
import { Add, Delete, DragIndicator } from '@mui/icons-material'
import { Card, Chip, Collapse, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import { getCorrectTargetValueForEvent } from 'src/utilities/generalUtils'
import { CustomAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'
import { returnLoadOptions } from 'src/views/plugins/calendar/event-utils'
import ConfirmationModal from '../../../../components/controlCenter/ConfirmationModal'
import { CCFormInput, CCTextArea } from '../../../../components/controlCenter/FormComponents'
import { array_move } from '../../../curatedLists/utils/curatedListData-utils'
import { PHASE_OBJECT, checkPhasesValidity, fixStartDaysAndEndDays } from '../utils'
import {
  CollapseIcon,
  PhaseDataContainer,
  PhaseDayRangeSubtitle,
  PhaseTitle,
  PhaseTitleContainer,
} from './styledComponents'

export function Phases({
  customPlan,
  setCustomPlan,
  changePhase,
  mode,
  disabledFields,
  handleChange,
  addPhase,
  removePhase,
}) {
  const phaseList = customPlan.phaseList

  const [collapsePhases, setCollapsePhases] = useState(false)

  function handleDragEnd(results) {
    const { source, destination } = results

    source &&
      destination &&
      setCustomPlan((prev) => {
        const oldPhases = prev?.phaseList || []
        const newPhases = array_move(oldPhases, source.index, destination.index)
        const phasesToSet = fixStartDaysAndEndDays(newPhases)
        return {
          ...prev,
          phaseList: phasesToSet,
        }
      })
  }

  return (
    <>
      {!checkPhasesValidity(customPlan)?.valid && (
        <strong className="text-danger">{checkPhasesValidity(customPlan)?.message}</strong>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <PhaseTitleContainer>
          <strong>Number of Phases:</strong>
          <Chip size="small" label={phaseList?.length || 0} />
        </PhaseTitleContainer>
        <div className="d-flex justify-content-end align-items-center gap-2">
          {!disabledFields && (
            <IconButton onClick={addPhase}>
              <Add />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              setCollapsePhases((collPrev) => {
                setCustomPlan((prev) => ({
                  ...prev,
                  phaseList: phaseList?.map((phase) => ({ ...phase, _collapsed: !collPrev })),
                }))
                return !collPrev
              })
            }}
          >
            <CollapseIcon collapsed={!collapsePhases} />
          </IconButton>
        </div>
      </div>
      <span>
        <strong>Total Days: </strong>
        {customPlan.totalDays}
      </span>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable id="dp-item-scroll" droppableId="ROOT" type="group">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="pt-4">
              {phaseList?.map((phase, idx) => (
                <Draggable
                  key={`${phase?.id}-${idx}`}
                  draggableId={`${phase?.id}-${idx}`}
                  index={idx}
                  isDragDisabled={mode === 'View'}
                >
                  {(provided, snapshot) => (
                    <Card
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      sx={{ mt: 2, px: 2 }}
                    >
                      <PhaseContainer
                        key={idx}
                        phase={phase}
                        setPhase={changePhase}
                        phaseNumber={idx + 1}
                        handleChange={(event) => handleChange(event, idx)}
                        removePhase={removePhase}
                        collapseAllPhases={collapsePhases}
                        disableFields={disabledFields}
                        dragPickProps={provided.dragHandleProps}
                        view={PHASE_VIEW_TYPES.inForm}
                        totalPhases={phaseList?.length}
                        totalDays={customPlan?.totalDays}
                        isDragging={snapshot.isDragging}
                      />
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export function PhaseContainer({
  phase,
  setPhase,
  phaseNumber,
  handleChange,
  removePhase,
  collapseAllPhases,
  disableFields,
  dragPickProps,
  view = 'default',
  totalPhases,
  totalDays,
  isDragging,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [settingExistingPhase, setExistingPhase] = useState(false)

  function showDeleteConfirmationModal() {
    setShowConfirmation(true)
  }

  function changePhaseDays(event) {
    const selfEvent = {
      target: {
        id: event.target.id,
        value: getCorrectTargetValueForEvent(event) < 0 ? 0 : getCorrectTargetValueForEvent(event),
        type: 'number',
      },
    }
    handleChange(selfEvent)
  }

  function changeToggleButton(e, newVal) {
    console.log(newVal)
    if (!!newVal) {
      setPhase(
        (prev) => ({
          ...prev,
          ...PHASE_OBJECT,
          title: prev?.title,
          days: prev?.days,
          _newPhase: newVal,
          legendColor: prev?.legendColor,
        }),
        phaseNumber - 1,
      )
    }
    setPhase((prev) => ({ ...prev, _newPhase: newVal }), phaseNumber - 1)
  }
  async function setSelectedPhase(selectedPhase) {
    setExistingPhase(true)
    const detailedPhase = await GetDetailedEntity('plan-phase', selectedPhase)
    setPhase(
      (prev) => ({
        ...detailedPhase,
        firstDay: prev?.firstDay,
        lastDay: prev?.lastDay,
        days: prev?.days,
        _newPhase: prev?._newPhase,
        legendColor: prev?.legendColor,
      }),
      phaseNumber - 1,
    )
    setExistingPhase(false)
  }

  async function loadOptions(inputValue, callback, { page, size }) {
    setExistingPhase(true)
    // tempCard.type === 'course' ? cardLanguages : languages,
    const resp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: 'plan-phase',
      page,
      size,
    })
    let dropDownOptions = {}
    if (resp.status === 200) {
      dropDownOptions = resp.data
    }
    setExistingPhase(false)
    return returnLoadOptions(dropDownOptions)
  }

  function toggleCollapse() {
    setPhase((prev) => ({ ...prev, _collapsed: !prev._collapsed }), phaseNumber - 1)
  }

  return (
    <>
      <PhaseDataContainer>
        <div className="d-flex justify-content-between align-items-center pb-3">
          <div className="d-flex justify-content-start align-items-center gap-2">
            {!disableFields && !!dragPickProps && (
              <div {...dragPickProps}>
                <DragIndicator />
              </div>
            )}
            {phaseNumber !== undefined && (
              <div
                className={`d-flex flex-column align-items-start justify-content-start gap-1 ${
                  phaseNumber === totalPhases &&
                  (phase?.lastDay > totalDays || phase?.lastDay < totalDays)
                    ? 'text-danger'
                    : ''
                }`}
              >
                <PhaseTitle>
                  #{phaseNumber} {phase.title}
                </PhaseTitle>
                <PhaseDayRangeSubtitle>
                  <span>Days {phase?.lastDay - phase?.firstDay + 1}</span>
                  {/* {phase?.firstDay !== phase?.lastDay && (
                    <>
                      <TrendingFlat sx={{ fontSize: '22px' }} />
                      <span>Day {phase?.lastDay}</span>
                    </>
                  )} */}
                </PhaseDayRangeSubtitle>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center gap-1">
            {!disableFields && !!removePhase && (
              <IconButton onClick={showDeleteConfirmationModal}>
                <Delete />
              </IconButton>
            )}
            <IconButton onClick={toggleCollapse}>
              <CollapseIcon collapsed={!phase._collapsed} />
            </IconButton>
          </div>
        </div>
        <Collapse in={phase._collapsed}>
          {!disableFields && view === PHASE_VIEW_TYPES.inForm && (
            <div className="d-flex justify-content-center align-items-center">
              <ToggleButtonGroup
                value={!!phase?._newPhase}
                exclusive
                size="small"
                onChange={changeToggleButton}
                color="primary"
                aria-label="text alignment"
              >
                <ToggleButton value={true} aria-label="left aligned">
                  Create a new Phase
                </ToggleButton>
                <ToggleButton value={false} aria-label="centered">
                  Use an existing phase
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          {!phase?._newPhase && !disableFields && view === PHASE_VIEW_TYPES.inForm && (
            <div className="d-flex w-100 flex-column gap-1 pb-3">
              <CFormLabel>Use Existing</CFormLabel>
              <CustomAsyncPaginate
                menuPortalTarget={document.body}
                value={!!phase?.id ? phase : null}
                isLoading={settingExistingPhase}
                loadOptions={loadOptions}
                debounceTimeout={1000}
                getOptionValue={getPhaseOptionValue}
                getOptionLabel={getPhaseOptionLabel}
                additional={{ page: 0, size: 100 }}
                required
                onChange={setSelectedPhase}
                isDisabled={disableFields}
                placeholder="Select an existing phase..."
              />
            </div>
          )}
          {((!phase?._newPhase ? !!phase.id : true) || view === PHASE_VIEW_TYPES.default) && (
            <div className="row">
              <CCFormInput
                id="title"
                value={phase?.title}
                coreUiGridItem
                colNumber={view === PHASE_VIEW_TYPES.inForm ? 11 : 10}
                onChange={handleChange}
                label="Title"
                placeholder="Please enter the title of your phase..."
                required
                disabled={disableFields}
              />
              {view === PHASE_VIEW_TYPES.inForm && (
                <>
                  <CCFormInput
                    id="days"
                    value={phase?.days === 0 ? '' : phase?.days}
                    type="number"
                    colNumber={1}
                    coreUiGridItem
                    onChange={changePhaseDays}
                    label="Days"
                    placeholder="Please enter the number of days of the phase..."
                    disabled={disableFields}
                    invalid={phase?.lastDay > totalDays}
                    feedbackInvalid="The last day of the phase must be less than or equal to the total days of the plan."
                    required
                  />
                </>
              )}
              {view === PHASE_VIEW_TYPES.default && (
                <>
                  <CCFormInput
                    id="firstDay"
                    value={phase?.firstDay}
                    coreUiGridItem
                    type="number"
                    colNumber={1}
                    onChange={handleChange}
                    label="First Day"
                    placeholder="Please enter the first day number of the phase..."
                    disabled={disableFields}
                    required
                  />
                  <CCFormInput
                    id="lastDay"
                    value={phase?.lastDay}
                    coreUiGridItem
                    type="number"
                    colNumber={1}
                    onChange={handleChange}
                    label="Last Day"
                    placeholder="Please enter the last day number of the phase..."
                    disabled={disableFields}
                    required
                  />
                </>
              )}
              <CCFormInput
                id="why"
                value={phase?.why}
                coreUiGridItem
                colNumber={6}
                onChange={handleChange}
                label="Why"
                placeholder="Why are we building this phase?"
                required
                disabled={disableFields}
              />
              <CCFormInput
                id="impact"
                value={phase?.impact}
                coreUiGridItem
                colNumber={6}
                onChange={handleChange}
                label="Impact"
                placeholder="What is the impact of this phase?"
                required
                disabled={disableFields}
              />
              <CCTextArea
                id="description"
                value={phase?.description}
                coreUiGridItem
                colNumber={12}
                onChange={handleChange}
                label="Description"
                placeholder="Give us a description of this phase..."
                required
                disabled={disableFields}
              />
            </div>
          )}
          <div className="py-1">
            <EntityBasedImageUploadComponent
              entityObject={phase}
              disabled={disableFields}
              entity="plan-phase"
              handleChange={handleChange}
              cuiBg
            />
          </div>
        </Collapse>
      </PhaseDataContainer>
      <ConfirmationModal
        visibility={showConfirmation}
        visibilitySetter={setShowConfirmation}
        action={'delete'}
        body={`Do you want to delete this Phase?(${phase.title})`}
        onSubmitFunctions={[() => removePhase(phaseNumber - 1)]}
      />
    </>
  )
}

export const PHASE_VIEW_TYPES = {
  default: 'default',
  inForm: 'inForm',
}

function getPhaseOptionValue(option) {
  return option?.id
}
function getPhaseOptionLabel(option) {
  return option?.title || option?.id
}
