import React from 'react'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { loadCategoryTypeBasedItems, loadDLTileItems } from '../../utils'
import ExtraOptionsComponent from './ExtraOptionsComponent'

export default function DLTypeTiles({
  item,
  handleChangeItem,
  setAdditionalData,
  additionalData,
  changeAdditionalItem,
  uiContextData,
}) {
  return (
    <>
      <div>
        <label>{uiContextData?.linkIdSelectionLabel || 'Item'}</label>
        <ActivityItemAsyncSelect
          classNamePrefix="activity-item-select"
          menuPortalTarget={document.body}
          value={item?.value ? item : null}
          loadOptions={loadDLTileItems}
          debounceTimeout={1000}
          additional={{
            page: 0,
            size: 200,
          }}
          onChange={(selVal) => handleChangeItem(selVal)}
        />
      </div>
      <ExtraOptionsComponent
        {...{ item, additionalData, changeAdditionalItem, setAdditionalData, uiContextData }}
      >
        <div className="pb-2">
          <label>{uiContextData?.extraDataItemIdSelectionLabel || 'Additional Item'}</label>
          <ActivityItemAsyncSelect
            cacheUniqs={[item?.value, additionalData?.idType]}
            classNamePrefix="activity-item-select"
            menuPortalTarget={document.body}
            value={additionalData?.value ? additionalData : null}
            loadOptions={loadCategoryTypeBasedItems}
            debounceTimeout={1000}
            additional={{
              page: 0,
              size: 200,
              metaCategoryTypeId: 'tiles',
              additionalFilters: {
                category: item?.value,
              },
            }}
            onChange={(selVal) => changeAdditionalItem(selVal)}
          />
        </div>
      </ExtraOptionsComponent>
    </>
  )
}
