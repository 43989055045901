import React from 'react'
import PropTypes from 'prop-types'
import GridListingComponent from './GridListingComponent'
import { styled as muiStyled } from '@mui/material/styles'
import { ButtonBase } from '@mui/material'

export default function SubtitleGallery({ loading, images, onChange, onChangeType = 'id' }) {
  function handleChange(image) {
    if (onChangeType === 'object') {
      onChange(image)
    } else {
      onChange(image.fileId)
    }
  }

  function subtitleBox(item, idx) {
    const calcBG = calculateBG(idx)
    return (
      <SubtitleBox
        className="text-white"
        key={item.id}
        bg={calcBG}
        onClick={() => handleChange(item)}
      >
        {(item?.title || item?.name || item?.internalTitle) && (
          <h5 className="w-100">{item?.title || item?.name || item?.internalTitle}</h5>
        )}
      </SubtitleBox>
    )
  }

  return <GridListingComponent items={images} loading={loading} customItemBox={subtitleBox} />
}

const SubtitleBox = muiStyled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'bg' })(
  ({ theme, bg }) => ({
    width: '100%',
    height: '200px',
    borderRadius: '6px',
    background: bg,
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  }),
)

function calculateBG(idx) {
  const bg = {
    0: '#000',
    1: '#111',
    2: '#222',
    3: '#333',
  }
  return bg[idx % 4]
}

SubtitleGallery.propTypes = {
  loading: PropTypes.bool,
  images: PropTypes.array,
  onChange: PropTypes.func,
  onChangeType: PropTypes.string,
}
