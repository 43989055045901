import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { fetchFunction, fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'

export async function getClinicFromObject(
  object,
  clinicId = 'clinicId',
  clinicName = 'clinicId',
  detailedObject = false,
) {
  const clinicIdToUse = object?.[clinicId] ? [object?.[clinicId]] : null
  console.log({ clinicIdToUse, objClinicId: object?.[clinicId] })
  if (clinicIdToUse === null) return object
  if (!detailedObject) {
    const foundClinics = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.clinic,
      ids: clinicIdToUse?.join(','),
    })
    return { ...object, [clinicName]: foundClinics?.[0] }
  } else {
    const foundClinics = await fetchFunction(GENERIC_APIS.entity.details, {
      entityType: ALL_ENTITY_TYPES.clinic,
      id: clinicIdToUse?.[0],
    })
    return { ...object, [clinicName]: foundClinics }
  }
}
