const base = 'policy/'

export const POLICIES_API_SIGNATURE_MAP = {
  focusAreaPolicies: {
    get: `${base}focus-area`,
  },
  acquisitionPolicies: {
    get: `${base}acquisition-policy`,
  },
  categoryPolicies: {
    get: `${base}category-policy`,
  },
  prewallPolicies: {
    get: `${base}prewall-policy`,
  },
  emailPolicies: {
    get: `${base}email-policy`,
  },
  ageGroups: {
    get: `${base}age-groups`,
  },
}
