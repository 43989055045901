import moment from 'moment'
import React from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import PaginatedListingTableHandlerWrapper from '../../PaginatedListingTableHandlerWrapper'

export default function BreatheTechniques({ userId, data, setData }) {
  return (
    <PaginatedListingTableHandlerWrapper
      apiCallFunction={GetSettingsData}
      apiCallUrl={USERS_API_SIGNATURES_MAP.usageDetails.pranaVayu.get}
      apiCallParams={{ userId: userId, size: 200 }}
      data={data}
      setData={setData}
      setupDataDependencies={[userId]}
    >
      <CCExtendedCSmartTable
        noUserNames
        items={data?.data}
        loading={data?.status === 'loading'}
        columns={columns}
        scopedColumns={scopedColumns}
        excludeGenericFields={[
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ]}
      />
    </PaginatedListingTableHandlerWrapper>
  )
}

// ;<ef-data-column
//   field="latestBreathTiming"
//   header="Current Streak"
//   save-state="true"
//   custom-formater="lastbreathtiming_formater"
//   save-state-expiry="3600"
// ></ef-data-column>

/* <ef-data-row-action name="View Log" fn-action="techniqueLog({userPranaVayu})" move-to-dropdown="true"></ef-data-row-action> */

const columns = [
  { key: 'title', label: 'Name', sorter: false, filter: false },
  // { key: 'teacher', label: 'Teacher', sorter: false, filter: false },
  { key: 'userPranaModifiedTime', label: 'Last Used', sorter: false, filter: false },
  { key: 'createdTime', label: 'First Used', sorter: false, filter: false },
  { key: 'totalSessions', label: 'Total Minutes', sorter: false, filter: false },
  { key: 'completedSessionCount', label: 'Times Completed', sorter: false, filter: false },
  { key: 'completedSessionTime', label: 'Times Discarded', sorter: false, filter: false },
  { key: 'latestBreathTiming', label: 'Current Streak', sorter: false, filter: false },
]

const scopedColumns = {
  title: (item) => {
    var toRet = '--'
    if (item?.userPranaVayu) {
      toRet = item?.pranaVayuTechniques?.name || ''
    }
    return <td>{toRet}</td>
  },
  teacher: (item) => {
    return <td>{item?.teacher?.name || '--'}</td>
  },
  userPranaModifiedTime: (item) => {
    return (
      <td>
        {item?.userPranaVayu?.modifiedTime
          ? moment(item?.userPranaVayu?.modifiedTime).format(
              MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
            )
          : '--'}
      </td>
    )
  },
  createdTime: (item) => {
    return (
      <td>
        {item?.userPranaVayu?.createdTime
          ? moment(item?.userPranaVayu?.createdTime).format(
              MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
            )
          : '--'}
      </td>
    )
  },
  totalSessions: (item) => {
    var toRet = '--'
    if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != undefined) {
      toRet = item?.userPranaVayu?.pranaVayuSummary?.totalSessionCount
    }
    return <td>{toRet}</td>
  },
  totalSessionTime: (item) => {
    var toRet = '--'

    if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != null) {
      toRet = item?.userPranaVayu?.pranaVayuSummary?.totalSessionTime
    }
    return <td>{toRet}</td>
  },
  completedSessionCount: (item) => {
    var toRet = '--'

    if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != null) {
      toRet = item?.userPranaVayu?.pranaVayuSummary?.completedSessionCount
    }
    return <td>{toRet}</td>
  },
  completedSessionTime: (item) => {
    var toRet = '--'
    if (item?.userPranaVayu != null && item?.userPranaVayu?.pranaVayuSummary != null) {
      toRet = item?.userPranaVayu?.pranaVayuSummary?.completedSessionTime
    }
    return <td>{toRet}</td>
  },
  latestBreathTiming: (item) => {
    var toRet = '--'
    if (item.userPranaVayu) {
      toRet = item?.userPranaVayu.latestBreathTiming
    }
    return <td>{toRet}</td>
  },
}
