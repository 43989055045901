import { Button } from '@mui/material'
import React from 'react'
import { openInNewTab } from 'src/utilities/generalUtils'
import OnlineCourseCourseStatusChip from 'src/views/courses/courseForm/components/OnlineCourseFieldsHandler/components/OnlineCourseCourseStatusChip'

export default function OCCheckDraftOrPublished({
  listingType = 'draft',
  relatedOnlineCourse,
  courseCategory,
}) {
  if (!relatedOnlineCourse) {
    return <td>NA</td>
  }

  if (listingType === 'draft') {
    return (
      <td>
        <div className="d-flex flex-column align-items-center justify-content-center gap-1">
          <Button
            onClick={(e) => {
              e.stopPropagation()
              openInNewTab(
                `${window.location.origin}/online-courses/categories/${relatedOnlineCourse?.category?.[0]}/non-draft/${relatedOnlineCourse?.id}?mode=View`,
              )
            }}
            variant="contained"
          >
            Open Non Draft
          </Button>
          <div className="d-flex justify-content-center align-items-center gap-1">
            <strong>Status: </strong>
            <OnlineCourseCourseStatusChip onlineCourse={relatedOnlineCourse} />
          </div>
        </div>
      </td>
    )
  }

  return (
    <td>
      <div className="d-flex flex-column align-items-center justify-content-center gap-1">
        <Button
          onClick={(e) => {
            e.stopPropagation()
            openInNewTab(
              `${window.location.origin}/online-courses/categories/${relatedOnlineCourse?.category?.[0]}/draft/${relatedOnlineCourse?.id}?mode=View`,
            )
          }}
          variant="contained"
        >
          Open Draft
        </Button>
        <div className="d-flex justify-content-center align-items-center gap-1">
          <strong>Status: </strong>
          <OnlineCourseCourseStatusChip onlineCourse={relatedOnlineCourse} />
        </div>
      </div>
    </td>
  )
}
