import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const getMentorsListToStore = createAsyncThunk(
  'appSettings/getMentorsListToStore',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { appSettings } = getState()
    const mentorsList = appSettings.allMentors

    if (!appSettings.allMentors) {
      dispatch(getMentorsListToStore.fulfilled(mentorsList))
    }

    const response = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.mentor,
      size: 999,
    })
    if (response.status === 200) {
      return response.data.content
    } else {
      rejectWithValue(response.data)
    }
  },
)
