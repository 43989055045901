import { convertMetaCategoryTypeToMetaCategoryTypeForDL } from 'src/common/utils/categoryType/converters'
import { LINK_TYPES } from 'src/utilities/constants'

export function buildURI(externalLinksData, externalLinksContextData, linkType) {
  switch (linkType) {
    case LINK_TYPES.deep:
      return buildDeepLinkData(externalLinksData, externalLinksContextData)
    case LINK_TYPES.hyperlink:
      return buildHyperLinkData(externalLinksData, externalLinksContextData)
    default:
      return externalLinksData
  }
}

function buildDeepLinkData(externalLinksData, externalLinksContextData) {
  const catType = convertMetaCategoryTypeToMetaCategoryTypeForDL(
    externalLinksContextData.metaCategoryId,
  )
  const extraCatType = convertMetaCategoryTypeToMetaCategoryTypeForDL(
    externalLinksContextData?.extraData?.metaCategoryId,
  )

  const part1Link = `aumhum://${catType || ''}`
  const extraPart = externalLinksContextData?.extraData ? '/extra' : ''

  const part2Link = catType
    ? `${externalLinksContextData?.idType ? `/${externalLinksContextData?.idType}` : ''}${
        externalLinksContextData?.itemId ? `/${externalLinksContextData.itemId}` : ''
      }`
    : ''
  const part3Link =
    externalLinksContextData?.additionalData && externalLinksContextData?.additionalData?.idType
      ? `/${externalLinksContextData?.additionalData?.idType}${
          externalLinksContextData?.additionalData?.itemId
            ? `/${externalLinksContextData?.additionalData?.itemId}`
            : ''
        }`
      : ''

  const extraDataForFinalLink = extraCatType
    ? `/${extraCatType}${
        externalLinksContextData?.extraData?.idType
          ? `/${externalLinksContextData?.extraData?.idType}`
          : ''
      }${
        externalLinksContextData?.extraData?.itemId
          ? `/${externalLinksContextData?.extraData.itemId}`
          : ''
      }`
    : ''

  console.log({ part1Link, extraPart, part2Link, part3Link, extraDataForFinalLink })

  return {
    btnTxt: externalLinksData.btnTxt,
    uri: `${part1Link}${part2Link}${part3Link}${extraPart}${extraDataForFinalLink}`,
  }
}

function buildHyperLinkData(externalLinksData, externalLinksContextData) {
  return {
    btnTxt: externalLinksData.btnTxt,
    uri: externalLinksData.uri,
  }
}
