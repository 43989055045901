import { Refresh } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import CCExtendedCSmartTable from 'src/components/controlCenter/CCExtendedCSmartTable'
import { currency_list } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { ShowPricingDetails } from '../../sub-plan-helper-components'

export default function IAPPricingTable({
  heading = 'IAP Pricing',
  androidPricingData,
  refreshIAPPricingData,
}) {
  const [appSettings, dispatchAppSettings] = useAppSettingsStore()
  const itemsToUse = androidPricingData?.map((item) => {
    return {
      ...item,
      countryCode:
        appSettings?.countries?.find((country) => country?.id?.toUpperCase() === item.countryCode)
          ?.title || item.countryCode,
    }
  })

  function iapPricingHeader() {
    return (
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div>
          <h4>{heading}</h4>
        </div>
        <div>
          <Tooltip label="Refresh">
            <IconButton onClick={() => alert('Functionality Not Implemented')}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  const columns = [
    {
      label: 'Country',
      key: 'countryCode',
    },
    {
      label: 'Price',
      key: 'price',
    },

    {
      label: 'Currency',
      key: 'currency',
    },
  ]

  const getCurrencyId = (priceToUse) => {
    return priceToUse?.currency
  }

  const scopedColumns = {
    price: (item) => {
      return (
        <td>
          <ShowPricingDetails
            priceToUse={item}
            getPrice={(itm) => itm.price}
            getCurrencyId={getCurrencyId}
          />
        </td>
      )
    },
    priceMicros: (item) => {
      return (
        <td>
          <ShowPricingDetails
            priceToUse={item}
            getPrice={(itm) => itm.priceMicros}
            getCurrencyId={getCurrencyId}
          />
        </td>
      )
    },
    currency: (item) => {
      const foundCurrency = currency_list.find(
        (currency) => currency.code === getCurrencyId(item)?.toLowerCase(),
      )
      return <td>{foundCurrency?.name}</td>
    },
  }

  return (
    <div>
      <CCExtendedCSmartTable
        items={itemsToUse?.sort((a, b) => a.countryCode.localeCompare(b.countryCode))}
        externalComponents={iapPricingHeader}
        columns={columns}
        noUserNames
        excludeGenericFields={['title', 'modifiedTime', 'modifiedBy', 'actions']}
        loading={false}
        scopedColumns={scopedColumns}
      />
    </div>
  )
}
