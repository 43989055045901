import { createSlice } from '@reduxjs/toolkit'
import { courseCmsFormSettingsExtraReducers } from './statusReducers'

const initialState = {
  sectionsAndFields: {
    data: {},
    status: 'idle',
    error: null,
  },
  allFieldTypes: {
    data: [],
    status: 'idle',
    error: null,
  },
}

const courseFormSettingsSlice = createSlice({
  name: 'courseCmsForm',
  initialState,
  reducers: {},
  extraReducers: courseCmsFormSettingsExtraReducers,
})

export const selectCourseCmsSectionsAndFields = (state) => state.courseCmsForm?.sectionsAndFields
export const selectCourseCMSFieldTypes = (state) => state.courseCmsForm?.allFieldTypes

export const selectEnabledCourseCmsSectionsAndFields = (state) => ({
  ...state.courseCmsForm?.sectionsAndFields,
  data: {
    ...state.courseCmsForm?.sectionsAndFields?.data,
    sections: state.courseCmsForm?.sectionsAndFields?.data?.sections?.filter(
      (section) => !!section?.enabled,
    ),
    fields: state.courseCmsForm?.sectionsAndFields?.data?.fields?.filter(
      (field) => !!field?.enabled,
    ),
  },
})

export const courseFormSettingsReducer = courseFormSettingsSlice.reducer
