import { Grid } from '@mui/material'
import { bool, func, object } from 'prop-types'
import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { namedItem } from 'src/views/curatedLists/utils/curatedList-utils'

export default function ClinicDetails({
  teacherData,
  handleChange,
  handleNestedChange,
  handleImageChange,
  disabled,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const domainOptions = store?.tags?.slice()
  function handleChangePersonalize(event) {
    handleNestedChange(event, 'personalize')
  }

  function handleChangeAvailableServices(event) {
    const values = event.target.value
    const namedItemsToUse = values.map((val) => ({
      ...namedItem,
      id: val?.id,
      title: val?.title,
    }))
    handleChange({ ...event, target: { ...event.target, value: namedItemsToUse } })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>Service Info</h3>
      </Grid>
      <Grid item xs={12}>
        <CustomReactSelect
          isMulti
          id="availableServices"
          value={teacherData?.availableServices}
          options={AVAILABLE_SERVICES_OPTIONS.sort((ser1, ser2) => sortFn(ser1.title, ser2.title))}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option.title}
          selectType={selectTypes.object}
          colNumber={12}
          disabled={disabled}
          onChangeEvent={handleChangeAvailableServices}
          label="Available Services"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <CustomReactSelect
          isMulti
          id="categories"
          value={teacherData?.categories}
          options={store.categories
            .filter((cat) => cat?.categoryType === 'clinic')
            .sort((cat1, cat2) => sortFn(cat1.name, cat2.name))}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option.name}
          selectType={selectTypes.string}
          colNumber={12}
          onChangeEvent={handleChange}
          disabled={disabled}
          label="Categories"
        />
      </Grid>
      <Grid item xs={12}>
        <CustomReactSelect
          isMulti
          id="focusAreas"
          value={teacherData?.personalize?.focusAreas}
          disabled={disabled}
          options={domainOptions.sort((tag1, tag2) => sortFn(tag1.tag, tag2.tag))}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option.name || option?.tag}
          selectType={selectTypes.string}
          onChangeEvent={handleChangePersonalize}
          label="Focus Areas"
        />
      </Grid>
    </Grid>
  )
}

const yearOptions = Array.from({ length: 74 }, (v, i) => ({
  id: `${1950 + i}`,
  label: `${1950 + i}`,
}))

const monthOptions = [
  { id: '01', label: 'January' },
  { id: '02', label: 'February' },
  { id: '03', label: 'March' },
  { id: '04', label: 'April' },
  { id: '05', label: 'May' },
  { id: '06', label: 'June' },
  { id: '07', label: 'July' },
  { id: '08', label: 'August' },
  { id: '09', label: 'September' },
  { id: '10', label: 'October' },
  { id: '11', label: 'November' },
  { id: '12', label: 'December' },
]

export const AVAILABLE_SERVICES_OPTIONS = [
  { id: 'opd', title: 'OPD' },
  { id: 'inpatient', title: 'Inpatient' },
  { id: 'pharmacy', title: 'Pharmacy' },
  { id: 'therapy', title: 'Therapy' },
  { id: 'assessment', title: 'Assessment' },
  { id: 'diagnostic_services', title: 'Diagnostic Services' },
]

ClinicDetails.propTypes = {
  teacherData: object,
  disabled: bool,
  handleChange: func,
  handleNestedChange: func,
  handleImageChange: func,
}
