import axios from 'axios'
import { toast } from 'react-toastify'
import { apiURL } from 'src/services'

const API_URL = [apiURL, '/api/secure/cc/'].join('')

const token = localStorage.getItem('token')
const Headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
}

export function GetData(url, Params = {}) {
  try {
    const response = axios.get(`${API_URL}${url}`, {
      headers: Headers,
      params: Params,
    })
    return response
  } catch (error) {
    toast.error('Something went wrong.')
    console.log(error)
    return error
  }
}
