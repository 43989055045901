import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { getClinicFromObject } from 'src/services/HealthCare/clinics/getClinicsFunctions'
import { transformFetchedEntity } from '../genericForm/fetch/fetchEntityTransformer'

export async function transformInitialOOHMsgData(oohMsg) {
  var oohMsgsToReturn = oohMsg
  oohMsgsToReturn = await getClinicFromObject(oohMsgsToReturn, 'clinicId', 'clinicId', true)
  if (oohMsgsToReturn?.clinicId) {
    const clinicObject = await transformFetchedEntity(
      ALL_ENTITY_TYPES.clinic,
      oohMsgsToReturn?.clinicId,
    )
    oohMsgsToReturn.clinicId = clinicObject
  }
  return oohMsgsToReturn
}
