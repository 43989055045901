import axios from 'axios'
import { toast } from 'react-toastify'
import { GetFeatureData } from 'src/services/APIs/Features/GetApi'
import { FEATURES_API } from 'src/views/features/utils/api-utils'

export async function getFileFromUrl(url) {
  try {
    const response = await axios.get(url, { responseType: 'blob' })
    console.log(response)
    const srtBlob = new Blob([response.data], { type: 'text/srt' })
    return srtBlob
  } catch (err) {
    console.log(err)
    toast.error('Could not get file')
  }

  // var file
  // await fetch(url)
  //   .then((res) => res.blob())
  //   .then((blob) => {
  //     file = blob
  //     return blob
  //   })
  // return file
}

export function getTextFromTextFile(file) {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = (event) => resolve(event.target.result)
    reader.onerror = (error) => reject(error)
    reader.readAsText(file)
  })
}

export function filterData(allData, field, values) {
  //IF YOU WANT TO PUT MORE VALUES IN THE FILTER YOU NEED TO PUT IT IN THE FORMAT OF AN ARRAY OF VALUES
  return allData.filter((data) => {
    const shouldWeKeep = values.some((value) => {
      return value === data?.[field]
    })
    return shouldWeKeep
  })
}

export async function getAndAttachReflectionToQuote(quote, fieldName = 'reflective-question') {
  const reflection = quote?.journalId || null
  if (reflection !== null && reflection !== undefined && reflection !== 'default-reflection') {
    const response = await GetFeatureData(FEATURES_API.journals.getSingle.url, {
      id: quote.journalId,
    })
    return {
      ...quote,
      [fieldName]:
        response.data?.journalQusAns?.lastItem?.question ||
        `Invalid Format - ${response.data?.journalQusAns?.lastItem?.question}`,
    }
  }
  return { ...quote, [fieldName]: reflection }
}
