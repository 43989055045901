import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { TRUE_FALSE_OPTIONS } from 'src/utilities/constants'

export default function PaymentGatewayHeaderFirstLayer({ filters, dropDownData, setFilters }) {
  return (
    <>
      <div style={{ width: '300px' }}>
        <CustomReactSelect
          id="active"
          value={filters?.active}
          options={TRUE_FALSE_OPTIONS({
            trueTitle: 'Yes',
            falseTitle: 'No',
            showAll: true,
          })}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option?.title}
          placeholder="Select a active..."
          label="Plan Groups"
          isClearable
          onChangeEvent={setFilters}
          selectType={selectTypes.string}
        />
      </div>
    </>
  )
}
