import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchFunction, fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const fetchAvailableClinics = createAsyncThunk(
  'clinicFlowData/fetchAvailableClinics',
  async (clinicBrandId, { getState, dispatch }) => {
    dispatch(fetchAvailableClinics.pending())
    const { clinicFlowData } = getState()
    const currentClinicBrandId = clinicFlowData.currentClinicBrandData.data?.id

    if (clinicBrandId === currentClinicBrandId) {
      dispatch(
        fetchAvailableClinics.fulfilled(clinicFlowData.availableClinicsForCurrentClinicBrand.data),
      )
    }

    const resp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinic,
      clinicBrandId: clinicBrandId,
      size: 1000,
    })
    if (resp) {
      return resp
    } else {
      return resp.message
    }
  },
)

export const fetchAllClinicBrands = createAsyncThunk(
  'clinicFlowData/fetchAllClinicBrands',
  async (_, { getState, dispatch }) => {
    dispatch(fetchCurrentClinicBrand.pending())
    const { clinicFlowData } = getState()
    const allClinicBrands = clinicFlowData.allClinicBrands.data

    if (allClinicBrands?.length > 0) {
      dispatch(fetchCurrentClinicBrand.fulfilled(clinicFlowData.allClinicBrands.data))
    }

    const resp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicBrand,
      size: 999,
    })
    if (resp) {
      return resp
    } else {
      return resp.message
    }
  },
)

export const fetchCurrentClinicBrand = createAsyncThunk(
  'clinicFlowData/fetchCurrentClinicBrand',
  async (clinicBrandId, { getState, dispatch }) => {
    dispatch(fetchCurrentClinicBrand.pending())
    const { clinicFlowData } = getState()
    const currentClinicBrandId = clinicFlowData.currentClinicBrandData.data?.id

    if (clinicBrandId === currentClinicBrandId) {
      dispatch(fetchCurrentClinicBrand.fulfilled(clinicFlowData.currentClinicBrandData.data))
    }

    const resp = await fetchFunction(GENERIC_APIS.entity.details, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicBrand,
      id: clinicBrandId,
    })
    if (resp) {
      return resp
    } else {
      return resp.message
    }
  },
)
