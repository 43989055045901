import { CircularProgress, Collapse } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { getMediaImageUrl } from 'src/services'
import {
  DispMsgViewContainer,
  DisplayMsgViewButtonLink,
  DisplayMsgViewButtonText,
  DisplayMsgViewDetails,
  DisplayMsgViewImg,
  DisplayMsgViewSubText,
  DisplayMsgViewTitleText,
  DisplayTempBadge,
} from '../styledComponents'

export default function DisplayMessageTemplateView({
  item,
  handleClick,
  collapsed = false,
  type,
  inListing,
}) {
  const [fetchingDetails, setFetchingDetails] = useState(false)

  function handleItemClick() {
    handleClick(item, setFetchingDetails)
  }

  return (
    <DispMsgViewContainer
      msgColor={item?.colors}
      clickFunction={!!handleClick}
      onClick={handleClick ? handleItemClick : () => {}}
    >
      {fetchingDetails ? (
        <CircularProgress />
      ) : (
        <>
          <DisplayMsgViewDetails className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">
                <DisplayTempBadge color="info">
                  {RENDER_DISP_MSG(type || item.type)}
                </DisplayTempBadge>
              </h5>
              <div>
                {inListing && (
                  <DisplayTempBadge color={item?.enabled ? 'success' : 'warning'}>
                    {item?.enabled ? 'Enabled' : 'Disabled'}
                  </DisplayTempBadge>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex flex-column align-items-start">
                <Collapse in={!collapsed}>
                  <DisplayMsgViewTitleText>{item?.title || '< NO TITLE >'}</DisplayMsgViewTitleText>
                  <DisplayMsgViewSubText>
                    {item?.subText || '< NO SUB TEXT >'}
                  </DisplayMsgViewSubText>
                  {item?.actionBtn && (
                    <DisplayMsgViewButtonText>
                      Button Text -
                      <DisplayMsgViewButtonLink
                        target="_blank"
                        rel="noreferrer"
                        href={item?.actionBtnUri}
                      >
                        &quot;{item?.actionBtnText}&quot;
                      </DisplayMsgViewButtonLink>
                    </DisplayMsgViewButtonText>
                  )}
                </Collapse>
              </div>
              <div className="h-100 d-flex align-items-start flex-shrink-0">
                <DisplayMsgViewImg
                  src={`${getMediaImageUrl()}${item.imageId}`}
                  alt={item.imageId}
                />
              </div>
            </div>
          </DisplayMsgViewDetails>
        </>
      )}
    </DispMsgViewContainer>
  )
}

DisplayMessageTemplateView.propTypes = {
  item: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  collapsed: PropTypes.bool,
  type: PropTypes.string,
}

export function RENDER_DISP_MSG(type) {
  switch (type) {
    case 'emptySlotMsg':
      return 'Empty Slot Message'
    case 'completedSlotMsg':
      return 'Completed Slot Message'
    case 'sentinelCard':
      return 'Sentinel Card'
    case 'landing-card':
      return 'Landing Card'
    default:
      return 'Display Message'
  }
}
