import { CFormInput } from '@coreui/react-pro'
import Delete from '@mui/icons-material/Delete'
import { Button, Grid } from '@mui/material'
import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { ALL_AVAILABLE_PLATFORMS, ALL_AVAILABLE_PLATFORMS_ARRAY, CRITERIA_OPERATORS } from 'src/utilities/constants'

export default function AppPlatformsComponent({
  appPlatform,
  changeAppPlatform,
  deletePlatform,
  disabled,
}) {
  return (
    <Grid container sx={{ p: 1, border: '1px solid #0000003E', borderRadius: '7px' }} spacing={1}>
      <Grid item xs={6}>
        <CustomReactSelect
          id="platform"
          label="Platform"
          options={ALL_AVAILABLE_PLATFORMS_ARRAY?.filter((platform) => platform?.mobile)}
          getOptionValue={(optn) => optn.id}
          getOptionLabel={(optn) => optn.title}
          value={appPlatform?.platform}
          selectType={selectTypes.string}
          onChangeEvent={changeAppPlatform}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomReactSelect
          id="appPackageName"
          label="App Package Name"
          options={
            appPlatform?.platform === ALL_AVAILABLE_PLATFORMS.android
              ? APP_PACKAGE_NAMES_ANDROID
              : APP_PACKAGE_NAMES_IOS
          }
          getOptionValue={(optn) => optn.id}
          getOptionLabel={(optn) => optn.title}
          value={appPlatform?.appPackageName}
          selectType={selectTypes.string}
          onChangeEvent={changeAppPlatform}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <label>Version</label>
        <div className="row g-1">
          <div className="col-md-4">
            <CustomReactSelect
              id="versionCriteria"
              options={CRITERIA_OPERATORS}
              getOptionValue={(optn) => optn.id}
              getOptionLabel={(optn) => optn.title}
              value={appPlatform?.versionCriteria}
              onChangeEvent={changeAppPlatform}
              selectType={selectTypes.string}
              disabled={disabled}
            />
          </div>
          <div className="col-md-8">
            <CFormInput
              id="appVersion"
              value={appPlatform?.appVersion}
              onChange={changeAppPlatform}
              disabled={disabled}
            />
          </div>
        </div>
      </Grid>
      {deletePlatform && !disabled && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="error" startIcon={<Delete />} onClick={deletePlatform}>
            Delete
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

const APP_PACKAGE_NAMES_IOS = [{ id: 'com.aumhum.aumhum', title: 'com.aumhum.aumhum' }]
const APP_PACKAGE_NAMES_ANDROID = [
  { id: 'com.aumhum.aumhum', title: 'com.aumhum.aumhum' },
  { id: 'com.aumhum.mentor', title: 'com.aumhum.mentor' },
]
