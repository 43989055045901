import { getCuratedCardRefsBulk } from 'src/views/setup/tiles/components/legacyTiles/tileFormUtils'
import { convertKeyValuePairsArrayObject } from '../common/commonConfigHelpers'

export async function transformInitialInAppMessageData(data) {
  const teacherIdsObjects =
    data?.criteria?.teacherIds?.length > 0
      ? await getCuratedCardRefsBulk(data?.criteria?.teacherIds, 'mentor')
      : []
  const courseIdsObjects =
    data?.criteria?.courseIds?.length > 0
      ? await getCuratedCardRefsBulk(data?.criteria?.courseIds, 'course')
      : []

  return {
    ...data,
    criteria: {
      ...(data?.criteria || {}),
      teacherIds: teacherIdsObjects,
      courseIds: courseIdsObjects,
    },
    templateData: {
      ...(data?.templateData || {}),
      metaInfo: convertKeyValuePairsArrayObject(data?.templateData?.metaInfo),
    },
  }
}
