import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'

export default function UserDonationHistory({ data, setData, userId }) {
  const [loading, setLoading] = useState(false)

  const donationHistory = data?.data

  async function getSubscriptionHistory() {
    const resp = await GetSettingsData(USERS_API_SIGNATURES_MAP.subscriptions.donationHistory.get, {
      userId,
      size: 200,
    })
    if (resp.status === 200) {
      setData((prev) => ({ ...prev, data: resp.data.content }))
    }
  }

  async function Setup() {
    if (donationHistory?.length <= 0 || !donationHistory) {
      setLoading(true)
      await getSubscriptionHistory()
    }
    setLoading(false)
  }

  useEffect(() => {
    Setup()
  }, [userId])

  return (
    <CCExtendedCSmartTable
      noUserNames
      items={donationHistory}
      loading={loading}
      columns={columns}
      excludeGenericFields={[
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
      ]}
      scopedColumns={scopedColumns}
    />
  )
}

const columns = [
  { key: 'txnId', label: 'ID', filter: false, sorter: false },
  { key: 'pkgId', label: 'Package', filter: false, sorter: false },
  {
    key: 'platformType',
    label: 'Package Type',
    filter: false,
    sorter: false,
  },
  {
    key: 'teacherName',
    label: 'Teacher Name',
    filter: false,
    sorter: false,
  },
  {
    key: 'createdTime',
    label: 'Created Time',
    filter: false,
    sorter: false,
  },
]

const scopedColumns = {
  createdTime: (item) => {
    return (
      <td>
        {moment(item?.createdTime).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
      </td>
    )
  },
}
