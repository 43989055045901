const SUMMARIZABLE_OBJECT = {
  apiParam: '',
  enabled: true,
  deleted: false,
}

export function getSummarizableObject(apiParam) {
  return {
    ...SUMMARIZABLE_OBJECT,
    apiParam,
  }
}
