import { Chip, Grid } from '@mui/material'
import React from 'react'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import FocusAreaHandler from './FocusAreaHandler'

export default function UsersOtherDetails({ userData: user, dropDownData }) {
  const studentPersonalize = user.personalize.studentPersonalize

  console.log({ userData: user, studentPersonalize })

  console.log({ dropDownData })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Expertise">
          {studentPersonalize?.experties != null ? (
            <>
              {studentPersonalize?.experties.iMeditate && (
                <Grid item xs={12}>
                  I Meditate :
                  {studentPersonalize.whereILearn != null &&
                    studentPersonalize.whereILearn.iFollowSchool && <Chip title="Follow School" />}
                  {studentPersonalize.whereILearn != null &&
                    studentPersonalize.whereILearn.iJustMeditate && <Chip title="Just Meditate" />}
                </Grid>
              )}
              {studentPersonalize?.experties.triedMeditations && (
                <Grid item xs={12}>
                  I have tried Meditations
                </Grid>
              )}
              {studentPersonalize?.experties.newToMeditations && (
                <Grid item xs={12}>
                  I am new to meditations
                </Grid>
              )}
              {!studentPersonalize?.experties.iMeditate &&
                !studentPersonalize?.experties.triedMeditations &&
                !studentPersonalize?.experties.newToMeditations && (
                  <Grid item xs={12}>
                    Not found
                  </Grid>
                )}
            </>
          ) : (
            <Grid item xs={12}>
              Empty
            </Grid>
          )}
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Preferences">
          {studentPersonalize?.preferences ? (
            <>
              <Grid item xs={12}>
                No of Days: {studentPersonalize?.preferences?.planDuration}
              </Grid>
              <Grid item xs={12}>
                Preferred Duration (min):
                {studentPersonalize?.preferences?.sessionTime > 1000
                  ? Math.round(studentPersonalize?.preferences?.sessionTime || 0)
                  : studentPersonalize?.preferences?.sessionTime}
              </Grid>
              <Grid item xs={12}>
                Preferred Time: {user?.userPreferredTime}
              </Grid>
              <Grid item xs={12}>
                Notification time : {user?.userPreferredTime}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              Empty
            </Grid>
          )}
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Subscription Level">
          <Grid item xs={12}>
            <span>Subscription Level: {user?.subscriptionType?.name}</span>
          </Grid>
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Category">
          {user.personalize.studentPersonalize.persona &&
          user.personalize.studentPersonalize.persona.category &&
          user.personalize.studentPersonalize.persona.category.length ? (
            <Grid item xs={12} display="flex" flexWrap="wrap" gap="10px">
              {user?.personalize?.studentPersonalize.persona?.category?.map((cat) => (
                <Chip
                  key={cat}
                  label={
                    dropDownData?.categories?.find((category) => category?.id === cat)?.title || cat
                  }
                />
              ))}
            </Grid>
          ) : (
            <Grid item xs={12}>
              NA
            </Grid>
          )}
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Segment">
          <Grid item xs={12} display="flex" flexWrap="wrap" gap="10px">
            {user?.appPolicy?.name || user?.appPolicy?.id || 'NA'}
          </Grid>
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper
          sectionContainerSx={{ height: '100%' }}
          heading="Subscription and Trial Details"
        >
          <Grid item xs={12}>
            <strong>Current Plan</strong>
            <div className="d-flex flex-column gap-2">
              {user?.activeSubscriptions && user?.activeSubscriptions?.subscription ? (
                <>
                  <span>Type: {user?.activeSubscriptions?.subscription?.subscriptionPlanType}</span>
                  <span>Id : {user?.activeSubscriptions?.subscription?.subscriptionPlan.id}</span>
                  <span>
                    Name: {user?.activeSubscriptions?.subscription?.subscriptionPlan.title}
                  </span>
                  <span>
                    Start Date : {user?.activeSubscriptions?.subscription?.subscriptionStartDate}
                  </span>
                  <span>
                    End Date: {user?.activeSubscriptions?.subscription?.subscriptionEndDate}
                  </span>
                </>
              ) : (
                <span>NA</span>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <strong>Trial Offered</strong>
              <div>
                <span>{user.trialOffered ? 'Yes' : 'No'}</span>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <span>Trial Package</span>
            <div>
              <span v-if="user.trialOffered == true && user.activeSubscriptions.subscription && user.activeSubscriptions">
                Trial was offered Check details in Database, following info is incorrect Id :{' '}
                {user.activeSubscriptions.subscription.subscriptionPlan.id}
                Name: {user.activeSubscriptions.subscription.subscriptionPlan.title}
                Start Date : {user.activeSubscriptions.subscription.subscriptionStartDate}
                End Date: {user.activeSubscriptions.subscription.subscriptionEndDate}
              </span>
              <span v-else>NA</span>
            </div>
          </Grid> */}
        </GridSectionWrapper>
      </Grid>
      {user?.personalize?.studentPersonalize?.persona &&
        user?.personalize?.studentPersonalize?.persona?.focusAreas &&
        user?.personalize?.studentPersonalize?.persona?.focusAreas?.length && (
          <Grid item xs={12} md={6}>
            <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Focus Area">
              {user?.personalize?.studentPersonalize?.persona?.focusAreas?.map((focArea) => {
                return (
                  <Grid item xs={12} key={focArea}>
                    <FocusAreaHandler
                      focusArea={dropDownData?.focusArea?.find(
                        (focusArea) => focusArea?.id === focArea,
                      )}
                      dropDownData={dropDownData}
                    />
                  </Grid>
                )
              })}
            </GridSectionWrapper>
          </Grid>
        )}
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Goals">
          <Grid item xs={12} display="flex" flexWrap="wrap" gap="5px" alignItems="center">
            <span>Primary Focus:</span>
            {user?.personalize?.studentPersonalize?.persona?.primaryTopics?.map((topic) => {
              return (
                <Chip
                  key={topic}
                  label={dropDownData?.tags?.find((tag) => tag?.id === topic)?.tag}
                />
              )
            })}
          </Grid>
          <Grid item xs={12} display="flex" flexWrap="wrap" gap="5px" alignItems="center">
            <span>Secondary Focus:</span>
            {user?.personalize?.studentPersonalize?.persona?.secondaryTopics?.map((topic) => {
              return (
                <Chip
                  key={topic}
                  label={dropDownData?.tags?.find((tag) => tag?.id === topic)?.tag}
                />
              )
            })}
          </Grid>
          <Grid item xs={12} display="flex" flexWrap="wrap" gap="5px" alignItems="center">
            <span>Focus:</span>
            {user?.personalize?.studentPersonalize?.persona?.soulTopics?.map((topic) => {
              return (
                <Chip
                  key={topic}
                  label={dropDownData?.tags?.find((tag) => tag?.id === topic)?.tag}
                />
              )
            })}
            {user?.personalize?.studentPersonalize?.persona?.mindTopics?.map((topic) => {
              return (
                <Chip
                  key={topic}
                  label={dropDownData?.tags?.find((tag) => tag?.id === topic)?.tag}
                />
              )
            })}
            {user?.personalize?.studentPersonalize?.persona?.bodyTopics?.map((topic) => {
              return (
                <Chip
                  key={topic}
                  label={dropDownData?.tags?.find((tag) => tag?.id === topic)?.tag}
                />
              )
            })}
          </Grid>
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Quote Criteria">
          <Grid item xs={12}>
            <span>
              Type: {user?.quoteCriteria?.type === 'all' && 'All'}{' '}
              {user?.quoteCriteria?.type === 'fav' && 'Favorite'}
              {user?.quoteCriteria?.type === 'general' && 'General'}
              {user?.quoteCriteria?.type === 'custom' && 'Custom'}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span>
              Sub - Type:{' '}
              {user.quoteCriteria.type !== 'custom' ? (
                <>NA</>
              ) : (
                <div className="d-flex flex-column gap-2">
                  {user?.quoteCriteria?.subType === 'genre' && <div>Genre</div>}
                  {user?.quoteCriteria?.subType === 'tag' && <div>Tag</div>}
                  {user?.quoteCriteria?.subType === 'feeling' && <div>Feeling</div>}
                </div>
              )}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span>
              Ref IDs:
              {user.quoteCriteria.type !== 'custom' ? (
                <>NA</>
              ) : (
                <div className="d-flex flex-column gap-2">
                  {user?.quoteCriteria?.refIds?.map((refId) => (
                    <div key="idx">{dropDownData?.tags?.find((tag) => tag?.id === refId)?.tag}</div>
                  ))}
                </div>
              )}
            </span>
          </Grid>
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="App Language">
          <Grid item xs={12}>
            {dropDownData?.languages?.find((lang) => lang?.id === studentPersonalize?.userLanguage)
              ?.languageName || studentPersonalize?.userLanguage}
          </Grid>
        </GridSectionWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <GridSectionWrapper sectionContainerSx={{ height: '100%' }} heading="Content Language">
          <Grid item xs={12} display="flex" flexWrap="wrap" gap="5px">
            {studentPersonalize?.contentLanguages?.map((lang) => (
              <Chip
                key={lang}
                label={
                  dropDownData?.languages?.find((language) => language?.id === lang)
                    ?.languageName || lang
                }
              />
            ))}
          </Grid>
        </GridSectionWrapper>
      </Grid>
    </Grid>
  )
}
