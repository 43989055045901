import { CFormInput } from '@coreui/react-pro'
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { CCTextArea } from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import NormalSectionWrapper from 'src/components/controlCenter/common/sectionComponents/NormalSectionWrapper'
import { TAB_ICON_VIEWS } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import TabPoliciesComponents from './components/TabPoliciesComponents'
import TabReOrderComponents from './components/TabReOrderComponents'
import TabSelectionComponent from './components/TabSelectionComponent'

export default function TabSettingsComponent({
  initialObject,
  imageObject: tabSettingsObject,
  setImageObject: setTabSettingsObject,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  formMode,
}) {
  const [store, dispatch] = useAppSettingsStore()
  const allTabs = store.metaTabs
  const [iconView, setIconView] = useState(TAB_ICON_VIEWS.icon)
  const dropDownOptions = {
    ...customOptions,
    allTabs: allTabs
      ?.filter((tab) => tab?.enabled)
      ?.sort((a, b) => a?.title?.localeCompare(b?.title)),
  }

  function toggleIconView() {
    setIconView((prev) =>
      prev === TAB_ICON_VIEWS.icon ? TAB_ICON_VIEWS.coverIcon : TAB_ICON_VIEWS.icon,
    )
  }

  const handleChangeAndroid = useCallback((eve) => {
    handleNestedChangeObject(eve, 'android')
  }, [])

  const handleChangeWeb = useCallback((eve) => {
    handleNestedChangeObject(eve, 'web')
  }, [])

  const handleChangeIos = useCallback((eve) => {
    handleNestedChangeObject(eve, 'ios')
  }, [])

  return (
    <div className="w-100">
      <NormalSectionWrapper heading="Basic Details">
        <GridSectionWrapper shadow={false}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <span>
              <strong>Tab Setting ID: </strong>
              {initialObject?.id}
            </span>
          </Grid>
          {/* <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <span>
              <strong>Status: </strong>
              <span
                className={`${
                  statusRender() !== 'NA' && statusRender() === 'Enabled' && 'text-success fw-bold'
                }`}
              >
                {statusRender()}
              </span>
            </span>
          </Grid> */}
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  id="enabled"
                  checked={!!tabSettingsObject?.enabled || false}
                  onChange={handleChangeObject}
                  disabled={disabled}
                />
              }
              label={!!tabSettingsObject?.enabled ? 'Enabled' : 'Disabled'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormInput
              id="name"
              value={tabSettingsObject?.name}
              label="Tab Setting Name"
              onChange={handleChangeObject}
              placeholder="Enter Tab Setting Name..."
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CCTextArea
              id="description"
              value={tabSettingsObject?.description}
              label="Tab Setting Description"
              onChange={handleChangeObject}
              placeholder="Enter Tab Setting Description..."
              disabled={disabled}
            />
          </Grid>
        </GridSectionWrapper>
      </NormalSectionWrapper>
      <NormalSectionWrapper heading="Tab Selection">
        <TabSelectionComponent
          androidTilePolicies={tabSettingsObject?.android?.tilePolicyMap}
          webTabTilePolicies={tabSettingsObject?.web?.tilePolicyMap}
          iosTabTilePolicies={tabSettingsObject?.ios?.tilePolicyMap}
          androidTabList={tabSettingsObject?.android?.tabs}
          webTabList={tabSettingsObject?.web?.tabs}
          iosTabList={tabSettingsObject?.ios?.tabs}
          dropDownOptions={dropDownOptions}
          handleChangeAndroid={handleChangeAndroid}
          handleChangeWeb={handleChangeWeb}
          handleChangeIos={handleChangeIos}
          disabled={disabled}
          iconView={iconView}
        />
      </NormalSectionWrapper>
      <NormalSectionWrapper heading="Tab Orders">
        <TabReOrderComponents
          androidTilePolicies={tabSettingsObject?.android?.tilePolicyMap}
          webTabTilePolicies={tabSettingsObject?.web?.tilePolicyMap}
          iosTabTilePolicies={tabSettingsObject?.ios?.tilePolicyMap}
          androidTabList={tabSettingsObject?.android?.tabs}
          webTabList={tabSettingsObject?.web?.tabs}
          iosTabList={tabSettingsObject?.ios?.tabs}
          dropDownOptions={dropDownOptions}
          handleChangeAndroid={handleChangeAndroid}
          handleChangeWeb={handleChangeWeb}
          handleChangeIos={handleChangeIos}
          disabled={disabled}
          iconView={iconView}
        />
      </NormalSectionWrapper>
      <NormalSectionWrapper heading="Tab Policies for Curated Tabs">
        <TabPoliciesComponents
          // androidTabDetailedList={tabSettingsObject?.android?.tabList}
          // webTabDetailedList={tabSettingsObject?.web?.tabList}
          // iosTabDetailedList={tabSettingsObject?.ios?.tabList}
          androidTilePolicies={tabSettingsObject?.android?.tilePolicyMap}
          webTabTilePolicies={tabSettingsObject?.web?.tilePolicyMap}
          iosTabTilePolicies={tabSettingsObject?.ios?.tilePolicyMap}
          dropDownOptions={dropDownOptions}
          disabled={disabled}
          handleChangeAndroid={handleChangeAndroid}
          handleChangeWeb={handleChangeWeb}
          handleChangeIos={handleChangeIos}
          iconView={iconView}
        />
      </NormalSectionWrapper>
      <Box
        sx={{
          position: 'fixed',
          top: 'var(--header-height-with-margin)',
          width: '100%',
          maxWidth: 'var(--generic-form-max-width)',
          zIndex: 5000,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Paper elevation={7}>
          <ToggleButtonGroup
            color="standard"
            size="small"
            value={iconView}
            exclusive
            onChange={(e, val) => setIconView(val)}
          >
            <ToggleButton value={TAB_ICON_VIEWS.icon}>Icon View</ToggleButton>
            <ToggleButton value={TAB_ICON_VIEWS.coverIcon}>Image Icon View</ToggleButton>
          </ToggleButtonGroup>
        </Paper>
      </Box>
    </div>
  )
}
