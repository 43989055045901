import { Pause, PlayArrow } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { getImageLink } from 'src/utilities/generalUtils'
import {
  CustomPlayerContainer,
  CustomPlayerControlsContainer,
  CustomPlayerSliderContainer,
  StyledCustomReactPlayer,
  StyledCustomReactPlayerSlider,
} from './styledComponents'

export function CustomReactPlayer({
  url,
  controls,
  defaultBackgroundImageUrl,
  mediaTitle,
  onFinish = () => {},
  containerStyles = {},
  playerControlsStyles = {},
  useBasicReactPlayer = false,
  ...rest
}) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [loading, setLoading] = useState(true)
  const [played, setPlayed] = useState(0)
  const playerRef = useRef()
  const duration = playerRef.current ? playerRef.current.getDuration() : 0
  const [hasVideo, setHasVideo] = useState(false)
  const [subtitles, setSubtitles] = useState([])

  const [backgroundImageUrlToUse, setBackgroundImageUrlToUse] = useState()

  function handleSeek(e, newValue) {
    const value = newValue
    playerRef.current.seekTo(value)
    setPlayed(value)
  }

  //   function onClickFullscreen() {
  //     screenfull.request(playerRef.current)
  //   }

  async function detectMediaType() {
    try {
      const response = await fetch(url)
      const text = await response.text()
      const videoCodecs = ['avc1', 'hev1', 'hvc1', 'vp8', 'vp9', 'av01']
      const videoRegex = new RegExp(`CODECS="[^"]*(${videoCodecs.join('|')})`, 'i')
      const hasVideoStream = videoRegex.test(text)
      console.log({ hasVideoStream, text })
      setHasVideo(hasVideoStream)
    } catch (error) {
      console.error('Error fetching or parsing the manifest file:', error)
      setHasVideo(true)
    }
  }

  async function Setup() {
    var backgroundImgUrl = defaultBackgroundImageUrl
    if (!backgroundImgUrl) {
      backgroundImgUrl = await getImageLink(undefined, undefined)
    }
    setBackgroundImageUrlToUse(backgroundImgUrl)
    detectMediaType()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : 0
      setPlayed(currentTime)
    }, 500)

    Setup()
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (isPlaying && duration !== 0 && duration !== undefined && duration !== null) {
      if (played === duration) {
        onFinish()
      }
    }
  }, [played])

  return useBasicReactPlayer ? (
    <ReactPlayer url={url} controls={controls} {...rest} />
  ) : (
    <CustomPlayerContainer style={containerStyles}>
      <StyledCustomReactPlayer
        ref={playerRef}
        playing={isPlaying}
        light={backgroundImageUrlToUse}
        style={{
          backgroundImage: `url(${backgroundImageUrlToUse})`,
          backgroundSize: 'cover',
        }}
        config={{
          file: {
            attributes: {
              crossOrigin: 'anonymous',
            },
            tracks: [
              {
                kind: 'subtitles',
                src: url.split('/').slice(0, -1).join('/') + '/subtitles_en.vtt?version=1.11',
                // src: subtitleFile,
                srcLang: 'en',
                default: true,
              },
            ],
          },
        }}
        url={url}
        height={'100%'}
        width={'100%'}
        onClickPreview={() => {
          setLoading(false)
          setIsPlaying(true)
        }}
        {...rest}
      />
      {!loading && (
        <>
          <CustomPlayerControlsContainer style={playerControlsStyles}>
            <IconButton
              sx={{ color: '#fff', p: '4px' }}
              onClick={() => setIsPlaying((prev) => !prev)}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <CustomPlayerSliderContainer>
              {/* <PrettoSlider
              value={played}
              onChangeCommitted={handleSeekCommitted}
              marks={marks}
              onChange={handleSeek}
            /> */}
              <span className="duration-text">
                {Math.floor((played / 60) % 60)}:{String(Math.floor(played % 60)).padStart(2, '0')}
              </span>
              <StyledCustomReactPlayerSlider
                value={played}
                onChange={handleSeek}
                max={duration}
                size="small"
              />
              {/* <IconButton onClick={onClickFullscreen}>
                  <Fullscreen />
                </IconButton> */}
            </CustomPlayerSliderContainer>
          </CustomPlayerControlsContainer>
        </>
      )}
    </CustomPlayerContainer>
  )
}
