import { Add } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import React from 'react'
import { FEATURE_BUNDLE_OBJECT } from 'src/common/types/planBundlePacks/featureBundle/featureBundleObject'
import { giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import FeatureBundleComponent from './FeatureBundle/FeatureBundleComponent'

//this component handles the extra fields for a bundlePack (refer to bundle pack object)
export default function BundlePackComponent({
  subscription,
  setSubscription,
  disabled,
  allSubscriptionPlans,
}) {
  function handleChangeBundle(eve, idx) {
    setSubscription((prev) => ({
      ...prev,
      bundle: prev?.bundle?.map((bundle, index) =>
        index === idx ? giveGenericHandleChangedData(eve, bundle) : bundle,
      ),
    }))
  }

  function addBundle() {
    setSubscription((prev) => ({
      ...prev,
      bundle: [...prev?.bundle, FEATURE_BUNDLE_OBJECT],
    }))
  }

  function deleteBundle(idx) {
    setSubscription((prev) => ({
      ...prev,
      bundle: prev?.bundle?.filter((bundle, index) => index !== idx),
    }))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>Bundle Pack Details</h3>
      </Grid>
      {subscription?.bundle?.map((bundle, idx) => (
        <Grid item key={idx} xs={12}>
          <FeatureBundleComponent
            bundle={bundle}
            changeBundle={(eve) => handleChangeBundle(eve, idx)}
            deleteBundle={subscription?.bundle?.length > 1 ? () => deleteBundle(idx) : undefined}
            allSubscriptionPlans={allSubscriptionPlans}
            disabled={disabled}
          />
        </Grid>
      ))}
      {!disabled && (
        <Grid item xs={12}>
          <Button onClick={addBundle} startIcon={<Add />} sx={{ width: '100%' }}>
            Add Feature Bundle
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
