import { CopyAll, OpenInNew } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { apiURL } from 'src/services'
import { AUMHUM_URL_MAP } from 'src/utilities/constants'
import { copyToClipBoard, openInNewTab } from 'src/utilities/generalUtils'

export default function CopyOrOpenInANewTabInWebApp({ postFixUrl }) {
  const finalURl = `${AUMHUM_URL_MAP?.[apiURL]}/${postFixUrl}`

  return (
    <>
      {postFixUrl ? (
        <Tooltip title={finalURl}>
          <div className="d-flex align-items-center justify-content-center gap-2 px-2">
            <IconButton
              className="flex-shrink-0"
              onClick={(event) => {
                event.stopPropagation()
                copyToClipBoard('Public Url', finalURl)
              }}
            >
              <CopyAll />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                openInNewTab(finalURl)
              }}
            >
              <OpenInNew />
            </IconButton>
          </div>
        </Tooltip>
      ) : (
        <i>Link not available.</i>
      )}
    </>
  )
}
