import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const META_SUBSCRIPTION_TYPES = {
  id: '',
  apiParam: ALL_ENTITY_TYPES_FROM_CONSTANTS.subscriptionPlanType,
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: true,
  slug: '',
  name: '',
  title: '',
}
