import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { DefaultHeaders, apiURL } from 'src/services'
import { TOAST_UTILS } from '../toast-utils'
import BGSVideoPreview from './BGSVideoPreview'

export default function BGSVideo({ bgSlide, handleChange, type }) {
  const videoInputRef = useRef(null)

  async function uploadVideo(event) {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    if (!!file) {
      const tst = toast.loading('Uploading Video...')
      try {
        const response = await axios.post(
          `${apiURL}/api/media/file/upload/video/private?downloadable=false`,
          formData,
          {
            headers: { ...DefaultHeaders(), 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
              const progress = progressEvent.loaded / progressEvent.total
              toast.update(tst, { progress })
            },
          },
        )
        if (response.status === 200) {
          handleChange({ target: { id: type, value: response.data.transcoding.hls.url } })
          toast.update(tst, {
            render: 'Saved Successfully!',
            type: toast.TYPE.SUCCESS,
            isLoading: false,
            ...TOAST_UTILS,
          })
        }
      } catch (error) {
        toast.update(tst, {
          render: 'Something went wrong!',
          type: toast.TYPE.ERROR,
          isLoading: false,
          ...TOAST_UTILS,
        })
        console.log(error)
        return error
      }
    }
  }
  console.log(bgSlide[type])

  return (
    <div>
      <BGSVideoPreview uploadedFile={bgSlide[type]} uploadButtonRef={videoInputRef} />
      <input
        id="video-upload"
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        style={{ display: 'none' }}
        ref={videoInputRef}
        onChange={uploadVideo}
      />
    </div>
  )
}

BGSVideo.propTypes = {
  bgSlide: PropTypes.object,
  handleChange: PropTypes.func,
  setBgSlide: PropTypes.func,
  type: PropTypes.string,
}
