import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export const PAYMENT_GATEWAYS = {
  id: '',
  apiParam: ALL_ENTITY_TYPES_FROM_CONSTANTS.paymentGateways,
  deleted: false,
  enabled: true,
  title: '',
  description: '',
  grpId: '',
  paymentGatewayList: [],
  idByUser: true,
}
