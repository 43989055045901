import React from 'react'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { IdTypes } from 'src/utilities/constants'
import { defaultLinkMakerContextData } from '../../../LinksMakerComponents'
import DeepLinkItemFactory from './IdTypesComponents/DeepLinkItemFactory'

export default function DeepLinkBuilder({
  deepLinkPart,
  setDeepLinkPart,
  dropDownOptions,
  setAdditionalData,
  handleChangeExtraData,
  linkCategoryTypeLabel = 'Link Category Type',
  linkTypeLabel = 'Link Type',
  linkIdSelectionLabel = 'Item',
  extraDataItemIdSelectionLabel,
  extraDataLinkTypeLabel,
  disabled = false,
}) {
  function handleChange(eve) {
    setDeepLinkPart(eve)
  }

  function changeLinkItem(selVal) {
    if (!selVal) {
      setDeepLinkPart((prev) => {
        const emptyData = {
          ...defaultLinkMakerContextData,
        }
        console.log({ emptyData })
        return {
          ...prev,
          itemId: emptyData.itemId,
          itemTitle: emptyData.itemTitle,
          additionalData: emptyData.additionalData,
        }
      })
      return
    }
    setDeepLinkPart((prev) => {
      return {
        ...prev,
        itemId: selVal.value,
        itemTitle: selVal.label,
        additionalData: defaultLinkMakerContextData.additionalData,
      }
    })
  }

  function handleActionData(selVal) {
    setDeepLinkPart((prev) => ({ ...prev, action: selVal }))
  }

  return (
    <>
      <div>
        <CustomReactSelect
          id="metaCategoryId"
          menuPortalTarget={document.body}
          value={deepLinkPart?.metaCategoryId}
          options={dropDownOptions.metaCategoryTypes
            ?.filter((catType) => catType?.scope === 'app' && catType?.enabled && !catType?.deleted)
            ?.sort((a, b) => a.title.localeCompare(b.title))}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title}
          label={linkCategoryTypeLabel}
          placeholder="Select..."
          selectType={selectTypes.string}
          onChangeEvent={(eve) => {
            handleChange(eve)
            changeLinkItem(null)
          }}
          disabled={disabled}
        />
      </div>
      {deepLinkPart?.metaCategoryId && (
        <>
          <div>
            <CustomReactSelect
              id="idType"
              menuPortalTarget={document.body}
              value={deepLinkPart?.idType}
              options={Object.entries(IdTypes)
                .map(([key, value]) => ({
                  id: key,
                  title: value,
                }))
                ?.filter((idType) =>
                  deepLinkPart?.metaCategoryId === 'tiles'
                    ? idType?.id !== IdTypes.id && idType?.id !== IdTypes.slug
                    : true,
                )
                ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              label={linkTypeLabel}
              placeholder="Select..."
              selectType={selectTypes.string}
              onChangeEvent={(eve) => {
                handleChange(eve)
                changeLinkItem(null)
              }}
              disabled={disabled}
            />
          </div>
          <DeepLinkItemFactory
            item={{
              value: deepLinkPart?.itemId,
              label: deepLinkPart?.itemTitle,
            }}
            metaCategoryId={deepLinkPart?.metaCategoryId}
            additionalData={deepLinkPart?.additionalData}
            extraData={deepLinkPart?.extraData}
            idType={deepLinkPart?.idType}
            handleChangeItem={changeLinkItem}
            setAdditionalData={setAdditionalData}
            dropDownOptions={dropDownOptions}
            handleChangeExtraData={handleChangeExtraData}
            actionData={deepLinkPart?.action}
            handleActionData={handleActionData}
            disabled={disabled}
            uiContextData={{
              linkIdSelectionLabel,
              extraDataItemIdSelectionLabel,
              linkTypeLabel,
              linkCategoryTypeLabel,
              extraDataLinkTypeLabel,
            }}
          />
        </>
      )}
    </>
  )
}

///might be useful later

/* {deepLinkPart?.idType !== IdTypes.list && (
        <>
          {deepLinkPart?.metaCategoryId === 'course' && (
            <>
              <div>
                <CustomReactSelect
                  isMulti
                  menuPortalTarget={document.body}
                  value={filterLang}
                  label={'Content Languages'}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.languageName}
                  options={dropDownOptions.languages.sort((a, b) =>
                    a.languageName.localeCompare(b.languageName),
                  )}
                  onChangeEvent={(e) => {
                    setFilterLang(e.target.value)
                    // setCourseOptions()
                  }}
                  selectType={selectTypes.string}
                  placeholder="Select Language"
                />
              </div>
              <div>
                <CustomReactSelect
                  menuPortalTarget={document.body}
                  isMulti
                  value={categoryFilters}
                  label={'Category'}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option?.title}
                  options={dropDownOptions.categories
                    ?.filter((cat) => cat.categoryType === deepLinkPart.metaCategoryId)
                    ?.sort((a, b) => a?.title.localeCompare(b?.title))}
                  onChangeEvent={(e) => {
                    setCategoryFilters(e.target.value)
                    // setCourseOptions()
                  }}
                  selectType={selectTypes.string}
                  placeholder="Select Language"
                />
              </div>
            </>
          )}
          {deepLinkPart?.metaCategoryId && (
            <div>
              <label>Item</label>
              <ActivityItemAsyncSelect
                cacheUniqs={[deepLinkPart?.metaCategoryId, filterLang]}
                classNamePrefix="activity-item-select"
                menuPortalTarget={document.body}
                value={
                  deepLinkPart?.itemId
                    ? {
                        value: deepLinkPart?.itemId,
                        label: deepLinkPart?.itemTitle,
                      }
                    : null
                }
                loadOptions={loadItems}
                debounceTimeout={1000}
                additional={{
                  page: 0,
                  size: 200,
                  metaCategoryId: deepLinkPart?.metaCategoryId,
                }}
                onChange={(selVal) => changeLinkItem(selVal)}
              />
            </div>
          )}
        </>
      )} */

/* <div className="d-flex justify-content-center align-items-center w-100">
        <IconButton>
          <Add />
        </IconButton>
      </div> */
