import { CFormLabel } from '@coreui/react-pro'
import { Checkbox, FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  CCFormInput,
  CCTextArea,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import { loadOptionsForAsyncPaginate } from 'src/utilities/generalUtils'
import { CustomAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'

const WebinarEditingForm = ({
  initialObject = {},
  imageObject: webinarObject,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
}) => {
  const [loadingDetails, setLoadingDetails] = useState({ mentor: false })
  const [selectedMentor, setSelectedMentor] = useState()

  async function GetMentorSelectedMentor() {
    setLoadingDetails((prev) => ({ ...prev, mentor: true }))
    const mentorDetails = await GetDetailedEntity('mentor', { id: webinarObject?.mentorId })
    if (!!mentorDetails) {
      setSelectedMentor(mentorDetails)
    }
    setLoadingDetails((prev) => ({ ...prev, mentor: false }))
  }

  function changeMentor(value) {
    handleChangeObject({ target: { id: 'mentorId', value: value?.id } })
    setSelectedMentor(value)
  }

  useEffect(() => {
    if (!!webinarObject?.mentorId) {
      GetMentorSelectedMentor()
    }
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                id="enabled"
                checked={!!webinarObject?.enabled || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={!!webinarObject?.enabled ? 'Enabled' : 'Disabled'}
          />
          <FormControlLabel
            control={
              <Checkbox
                size={'small'}
                id="publicEvent"
                checked={!!webinarObject?.publicEvent || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={'Webinar is public'}
          />
        </Grid>
        <Grid item xs={12}>
          <CCFormInput
            id="title"
            value={webinarObject?.title}
            onChange={handleChangeObject}
            invalid={!webinarObject?.title}
            label="Title"
            disabled={disabled}
            required
            colNumber={12}
          />
        </Grid>
        <Grid item xs={12}>
          <CCTextArea
            id="description"
            value={webinarObject?.description}
            onChange={handleChangeObject}
            label="Description"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <CFormLabel>Mentor</CFormLabel>
          <CustomAsyncPaginate
            isLoading={loadingDetails.mentor}
            value={selectedMentor}
            loadOptions={loadOptionsForAsyncPaginate}
            getOptionValue={(object) => object.id}
            getOptionLabel={(object) => object.title}
            onChange={changeMentor}
            placeholder="Select a mentor..."
            debounceTimeout={500}
            additional={{
              page: 0,
              getFunction: GetSettingsData,
              otherParams: { entityType: ALL_ENTITY_TYPES?.mentor },
              apiUrl: GENERIC_APIS.entity.getPaginated,
              setLoading: (val) => setLoadingDetails((prev) => ({ ...prev, mentor: val })),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size={'small'}
                id="free"
                checked={!!webinarObject?.free || false}
                onChange={handleChangeObject}
                disabled={disabled}
              />
            }
            label={'Webinar is free'}
          />
        </Grid>
        {!webinarObject?.free && (
          <Grid item xs={12}>
            <CustomReactSelect
              id="pricingPlanId"
              value={webinarObject?.pricingPlanId}
              options={(customOptions?.subscriptionOptions || [])?.filter(
                (plan) => plan?.enabled && plan?.active && plan?.planGrpId === '1on1',
              )}
              getOptionLabel={(option) =>
                `${option?.pricingMatrix?.ROW?.discountedPrice || 'No Data'} ${
                  option?.pricingMatrix?.ROW?.currencySymbol || ''
                }: (${option?.title || option?.internalTitle || ''})`
              }
              getOptionValue={(option) => option?.id || ''}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
              placeholder="Select a plan..."
              label="Webinar Session Price Plan"
              disabled={disabled}
              required
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <CCFormInput
            id="minUsers"
            value={webinarObject?.minUsers}
            onChange={handleChangeObject}
            label="Minimum Users"
            type="number"
            disabled={disabled}
            colNumber={12}
          />
        </Grid>
        <Grid item xs={3}>
          <CCFormInput
            id="maxUsers"
            value={webinarObject?.maxUsers}
            onChange={handleChangeObject}
            label="Maximum Users"
            type="number"
            colNumber={12}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <EntityBasedImageUploadComponent
            disabled={disabled}
            entity={entity}
            entityObject={webinarObject}
            handleChange={handleChangeObject}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default WebinarEditingForm
