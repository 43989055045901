import { configureStore } from '@reduxjs/toolkit'
import { appSettingsReducer } from './reducers/appSettings/appSettingsReducer'
import { clinicFlowReducer } from './reducers/clinicFlowData/clinicFlowDataReducer'
import { courseFormSettingsReducer } from './reducers/courseFormSettings/courseFormSettingsReducers'
import { subscriptionsReducers } from './reducers/subscriptions/subscriptionReducer'

// const store = configureStore({
//   reducer: {
//     change: changeReducer,
//   },
// })

export default configureStore({
  reducer: {
    appSettings: appSettingsReducer,
    clinicFlowData: clinicFlowReducer,
    subscriptions: subscriptionsReducers,
    courseCmsForm: courseFormSettingsReducer,
  },
  devTools: true,
})
