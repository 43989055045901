import { CCol } from '@coreui/react-pro'
import styled from 'styled-components'
import { List, styled as muiStyled } from '@mui/material'

export const FAQContainer = styled.div`
  border-radius: 3px;
`
export const FAQEditor = styled.div`
  background: ${(props) => (props.theme === 'dark' ? '#999999' : '#ebedef')};
  border: 1px solid #d8dbe0;
  border-radius: 7px;
  padding: 3px;
  height: 100%;
`
export const CategorySelectContainer = styled.div`
  width: 70%;
`
export const FAQHeading = styled.div`
  background: var(--cui-primary);
  color: #fff;
`

export const FAQListContainer = styled(CCol)`
  height: 100%;
  pointer-events: ${({ isDragging }) => (isDragging ? 'none' : 'auto')};
  opacity: ${({ isDragging }) => (isDragging ? '0.8' : '1')};
  ${({ isDragging }) => (isDragging ? 'filter: blur(4px)' : '')};
  transition: opacity 0.2s ease-out 0.1s, filter 0.1s ease-out 0.1s;
`
export const FAQsContainer = styled.div`
  height: auto;
  padding-right: 0.25rem;
  overflow-y: ${({ isDragging }) => (isDragging ? 'hidden' : 'auto')};
`
export const FAQCategoryListContainer = styled(CCol)`
  height: 100%;
  pointer-events: ${({ isDragging }) => (isDragging ? 'none' : 'auto')};
  opacity: ${({ isDragging }) => (isDragging ? '0.8' : '1')};
  ${({ isDragging }) => (isDragging ? 'filter: blur(4px)' : '')};
  transition: opacity 0.2s ease-out 0.1s, filter 0.1s ease-out 0.1s;
`
export const FaqFields = styled.div`
  flex-shrink: 0;
`
export const CategoryList = muiStyled(List)(({ theme }) => ({
  height: '90%',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge and Firefox */
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none' /* Firefox */,
}))

export const FAQqnaContainer = styled.div`
  border-radius: 0.5rem;
`
