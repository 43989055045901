import moment from 'moment'
import React from 'react'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import OnlineCourseCourseStatusChip from 'src/views/courses/courseForm/components/OnlineCourseFieldsHandler/components/OnlineCourseCourseStatusChip'

export default function OnlineCourseStatusComponent({ item }) {
  return (
    <td style={{ padding: '0px 15px' }}>
      <OnlineCourseCourseStatusChip onlineCourse={item} />
      <div className="d-flex flex-column gap-2">
        {item?.approved && (
          <>
            <div className="d-flex align-items-center w-100">
              <span>
                <strong className="flex-shrink-0">Approved By: </strong>
                {item?.approvedBy ? <span>{item?.approvedBy}</span> : <span>Not logged</span>}
                <span>
                  <strong className="flex-shrink-0"> at </strong>
                  {item?.approveAt ? (
                    <span>
                      {moment
                        .utc(item?.approveAt)
                        .local()
                        .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
                    </span>
                  ) : (
                    <i>time not logged</i>
                  )}
                </span>
              </span>
            </div>
          </>
        )}
        {item?.reqToUnpublish && (
          <>
            <div className="d-flex align-items-center w-100">
              <span>
                <strong className="flex-shrink-0">Req to Unpublish At: </strong>
                {item?.approveAt ? (
                  <span>
                    {moment
                      .utc(item?.publishAt)
                      .local()
                      .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
                  </span>
                ) : (
                  <span>Not logged</span>
                )}
              </span>
            </div>
          </>
        )}
        {item?.status === 'published' && (
          <>
            <div className="d-flex align-items-center w-100">
              <span>
                <strong className="flex-shrink-0">Published At: </strong>
                {item?.publishAt ? (
                  <span>
                    {moment
                      .utc(item?.publishAt)
                      .local()
                      .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
                  </span>
                ) : (
                  <span>Not logged</span>
                )}
              </span>
            </div>
          </>
        )}
        {item?.status === 'unpublished' && (
          <>
            <div className="d-flex align-items-center w-100">
              <strong className="flex-shrink-0">Unpublished At: </strong>
              {item?.unPublishAt ? (
                <span>
                  {moment
                    .utc(item?.unPublishAt)
                    .local()
                    .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
                </span>
              ) : (
                <span>Not logged</span>
              )}
            </div>
          </>
        )}
      </div>
    </td>
  )
}
