const { Box, Typography } = require('@mui/material')

export function TabWrapper({ children, tabValue, currentValue, tabBoxProps, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={tabValue !== currentValue}
      id={`simple-tabpanel-${currentValue}`}
      aria-labelledby={`simple-tab-${currentValue}`}
      className="w-100"
      style={{ display: tabValue === currentValue ? 'block' : 'none' }}
      {...other}
    >
      <Box sx={{ p: 3 }} {...tabBoxProps}>
        {tabValue === currentValue && children}
      </Box>
    </div>
  )
}
