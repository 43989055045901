import { toast } from 'react-toastify'
import { fetchAllFieldTypes } from './services/fieldTypes/fieldTypes'
import { fetchSectionsAndFieldsForACategory } from './services/sectionsAndFields/sectionsAndFields'

export const courseCmsFormSettingsExtraReducers = (builder) => {
  builder
    .addCase(fetchSectionsAndFieldsForACategory.pending, (state) => {
      state.sectionsAndFields.status = 'loading'
    })
    .addCase(fetchSectionsAndFieldsForACategory.fulfilled, (state, action) => {
      state.sectionsAndFields.status = 'succeeded'
      state.sectionsAndFields.data = action.payload
    })
    .addCase(fetchSectionsAndFieldsForACategory.rejected, (state, action) => {
      state.sectionsAndFields.status = 'failed'
      state.sectionsAndFields.error = action.payload
      toast.error(action.payload)
    })
    //fieldTypes
    .addCase(fetchAllFieldTypes.pending, (state) => {
      state.allFieldTypes.status = 'loading'
    })
    .addCase(fetchAllFieldTypes.fulfilled, (state, action) => {
      state.allFieldTypes.status = 'succeeded'
      state.allFieldTypes.data = action.payload
    })
    .addCase(fetchAllFieldTypes.rejected, (state, action) => {
      state.allFieldTypes.status = 'failed'
      state.allFieldTypes.error = action.payload
      toast.error(action.payload)
    })
}
